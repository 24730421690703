import React from 'react';
import './CloudLoader.scss';

const CloudLoader = () => {
  return (
    <div className={"cloud-loader-wrapper"}>
      <div className={"cloud-loader-wrapper-img"}>
        <img src={"/images/healthAndWellness/score-cloud-loader.gif"} alt="loading..." />
      </div>
    </div>
  );
};

export default CloudLoader;
