import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const liff = window.liff;

const ShareTargetPicker = () => {
  const search = useLocation().search;
  const url = new URLSearchParams(search).get('contentUrl');
  useEffect(() => {
    if (url) {
      shareData();
    }
  }, []);

  const shareData = async () => {
    const share = await liff
      .shareTargetPicker([
        {
          type: 'text',
          text: url ? `${url}` : 'link',
        },
      ])
      .then(() => {
        console.log('sharing');
      })
      .catch((err) => {
        console.log('error', err);
        alert('err', err);
        liff.closeWindow();
      });
    setTimeout(() => liff.closeWindow(), 2000);
  };
  return <div></div>;
};
export default ShareTargetPicker;
