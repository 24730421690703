import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import "./assesment.scoped.scss";
import Swal from "sweetalert2";
import Spinner from '../components/Spinner'
import axios from "axios";
import Slider from "react-slick";
import { Avatar } from 'antd';
import { BiArrowBack } from "react-icons/bi";
import LoadingOverlay from 'react-loading-overlay';

const Assesment = (props) => {
    let [loading2, setLoading2] = useState(false);
    const history = useHistory();
    let User = localStorage.getItem("User");
    const cover_user = User ? JSON.parse(User) : '';
    const [currentSlide, setCurrentSlide] = useState(0);
    const [listAssesment, setlistAssesment] = useState([
    ]);

    useEffect(() => {
        getStore();
    }, []);


    const getStore = () => {
        const liffProfile = localStorage.getItem("Profile");
        setLoading2(true)
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL_UAT}/assessment/frontend`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${cover_user.token}`
            },
        }).then((res) => {
            setLoading2(false)
            console.log('test', res.data.data);
            setlistAssesment(res.data.data)
        }).catch((err) => {
            setLoading2(false)
        });
    };
    const settings = {
        dots: true,
        nav: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: dots => (
            <div>
                <ul
                    style={{
                        position: "absolute",
                        bottom: "-10px",
                        width: "100%",
                        padding: "0",
                        margin: "0",
                        listStyle: "none",
                        textAlign: "center"
                    }}
                >
                    {dots.map((dot, index) => (
                        <li
                            key={index}
                            style={{
                                display: "inline-block",
                                margin: "0 4px",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                backgroundColor: dot.props.className === "slick-active" ? "#83c346" : "gray"
                            }}
                        />
                    ))}
                </ul>
            </div>
        ),


    };

    const home = () => {

        if (cover_user.type == "member") {
            history.push("/assesment");
        } else if (cover_user.type == "abuddy") {
            let roomidsestion = localStorage.getItem("roomid");
            const normalizedRoomid = roomidsestion.replace(/\"/g, "");
            history.push("/assesment?roomId=" + normalizedRoomid);

        }

    }
    const avatarStyle = {
        marginRight: '8px', // Adjust the spacing as desired
        fontSize: '24px', // Increase the font size for a larger Avatar
    };
    return (
        <>
            <div className="page overflow_disable" style={{
                minHeight: '126vh',
                overflowX: 'hidden'
            }}>
                <header style={{ textAlign: 'center' }}>
                    <div style={{ position: 'relative' }}>
                        {window.screen.width > 700 ? <img src="/images/assesment/gran.png" alt="รูปภาพ" style={{ position: 'absolute', zIndex: '1', width: '31%', marginTop: '20px', right: '40px' }} /> : <img src="/images/assesment/gran.png" alt="รูปภาพ" style={{ position: 'absolute', zIndex: '1', width: '31%', marginTop: '20px', right: '0' }} />}

                        <img src="/images/assesment/BGM.png" alt="รูปภาพ" style={{ maxWidth: '100%', maxHeight: '200px', width: '70vh', position: 'relative' }} />
                    </div>
                    <div style={{ position: 'absolute', top: '45px', left: '50%', transform: 'translate(-50%, -50%)', lineHeight: '25px' }}>
                        <span style={{ fontSize: '22px', fontFamily: 'Sukhumvit Set Extra Bold' }}>Select Assessment</span><br />
                    </div>
                </header>
                <div>
                    <div onClick={() => {
                        if (cover_user.type == "member") {
                            history.push("/assesment");
                        } else if (cover_user.type == "abuddy") {
                            let roomidsestion = localStorage.getItem("roomid");
                            const normalizedRoomid = roomidsestion.replace(/\"/g, "");
                            history.push("/assesment?roomId=" + normalizedRoomid);

                        }
                    }} style={{ position: 'absolute', top: '30px', left: '30px' }}>
                        <BiArrowBack style={{ fontSize: '30px' }} />

                    </div>
                    <div>
                        <div>
                            <Slider {...settings} afterChange={slideIndex => setCurrentSlide(slideIndex)} className="slick-list">
                                {listAssesment?.map((data, key) => {
                                    return (
                                        <div>

                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div
                                                    className="card"
                                                    style={{
                                                        width: '90%',
                                                        borderRadius: '20px',
                                                        paddingTop: '20px',
                                                        backgroundImage: 'linear-gradient(rgba(234, 248, 226, 1), rgba(255, 255, 255, 0.5), transparent)',
                                                        zIndex: '1',
                                                        position: 'relative',
                                                        marginTop: '-6vh',
                                                        border: 'none'
                                                    }}
                                                >
                                                    {cover_user.type == "member" ? <div onClick={() => {
                                                        if (cover_user.type == "member") {
                                                            localStorage.setItem("assesment_id", data._id);
                                                            localStorage.setItem("title", data.title);
                                                            history.push("/assesment/abudyassesment");
                                                        }
                                                    }} style={{ position: 'absolute', right: '20px', top: '10px' }}>
                                                        <Avatar size={50} src="/images/assesment/abuddy.jpg" style={avatarStyle} />


                                                    </div>
                                                        : null
                                                    }

                                                    <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                        <div>
                                                            <img src={data.url} alt="รูปภาพ" style={{ maxWidth: '100%', maxHeight: '200px', width: 'auto', border: 'none', marginTop: '25px' }} />
                                                        </div>
                                                        <span style={{ marginTop: '10px', color: 'green', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px' }}>
                                                            {data.title}
                                                            <br />
                                                        </span>
                                                        <span style={{ marginTop: '10px', color: '#2C2C2C', fontSize: '13px', fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                                            {data.description}
                                                        </span>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10vh', paddingLeft: '23px' }}>
                                                        <div className="process" style={{ '--widthProcess': data.percent + '%', width: '80%' }}></div>
                                                        <span style={{ marginLeft: '10px', color: '#d1e8ba', fontWeight: 'bold' }}>{data.percent}%</span>
                                                        <span style={{ marginLeft: '10px', color: '#d1e8ba', fontWeight: 'bold' }}>complete</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    );
                                })}
                            </Slider>

                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '7vh' }}>
                                {listAssesment[currentSlide]?.percent === 100 ? (
                                    <button
                                        style={{
                                            borderRadius: '35px',
                                            backgroundColor: 'rgb(15, 119, 74)',
                                            width: '40vh',
                                            fontFamily: 'Sukhumvit Set tadmai bold',
                                            borderColor: 'rgb(15, 119, 74)',
                                            fontSize: '2vh',
                                            height: '7vh'
                                        }}
                                        type="button"
                                        className="btn btn-primary btn-lg btn-block button"
                                        onClick={() => {
                                            const selectedData = listAssesment[currentSlide];
                                            localStorage.setItem("assesment_id", selectedData._id);
                                            history.push("/assesment/finish");
                                        }}
                                    >
                                        ดูผลประเมิน
                                    </button>
                                ) : listAssesment[currentSlide]?.percent < 100 && listAssesment[currentSlide]?.percent !== 0 ? (
                                    <button
                                        style={{
                                            borderRadius: '35px',
                                            backgroundColor: 'rgb(15, 119, 74)',
                                            width: '40vh',
                                            fontFamily: 'Sukhumvit Set tadmai bold',
                                            borderColor: 'rgb(15, 119, 74)',
                                            fontSize: '2vh',
                                            height: '7vh'
                                        }}
                                        type="button"
                                        className="btn btn-primary btn-lg btn-block button"
                                        onClick={() => {
                                            const selectedData = listAssesment[currentSlide];
                                            localStorage.setItem("assesment_id", selectedData._id);
                                            history.push("/assesment/pagequestion");
                                        }}
                                    >
                                        ทำต่อ
                                    </button>
                                ) : (
                                    <button
                                        style={{
                                            borderRadius: '35px',
                                            backgroundColor: 'rgb(15, 119, 74)',
                                            width: '40vh',
                                            fontFamily: 'Sukhumvit Set tadmai bold',
                                            borderColor: 'rgb(15, 119, 74)',
                                            fontSize: '2vh',
                                            height: '7vh'
                                        }}
                                        type="button"
                                        className="btn btn-primary btn-lg btn-block button"
                                        onClick={() => {
                                            const selectedData = listAssesment[currentSlide];
                                            localStorage.setItem("assesment_id", selectedData._id);
                                            history.push("/assesment/pagequestion");
                                        }}
                                    >
                                        Start
                                    </button>
                                )}
                            </div>

                        </div>
                    </div>
                </div >
                <LoadingOverlay active={loading2} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
            </div>
        </>
    );
};

export default Assesment;