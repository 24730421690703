import React from "react";
import "./ChallengeSubmit.scss"
import { CHALLENGE } from "../../../../common/challengeConstants";

const ChallengeSubmit = (props) => {
    const { updateUserAnswers, enableSubmit } = props
    return (<>
        <button disabled={!enableSubmit} onClick={updateUserAnswers} className={`btn-green ${enableSubmit ? 'btn-green-linear' : 'btn-white-linear'} w-100`}>
            {CHALLENGE.challengeQuiz.submitButtonText}
        </button>
    </>
    )
}

export default ChallengeSubmit;