import React, { useEffect, useState } from 'react';
import './ChallengeAutoSyncStep.scss';
import {
  CalendarLoader,
  ChallengeProgressBar,
  commonConstant,
} from '../../../common';
import {
  AUTO_SYNC_STEPS_THAI,
  AUTO_SYNC_STEPS_TRACK,
  BODY_KEY_APP_LINK,
  BODY_KEY_APPSTORE,
  BODY_KEY_PLAYSTORE,
  BODY_KEY_UNIVERSAL_LINK,
} from '../../../common/challengeConstants';
import { getBodyKeyRecordAction } from '../../../actions/challenge/challengeBodyKeyActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getHnwAllHealhGoalList } from '../../../actions';
import { round } from '../../../services';
import FloatingWidget from '../../../common/challenge/floatingWidget/FloatingWidget';
import {
  checkIsIOS,
  encapsulatePercent,
  formatNumberWithCommas,
  getCalorieByTotalSteps,
  getDistanceCovered,
} from '../../../utill.func';
import ChallengeCircularProgress from '../../../common/challenge/challengeCircularProgress/ChallengeCircularProgress';

const TARGET_STEP = 10000;
const ChallengeAutoSyncStep = (props) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const healthGoals = useSelector((state) => state.goalSetting.healthGoalList);
  const bodyKeyRecords = useSelector(
    (state) => state.challengeBodyKey.bodyKeyRecords,
  );
  const userActivityTarget = {
    ...healthGoals?.activityGoal,
    targetSteps: TARGET_STEP,
    targetDistance: getDistanceCovered(TARGET_STEP, 2),
  };
  const [loading, setLoading] = useState(true);

  const accSteps =
    bodyKeyRecords?.length &&
    bodyKeyRecords.reduce(
      (previousValue, currentValue) => {
        previousValue.stepCount += parseInt(currentValue?.stepCount);
        previousValue.stepKcal += getCalorieByTotalSteps(
          currentValue?.stepCount,
          2,
        );
        previousValue.stepDistance += getDistanceCovered(
          currentValue?.stepCount,
          2,
        );
        previousValue.stepTime += parseInt(currentValue?.stepTime);
        return previousValue;
      },
      {
        stepCount: 0,
        stepKcal: 0,
        stepDistance: 0,
        stepTime: 0,
      },
    );
  // percentage
  const accumulatedSteps = {
    ...accSteps,
    perCount:
      (parseInt(accSteps.stepCount) * 100) / userActivityTarget.targetSteps ||
      0,
    perKcal:
      (parseInt(accSteps.stepKcal) * 100) / userActivityTarget.targetCalories ||
      0,
    perDistance:
      (parseInt(accSteps.stepDistance) * 100) /
        userActivityTarget.targetDistance || 0,
    perTime:
      (parseInt(accSteps.stepTime) * 100) / userActivityTarget.exerciseTime ||
      0,
  };

  useEffect(() => {
    setLoading(true);
    const didMount = async () => {
      await Promise.allSettled([
        loadBodyKeyRecords(customer?.bodyKeyId),
        getHealthGoals(customer?._id),
      ]);
      setLoading(false);
    };
    didMount();
  }, [customer?.bodyKeyId, customer?._id]);

  const loadBodyKeyRecords = async (bkId) => {
    try {
      if (bkId) {
        await dispatch(
          getBodyKeyRecordAction({
            startDate: moment().format('yyyy-MM-DD'),
            endDate: moment().format('yyyy-MM-DD'),
            bodyKeyId: bkId,
          }),
        );
      }
    } catch (error) {
      console.error('Body Key Recod failed', error);
    }
  };
  const getHealthGoals = async (cId) => {
    try {
      if (cId) {
        await dispatch(getHnwAllHealhGoalList(cId));
      }
    } catch (err) {
      console.error('err', err);
    }
  };

  return (
    <div className="challenge-auto-sync-step">
      <CalendarLoader showLoading={loading} />
      <FloatingWidget
        onClick={() => {
          loadBodyKeyRecords(customer?.bodyKeyId);
          // NOTE: universal link & app link here
          const isIOS = checkIsIOS();
          const now = new Date().valueOf();
          setTimeout(() => {
            if (new Date().valueOf() - now > 100) return;
            // TODO: CONSOLE NEED TO REMOVE AFTER TESTING
            console.log('BODY_KEY_APP_IN_STORE');
            window.location = isIOS
              ? new URL(BODY_KEY_APPSTORE)
              : new URL(BODY_KEY_PLAYSTORE);
          }, 25);
          // TODO: CONSOLE NEED TO REMOVE AFTER TESTING
          console.log('BODY_KEY_APP');
          window.location = isIOS
            ? new URL(BODY_KEY_UNIVERSAL_LINK)
            : new URL(BODY_KEY_APP_LINK);
        }}
      >
        <button className="auto-sync-widget-btn">
          <img
            src={
              customer?.challengeAutoSync
                ? '/images/challenge/floating-widget-green.svg'
                : '/images/challenge/floating-widget-white.png'
            }
            width={92}
            height={92}
            alt="floating-widget-auto-sync"
          />
        </button>
      </FloatingWidget>
      <div className="challenge-auto-sync-step-header">
        <button
          className="btn btn-white-linear"
          onClick={() => props.history.push(commonConstant.pathHealthnWellness)}
        >
          <img src="/images/challenge/challenge-home.svg" alt="" />
        </button>
        <header className="challenge-auto-sync-step-header-title">
          {AUTO_SYNC_STEPS_THAI.SCREEN_TITLE}
        </header>
        <button
          className="btn btn-white-linear"
          onClick={() =>
            props.history.push(commonConstant.pathHnwExerciseLogCalendar)
          }
        >
          <img
            src="/images/challenge/challenge-calendar.svg"
            alt="challenge-calendar"
          />
        </button>
      </div>
      <div className="challenge-auto-sync-step-body">
        <ChallengeCircularProgress
          value={formatNumberWithCommas(accumulatedSteps?.stepCount)}
          total={formatNumberWithCommas(userActivityTarget?.targetSteps)}
          percent={round(encapsulatePercent(accumulatedSteps?.perCount), 0)}
          unit={AUTO_SYNC_STEPS_THAI.STEP_COUNT_UNIT}
        />
        <div className="challenge-auto-sync-step-body-list">
          {AUTO_SYNC_STEPS_TRACK(accumulatedSteps, userActivityTarget).map(
            (step, id) => (
              <div className="exercise-card" key={`${step?.title}_${id}`}>
                <div className="exercise-card-title">{step?.title}</div>
                <ChallengeProgressBar
                  percentage={round(encapsulatePercent(step?.percent), 0) || 0}
                />
                <div className="display-flex">
                  <span>
                    <span
                      className={`exercise-card-value ${
                        step?.value === 0 ? 'black' : 'green'
                      }`}
                    >
                      {formatNumberWithCommas(step?.value)}
                    </span>
                    <span className="exercise-card-total">
                      /{formatNumberWithCommas(step?.total)}
                    </span>{' '}
                    <span className="exercise-card-unit">{step?.unit}</span>
                  </span>
                  <span className="exercise-card-percent">
                    {round(encapsulatePercent(step?.percent), 0) || 0}%
                  </span>
                </div>
              </div>
            ),
          )}
          <div
            className="goal-card"
            onClick={() => props.history.push(commonConstant.pathHnwGoalsHome)}
          >
            <img src="/images/challenge/goal-black.svg" alt="goal-black.svg" />
            <span className="goal-redirection-link">
              {AUTO_SYNC_STEPS_THAI.GOAL_REDIRECTION}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeAutoSyncStep;
