import React, { useState, useEffect } from 'react';
import { getPriceFormat } from '../../../../services/promotion/utils.service';

export const ProfileProducts = props => {
  const { profileDetails, planType } = props;
  const [totalPrice, setTotalPrice] = useState(0);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (profileDetails && planType) {
      renderProfileProductPrice();
    }
  }, [JSON.stringify(profileDetails), planType]);

  const renderProfileProductPrice = () => {
    let profileProductPrice = 0;
    if (planType !== 'flex') {
      let children = profileDetails.currentElementView &&
        profileDetails.currentElementView.entries &&
        profileDetails.currentElementView.entries.length &&
        profileDetails.currentElementView.entries[0].children ?
        profileDetails.currentElementView.entries[0].children : [];
      if (children && children.length) {
        children.forEach(child => {
          profileDetails.recommendedProduct.forEach(pro => {
            if (pro.id === child.selectedProductCode) {
              pro.updatedQuantity = child.quantity;
            }
          });
          const filteredProduct = profileDetails.recommendedProduct.find(pro => pro.id === child.selectedProductCode);
          let aboPrice = 0;
          if (filteredProduct) {
            aboPrice = filteredProduct.prices.find((value) => value.type === 'ABO');
            aboPrice = parseInt(aboPrice.price);
            profileProductPrice += aboPrice * child.quantity;
          }
        });
      }
    } else {
      let qty = profileDetails.currentElementView &&
        profileDetails.currentElementView.entries &&
        profileDetails.currentElementView.entries.length &&
        profileDetails.currentElementView.entries[0].quantity ?
        profileDetails.currentElementView.entries[0].quantity : 0;
      let aboPrice = profileDetails && profileDetails.sopProduct &&
        profileDetails.sopProduct.prices &&
        profileDetails.sopProduct.prices.length &&
        profileDetails.sopProduct.prices.find((value) => value.type === 'ABO');
      profileProductPrice = parseInt(aboPrice?.price) * qty;
    }
    setTotalPrice(profileProductPrice);
    const product = profileDetails?.recommendedProduct && profileDetails?.recommendedProduct.length ?
      profileDetails?.recommendedProduct : [profileDetails?.sopProduct];
    setProducts(product);
  }

  const getTotalQuantity = () => {
    let qty = 0;
    if (planType === 'flex') {
      qty = profileDetails.currentElementView &&
        profileDetails.currentElementView.entries &&
        profileDetails.currentElementView.entries.length &&
        profileDetails.currentElementView.entries[0].quantity ?
        profileDetails.currentElementView.entries[0].quantity : 0;
    } else {
      if (products && products.length > 0) {
        products.forEach(pro => {
          if (pro && pro.updatedQuantity > 0) {
            qty += pro.updatedQuantity;
          }
        })
      }
    }
    return qty;
  }

  return (
    <div className='profile_details_products'>
      <div className='col-12 header'>
        <span>฿ {getPriceFormat(totalPrice)}</span>
        <span>รายการสินค้า ({getTotalQuantity()})</span>
      </div>
      <div className='col-12'>
        {products && products.length && products.map((product, index) => {
          let aboPrice = product.prices.find((value) => value.type === 'ABO');
          aboPrice = parseInt(aboPrice.price);
          let amwayValue = product?.amwayValue;
          return (
            <div className='row product_tiles' key={`profile-products-list-${index}`}>
              <div className='col-3'>
                <img src={product?.galleryImages?.[0].image_url} />
              </div>
              <div className='col-9 details'>
                <div className='row justify-left sku'>#{product?.sku}</div>
                <div className='row justify-left identifier'>{product?.identifier}</div>
                <div className='row price_pvbv'>฿ {getPriceFormat(aboPrice)}</div>
                <div className='row price_pvbv price_pvbv_container'>
                  <span className='pv_bv'>PV {getPriceFormat(amwayValue?.pointValue)} / BV {getPriceFormat(amwayValue?.businessVolume)}</span>
                  <span> x{planType === 'flex' ? profileDetails.currentElementView &&
                    profileDetails.currentElementView.entries &&
                    profileDetails.currentElementView.entries.length &&
                    profileDetails.currentElementView.entries[0].quantity ?
                    profileDetails.currentElementView.entries[0].quantity : 0 : product?.updatedQuantity}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProfileProducts;