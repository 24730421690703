import React from 'react';
import './LinearRowContent.scss';
import {
  GOAL_SETTING_KG_PER_WEEK,
  HEALTH_GOAL_TYPES,
  LOSE_WEIGHT_TH,
} from '../../../../../common/commonConstant';
import { formatNumberWithCommas } from '../../../../../utill.func';

export default function LinearRowContent({
  type,
  content,
  healthGoalValue,
  options = {},
}) {
  return (
    <div className="goal-setup-linear-row-component d-flex justify-content-between align-items-center">
      <div
        className={`goal-setup-linear-row-component-left-${
          HEALTH_GOAL_TYPES.WEIGHT === type ? 'text' : 'text-light'
        }`}
      >
        {type === HEALTH_GOAL_TYPES.WEIGHT ? `${options.weightGoalType}:` : `${content.dataName}:`}
      </div>
      <div className="goal-setup-linear-row-component-right">
        <div className="goal-setup-linear-row-component-right-col1 d-flex justify-content-end align-items-center">
          <div className="goal-setup-linear-row-component-right-col1-value">
            {formatNumberWithCommas(healthGoalValue)}
          </div>
          <div className="goal-setup-linear-row-component-right-col1-unit">
            {content.dataUnit}
          </div>
        </div>
        {HEALTH_GOAL_TYPES.WEIGHT === type ? (
          <div className="goal-setup-linear-row-component-right-col2">
            {LOSE_WEIGHT_TH === options?.weightGoalType ? '' : '+'}
            {options?.targetWeightPerDay}{" "}{GOAL_SETTING_KG_PER_WEEK}
          </div>
        ) : null}
      </div>
    </div>
  );
}
