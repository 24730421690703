import React, { useEffect, useState } from 'react';
import './ChallengeHome.scss';
import ChallengeCarousel from '../challengeCarousel/ChallengeCarousel';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChallengeList, fetchChallengeProfile } from '../../../actions/challengeListActions';
import { CHALLENGE_LIST } from '../../../common/challengeConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ChallengeNavButton, commonConstant } from '../../../common';
import { getDataFromSession, getParamsString } from '../../../utill.func';
// import ChallengeSelfCarousel from '../challengeSelfCarousel/ChallengeSelfCarousel';
import { fetchChallengeSelf } from '../../../actions/challenge/challengeSelfActions';
import { homePageTabBtnSize } from '../../../common/commonConstant';
import { FETCH_CHALLENGES_DETAIL_SUCCESS } from '../../../actions/types';

const ChallengeHome = ({ isAccessTokenFetched }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [challengeStatus, setChallengeStatus] = useState(
    CHALLENGE_LIST.TYPES.ongoing,
  );
  const challengeList = useSelector(
    (state) => state.challengeListObj?.challengeList,
  );

  const challengeProfileList = useSelector(
    (state) => state.challengeListObj?.challengeProfile?.profileList
  );

  const policyStatus = useSelector((state) => state?.home?.policyStatus);
  const { challengeSelfList } = useSelector((state) => state?.challengeSelf);
  const { isAuthTokenSaved } = useSelector(
    (state) => state.tokenStatus,
  );

  const fetchChallenge = async (type) => {
    setIsLoading(true);
    await dispatch(fetchChallengeList(type ? { challengeStatus: type } : undefined))
      .finally(() => {
        setIsLoading(false);
      })
  };

  const fetchChallengeSelfData = async () => {
    try {
      dispatch(fetchChallengeSelf());
    } catch (error) {
      throw new Error(error);
    }
  }

  const fetchProfile = async (eventIdList) => {
    const { partyId, amwayNumber } = getDataFromSession(
      'customer'
    );
    const payloadObj = {
      partyId,
      aboId: amwayNumber,
      eventIds: eventIdList
    }
    setProfileLoading(true);
    await dispatch(fetchChallengeProfile(`?${getParamsString(payloadObj)}`));
    setProfileLoading(false);
  }

  const challengeTabList = CHALLENGE_LIST.HOME_TABS_MAPPING.map((tabObj) => {
    const name = tabObj.name;
    return challengeList && challengeList[name] && challengeList[name].length > 0 ? tabObj.label : null;
  }).filter(Boolean);

  const handleTabButtonChange = (index, value) => {
    const challengeVal = CHALLENGE_LIST.TYPE_MAP[value];
    if (!challengeList?.[challengeVal]) {
      fetchChallenge(challengeVal);
    }
    setChallengeStatus(challengeVal);
  };

  const handleCardClick = async (cardObj, isRegistered) => {
    const { id, eventId, challengeStatus: type, completeDate, teamDashboardImage } = cardObj;
    // TODO: need to store in localstorage for camera screen's navigate back
    await dispatch({
      type: FETCH_CHALLENGES_DETAIL_SUCCESS,
      payload: cardObj,
    });
    sessionStorage.setItem("challengeData", JSON.stringify({challengeId: eventId, challengeEventId: id, challengeRewardDate: completeDate, myDashboardBg: teamDashboardImage}));
    let paramsObj = {};
    if (type===CHALLENGE_LIST.TYPES.upcoming || (type===CHALLENGE_LIST.TYPES.ongoing && !isRegistered)) {
      paramsObj = {
        challengeId: id,
        isRegistered
      }
      history.push(`${commonConstant.pathChallengeDetail}?${getParamsString(paramsObj)}`);
    }
    else if(type===CHALLENGE_LIST.TYPES.ongoing && isRegistered) {
      paramsObj = {
        challengeId : eventId,
        eventId : id
      }
      history.push(`${commonConstant.pathChallengeTeamDashboard}?${getParamsString(paramsObj)}`);
    }
  }

  useEffect(() => {
    if (isAuthTokenSaved && isAccessTokenFetched) {
      const { TYPES } = CHALLENGE_LIST;
      const { ongoing, upcoming } = challengeList;

      if (!ongoing && !upcoming) {
        fetchChallenge();
      } else {
        if (!ongoing) {
          fetchChallenge(TYPES.ongoing);
        } else if (!upcoming) {
          fetchChallenge(TYPES.upcoming);
        }
      }
      if (ongoing?.length > 0) {
        setChallengeStatus(TYPES.ongoing);
      } else if (upcoming?.length > 0 && !ongoing.length > 0) {
        setChallengeStatus(TYPES.upcoming);
      }

      if (ongoing?.length > 0 && !challengeProfileList) {
        const eventIdList = ongoing
          .filter((challenge) => challenge.eventId)
          .map((challenge) => challenge.eventId)
          .join(',');
        fetchProfile(eventIdList);
      }
    }
  }, [challengeList, isAuthTokenSaved, isAccessTokenFetched]);

  useEffect(() => {
    if (isAuthTokenSaved && isAccessTokenFetched) {
      fetchChallengeSelfData();
    }
  }, [isAuthTokenSaved, isAccessTokenFetched]);


  return (
    <>
      <div className="challenge-list">
        <ChallengeNavButton
          tabList={challengeTabList}
          onTabButtonChange={handleTabButtonChange}
          styleObj={{fontSize: homePageTabBtnSize}}
          tabListClass='challenge-tab-list-home'
        />
        <div>
          <ChallengeCarousel
            carouselList={challengeList?.[challengeStatus]}
            isLoading={isLoading}
            isProfileLoading={profileLoading}
            challengeProfileList={challengeProfileList}
            onCardClick={handleCardClick}
            showTag
          />
        </div>
        {/* <div className="challenge-self-carousel-container">
          <ChallengeSelfCarousel carouselSelfList={challengeSelfList} />
        </div> */}
      </div>
    </>
  );
};

export default ChallengeHome;
