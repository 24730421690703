import React,{ useState,useEffect,useRef } from 'react';

import './Collapse.scss';

export const Collapse = ({ standalone = false, title,children, customSuffix, isCollpased, onClick , seperator = true, noContent = false, containerClassName, titleClass, hideSuffix }) => {
  const [collapse, setCollapse] = useState(true);
  const contentRef = useRef();
  useEffect(() => {
    contentRef.current.style.setProperty('--max-height',`${contentRef.current.scrollHeight}px`);
  }, [collapse, children]);
  useEffect(() => {
    if (isCollpased !== undefined){
      setCollapse(isCollpased);
    }
  },[isCollpased]);
  return (
    <div className={`collapse-container ${!collapse && 'active'} ${noContent && 'no-content'} ${containerClassName}`}>
      <div className={`collapse-header ${standalone && 'standalone'}`} onClick={ onClick ? () => onClick() : () => setCollapse(collapse => !collapse)}>
        <span className={`title ${titleClass}`}>{title}</span>
        {!hideSuffix && <span className={`suffix ${!collapse && 'active'} ${customSuffix && 'custom'}`}>
          {
            customSuffix ? customSuffix : <img src="/images/payment/chevron-down.svg" />
          }
        </span>}
      </div>
      { seperator && <hr/>}
      <div ref={contentRef} className={`collapse-content ${standalone && 'standalone'} ${!collapse && 'active'}`}>
        <div className="content-container">
          {children}
        </div>
        
      </div>
    </div>
  );};
