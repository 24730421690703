import React from 'react';
import { EXERCISE_LOG_DETAIL } from '../../../../../common/commonConstant';
import HnwTimeLogData from './HnwTimeLogData';
import { getParamsString } from '../../../../../utill.func';

export default function HnwExerciseTimeLog({
  toggleTimeLog,
  setExerciseMenuId,
  routeTo,
  exerciseMenuId,
  exerciseTimeLogList,
  isTargetCalorieCalculated,
  targetCalorie,
}) {
  return (
    <div className="hnw-exercise-time-log-container">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={() => {
            toggleTimeLog(false);
            setExerciseMenuId('');
          }}
        >
          <img
            src="/images/healthAndWellness/exercise-log/close-icon.svg"
            alt=""
          />
        </button>
      </div>
      <div className="modal-body">
        <div className="modal-title">
          <div className="modal-heading">
            {EXERCISE_LOG_DETAIL.TIME_LOG.HEADING}
          </div>
        </div>
        <div className="devices-list-wrapper">
          <div className="devices-single">
            <div className="device-single-left">
              <img
                src="/images/healthAndWellness/goal-setting/icons/burn.svg"
                width={50}
                height={50}
              />
              <div className="device-name">
                {EXERCISE_LOG_DETAIL.TIME_LOG.ADD_NEW}
              </div>
            </div>
            <div
              className="btn-icon-round"
              onClick={() => {
                let paramsObj = {
                  exerciseMenuId: exerciseMenuId,
                };
                if (isTargetCalorieCalculated && targetCalorie) {
                  paramsObj.suggestedCalorie = targetCalorie;
                }
                routeTo(
                  'pathHnwExerciseLogDetail',
                  `?${getParamsString(paramsObj)}`,
                );
              }}
            >
              <img
                src="/images/healthAndWellness/exercise-log/green-add-icon.svg"
                className="add-img"
              />
            </div>
          </div>
          {exerciseTimeLogList
            ?.filter(
              (timelog) => timelog?.excerciseCategory?.id === exerciseMenuId,
            )
            ?.map((timeLog) => (
              <HnwTimeLogData timeLog={timeLog} routeTo={routeTo} />
            ))}
        </div>
      </div>
    </div>
  );
}
