import React, { forwardRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './HnWSlideup.scss';

const HnWSlideup = forwardRef((props, ref) => {

  const [showButton, setShowButton] = useState(false);
  const {scrollHide=false} =props;

  useEffect(() => {
    let slideUpTimeout;

    const handleScrollButtonVisibility = () => {
      if (window.pageYOffset > 40) {
        setShowButton(true);

        if (scrollHide) {
          clearTimeout(slideUpTimeout);
          slideUpTimeout = setTimeout(() => {
            setShowButton(false);
          }, 1000);
        }
      } else {
        setShowButton(false);
      }
    };

    const handleTabOrClick = () => {
      setShowButton(prevShowButton => !prevShowButton);
    };

    window.addEventListener('scroll', handleScrollButtonVisibility);
    scrollHide && document.addEventListener('click', handleTabOrClick);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
      scrollHide && document.removeEventListener('click', handleTabOrClick);
      clearTimeout(slideUpTimeout);
    };
  }, [scrollHide]);

  const slideToTop = () => {
    if (ref?.current) {
      ref.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };


  if (showButton) {
    return (
      <div onClick={slideToTop} className="text-center">
        <a className="home-fixed-button" style={{'bottom': props.bottomGap }}>
          <img src="/images/healthAndWellness/icon/arrow-up.svg" />
        </a>
      </div>
    );
  }
});

export default HnWSlideup;