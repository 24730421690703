import React from 'react';
import '../Score.scss';
import { getCustomerAge, getCustomerGender } from '../../../../../services/healthWellness/healthWellness.service';

const BannerCard = ({ bmiData }) => {

  const genderValue = getCustomerGender();

  const DETAILS_MAPPING = {
    [genderValue === 'หญิง' ? 'female' : 'male']: {
      heading: 'เพศ:',
      value: genderValue ?? '',
      unit: ''
    },
    age: {
      heading: 'อายุ:',
      value: getCustomerAge() ?? 0,
      unit: 'ปี'
    },
    weight: {
      heading: 'น้ำหนัก:',
      value: bmiData.weight ?? 0,
      unit: 'กก.',
    },
    height: {
      heading: 'ส่วนสูง:',
      value: bmiData.height ?? 0,
      unit: 'ซม.',
    },
  }

  return <div className="search-banner-card-wrapper">
    <div className="search-banner-card">
      <div className="profile-details">
        {Object.keys(DETAILS_MAPPING).map((item) => (
          <div key={item} className="profile-details-single-container">
            <img src={`/images/assesment/${item}.svg`} />
            <div className="profile-details-text-wrapper">
              <div className="profile-details-heading">{DETAILS_MAPPING[item].heading}</div>
              <div className="profile-details-value">{`${DETAILS_MAPPING[item].value} ${DETAILS_MAPPING[item].unit}`}</div>
            </div>
          </div>))}
      </div>
    </div>
  </div>
}

export default BannerCard;
