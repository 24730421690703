import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { createPayment } from '../../../actions';
import { Checkbox } from '../../../helpers';
import { commonConstant } from '../../../common';
import variables from '../../../common/commonConstant.scss';

import './ChooseCreditCard.scss';

export class ChooseCreditCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      selected: null,
      redirect: null,
      cardData : [
        {
          id: '1',
          bankName: 'ไทยพาณิชย์',
          accountName: 'TODSOB NAKTURAKIJ',
          cardType: 'visa',
          cardNo: '1234',
        },
        {
          id: '2',
          bankName: 'ไทยพาณิชย์',
          accountName: 'TODSOB NAKTURAKIJ',
          cardType: 'mastercard',
          cardNo: '1234',
        },
      ],
    };
  }

  handleSelect = value => {
    this.setState({
      selected: value,
    });
  }

  handleClick = type => {
    if (type === 'edit'){
      this.setState({
        redirect: {
          pathname: commonConstant.pathCreateCreditCard,
          state: { edit: true },
        },
      });
    } else {
      this.setState({
        redirect: commonConstant.pathCreateCreditCard,
      });
    }
  }

  handleSubmit = () => {
    this.props.createPayment({ type: 'creditcard', data: {} });
    this.setState({
      redirect: commonConstant.pathPaymentConfirm,
    });
  }

  renderCardTypes = type => {
    switch (type){
      case 'visa':
        return <img src="/images/payment/visa.svg" alt="" />;
      case 'mastercard':
        return <img src="/images/payment/mastercard.svg" alt="" />;
      default:
        return;
    }
  }

  renderCards = () => {
    const { handleSelect, renderCardTypes } = this;
    const { cardData, selected } = this.state;
    if (cardData.length === 0){
      return <div className="center">
        <img src="/images/payment/EmptyCard.svg" />
        <div>ไม่มีบัตรเครดิต / เดบิต</div>
      </div>;
    }
    return <>
    {cardData.map((card,idx) => (
      <div key={idx} className="creditcard-choice">
        <Checkbox checkmarkColor={variables.success} checked={selected === card.id} onClick={() => handleSelect(card.id)}>
          <div className="detail-left">
            <div className="bank-name">
              {card.bankName}
            </div>
            <div className="account-name">
              {card.accountName}
            </div>
          </div>
          <div className="detail-right">
            <div className="cardno">
              {renderCardTypes(card.cardType)}
              {card.cardNo}
            </div>
          </div>
        </Checkbox>
      </div>
    ))}
    <hr/>
    </>;
  }

  render() {
    const { renderCards, handleClick, handleSubmit } = this;
    const { cardData, selected,redirect } = this.state;
    if (redirect){
      return <Redirect to={redirect} />;
    }
    return (
      <div className="creditcard-pick-container">
        <div className="header">
          <div>
          บัตรเครดิต / เดบิต
          </div>
        </div>
        <div className="body">
          {renderCards()}
          <div className="buttons-container">
            <button className="away-button" disabled={cardData.length === 0} onClick={() => handleClick('edit')}>แก้ไขบัตร</button>
            <button className="away-button" onClick={() => handleClick()}>เพิ่มบัตร</button>
          </div>
        </div>
        <div className="footer">
          <button className={`register-next-button ${selected && 'active'}`} disabled={!selected} onClick={handleSubmit}>ยืนยัน</button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createPayment: data => dispatch(createPayment(data)),
});

export default connect(null,mapDispatchToProps)(ChooseCreditCard);
