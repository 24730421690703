import React, { useEffect, useState } from 'react';
import { getPriceFormat } from '../../../services/promotion/utils.service';
import '../../sop/Subscription/SOPSubscription.scss';

export const PLPItem = ({
  img,
  sku,
  identifier,
  price,
  amwayValue,
  sopPlans,
}) => {
  const renderOption = () => {

    if (sopPlans && sopPlans.length < 2) {
      return (
        <div className="w-100 option-box">
          <div key={`sop-products-plan-cycle-${0}`} className="new-option-content">
            <span>{sopPlans[0].cycle.name}</span>
          </div>
          <div key={`sop-products-plan-cycle-${1}`} className="new-option-content hidden-content">
            <span>{sopPlans[0].cycle.name}</span>
          </div>
        </div>
      );
    } else if (sopPlans && sopPlans.length >= 2) {
      return (
        <div className="w-100 option-box">
          {sopPlans.map((value ,index) => (
            index < 2 && <div key={`sop-products-plan-cycle-${index}`} className="new-option-content">
              <span>{value.cycle.name}</span>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="sop-product-box">
      <div className="sop-product-content">
        <div className="row no-margin-row display-flex">
          <div className="product-image-container">
            <div className="card-product-img">
              <img
                src={img}
                alt="SOP product image"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </div>
        </div>
        <div className='display-flex'>
            <span className="new-sop-title-sku">#{sku}</span>
        </div>
        <div className='display-flex'>
            <span className="new-sop-title-name">{identifier}</span>
        </div>
        <div className='display-flex sop-plp-benefit'>{renderOption()}</div>
        <div className="display-flex sop-price-container">
          <h2>฿ {getPriceFormat(price)}</h2> &ensp; &ensp;
        </div>
      </div>
     
    </div>
  );
};
export default PLPItem;
