import React, { useState, useCallback, useEffect, useRef } from 'react';
import './QuizQuestionCard.scss';
import QuizAnswerButton from './QuizAnswerButton';

const QuizQuestionCard = (props) => {
  const {
    orderNumber,
    question,
    labels,
    isAnswered,
    quizData,
    selectedAnswers,
    onAnswerSelect,
  } = props;
  const [isFull, setIsFull] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState();
  const [userAnswer, setUserAnswer] = useState();
  const questionDivRef = useRef(null);

  const handleTextWrap = useCallback(
    (wrap) => {
      if (wrap && !isFull) {
        setIsFull(true);
      }
    },
    [isFull],
  );

  useEffect(() => {
    if (isAnswered) {
      const isCorrect =
        quizData?.quiz_user_answers?.user_answer[orderNumber] ===
        quizData?.allCorrectAnswers[orderNumber];
      setIsAnswerCorrect(isCorrect);
      const userAnsIndex =
        quizData?.quiz_user_answers?.user_answer[orderNumber];
      setUserAnswer(question?.question_choice[userAnsIndex]);
    }
  }, [quizData]);

  const answerOptionsState = !isAnswered ? (
    <div className="question-card-answer-options">
      {question?.question_choice?.map((answer, index) => (
        <QuizAnswerButton
          key={index}
          isAnswered={isAnswered}
          answer={answer}
          labels={labels[index]}
          onTextWrap={handleTextWrap}
          isSelected={selectedAnswers[orderNumber] === index}
          onSelect={() => onAnswerSelect && onAnswerSelect(orderNumber, index)}
          parentRef={questionDivRef}
        />
      ))}
    </div>
  ) : (
    <div className="question-card-answer-options">
      <QuizAnswerButton
        isAnswered={isAnswered}
        answer={userAnswer}
        labels={isAnswerCorrect ? labels.correctAns : labels.incorrectAns}
        onTextWrap={handleTextWrap}
        isCorrect={isAnswerCorrect}
        parentRef={questionDivRef}
      />
    </div>
  );

  const question_title = question?.question_title;
  return (
    <div
      ref={questionDivRef}
      className={`question-card-single ${
        isFull || isAnswered ? 'question-card-full' : ''
      }`}
    >
      <div className="question-card-name">
        <span>{orderNumber + 1}.</span>
        <span>{question_title}</span>
      </div>
      {answerOptionsState}
    </div>
  );
};

export default QuizQuestionCard;
