import React from 'react';
import LinearRowThumbnailContent from './LinearRowThumbnailContent';
import config from '../../../../../config';

export default function HnwSupplementGoalCardBody({ healthGoal }) {
  return (
    <div className="card-body-section-supplement">
      {healthGoal?.supplementData.map((sd, i) => (
        <LinearRowThumbnailContent
          key={`${sd?.supplementId}_${i}`}
          healthGoalImgUri={`${config.S3_URL}${sd?.image}` || '/images/healthAndWellness/goal-setting/icons/default-supplement.svg'}
          healthGoalName={sd?.name}
          healthGoalValue={sd?.quantity}
          healthGoalUnit={sd?.unit}
        />
      ))}
    </div>
  );
}
