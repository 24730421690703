import React, { useEffect } from 'react';
import './libraryProfile.scss';
import Categories from '../profile/categories';
import DiscoverNew from '../profile/discoverNew';

const AmwayLibrary = () => {
  return (
    <div className="my-library">
      <Categories type={'AmwayLibrary'} />
      <DiscoverNew type={'AmwayLibrary'} />
    </div>
  );
};
export default AmwayLibrary;
