import React from "react";
import "./TeamDashboardLoader.scss";

const TeamDashboardLoader = () => {
  return (
    <div className="team-dashboard-container">
    <div className="carousel-skeleton-container">
    <div className="carousel-skeleton-card-container">
      <div className="skeleton-card-image skeleton"></div>
    </div>
  </div>
  </div>
  );
};

export default TeamDashboardLoader;
