import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'

import './bmi.scss'
import Description from './description/description'
import StatusBar from './statusBar/statusBar'
// import BmiChart from './bmiChart/bmiChart'
import Classification from './classification/classification'
import { Slideup } from '../../../common';
import Info from './info/info'
import { bmiClassification } from '../../../services/utils.service'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBmiData } from '../../../actions'

const Bmi = () => {
    const [loading, setLoading] = useState(false);
    const activeClassWrapper = {
        1: { text: 'น้ำหนักต่ำกว่าเกณฑ์', class: 'bmi-blue-active' },
        2: { text: 'ร่างกายสมส่วน', class: 'bmi-green-active' },
        3: { text: 'อ้วนระดับ 1', class: 'bmi-yellow-active' },
        4: { text: 'อ้วนระดับ 2', class: 'bmi-orange-active' },
        5: { text: 'อ้วนระดับ 3', class: 'bmi-red-active' },
    }

    const dispatch = useDispatch();
    const { ...bmiData } = useSelector(state => state.bmi)

    const [activeData, setActiveData] = useState({});

    useEffect(() => {
        if (!bmiData?.bmi) {
            setLoading(true);
            dispatch(fetchBmiData()).finally(() => setLoading(false));
        }
    }, []);

    useEffect(() => {
        if (bmiData?.bmi) {
            const data = bmiClassification(bmiData.bmi, activeClassWrapper);
            setActiveData(data);
        }
    }, [bmiData?.bmi]);

    return (
        <div className="home-bmi-header-container">
            <div className={`container-fluid p-0 banner-container ${activeData?.class ?? ''}`}>
                <Info bmi={bmiData?.bmi} />
                <div className="bmi-details-wrapper">
                    <div className="bmi-details-container">
                        <StatusBar activeClass={activeData?.class ?? ''} />
                        <div className="row">
                            <div className="col-12">
                                <h5 className="bmi-details-title">{activeData?.text ?? 'เกณฑ์ของดัชนีมวลกาย'}</h5>
                            </div>
                        </div>
                        {bmiData?.bmi && <Classification bmiData={bmiData} />}
                    </div>
                    {/* {bmiData.bmi ? <BmiChart /> : null} */}
                    <Description />
                </div>
            </div>
            <Slideup />
            <LoadingOverlay active={loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
        </div>
    )
}

export default Bmi