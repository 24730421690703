import React, { useEffect, useState } from 'react';
import './ChallengeMapUserPin.scss';
import { CHALLENGE_MAP, allUsersMarkerTranslateMapping } from '../../../common/challengeConstants';

export default function ChallengeMapUserPin({ imgSrc, isLeader, index, ownRank, show, onClick, partyId, userPinLocationInfo }) {
  const [imgClass, setImgClass] = useState('--no_image');

  useEffect(() => {
    !imgSrc && setImgClass('--no_image');
  }, [imgSrc]);

  const handleImageLoad = () => setImgClass('');
  const handleImageError = () => setImgClass('--no_image');
  const getBubbleMessage = (index) => ownRank > index ? CHALLENGE_MAP.BUBBLE_AHEAD_MESSAGE : CHALLENGE_MAP.BUBBLE_BEHIND_MESSAGE;

  return (
    <div className="user-profile-wrapper"
      onClick={() => onClick(index, partyId)}
      tabIndex="0" onBlur={() => onClick(-1)}
      style={{ translate: userPinLocationInfo }}
    >
      <div className="user-rank">{index + 1}</div>
      {
        index !== ownRank && <div
          className={`user-chat-bubble${!show ? ' --hide' : ''}`}
        >
          {getBubbleMessage(index)}
        </div>
      }
      <img
        onLoad={handleImageLoad}
        onError={handleImageError}
        src={imgSrc}
        className={`user-img ${imgClass}${isLeader ? ' --is-leader' : ''}`}
        alt={`User ${index}`}
      />
      {isLeader && <img className="user-image-badge" src="/images/challenge/crown.svg" alt />}
    </div>
  )
}
