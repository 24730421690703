import React from "react";

const CircleRoundHalfDouble = () => {

    return (
        <div className="circle-round-half-1">
            <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="95" cy="95" r="89" stroke="white" strokeWidth="12" strokeLinecap="round"></circle>
                <circle cx="95" cy="95" r="89" stroke="url(#paint0_linear_7142_422411)" strokeWidth="12"
                    strokeLinecap="round"></circle>
                <path
                    d="M17.9237 50.5C27.9754 33.09 43.6253 19.5959 62.3247 12.2152C81.0242 4.83448 101.672 4.00201 120.905 9.85335C140.138 15.7047 156.823 27.8951 168.244 44.4394C179.664 60.9837 185.147 80.9072 183.8 100.965"
                    stroke="#6CC949" strokeWidth="12" strokeLinecap="round" />
                <defs>
                    <linearGradient id="paint0_linear_7142_422411" x1="68" y1="12.3913" x2="109" y2="12.3913"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6CC949" />
                        <stop offset="1" stopColor="#9FDC7D" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    )
}

export default CircleRoundHalfDouble;