import { GET_BODY_DATA_DIFFERENCE, GET_BODY_DATA_DIFFERENCE_FAILURE, GET_BODY_DATA_DIFFERENCE_SUCCESS } from "../actions/types";

const initialState = {
    bodyDifferenceData: null,
    bodyDataDiffLoading: true,
    error: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BODY_DATA_DIFFERENCE:
            return {
                ...state,
                bodyDataDiffLoading: true,
                error: null,
            };
        case GET_BODY_DATA_DIFFERENCE_SUCCESS:
            return {
                ...state,
                bodyDataDiffLoading: false,
                bodyDifferenceData: action.payload,
            };
        case GET_BODY_DATA_DIFFERENCE_FAILURE:
            return {
                ...state,
                bodyDataDiffLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

