export class FoodLogGoal {
    constructor(data = null) {
        if (data) {
            this.id = data._id || '';
            this.customers = data.customers || '';
            this.targetCalories = data.targetCalories || null;
            this.targetProtein = data.targetProtein || null;
            this.targetCarbs = data.targetCarbs || null;
            this.targetFat = data.targetFat || null;
            this.updatedDate = data.updatedDate || '';
            this.createdDate = data.createdDate || '';
        }
    }
}