import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import './AssessmentHomePage.scss';
import Liff from '../../../modal/Liff';
import { CartAssessmentHeader } from '../../../../common';
import { AssessmentPopup, AssessmentDescription, LiffAssessmentModal, UserErrorModal } from '../components';
import config from '../../../../config';
import { getHnWAssessmentDetail, getHnWAssessmentList, deleteHnWAssessmentDetail, getHnWAssessmentHistoryLog } from '../../../../actions';
import { DEFAULT_HOME_PAGE, ASSESSMENT_TOTAL_QUESTION, ASSESSMENT_STATUS } from '../../../../common/commonConstant';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();
const DEFAULT_ASSESSMENT_TIME = 5;
const liff = window.liff;

//TODO: change the exact number of assessment questions
export const AssessmentHomePage = ({ routeTo }) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);

    const { assessmentDetail, assessmentList, assessmentHistoryLog } = useSelector((state) => state.assessment);
    const customerData = useSelector(state => state.customer);
    const [isOpenLiffDes, setIsOpenLiffDes] = useState(false);
    const [statusModal, setStatusModal] = useState(false);

    const [assessmentId, setAssessmentId] = useState(false);
    const [answerId, setAnswerId] = useState('');
    const [doneAnswerId, setDoneAnswerId] = useState(false);
    const [loading, setLoading] = useState(true);
    const [detailLoader, setDetailLoader] = useState(false);
    const [assessmentStatus, setAssessmentStatus] = useState('');
    const [errorModal, setErrorModal] = useState(false);

    const statusModalClass = !isOpenLiffDes ? (assessmentStatus === ASSESSMENT_STATUS.done) ? "retryModalClass" : "modalClass" : "";
    const desClass = isOpenLiffDes ? "desClass" : "";

    const checkForTnC = () => {
        if (!customerData?.isTncAck) {
            routeTo('pathTermsConditions', '?path=pathHnWAssessmentHome');
        } else {
            if (assessmentStatus === ASSESSMENT_STATUS.pending) {
                setStatusModal(true);
            } else {
                redirectToAssessment();
            }
        }
    }


    const renderAssessmentPopup = () => {
        return (
            <AssessmentPopup
                assessmentStatus={assessmentStatus}
                firstCTAAction={() => { (assessmentStatus === ASSESSMENT_STATUS.done) ? redirectToAssessment() : deleteAssessmentDetail() }}
                secondCTAAction={() => { (assessmentStatus === ASSESSMENT_STATUS.done) ? redirectToAssessmentScore() : redirectToAssessment() }}
                firstCTAText={DEFAULT_HOME_PAGE.first_cta_text}
                secondCTAText={(assessmentStatus === ASSESSMENT_STATUS.done) ? DEFAULT_HOME_PAGE.second_cta_done_text : DEFAULT_HOME_PAGE.second_cta_text}
            />
        );
    };

    const renderAssessmentDes = () => {
        return (
            <AssessmentDescription setIsOpenLiffDes={setIsOpenLiffDes} assessmentList={assessmentList} />
        );
    };

    const redirectToAssessment = (data) => {
        const assessment_id = data ?? assessmentId;
        routeTo('pathHnWAssessment', `?assessment_id=${assessment_id}`);
    };

    const redirectToAssessmentScore = () => {
        routeTo('pathHnWAssessmentScore', `?assessment_id=${assessmentId}&answer_id=${doneAnswerId}`);
    };

    const closeLiffWindow = async () => {
        await liff.closeWindow();
    }

    const FirstTimeAssessment = () => {
        return <button className='btnClass' onClick={() => checkForTnC()}>{DEFAULT_HOME_PAGE.start_btn}</button>;
    }

    const SecondTimeAssessment = () => {
        return (<div className='asm-ftr-ctn-btn-sec'>

            <div className='asm-ftr-ctn-btn-sec-lbtn' onClick={() => redirectToAssessmentScore()} >
                <img
                    src="/images/healthAndWellness/assessment/assessment_result_icon.svg"
                />
                <span className='asm-ftr-ctn-btn-sec-lbtn-t1'>
                    {DEFAULT_HOME_PAGE.result_btn}
                </span>
            </div>
            <button className='btnClass' onClick={() => setStatusModal(true)}>{DEFAULT_HOME_PAGE.retry_btn}</button>
        </div>
        );
    }

    useEffect(() => {
        const didMount = async () => {
            await fetchAssessmentDetail();
        };
        didMount();
    }, []);

    const fetchAssessmentDetail = async () => {
        try {
            setLoading(true);
            dispatch(getHnWAssessmentList()).then(res => {
                const assessment_id = (urlParams.get('assessment_id')) ?? (res?.assessment_id);
                const uid = urlParams.get('uid') ?? '';
                const customer = JSON.parse(sessionStorage.getItem('customer'));
                if (customer?.uid && uid && customer?.uid !== uid) {
                    setErrorModal(true);
                }
                setAssessmentId(assessment_id);
                dispatch(getHnWAssessmentHistoryLog()).then(res => {
                    if (res && res.length) {
                        setDoneAnswerId(res[0]._id);
                    }
                });
                dispatch(getHnWAssessmentDetail(assessment_id));
                setLoading(false);
                setDetailLoader(true);
                dispatch(getHnWAssessmentDetail(assessment_id)).then(res => {

                    const hnwProfile = urlParams.get('from');
                    setDetailLoader(false);
                    setAnswerId(res?.answer_id);
                    setAssessmentStatus(res?.assessments_status);
                    if (hnwProfile) { setStatusModal(true); }
                }).catch(function (error) {
                    console.log(error);
                });
                setLoading(false);
            }).catch(function (error) {
                console.log(error);
            });
        }
        catch (error) {
            setLoading(false);
        }
    }


    const deleteAssessmentDetail = async () => {
        try {
            setLoading(true);
            dispatch(deleteHnWAssessmentDetail(answerId)).then(res => {
                setLoading(false);
                if (res?.success) {
                    redirectToAssessment();
                }
            }).catch(function (error) {
                setLoading(false);
                console.log(error);
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    const backgroundImageStyle = {
        backgroundImage: `url(${config.S3_URL}${assessmentList?.landing_image_url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }

    const questionCount = (parseInt(assessmentList?.hw_assessments?.length) + 4) || ASSESSMENT_TOTAL_QUESTION;
    return (
        <section>
            <LoadingOverlay
                text="Loading"
                spinner
                active={loading}
                styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
            />

            <>
                <CartAssessmentHeader
                    leftIcon={"/images/healthAndWellness/assessment/assessment_home.svg"}
                    rightFirstIcon={"/images/healthAndWellness/assessment/assessment_history_log.svg"}
                    rightSecondIcon={"/images/healthAndWellness/assessment/assessment_cart.svg"}
                    leftLabel={DEFAULT_HOME_PAGE.header}
                    routeTo={routeTo}
                />
                <div className='asm' style={backgroundImageStyle}>
                    <div className='asm-ftr'>
                        <div className="asm-ftr-ctn">
                            <div className='asm-ftr-ctn-hdr'>
                                <span className='asm-ftr-ctn-hdr-t1'>
                                    {DEFAULT_HOME_PAGE.footer_title}
                                </span>
                                <img
                                    src="/images/assesment/assessment_info.svg"
                                    onClick={() => setIsOpenLiffDes(true)}
                                />

                            </div>
                            {assessmentDetail ?
                                <>
                                    <div className='asm-ftr-ctn-body'>
                                        <div className='asm-ftr-ctn-body-left'>
                                            <div className='asm-ftr-ctn-body-left-up'>
                                                <img
                                                    src="/images/assesment/assessment_quantity.svg"
                                                />
                                                <span className='asm-ftr-ctn-body-left-up-t1'>
                                                    {DEFAULT_HOME_PAGE.points}
                                                </span>
                                            </div>
                                            <span className='asm-ftr-ctn-body-left-down'>{`${questionCount} ข้อ`}</span>
                                        </div>
                                        <div className='asm-ftr-ctn-body-right'>
                                            <div className='asm-ftr-ctn-body-right-up'>
                                                <img
                                                    src="/images/assesment/assessment_timer.svg"
                                                />
                                                <span className='asm-ftr-ctn-body-right-up-t1'>
                                                    {DEFAULT_HOME_PAGE.time}
                                                </span>
                                            </div>
                                            <span className='asm-ftr-ctn-body-right-down'>{`${DEFAULT_ASSESSMENT_TIME} นาที`}</span>
                                        </div>
                                    </div>

                                    <div className='asm-ftr-ctn-btn'>
                                        {(assessmentStatus === ASSESSMENT_STATUS.done || assessmentHistoryLog?.length) ? <SecondTimeAssessment assessmentDetail={assessmentDetail} /> : <FirstTimeAssessment />}
                                    </div>
                                </> :
                                <LoadingOverlay
                                    text="Loading"
                                    spinner
                                    active={detailLoader}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className={`asm-modal-ctn ${statusModalClass} ${desClass}`}>
                    <Liff
                        onClose={() => setIsOpenLiffDes(false)}
                        isOpen={isOpenLiffDes}
                    >
                        <div className="scroll">
                            {renderAssessmentDes()}
                        </div>
                    </Liff>

                    {statusModal &&
                        <LiffAssessmentModal
                            onClose={() => setStatusModal(false)}
                            isOpen={statusModal}
                            title={(assessmentStatus === ASSESSMENT_STATUS.done) ? DEFAULT_HOME_PAGE.retry_title : DEFAULT_HOME_PAGE.retry_title}
                            subtitle={(assessmentStatus === ASSESSMENT_STATUS.done) ? DEFAULT_HOME_PAGE.retry_subtitle : DEFAULT_HOME_PAGE.continue_subtitle}
                        >
                            <div className="scroll">
                                {renderAssessmentPopup()}
                            </div>
                        </LiffAssessmentModal>}
                </div>
                {errorModal &&
                    <UserErrorModal
                        isOpen={errorModal}
                        onClose={() => closeLiffWindow()}
                    >
                    </UserErrorModal>
                }
            </>


        </section>
    );
};

export default AssessmentHomePage;
