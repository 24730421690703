import { createBrowserHistory } from 'history';
import React, { useEffect, useState, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';

import {
    getCustomerAge,
    getCustomerGender,
} from '../../../../../services/healthWellness/healthWellness.service';
import './HealthAssessment.scss';
import { GENDER, H_W_VALIDATION, bmiScoreValue, waistlineScoreValue } from '../../../../../common/commonConstant';
import { updateBmiData, fetchBmiData } from '../../../../../actions';
import { bmiClassification, waistlineAnswerScore } from '../../../../../services/utils.service'
import HnWNumpadModal from '../../../../../common/hnWNumpadModal/HnWNumpadModal';
import Numpad from '../../../../../common/hnWNumpadModal/Numpad';

export const history = createBrowserHistory();

const Health = ({ healthData, getAnswer }) => {
    const dispatch = useDispatch();
    const bmi = useSelector((state) => state.bmi);
    const customerData = useSelector(state => state.customer);

    const [loading, setLoading] = useState(false);

    const [gender, setGender] = useState('');
    const [age, setAge] = useState(0);

    const [openNumpad, setOpenNumpad] = useState(false);
    const [currentInput, setCurrentInput] = useState('');
    const [healthInfo, setHealthInfo] = useState({});
    // Deafult input state for UI data only to blank input text on numpad open
    const [defaultInput, setDefaultInput] = useState({});
    const [height, setHeight] = useState(0); // Initial height value
    const divRef = useRef(null);

    useEffect(() => {
        const didMount = async () => {
            updateState(bmi);
            setGender(getCustomerGender(customerData));
            setAge(getCustomerAge(customerData));
            if (bmi.bmi && bmi.waist) {
                updateAnswer();
            }
        };
        didMount();
    }, [customerData, bmi]);

    const updateState = (data) => {
        const weight = (data?.weight) ? (data?.weight) : healthInfo['weight'];
        const height = (data?.height) ? (data?.height) : healthInfo['height'];
        const waist = (data?.waist) ? (data?.waist) : healthInfo['waist'];
        updateHealthInfo('weight', weight);
        updateHealthInfo('height', height);
        updateHealthInfo('waist', waist);
    };

    const updateData = () => {
        const value = Number.parseFloat(healthInfo[currentInput]);
        updateAnswer();
        setLoading(true);
        dispatch(updateBmiData({ [currentInput]: value })).then(res => {
            dispatch(fetchBmiData()).then(res => {
                setLoading(false);
            });
        })
    }

    const updateAnswer = () => {
        const waist = (healthInfo?.waist && (healthInfo?.waist !== undefined)) ? Number.parseFloat(healthInfo['waist']) : bmi?.waist;
        const height = (healthInfo?.height && (healthInfo?.height !== undefined)) ? Number.parseFloat(healthInfo['height']) : bmi?.height;
        const weight = (healthInfo?.weight && (healthInfo?.weight !== undefined)) ? Number.parseFloat(healthInfo['weight']) : bmi?.weight;
        const bmiVal = bmi.bmi;
        const healthDataObj = {
            height: height,
            weight: weight,
            waist: waist,
            bmi: bmiVal,
            age: age
        }
        let answeredQuestions = [];
        const bmiValue = bmiClassification(bmi.bmi, bmiScoreValue);
        const waistValue = waistlineAnswerScore(waist, gender, waistlineScoreValue)
        const bmiAnsweredValue = healthData?.question_value.find(answer => answer.value === bmiValue);
        const waistAnsweredValue = healthData?.question_value.find(answer => answer.value === waistValue);
        answeredQuestions = [...answeredQuestions, bmiAnsweredValue, waistAnsweredValue, { healthData: healthDataObj }];
        getAnswer(answeredQuestions, healthData);
    }

    const renderWeight = () => {
        return (
            <div className={`input-tag ${healthInfo['weight'] ? '' : 'input-empty'}`}
                onClick={() => { toggleNumpad(true, 'weight') }}>
                {healthInfo && healthInfo['weight'] ?
                    (openNumpad && currentInput == 'weight') ? defaultInput['weight'] :
                        healthInfo['weight'] : 'โปรดระบุ'}
            </div>
        )
    };

    const renderHeight = () => {
        return (
            <div className={`input-tag ${healthInfo['height'] ? '' : 'input-empty'}`}
                onClick={() => { toggleNumpad(true, 'height') }} >
                {healthInfo && healthInfo['height'] ?
                    (openNumpad && currentInput == 'height') ? defaultInput['height'] :
                        healthInfo['height'] : 'โปรดระบุ'}
            </div>
        )
    };

    const renderWaist = () => {
        return (
            <div className={`input-tag ${healthInfo['waist'] ? '' : 'input-empty'}`}
                onClick={() => { toggleNumpad(true, 'waist') }} >
                {healthInfo && healthInfo['waist'] ?
                    (openNumpad && currentInput == 'waist') ? defaultInput['waist'] :
                        healthInfo['waist'] : 'โปรดระบุ'}
            </div>
        )
    };

    // Scroll commnted : TODO
    // useEffect(() => {
    //     // Scroll to the div after state update
    //     if(divRef.current) {
    //         divRef.current.scrollIntoView({ behavior: "smooth" });
    //     }
    // }, [height]);

    // toggle numpad
    const toggleNumpad = (val, type) => {
        if (type) { setCurrentInput(type) }
        setOpenNumpad(val)
        // if(!val) {
        //     setHeight(0);  
        // } else {
        //     setHeight(150); 
        // }
    }

    // state update dynamically for api inputs
    const updateHealthInfo = (key, value) => {
        setHealthInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    // state update dynamically for default inputs for UI only
    const updateDefaultInfo = (key, value) => {
        setDefaultInput(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    // On input change at modal updating state of component with updated value
    const handleInputChange = (value) => {
        const currentvalue = (defaultInput[currentInput] && defaultInput[currentInput] !== undefined) ? defaultInput[currentInput] : '';
        const regex = (H_W_VALIDATION[currentInput].pattern);
        const newValue = currentvalue + value;
        if (regex.test(newValue)) {
            updateHealthInfo(currentInput, newValue);
            // set default Input for UI only
            updateDefaultInfo(currentInput, newValue);
        }
    };

    // On delete at modal updating state of component
    const handleDelete = () => {
        let value = (defaultInput[currentInput] && defaultInput[currentInput] !== undefined) && defaultInput[currentInput].toString();
        if (value) {
            const updatedValue = value.slice(0, -1)
            updateHealthInfo(currentInput, updatedValue);
            updateDefaultInfo(currentInput, updatedValue);
        }
    };

    // On OK click at modal updating state of component and calling api for update
    const handleOKClick = () => {
        toggleNumpad(false);
        setDefaultInput({});
        if (healthInfo[currentInput] && (bmi[`${currentInput}UpdatedDate`] ||
            healthInfo[currentInput] != bmi[currentInput])) {
            updateData();
        } else {
            updateHealthInfo(currentInput, bmi[currentInput]);
        }
    };

    // Renderinng Numpad Modal
    const renderNumpad = () => {
        return (
            <Numpad handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                handleOKClick={handleOKClick}
            />
        );
    };

    return (
        <>
            <LoadingOverlay
                text="Loading"
                spinner
                active={loading}
                styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
            />
            <div className={openNumpad ? '' : `health-title word-wrap-break`}>
                {healthData?.question}
            </div>
            <div className={`health-container ${openNumpad ? 'set-transition health-mb-130' : 'set-transition'}`}>
                <div className="health-question word-wrap-break">{healthData?.description}</div>
                <div className="health-input-parent-div w-h-100">
                    <div className="input-div">
                        <div className="title-div word-wrap-break">
                            <img src="/images/healthAndWellness/assessment/weight.svg" />
                            <span>น้ำหนัก (กิโลกรัม)</span>
                        </div>
                        {renderWeight()}
                    </div>
                    <div className="input-div">
                        <div className="title-div">
                            <img src="/images/healthAndWellness/assessment/height.svg" />
                            <span>ส่วนสูง (เซนติเมตร)</span>
                        </div>
                        {renderHeight()}
                    </div>
                    <div className="input-div">
                        <div className="title-div">
                            {GENDER.female ? <img src="/images/healthAndWellness/icon/gender.svg" /> : <img src="/images/healthAndWellness/icon/gender-male.svg" />}
                            <span>เพศ</span>
                        </div>
                        <div className="customer-value word-wrap-break">{gender}</div>
                    </div>
                    <div className="input-div">
                        <div className="title-div">
                            <img src="/images/healthAndWellness/assessment/age.svg" />
                            <span>อายุ (ปี)</span>
                        </div>
                        {age ? (
                            <div className="customer-value word-wrap-break">{age}</div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="input-div no-border">
                        <div className="title-div">
                            <img src="/images/healthAndWellness/assessment/waist.svg" />
                            <span>เส้นรอบเอว (นิ้ว)</span>
                        </div>
                        {renderWaist()}
                    </div>
                </div>
            </div>
            {/* <div ref={divRef} style={{height: `${height}px` }}> </div> */}
            <div className='health-container-modal'>
                <HnWNumpadModal onClose={() => toggleNumpad(false)} openNumpad={openNumpad} onBackgroundClick={handleOKClick}>
                    {renderNumpad()}
                </HnWNumpadModal>
            </div>

        </>
    );
};

export default Health;