
import React from 'react';

export const HnWContentModal = (props) => {
    return (
        <div className='asm-modal-ctn'>
            <div className={`asm-modal-ctn-btn retryModalClass`}>
                <button onClick={props.btnOnClick1} className='asm-modal-ctn-btn-btn1'>{props.btnTitle1}</button>
                <button onClick={props.btnOnClick2} className='asm-modal-ctn-btn-btn2'>{props.btnTitle2}</button>
            </div>
        </div>)
}

export default HnWContentModal;
