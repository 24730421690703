import AxiosLibrary from '../../../config/config.axios';
import config from '../../../config';
import axios from 'axios';

export const getAmwayContent = async (query) => {
  try {
    const { data } = await AxiosLibrary.get(
      `${config.API_URL}/library/amway/contents`,
      {
        params: {
          search: query,
        },
      },
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const getAmwayContentBySubCat = async (query, subCatId) => {
  try {
    const { data } = await AxiosLibrary.get(
      `${config.API_URL}/library/amway/content/category/${subCatId}`,
      {
        params: {
          search: query,
        },
      },
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const getAboContentsByCategoryId = async (query, catId) => {
  try {
    const { data } = await AxiosLibrary.get(
      `${config.API_URL}/library/abo/content/category/${catId}`,
      {
        params: {
          search: query,
        },
      },
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const getAboPublicContent = async (contentId) => {
  try {
    const { data } = await axios.get(
      `${config.API_URL}/content/abo/${contentId}`,
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const getAboContents = async (query) => {
  try {
    const { data } = await AxiosLibrary.get(
      `${config.API_URL}/library/contents`,
      {
        params: {
          search: query,
        },
      },
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const createAboContent = async (body) => {
  try {
    await AxiosLibrary.post(`${config.API_URL}/library/content`, body);
  } catch (error) {
    throw error.response;
  }
};

export const updateAboContent = async (contentId, body) => {
  try {
    await AxiosLibrary.put(
      `${config.API_URL}/library/content/update/${contentId}`,
      body,
    );
  } catch (error) {
    throw error.response;
  }
};

export const deleteAboContent = async (contentId) => {
  try {
    await AxiosLibrary.delete(
      `${config.API_URL}/library/content/delete/${contentId}`,
    );
  } catch (error) {
    throw error.response;
  }
};

export const uploadImage = async (body) => {
  try {
    const { data } = await AxiosLibrary.post(
      `${config.API_URL}/library/image/upload`,
      setFormData(body),
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

const setFormData = (body) => {
  const formData = new FormData();
  formData.append('imageFile', body.image);
  formData.append('name', body.name);

  return formData;
};
