import React from 'react';
import './style.scss';
import { CALENDAR_DATA_LABELS } from '../../../../common/commonConstant';
import { thaiDate } from '../../../../utill.func';

export default function GraphTitle({ date, itemTypeList }) {
  return (<>
    <div className="graph-title-container">
      {itemTypeList.map(({ name, value, legendLabel, unit }) => <div key={name} className="graph-title-container-content">
        <div className="graph-title-legend-container">
          <a className="legend-icon">
            <img src={CALENDAR_DATA_LABELS[name].legendIcon} alt={`${name}_dot_legend_icon`} />
          </a>
          <div className="title-typography font-family-sukhamaitext font-weight-700">
            {legendLabel ?? CALENDAR_DATA_LABELS[name].legendLabel}
          </div>
        </div>
        <div className="graph-title-value-container">
          <div className="title-typography font-family-sukhamaitext font-weight-700">
            {value ? Math.round(value).toLocaleString() : '-'}
          </div>
          <div className="graph-title-value-unit">
            {unit ?? CALENDAR_DATA_LABELS[name].unit}
          </div>
        </div>
      </div>
      )}
    </div>
    {date && (
      <div className="graph-title-container-content-date">
        <span className="dim-typography font-family-sukhamaitext">
          {thaiDate(date, "eeeeee, dd MMM yy").replace('.', '')}
        </span>
      </div>)}
  </>
  );
}
