import React, { useState,useEffect } from 'react';
import './SliderAssessment.scss';

const SliderAssessment = ({sliderData, onSlide}) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        handleChange(0);
    }, [sliderData]);
    
    const handleChange = (val) => {
      setValue(parseInt(val));
      onSlide(parseInt(val));
    };

    const _renderSliderLabel =() => {
        return (
            sliderData.map((item, index) => (
                <div key={index} className={`range-label ${parseInt(index) === parseInt(value) ? 'range-hide-dot' : ''}`}>
                  <span className='range-label-text'>{item.value}</span>
                </div>
            ))
        )
    }

    const _renderSliderDots = () => {
        return (
            sliderData.map((item, index) => (
                <span key = {index} className={`range-dots ${parseInt(index) === parseInt(value) ? 'range-hide-dot' : ''}`}></span>
            ))
        )
    }

    return(
        <div className="slider-container">
            <input className={`custom-range-slider`} onChange={(e)=>handleChange(e.target.value)} 
              type="range" min="0" max={sliderData.length - 1} step="1" value={value}/>
            <span className='custom-range-label'>{_renderSliderLabel()}</span>
            {/* <span className="custom-range-dots">{_renderSliderDots()}</span> */}
        </div>
    );
};

export default SliderAssessment;
