import React, { useEffect, useRef } from 'react';
import Select from 'react-select';

import './SelectorAddress.scss';

export const SelectorAddress = ({ option, fetch, onChange, stateValue, placeholder }) => {
  const selectRef = useRef();

  useEffect(() => {
    const value = option.find(
      (item) => stateValue && item.data.refCode === stateValue.refCode,
    );
    if (selectRef.current && selectRef.current.select)
      stateValue
        ? selectRef.current.select.setValue(value, 'select-option')
        : selectRef.current.select.clearValue();
  }, [stateValue, option]);

  return (
    <Select
      ref={selectRef}
      placeholder={placeholder}
      classNamePrefix="promotion-react-select"
      options={option}
      onChange={(data) => {
        if (data) {
          onChange(data.data);
        }
      }}
      onInputChange={(input) => {
        if (input.length >= 3) {
          fetch(input);
        }
      }}
    />
  );
};
