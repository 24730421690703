import React, { useEffect, useState } from 'react';
import './ChallengeDefault.scss';
import HnwFooter from '../../../common/hnwFooter/HnwFooter';
import { noChallengeText } from '../../../common/challengeConstants';


const ChallengeDefault = () => {
  return (
    <>
    <div className='challenge-default-main'>
      <div className="bg-overlay">
        <div className='challenge-default-text'>
          <span>{noChallengeText?.top}</span>
          <span>{noChallengeText?.middle}</span>
          <span className='text-large'>{noChallengeText?.bottom}</span>
        </div>
      </div>
    </div>
    <HnwFooter />
    </>
  );
};

export default ChallengeDefault;