import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';

import { otpFetch, verifyOTPWithoutUpdate } from '../../../actions';
import { OTPInput, Modal } from '../../../helpers';

import './Otp.scss';
import { commonConstant } from '../../../common';

export class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      invalid: false,
      initialLoading: true,
      invalidCount: 0,
      showModal: false,
      redirect: false,
      verified: false,
    };

    this.otpRef = createRef();
  }

  componentDidMount() {
    if (isEmpty(this.props.customer)) {
      this.props.history.push(commonConstant.pathHome);
    }
    this.props.otpFetch(this.props.customer.amwayNumber);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.otp, this.props.otp)) {
      this.setState({
        loading: false,
        initialLoading: false,
      });
    }
    if (!isEqual(prevProps.verifyOtp, this.props.verifyOtp)) {
      if (this.props.verifyOtp.code === 200) {
        this.setState({ verified: true, loading: false });
      } else {
        const { invalidCount } = this.state;
        if (invalidCount < 3) {
          this.setState(state => ({
            loading: false,
            invalid: true,
            invalidCount: state.invalidCount + 1,
          }));
          this.otpRef.current.clear();
        } else {
          this.otpRef.current.clear();
          this.setState({
            showModal: true,
          });
        }
      }
    }
  }

  handleSubmitOtp = (otp, props) => {
    const { isFilled } = props;
    if (isFilled) {
      this.props.verifyOTPWithoutUpdate({
        otp,
        'accountId': this.props.customer.amwayNumber,
      });
      this.setState({ loading: true });
    }
  }

  handleFetchOtp = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      this.otpRef.current.clear();
    }, 2000);
    this.props.otpFetch(this.props.customer.amwayNumber);
  }

  render() {
    const { handleSubmitOtp, handleFetchOtp } = this;
    const { loading, invalid, initialLoading, showModal, redirect, verified } = this.state;
    if (initialLoading) {
      return <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />;
    }
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <div className="regisPage otp-container">
        <div className="header">
          <img src="/images/upgradeMember/otp-header.png" />
          <h2 >กรอกรหัส OTP เพื่อยืนยัน</h2>
        </div>

        {
          showModal && <Modal
            title="คุณกรอกรหัส OTP ผิดเกินกำหนด"
            content="กรุณาติดต่อ Amway Contact Center"
            type="warning"
            fnOk={() => window.location = 'tel:027258000'}
            fnCancel={() => this.props.history.replace(commonConstant.pathAbo)}
            okText="โทร"
            cancleText="ยกเลิกรายการ" />
        }
        <div className="otp-content">
          <div>ระบบได้ส่งรหัส OTP ไปยังเบอร์โทรศัพท์มือถือ</div>
          <div>{this.props.otp.phoneNumber}</div>

          <OTPInput
            loading={loading}
            ref={this.otpRef}
            invalid={invalid}
            count={4}
            onChange={invalid ? () => this.setState({ invalid: false }) : null}
            onLastInput={(val, props) => handleSubmitOtp(val, props)}
            resendFn={handleFetchOtp}
            resendBtn={true}
            delay={true}
            resendDelay={60}
            invalidMessage="รหัส OTP ไม่ถูกต้องกรุณาลองอีกครั้ง"
            disable={verified}
          />

        </div>
        <div className="otp-footer">
          <a className="link" onClick={() => this.setState({ redirect: commonConstant.pathUpgradePhoneChange })}>เปลี่ยนเบอร์โทรศัพท์</a>
          <button
            className={`away-button ${(verified && !redirect) && 'active'}`}
            disabled={!verified || redirect}
            onClick={() => this.setState({ redirect: commonConstant.pathUpgradeChooseMag })}
          >ยืนยัน</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ otp, verifyOtp, customer }) => ({
  otp,
  verifyOtp,
  customer,
});

const mapDispatchToProps = (dispatch) => ({
  otpFetch: uid => dispatch(otpFetch(uid)),
  verifyOTPWithoutUpdate: params => dispatch(verifyOTPWithoutUpdate(params)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
