import config from '../config';
import AxiosPromotion from './promotion/index';

export const getSOPList = async () => {
  const result = await AxiosPromotion.get(`${config.API_URL}/sop/getAll`,
  {
      currentPage: 1,
      perPage: 100,
  }
  );
  return result.data;
};

export const getSOPDetail = async (id, cartId) => {
  const result = await AxiosPromotion.get(
    `${config.API_URL}/sop/product/${id}?cartId=${cartId}`
  );
  return result.data;
};

export const getSOPSolutionPlans = async() => {
  const result = await AxiosPromotion.get(`${config.API_URL}/sop/solutionplans`)
  return result.data;
}

export const getSubscriptionOptions = async() => {
  const result = await AxiosPromotion.get(`${config.API_URL}/sop/options`)
  return result.data;
}

export const getSOPSolutionProductList = async(productId, currentPage, cartId) => {
  const result = await AxiosPromotion.get(`${config.API_URL}/sop/solutionproducts/${productId}?cartId=${cartId}`,
  {
    currentPage,
    perPage:100
  })
  return result.data;
}

export const getSOPProfileList = async(filter, cartId) => {
  const result = await AxiosPromotion.get(`${config.API_URL}/sop/profile?filter=${filter}&cartId=${cartId}`,
  {
    currentPage:1,
    perPage:1000
  })
  return result.data;
}

export const getSOPProfileDetails = async(subscriptionID, profileId, cartId) => {
  const result = await AxiosPromotion.get(`${config.API_URL}/sop/profile/${subscriptionID}?profileId=${profileId}&cartId=${cartId}`,
  {
    currentPage:1,
    perPage:1000
  })
  return result.data;
}

export const getSOPProfileToCart = async (cartId, orderEntries) => {
  const response = await AxiosPromotion.post(
    `${config.API_URL}/promotion/cart/item`,
    { cartId, orderEntries },
  );
  return response.data;
}

export const addSOPSubscription = async (payload) => {
  const result = await AxiosPromotion.post(
    `${config.API_URL}/sop/subscription-cart`,
    payload,
  );
  return result.data;
};

export const addSolutionSOPSubscription = async (payload) => {
  const result = await AxiosPromotion.post(
    `${config.API_URL}/sop/solution-subscription-cart`,
    payload,
  );
  return result.errorCode ? { message: result.errorCode } : result.data;
};