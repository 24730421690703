import { FETCH_HNW_HEALTH_GOAL_LIST, DELETE_GOAL, UPDATE_HEALTH_LIST } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_HNW_HEALTH_GOAL_LIST:
      return {
        ...state,
        healthGoalList: action.payload,
      };
    case DELETE_GOAL:
      return {
        ...state,
        healthGoalList: {
          ...state.healthGoalList,
          [action.payload.goalType]: null,
        },
      };
    case UPDATE_HEALTH_LIST:
      return { ...state, healthGoalList: action.payload }
    default:
      return state;
  }
}
