import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Container, Wrapper, Canvas, Cam, ErrorMsg } from './styles';
import { CAMERA_CC_VAR } from '../../../commonConstant';

export const Camera = React.forwardRef(
  (
    {
      facingMode = 'user',
      aspectRatio = 'cover',
      numberOfCamerasCallback = () => null,
      videoSourceDeviceId = undefined,
      errorMessages = {
        noCameraAccessible: CAMERA_CC_VAR.NO_CAMERA_ACCESSIBLE_ERR,
        permissionDenied: CAMERA_CC_VAR.PERMISSION_DENIED_ERR,
        switchCamera: CAMERA_CC_VAR.SWITCH_CAMERA_ERR,
        canvas: CAMERA_CC_VAR.CANVAS_NOT_SUPPORTED_ERR,
      },
      videoReadyCallback = () => null,
      setImage = () => {},
    },
    ref,
  ) => {
    const player = useRef(null);
    const canvas = useRef(null);
    const context = useRef(null);
    const container = useRef(null);
    const [numberOfCameras, setNumberOfCameras] = useState(0);
    const [stream, setStream] = useState(null);
    const [currentFacingMode, setFacingMode] = useState(facingMode);
    const [notSupported, setNotSupported] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [torchSupported, setTorchSupported] = useState(false);
    const [torch, setTorch] = useState(false);
    const mounted = useRef(false);

    useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);

    useEffect(() => {
      numberOfCamerasCallback(numberOfCameras);
    }, [numberOfCameras]);

    const switchTorch = async (on = false) => {
      if (stream && navigator?.mediaDevices && !!mounted.current) {
        const supportedConstraints =
          navigator.mediaDevices.getSupportedConstraints();
        const [track] = stream.getTracks();
        if (supportedConstraints && 'torch' in supportedConstraints && track) {
          try {
            await track.applyConstraints({ advanced: [{ torch: on }] });
            return true;
          } catch {
            return false;
          }
        }
      }

      return false;
    };

    useEffect(() => {
      switchTorch(torch);
    }, [torch]);

    useImperativeHandle(ref, () => ({
      takePhoto: (type) => {
        if (numberOfCameras < 1) {
          throw new Error(errorMessages.noCameraAccessible);
        }

        if (canvas?.current) {
          const playerWidth = player?.current?.videoWidth || 1280;
          const playerHeight = player?.current?.videoHeight || 720;
          const playerAR = playerWidth / playerHeight;

          const canvasWidth = container?.current?.offsetWidth || 1280;
          const canvasHeight = container?.current?.offsetHeight || 1280;
          const canvasAR = canvasWidth / canvasHeight;

          let sX, sY, sW, sH, imgData;

          if (playerAR > canvasAR) {
            sH = playerHeight;
            sW = playerHeight * canvasAR;
            sX = (playerWidth - sW) / 2;
            sY = 0;
          } else {
            sW = playerWidth;
            sH = playerWidth / canvasAR;
            sX = 0;
            sY = (playerHeight - sH) / 2;
          }

          canvas.current.width = sW;
          canvas.current.height = sH;

          if (!context.current) {
            context.current = canvas.current.getContext('2d', {
              willReadFrequently: true,
            });
          }

          if (context.current && player?.current) {
            context.current.drawImage(
              player.current,
              sX,
              sY,
              sW,
              sH,
              0,
              0,
              sW,
              sH,
            );
          }

          switch (type) {
            case 'imgData':
              imgData = context.current?.getImageData(0, 0, sW, sH);
              break;
            case 'toBlob':
              canvas.current.toBlob(
                (blob) => {
                  imgData = blob;
                  setImage(blob);
                },
                'image/jpeg',
                1,
              );
              break;
            case 'toDataURL':
              imgData = canvas.current.toDataURL('image/jpeg');
              break;
            default: /* base64url */
              imgData = canvas.current.toDataURL('image/jpeg');
              break;
          }

          return imgData;
        } else {
          throw new Error(errorMessages.canvas);
        }
      },
      switchCamera: () => {
        if (numberOfCameras < 1) {
          throw new Error(errorMessages.noCameraAccessible);
        } else if (numberOfCameras < 2) {
          console.error(
            'Error: Unable to switch camera. Only one device is accessible.',
          ); // console only
        }
        const newFacingMode =
          currentFacingMode === 'user' ? 'environment' : 'user';
        setFacingMode(newFacingMode);
        return newFacingMode;
      },
      getNumberOfCameras: () => {
        return numberOfCameras;
      },
      toggleTorch: () => {
        const torchVal = !torch;
        setTorch(torchVal);
        return torchVal;
      },
      torchSupported: torchSupported,
    }));

    useEffect(() => {
      initCameraStream(
        stream,
        setStream,
        currentFacingMode,
        videoSourceDeviceId,
        setNumberOfCameras,
        setNotSupported,
        setPermissionDenied,
        !!mounted.current,
      );
    }, [currentFacingMode, videoSourceDeviceId]);

    useEffect(() => {
      switchTorch(false).then((success) => setTorchSupported(success));
      if (stream && player && player.current) {
        player.current.srcObject = stream;
      }
      return () => {
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      };
    }, [stream]);

    return (
      <Container ref={container} aspectRatio={aspectRatio}>
        <Wrapper>
          {notSupported ? (
            <ErrorMsg>{errorMessages.noCameraAccessible}</ErrorMsg>
          ) : null}
          {permissionDenied ? (
            <ErrorMsg>{errorMessages.permissionDenied}</ErrorMsg>
          ) : null}
          <Cam
            ref={player}
            id="video"
            muted
            autoPlay
            playsInline
            onLoadedData={videoReadyCallback}
          ></Cam>
          <Canvas ref={canvas} />
        </Wrapper>
      </Container>
    );
  },
);

Camera.displayName = 'Camera';

const shouldSwitchToCamera = async (currentFacingMode) => {
  const cameras = [];
  if (currentFacingMode === 'environment') {
    await navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter((i) => i.kind == 'videoinput');
      videoDevices.forEach((device) => {
        const capabilities = device.getCapabilities();
        if (
          capabilities.facingMode &&
          capabilities.facingMode.indexOf('environment') >= 0 &&
          capabilities.deviceId
        ) {
          cameras.push(capabilities.deviceId);
        }
      });
    });
  }

  if (cameras.length > 1) {
    return cameras.pop();
  }

  return undefined;
};

const initCameraStream = async (
  stream,
  setStream,
  currentFacingMode,
  videoSourceDeviceId,
  setNumberOfCameras,
  setNotSupported,
  setPermissionDenied,
  isMounted,
) => {
  // stop any active streams in the window
  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  let cameraDeviceId;

  const switchToCamera = await shouldSwitchToCamera(currentFacingMode);
  if (switchToCamera) {
    cameraDeviceId = switchToCamera;
  } else {
    cameraDeviceId = videoSourceDeviceId
      ? { exact: videoSourceDeviceId }
      : undefined;
  }

  const constraints = {
    audio: false,
    video: {
      deviceId: cameraDeviceId,
      facingMode: currentFacingMode,
    },
  };

  if (navigator?.mediaDevices?.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        if (isMounted) {
          setStream(handleSuccess(stream, setNumberOfCameras));
        }
      })
      .catch((err) => {
        handleError(err, setNotSupported, setPermissionDenied);
      });
  } else {
    const getWebcam =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (getWebcam) {
      getWebcam(
        constraints,
        async (stream) => {
          if (isMounted) {
            setStream(handleSuccess(stream, setNumberOfCameras));
          }
        },
        (err) => {
          handleError(err, setNotSupported, setPermissionDenied);
        },
      );
    } else {
      setNotSupported(true);
    }
  }
};

const handleSuccess = (stream, setNumberOfCameras) => {
  navigator.mediaDevices
    .enumerateDevices()
    .then((r) =>
      setNumberOfCameras(r.filter((i) => i.kind === 'videoinput').length),
    );

  return stream;
};

const handleError = (error, setNotSupported, setPermissionDenied) => {
  console.error(error);
  if (error.name === 'PermissionDeniedError') {
    setPermissionDenied(true);
  } else {
    setNotSupported(true);
  }
};
