import React, { useState } from 'react';
import { Checkbox } from '../../helpers';
import './CartPromotion.scss';

export const PaymentOption = (props) => {
  const [selected, setSelected] = useState();

  const handleSelect = (option) => {
    setSelected(option.title);
    props.setPaymentOptionSelected(option);
  };

  const displayOptions = () => {
    return props.paymentOptions.map((option, index) => {
      const isSelected = option.title === selected;
      const selectStyle = isSelected ? 'select' : '';
      return (
        <section
          className={`flex payment-option-container mb-10 ${selectStyle}`}
          key={index}
          onClick={() => handleSelect(option)}
        >
          <Checkbox checkmarkColor={'#a93131'} checked={isSelected} />
          <div className="font-bold font-stock">{option.title}</div>
        </section>
      );
    });
  };

  if (!props.paymentOptions) {
    return <></>;
  }

  return (
    <section>
      <div className="pd-20">{displayOptions()}</div>
    </section>
  );
};

export default PaymentOption;
