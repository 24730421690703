import React from 'react';
import './HnwTabs.scss';

const HnwTabs = ({ typeList, activeTab, onClick }) => <div className="hnw-tabs">
  {typeList.map((tabItem) =>
    <div
      key={tabItem.tabLabel}
      onClick={() => onClick(tabItem)}
      className={`htw-tab-item${activeTab.tabLabel === tabItem.tabLabel ? ' --active' : ''}`}>
      {tabItem.tabLabel}
    </div>)}
</div>

export default HnwTabs;