import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { CalendarLoader, HnwSetterCard, HnwSuccessOverlayModal } from '../../../../common';
import { fetchGoalsNutrition, updateGoalsNutrition } from '../../../../actions/hnwGoalsActions';
import { getDefaultValues } from '../../../../services/healthWellness/hnwGoals.service';
import { getCustomerAge, getCustomerGender } from '../../../../services/healthWellness/healthWellness.service';
import { getDataFromSession } from '../../../../utill.func';
import { GENDER, GOALS_NUTRITION_DETAILS, GOALS_SUBMIT_BUTTON_TITLE, HEALTH_GOAL_TYPES, HNW_GOAL_SETTING, } from '../../../../common/commonConstant';
import { fetchBmiData, getHnwAllHealhGoalList } from '../../../../actions';
import './NutritionGoal.scss';
import { round } from '../../../../services';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();

export default function NutritionGoal({ routeTo, routeReplaceTo }) {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isApiHit, setIsApiHit] = useState(false);
  const [loading, setLoading] = useState(false);
  const bmi = useSelector((state) => state.bmi);
  const NutritionGoalData = useSelector((state) => state.hnwGoals.nutritionGoal);
  const healthGoals = useSelector((state) => state.goalSetting.healthGoalList);

  const customerGender = Object.keys(GENDER).find((item) => GENDER[item] === getCustomerGender());
  const customerAge = getCustomerAge();

  const defaultValues = getDefaultValues(bmi, customerAge, customerGender);

  const {
    targetCalories: { min: targetCaloriesMin, max: targetCaloriesMax },
    targetCarbs: { min: targetCarbsMin, max: targetCarbsMax },
    targetFat: { min: targetFatMin, max: targetFatMax },
    targetProtein: { min: targetProteinMin, max: targetProteinMax },
  } = GOALS_NUTRITION_DETAILS.GOAL_NUTRTION_MAPPING;

  const defaultValue = {
    targetCalories: round(defaultValues.defaultCalories, 0) || 0,
    targetProtein: round(defaultValues.defaultProtein, 0) || 0,
    targetCarbs: round(defaultValues.defaultCarbs, 0) || 0,
    targetFat: round(defaultValues.defaultFat, 0) || 0,
  };

  const [nutritionGoalVal, setNutritionGoalVal] = useState(defaultValue);

  const { targetCalories, targetCarbs, targetFat, targetProtein } = nutritionGoalVal;

  const isCaloriesOutsideLimit = +targetCalories < +targetCaloriesMin || +targetCalories > +targetCaloriesMax;
  const isCarbsOutsideLimit = +targetCarbs < +targetCarbsMin || +targetCarbs > +targetCarbsMax;
  const isFatOutsideLimit = +targetFat < +targetFatMin || +targetFat > +targetFatMax;
  const isProteinOutsideLimit = +targetProtein < +targetProteinMin || +targetProtein > +targetProteinMax;

  const isSubmitDisabled = isCaloriesOutsideLimit || isCarbsOutsideLimit || isFatOutsideLimit || isProteinOutsideLimit;

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const nutritionId = params.get(`${HEALTH_GOAL_TYPES.NUTRITION}_id`);
  const activeFlow = params.get("activeFlow");

  const dispatch = useDispatch();

  const handleSuccessRedirect = () => {
    if (parseInt(activeFlow) === 1) {
      const goalId = healthGoals?.[HEALTH_GOAL_TYPES.WATER]?.id || null
      routeReplaceTo('pathHnwGoalsWater', goalId ? `?${HEALTH_GOAL_TYPES.WATER}_id=${goalId}&activeFlow=${activeFlow}` : `?activeFlow=${activeFlow}`);
    } else {
      routeTo('pathHnwGoalsHome');
    }
    setIsApiHit(false);
  };

  const handleGoalValueChange = (newVal, name) => {
    setNutritionGoalVal({ ...nutritionGoalVal, [name]: round(newVal) });
  }

  useEffect(() => {
    if (!nutritionId) {
      setNutritionGoalVal(defaultValue);
    }
  }, [defaultValue.targetCalories])

  useEffect(() => {
    if (NutritionGoalData && Object.keys.length) {
      setNutritionGoalVal(NutritionGoalData);
      isApiHit && setShowSuccess(true);
    }
  }, [NutritionGoalData]);

  useEffect(() => {
    if (!bmi?.bmi) {
      setLoading(true);
      dispatch(fetchBmiData())
        .then(() => setLoading(false));
    }
    if (nutritionId) {
      dispatch(fetchGoalsNutrition(nutritionId))
        .finally(() => setLoading(false));
    }
    if (parseInt(activeFlow) === 1) {
      const customer = getDataFromSession(
        HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
      );
      if (customer?._id) {
        dispatch(getHnwAllHealhGoalList(customer?._id))
      }
    }
  }, []);

  const handleSubmitNutritionGoal = () => {
    setIsApiHit(true);
    setLoading(true);
    dispatch(updateGoalsNutrition({
      nutritionId, data: {
        targetCalories: +targetCalories,
        targetProtein: +targetProtein,
        targetCarbs: +targetCarbs,
        targetFat: +targetFat
      },
    })).finally(() => setLoading(false));
  };

  return (
    <>
      <div className="hnw-goals-nutrition-container">
        <img src="/images/healthAndWellness/goals/nutrition/goalSettingHeaderNutrition.svg"
          alt="header-image" className="hnw-goals-nutrition-header" />
        <div className="hnw-goals-nutrition-details-container">
          <div className="hnw-goals-nutrition-details-wrapper">
            {Object.entries(GOALS_NUTRITION_DETAILS.GOAL_NUTRTION_MAPPING).map(([name, item]) => (
              <HnwSetterCard
                name={name}
                headerText={item.heading}
                unit={`${item.unit} ${nutritionGoalVal[name] === defaultValue[name] ? '(แนะนำ)' : ''}`}
                defaultValue={`${round(defaultValue[name])}`}
                resetEnabled={`${nutritionGoalVal[name]}` !== `${defaultValue[name]}`}
                value={round(nutritionGoalVal[name], 0)}
                onValueChange={handleGoalValueChange}
                maxValue={item.max}
                minValue={item.min}
                stepperVal={item.stepper}
                footerEnabled={`${nutritionGoalVal[name]}` !== `${defaultValue[name]}`}
                footerTextArr={[GOALS_NUTRITION_DETAILS.SETTER_CARD_FOOTER_FIRST, GOALS_NUTRITION_DETAILS.SETTER_CARD_FOOTER_SECOND]}
              />))}
          </div>
          <div className="hnw-goals-nutrition-details-suggestion">
            <div className="hnw-goals-nutrition-details-suggestion-description">
              <ul>
                <li>{GOALS_NUTRITION_DETAILS.SUGGESTION_LIST_FIRST}</li>
                <li>{GOALS_NUTRITION_DETAILS.SUGGESTION_LIST_SECOND_LEFT}
                  <img src="/images/healthAndWellness/goals/common/stepper_left_icon.svg" />
                  <img src="/images/healthAndWellness/goals/common/stepper_right_icon.svg" />
                  {GOALS_NUTRITION_DETAILS.SUGGESTION_LIST_SECOND_RIGHT}</li>
                <li>{GOALS_NUTRITION_DETAILS.SUGGESTION_LIST_THIRD}</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div className="hnw-goals-nutrition-footer">
        <img src="/images/healthAndWellness/goals/goalSettingFooter.svg" alt="footer-image" />
        <button disabled={isSubmitDisabled} className="btn" onClick={handleSubmitNutritionGoal}>{GOALS_SUBMIT_BUTTON_TITLE}</button>
      </div>
      <CalendarLoader showLoading={loading} />
      {showSuccess && <HnwSuccessOverlayModal show={showSuccess} onTimeout={handleSuccessRedirect} />}
    </>
  );
};