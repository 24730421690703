import React from 'react';
import './hnwFoodCalorieChart.scss';

const FoodCalorieChart = ({ foodData, foodDetails, multiplyFactor, calculateScale, icon }) => {

    const percentCalculation = () => {
        const value = calculateScale(foodDetails[foodData.name], multiplyFactor) || foodDetails[foodData.name]
        if (value) {
            if (foodData.name !== 'carbs') {
                if (value > 50) {
                    return 100;
                }
                return (value / 50) * 100;
            }
            if (value > 100) { 
                return 100;
            }
            return value;
        }
        return 0;
    }

  return (
    <div className="fc-percent">
      <svg>
        <circle cx="37" cy="37" r="32" />
        <circle cx="37" cy="37" r="32" style={{ '--percent': percentCalculation() }} />
      </svg>
      <img src={`/images/healthAndWellness/foodCalories/icons/${icon}.svg`} alt={icon} />
      <div className="number">
        <div className="progress-value">
          <div className="progress-value-inner">{calculateScale(foodDetails[foodData.name], multiplyFactor) || foodDetails[foodData.name] || 0}</div>
          <div className="progress-value-unit">กรัม</div>
        </div>
      </div>
    </div>
  );
};

export default FoodCalorieChart;
