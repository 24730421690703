import { FETCH_AUTH_TOKEN_FAIL, FETCH_AUTH_TOKEN_SUCCESS } from "../actions/types";

  
  const initialState = {
    isAuthTokenSaved: false,
  };
  
  const rootReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_AUTH_TOKEN_SUCCESS:
        return {
          ...state,
          isAuthTokenSaved: true,
        };
      case FETCH_AUTH_TOKEN_FAIL:
        return {
          ...state,
        };
        return {
          ...state,
          customerData: null,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default rootReducer;
  