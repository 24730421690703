import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import "./abudyassesment.scoped.scss";
import axios from "axios";

import Card from 'react-bootstrap/Card';
import Spinner from '../components/Spinner'
import { useSwipeable } from 'react-swipeable';
import { UserOutlined } from '@ant-design/icons';
import Slider from "react-slick";
import { Collapse, Avatar } from 'antd';

import LoadingOverlay from 'react-loading-overlay';




const { Panel } = Collapse;



const Abudyassesment = (props) => {
    let [loading2, setLoading2] = useState(false);
    const history = useHistory();
    const [title, settitle] = useState(false);
    let User = localStorage.getItem("User");
    let assesment_id = localStorage.getItem("assesment_id")
    const cover_user = User ? JSON.parse(User) : '';
    const Assesment_id = assesment_id ? assesment_id : '';
    const [listAssesment, setlistAssesment] = useState([
    ]);

    // const data = [
    //     {
    //         "suggestions_solutions": [
    //             {
    //                 "_id": "6476ef668d5b4719c4f9b475",
    //                 "title": "test",
    //                 "type": "free",
    //                 "content": "test",
    //                 "image_path": "/assessmsnt/1685516130062-PD.png",
    //                 "url": "https://lbc-cms.preprod.sea.amway.net/assessmsnt/1685516130062-PD.png",
    //                 "price": "400",
    //                 "link": "",
    //                 "status": "active",
    //                 "createdDate": "2023-05-31T06:55:34.991Z",
    //                 "updatedDate": "2023-05-31T06:55:34.991Z"
    //             },
    //             {
    //                 "_id": "6476e30cd2992d1573d2e3c0",
    //                 "title": "test",
    //                 "type": "cvd",
    //                 "content": "test CVD",
    //                 "image_path": "/assessmsnt/1685512968658-PD.png",
    //                 "url": "https://lbc-cms.preprod.sea.amway.net/assessmsnt/1685512968658-PD.png",
    //                 "price": "700",
    //                 "link": "",
    //                 "status": "active",
    //                 "createdDate": "2023-05-31T06:02:52.915Z",
    //                 "updatedDate": "2023-05-31T06:02:52.915Z"
    //             },
    //             {
    //                 "_id": "6476e29b35afee150de633a8",
    //                 "title": "สินค้า 1",
    //                 "type": "mobility",
    //                 "content": "สินค้าMobi",
    //                 "image_path": "/assessmsnt/1685512853479-PD.png",
    //                 "url": "https://lbc-cms.preprod.sea.amway.net/assessmsnt/1685512853479-PD.png",
    //                 "price": "500",
    //                 "link": "",
    //                 "status": "active",
    //                 "createdDate": "2023-05-31T06:00:59.277Z",
    //                 "updatedDate": "2023-05-31T06:00:59.277Z"
    //             }
    //         ],
    //         "_id": "6476fddc2cd02f206f7e3d9e",
    //         "abuddys": {
    //             "_id": "6476f05c65622e1a2aeb8a7a",
    //             "uid": "Uc8c92a4a90b4fe2c17fb310f93986340",
    //             "displayName": "Kingdombomvenom",
    //             "pictureUrl": "https://profile.line-scdn.net/0h0FmLvuLGb2lANXq7MqsRFjBlbANjRDZ7b1QoXSEzY198AX04OFV1BnE3Y1p6AChoaFAkBnQyNl1MJhgPXmOTXUcFMVx1AiA7a1Mhhg",
    //             "roomId": "Ca8936f7bb3477822c0f8c99462cc91aa",
    //             "createdDate": "2023-05-31T06:59:40.084Z",
    //             "updatedDate": "2023-05-31T06:59:40.084Z"
    //         },
    //         "customers": "5d846662d049752dad116f03",
    //         "assessments": "6476d30307e4be0d13172920",
    //         "createdDate": "2023-05-31T07:57:16.182Z",
    //         "updatedDate": "2023-05-31T07:57:16.182Z"
    //     },
    //     {
    //         "suggestions_solutions": [
    //             {
    //                 "_id": "6476ef668d5b4719c4f9b475",
    //                 "title": "test",
    //                 "type": "free",
    //                 "content": "test",
    //                 "image_path": "/assessmsnt/1685516130062-PD.png",
    //                 "url": "https://lbc-cms.preprod.sea.amway.net/assessmsnt/1685516130062-PD.png",
    //                 "price": "400",
    //                 "link": "",
    //                 "status": "active",
    //                 "createdDate": "2023-05-31T06:55:34.991Z",
    //                 "updatedDate": "2023-05-31T06:55:34.991Z"
    //             },
    //             {
    //                 "_id": "6476e30cd2992d1573d2e3c0",
    //                 "title": "test",
    //                 "type": "cvd",
    //                 "content": "test CVD",
    //                 "image_path": "/assessmsnt/1685512968658-PD.png",
    //                 "url": "https://lbc-cms.preprod.sea.amway.net/assessmsnt/1685512968658-PD.png",
    //                 "price": "700",
    //                 "link": "",
    //                 "status": "active",
    //                 "createdDate": "2023-05-31T06:02:52.915Z",
    //                 "updatedDate": "2023-05-31T06:02:52.915Z"
    //             },
    //             {
    //                 "_id": "6476e29b35afee150de633a8",
    //                 "title": "สินค้า 1",
    //                 "type": "mobility",
    //                 "content": "สินค้าMobi",
    //                 "image_path": "/assessmsnt/1685512853479-PD.png",
    //                 "url": "https://lbc-cms.preprod.sea.amway.net/assessmsnt/1685512853479-PD.png",
    //                 "price": "500",
    //                 "link": "",
    //                 "status": "active",
    //                 "createdDate": "2023-05-31T06:00:59.277Z",
    //                 "updatedDate": "2023-05-31T06:00:59.277Z"
    //             }
    //         ],
    //         "_id": "647f6308f1ab503377cf2655",
    //         "abuddys": {
    //             "_id": "647f6117f1ab503377cf264d",
    //             "uid": "U638e97fb0e731160b7a2374c51f45d70",
    //             "displayName": "Babe",
    //             "pictureUrl": "https://profile.line-scdn.net/0hzXse_UQNJXdeHDdF4n1bCC5MJh19bXxlcH5tRD4ec083KGZ2eipoRm0ffxBrKWcicHM4RD5MckNSD1IRQErZQ1kse0JrK2oldXprmA",
    //             "roomId": "Ca8936f7bb3477822c0f8c99462cc91aa",
    //             "createdDate": "2023-06-06T16:38:47.452Z",
    //             "updatedDate": "2023-06-06T16:38:47.452Z"
    //         },
    //         "customers": "5d846662d049752dad116f03",
    //         "assessments": "6476d30307e4be0d13172920",
    //         "createdDate": "2023-06-06T16:47:04.564Z",
    //         "updatedDate": "2023-06-06T16:47:30.820Z"
    //     }
    // ]


    useEffect(() => {
        getStore();
    }, []);
    const getStore = () => {
        const liffProfile = localStorage.getItem("Profile");
        let User = localStorage.getItem("assesment_id");
        setLoading2(true);
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL_UAT}/assessment/result-solution-downline/${User}`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${cover_user.token}`
            }
        })
            .then((res) => {
                setLoading2(false);
                let title = localStorage.getItem('title')

                settitle(title)
                console.log(res.data.data);
                setlistAssesment(res.data.data);
            })
            .catch((err) => {
                setLoading2(false);
            });
    };
    const PageAssesment = () => {

        if (cover_user.type == "member") {
            history.push("/assesment");
        } else if (cover_user.type == "abuddy") {
            let roomidsestion = localStorage.getItem("roomid");
            const normalizedRoomid = roomidsestion.replace(/\"/g, "");
            history.push("/assesment?roomId=" + normalizedRoomid);

        }
    }

    const settings = {
        dots: false,
        arrow: false, // Set to false to remove the arrows
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,

    };





    const handlers = useSwipeable({
        // onSwipedLeft: () => {
        //    
        // },
        onSwipedRight: () => {
            if (cover_user.type == "member") {
                history.push("/assesment");
            } else if (cover_user.type == "abuddy") {
                let roomidsestion = localStorage.getItem("roomid");
                const normalizedRoomid = roomidsestion.replace(/\"/g, "");
                history.push("/assesment?roomId=" + normalizedRoomid);

            }


        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });
    const avatarStyle = {
        marginRight: '8px', // Adjust the spacing as desired
        fontSize: '24px', // Increase the font size for a larger Avatar
    };
    return (
        <>

            <div className="page overflow_disable" style={{ background: '#F7F8FB', minHeight: '100vh', overflowX: 'hidden' }}>
                <div className="head-page-market pb-3 container2">
                    <div className="row no-pad">
                        <div className="col-2" style={{ position: 'absolute', left: '25px' }}
                            onClick={() => {
                                PageAssesment()
                            }}
                        >
                            <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.35031 0.7256C7.64636 0.4248 8.11244 0.4248 8.40848 0.7256C8.69453 1.01624 8.69453 1.49995 8.40848 1.78991L3.04292 7.24157H19.7485C20.1613 7.24157 20.5 7.57488 20.5 7.99424C20.5 8.4136 20.1613 8.75776 19.7485 8.75776H3.04292L8.40848 14.1992C8.69453 14.5 8.69453 14.9844 8.40848 15.2744C8.11244 15.5752 7.64636 15.5752 7.35031 15.2744L0.714536 8.53216C0.428488 8.24152 0.428488 7.7578 0.714536 7.46784L7.35031 0.7256Z" fill="black" />
                            </svg>
                        </div>
                        <div className="center col-8">
                            <center className="title text-center" style={{ fontSize: '3vh', fontFamily: 'Sukhumvit Set Extra Bold' }}>
                                A Buddy
                            </center>
                        </div>
                        <div className="col-2">
                        </div>
                    </div>
                </div>
                <div className="container2" style={{ padding: '40px' }}>
                    <>
                        <Collapse
                            expandIconPosition="end"
                            style={{
                                borderRadius: "15px",
                                boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px"
                            }}
                        >
                            {listAssesment.length > 0 ? (
                                listAssesment.map(panel => (
                                    <Panel
                                        key={panel._id}
                                        header={
                                            <div>
                                                {panel.abuddys.pictureUrl ? (
                                                    <Avatar size={48} src={panel.abuddys.pictureUrl} style={avatarStyle} />
                                                ) : (
                                                    <Avatar size={48} icon={<UserOutlined />} style={avatarStyle} />
                                                )}
                                                <span style={{
                                                    fontFamily: 'Sukhumvit Set tadmai bold',
                                                    fontSize: '15px'
                                                }}>{panel.abuddys.displayName}</span>
                                            </div>
                                        }
                                    >
                                        <div>
                                            <Card style={{ borderRadius: "25px", border: "none", overflow: "hidden" }}>
                                                <span style={{ marginTop: '10px', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px' }}>{title}</span>
                                                <span style={{ fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '17px' }}>Solution Suggest</span>
                                                <Card.Body style={{ borderRadius: "25px", background: "rgba(255, 255, 255, 18%)" }}>
                                                    <Slider {...settings}>
                                                        {panel.suggestions_solutions?.map((data, key) => (
                                                            <div key={key} className="card2" style={{ width: '200px' }}>
                                                                <div className="card-image">
                                                                    <img src={data.url} alt="Product" />
                                                                </div>
                                                                <div className="card-content">
                                                                    <div style={{ textAlign: 'initial' }}><span>{data.content}</span></div>
                                                                    <div className="price"><span> {data.price}</span></div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Panel>
                                ))
                            ) : (
                                <p className="notdata">ยังไม่มีรายการ</p>
                            )}
                        </Collapse>



                    </>
                </div >
                <LoadingOverlay active={loading2} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
            </div >

        </>
    );
};

export default Abudyassesment;