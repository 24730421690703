import React from "react";
import { FoodCategoryCarousel } from "../../../components/healthAndWellness/foodCalories/components";
import { formatNumberWithCommas, removeDisableForRedirection } from "../../../utill.func";
import { BadgeCarousel } from "../../index";
import './BadgeRectCard.scss';

const BadgeRectCard = ({ cards, title, badgeImage, badges, values, maxBadgeValue, unit, redirectToBadgeDetail }) => {

    const renderBadgeImage = () => {
        return (
            <div className="badge-img-container">
                <img src={`/images/challenge/badges/bg-circle-badge@5x.png`} className='badge-img-bg'/>
                <img src={badgeImage} className='badge-img' onClick={redirectToBadgeDetail}/>
            </div>
        )
    }

    const handleBadgeDetailRedirection = (badges, event) => {
        event.stopPropagation();
        const checkBadgePath = removeDisableForRedirection(badges);
        redirectToBadgeDetail(checkBadgePath, 'steps');
    }

    const renderBadgesCarousel = () => {
        return (
            <div className="badge-carousel-container">
                <BadgeCarousel
                    data={badges}
                    onClick={handleBadgeDetailRedirection}
                />
            </div>
        )
    }

    return (
        <div className="badge-rect-container">
            <div className="badge-title-font">{title}</div>
            {cards ? renderBadgeImage() : renderBadgesCarousel()}
            <div className="badge-values">
                <span className={`badge-current-values ${values > 0 ? 'green-font' : ''}`}>{formatNumberWithCommas(values)}</span>
                <span className="badge-total-values">{maxBadgeValue}</span>
                <span className="badge-unit">{unit}</span>
            </div>
        </div>
    )
}

export default BadgeRectCard;