import {
  getChallengeImages,
  getPreSignedUrl,
  putConfirmUpload,
  putPreSignedUrl,
} from '../../services/challenge/challengeImageUpload.service';
import {
  GET_USER_UPLOADED_IMAGES,
  GET_USER_UPLOADED_IMAGES_SUCCESS,
  GET_USER_UPLOADED_IMAGES_ERROR,
  GET_PRE_SIGNED_URL_SUCCESS,
  GET_PRE_SIGNED_URL_ERROR,
  PUT_PRE_SIGNED_URL_SUCCESS,
  PUT_PRE_SIGNED_URL_ERROR,
  PUT_CONFIRM_UPLOAD_SUCCESS,
  PUT_CONFIRM_UPLOAD_ERROR,
} from '../types';

export const getChallengeImagesAction = (params) => (dispatch) => {
  dispatch({ type: GET_USER_UPLOADED_IMAGES });
  return getChallengeImages(params)
    .then((result) => {
      dispatch({
        type: GET_USER_UPLOADED_IMAGES_SUCCESS,
        payload: result,
      });
      return result;
    })
    .catch((error) => {
      dispatch({
        type: GET_USER_UPLOADED_IMAGES_ERROR,
        payload: error.message,
      });
    });
};

export const getPreSignedUrlAndUploadAction = (payload) => (dispatch) => {
  return getPreSignedUrl(payload)
    .then((preSignedUrlResponse) => {
      dispatch({
        type: GET_PRE_SIGNED_URL_SUCCESS,
        payload: preSignedUrlResponse,
      });
      return putPreSignedUrl({
        ...payload,
        preSignedUrl: preSignedUrlResponse.url,
      })
        .then((response) => {
          dispatch({
            type: PUT_PRE_SIGNED_URL_SUCCESS,
            payload: preSignedUrlResponse,
          });
          return putConfirmUpload({...payload, path: preSignedUrlResponse.path})
            .then((confirmUploadResponse) => {
              dispatch({
                type: PUT_CONFIRM_UPLOAD_SUCCESS,
                payload: confirmUploadResponse,
              });
              return confirmUploadResponse;
            })
            .catch((error) => {
              dispatch({
                type: PUT_CONFIRM_UPLOAD_ERROR,
                payload: error.message,
              });
            });
        })
        .catch((error) => {
          dispatch({
            type: PUT_PRE_SIGNED_URL_ERROR,
            payload: error.message,
          });
        });
    })
    .catch((error) => {
      dispatch({
        type: GET_PRE_SIGNED_URL_ERROR,
        payload: error.message,
      });
    });
};
