
import React, { useState, useEffect } from 'react';
import { getSOPSolutionPlans, getSOPSolutionProductList, addSolutionSOPSubscription } from '../../../services/sop-sub.service';
import { commonConstant } from '../../../common';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import LoadingOverlay from 'react-loading-overlay';
import { SolutionHeader, SolutionItem, SolutionFooter, SolutionFooterProduct } from './index';
import { getCart } from '../../../services/promotion/utils.service';

let currentPage = 1;
let sopCategoryId = '';
export const history = createBrowserHistory();

export const SOPSolution = () => {
	const [solutionPlan, setSolutionPlan] = useState([]);
	const [selectedPlanType, setSelectedPlanType] = useState('BASIC');
	const [initalSolutionType, setInitalSolutionType] = useState('BASIC');
	const [solutionList, setSolutionList] = useState([]);
	const [basicMaxValue, setBasicMaxValue] = useState(0);
	const [loading, setLoading] = useState(true);
	const [priceTotal, setPriceTotal] = useState(0);
	const [cart, setCart] = useState({});
	const [accountType, setAccountType] = useState('ABO');
	const [cartEntries, setCartEntries] = useState(0);

	useEffect(() => {
		const didMount = async () => {
			try {
				const urlParams = queryString.parse(history.location.search);
				if (urlParams && urlParams.plantype) {
					setSelectedPlanType(urlParams.plantype.toUpperCase());
					setInitalSolutionType(urlParams.plantype.toUpperCase());
				}
				const cartData = await getCart(); 
        if(cartData && cartData.length) { 
          setCart(cartData[0]);
          setAccountType(cartData[0].account?.accountType);
					let totalEntries = 0;
					let entries = cartData[0].entries.filter((values) => values.quantity);
					entries.forEach(entry => {
						const totalPrice = entry && entry.totalPrice && entry.totalPrice.price ? parseInt(entry?.totalPrice?.price) : 0
						if(entry && !entry.isGiveAway && !entry?.kitEntryCode && totalPrice > 0) {
							totalEntries +=entry.quantity;
						}
					});
					setCartEntries(totalEntries);
        }
		const detail = await getSOPSolutionPlans();
		setSolutionPlan(detail.data);
			} catch (error) { }
		}
		didMount();
	}, []);

	useEffect(() => {
		const didMount = async () => {
			try {
				if (solutionPlan && solutionPlan.length) {
					solutionPlan.forEach(plans => {
						const qualificationRuleType = plans.qualificationRules &&
							plans.qualificationRules.length &&
							plans.qualificationRules[0].qualificationRuleType ?
							plans.qualificationRules[0].qualificationRuleType : '';
						const maxValue = plans.qualificationRules &&
							plans.qualificationRules.length &&
							plans.qualificationRules[0].maxValue ?
							plans.qualificationRules[0].maxValue : 0;
						if (qualificationRuleType.toUpperCase().indexOf(selectedPlanType) >= 0) {
							plans.isSelected = true
						} else {
							plans.isSelected = false;
						}
						if (qualificationRuleType.toUpperCase().indexOf('BASIC') >= 0) {
							setBasicMaxValue(maxValue);
							plans.productOptions.forEach((product) => {
								if (product.type === 'SECONDARY') {
									sopCategoryId = product.defaultProductCode;
								}
							});
						}
					});
					setSolutionPlan(solutionPlan);
					const data = await getSOPSolutionProductList(sopCategoryId, currentPage, cart?.id);
					currentPage = currentPage + 1;
					setSolutionList(data);
				}
			} catch (error) { }
		}
		didMount();
	}, [solutionPlan.length]);

	useEffect(() => {
		const didMount = async () => {
			try {
				if (solutionList && solutionList.products && solutionList.products.length > 0) {
					if (solutionList.total > solutionList.products.length) {
						setLoading(false);
						const data = await getSOPSolutionProductList(sopCategoryId, currentPage, cart?.id);
						currentPage = currentPage + 1;
						data.products = [...solutionList.products, ...data.products]
						setSolutionList(data);
					}
				}
			} catch (error) {

			}
		}
		didMount();
	}, [JSON.stringify(solutionList)]);

	useEffect(() => {
		if (priceTotal > 0) {
			if (solutionPlan && solutionPlan.length) {
				const initalBasicPlanSelected = 'BASIC'.indexOf(initalSolutionType) >= 0;
				solutionPlan.forEach(plans => {
					const qualificationRuleType = plans.qualificationRules &&
						plans.qualificationRules.length &&
						plans.qualificationRules[0].qualificationRuleType ?
						plans.qualificationRules[0].qualificationRuleType : '';
						if(priceTotal > basicMaxValue) {
							if (qualificationRuleType.toUpperCase().indexOf('BASIC') >= 0) { 
								plans.isSelected = false;
							} else {
								plans.isSelected = true;
							}
							if(initalBasicPlanSelected) {
								setSelectedPlanType('BEYOND')
							}
						} else {
							if (qualificationRuleType.toUpperCase().indexOf('BASIC') >= 0) { 
								plans.isSelected =true;
							} else {
								plans.isSelected = false;
							}
							if(initalBasicPlanSelected) {
								setSelectedPlanType('BASIC')
							}
						}
				})
			}
		}
	},[priceTotal])

	const addProductToListEvent = async (sku, option) => {
		const newSolutionList = solutionList;
		let initalPrice = priceTotal; 
		if (newSolutionList && newSolutionList.products && newSolutionList.products.length) {
			newSolutionList.products.forEach(product => {
				if (product.sku === sku) {
					let aboPrice = product.prices.find((value) => value.type === 'ABO');
					aboPrice = parseInt(aboPrice.price);
					if (option === 'increase') {
						product.addedQuantity = product.addedQuantity ? product.addedQuantity + 1 : 1;
						initalPrice = product.addedQuantity ? initalPrice + aboPrice : initalPrice;
					} else {
						product.addedQuantity = product.addedQuantity ? product.addedQuantity - 1 : 0;
						initalPrice = initalPrice - aboPrice;
					}
				}
			})
		};
		setPriceTotal(initalPrice);
		setSolutionList({ ...solutionList, newSolutionList });
	}

	const checkIsSkuAddDisable = sku => {
		let skuDisable = false;
		const newSolutionList = solutionList;
		if (newSolutionList && newSolutionList.products && newSolutionList.products.length) {
			newSolutionList.products.forEach(product => {
				if (product.sku === sku) {
					if (product.baseOptions && product.baseOptions.length) {
						const baseProduct = product.baseProduct;
						let quantityAdded = 0;
						const filterBaseProduct = newSolutionList.products.filter(products => products.baseProduct === baseProduct);
						if (filterBaseProduct && filterBaseProduct.length) {
							filterBaseProduct.forEach(filterProduct => {
								quantityAdded = filterProduct.addedQuantity ? (quantityAdded + filterProduct.addedQuantity) : quantityAdded;
							})
						}
						if (quantityAdded >= 5) {
							skuDisable = true;
						}
					} else {
						if (product.addedQuantity >= 5) {
							skuDisable = true;
						}
					}
				}
			})
		}
		return skuDisable;
	}

	const renderSolutionPLP = () => {
		if (solutionList && solutionList.products && solutionList.products.length > 0) {
			return solutionList.products.map((product, index) => {
				const {
					galleryImages,
					prices,
					amwayValue,
				} = product;
				const img = galleryImages.length > 0 ? galleryImages[0].image_url : '';
				let aboPrice = prices.find((value) => value.type === 'ABO');
				aboPrice = parseInt(aboPrice.price);
				return (
					<div className='col-6 no-padding-col' key={`solution-${index}`}>
						<SolutionItem product={product}
							img={img}
							price={aboPrice}
							accountType={accountType}
							increaseDisabled={checkIsSkuAddDisable(product.sku)}
							addProductToList={(sku, option) => addProductToListEvent(sku, option)}
							amwayValue={amwayValue} />
					</div>
				)
			})
		}
	}

	const renderProductSection = () => {
    if (solutionList && solutionList.products && solutionList.products.length > 0) {
      return solutionList.products.map((product, index) => {
        const {
          galleryImages,
          prices,
          amwayValue,
        } = product;
        const img = galleryImages.length > 0 ? galleryImages[0].image_url : '';
        let aboPrice = prices.find((value) => value.type === 'ABO');
        aboPrice = parseInt(aboPrice.price);
        if (product.addedQuantity && product.addedQuantity > 0) {
          return (
            <div className='col-12' key={`solution-footer-${index}`}>
							<SolutionFooterProduct img={img}
								price={aboPrice}
								product={product}
								increaseDisabled={checkIsSkuAddDisable(product.sku)}
								addProductToList={(sku, option) => addProductToListEvent(sku, option)}
								amwayValue={amwayValue} />
            </div>
          )
        }
      })
    }
  }

	const checkoutSubmitEvent = async () => {
		try {
			const orderEntries = {};
			let defaultProductCode = '';
			const customer = JSON.parse(sessionStorage.getItem('customer'));
			if (solutionPlan && solutionPlan.length) {
				setLoading(true);
				solutionPlan.forEach(plans => {
					if (plans.isSelected) {
						plans.productOptions.forEach((product) => {
							if (product.type === 'SECONDARY') {
								defaultProductCode = product.defaultProductCode;
								orderEntries.sopProduct = {
									id: product.defaultProductCode
								}
							}
						});
						orderEntries.selectedSopPlan = {
							code: plans.code
						}
						orderEntries.selectedEntries = [{
							quantity: 1
						}]
					}
				})
				orderEntries.subscribedFor = {
					accountId: customer.amwayNumber,
					primaryParty: {
						uid: customer.partyId
					}
				}
				orderEntries.childProducts = [];
				if (solutionList && solutionList.products && solutionList.products.length > 0) {
					for (let child of solutionList.products) {
						if (child.addedQuantity && child.addedQuantity > 0) {
							const childProducts = {
								productCode: child.id,
								quantity: child.addedQuantity,
								kitEntryCode: child.baseProduct ? `${defaultProductCode}-${child.baseProduct}` : `${defaultProductCode}-${child.id}`
							}
							orderEntries.childProducts.push(childProducts);
						}
					}
				}
				orderEntries.startMonth = new Date().getMonth() + 1;
				orderEntries.startYear = new Date().getFullYear();
				orderEntries.isAutoContinue = true;
				const data = await addSolutionSOPSubscription(orderEntries);
				setLoading(false);
				if(data.message && data.message === 'success') {
					window.location.href = commonConstant.pathCartPromotion;
				}
			}
		} catch (error) {
			if (
        error.response.data.statusCode === '400' &&
        error.response.data.message.errorCode === 'PLANEXCEED'
      ) {
				const { liff } = window;
        setLoading(false);
        liff.closeWindow();
      } else {
        setLoading(false);
        alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
      }
		}
	}

	return (
		<section>
			<LoadingOverlay
				text="Loading"
				spinner
				active={loading}
				styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
			/>
			<SolutionHeader solutionPlan={solutionPlan}
			  cartEntries={cartEntries}
				selectedPlanType={initalSolutionType} />
			<div className='row sop-plp-container no-margin-row'>{renderSolutionPLP()}</div>
			<SolutionFooter solutionList={solutionList}
				solutionPlan={solutionPlan}
				selectedPlanType={selectedPlanType}
				checkoutSubmit={()=>checkoutSubmitEvent()}
				priceTotal={priceTotal} >
				<div className='col-12 product-section'>
					{renderProductSection()}
				</div>
			</SolutionFooter>
		</section>
	)
};