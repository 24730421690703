import {
  FETCH_CHALLENGES_LIST_SUCCESS,
  FETCH_CHALLENGES_LIST_ONGOING_SUCCESS,
  FETCH_CHALLENGES_LIST_UPCOMING_SUCCESS,
  FETCH_CHALLENGES_LIST_FAILURE,
  FETCH_CHALLENGES_DETAIL_SUCCESS,
  FETCH_CHALLENGES_DETAIL_FAILURE,
  FETCH_CHALLENGES_PROFILE_FAILURE,
  FETCH_CHALLENGES_PROFILE_SUCCESS,
} from '../actions/types';
import { CHALLENGE_LIST } from '../common/challengeConstants';

const initialState = {
  loading: false,
  error: null,
  challengeList: {
    ongoing: null,
    upcoming: null,
    completed: null,
  },
  challengeProfile: {
    error: null,
    profileList: null,
  },
  selectedChallenge: {
    error: null,
    challengeDetails: {},
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CHALLENGES_LIST_SUCCESS:
      const ongoingList = action.payload.filter((challengeObj) => challengeObj?.challengeStatus===CHALLENGE_LIST.TYPES.ongoing);
      const upcomingList = action.payload.filter((challengeObj) => challengeObj?.challengeStatus===CHALLENGE_LIST.TYPES.upcoming);
      return {
        ...state,
        challengeList: {
          ...state.challengeList,
          ongoing: ongoingList,
          upcoming: upcomingList
        },
      };
    case FETCH_CHALLENGES_LIST_ONGOING_SUCCESS:
      return {
        ...state,
        challengeList: {
          ...state.challengeList,
          ongoing: action.payload,
        },
      };
    case FETCH_CHALLENGES_LIST_UPCOMING_SUCCESS:
      return {
        ...state,
        challengeList: {
          ...state.challengeList,
          upcoming: action.payload,
        },
      };

    case FETCH_CHALLENGES_LIST_FAILURE:
      return {
        ...state,
        challengeList: {
          ...state.challengeList,
          error: true,
        },
      };
    case FETCH_CHALLENGES_DETAIL_SUCCESS:
      return {
        ...state,
        selectedChallenge: {
          ...state.selectedChallenge,
          challengeDetails: action.payload,
        },
      };
    case FETCH_CHALLENGES_DETAIL_FAILURE:
      console.log('error', action.payload);
      return {
        ...state,
        selectedChallenge: {
          ...state.selectedChallenge,
          error: action.payload,
        },
      };
    case FETCH_CHALLENGES_PROFILE_SUCCESS:
      return {
        ...state,
        challengeProfile: {
          error: null,
          profileList: action.payload,
        },
      };
    case FETCH_CHALLENGES_PROFILE_FAILURE:
      console.log('error', action.payload);
      return {
        ...state,
        challengeProfile: {
          ...state.challengeProfile,
          error: action.error,
        },
      };

    default:
      return state;
  }
}
