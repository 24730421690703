import React from 'react'
import './EmptyListComponent.scss';
import { useLottie } from "lottie-react";

export default function EmptyListComponent(props) {

  const options = {
    animationData: props.emptyImg
  };
  const { View } = useLottie(options);

  return (
    <div className="dietary-supplement-no_data">
      <div className="empty-img-wrapper">
        {View}
      </div>
      <div className="dietary-supplement-no_data-heading">{props.heading}</div>
      <div className="dietary-supplement-no_data-description">{props.descriptionTop}</div>
      <div className="dietary-supplement-no_data-description">{props.descriptionBottom}</div>
    </div>
  )
}
