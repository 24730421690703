
import React from 'react';
import Liff from '../../../../modal/Liff';
import './CategoryPopup.scss';

export const CategoryPopup = ({ data, isOpen, onClose, onClick = () => { } }) => (
  <Liff
    isOpen={isOpen}
    onClose={onClose}
    onClickOutside={true}
  >
    <div className="modal-body mt-3 pt-2">
      <div className="modal-title">
        <div className="modal-heading">หมวดหมู่</div>
        <div className="modal-category-container">
          {data.map((categoryItem) => (
            <div key={categoryItem?.id} className="modal-category-single" onClick={() => onClick(categoryItem)}>
              <img src={categoryItem?.imageUrl} />
              <div className="modal-category-title">{categoryItem?.name}</div>
            </div>))}
        </div>
      </div>
    </div>
  </Liff>
);

export default CategoryPopup;
