import { OTP_FETCH, VERIFY_OTP_FETCH, CHANGE_PHONE, CREATE_BANK } from '../actions/types';

export function otp(state = {}, action) {
  switch (action.type) {
    case OTP_FETCH:
      return { ...state, ...action.payload, timestamp: action.timestamp };
    default:
      return state;
  }
}

export function verifyOtp(state = [], action) {
  switch (action.type) {
    case VERIFY_OTP_FETCH:
      return { ...state, ...action.payload, timestamp: action.timestamp };
    default:
      return state;
  }
}

export function changePhoneNumber(state = [], action) {
  switch (action.type) {
    case CHANGE_PHONE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function createBank(state = [], action) {
  switch (action.type) {
    case CREATE_BANK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
