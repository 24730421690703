import React from 'react';
import { useSelector } from 'react-redux';
import { BADGE_MAPPING, BADGES_MILESTONE, CHALLENGE_UNLOCKED } from '../../../common/challengeConstants';
import { ChallengeShare } from '../../../common';
import { isValidDateTime, thaiDateWOTime } from '../../../utill.func';
import './ChallengeUnlocked.scss';

export default function ChallengeUnlocked({ badgeNumber, onClose }) {
  const { teamData } = useSelector((state) => state.challengeTeamDashboard);
  const unlock_date = teamData?.stepsBadgeData?.[BADGES_MILESTONE[`badge${badgeNumber}`]]?.currentUserData?.receivedAt;
  const badgeImgPath = `/images/challenge/badges/steps-${BADGES_MILESTONE[`badge${badgeNumber}`]}-badge@5x.png`;

  const handleModalClick = (event) => event.target === event.currentTarget && onClose();

  return (
    <div className="challenge-unlock-modal">
      <div className="line-modal">
        <div className="modal fade show badge-step-modal" id="badgeStep" tabIndex="-1" aria-hidden="true" onClick={handleModalClick}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <img className="challenge-unlock-firework" src="/images/challenge/Firework x2.gif" />
              <div className="modal-body">
                <div className="modal-title">
                  {CHALLENGE_UNLOCKED.MODAL_TITLE}
                </div>
                <div className="modal-subtitle">
                  <span>{CHALLENGE_UNLOCKED.MODAL_GREETING_TEXT}</span>
                  <span className="ml-2 modal-subtitle-greeting">{BADGE_MAPPING[badgeNumber - 1]?.greetingMessage}</span>
                </div>
                <div className="badge-img-wrapper">
                  <img src="/images/challenge/badges/bg-dark-badge@5x.png" alt="" className="badge-img-bg" />
                  <img src={badgeImgPath} alt="" className="badge-img" />
                </div>
                <ChallengeShare imgPath={`${process.env.REACT_APP_REDIRECT_URL}${badgeImgPath}`}
                  message={`${CHALLENGE_UNLOCKED.UNLOCK_MESSAGE_DATE_PREFIX} ${isValidDateTime(unlock_date) && thaiDateWOTime(new Date(unlock_date), 'dd MMM yy')}`} />
                <button className="btn btn-light-green btn-full" onClick={onClose}>
                  {CHALLENGE_UNLOCKED.MODAL_SUBMIT_BTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
