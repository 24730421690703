import React from 'react';
import '../Score.scss';

const Chip = ({ title, content, type, onClick = () => { } }) => {
  const handleClick = () => {
    onClick(type, title, content)
  }
  return <div className="category-general-tag" onClick={handleClick} >
    <span>{title}</span>
    <img src="/images/assesment/info.svg" />
  </div >
}

export default Chip;
