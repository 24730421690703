import { selfChallengeData } from "../../common/logListDataDummy/selfChallengeCardData";
import { ChallengeSelf } from "../../model/ChallengeSelf.model";

export const getChallengeSelf = async () => {
    
    try {
        const response = selfChallengeData;

        return response?.map(challenge => new ChallengeSelf(challenge));
    } catch (error) {
        throw error;
    }
};