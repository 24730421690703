import React, { useEffect, useState } from 'react';
import './HnwGoals.scss';
import HnwGoalCard from './components/HnwGoalCard/HnwGoalCard';
import commonConstant, {
  GOAL_SETTING_HOME_TITLE_TH,
  HEALTH_GOAL_CARD_CONTENT,
  HEALTH_GOAL_TYPES,
  HNW_GOAL_SETTING,
} from '../../../common/commonConstant';
import { getHnwAllHealhGoalList } from '../../../actions/hnwGoalSettingActions';
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromSession } from '../../../utill.func';
import { CalendarLoader, Slideup } from '../../../common';
import { updateWeight } from '../../../actions';

export default function HnwGoals({ routeTo }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const healthGoals = useSelector((state) => state.goalSetting.healthGoalList);
  useEffect(() => {
    const { _id: customerId } = getDataFromSession(
      HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
    );
    const didMount = async (cId) => {
      await getHealthGoals(cId);
    };
    if (customerId) {
      didMount(customerId);
    }
  }, []);

  useEffect(() => {
    if (healthGoals?.weightGoal) {
      dispatch(updateWeight(healthGoals?.weightGoal))
    }
  }, [healthGoals]);

  async function getHealthGoals(cId) {
    try {
      setLoading(true);
      await dispatch(getHnwAllHealhGoalList(cId));
      setLoading(false);
    } catch (err) {
      console.error('err', err);
      setLoading(false);
    }
  }

  return (
    <div className='goal-setting-home-container'>
      <div className="goal-setting-home-header">
        <div className="container-fluid">
          <div className="row align-items-center header-bottom-section">
            <div className="col-3">
              <a className="mr-10">
                <img
                  src="/images/healthAndWellness/goal-setting/icons/home.svg"
                  alt="home.svg"
                  onClick={() => routeTo('pathHealthnWellness')}
                />
              </a>
            </div>
            <div className="col-9 text-right header-bottom-icons">
              <a>
                <img
                  src="/images/healthAndWellness/goal-setting/icons/icon-food.svg"
                  alt="icon-food.svg"
                  onClick={() => routeTo('pathFoodLogHome')}
                />
              </a>
              <a>
                <img
                  src="/images/healthAndWellness/goal-setting/icons/icon-burn-stroke.svg"
                  alt="icon-burn-stroke.svg"
                  onClick={() => routeTo('pathHnwExerciseLogHome')}
                />
              </a>
              <a>
                <img
                  src="/images/healthAndWellness/goal-setting/icons/profile.svg"
                  alt="user.png"
                  onClick={() => routeTo('pathProfile')}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="goal-setting-container">
        <CalendarLoader showLoading={loading} />
        <div className="goal-setting-heading d-flex">
          <a>
            <img
              src={
                '/images/healthAndWellness/goal-setting/icons/goal-black.svg'
              }
              alt="goal-icon-black"
            />
          </a>
          <span className="goal-setting-title">
            <b> {GOAL_SETTING_HOME_TITLE_TH}</b>
          </span>
        </div>
        <div className="goal-setup-container">
          {Object.values(HEALTH_GOAL_TYPES).map((type, i) => (
            <HnwGoalCard
              content={HEALTH_GOAL_CARD_CONTENT[type]}
              healthGoal={healthGoals?.[type]}
              routeTo={routeTo}
              type={type}
              key={`${i}_${type}`}
            />
          ))}
        </div>
      </div>
      <Slideup />
    </div>
  );
}
