import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay';
import './policy.scss'
import { getCustomerPolicy, getPolicies, updateCustomerPolicy } from '../../../services/healthWellness/healthWellness.service';
import { policyTermsList, policyTitle } from '../../../common/commonConstant';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOMER_FETCH, CUSTOMER_FETCH_LOADER } from '../../../actions/types';
import { updatePolicyStatus } from '../../../thunk/hnwHome.thunk';

const Policy = ({ routeTo }) => {

    const dispatch = useDispatch();
    const customerData = useSelector(state => state.customer);
    const { policies } = useSelector(state => state.home);

    const [loading, setLoading] = useState(false);
    const [customerPolicy, setCustomerPolicy] = useState([]);
    const [policyToggle, setPolicyToggle] = useState(false);

    useEffect(() => {
        if (customerData?.amwayNumber) {
            if (!customerData.hasOwnProperty('isPolicyAccepted')) {
                fetchCustomerPolicy();
            } else {
                setPolicyToggle(customerData.isPolicyAccepted);
            }
        }
    }, [customerData.amwayNumber])

    useEffect(() => {
        const fetchData = async () => {
            const selectedPolicy = (policies.length && policies.find(el => el.title.toLowerCase() === policyTitle.home_policy)) ?? null;
            setCustomerPolicy(selectedPolicy);
        }
        if (policies?.length) {
            fetchData();
        }
    }, [policies])

    const fetchCustomerPolicy = async () => {
        try {
            const policies = await getPolicies();
            const selectedPolicy = (policies.length && policies.find(el => el.title.toLowerCase() === policyTitle.home_policy)) ?? null;
            const customerpolicy = await getCustomerPolicy();
            const selectedCustomerPolicy = selectedPolicy && customerpolicy.length && customerpolicy.find(el => el.privacies === selectedPolicy.id);
            setCustomerPolicy(selectedPolicy);
            setPolicyToggle((selectedCustomerPolicy && selectedCustomerPolicy.status) ?? false);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    const updatePolicy = async (status) => {
        try {
            setLoading(true);
            await updateCustomerPolicy({
                privacyId: customerPolicy.id,
                status
            });
            customerData.isPolicyAccepted = status;
            dispatch({
                type: CUSTOMER_FETCH,
                payload: customerData
            });
            dispatch({
                type: CUSTOMER_FETCH_LOADER
            })
            dispatch(updatePolicyStatus(status));
            setLoading(false);
            routeTo('pathHealthnWellness');
        } catch (e) {
            setLoading(false);
        }
    }

    const onToggleChange = () => {
        setPolicyToggle(prevStatus => {
            updatePolicy(!prevStatus)
            return !prevStatus;
        });
    }

    return (
        <>
            <div className="policy-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="policy-title">การจัดการข้อมูลส่วนบุคคล</h2>
                        </div>
                    </div>
                </div>
                <div className="policy-content-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-title">
                                    <p>ให้ความยินยอมเปิดเผยข้อมูลส่วนบุคคล</p>
                                    <label className="switch">
                                        <input type="checkbox" checked={policyToggle} onChange={onToggleChange} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="card-content">
                                    <p className='content-top-text'>ฉันเข้าใจและยินยอมให้รวบรวมข้อมูลด้านสุขภาพ ของฉันเพื่อการให้บริการโซลูชั่น เพื่อการควบคุม หรือลดน้ำหนัก และการดูแลสุขภาพ ผ่าน [LINE AMWAY THAILAND OFFICIAL ACCOUNT] (“แอป”) ที่ให้ไว้บนแพลตฟอร์ม LINE AMWAY THAILAND OFFICIAL ACCOUNT ข้อมูลด้านสุขภาพ ที่รวบรวมผ่านแอป มีดังต่อไปนี้:</p>
                                    <div className="card-list">
                                        {
                                            policyTermsList.map((el, index) => (
                                                <div key={`list${index}`} className="card-list-single">
                                                    <span className="card-list-dot"></span>
                                                    <span className="card-list-text">{el}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <p>ฉันเข้าใจดีว่าข้อมูลด้านสุขภาพ ของฉันทั้งหมด จะถูกเก็บไว้ตั้งแต่ วันที่ส่งข้อมูลผ่านแอป จนถึงวันที่ผู้ใช้ เพิกถอนความยินยอม ในการให้ข้อมูลผ่านแอป หรือลบบัญชีบนแอปของตน ดังนั้น หากคุณไม่ต้องการ ให้ข้อมูลข้างต้น กรุณางดการใช้แอป เนื่องจากข้อมูลดังกล่าว จำเป็นต่อการนำไปใช้เพื่อนำเสนอและวิเคราะห์ โซลูชันเพื่อการควบคุม หรือลดน้ำหนัก และการดูแลสุขภาพ ที่มีอยู่ในแอปนี้</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoadingOverlay active={loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
        </>
    )
}

export default Policy