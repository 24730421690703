import React from 'react';
import './ExerciseRecommendedCard.scss';
import {
  CARDIO_CAT_TYPE,
  CYCLING_CAT_TYPE,
  EXERCISE_DAILY_ACTIVITY,
  EXERCISE_DURATION_IN_MIN_UNIT,
  EXERCISE_KILOMETER_UNIT,
  EXERCISE_RECOMMENDED_LIST,
  EXERCISE_TIME_UNIT,
  WALKING_AND_RUNNING_CAT_TYPE,
  WORKOUT_CAT_TYPE,
} from '../commonConstant';
import { formatNumberWithCommas, getParamsString } from '../../utill.func';
import { round } from '../../services';

const ExerciseRecommendedCard = ({
  item,
  toggleTimeLog,
  isRecommended,
  setExerciseMenuId,
  exerciseTimeLogList,
  routeTo,
  isTargetCalorieCalculated,
  targetCalorie,
}) => {
  return (
    <div className="recommended-exercise-container">
      <div
        className="recommended-exercise-card-single"
        onClick={() => {
          if (isRecommended) {
            let paramsObj = {
              exerciseMenuId: item?.id,
            };
            if (isTargetCalorieCalculated && targetCalorie) {
              paramsObj.suggestedCalorie = targetCalorie;
            }
            routeTo(
              'pathHnwExerciseLogDetail',
              `?${getParamsString(paramsObj)}`,
            );
          } else {
            if (
              exerciseTimeLogList?.length &&
              exerciseTimeLogList.filter(
                (a) => a?.excerciseCategory?.id === item?.excerciseCategory?.id,
              ).length
            ) {
              toggleTimeLog(true);
              setExerciseMenuId(item?.excerciseCategory?.id);
            } else {
              let paramsObj = {
                exerciseMenuId: item?.excerciseCategory?.id,
              };
              if (isTargetCalorieCalculated && targetCalorie) {
                paramsObj.suggestedCalorie = targetCalorie;
              }
              routeTo(
                'pathHnwExerciseLogDetail',
                `?${getParamsString(paramsObj)}`,
              );
            }
          }
        }}
      >
        <img
          src={item?.image}
          width={64}
          height={64}
          style={{
            borderRadius: '10px',
          }}
        />
        <div className="recommended-exercise-card-details">
          <div className="recommended-exercise-card-name">{item?.name}</div>
          <div className="recommended-exercise-card-type">
            {isRecommended
              ? item?.exerciseCategory?.name
              : item?.excerciseCategory?.exerciseCategory?.name}
          </div>
          {isRecommended ? (
            <div className="recommended-exercise-card-time">
              <img src="/images/healthAndWellness/exercise-log/icons/time.svg" />
              <span className="recommended-exercise-duration">
                {EXERCISE_RECOMMENDED_LIST.duration}
              </span>
              <span className="recommended-exercise-value">
                {item?.suggestionTime}
              </span>
              <span className="recommended-exercise-unit">
                {EXERCISE_RECOMMENDED_LIST.mins}
              </span>
            </div>
          ) : (
            <div className="activity-icon-wrapper">
              <div className="activity-icon-list">
                {{
                  [WORKOUT_CAT_TYPE]: (
                    <>
                      <img src="/images/healthAndWellness/exercise-log/icons/count.svg" />
                      <div className="activity-icon-value">
                        {item?.noOfTimes
                          ? formatNumberWithCommas(item?.noOfTimes)
                          : '-'}
                      </div>
                      <div className="activity-icon-unit">
                        {EXERCISE_TIME_UNIT}
                      </div>
                    </>
                  ),
                  [CARDIO_CAT_TYPE]: (
                    <>
                      <img src="/images/healthAndWellness/exercise-log/icons/duration.svg" />
                      <div className="activity-icon-value">
                        {item?.duration
                          ? formatNumberWithCommas(item?.duration)
                          : '-'}
                      </div>
                      <div className="activity-icon-unit">
                        {EXERCISE_DURATION_IN_MIN_UNIT}
                      </div>
                    </>
                  ),
                  [CYCLING_CAT_TYPE]: (
                    <>
                      <img src="/images/healthAndWellness/exercise-log/icons/duration.svg" />
                      <div className="activity-icon-value">
                        {item?.duration
                          ? formatNumberWithCommas(item?.duration)
                          : '-'}
                      </div>
                      <div className="activity-icon-unit">
                        {EXERCISE_DURATION_IN_MIN_UNIT}
                      </div>
                    </>
                  ),
                  [WALKING_AND_RUNNING_CAT_TYPE]: (
                    <>
                      <img src="/images/healthAndWellness/exercise-log/icons/distance.svg" />
                      <div className="activity-icon-value">
                        {item?.distCovered
                          ? formatNumberWithCommas(round(item?.distCovered))
                          : '-'}
                      </div>
                      <div className="activity-icon-unit">
                        {EXERCISE_KILOMETER_UNIT}
                      </div>
                    </>
                  ),
                }[item?.catName] || (
                  <>
                    <img src="/images/healthAndWellness/exercise-log/icons/count.svg" />
                    <div className="activity-icon-value">
                      {item?.noOfTimes
                        ? formatNumberWithCommas(item?.noOfTimes)
                        : '-'}
                    </div>
                    <div className="activity-icon-unit">
                      {EXERCISE_TIME_UNIT}
                    </div>
                  </>
                )}
              </div>
              <div className="activity-icon-list">
                <img src="/images/healthAndWellness/exercise-log/icons/icon-burn-orange.svg" />
                <div className="activity-icon-value">
                  {item?.caloriesBurn
                    ? formatNumberWithCommas(item?.caloriesBurn)
                    : '-'}
                </div>
                <div className="activity-icon-unit">
                  {EXERCISE_DAILY_ACTIVITY.calories}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="btn-icon-round">
          <img src="/images/healthAndWellness/exercise-log/icons/green-arrow.svg" />
        </div>
      </div>
    </div>
  );
};
export default ExerciseRecommendedCard;
