import React, { useEffect, useState } from 'react';
import { CHALLENGE_MAP_LOADING } from '../../../common/challengeConstants';
import './ChallengeMapLoading.scss';

const ChallengeMapLoading = ({ onLoadFinish }) => {
  const [loadingText, setLoadingText] = useState(CHALLENGE_MAP_LOADING[0].message);
  const [loadingPercent, setLoadingPercentage] = useState(0);

  useEffect(() => {
    CHALLENGE_MAP_LOADING.forEach((percentageObj, index) => {

      setTimeout(() => {
        setLoadingText(percentageObj.message);
        setLoadingPercentage(percentageObj.value);
      }, (index) * 1000);

      setTimeout(onLoadFinish, 3500);
    });
  }, []);

  return (
    <div className="challenge-map-loading-wrapper">
      <div className="challenge-map-loading-content">
        <img src="/images/challenge/4_3.png" />
        <div className="challenge-map-loading-content_text">{loadingText}</div>
        <div className="progress-container">
          <div style={{ "--loading-percent": `${loadingPercent}%` }} className="progress-bar"></div>
        </div>
      </div>
    </div>
  )
}

export default ChallengeMapLoading;