import React from 'react';

export default function ExerciseInputCard({ inputFields }) {
  return (
    <div className="excercise-info-card">
      {inputFields.map((item, key) => (
        <div
          className="excercise-info-card-single"
          key={`${key}_${item.fieldName}`}
        >
          <div className="excercise-info-card-left">
            <img src={item.fieldIcon} />
            <span>{item.fieldName}</span>
          </div>
          <div className="excercise-info-card-right green">
            {item?.inputRenderProp()}
          </div>
        </div>
      ))}
    </div>
  );
}
