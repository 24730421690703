import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { isIOS } from "react-device-detect";
import { DELETE_MODAL_CANCEL, DELETE_BUTTON_CONFIRM, DELETE_MODAL_HEADING } from "../commonConstant";
import './HealthDeleteFoodModal.scss';

export const DELETE_OPEN = 'open';

const HealthDeleteFoodModal = ({ onClose, onConfirm, isDeleteModalOpen,
     deleteBtnMsg=DELETE_BUTTON_CONFIRM, cancelBtnMsg=DELETE_MODAL_CANCEL, deleteHeaderMsg=DELETE_MODAL_HEADING, titleColor, cancelBtnColor, exerciseModal=false, deleteBtnMsgIos, cancelBtnMsgIos }) => {

    const [isLiffOpen, setIsLiffOpen] = useState(false);

    useEffect(() => {
        const isLiffOpen = isDeleteModalOpen ? DELETE_OPEN : '';
        setIsLiffOpen(isLiffOpen);
    }, [isDeleteModalOpen]);

    const cancelButtonClass = cancelBtnColor? `btn-link-${cancelBtnColor}`:`btn-link-default`;
    const titleClass = titleColor? `modal-heading-${titleColor}`: '';
    const headerClass = exerciseModal? `modal-heading-exercise`: '';

    const renderButtons = () => {
        return (
            <div className="modal-footer">
                <button type="button" className="btn btn-link-red font-16" data-bs-dismiss="modal" onClick={onConfirm}>{deleteBtnMsgIos}</button>
                <button type="button" className={`btn ${cancelButtonClass}`} onClick={onClose}>{cancelBtnMsgIos}</button>
            </div>
        )
    }
    return (
        <section className={`delete-modal-panel ${isLiffOpen}`}>
            <div className="delete-modal" id={isIOS ? `deleteModalios` : `deleteModalAndroid`} tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className={isIOS ? 'modal-title text-centered' : 'modal-title'}>
                                <div className={isIOS ? !exerciseModal ? `modal-heading-ios sf-pro-heading ${titleClass} ${headerClass}` :
                                    `modal-heading-ios ${titleClass} ${headerClass}` :
                                    `modal-heading roboto-medium-heading ${titleClass} ${headerClass}`}>{deleteHeaderMsg}</div>
                            </div>
                        </div>
                        {exerciseModal && isIOS ? renderButtons() : <div className="modal-footer">
                            <button type="button" className={`btn ${isIOS ? 'btn-cancel-sf-pro' : 'btn-cancel-roboto'} ${cancelButtonClass}`} onClick={onClose}>{cancelBtnMsg}</button>
                            <button type="button" className={`btn ${isIOS ? 'btn-link-red-sf-pro-regular' : 'btn-link-red-roboto'}`} data-bs-dismiss="modal" onClick={onConfirm}>{deleteBtnMsg}</button>
                        </div>}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HealthDeleteFoodModal;