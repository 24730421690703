import React from 'react';
import Chip from './Chip';
import { ASSESSMENT_QUESTION_TEMPLATE } from '../../../../../common/commonConstant';
import '../Score.scss';

const AnswerCard = ({ type, data = [], onChipClick }) => {
  return <div className={`category-general-container category-general-${ASSESSMENT_QUESTION_TEMPLATE[type].icon}-container`}>
    <div className="category-general-header">
      <div className="category-general-icon">
        <img src={`/images/assesment/${ASSESSMENT_QUESTION_TEMPLATE[type].icon}.svg`} />
      </div>
      <div className="category-general-title">{ASSESSMENT_QUESTION_TEMPLATE[type].title}</div>
    </div>
    <div className="category-general-body">
      <div className="category-general-row">
        {data.map(({ short_name, content }) => <Chip onClick={onChipClick} title={short_name} key={short_name} type={type} content={content} />)}
      </div>
    </div>
  </div>;
};

export default AnswerCard;
