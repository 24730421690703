import React from "react";
import { FOOD_BAR_NAME, FOOD_CARD_HEADING, FOOD_CIRCLE_BG_ICON, FOOD_CIRCLE_GRADIENT_DEGREE, FOOD_CIRCLE_GRADIENT_ID, FOOD_CIRCLE_START_COLOR, FOOD_CIRCLE_STOP_COLOR, FOOD_EMPTY_STATE_HEADING, FOOD_PARAMETERS_MAPPING } from "../commonConstant";
import HealthHomeCircleChart from "../healthHomeCircleChart/HealthHomeCircleChart";
import HealthHomeProgressBar from "../healthHomeProgressBar/HealthHomeProgressBar";
import HealthLogItemEmptyCard from "../healthLogItemEmptyCard/HealthLogItemEmptyCard";
import './HealthLogFoodCard.scss';
import { round } from "../../services";

const HealthLogFoodCard = ({ isEmpty, calorie, healthParamsValues, onClick = () => { } }) => {

  const renderChartCardHeader = () => {
    return (
      <div className="health-log-food-card-header">
        <div className="health-log-food-card-header-left">
          <img src="/images/healthAndWellness/foodLog/icons/food.svg" />
          <h6>{FOOD_CARD_HEADING}</h6>
        </div>
        <div className="health-log-food-card-header-right">
          <button className="btn-icon-round">
            <img src="/images/healthAndWellness/foodLog/icons/arrow-right.svg" />
          </button>
        </div>
      </div>
    )
  }

  const renderChartCardBody = () => {
    return (
      <div className="health-log-food-card-body">
        <div className="health-log-food-card-body-left">
          <HealthHomeCircleChart
            stopColorStart={FOOD_CIRCLE_START_COLOR}
            stopColorEnd={FOOD_CIRCLE_STOP_COLOR}
            bgIcon={FOOD_CIRCLE_BG_ICON}
            usedQty={calorie.consumed}
            totalQty={calorie.total}
            unit={calorie.unit}
            gradientId={FOOD_CIRCLE_GRADIENT_ID}
            gradientDegree={FOOD_CIRCLE_GRADIENT_DEGREE} />
        </div>
        <div className="health-log-food-card-body-right">
          {FOOD_PARAMETERS_MAPPING.map((nutritionVal) => {
            return (<div key={nutritionVal.name} className="health-log-food-card-nutrition-single-info">
              <div className="health-log-food-card-nutrition-single-info-inner-container">
                <div className="health-log-food-card-nutrition-single-info-left">
                  <img src={nutritionVal.iconPath} />
                  <div className="health-log-food-card-nutrition-single-info-title">
                    {nutritionVal.label}
                  </div>
                </div>
                <div className="health-log-food-card-nutrition-single-info-right">
                  <div className="health-log-food-card-nutrition-single-info-percent">{round(healthParamsValues[nutritionVal.name].consumed, 0) || '-'}
                  </div>
                  <div className="health-log-food-card-nutrition-unit-container">
                    <div className="health-log-food-card-nutrition-single-info-goal">/
                    </div>
                    <div className="health-log-food-card-nutrition-single-info-goal">{round(healthParamsValues[nutritionVal.name].total, 0) ?? ''}
                    </div>
                    <div className="health-log-food-card-nutrition-single-info-unit">{nutritionVal.unit}
                    </div>
                  </div>
                </div>
              </div>
              <HealthHomeProgressBar value={round(healthParamsValues[nutritionVal.name].consumed, 0)} maxValue={round(healthParamsValues[nutritionVal.name].total, 0)} />
            </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderCircularChart = () => {
    return (
      <div className='health-log-food-card' onClick={onClick}>
        {renderChartCardHeader()}
        {renderChartCardBody()}
      </div>
    )
  }

  return (isEmpty ? <HealthLogItemEmptyCard itemType={FOOD_BAR_NAME} onClick={onClick} /> : renderCircularChart())
}

export default HealthLogFoodCard;