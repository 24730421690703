import React from 'react';
import { ADD_GOAL_BTN_TH, goalRoutesMap, HEALTH_GOAL_TYPES } from '../../../../../common/commonConstant';

export default function HnwAddGoalBtn({ type, routeTo }) {
  return (
    <div
      className="goal-setup-btn d-flex flex-row justify-content-center align-items-center"
      onClick={() => routeTo(goalRoutesMap[type], `?activeFlow=${HEALTH_GOAL_TYPES.WEIGHT === type ? "1": "0"}`)}
    >
      <a>
        <img
          src={'/images/healthAndWellness/goal-setting/icons/add-green.svg'}
          alt="goal-setup-card-btn-icon"
        />
      </a>
      <span className="goal-setup-btn-typography">{ADD_GOAL_BTN_TH}</span>
    </div>
  );
}
