import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HnwSetterCard,
  HnWTooltip,
  HnwSuccessOverlayModal,
  CalendarLoader,
} from '../../../../common';
import commonConstant, {
  GENDER,
  GOALS_SUBMIT_BUTTON_TITLE,
  GOALS_WATER_DETAILS,
  HEALTH_GOAL_TYPES,
  HNW_GOAL_SETTING,
} from '../../../../common/commonConstant';
import {
  calculateConsumableQuantity,
  getNumberFromString,
  getDataFromSession,
} from '../../../../utill.func';
import {
  fetchBmiData,
  fetchGoalsWater,
  getHnwAllHealhGoalList,
  updateGoalsWater,
} from '../../../../actions';
import {
  getCustomerAge,
  getCustomerGender,
} from '../../../../services/healthWellness/healthWellness.service';
import { getDefaultValues } from '../../../../services/healthWellness/hnwGoals.service';
import { round } from '../../../../services';
import { createBrowserHistory } from 'history';
import './WaterGoal.scss';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();

export default function WaterGoal({ routeTo, routeReplaceTo }) {
  const dispatch = useDispatch();
  const WaterGoalData = useSelector((state) => state.hnwGoals.waterGoal);
  const healthGoals = useSelector((state) => state.goalSetting.healthGoalList);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const waterId = params.get('waterGoal_id');
  const activeFlow = params.get('activeFlow');
  const minGoalValue = GOALS_WATER_DETAILS.SETTER_CARD_MINIMUM_VALUE;
  const maxGoalValue = GOALS_WATER_DETAILS.SETTER_CARD_MAXIMUM_VALUE;

  const bmi = useSelector((state) => state.bmi);
  const customerGender = Object.keys(GENDER).find(
    (item) => GENDER[item] === getCustomerGender(),
  );
  const customerAge = getCustomerAge();
  const defaultValues = getDefaultValues(bmi, customerAge, customerGender);

  const [waterGoalVal, setWaterGoalVal] = useState(
    round(defaultValues.defaultWater, 0),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isApiHit, setIsApiHit] = useState(false);
  const [showToolTip, setShowToolTip] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);

  let setterCardUnit = GOALS_WATER_DETAILS.SETTER_CARD_UNIT;
  let isResetEnabled = true;
  let isFooterEnabled = true;
  if (waterGoalVal === round(defaultValues.defaultWater)) {
    setterCardUnit = GOALS_WATER_DETAILS.SETTER_CARD_UNIT_RECOMMENDED;
    isResetEnabled = false;
    isFooterEnabled = false;
  }

  const checkIsOutOfRange = (goalVal) => {
    const isOutOfRange =
      isNaN(goalVal) ||
      goalVal < parseInt(minGoalValue) ||
      goalVal > parseInt(maxGoalValue);
    setIsButtonDisabled(isOutOfRange);
    setShowToolTip(!isOutOfRange);
  };

  const handleGoalValueChange = (newWaterVal) => {
    const parsedValue = getNumberFromString(newWaterVal);
    checkIsOutOfRange(parsedValue);
    setWaterGoalVal(parsedValue);
  };

  const getGlassQty = () => {
    const glasses = calculateConsumableQuantity(
      waterGoalVal,
      GOALS_WATER_DETAILS.TOOLTIP_DEFAULT_GLASS_UNIT,
    );
    return `X ${glasses} `;
  };

  const handleSubmitWaterGoal = () => {
    setIsApiHit(true);
    setIsLoading(true);
    dispatch(
      updateGoalsWater({
        waterId,
        data: {
          targetWater: +waterGoalVal,
        },
      }),
    );
  };

  const handleSuccessRedirect = () => {
    if (parseInt(activeFlow) === 1) {
      const goalId = healthGoals?.[HEALTH_GOAL_TYPES.SUPPLEMENT]?.id || null
      routeReplaceTo('pathHnwGoalsSupplements', goalId ? `?${HEALTH_GOAL_TYPES.SUPPLEMENT}_id=${goalId}&activeFlow=${activeFlow}` : `?activeFlow=${activeFlow}`);
    } else {
      routeTo('pathHnwGoalsHome');
    }
    setIsApiHit(false);
  };

  useEffect(() => {
    if (!waterId) {
      const defaultGoalVal = round(defaultValues.defaultWater, 0);
      setWaterGoalVal(defaultGoalVal);
      checkIsOutOfRange(defaultGoalVal);
    }
    setIsLoading(false);
  }, [defaultValues.defaultWater]);

  useEffect(() => {
    if (!bmi?.bmi) {
      setIsLoading(true);
      dispatch(fetchBmiData()).then(() => setIsLoading(false));
    }
    if (waterId && !WaterGoalData) {
      dispatch(fetchGoalsWater(waterId));
    }
  }, [waterId]);

  useEffect(() => {
    const targetWater = WaterGoalData?.targetWater;
    const isError = WaterGoalData?.hasError;
    if (targetWater) {
      setWaterGoalVal(targetWater);
      checkIsOutOfRange(targetWater);
    }
    if (targetWater || isError || isApiHit) {
      setIsLoading(false);
    }
    if (isApiHit && !isError) {
      setShowSuccess(true);
    }
  }, [WaterGoalData]);

  useEffect(() => {
    if (parseInt(activeFlow) === 1) {
      const customer = getDataFromSession(
        HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
      );
      if (customer?._id && !healthGoals?.waterGoal || !healthGoals?.foodGoal || !healthGoals?.activityGoal) {
        dispatch(getHnwAllHealhGoalList(customer?._id))
      }
    }
  }, []);

  return (
    <div className="hnw-goals-water-container">
      <img
        src="/images/healthAndWellness/goals/water/water_goal_header.svg"
        alt="header-image"
        className="hnw-goals-water-header"
      />
      <div className="hnw-goals-water-details-container">
        <div className="hnw-goals-water-details-container__card-wrapper">
          <HnwSetterCard
            headerText={GOALS_WATER_DETAILS.SETTER_CARD_HEADER}
            unit={setterCardUnit}
            defaultValue={round(defaultValues.defaultWater)}
            resetEnabled={isResetEnabled}
            value={waterGoalVal}
            onValueChange={handleGoalValueChange}
            maxValue={maxGoalValue}
            minValue={minGoalValue}
            stepperVal={GOALS_WATER_DETAILS.SETTER_CARD_STEPPER_VALUE}
            footerEnabled={isFooterEnabled}
            footerTextArr={[
              GOALS_WATER_DETAILS.SETTER_CARD_FOOTER_FIRST,
              GOALS_WATER_DETAILS.SETTER_CARD_FOOTER_SECOND,
            ]}
          />
        </div>
        {showToolTip && (
          <div className="hnw-goals-water-details-container__tooltip-wrapper">
            <HnWTooltip>
              <div className="hnw-goals-water-details-container__tooltip-wrapper__main">
                <div className="hnw-goals-water-details-container__tooltip-wrapper__main-header">
                  {GOALS_WATER_DETAILS.TOOLTIP_HEADER}
                </div>
                <div className="hnw-goals-water-details-container__tooltip-wrapper__main-details">
                  <img src="/images/healthAndWellness/goals/water/glass_filled.svg" />
                  <div>
                    <span className="hnw-goals-water-details-container__tooltip-wrapper__main-quantity">
                      {getGlassQty()}
                    </span>
                    <span className="hnw-goals-water-details-container__tooltip-wrapper__main-unit">
                      {GOALS_WATER_DETAILS.TOOLTIP_UNIT}
                    </span>
                  </div>
                </div>
              </div>
            </HnWTooltip>
          </div>
        )}

        <div className="hnw-goals-water-details-container__suggestions-wrapper">
          <div className="hnw-goals-water-details-container__suggestions-wrapper-suggestion-list">
            <ul>
              <li>{GOALS_WATER_DETAILS.SUGGESTION_LIST_FIRST}</li>
              <li>
                <span>{GOALS_WATER_DETAILS.SUGGESTION_LIST_SECOND_LEFT}</span>
                <img src="/images/healthAndWellness/goals/common/stepper_left_icon.svg" />
                <img src="/images/healthAndWellness/goals/common/stepper_right_icon.svg" />
                <span className="hnw-goals-water-details-container__suggestions-wrapper-suggestion-list-text">
                  {GOALS_WATER_DETAILS.SUGGESTION_LIST_SECOND_RIGHT}
                </span>
              </li>
              <li>{GOALS_WATER_DETAILS.SUGGESTION_LIST_THIRD}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="hnw-goals-water-footer">
        <img
          src="/images/healthAndWellness/goals/goalSettingFooter.svg"
          alt="footer-image"
        />
        <button
          className="btn"
          disabled={isButtonDisabled}
          onClick={handleSubmitWaterGoal}
        >
          {GOALS_SUBMIT_BUTTON_TITLE}
        </button>
      </div>
      <CalendarLoader showLoading={isLoading} />
      {showSuccess && (
        <HnwSuccessOverlayModal
          show={showSuccess}
          onTimeout={handleSuccessRedirect}
        />
      )}
    </div>
  );
}
