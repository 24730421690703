import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  convertBlobToBase64,
  fetchSmartReport,
} from '../../services/promotion/utils.service';
import './SmartReport.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const history = createBrowserHistory();
const liff = window.liff;

export default function RedirectSmartReport() {
  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState('');
  const [numPages, setNumPages] = useState(0);

  const buttonRef = useRef(null);

  useEffect(() => {
    const didMount = async () => {
      liff.init({
        liffId: process.env.REACT_APP_LIFF_CLIENT_ID,
      });
      await renderSmartReport();
    };
    didMount();
  }, []);

  const extractUrlParams = () => {
    let keyword = '';
    const urlParams = queryString.parse(history.location.search);
    if (urlParams) {
      keyword = urlParams?.keyword ?? '';
    }
    return { keyword };
  };

  const renderSmartReport = async () => {
    try {
      setLoading(true);
      const { keyword } = extractUrlParams();
      if (keyword) {
        const reportResponse = await fetchSmartReport(keyword.toLowerCase());

        if (reportResponse?.token && reportResponse?.pdfUrl) {
          let request = new XMLHttpRequest();
          request.open('GET', reportResponse?.pdfUrl, true);
          request.responseType = 'blob';
          request.setRequestHeader('Accept', 'application/pdf');
          request.setRequestHeader(
            'Authorization',
            'Bearer ' + reportResponse?.token,
          );
          request.setRequestHeader(
            'Cache-Control',
            'no-cache, no-store, must-revalidate',
          );
          request.onload = function (data) {
            if (request.status === 200) {
              convertBlobToBase64(data.currentTarget.response).then(
                (base64) => {
                  setBase64(base64);
                  setLoading(false);
                },
              );
            } else {
              pushErrorMessageToUser();
            }
          };
          request.onerror = () => {
            pushErrorMessageToUser();
          };
          request.send();
        } else {
          setLoading(false);
          pushErrorMessageToUser();
        }
      } else {
        pushErrorMessageToUser();
      }
    } catch (error) {
      console.log(error);
      pushErrorMessageToUser();
      setLoading(false);
    }
  };

  const pushErrorMessageToUser = () => {
    let errMsgText = 'ไม่พบข้อมูลรายงานการจ่ายส่วนลดในเดือนที่เลือก';
    liff
      .sendMessages([
        {
          type: 'text',
          text: errMsgText,
        },
      ])
      .then(() => {
        console.log('message sent', errMsgText);
        liff.closeWindow();
      })
      .catch((err) => {
        console.log('error', err);
        liff.closeWindow();
      });
  };

  const handleButtonClick = async () => {
    const { keyword } = extractUrlParams();
    const basePath = window?.location?.origin;
    const url = `${basePath}/view/smart-report?keyword=${keyword}`;

    liff.openWindow({
      url,
      external: true,
    });
    liff.closeWindow();
  };

  const renderDocument = () => {
    if (!loading && base64?.length) {
      return (
        <>
          {renderButton()}
          <div className="pdf-container">
            <Document
              file={base64}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              onLoadError={console.error}
            >
              {Array.apply(null, Array(numPages))
                .map((count, index) => index + 1)
                .map((page) => (
                  <Page
                    size="A4"
                    pageNumber={page}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                ))}
            </Document>
          </div>
        </>
      );
    }
  };

  const renderButton = () => {
    return (
      <div className="top-bar">
        <button
          className="download-btn"
          ref={buttonRef}
          onClick={() => handleButtonClick()}
        >
          Download<img src="/images/download.svg" height="14px"></img>
        </button>
      </div>
    );
  };

  return (
    <section>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      {renderDocument()}
    </section>
  );
}
