import React, { Component } from 'react';

class Coupon extends Component {
  render() {
    const newDate = new Date(this.props.valid);
    const month = newDate.getMonth() + 1;
    const monthWithZero = month <= 9 ? `0${month.toString()}` : month.toString();
    const day = newDate.getDate();
    const dayWithZero = day <= 9 ? `0${day.toString()}` : day.toString();
    const year = newDate.getFullYear().toString().substr(-2);
    const dateString = `${dayWithZero}/${monthWithZero}/${year}`;
    return (
      <div className="coupon">
        <div className="detail">
          <span>{this.props.detail}</span>
        </div>
        <div className="price">
          <div className="discount"><span>ลดลง ฿ {this.props.discount}</span></div>
          <div className="valid"><span>ใช้ได้ถึง {dateString}</span></div>
        </div>
      </div>
    );
  }
}

export default Coupon;
