import variables from '../common/commonConstant.scss';

export const MEAL_CARDS = [
  {
    imagePath:
      '/images/healthAndWellness/foodLog/foodOverview/icons/breakfast.svg',
    type: 'breakfast',
    header: 'มื้อเช้า',
    calorie: null,
    data: null,
  },
  {
    imagePath: '/images/healthAndWellness/foodLog/foodOverview/icons/lunch.svg',
    type: 'lunch',
    header: 'มื้อเที่ยง',
    calorie: null,
    data: null,
  },
  {
    imagePath:
      '/images/healthAndWellness/foodLog/foodOverview/icons/dinner.svg',
    type: 'dinner',
    header: 'มื้อเย็น',
    calorie: null,
    data: null,
  },
  {
    imagePath:
      '/images/healthAndWellness/foodLog/foodOverview/icons/supplement.svg',
    type: 'dietarySupplement',
    header: 'ผลิตภัณฑ์เสริมอาหาร',
    calorie: null,
    data: null,
  },
];

export const DRINK_STATUS = {
  full: 'full',
  half: 'half',
  empty: 'empty',
  add: 'add',
};

export const DRINK_NAME = {
  water: 'water',
  coffee: 'coffee',
  softDrink: 'softDrink',
};

export const DRINK_LIMIT = {
  water: {
    initial: 8,
    max: 16,
  },
  coffee: {
    initial: 5,
    max: 10,
  },
  softDrink: {
    initial: 8,
    max: 16,
  },
};

export const DEFAULT_DRINK_INTAKE = {
  waterVolume: 100,
  coffeeVolume: 100,
  softDrinkVolume: 180,
};

export const QUERY_PARAMS_MAPPER = {
  breakfast: {
    type: 'breakfast',
  },
  lunch: {
    type: 'lunch',
  },
  dinner: {
    type: 'dinner',
  },
  dietarySupplement: {
    type: 'supplements-log',
    search: '1'
  },
};

export const DRINKS_PAYLOAD_MAPPER = {
  water: {
    level: 'waterLevel',
    intake: 'waterIntake',
    volume: 'waterVolume',
  },
  coffee: {
    level: 'coffeeLevel',
    intake: 'coffeeIntake',
    volume: 'coffeeVolume',
  },
  softDrink: {
    level: 'softDrinkLevel',
    intake: 'softDrinkIntake',
    volume: 'softDrinkVolume',
  },
};

export const SOFT_DRINK_VOLUME_LIST = [
  180, 245, 250, 325, 330, 375, 450, 500, 1000,
];

export const WATER_VOLUME_LIST = [
  100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800,
  850, 900, 950, 1000,
];

export const FOODTYPES = {
  water: 'water',
  food: 'food',
};

export const DRINK_CAUTION_MSG = {
  water: {
    icon: '/images/healthAndWellness/foodLog/icons/water-drop-msg-icon.svg',
    title: 'ดื่มน้ำคุณภาพ ไม่ดื่มไมโครพลาสติก',
    styleObj: {
      color: variables.lightBlue
    }
  },
  coffee: {
    title: 'ไม่ควรดื่มเกิน 3 กระป๋อง / วัน'
  },
  soda: {
    title: 'ไม่ควรดื่มเกิน 3 กระป๋อง / วัน'
  },
  default: {
    icon: '/images/healthAndWellness/foodLog/icons/caution.svg'
  }
}

export const MAX_VAL_TO_SHOW = 9999;
