import React from 'react';
import { INPUT_PLACEHOLDER } from '../../../../../common/commonConstant';
import './HnwWeightInputBox.scss';

export default function HnwWeightInputbox({ name, value, onClick, hasError, weightHeader }) {
  return (
    <div className="hnw-goals-weight-details">
      <div className="hnw-goals-weight-details-heading">{weightHeader}</div>
      <div className={`hnw-goals-weight-details-value${value && !hasError ? '' : ' --no-value'}`} onClick={() => onClick(name)}>
        {value || INPUT_PLACEHOLDER}
      </div>
    </div>
  );
}
