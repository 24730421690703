import React from 'react'
import { CartAssessmentHeader } from '../../../../../common'

const FoodLogHeader = ({ routeTo }) => {
    return (
        <CartAssessmentHeader
            leftIcon={"/images/healthAndWellness/foodLog/icons/home-black.svg"}
            headerRoute={'pathHealthnWellness'}
            rightSecondIcon={"/images/healthAndWellness/foodLog/icons/calendar-food.svg"}
            rightSecondIconRoute={'pathFoodLogCalendar'}
            routeTo={routeTo}
        />
    )
}

export default FoodLogHeader