import { SELECT_SOP, ADD_CART, REMOVE_CART } from '../actions/types';

export function selectedSOP(state = {}, action) {
  switch (action.type) {
    case SELECT_SOP:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function SOPCart(state = [], action) {
  switch (action.type) {
    case ADD_CART:
      return { ...state, ...action.data };
    case REMOVE_CART:
      return state.filter(obj => obj.id !== action.id);
    default:
      return state;
  }
}
