import { PUBLISH_STATUS } from '../common/commonConstant';
import config from '../config';

export class FoodCategory {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.name = data.name || '';
      this.tagImage = data.tagImage || '';
      this.tagImageUrl = this.tagImage ? `${config.S3_URL}${this.tagImage}` : '';
      this.image = data.image || '';
      this.imageUrl = this.image ? `${config.S3_URL}${this.image}` : (data.imageUrl || '/images/healthAndWellness/foodCalories/food-category/cat-default.svg');
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
      this.order = data.order || null;
      this.status = data.status || PUBLISH_STATUS.PUBLISHED;
    }
  }
}
