import moment from 'moment';
import {
  AMWAY_SUGGEST_TYPE,
  EXERCISE_LOG_DETAIL,
  HNW_GOAL_SETTING,
  LABEL_EN_SUGGEST_LOG_TAB,
  MAP_AMWAY_SUGGEST_AND_SUGGEST,
  MAP_SUGGEST_AND_AMWAY_SUGGEST,
} from '../common/commonConstant';
import config from '../config';
import { checkImageURL, timeMoment } from '../utill.func';

export class ExerciseLog {
  constructor(data) {
    this.id = data?._id || '';
    this.startTime = data?.startTime || '';
    this.endTime = data?.endTime || '';
    this.excerciseCategory = new ExerciseLogMenu(data?.excerciseCategory);
    this.excerciseTime = this.startTime && this.endTime
      ? timeMoment(this.endTime).diff(timeMoment(this.startTime), 'minutes')
      : HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_DEFAULT_VALUE;
    this.avgHeartRate = data?.avgHeartRate || 0;
    this.maxHeartRate = data?.maxHeartRate || 0;
    this.noOfTimes = data?.noOfTimes || 0;
    this.caloriesBurn =
      data?.caloriesBurn ||
      HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_DEFAULT_VALUE;
    this.distCovered = data?.distCovered || 0;
    this.avgSpeed = data?.avgSpeed || 0;
    this.type =
      MAP_AMWAY_SUGGEST_AND_SUGGEST[data?.type] || LABEL_EN_SUGGEST_LOG_TAB;
    this.customers = data?.customers || '';
    this.excerciseGoal = new ExerciseGoal(data?.excerciseGoal);
    this.createdDate = data?.createdDate || '';
    this.updatedDate = data?.updatedDate || '';
    this.userExerciseMenuIds =
      this.excerciseGoal?.activities
        ?.flatMap((category) => category?.exerciseMenu)
        ?.map((menu) => menu?.exerciseId) || [];
    this.image = data?.excerciseCategory?.image
      ? checkImageURL(data.excerciseCategory?.image)
      : '';
    this.suggestionTime = data?.excerciseCategory?.suggestionTime || '';
    this.name = data?.excerciseCategory?.name || '';
    this.amount =
      data?.caloriesBurn ||
      HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_DEFAULT_VALUE;
  }
}

export class ExerciseGoal {
  constructor(data) {
    this.targetCalories = data?.targetCalories || null;
    this.exerciseTime = data?.exerciseTime || null;
    this.id = data?._id || '';
    this.activities = data?.activities
      ? data?.activities?.map((activity) => new ExerciseGoalActivity(activity))
      : [];
    this.customers = data?.customers || '';
    this.createdDate = data?.createdDate || '';
    this.updatedDate = data?.updatedDate || '';
    this.myActivityMenu =
      this.activities?.flatMap((activity) => activity?.exerciseMenu) || [];
  }
}

export class MyGoalExerciseMenu {
  constructor(data) {
    this.exerciseMenuId = data?.id || '';
    this.exerciseMenuName = data?.name || '';
    this.calorieBurnTime = data?.calorieBurnTime || null;
    this.suggestionTime = data?.suggestionTime || null;
    this.image = data?.image ? checkImageURL(data.image) : '';
    this.exerciseCatName =
      data?.excerciseCategory?.exerciseCategory?.name || '';
    this.startTime = data?.startTime || '';
    this.endTime = data?.endTime || '';
    this.type = data?.type || '';
    this.caloriesBurn = data?.caloriesBurn || null;
    this.differenceInMinutes = data?.differenceInMinutes || 0;
  }
}
export class ExerciseGoalActivity {
  constructor(data) {
    this.exerciseMenu = data?.exerciseMenu
      ? data?.exerciseMenu?.map((eMenu) => new ExerciseLogMenu(eMenu))
      : [];
    this.exerciseCategory = data?.exerciseCategory || '';
  }
}

export class ExerciseLogMenu {
  constructor(data) {
    this.id = data?._id || '';
    this.exerciseId = data?.exerciseId || '';
    this.name = data?.name || '';
    this.calorieBurnTime = data?.calorieBurnTime || 0;
    this.suggestionTime = data?.suggestionTime || 0;
    this.image = data?.image ? `${config.S3_URL}${data.image}` : '';
    this.exerciseCategory = data?.exerciseCategory || '';
    this.exerciseTemplate = data?.exerciseTemplate || '';
    this.exerciseTemplateBannerImage = data?.exerciseTemplateBannerImage ? `${config.S3_URL}${data.exerciseTemplateBannerImage}` : '';
    this.exerciseTemplates = data?.exerciseTemplates
      ? data?.exerciseTemplates?.map(
        (template) => new ExerciseLogTemplate(template),
      )
      : [];
  }
}
export class ExerciseLogTemplate {
  constructor(data) {
    this.id = data?._id || '';
    this.inputType = data?.inputType || '';
    this.startText = data?.startText || '';
    this.startImage = data?.startImage
      ? `${config.S3_URL}${data.startImage}`
      : '';
    this.endText = data?.endText || '';
    this.endTextImage = data?.endTextImage
      ? `${config.S3_URL}${data.endTextImage}`
      : '';
    this.createdDate = data?.createdDate || '';
    this.updatedDate = data?.updatedDate || '';
    this.newSection = data?.newSection || false;
    this.fieldKey = data?.fieldKey || '';
    this.minRange = data?.minRange || 1;
    this.maxRange = data?.maxRange || 999;
  }
}

export class CreateOrUpdateExerciseLogModel {
  constructor(data) {
    this.excerciseCategory = data?.excerciseCategory || '';
    this.type = MAP_SUGGEST_AND_AMWAY_SUGGEST[data?.type] || AMWAY_SUGGEST_TYPE;
    this.startTime = data?.startTime || '';
    this.endTime = data?.endTime || '';
    if (this.type === EXERCISE_LOG_DETAIL.AMWAY_SUGGEST_TYPE) {
      this.excerciseTime = data?.excerciseTime ? `${data?.excerciseTime}` : '';
      this.caloriesBurn = parseInt(data?.caloriesBurn);
    } else if (this.type === EXERCISE_LOG_DETAIL.MANUAL_TYPE) {
      if (data?.noOfTimes) this.noOfTimes = parseInt(data?.noOfTimes);
      if (data?.calorie) this.caloriesBurn = parseInt(data?.calorie);
      if (data?.avgHeartRate) this.avgHeartRate = parseInt(data?.avgHeartRate);
      if (data?.maxHeartRate) this.maxHeartRate = parseInt(data?.maxHeartRate);
      if (data?.distCovered) this.distCovered = parseFloat(data?.distCovered);
      if (data?.avgSpeed) this.avgSpeed = parseInt(data?.avgSpeed);
    }
  }
}
