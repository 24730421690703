import { CHUNK_SIZE } from "../../common/challengeConstants";
import { getChallengeVideos, getYoutubeVideoDetails, updateChallengeVideo } from "../../services/challenge/challengeVideo.service";
import { chunkArray } from "../../utill.func";
import { GET_CHALLENGE_VIDEOS, GET_CHALLENGE_VIDEOS_FAILURE, GET_CHALLENGE_VIDEOS_SUCCESS, GET_YOUTUBE_VIDEOS, GET_YOUTUBE_VIDEOS_FAILURE, GET_YOUTUBE_VIDEOS_SUCCESS, UPDATE_VIDEO_STATUS, UPDATE_VIDEO_STATUS_FAILURE, UPDATE_VIDEO_STATUS_SUCCESS } from "../types";

export const fetchChallengeVideos = (params) => async (dispatch) => {
  dispatch({ type: GET_CHALLENGE_VIDEOS });

  try {
    const result = await getChallengeVideos(params);
    dispatch({
      type: GET_CHALLENGE_VIDEOS_SUCCESS,
      payload: result,
    });

    const videoIds = result.map((video) => video.videoId);
    const chunks = chunkArray(videoIds, CHUNK_SIZE);

    let youtubeDetails = [];
    for (const chunk of chunks) {
      dispatch({ type: GET_YOUTUBE_VIDEOS });
      const youtubeResult = await getYoutubeVideoDetails(chunk);
      youtubeDetails = [...youtubeDetails, ...youtubeResult];
    }
    
    const mergedData = result.map((video) => {
      const youtubeDetail = youtubeDetails.find(
        (detail) => detail.id === video.videoId
      );
      if (youtubeDetail) {
        video.youtubeDetails = {
          title: youtubeDetail.snippet?.title,
          thumbnail: youtubeDetail.snippet?.thumbnails?.high?.url,
          duration: youtubeDetail.contentDetails?.duration,
        };
      }
      return video;
    });

    dispatch({
      type: GET_YOUTUBE_VIDEOS_SUCCESS,
      payload: mergedData,
    });

    return mergedData;
  } catch (error) {
    dispatch({ type: GET_CHALLENGE_VIDEOS_FAILURE, payload: error.message });
    dispatch({
      type: GET_YOUTUBE_VIDEOS_FAILURE,
      payload: error.message,
    })
  };
};

export const updateVideoStatus = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_VIDEO_STATUS });

  try {
    const result = await updateChallengeVideo(data);
    dispatch({
      type: UPDATE_VIDEO_STATUS_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_VIDEO_STATUS_FAILURE,
      payload: error.message,
    });
  }
};