import React from 'react';
import '../modal.scss';

export default function CustomModal({ isOpen, children, className = null }) {
  return (
    <div className={`line-modal ${className || ""}`}>
      <div className="modal fade show badge-step-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </div>
  );
}
