import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import LoadingOverlay from 'react-loading-overlay';
import { isEqual } from 'lodash';

import { policyFetch, customerFetch, validateCart, calculateCart, customerFindByNumber } from '../../../actions';
import { Card, Checkbox, Modal } from '../../../helpers';
import { commonConstant } from '../../../common';
import { activityService } from '../../../services';

import './ConfirmPayment.scss';
import AmwayRedirect from '../../redirect/Redirect';
import { excludeProductEntries } from '../../../helpers/Products/excludeProductEntries';

export const RenewConfirmPayment = () => {
  const cartRef = useRef();
  const [showConsent, setShowConsent] = useState(false);
  const [isCalculated, setCalculated] = useState(false);
  const [accept, setAccept] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState();
  const [modalContent, setModalContent] = useState();
  const { customer, cart, pickStore, find } = useSelector(state => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const sendAvtivity = useCallback((status) => {
    activityService.create({
      uid: customer.uid,
      userId: customer._id,
      status: status,
      action: 'CONFIRM_PAYMENT_FOR_MAGAZINE',
    });
  });

  useEffect(() => {
    // dispatch(addrFetch(customer.amwayNumber));
    dispatch(customerFindByNumber(customer.amwayNumber));
    dispatch(customerFetch(customer.amwayNumber));
    dispatch(validateCart(customer.partyId, cart.data.code));
    cartRef.current = cart;
  }, []);

  useEffect(() => {
    if (cart.validate && !isEqual(cart.validate, cartRef.current.validate) && cart.validate.code === 200) {
      dispatch(calculateCart(customer.partyId, cart.data.code));
    } else if (cart.calculated && cart.calculated !== cartRef.current.calculated) {
      setCalculated(true);
    } else if (cart.validate && cart.validate.code !== 200) {
      handleToggleModal('ERROR');
    }
    cartRef.current = cart;
  }, [cart]);

  useEffect(() => {
    if (showConsent) {
      dispatch(policyFetch({ accountSubTypeCd: customer.type === 'member' ? 'MEMBER' : 'BusinessOwner' }));
    }
  }, [showConsent]);

  useEffect(() => {
  }, [find]);

  const handleToggleModal = (content) => {
    setModalContent(content);
    setShowModal(show => !show);
  };

  // if (!address.data){
  //   return <div> <LoadingOverlay
  //     active={true}
  //     spinner
  //     text="Loading"
  //     styles={{ wrapper: { width: '100%', height: '100%', position: 'absolute', pointerEvents: 'none' } }}
  //   /></div>;
  // } else {
  //   const addrList = address.data.addressBookList[0].addressList[0];
  //   addr = `${` ${addrList.addrStreet}`}${` ${addrList.addrLineTwo}`}${` ${addrList.addrLineThree}`}${` ${addrList.addrLineFour}`} ${addrList.cityName} ${addrList.postalCd}`;
  // }

  if (!cart.data || !cart.calculated || !find.accountMst) {
    return <div> <LoadingOverlay
      active={true}
      spinner
      text="Loading"
      styles={{ wrapper: { width: '100%', height: '100%', position: 'absolute', pointerEvents: 'none' } }}
    /></div>;
  }
  if (showConsent) {
    return <PaymentConsent onClick={() => setShowConsent(!showConsent)} />;
  } else if (redirect) {
    sendAvtivity('complete');
    return <AmwayRedirect redirect={`/checkout/mobile/2c2p?cartCode=${cart.data.code}`} />;
  }

  const handleAddressRender = ({ type, deliveryType }) => {
    switch (type) {
      case 'electronic':
        return (
          <>
            <div className="card-header">
              ชุดเริ่มต้นธุรกิจ
            </div>
            <div className="card-body">
              <div className="header">[ E - Starter Kit ]</div>
              <div>
                รับส่วนลด 150 บาท ในการซื้อสินค้า
              </div>
            </div>
          </>
        );
      case 'physical':
        if (deliveryType === 'atShop' && cart.data.pickupOrderGroups) {
          return (
            <>
              <div className="card-header">
                ข้อมูลการจัดส่ง
              </div>
              <div className="card-body">
                <div className="header">[ {cart.data.deliveryMode.name} ]</div>
                <div>
                  {pickStore.data.entry.deliveryPointOfService.displayName}
                </div>
                <div>
                  {pickStore.data.entry.deliveryPointOfService.address.line1.replace(/<\/?[^>]+(>|$)/g, '')}
                </div>
              </div>
            </>
          );
        }
        return (
          <>
            <div className="card-header">
              ข้อมูลการจัดส่ง
            </div>
            <div className="card-body">
              <div className="header">[ จัดส่งที่อยู่บ้าน (ที่อยู่ปัจจุบัน) ]</div>
              <div>
                ชุดคู่มือนักธุรกิจแอมเวย์แบบรูปเล่ม
              </div>
              <div>
                {cart.data.account.primaryParty.defaultAddress.formattedAddress}
              </div>
            </div>
          </>
        );
      default:
        return;
    }
  };

  const handleModalContent = () => {
    switch (modalContent) {
      case 'ERROR':
        return {
          content: 'เกิดข้อพิดพลาด กรุณาลองใหม่ในภายหลัง',
          type: 'danger',
          cancleBtn: false,
          okBtn: true,
          okText: 'ปิด',
          fnOk: () => history.replace(commonConstant.pathChooseMag),
        };
      case 'NO_PHONE':
        return {
          content: ' ไม่พบข้อมูลเบอร์มือถือ กรุณาติดต่อ Amway Contact Center 027258000',
          type: 'danger',
          cancleBtn: false,
          okBtn: true,
          okText: 'โทร',
          fnOk: () => window.location = 'tel:027258000',
        };
      default:
        return;
    }
  };

  const date = new Date(find.partyList[0].partyMst.birthdate);
  const formattedDate = `${date.getDate()} ${commonConstant.MONTHS.th[date.getMonth()]} ${date.getFullYear() + 543}`;
  const content = handleModalContent();
  return (
    <div className="confirmpayment-container">
      {

        showModal && <Modal
          content={content.content}
          type={content.type}
          fnClose={() => setShowModal(false)}
          fnOk={content.fnOk}
          fnCancel={content.cancleBtn ? () => setShowModal(false) : null}
          closeBtn={content.closeBtn}
          okBtn={content.okBtn}
          okText={content.okText}
          cancleText="ยกเลิก" />
      }
      <div className="header">
        ตรวจสอบข้อมูลก่อนชำระเงิน
      </div>
      <div className="body">
        <Card className="price-card">
          <div className="card-body">
            {
              cart.data.entries.filter(excludeProductEntries).map(data => (
                <div className="product" key={data.aliasCode}>
                  <div>{data.product.name}</div>
                  <div className="price">{data.basePrice.formattedValue}</div>
                </div>
              ))
            }
          </div>
        </Card>
        <Card className="addr-card">
          {handleAddressRender(location.state)}
        </Card>
        <Card className="info-card">
          <div className="card-header">
            ข้อมูลส่วนตัว
          </div>
          <div className="card-body">
            <div className="personal-info">
              <div className="label">ชื่อ - สกุล ( ภาษาไทย )</div>
              <div>{find.accountMst.accountName}</div>
            </div>
            <div className="personal-info">
              <div className="label">วัน เดือน ปี เกิด</div>
              <div>{formattedDate}</div>
            </div>
            <div className="personal-info">
              {
                find.partyList[0].phoneList.length !== 0 && <div className="personal-info">
                  <div className="label">เบอร์โทรศัพท์</div>
                  <div>{find.partyList[0].phoneList[0].phoneLocalNum}</div>
                </div>
              }
            </div>
            <div className="personal-info">
              <div className="label">อีเมล</div>
              <div>-</div>
            </div>
            <div className="personal-info">
              {
                find.partyList[0].taxList.length !== 0 && <>
                  <div className="label">เลขที่บัตรประชาชน</div>
                  <div>{find.partyList[0].taxList[0].taxTypeCd === 'CID' ? find.partyList[0].taxList[0].taxId : ''}</div>
                </>
              }
            </div>
            <div className="personal-info">
              {
                find.partyList[0].partyMst.genderCd && <>
                  <div className="label">เพศ</div>
                  <div>{commonConstant.GENDER.th[find.partyList[0].partyMst.genderCd.toUpperCase()]}</div>
                </>
              }
            </div>
          </div>

        </Card>
        <Card className="curraddr-card">
          <div className="card-header">
            ที่อยู่ปัจจุบัน
          </div>
          <div className="card-body">
            {cart.data.account.primaryParty.defaultAddress.formattedAddress}
          </div>
        </Card>
        <div className="remark">
          * หากต้องการแก้ไขข้อมูลส่วนตัวต้องไปที่ช็อป
        </div>
      </div>
      <div className="footer">
        <Checkbox checked={accept} onClick={() => setAccept(accept => !accept)}>คุณยอมรับ <div className="link" onClick={() => setShowConsent(true)}>เงื่อนไขการต่ออายุ{customer.type === 'abo' ? 'นักธุรกิจแอมเวย์' : 'สมาชิกแอมเวย์'}</div></Checkbox>
        <button onClick={isCalculated && accept ? () => setRedirect(true) : null} className={`away-button ${isCalculated && accept && 'active'}`}>{isCalculated ? 'ชำระเงิน' : 'loading'}</button>
      </div>
    </div>
  );
};

const PaymentConsent = ({ onClick }) => {
  const { policy, customer } = useSelector(state => state);
  if (!policy.data) {
    return <div> <LoadingOverlay
      active={true}
      spinner
      text="Loading"
      styles={{ wrapper: { width: '100%', height: '100%', position: 'absolute', pointerEvents: 'none' } }}
    /></div>;
  }
  return (
    <div className="payment-consent-container">
      <Card>
        <div className="card-header">
          เงื่อนไขและข้อตกลง
        </div>
        <div className="card-body">
          <div className="header">
            เงื่อนไขการต่ออายุ{customer.type === 'abo' ? 'นักธุรกิจแอมเวย์' : 'สมาชิกแอมเวย์'}
          </div>
          <div>
            {ReactHtmlParser(policy.data.contractDetail.verbiage)}
          </div>
        </div>
      </Card>
      <div className="card-footer">
        <button className="away-button active" onClick={onClick}>กลับ</button>
      </div>
    </div>
  );
};
