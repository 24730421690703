import React from 'react';
import { Modal, Button } from 'antd';
import './modal.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { commonConstant } from '../../../common';
import { EContentType, EModalTitle } from '../common.library';

const ModalContentSuccess = (props) => {
  const { visibleModal, setVisibleModal, setCategoryId, aboContent, title } =
    props;
  const history = useHistory();
  const location = useLocation();

  const handleCancle = () => {
    setVisibleModal(false);
    if (title === EModalTitle.CREATE_SUCCESS) {
      history.push({
        pathname: commonConstant.pathLibrarySubContentList,
        search: `id=${setCategoryId}`,
        state: { isAmwayContent: false },
      });
    }
    if (title === EModalTitle.UPDATE_SUCCESS) {
      history.push({
        pathname: commonConstant.pathLibraryViewContent,
        search: `aboId=${aboContent._id}`,
      });
    }
  };

  const handleButton = () => {
    setVisibleModal(false);
    history.push({
      pathname: commonConstant.pathLibraryProfile,
      search: `currentTab=${EContentType.MY_LIBRARY}`,
    });
  };

  return (
    <Modal
      className="warp-modal"
      open={visibleModal}
      centered
      footer={null}
      onCancel={handleCancle}
      closable={true}
      maskClosable={false}
    >
      <div className="modal-image-icon">
        <img src="../../images/library/icon_createSave_content.svg"></img>
      </div>
      <div className="modal-title">{title}</div>
      {title === EModalTitle.CREATE_SUCCESS ? (
        <div className="modal-desc">
          สร้าง Content สำเร็จ
          <br /> สามารถดูข้อมูลได้ที่หน้าหน้า My Library
        </div>
      ) : (
        <div className="modal-desc">
          บันทึกข้อมูลสำเร็จ
          <br /> สามารถดูข้อมูลได้ที่หน้าหน้า My Library
        </div>
      )}
      <Button
        className="btn"
        onClick={() => {
          handleButton();
        }}
        block
      >
        My Library
      </Button>
    </Modal>
  );
};
export default ModalContentSuccess;
