import React from "react";
import { CHALLENGE } from "../../../../common/challengeConstants";

const VdoBadgeDetail = ({ disabled }) => {

    const renderActiveVdoBadgeDetail = () => {
        return (
            <p>
                {CHALLENGE.badges.activeMissionBadgeHeading}<br />
                {CHALLENGE.badges.activeVdoBadgeSubHeading}<br />
                {CHALLENGE.badges.bodyKey10Heading}
            </p>
        )
    }

    const renderInactiveVdoBadgeDetail = () => {
        return (
            <p>
                {CHALLENGE.badges.inactiveMissionBadgeHeading}<br />
                {CHALLENGE.badges.inactiveVdoSubHeading}<br />
                {CHALLENGE.badges.bodyKey10Heading}
            </p>
        )
    }

    return (
        disabled ? renderInactiveVdoBadgeDetail() : renderActiveVdoBadgeDetail()
    )
}

export default VdoBadgeDetail;