import React from 'react';

export default function HnwTimeLogData({ timeLog, routeTo }) {
  return (
    <div className="devices-single">
      <div className="device-single-left">
        <img
          src="/images/healthAndWellness/goal-setting/icons/burn.svg"
          width={50}
          height={50}
        />
        <div className="device-name">
          {timeLog?.startTime ? timeLog?.startTime : '-'}
          {' - '}
          {timeLog?.endTime ? timeLog?.endTime : '-'} น.
        </div>
      </div>
      <a
        className="btn-icon-round"
        onClick={() =>
          routeTo(
            'pathHnwExerciseLogDetail',
            `?exerciseMenuId=${timeLog?.excerciseCategory?.id}&exerciseLogId=${timeLog?.id}&type=${timeLog?.type}`,
          )
        }
      >
        <img src="/images/healthAndWellness/exercise-log/icons/green-arrow.svg" />
      </a>
    </div>
  );
}
