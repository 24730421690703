import React, { useEffect, useRef } from 'react';

export const AmwaySeamlessRedirect = ({ redirect }) => {
  const ref = useRef();
  useEffect(() => {
    ref.current.submit();
  }, []);
  return (
    <div>
      Loading...
      <form
        ref={ref}
        id="appForm"
        method="post"
        action={`${process.env.REACT_APP_SEAMLESS_ACTION}`}
      >
        <input
          type="hidden"
          name="state"
          value={`${process.env.REACT_APP_SEAMLESS_STATE}`}
        />
        <input
          type="hidden"
          name="nonce"
          value={`${process.env.REACT_APP_SEAMLESS_NONCE}`}
        />
        <input
          type="hidden"
          name="client_id"
          id="client_id"
          value={`${process.env.REACT_APP_SEAMLESS_CLIENT_ID}`}
        />
        <input
          type="hidden"
          name="redirect_uri"
          id="redirect_uri"
          value={`${process.env.REACT_APP_SEAMLESS_REDIRECT_URL}`}
        />
        <input
          type="hidden"
          name="redirectTargetUrl"
          value={redirect}
        />
        <input style={{ display: 'none' }} type="submit" value="submit" />
      </form>
    </div>
  );
};

export default AmwaySeamlessRedirect;
