export const PROFILE_FETCH = 'profile_fetch';
export const CUSTOMER_CREATE = 'customer_create';
export const CUSTOMER_MEMBER = 'customer_checkmember';
export const CUSTOMER_FETCH = 'customer_fetch';
export const CUSTOMER_FETCH_LOADER = 'customer_fetch_loader';
export const CUSTOMER_UPDATE = 'customer_update';
export const GET_LAST_MONTH_BMI = 'get_last_month_bmi';
export const GET_CUSTOMER_MONTHLY_BMI = 'get_customer_monthly_bmi';
export const GET_MONTHLY_BMI_LIST = 'get_monthly_bmi_list';
export const GET_CUSTOMER_BMI = 'get_customer_bmi';
export const UPDATE_BMI = 'UPDATE_BMI';
export const CUSTOMER_FIND = 'customer_find';
export const SET_CUSTOMER_AUTO_SYNC = 'set_customer_auto_sync';

export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_CLEAR';

export const COUPON_FETCH = 'COUPON_FETCH';

export const PAYMENT_CREATE = 'payment_create';

export const SELECT_SOP = 'SELECT_SOP';
export const ADD_CART = 'ADD_CART';
export const REMOVE_CART = 'REMOVE_CART';

export const ADDR_FETCH = 'ADDR_FETCH';
export const POLICY_FETCH = 'POLICY_FETCH';

export const OTP_FETCH = 'OTP_FETCH';
export const VERIFY_OTP_FETCH = 'VERIFY_OTP_FETCH';

export const CHECK_RENEWAL = 'CHECK_RENEWAL';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const CREATE_CART = 'CREATE_CART';
export const ADD_ITEM = 'ADD_ITEM';
export const STORE_FETCH = 'STORE_FETCH';
export const VALIDATE_CART = 'VALIDATE_CART';
export const CAL_CART = 'CAL_CART';
export const PICK_STORE = 'PICK_STORE';
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY';

export const ADD_UPGRADE = 'ADD_UPGRADE';
export const CHANGE_PHONE = 'CHANGE_PHONE';
export const SET_RICHMENU = 'SET_RICHMENU';
export const CREATE_BANK = 'CREATE_BANK';

// ------------------ASSESSMENT TYPES -----------------------
export const FETCH_HNW_ASSESSMENT_LIST = 'FETCH_HNW_ASSESSMENT_LIST';
export const FETCH_HNW_ASSESSMENT = 'FETCH_HNW_ASSESSMENT';
export const UPDATE_HNW_ANSWER = 'UPDATE_HNW_ANSWER';
export const FETCH_HNW_ASSESSMENT_DETAIL = 'FETCH_HNW_ASSESSMENT_DETAIL';
export const FETCH_HNW_ASSESSMENT_HISTORY_LOG =
  'FETCH_HNW_ASSESSMENT_HISTORY_LOG';
export const DELETE_HNW_ASSESSMENT_DETAIL = 'DELETE_HNW_ASSESSMENT_DETAIL';
export const GET_HNW_SCORE = 'GET_HNW_SCORE';
export const FETCH_CUSTOMER_PIF_CART = 'FETCH_CUSTOMER_PIF_CART';
export const ADD_PRODUCT = 'ADD_PRODUCT';

export const DELETE_PROFILE_DATA = 'DELETE_PROFILE_DATA';
export const GET_HNW_PRODUCTS = 'GET_HNW_PRODUCTS';
export const GET_BODYKEY_CHALLENGES = 'GET_BODYKEY_CHALLENGES';

// ------------------FOOD CALORIE TYPES ----------------------
export const GET_FOOD_DETAIL = 'GET_FOOD_DETAIL';
export const GET_FOOD_CATEGORIES = 'GET_FOOD_CATEGORIES';
export const GET_FOOD_PRODUCTS = 'GET_FOOD_PRODUCTS';
export const EMPTY_PRODUCTS_REQUEST = 'EMPTY_PRODUCTS_REQUEST';
export const GET_SEARCH_PRODUCTS = 'GET_SEARCH_PRODUCTS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_HISTORY_KEYWORDS = 'GET_HISTORY_KEYWORDS';
export const GET_RECOMMENDED_PRODUCTS = 'GET_RECOMMENDED_PRODUCTS';

// ------------------FOOD LOG ----------------------
export const ADD_FOOD_MENU = 'ADD_FOOD_MENU';
export const ADD_FOOD_IMAGE = 'ADD_FOOD_IMAGE';
export const ADD_FOOD_LOG = 'ADD_FOOD_LOG';
export const GET_TARGET_CALORIES = 'GET_TARGET_CALORIES';
export const DELETE_FOOD_LOG = 'DELETE_FOOD_LOG';

// --------------------GOAL SETTING-----------------------------
export const FETCH_HNW_HEALTH_GOAL_LIST = 'GET_HEALTH_GOAL_LIST';
export const UPDATE_HEALTH_LIST = 'UPDATE_HEALTH_LIST';
export const DELETE_GOAL = 'DELETE_GOAL';

export const GET_WEIGHT_GOAL = 'GET_WEIGHT_GOAL';
export const UPDATE_WEIGHT_GOAL = 'UPDATE_WEIGHT_GOAL';
export const GET_WATER_GOAL = 'GET_WATER_GOAL';
export const UPDATE_WATER_GOAL = 'UPDATE_WATER_GOAL';

export const GET_NUTRITION_GOAL = 'GET_NUTRITION_GOAL';
export const UPDATE_NUTRITION_GOAL = 'UPDATE_NUTRITION_GOAL';

export const GET_LOG_LIST = 'GET_LOG_LIST';
export const GET_EXERCISE_CATEGORIES = 'GET_EXERCISE_CATEGORIES';
export const GET_EXERCISE_LOG_LIST = 'GET_EXERCISE_LOG_LIST';
export const GET_EXERCISE_DETAILS = 'GET_EXERCISE_DETAILS';
export const GET_SUPPLEMENTS_GOAL = 'GET_SUPPLEMENTS_GOAL';
export const UPDATE_SUPPLEMENTS_GOAL = 'UPDATE_SUPPLEMENTS_GOAL';

export const POST_ACTIVITY_GOAL = 'POST_ACTIVITY_GOAL';
export const PUT_ACTIVITY_GOAL = 'PUT_ACTIVITY_GOAL';
export const GET_ACTIVITY_GOAL = 'GET_ACTIVITY_GOAL';
export const GET_EXERCISE_MENU_GOAL = 'GET_EXERCISE_MENU_GOAL';
export const RESET_EXERCISE_MENU_GOAL = 'RESET_EXERCISE_MENU_GOAL';

// -------------------FOOD LOG OVERVIEW-------------------------

export const EMPTY_FOOD_LOGS_REQUEST = 'EMPTY_FOOD_LOGS_REQUEST';
export const GET_WATER_LOGS = 'GET_WATER_LOGS';
export const GET_WATER_LOGS_ERROR = 'GET_WATER_LOGS';
export const GET_FOOD_LOGS = 'GET_FOOD_LOGS';
export const GET_FOOD_LOGS_ERROR = 'GET_FOOD_LOGS';
export const POST_WATER_LOGS = 'POST_WATER_LOGS';
export const POST_WATER_LOGS_ERROR = 'POST_WATER_LOGS_ERROR';
export const GET_WATER_VOLUME = 'GET_WATER_VOLUME';
export const GET_WATER_VOLUME_ERROR = 'GET_WATER_VOLUME_ERROR';
export const POST_WATER_VOLUME = 'POST_WATER_VOLUME';
export const POST_WATER_VOLUME_ERROR = 'POST_WATER_VOLUME_ERROR';
export const UPDATE_WATER_VOLUME = 'UPDATE_WATER_VOLUME';
export const UPDATE_WATER_VOLUME_ERROR = 'UPDATE_WATER_VOLUME_ERROR';
export const GET_FOOD_LOG_DATA = 'GET_FOOD_LOG_DATA';
export const GET_FOOD_LOG_DATA_ERROR = 'GET_FOOD_LOG_DATA_ERROR';
export const UPDATE_HEALTH_DATA = 'UPDATE_HEALTH_DATA';
// ---------------------- FOOD LOG ---------------------------

export const GET_FOOD_CALENDAR_DATA = 'GET_FOOD_CALENDAR_DATA';
export const GET_EXERCISE_CALENDAR_DATA = 'GET_EXERCISE_CALENDAR_DATA';

export const CLEAR_EXERCISE_LOG_TYPE = 'CLEAR_EXERCISE_LOG_TYPE';
export const CREATE_EXERCISE_LOG_TYPE = 'CREATE_EXERCISE_LOG_TYPE';
export const UDPATE_EXERCISE_LOG_TYPE = 'UDPATE_EXERCISE_LOG_TYPE';
export const GET_USER_ACTIVITY_GOAL = 'GET_USER_ACTIVITY_GOAL';

export const GET_EXERCISE_CATEGORY_LIST_TYPE =
  'GET_EXERCISE_CATEGORY_LIST_TYPE';
export const GET_EXERCISE_MENU_LIST_TYPE = 'GET_EXERCISE_MENU_LIST_TYPE';
export const GET_EXERCISE_MENU_AND_TEMPLATES_TYPE =
  'GET_EXERCISE_MENU_AND_TEMPLATES_TYPE';
export const GET_EXERCISE_TIMELOG_LIST_TYPE = 'GET_EXERCISE_TIMELOG_LIST_TYPE';



export const GET_EXERCISE_LOG_BY_ID_TYPE = 'GET_EXERCISE_LOG_BY_ID_TYPE';
export const GET_PREV_EXERCISE_LOG_BY_ID_TYPE =
  'GET_PREV_EXERCISE_LOG_BY_ID_TYPE';
export const DELETE_EXERCISE_LOG_TYPE = 'DELETE_EXERCISE_LOG_TYPE';
export const GET_SUGGESTED_EXERCISE_MENU_LIST_TYPE =
  'GET_SUGGESTED_EXERCISE_MENU_LIST_TYPE';

export const GET_USER_ACTIVITY_GOAL_SUCCESS = 'GET_USER_ACTIVITY_GOAL_SUCCESS';
export const GET_USER_ACTIVITY_GOAL_FAILURE = 'GET_USER_ACTIVITY_GOAL_FAILURE';
export const GET_EXERCISE_TIMELOG_LIST_TYPE_SUCCESS = 'GET_EXERCISE_TIMELOG_LIST_TYPE_SUCCESS';
export const GET_EXERCISE_TIMELOG_LIST_TYPE_FAILURE = 'GET_EXERCISE_TIMELOG_LIST_TYPE_FAILURE';

// ------------------ CHALLENGE -----------------------

export const FETCH_CHALLENGE_TEAM_DETAIL = 'FETCH_CHALLENGE_TEAM_DETAIL';
export const FETCH_CHALLENGE_TEAM_SUCCESS = 'FETCH_CHALLENGE_TEAM_SUCCESS';
export const FETCH_CHALLENGE_TEAM_FAILURE = 'FETCH_CHALLENGE_TEAM_FAILURE';
export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';
export const FETCH_USER_DETAIL_SUCCESS = 'FETCH_USER_DETAIL_SUCCESS';
export const FETCH_USER_DETAIL_FAILURE = 'FETCH_USER_DETAIL_FAILURE';

export const FETCH_CHALLENGES_LIST_SUCCESS = 'FETCH_CHALLENGES_LIST_SUCCESS';
export const FETCH_CHALLENGES_LIST_ONGOING_SUCCESS = 'FETCH_CHALLENGES_LIST_ONGOING_SUCCESS';
export const FETCH_CHALLENGES_LIST_UPCOMING_SUCCESS = 'FETCH_CHALLENGES_LIST_UPCOMING_SUCCESS';
export const FETCH_CHALLENGES_LIST_FAILURE = 'FETCH_CHALLENGES_LIST_FAILURE';

export const GET_CHALLENGE_VIDEOS = 'GET_CHALLENGE_VIDEOS';
export const GET_CHALLENGE_VIDEOS_SUCCESS = 'GET_CHALLENGE_VIDEOS_SUCCESS';
export const GET_CHALLENGE_VIDEOS_FAILURE = 'GET_CHALLENGE_VIDEOS_FAILURE';

export const FETCH_CHALLENGE_QUIZ = 'FETCH_CHALLENGE_QUIZ';
export const FETCH_CHALLENGE_QUIZ_SUCCESS = 'FETCH_CHALLENGE_QUIZ_SUCCESS';
export const FETCH_CHALLENGE_QUIZ_FAILURE = 'FETCH_CHALLENGE_QUIZ_FAILURE';
export const UPDATE_CHALLENGE_QUIZ_ANSWERS = 'UPDATE_CHALLENGE_QUIZ_ANSWERS';
export const UPDATE_CHALLENGE_QUIZ_ANSWERS_SUCCESS = 'UPDATE_CHALLENGE_QUIZ_ANSWERS_SUCCESS';
export const UPDATE_CHALLENGE_QUIZ_ANSWERS_FAILURE = 'UPDATE_CHALLENGE_QUIZ_ANSWERS_FAILURE';
export const FETCH_UPDATED_ANSWER = 'FETCH_UPDATED_ANSWER';
export const FETCH_UPDATED_ANSWER_SUCCESS = 'FETCH_UPDATED_ANSWER_SUCCESS';
export const FETCH_UPDATED_ANSWER_FAILURE = 'FETCH_UPDATED_ANSWER_FAILURE';

export const FETCH_CHALLENGES_DETAIL_SUCCESS = 'FETCH_CHALLENGES_DETAIL_SUCCESS';
export const FETCH_CHALLENGES_DETAIL_FAILURE = 'FETCH_CHALLENGES_DETAIL_FAILURE';
export const FETCH_CHALLENGES_PROFILE_SUCCESS = 'FETCH_CHALLENGES_PROFILE_SUCCESS';
export const FETCH_CHALLENGES_PROFILE_FAILURE = 'FETCH_CHALLENGES_PROFILE_FAILURE';

export const GET_YOUTUBE_VIDEOS = 'GET_YOUTUBE_VIDEOS';
export const GET_YOUTUBE_VIDEOS_SUCCESS = 'GET_YOUTUBE_VIDEOS_SUCCESS';
export const GET_YOUTUBE_VIDEOS_FAILURE = 'GET_YOUTUBE_VIDEOS_FAILURE';

export const SET_FOOTER_STATE_HOME = 'SET_FOOTER_STATE_HOME';
export const SET_FOOTER_STATE_CALORIES = 'SET_FOOTER_STATE_CALORIES';
export const SET_FOOTER_STATE_CHALLENGE = 'SET_FOOTER_STATE_CHALLENGE';
export const SET_FOOTER_STATE_EXERCISE = 'SET_FOOTER_STATE_EXERCISE';
export const SET_FOOTER_STATE_PROFILE = 'SET_FOOTER_STATE_PROFILE';

export const UPDATE_VIDEO_STATUS = 'UPDATE_VIDEO_STATUS';
export const UPDATE_VIDEO_STATUS_SUCCESS = 'UPDATE_VIDEO_STATUS_SUCCESS';
export const UPDATE_VIDEO_STATUS_FAILURE = 'UPDATE_VIDEO_STATUS_FAILURE';

export const GET_BODY_KEY_VERIFY = 'GET_BODY_KEY_VERIFY'
export const GET_BODY_KEY_VERIFY_SUCCESS = 'GET_BODY_KEY_VERIFY_SUCCESS'
export const GET_BODY_KEY_VERIFY_ERROR = 'GET_BODY_KEY_VERIFY_ERROR'

export const GET_BODY_KEY_RECORD = 'GET_BODY_KEY_RECORD'
export const GET_BODY_KEY_RECORD_SUCCESS = 'GET_BODY_KEY_RECORD_SUCCESS'
export const GET_BODY_KEY_RECORD_ERROR = 'GET_BODY_KEY_RECORD_ERROR'

export const POST_OTP_GENERATE_BODY_KEY = 'POST_OTP_GENERATE_BODY_KEY'
export const POST_OTP_GENERATE_BODY_KEY_SUCCESS = 'POST_OTP_GENERATE_BODY_KEY_SUCCESS'
export const POST_OTP_GENERATE_BODY_KEY_ERROR = 'POST_OTP_GENERATE_BODY_KEY_ERROR'

export const POST_OTP_VERIFY_BODY_KEY = 'POST_OTP_VERIFY_BODY_KEY'
export const POST_OTP_VERIFY_BODY_KEY_SUCCESS = 'POST_OTP_VERIFY_BODY_KEY_SUCCESS'
export const POST_OTP_VERIFY_BODY_KEY_ERROR = 'POST_OTP_VERIFY_BODY_KEY_ERROR'

export const GET_USER_UPLOADED_IMAGES = 'GET_USER_UPLOADED_IMAGES'
export const GET_USER_UPLOADED_IMAGES_SUCCESS = 'GET_USER_UPLOADED_IMAGES_SUCCESS'
export const GET_USER_UPLOADED_IMAGES_ERROR = 'GET_USER_UPLOADED_IMAGES_ERROR'

export const GET_PRE_SIGNED_URL_SUCCESS = 'GET_PRE_SIGNED_URL_SUCCESS'
export const GET_PRE_SIGNED_URL_ERROR = 'GET_PRE_SIGNED_URL_ERROR'
export const PUT_PRE_SIGNED_URL_SUCCESS = 'PUT_PRE_SIGNED_URL_SUCCESS'
export const PUT_PRE_SIGNED_URL_ERROR = 'PUT_PRE_SIGNED_URL_ERROR'
export const PUT_CONFIRM_UPLOAD_SUCCESS = 'PUT_CONFIRM_UPLOAD_SUCCESS'
export const PUT_CONFIRM_UPLOAD_ERROR = 'PUT_CONFIRM_UPLOAD_ERROR'

export const SYNC_USER_BADGE_COUNT_SUCCESS = 'SYNC_USER_BADGE_COUNT_SUCCESS'
export const SYNC_USER_BADGE_COUNT_FAILURE = 'SYNC_USER_BADGE_COUNT_FAILURE'
export const GET_SELF_CHALLENGE = 'GET_SELF_CHALLENGE';
export const GET_SELF_CHALLENGE_SUCCESS = 'GET_SELF_CHALLENGE_SUCCESS';
export const GET_SELF_CHALLENGE_FAILURE = 'GET_SELF_CHALLENGE_FAILURE';

export const GET_CUSTOMER_BMI_SUCCESS = 'GET_CUSTOMER_BMI_SUCCESS';
export const GET_CUSTOMER_BMI_FAILURE = 'GET_CUSTOMER_BMI_FAILURE';

export const GET_BODY_DATA_DIFFERENCE = 'GET_BODY_DATA_DIFFERENCE';
export const GET_BODY_DATA_DIFFERENCE_SUCCESS = 'GET_BODY_DATA_DIFFERENCE_SUCCESS';
export const GET_BODY_DATA_DIFFERENCE_FAILURE = 'GET_BODY_DATA_DIFFERENCE_FAILURE';

export const FETCH_AUTH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS';
export const FETCH_AUTH_TOKEN_FAIL = 'FETCH_TOKEN_FAIL';
