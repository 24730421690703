import React from "react";
import "./ProfileCardLoader.scss";

const ProfileCardLoader = () => {
  return (
    <div className="shimmer-home-profile-container">
      <div className="shimmer-square-float-btn">
        <div className="shimmer shimmer-img"></div>
      </div>
      <div className="shimmer-home-profile-details">
        <div className="shimmer shimmer-text shimmer-greeting"></div>
        <div className="shimmer shimmer-text shimmer-name"></div>
      </div>
    </div>
  );
};

export default ProfileCardLoader;
