
import React, { useState } from 'react';
import './AssessmentNotCompleted.scss';
import { DEFAULT_ASSESSMENT_NOTCOMPLETED_SCREEN, ASSESSMENT_TOTAL_QUESTION } from '../../../../common/commonConstant';

//TODO: change the exact number of assessment questions
export const AssessmentNotCompleted = ({ assessmentDetail, assessmentList, assessmentId, redirectToAssessment }) => {
    const questionCount = (parseInt(assessmentList?.hw_assessments?.length) + 4) || ASSESSMENT_TOTAL_QUESTION;
    const answerCount = (parseInt(assessmentDetail?.answers?.length) + 4) || 0;

    return (
        <div className='asm-not-com-ctn'>
            <div className='row'>
                <div className='col-12'>
                    <div className='not-com-header'>
                        {DEFAULT_ASSESSMENT_NOTCOMPLETED_SCREEN.header}
                    </div>
                    <div className='not-com-body-content'>
                        <div className='body-content'>
                            <div class="body-content-title">{DEFAULT_ASSESSMENT_NOTCOMPLETED_SCREEN.contentHeader}</div>
                            <div class="body-content-btn">{`${answerCount}/${questionCount} ข้อ`}</div>
                            <div class="body-content-subtitle">
                                <div class="subtitle1">{DEFAULT_ASSESSMENT_NOTCOMPLETED_SCREEN.subtitle1}</div>
                                <div class="subtitle2">{DEFAULT_ASSESSMENT_NOTCOMPLETED_SCREEN.subtitle2}</div>
                            </div>
                        </div>
                        <img src="/images/assesment/assessment_progress_card.svg" class="w-100" />
                    </div>

                    <div className='not-com-footer'>
                        <div className='btn1' onClick={() => redirectToAssessment(assessmentId)}>
                            <span >
                                {DEFAULT_ASSESSMENT_NOTCOMPLETED_SCREEN.btn1}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssessmentNotCompleted;
