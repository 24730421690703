import React, { useEffect, useRef } from 'react';
import './ChallengeCarousel.scss';
import Slider from 'react-slick';
import ChallengeCarouselLoader from '../challengeCarouselLoader/ChallengeCarouselLoader';
import { calculateDaysLeft, calculateTeamDashboardStatus, checkIsRewardPeriod, getChallengeTodayDate, getDataFromSession, getIsoFormattedDate, initializeLiff, thaiDate } from '../../../utill.func';
import ChallengeUsers from '../challengeUsers/ChallengeUsers.jsx';
import ChallengeUsersLoader from '../challengeUsers/ChallengeUsersLoader';
import { CAROUSEL_CARD_DATES, CHALLENGE, CHALLENGE_LIST, ChallengeTeamdashboardStatus, rectBadgeClassname } from '../../../common/challengeConstants';
import ChallengeTag from '../challengeTag/ChallengeTag';
import { format } from 'date-fns';

const ChallengeCarousel = ({
  title,
  carouselList = [],
  isLoading,
  onCardClick,
  challengeProfileList = [],
  isProfileLoading = false,
  showTag = false
}) => {
  const settings = {
    dots: false,
    nav: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0
  };

  const getProfileStatus = (cardObj) => {
    const { completeDate: rewardEndDate, eventId } = cardObj || {};
    if (eventId) {
      const eventProfileObj = challengeProfileList?.find(
        (profileObj) => eventId === profileObj?.teamInfo?.eventId
      ) ?? null;
      const eventTeam = eventProfileObj && eventProfileObj.teamInfo;
      const eventUsers = eventProfileObj && eventProfileObj.usersInfo;
      const isRegistered = eventUsers?.length > 0;


      const { approveDate, teamSize, complete60Date, endChallenge, challengeDate, completeTeamIn, weightoutDate } = eventTeam || {};
      const tagStatus = eventTeam && calculateTeamDashboardStatus(approveDate, teamSize, complete60Date, endChallenge, completeTeamIn, weightoutDate, rewardEndDate);
      const isChallengeStarted = !!approveDate;
      return {
        eventProfileObj,
        eventTeam,
        isRegistered,
        tagStatus,
        teamSize,
        isChallengeStarted,
        challengeDate
      }
    }
  };

  const handleCardClick = (cardObj, isRegistered, inActiveClass) => {
    if (!inActiveClass) {
      onCardClick(cardObj, isRegistered);
    }
  }

  const handleInviteClick = async (e, link) => {
    e.stopPropagation();
    try {
      const liff = await initializeLiff();
      if (liff) {
        await liff.shareTargetPicker(
          [
            {
              type: "text",
              text: link,
            },

          ],
          {
            isMultiple: true,
          }
        )
      }
    } catch (error) {
      alert('Error handling LIFF action', error);
    }
  }

  const isRegDateExpired = (regEndDate) => getChallengeTodayDate() > getIsoFormattedDate(regEndDate);

  const getDetailsContent = (cardObj, profileObj, tagStatus, canRegister) => {
    const {
      challengeStatus: challengeType,
      startDate: regStartDate,
      endDate: regEndDate,
      completeDate: rewardEndDate,
    } = cardObj || {};
    const {
      complete60Date,
      endChallenge,
      weightoutDate: { startDate: weightStartDate, endDate: weightEndDate } = {},
    } = profileObj?.teamInfo || {};
    const todayDate = getChallengeTodayDate();
    const daysLeftToReg = calculateDaysLeft(regEndDate);

    const dateCompleteFormat = 'dd MMM yy';
    const dateMonthFormat = 'dd MMM';
    if (challengeType === CHALLENGE_LIST.TYPES.upcoming || canRegister) {

      if (todayDate < regStartDate && daysLeftToReg > 30) {
        return `${CAROUSEL_CARD_DATES.upcoming.beforeReg} ${thaiDate(regStartDate, dateCompleteFormat)} - ${thaiDate(regEndDate, dateCompleteFormat)}`
      } else if ((todayDate > regStartDate && todayDate < regEndDate) || todayDate < regStartDate && daysLeftToReg <= 30) {
        return `${CAROUSEL_CARD_DATES.upcoming.duringReg} ${thaiDate(regEndDate, dateMonthFormat)} (${CAROUSEL_CARD_DATES.daysLeft(daysLeftToReg)})`
      }
    }

    if (challengeType === CHALLENGE_LIST.TYPES.ongoing) {
      switch (tagStatus) {
        case ChallengeTeamdashboardStatus.not_yet_started:
          if (todayDate < regEndDate) {
            return `${CAROUSEL_CARD_DATES.ongoing.notStarted} ${thaiDate(regEndDate, dateMonthFormat)} (${CAROUSEL_CARD_DATES.daysLeft(daysLeftToReg)})`;
          }
          break;
        case ChallengeTeamdashboardStatus.ready_to_start:
          if (todayDate < regEndDate) {
            return `${CAROUSEL_CARD_DATES.ongoing.readyToStart}`;
          }
          break;
        case ChallengeTeamdashboardStatus.ongoing:
          return `${CAROUSEL_CARD_DATES.daysLeft(calculateDaysLeft(complete60Date))}`;
          break;
        case ChallengeTeamdashboardStatus.reward_period:
          return `${CAROUSEL_CARD_DATES.ongoing.rewardPeriod} ${thaiDate(rewardEndDate, dateCompleteFormat)}`
          break;
        case ChallengeTeamdashboardStatus.weight_end:
          return `${CAROUSEL_CARD_DATES.ongoing.weightEnd} ${thaiDate(weightStartDate, dateMonthFormat)} - ${thaiDate(weightEndDate, dateCompleteFormat)}`
          break;
        case ChallengeTeamdashboardStatus.remove_team:
          return `${CAROUSEL_CARD_DATES.ongoing.removeTeam}`
        default:
          return null;
      }
    }

  }

  const renderCarouselCard = (cardObj) => {
    const profileStatus = getProfileStatus(cardObj);
    const { eventProfileObj, isRegistered, tagStatus, teamSize, challengeDate, isChallengeStarted } = profileStatus;
    const challengeType = cardObj?.challengeStatus;
    const registrationExpired = isRegDateExpired(cardObj?.endDate);
    const isUpcomingAndNotExpired = challengeType === CHALLENGE_LIST.TYPES.upcoming && !registrationExpired;
    const isNotRegisteredAndNotExpired = !isRegistered && !registrationExpired;
    const isRegisteredAndCanAddMembers = isRegistered && teamSize < CHALLENGE.teamDashboard.maxTeamMembers && !registrationExpired;
    const showInviteImg = isUpcomingAndNotExpired || isNotRegisteredAndNotExpired || isRegisteredAndCanAddMembers;
    const inActiveClass = !isRegistered && registrationExpired ? 'card-container-inactive' : '';

    return (
      <div
        key={cardObj?.id}
        className={`card-container-list ${inActiveClass}`}
        onClick={() => handleCardClick(cardObj, isRegistered, inActiveClass)}
      >
        <img className={`card-image-list`} src={cardObj?.challengeCardImg} />
        {showInviteImg && (
          <div className='challenge-invite-img' onClick={(e) => handleInviteClick(e, cardObj?.challengeLink)}>
            <img src='/images/challenge/icons/invite-icon.svg' />
          </div>
        )}
        {isProfileLoading && (
          <div className="challenge-profiles">
            <ChallengeUsersLoader count={4} />
          </div>
        )}
        {!isProfileLoading && isRegistered && (
          <div className="challenge-profiles">
            <ChallengeUsers usersList={eventProfileObj.usersInfo} showUserTag={!isChallengeStarted} status={tagStatus?.status} />
          </div>
        )}
        {!isProfileLoading && showTag && isRegistered && isChallengeStarted && tagStatus?.status && (
          <div className='challenge-tag-wrapper'>
            <ChallengeTag status={tagStatus?.status} challengeDay={challengeDate} />
          </div>
        )}
        <div className="card-container-details">
          <div className="card-container-details-title">{cardObj.name}</div>
          <div className="card-container-details-description">
            {getDetailsContent(cardObj, eventProfileObj, tagStatus?.status, isNotRegisteredAndNotExpired)}
          </div>
        </div>
      </div>
    );
  };

  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.slickGoTo(0);
    }
  }, [carouselList])

  return (
    <>
      {isLoading && <ChallengeCarouselLoader />}
      {!isLoading && carouselList?.length > 0 && (
        <div className="challenge-carousel-main">
          {title && <div className="carousel-title">{title}</div>}
          <div className={`carousel-content-list`}>
            <Slider {...settings} ref={carouselRef}>
              {carouselList.map((cardObj) => renderCarouselCard(cardObj))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
};

export default ChallengeCarousel;
