import {ALERT_ERROR, ALERT_CLEAR} from "./types"
export const alertActions = {
    success,
    showError,
    clear
};

function success(message) {
    return { type: "SUCCESS", message };
}

function showError(message) {
   
    return { type: ALERT_ERROR, error: true, message };
}

function clear() {
    return { type: ALERT_CLEAR, error: false };
}
