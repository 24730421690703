import React, { useEffect, useState, useRef } from 'react';
import './FoodLogHome.scss';
import {
  FoodLogDrinks,
  FoodLogHeader,
  FoodLogMeal,
  FoodLogOverview,
} from './components';
import HnWSlideup from '../../../common/slideup/HnWSlideup';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFoodLogDetails,
  getHealthLogs,
  getWaterVolume,
} from '../../../thunk/foodLog.thunk';
import {
  getDateForAPI,
  getDataFromSession,
  isEmptyObject,
  isValidObject,
  calculateDefaultFoodGoal,
  calculateDefaultWaterGoal,
  getDatetimeStamp,
} from '../../../utill.func';
import { getHnwAllHealhGoalList } from '../../../actions/hnwGoalSettingActions';
import {
  HNW_GOAL_SETTING,
  H_W_VALIDATION,
  HEALTH_GOAL_CARD_CONTENT,
  HEALTH_GOAL_TYPES,
  HNW_FOOD_LOG,
} from '../../../common/commonConstant';
import {
  CalendarLoader,
  HnwFooter,
  HnwHeightWeightInput,
  HnwInputModal,
} from '../../../common';
import { fetchBmiData, updateBmiData } from '../../../actions';
import HnwGoalCard from '../hnwGoals/components/HnwGoalCard/HnwGoalCard';
import { FOODTYPES } from '../../../constants';
import { SET_FOOTER_STATE_CALORIES } from '../../../actions/types';
import { setChallengeType } from '../../../actions/hnwFooterAction';

export default function FoodLogHome({ routeTo }) {
  const dispatch = useDispatch();
  const bmiData = useSelector((state) => state.bmi);
  const { foodLogData, volumeData, foodChartData } = useSelector(
    (state) => state?.logList,
  );
  const { healthGoalList } = useSelector((state) => state?.goalSetting);
  const { _id: customerId } = getDataFromSession(
    HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
  );
  const { isAuthTokenSaved } = useSelector(
    (state) => state.tokenStatus,
  );

  const date = new Date();
  const startDate = getDateForAPI(date);
  const endDate = getDateForAPI(date);

  const [loading, setLoading] = useState(false);
  const [foodGoalData, setFoodGoalData] = useState(null);
  const [waterGoalData, setWaterGoalData] = useState(null);
  const [supplementGoalData, setSupplementGoalData] = useState(null);
  const [isFoodGoalCalculated, setIsFoodGoalCalculated] = useState(false);
  const [isWatergoalCalculated, setIsWaterGoalCalculated] = useState(false);

  const waterLogRef = useRef(null);

  const fetchBMIData = () => {
    if (!bmiData?.bmi) {
      setLoading(true);
      dispatch(fetchBmiData());
    }
  };

  const fetchHealthGoals = (customerId) => {
    if (
      !healthGoalList?.waterGoal ||
      !healthGoalList?.foodGoal ||
      !healthGoalList?.activityGoal
    ) {
    setLoading(true);
    dispatch(getHnwAllHealhGoalList(customerId));
    }
  };

  const fetchLogData = async () => {
    setLoading(true);
    const healthLogsPromises = [
      dispatch(
        getHealthLogs({
          startDate: getDatetimeStamp(startDate),
          endDate: getDatetimeStamp(endDate),
        }),
      ),
    ];
    let customerDataPromises = [];
    customerDataPromises = customerId
      ? [
        dispatch(
          getFoodLogDetails(customerId, {
            startDate: getDatetimeStamp(startDate),
            endDate: getDatetimeStamp(endDate),
          }),
        ),
        !isValidObject(volumeData) ? dispatch(getWaterVolume(customerId)) : null,
      ]
      : [];
    try {
      if (customerDataPromises.length) {
        await Promise.allSettled([
          ...healthLogsPromises,
          ...customerDataPromises,
        ]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(isAuthTokenSaved) {
      fetchBMIData();
      fetchHealthGoals(customerId);
      dispatch(setChallengeType(SET_FOOTER_STATE_CALORIES));
    }
  }, [isAuthTokenSaved]);

  useEffect(() => {
    if (
      bmiData.height &&
      bmiData.weight &&
      (!healthGoalList?.foodGoal || isEmptyObject(healthGoalList?.foodGoal))
    ) {
      const defaultFoodGoal = calculateDefaultFoodGoal(bmiData);
      setFoodGoalData(defaultFoodGoal);
      setIsFoodGoalCalculated(true);
    }
    if (
      bmiData.height &&
      bmiData.weight &&
      (!healthGoalList?.waterGoal || isEmptyObject(healthGoalList?.waterGoal))
    ) {
      const defaultWaterGoal = calculateDefaultWaterGoal(bmiData);
      setWaterGoalData(defaultWaterGoal);
      setIsWaterGoalCalculated(true);
    }
  }, [bmiData.height, bmiData.weight]);

  useEffect(() => {
    if (isValidObject(healthGoalList)) {
      const { foodGoal, waterGoal, supplementGoal } = healthGoalList;
      if (isValidObject(foodGoal)) {
        setFoodGoalData(healthGoalList?.foodGoal);
        setIsFoodGoalCalculated(false);
      }
      if (isValidObject(waterGoal)) {
        setWaterGoalData(healthGoalList?.waterGoal?.targetWater);
        setIsWaterGoalCalculated(false);
      }
      if (isValidObject(supplementGoal)) {
        setSupplementGoalData(healthGoalList?.supplementGoal);
      }
    }
  }, [healthGoalList]);

  useEffect(() => {
    isAuthTokenSaved && fetchLogData();
  }, [startDate, endDate, customerId, isAuthTokenSaved]);

  useEffect(() => {
    setLoading(true);
    isAuthTokenSaved && dispatch(
      getHealthLogs({
        startDate: getDatetimeStamp(startDate),
        endDate: getDatetimeStamp(endDate),
      }),
    ).finally(() => setLoading(false));
  }, [volumeData, isAuthTokenSaved]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shouldScrollToWaterLog = params.get('scrollTo') === 'waterLog';

    if (shouldScrollToWaterLog) {
      setTimeout(() => {
        waterLogRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }, 1000);
    }
  }, []);

  return (
    <div className="food-overview-page">
      <FoodLogHeader routeTo={routeTo} />
      <div className="food-overview-banner-container">
        <FoodLogOverview
          foodChartData={foodChartData}
          goalData={foodGoalData}
        />
      </div>
      <FoodLogMeal
        foodLogData={foodLogData}
        foodGoalData={foodGoalData}
        isFoodGoalCalculated={isFoodGoalCalculated}
        routeTo={routeTo}
      />
      <FoodLogDrinks
        ref={waterLogRef}
        customerId={customerId}
        healthData={foodChartData}
        waterGoalData={waterGoalData}
        isWatergoalCalculated={isWatergoalCalculated}
        volumeData={volumeData}
        setLoading={setLoading}
      />
      {supplementGoalData && (
        <div className="food-log-supplement-goal-container">
          <div className="food-log-supplement-goal-container__heading">
            {HNW_FOOD_LOG.labelMapping.supplementGoalCardHeader}
          </div>
          <HnwGoalCard
            content={HEALTH_GOAL_CARD_CONTENT[HEALTH_GOAL_TYPES.SUPPLEMENT]}
            healthGoal={supplementGoalData}
            routeTo={routeTo}
            type={HEALTH_GOAL_TYPES.SUPPLEMENT}
            showDeleteModal={false}
            routeParams={`referrerFrom=${HNW_FOOD_LOG.routeMapping.referrer}`}
          />
        </div>
      )}
      <HnWSlideup />
      <CalendarLoader showLoading={loading} />
      <HnwFooter/>
    </div>
  );
}