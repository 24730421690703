import React from 'react';
import Chart from 'react-apexcharts';
import { fixDataForBar, getMonthFirstDayAndLastDay } from './graph-util';
import variables from '../../../common/commonConstant.scss';

const BarGraph = ({ data = [], secondaryData, onMouseMove = () => { }, month, year }) => {
  const { firstDayOfMonth, lastDayOfMonth } = getMonthFirstDayAndLastDay(month, year);

  const series = [
    ...secondaryData ? [{
      name: 'Last Month',
      type: 'bar',
      data: fixDataForBar(secondaryData, month, year),
    }] : [],
    {
      name: 'Current Month',
      type: 'bar',
      data: fixDataForBar(data, month, year),
    },
  ];

  const handleChartEvent = (event, chartContext, config) => {
    if (config?.dataPointIndex > -1) {
      const { series } = chartContext.w.config;
      const seriesData = series[series.length - 1]?.data;
      onMouseMove(seriesData[config.dataPointIndex]);
    }
  };

  const options = {
    chart: {
      id: 'chart',
      type: 'bar',
      width: '100%',
      foreColor: variables.homegrey3,
      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
      events: {
        mouseMove: handleChartEvent,
        markerClick: handleChartEvent,
      },
    },
    legend: { show: false },
    plotOptions: {
      bar: {
        borderRadius: 1, // Set the border radius for bars
        columnWidth: 0,
      },
    },
    colors: [...secondaryData ? [variables.gray15] : [], variables.themePrimary],
    fill: {
      opacity: 1,
      type: 'solid',
    },
    stroke: {
      width: 2,
      lineCap: 'round',
      dashArray: '2 0',
    },
    grid: {
      borderColor: variables.themePrimary, // Hide the outside border
      strokeDashArray: 4, // Length of dashes in the vertical grid lines
      position: 'front', // Position of the grid lines
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines on the X-axis
        },
      },
      yaxis: {
        lines: {
          show: false, // Hide the horizontal grid lines on the Y-axis
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
      colors: [variables.white],
      strokeColor: variables.themePrimary,
      strokeWidth: 3,
    },
    tooltip: {
      x: {
        show: false, // Hide default tooltip value
      },
      y: {
        title: {
          formatter: () => '',
        },
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // Retrieve data point information
        const dataPoint = series[series.length - 1][dataPointIndex];

        // Format HTML content for the tooltip
        return `<div class="custom-tooltip">
            <span>${dataPoint || 'ไม่มีข้อมูล'}</span>
          </div>`;
      },
    },
    xaxis: {
      type: 'datetime',
      min: firstDayOfMonth.getTime(),
      max: lastDayOfMonth.getTime() + 1,
      axisBorder: {
        show: false, // Hide the x-axis line
      },
      crosshairs: {
        show: true,
        width: 1,
        position: 'front',
        opacity: 0.9,
        stroke: {
          width: 2,
          dashArray: 3,
          color: variables.graphgreen,
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -155,
        formatter: () => ' ',
      },
      tickPlacement: 'on', // Place ticks on the axis,
      tickAmount: lastDayOfMonth.getDate(),
      offsetY: -10,
      labels: {
        show: true,
        formatter: (value) => new Date(value).getDate(),
        hideOverlappingLabels: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: (val) => Math.round(val),
      },
      tickAmount: 3,
      axisBorder: {
        show: true,
        color: variables.themePrimary,
      },
      opposite: true,
    },
  };
  return <Chart
    options={options}
    type="area"
    series={series}
    height={200}
  />;
};
export default BarGraph;
