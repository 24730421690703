import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import config from '../../config';
import { getContentById } from '../../services/abuddy/content.service';
import { message } from '../../services/promotion/utils.service';
import '../abuddy/abuddy.scss';

export const history = createBrowserHistory();

export const AbuddyContent = () => {
  const urlParams = queryString.parse(history.location.search);

  const contentId = urlParams.id;

  const [content, setContent] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const content = await getContentById(contentId);
      setContent(content);
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        return alert(message.error.notFound);
      }
      return alert(message.error.somethingWrong);
    }
  };

  const replaceNewLineHTML = (text) => {
    return text.replaceAll(/\n/g, '<br/>');
  };

  if (!content) {
    return (
      <LoadingOverlay
        text="Loading"
        spinner
        active={true}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
    );
  }

  return (
    <section>
      <div>
        <img
          className="w-h-100"
          alt="content-banner"
          src={config.S3_URL + `${content.imageContent}`}
        />
      </div>
      <div className="content-header" />
      <div className="content-container">
        <h2>
          <b>{content.name}</b>
        </h2>
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: `${replaceNewLineHTML(content.content)}`,
          }}
        ></div>
      </div>
    </section>
  );
};

export default AbuddyContent;
