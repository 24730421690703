import React from 'react';
import './LinearRowThumbnailContent.scss';
import { formatNumberWithCommas } from '../../../../../utill.func';

export default function LinearRowThumbnailContentEMenu({
  healthGoalImgUri,
  healthGoalName,
  healthGoalValue,
  healthGoalUnit,
  crop
}) {

  return (
    <div className="goal-setup-linear-row-thumbnail-component d-flex align-items-center">
      <div className="goal-setup-linear-row-thumbnail-component-left d-flex justify-content-center align-items-center">
        <a>
          <img
            height={48}
            width={48}
            style={{
              borderRadius: '10px',
            }}
            src={healthGoalImgUri}
            alt="img"
          />
        </a>
      </div>
      <div className="goal-setup-linear-row-thumbnail-component-right">
        <div className="goal-setup-linear-row-thumbnail-component-right-title">
          {healthGoalName}
        </div>
        <div className="goal-setup-linear-row-thumbnail-component-right-amount d-flex justify-content-start">
          <span className="goal-setup-linear-row-thumbnail-component-right-amount-value">
            {formatNumberWithCommas(healthGoalValue)}
          </span>
          <span className="goal-setup-linear-row-thumbnail-component-right-amount-unit">
            {healthGoalUnit}
          </span>
        </div>
      </div>
    </div>
  );
}
