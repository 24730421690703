import React, { useEffect, useState } from 'react';
import HnWLineInput from '../../../../../common/hnwLineInput/HnWLineInput';
import ExerciseSubDetails from '../exerciseSubDetails/ExerciseSubDetails';
import ExerciseInputCard from '../exerciseInputCard/ExerciseInputCard';
import ExerciseMenuHeader from '../exerciseMenuHeader/ExerciseMenuHeader';
import { CADENCE_KEY, EXERCISE_LOG_DETAIL, LATENCY_LEVEL_KEY } from '../../../../../common/commonConstant';

export default function ExerciseManualLogPage({
  exerciseLogDetailState,
  onChangeValue,
  exerciseMenu,
  exerciseLogError,
  startTimeValidationStatus,
  endTimeValidationStatus,
  handleKeypadOpen,
  handleScrollBottom
}) {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    let currentSection = [];
    const accumulatedTemplate = exerciseMenu?.exerciseTemplates?.reduce(
      (accumulator, template, index) => {
        if (
          [
            EXERCISE_LOG_DETAIL.TEMPLATE_INPUT_TIME,
            EXERCISE_LOG_DETAIL.TEMPLATE_INPUT_CALORIES,
          ].includes(template.inputType)
        ) {
          accumulator.push([template]);
          return accumulator;
        } else if (
          EXERCISE_LOG_DETAIL.TEMPLATE_INPUT_NUMPAD_TYPE === template.inputType
        ) {
          if (template?.newSection) {
            if (currentSection.length > 0) {
              accumulator.push(currentSection);
              currentSection = [template];
            } else {
              currentSection.push(template);
            }
          } else {
            currentSection.push(template);
          }
          if (index === exerciseMenu?.exerciseTemplates?.length - 1) {
            accumulator.push(currentSection);
            currentSection = [];
          }
          return accumulator;
        }
      },
      [],
    );
    setTemplates(accumulatedTemplate);
  }, [exerciseMenu?.exerciseTemplates]);

  const renderInputFields = (templateField) => {
    const inputType = templateField?.length ? templateField[0].inputType : '';
    switch (inputType) {
      case EXERCISE_LOG_DETAIL.TEMPLATE_INPUT_TIME:
        return [
          {
            fieldIcon: templateField[0]?.startImage,
            fieldName: templateField[0]?.startText,
            inputRenderProp: () => (
              <HnWLineInput
                name={'startTime'}
                value={exerciseLogDetailState.startTime}
                type="time"
                validationStatus={startTimeValidationStatus}
                onChange={onChangeValue}
                handleKeypadOpen={handleKeypadOpen}
                handleScrollBottom={() => handleScrollBottom(false)}
              />
            ),
          },
          {
            fieldIcon: templateField[0]?.endTextImage,
            fieldName: templateField[0]?.endText,
            inputRenderProp: () => (
              <HnWLineInput
                name="endTime"
                value={exerciseLogDetailState.endTime}
                type="time"
                onChange={onChangeValue}
                validationStatus={endTimeValidationStatus}
                handleKeypadOpen={handleKeypadOpen}
                handleScrollBottom={() => handleScrollBottom(false)}
              />
            ),
          },
        ];
      case EXERCISE_LOG_DETAIL.TEMPLATE_INPUT_CALORIES:
        return [
          {
            fieldIcon: templateField[0]?.startImage,
            fieldName: templateField[0]?.startText,
            inputRenderProp: () => (
              <HnWLineInput
                name="calorie"
                value={exerciseLogDetailState.calorie}
                type="number"
                onChange={(name, value) =>
                  onChangeValue(name, value, {
                    maxRange:
                      EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_MAX_VALUE,
                    minRange:
                      EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_MIN_VALUE,
                  })
                }
                validationStatus={exerciseLogError.calorie}
                handleKeypadOpen={handleKeypadOpen}
                handleScrollBottom={() => handleScrollBottom(false)}
              />
            ),
          },
        ];
      case EXERCISE_LOG_DETAIL.TEMPLATE_INPUT_NUMPAD_TYPE:
        return templateField.map((field) => ({
          fieldIcon: field?.startImage,
          fieldName: field?.startText,
          inputRenderProp: () => (
            <HnWLineInput
              name={field?.fieldKey}
              value={exerciseLogDetailState[field?.fieldKey]}
              type="number"
              onChange={(name, value) =>
                onChangeValue(name, value, {
                  maxRange: field?.maxRange || 999,
                  minRange: field?.minRange || 1,
                })
              }
              validationStatus={exerciseLogError[field?.fieldKey]}
              handleKeypadOpen={
                field?.fieldKey === CADENCE_KEY ||
                  field?.fieldKey === LATENCY_LEVEL_KEY
                  ? handleKeypadOpen
                  : handleKeypadOpen
              }
              handleScrollBottom={
                field?.fieldKey === CADENCE_KEY ||
                  field?.fieldKey === LATENCY_LEVEL_KEY
                  ? handleScrollBottom
                  : () => handleScrollBottom(false)
              }
            />
          ),
        }));
      default:
        return [];
    }
  };
  return (
    <>
      <ExerciseMenuHeader
        name={exerciseMenu?.name}
        image={exerciseMenu?.image}
        templateImg={exerciseMenu?.exerciseTemplateBannerImage}
      />
      <ExerciseSubDetails />
      {templates.map((templateGroup, index) => (
        <ExerciseInputCard key={`input-card-${index}`} inputFields={renderInputFields(templateGroup)} />
      ))}
    </>
  );
}
