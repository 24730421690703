import React, { useEffect, useRef, useState } from 'react';
import './AccessChallengeCamera.scss';
import { CommonAndroidWrapper } from '../commonAndroidWrapper/CommonAndroidWrapper';
import { getChallengeImagesAction } from '../../../actions/challenge/challengeImageUploadActions';
import { getLocalStorage } from '../../../services/promotion/utils.service';
import { IMAGE_UPLOAD_PROPS } from '../../challengeConstants';
import { useDispatch } from 'react-redux';

export default function AccessChallengeCamera() {
  const imageRef = useRef(null);
  const androidFeatureRef = useRef(null);
  const dispatch = useDispatch();
  const [challengeObj, setChallengeObj] = useState(null);

  useEffect(() => {
    const onMount = async () => {
      const uploadPropsString = await getLocalStorage(IMAGE_UPLOAD_PROPS);
      const uploadProps = JSON.parse(uploadPropsString);
      setChallengeObj(uploadProps);
      if (uploadProps.eventId && uploadProps.userId) {
        dispatch(
          getChallengeImagesAction({
            eventId: uploadProps.eventId,
            userId: uploadProps.userId,
            scoreId: null,
          }),
        );
      }
    };
    onMount();
  }, []);

  return (
    <div className="custom-android-camera">
      <CommonAndroidWrapper
        eventId={challengeObj?.eventId}
        partyId={challengeObj?.partyId}
        aboId={challengeObj?.aboId}
        userId={challengeObj?.userId}
        redirectionEventIdQuery={challengeObj?.redirectionEventIdQuery}
        ref={androidFeatureRef}
        imageRef={imageRef}
        photos={null}
        setPhotos={() => {}}
        componentInstance={'CAMERA'}
      />
    </div>
  );
}
