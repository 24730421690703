import AxiosPromotion from './index';
import config from '../../config';
import { getCustomerIdFormFacade } from './utils.service';

export const AREA = {
  PROVINCE: 'province',
  DISTRICT: 'district',
  SUBDISTRICT: 'sub-district',
};

export const getProvince = async (keyword) => {
  try {
    const { data } = await AxiosPromotion.get(
      `${config.API_URL}/promotion/provinces?keyword=${keyword}`,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getDistrict = async (keyword) => {
  try {
    const { data } = await AxiosPromotion.get(
      `${config.API_URL}/promotion/districts?keyword=${keyword}`,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getSubdistrict = async (keyword) => {
  try {
    const { data } = await AxiosPromotion.get(
      `${config.API_URL}/promotion/subdistricts?keyword=${keyword}`,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createAddress = async (body) => {
  try {
    const { data } = await AxiosPromotion.post(
      `${config.API_URL}/promotion/address`,
      body,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAddress = async (uid) => {
  try {
    const { data } = await AxiosPromotion.get(
      `${config.API_URL}/promotion/address`,
      { params: { uid } },
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
