import React, { useEffect, useState } from 'react';
import './HnWNumpadModal.scss';

export const LIFF_OPEN = 'open';

const HnWNumpadModal = (props) => {
    const [isLiffOpen, setIsLiffOpen] = useState('');
    useEffect(() => {
        const isLiffOpen = props?.openNumpad ? LIFF_OPEN : '';
        setIsLiffOpen(isLiffOpen);
    }, [props?.openNumpad]);

    const handleBackgroundClick = (event) => {
        if (props.onBackgroundClick) {
            (event.target === event.currentTarget) && props.onBackgroundClick(event);
        }
    };

    return (
        <section onClick={handleBackgroundClick} className={`liff-numpad-panel ${props.className || ""} ${isLiffOpen}`}>
            <div className="numpad-panel">
                {props?.children}
            </div>
        </section>
    );
};

export default HnWNumpadModal;
