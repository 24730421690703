import React, { useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import { commonConstant } from '../../../common';
import { paymentService } from '../../../services';
import { PaymentConfirmation } from '../../../services/promotion/payment.service';
import { message } from '../../../services/promotion/utils.service';

export const PaymentStatus = ({ params }) => {
  const liff = window.liff;
  const { profile, customer } = useSelector((state) => state);

  const hasSentPaymentStatus = useRef(false);

  useEffect(() => {
    if (
      profile.userId &&
      customer.uid &&
      customer.registerStatus !== 'complete'
    ) {
      return window.location.replace(commonConstant.pathHome);
    } else if (profile.userId && customer && customer.uid) {
      if (!hasSentPaymentStatus.current && params.orderId && params.success) {
        sendStatus(customer.uid, customer.amwayNumber, params);
        hasSentPaymentStatus.current = true;
      } else if (!params.orderId || !params.success){
        alert(message.error.somethingWrong);
      }
    }
  }, [profile, customer]);

  async function sendStatus(lineMid, amwayNumber, params) {
    //! Coding by customer
    // const res = await paymentService.sendPaymentStatus({
    //   lineMid,
    //   amwayNumber,
    //   ...params,
    // });
    // switch (res.code) {
    //   case 200:
    //     console.log('success');
    //     return liff.closeWindow();
    //   default:
    //     return alert('Something went wrong. Please try again later');
    // }

    const isConfirmPayment = await PaymentConfirmation({
      lineMid,
      amwayNumber,
      ...params,
    });
    switch (isConfirmPayment.success) {
      case true:
        console.log('success');
        await liff.closeWindow();
        break;
      default:
        alert(message.error.somethingWrong);
        await liff.closeWindow();
        return;
    }
  }

  return (
    <LoadingOverlay
      active={true}
      spinner
      text="Loading"
      styles={{
        wrapper: {
          width: '100%',
          height: '100%',
          position: 'absolute',
          pointerEvents: 'none',
          top: 0,
          left: 0,
        },
      }}
    />
  );
};
