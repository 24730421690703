import React from 'react';
import './HnwHeightWeightInput.scss';
import { HEIGHT_WEIGHT_POP_UP } from '../commonConstant';
import HnwInputBox from '../hnwInputBox/HnwInputBox';

const HnwHeightWeightInput = ({
  userWeight,
  userHeight,
  handleInputBoxClick,
}) => {
  return (
    <div className="food-overview-input-modal-container">
      <h2 className="food-overview-input-modal-container-heading">
        {HEIGHT_WEIGHT_POP_UP.TITLE}
      </h2>
      <div className='description-container'>
        <p className="food-overview-input-modal-container-description">
          {HEIGHT_WEIGHT_POP_UP.DESCRIPTION.line1}
        </p>
        <p className="food-overview-input-modal-container-description">
          {HEIGHT_WEIGHT_POP_UP.DESCRIPTION.line2}
        </p>
        <p className="food-overview-input-modal-container-description">
          {HEIGHT_WEIGHT_POP_UP.DESCRIPTION.line3}
        </p>
      </div>
      <div className="hnw-weight-accordian-container">
        <div className="hnw-weight-accordian-single">
          <HnwInputBox
            name="weight"
            label="น้ำหนัก (กิโลกรัม)"
            iconImg="/images/healthAndWellness/icon/weight.svg"
            placeholder="โปรดระบุ"
            hasError={!userWeight}
            onClick={handleInputBoxClick}
            value={userWeight}
          />
        </div>
        <div className="hnw-weight-accordian-single">
          <HnwInputBox
            name="height"
            label="ส่วนสูง (เซนติเมตร)"
            iconImg="/images/healthAndWellness/icon/height.svg"
            placeholder="โปรดระบุ"
            hasError={!userHeight}
            onClick={handleInputBoxClick}
            value={userHeight}
          />
        </div>
      </div>
    </div>
  );
};

export default HnwHeightWeightInput;
