import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';


// CHILD
const ImageCropper = ({
  crop,
  setCrop,
  zoom,
  setZoom,
  setCoord,
  image,
}) => {
  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => setCoord(croppedAreaPixels),
    [],
  );
  return (
    <div className="cropper-container">
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        cropShape="rect"
        showGrid={false}
        aspect={3 / 4}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        objectFit="cover"
      />
    </div>
  );
};

export default ImageCropper;
