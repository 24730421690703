import React, { useState, useEffect } from 'react';
import { CalendarLoader, HealthLogCard, NoResultFound } from '../../../../../common';
import './ExerciseLogList.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  HNW_EXERCISE_LOG,
  NO_RESULT_FOUND,
  exerciseLogListMapping,
} from '../../../../../common/commonConstant';
import { useLocation } from 'react-router-dom';
import { fetchExerciseTimeLogs } from '../../../../../actions';
import { getDatetimeStamp } from '../../../../../utill.func';

export default function ExerciseLogList() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { exerciseTimeLogList } = useSelector(
    (state) => state.exerciseLog,
  );
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { headerIcon, headerText } = exerciseLogListMapping[HNW_EXERCISE_LOG];
  const emptyImg = NO_RESULT_FOUND.exercise_no_result;

  useEffect(() => {
    const didMount = async () => {
      await getLogListData();
    };
    didMount();
  }, []);

  const getLogListData = async () => {
    try {
      setLoading(true);
      dispatch(fetchExerciseTimeLogs({ 
        startDate: getDatetimeStamp(startDate),
        endDate: getDatetimeStamp(endDate) 
       }))
        .then((res) => {
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <CalendarLoader showLoading={loading} />
      <div className="exercise-log-list-container">
        <div className="log-list-header">
          <img className="log-list-header-img" src={headerIcon} />
          <div className="log-list-header-text">{headerText}</div>
        </div>
        <div className="log-list-card-container">
          {exerciseTimeLogList?.length ? (
            <div>
              {exerciseTimeLogList?.map((item) => (
                <div className="log-list-card">
                  <HealthLogCard item={item} exerciseType={HNW_EXERCISE_LOG} />
                </div>
              ))}
            </div>
          ) : (
            <NoResultFound
              emptyImg={emptyImg}
              header={NO_RESULT_FOUND.header}
              description={NO_RESULT_FOUND.description}
            />
          )}
        </div>
      </div>
    </>
  );
}
