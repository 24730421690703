import React from 'react'
import './statusBar.scss'

const StatusBar = ({ activeClass }) => {

    const renderArrowList = (className, image, index) => {
        return (
            <div key={`statusBarArrow${index}`} className={className}>
                <img src={`/images/healthAndWellness/bmi/icons/${image}`} />
            </div>
        )
    }

    const progressBarArrowClasses = ['bmi-progress-blue-img', 'bmi-progress-green-img', 'bmi-progress-yellow-img', 'bmi-progress-orange-img', 'bmi-progress-red-img']
    const progressBarClasses = ['bmi-progress-blue', 'bmi-progress-green', 'bmi-progress-yellow', 'bmi-progress-orange', 'bmi-progress-red']

    const renderProgressBar = (className, index) => {
        return (
            <div key={`statusBarProgress${index}`} className={className}>
            </div>
        )
    }

    return (
        <div className={`row`}>
            <div className="col-12">
                {
                    activeClass ? <div className="bmi-progress-bar-arrow">
                        {
                            progressBarArrowClasses.map((el, index) => renderArrowList(el, 'arrow-top.svg', index))
                        }
                    </div> : null
                }
                <div className="bmi-progress-bar">
                    {
                        progressBarClasses.map((el, index) => renderProgressBar(el, index))
                    }
                </div>
            </div>
        </div>
    )
}

export default StatusBar