import React from "react";
import "./TeamDashboardVdoLoader.scss";

const TeamDashboardVdoLoader = () => {
  return (
    <div className="team-dashboard-vdo-container">
     <div className="vdo-skeleton-container">
      <div className="vdo-skeleton-card-container">
        <div className="skeleton-card-image skeleton"></div>
        <div className="skeleton-card-details">
        </div>
      </div>
      <div className="vdo-skeleton-card-container">
        <div className="skeleton-card-image skeleton"></div>
        <div className="skeleton-card-details">
        </div>
      </div>
    </div>
  </div>
  );
};

export default TeamDashboardVdoLoader;
