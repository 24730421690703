import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { isEqual } from 'lodash';

import { customerFetch, customerFindByNumber, fetchChangePhoneNumber } from '../../../actions';
import { Modal } from '../../../helpers';
import commonConstant from '../../../common/commonConstant';

import './PhoneChange.scss';

const phoneRegex = /[0-9]{10}/;

export const PhoneChange = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [loadingSetPhone, setLoadingSetPhone] = useState();
  const [editable, setEditable] = useState();
  const [redirect, setRedirect] = useState();
  const [phone, setPhone] = useState();
  const [modalContent, setModalContent] = useState('CHANGE_PHONE');
  const { profile, customer, find, changePhoneNumber } = useSelector(state => state);
  const didChangePhone = useRef(changePhoneNumber ? changePhoneNumber : false);
  const history = useHistory();

  useEffect(() => {
    if (profile.token) {
      dispatch(customerFetch(profile.userId));
    }
  }, [profile]);

  useEffect(() => {
    if (customer.amwayNumber) {
      dispatch(customerFindByNumber(customer.amwayNumber));
    }
  }, [customer]);

  useEffect(() => {
    if (find.partyList) {
      if (find.partyList[0].phoneList.length !== 0) {
        setPhone(find.partyList[0].phoneList[0].phoneLocalNum);
        setLoading(false);
        setEditable(find.partyList[0].phoneList[0].phoneLocalNum);
      } else {
        setLoading(false);
        handleEditMode();
      }
    }
  }, [find]);

  useEffect(() => {
    if (changePhoneNumber && !isEqual(changePhoneNumber, didChangePhone.current)) {
      didChangePhone.current = changePhoneNumber;
      if (changePhoneNumber && changePhoneNumber.code === 200) {
        setConfirmed(true);
        setLoadingSetPhone(false);
      }
      else if (changePhoneNumber && changePhoneNumber.code === 400) {
        setModalContent('PHONE_EXIST');
        setShowModal(true);
      }
      else {
        setModalContent('ERROR');
        setShowModal(true);
      }
    }
  }, [changePhoneNumber]);

  const handleModalEditMode = () => {
    setModalContent('CHANGE_PHONE');
    setShowModal(true);
  };

  const handleEditMode = () => {
    setShowModal(false);
    setEditable(false);
    setPhone('');
  };

  const handleSubmitNewPhone = () => {
    setLoadingSetPhone(true);
    dispatch(fetchChangePhoneNumber({
      'phoneNumer': phone,
      'amwayNumber': customer.amwayNumber,
      'partyId': customer.partyId,
    }));
  };

  const handleModalContent = () => {
    switch (modalContent) {
      case 'ERROR':
        return {
          title: 'เกิดข้อผิดพลาด',
          content: 'กรุณาลองใหม่ในภายหลัง',
          type: 'warning',
          okBtn: true,
          okText: 'ปิด',
          closeBtn: false,
          fnOk: () => window.location.reload(),
        };
      case 'PHONE_EXIST':
        return {
          title: 'เกิดข้อผิดพลาด',
          content: 'เบอร์โทรศัพท์ซ้ำกับที่มีในระบบ',
          type: 'warning',
          okBtn: true,
          okText: 'ปิด',
          closeBtn: false,
          fnOk: () => window.location.reload(),
        };
      case 'CHANGE_PHONE':
        return {
          title: 'หากท่านเปลี่ยนเบอร์โทรศัพท์มือถือ',
          content: 'ระบบจะทำการบันทึกข้อมูลหมายเลขโทรศัพท์นี้\n เพื่อใช้ในการยืนยันตัวตนและแจ้งข่าวสารต่างๆ',
          type: 'warning',
          okBtn: true,
          okText: 'ยืนยัน',
          closeBtn: true,
          fnOk: () => handleEditMode(),
          fnClose: () => setShowModal(false),
        };
      default:
        return;
    }
  };

  const content = handleModalContent();
  if (loading) {
    return <LoadingOverlay active={loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />;
  }
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className="phonechange-container">
      {
        showModal && <Modal
          title={content.title}
          content={content.content}
          type={content.type}
          fnClose={content.fnClose}
          fnOk={content.fnOk}
          closeBtn={content.closeBtn}
          okBtn={content.okBtn}
          okText={content.okText} />
      }
      <div className="header">
        <img className="header-img" src="/images/upgradeMember/header.png" alt="" />
        <div className="header-text">อัพเกรดเป็นนักธุรกิจแอมเวย์ง่าย ๆ! เพียง 2 ขั้นตอน</div>
      </div>
      <div className="body">
        <div className="desc">กรุณากรอกเบอร์โทรศัพท์มือถือของคุณเพื่อรับรหัส OTP</div>
        <div className="custom-input-container">
          <div className="input-label">เบอร์โทรศัพท์มือถือ</div>
          <div className="input-container">
            <input type="tel" className={`${editable || loadingSetPhone || confirmed ? 'disable' : ''}`} placeholder="0XX-XXX-XXXX" maxLength={10} value={phone} onChange={e => setPhone(e.target.value)} disabled={editable || loadingSetPhone || confirmed} />
            <button className={`away-button suffix-button ${editable || phoneRegex.test(phone) ? 'active' : ''}`} disabled={loadingSetPhone || confirmed} onClick={editable ? handleModalEditMode : handleSubmitNewPhone}>{editable ? 'เปลี่ยน' : loadingSetPhone ? 'loading...' : 'ยืนยัน'}</button>
          </div>
        </div>
      </div>
      <div className="footer">
        <button className={`away-button ${editable || confirmed ? 'active' : ''}`} onClick={editable || confirmed ? () => setRedirect(commonConstant.pathUpgradeOTP) : null}>ส่งรหัส OTP</button>
      </div>
    </div>
  );
};
