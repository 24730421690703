import React, {useState} from 'react';
import './FooterAssessment.scss';

const Footer = ({title, buttonActive = false, handleSubmit}) => {

  return (
    <div className="assessment-footer-vector">
        <img src={`/images/healthAndWellness/footer-wave.svg`} className="w-100 footer-curve-bg"/>
        <div className="footer-parent">
          <button onClick={() => handleSubmit()} disabled={!buttonActive}
          className={`footer-btn w-100 ${buttonActive? '': 'footer-btn-disable'}`}>{title}</button>
        </div>
      </div>
  );
}

export default Footer;
