import React from 'react';

import './Card.scss';

export const Card = ({ children, className }) => {
  const classNameCobined = `card-container ${className}`;
  return <div className={classNameCobined}>
    {children}
  </div>;
};
