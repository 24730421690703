import React from 'react'
import './info.scss'
import { bmiClassification } from '../../../../services';

const Info = ({ bmi }) => {

    let activeImage = 'initial';
    const activeImageWrapper = {
        0: 'initial',
        1: 'blue',
        2: 'green',
        3: 'yellow',
        4: 'orange',
        5: 'red'
    }

    activeImage = bmi ? bmiClassification(bmi, activeImageWrapper) : 'initial';

    return (
        <div className="bmi-banner-container">
            <img src="/images/healthAndWellness/bmi/bmi-banner.svg" />
            <div className="half-circle-center">
                <div className="outer-half-circle"></div>
                <div className="position-relative">
                    <div className="inner-half-circle">
                        <img src={`/images/healthAndWellness/bmi/${activeImage}.svg`} />
                        <div className="circle-heading">BMI</div>
                        <div className="circle-value">
                            {bmi ? bmi : 'จะถูกคำนวน โดยอัตโนมัติ'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info