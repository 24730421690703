import { act } from "react";
import {
  FETCH_CHALLENGE_QUIZ,
  FETCH_CHALLENGE_QUIZ_FAILURE,
  FETCH_CHALLENGE_QUIZ_SUCCESS,
  UPDATE_CHALLENGE_QUIZ_ANSWERS,
  UPDATE_CHALLENGE_QUIZ_ANSWERS_FAILURE,
  UPDATE_CHALLENGE_QUIZ_ANSWERS_SUCCESS,
  FETCH_UPDATED_ANSWER,
  FETCH_UPDATED_ANSWER_SUCCESS,
  FETCH_UPDATED_ANSWER_FAILURE
} from "../../actions/types";

const initialState = {
  challengeQuizList: [],
  requestId: null,
  loading: false,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CHALLENGE_QUIZ:
    case UPDATE_CHALLENGE_QUIZ_ANSWERS:
    case FETCH_UPDATED_ANSWER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CHALLENGE_QUIZ_SUCCESS:
      return {
        ...state,
        loading: false,
        challengeQuizList: action.payload,
        error: null,
      };
    case UPDATE_CHALLENGE_QUIZ_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case FETCH_UPDATED_ANSWER_SUCCESS:

    const updatedQuizlist =  state.challengeQuizList.map(quiz => {
      if (quiz.id === action.payload.quiz_user_id) {
        delete quiz.quiz_user_answers;
        return { ...quiz, ...{quiz_user_answers: action.payload}};
      } else {
        return quiz;
      }
    })

    return {
      ...state,
      loading: false,
      challengeQuizList: updatedQuizlist,
      error: null,
    };

    case FETCH_CHALLENGE_QUIZ_FAILURE:
    case UPDATE_CHALLENGE_QUIZ_ANSWERS_FAILURE:
    case FETCH_UPDATED_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}