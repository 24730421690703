// multi-bar.js
import React, { useRef } from 'react';
import Chart from 'react-apexcharts';
import { GRAPH_TYPE, LABEL_EN_WEEK_CALENDAR_TAB, Y_AXIS_DEFAULT_MAX_LABEL, Y_AXIS_MAX_VALUE_LIMIT } from '../../../../common/commonConstant';
import variables from '../../../../common/commonConstant.scss';
import '../GraphStyle.scss';

const MultiBar = ({
  graphType,
  type,
  min,
  max,
  xAxisCategories,
  yAxisCategories,
  onMouseMove = () => { },
  highlightedLabel,
}) => {

  const highlightedLabelRef = useRef(highlightedLabel);
  highlightedLabelRef.current = highlightedLabel;

  const handleChartEvent = (event, chartContext, config) => {
    if (config?.dataPointIndex > -1) {
      const series = chartContext.w.config.series.map(item => ({ [item.name]: item.data }));
      const data = series.reduce((acc, value) => ({ ...acc, ...value }), {});
      onMouseMove(data, config?.dataPointIndex);
      const labels = chartContext.el.querySelectorAll('.apexcharts-xaxis-texts-g text');
      labels.forEach((label) => {
        if (label.lastChild.innerHTML === highlightedLabelRef.current) {
          label.style.fill = variables.themePrimary;
        }
        else {
          label.style.fill = variables.homegrey;
        }
      });
    }
  };

  const YAxisMax = Math.round(yAxisCategories.reduce((acc, currentVal) => {
    if (currentVal?.data?.length) {
      let maxValue = acc ?? 0;
      currentVal.data.forEach((item) => maxValue = Math.max(maxValue, item.y));
      return maxValue;
    }
  }, 0) * Y_AXIS_MAX_VALUE_LIMIT);
  const options = {
    chart: {
      id: 'amway-food-exercise-water-multi-bar-graph',
      foreColor: '#cbd2e0',
      type: graphType,
      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
      events: {
        mouseMove: handleChartEvent,
        markerClick: handleChartEvent,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: type === LABEL_EN_WEEK_CALENDAR_TAB ? 6 : 7,
        endingShape: 'rounded',
        columnWidth: yAxisCategories.length === 1 ? '35%' : '70%',
      },
    },
    stroke: {
      width: graphType === GRAPH_TYPE.LINE ? 5 : 1,
      lineCap: 'round',
    },
    grid: {
      borderColor: variables.themePrimary,
      strokeDashArray: 4, // Length of dashes in the vertical grid lines
      position: 'back', // Position of the grid lines
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines on the X-axis
        },
      },
      yaxis: {
        lines: {
          show: false, // Hide the horizontal grid lines on the Y-axis
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
      colors: [variables.markerbg],
      strokeColor: variables.themePrimary,
      strokeWidth: 3,
    },
    tooltip: {
      x: {
        show: true, // Hide default tooltip value
      },
      y: {
        title: {
          formatter: () => '',
        },
      },
      custom: function () {
        return '';
      },
    },
    fill: {
      opacity: 1,
      type: "gradient",
      gradient: {
        type: graphType === GRAPH_TYPE.LINE ? 'horizontal' : "vertical",
        gradientToColors: yAxisCategories.map(({ color2 }) => color2),
      }
    },
    xaxis: {
      type: graphType === GRAPH_TYPE.LINE ? 'datetime' : 'category',
      ...graphType === GRAPH_TYPE.LINE ? {} : { categories: xAxisCategories },
      axisBorder: {
        show: false, // Hide the x-axis line
      },
      ...graphType === GRAPH_TYPE.LINE ? { min: min?.getTime() } : {},
      ...graphType === GRAPH_TYPE.LINE ? { max: max?.getTime() + 1 } : {},
      crosshairs: {
        show: true,
        width: 1,
        position: 'front',
        opacity: 0.9,
        stroke: {
          width: 2,
          dashArray: 3,
          color: variables.graphgreen,
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -135,
        formatter: () => ' ',
      },
      tickPlacement: 'between', // Place ticks on the axis,
      ...graphType === GRAPH_TYPE.LINE ? { tickAmount: 6 } : {},
      offsetX: type === LABEL_EN_WEEK_CALENDAR_TAB ? -12 : (graphType === GRAPH_TYPE.LINE ? 4 : -16),
      offsetY: -10,
      labels: {
        style: {
          colors: '#A0ABC0',
          lineHeight: '15px',
          fontSize: '12px',
          fontFamily: 'Sukhumvit Tadmai',
          fontWeight: 700,
        },
        ...graphType === GRAPH_TYPE.LINE ? { formatter: (value) => new Date(value).getHours() } : {},
      },
    },
    yaxis: {
      min: 0,
      max: Math.max(Y_AXIS_DEFAULT_MAX_LABEL, YAxisMax),
      labels: {
        style: {
          colors: '#A0ABC0',
          lineHeight: '15px',
          fontSize: '12px',
          fontFamily: 'Sukhumvit Tadmai',
          fontWeight: 700,
        },
        formatter: (value) => `${parseInt(value)}`,
      },
      tickAmount: 3,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: '#80D26C',
      },
      opposite: true,
    },
    legend: {
      show: false,
    },
  };

  let singleDataClass = '';
  if (yAxisCategories.length === 1) {
    const dataLength = yAxisCategories[0].data.filter((dataItem) => dataItem.y !== null).length;
    singleDataClass = dataLength === 1 ? ' single-data-list' : '';
  }

  const seriesCountClass = yAxisCategories.length === 2 ? ' --two-series' : '';

  return (
    <div className={`graph-style --${graphType}${singleDataClass}${seriesCountClass}`}>
      <Chart options={options} series={yAxisCategories} height={200} width="100%" />
    </div>
  );
};
export default MultiBar;
