import { waterImagesMapping } from '../common/commonConstant';
import config from '../config';
import { FoodGoal, WaterGoal } from './GoalSetting.model';

export class FoodLogParent {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
      this.caloriesPerDay = data.caloriesPerDay || 0;
      this.carbohydratePerDay = data.carbohydratePerDay || 0;
      this.proteinPerDay = data.proteinPerDay || 0;
      this.fatPerDay = data.fatPerDay || 0;
      this.foodGoal = data.foodGoal ? new FoodGoal(data.foodGoal) : null;
      this.myFoodLog = data.myFoodLog ? new FoodLog(data.myFoodLog) : null;
      this.myFoodMenu = data.myFoodMenu ? new FoodMenu(data.myFoodMenu) : null;
      this.weight = data.productWeight || null;
      this.myFoodWeight = data.myFoodWeight?.myFoodWeight || null;
      this.image = this.myFoodMenu?.image
        ? config.S3_URL + this.myFoodMenu?.image
        : '/images/healthAndWellness/foodCalories/food-details/food-default-suggest-card.svg';
      this.amount = data.myFoodMenu?.calorie || '';
      this.name = data.myFoodMenu?.name || '';
    }
  }
}
export class FoodLog {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
      this.breakfast = data.breakfast || [];
      this.lunch = data.lunch || [];
      this.dinner = data.dinner || [];
      this.dietarySupplement = data.dietarySupplement || [];
    }
  }
}

export class FoodMenu {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
      this.name = data.name || '';
      this.productCategory = data.productCategory || '';
      this.unit = data.unit || '';
      this.image = data.image || '';
      this.amount = data.amount || '';
      this.weight = data.weight || '';
      this.carbs = data.carbs || '';
      this.calorie = data.calorie || '';
      this.protein = data.protein || '';
      this.fat = data.fat || '';
    }
  }
}
export class WaterLog {
  constructor(data) {
    if (data) {
      this.coffeeVolume = data.coffeeVolume || 0;
      this.createdDate = data.createdDate || '';
      this.customers = data.customers || '';
      this.softDrinkVolume = data.softDrinkVolume || 0;
      this.updatedDate = data.updatedDate || '';
      this.waterVolume = data.waterVolume || 0;
      this.id = data._id || '';
    }
  }
}

export class WaterLogParent {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
      this.waterIntake = data.waterIntake || 0;
      this.softDrinkIntake = data.softDrinkIntake || 0;
      this.coffeeIntake = data.coffeeIntake || 0;
      this.waterGoal = data.waterGoal ? new WaterGoal(data.waterGoal) : null;
      this.waterVolume = data.waterVolume
        ? new WaterLog(data.waterVolume)
        : null;
      this.amount =
        data.waterIntake || data.softDrinkIntake || data.coffeeIntake || '';
      this.image = data.waterIntake
        ? waterImagesMapping.water.image
        : data.coffeeIntake
        ? waterImagesMapping.tea.image
        : data.softDrinkIntake
        ? waterImagesMapping.softdrink.image
        : '';
      this.name = data.waterIntake
        ? waterImagesMapping.water.title
        : data.coffeeIntake
        ? waterImagesMapping.tea.title
        : data.softDrinkIntake
        ? waterImagesMapping.softdrink.title
        : '';
    }
  }
}
