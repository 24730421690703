import React from "react";
import './BadgeCarousel.scss'
import SlickCarousel from "../slickCarousel/slickCarousel";

const BadgeCarousel = ({ data, onClick }) => {

    return (
        <SlickCarousel slidesToShow={4}>
            {data.map((item, index) => {
                return (
                    <div className="badge-carousel-list" key={index}>
                        <img src={`/images/challenge/badges/bg-badge@5x.png`}/>
                        <img className="badge-img" src={`/images/challenge/badges/steps-${item}-badge@5x.png`} onClick={(event) => onClick(item, event)}/>
                    </div>
                )
            })}
        </SlickCarousel>
    )

}

export default BadgeCarousel;