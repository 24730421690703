import React from 'react';
import './HomeNutritionLoader.scss';

const HomeNutritionLoader = ({ className = null }) => {
  return (
    <div className="home-nutrition-loader-container">
      <div className="steps-skeleton-container">
        <div className="steps-skeleton-card-container">
          <div
            className={`skeleton-card-image skeleton ${className || ''}`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default HomeNutritionLoader;
