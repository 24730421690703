import React, { useState } from 'react';
import './HnwCheckbox.scss';
import { H_W_CHECKBOX_MAPPING } from '../commonConstant';

export default function HnwCheckbox({state = 'unchecked', label, onCheckValueChange}) {
    const checkboxImg = H_W_CHECKBOX_MAPPING[state];
    return (
        <div className='hnw-checkbox-container'>
            <img src={checkboxImg} onClick={onCheckValueChange}/>
            <span className='hnw-checkbox-container-text'>{label}</span>
        </div>
    )
}