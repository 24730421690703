

export class Assessment {
    constructor(data) {

        if (data) {

            const isArrayofStrings = (arr) => {
                return Array.isArray(arr) && arr.every(item => typeof item === 'string');
            }
            if (data.assessmentsStatus == 'DONE') {
                this.answer_id = '';
            } else {
                this.answer_id = data.answer_id || '';
            }
            this.assessment_id = data._id || '';
            this.hw_assessments = isArrayofStrings(data.hw_assessments_questions) ? data.hw_assessments_questions :
                data.hw_assessments_questions.map(value => new Question(value)) || [];
            this.upselling_item = data.upselling_item || [];
            this.title = data.title || '';
            this.description = data.description || '';
            this.home_image_url = data.home_image_url || '';
            this.landing_image_url = data.landing_image_url || '';
            this.status = data.assessmentsStatus || '';
            this.assessment_status = data.assessmentsStatus || '';
            if (data.ListType) {
                this.score_image_url = data.ListType.imageUrl || '';
                this.score_title = data.ListType.scoreTitle || '';
                this.min_score = data.ListType.minScore || '';
                this.max_score = data.ListType.maxScore || '';
            }
            this.customerUid = data.customers?.uid || '';
        }
    }
}

export class Question {
    constructor(data) {
        this._id = data._id || '';
        this.question = data.question || '';
        this.description = data.description || '';
        this.question_type = data.question_type || '';
        this.minimum_answer = data.minimum_answer || '1';
        this.display_order = data.display_order || '';
        this.assessment_id = data.assessments || '';
        this.answer = data.answer || [];
        this.total_score = data.total_score || [];
        this.isAnswerGiven = data.isAnswerGiven || false;
        this.question_value = data.question_value.map(value => new QuestionValue(value));
    }
}


export class QuestionValue {
    constructor(data) {
        this._id = data._id || '';
        this.key = data.key || '';
        this.value = data.value || '';
        this.image_url = data.image_url || '';
        this.image_active_url = data.image_active_url || '';
        this.image_inactive_url = data.image_inactive_url || '';
        this.score = data.score || 0;
        this.total_score = data.total_score || [];
        this.content = data.content || '';
        this.short_name = data.short_name || '';
        this.recommended_item = data.recommended_item || [];
    }
}

export class AssessmentAnswer {
    constructor(assessment_id, assessment_data, customer_id, answer_id, assessment_status) {
        this.customer_id = customer_id || '';
        this.assessment_id = assessment_id || '';
        this.answer_id = answer_id || '';
        this.score = calculateTotalScore(assessment_data) || 0;
        this.assessmentsStatus = assessment_status || 'PENDING';
        this.answers = assessment_data?.map(answer => new Answers(answer)) || [];
        if(assessment_data?.length) {
            const healthInfo = assessment_data[0]?.healthInfo;
            this.weight = healthInfo?.healthData?.weight || null;
            this.height = healthInfo?.healthData?.height || null;
            this.waist = healthInfo?.healthData?.waist || null;
            this.bmi = healthInfo?.healthData?.bmi || null;
            this.age = healthInfo?.healthData?.age || null;
        }
    }
}

export class Answers {
    constructor(answer_data) {
        this.question_id = answer_data._id || '';
        this.answer = answer_data.answer || [];
    }
}

export class AssessmentDetailData {
    constructor(data) {
        if (data) {
            this.answer_id = data?.answer?._id || '';
            this.customer_id = data?.answer?.customers || "";
            this.assessment_id = data?.answer?.assessments;
            this.assessments_status = data?.answer?.assessmentsStatus || '';
            this.total_score = data?.answer?.totalScore || '';
            this.answers = data?.answer?.answers || [];
            this.image_url = data?.imageUrl || '';
        }
    }
}

export class AssessmentHistoryLog {
    constructor(data) {
        if (data) {
            this._id = data?._id || '';
            this.updated_date = data?.updatedDate || '';
            this.image_url = data?.imageUrl || '';
            this.score_title = data?.scoreTitle || '';
            this.title = data?.title || '';
        }
    }
}

const calculateTotalScore = (data) => {
    let totalScore = 0;
    if(data) {
        data.forEach(assessment => {
            // Sum up the values in the total_score array
            totalScore += assessment.total_score.reduce((acc, curr) => acc + parseInt(curr), 0);
        });
    }
    return totalScore;
}