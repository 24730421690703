import React, { useEffect, useRef } from 'react';
import './QuizAnswerButton.scss';

const QuizAnswerButton = (props) => {
  const {
    isAnswered,
    answer,
    labels,
    onTextWrap,
    isCorrect,
    isSelected,
    onSelect,
    parentRef
  } = props;

  const selectedClass = isSelected ? 'btn-light-green-active' : '';
  const answeredClass = isAnswered
    ? isCorrect
      ? 'btn-light-green btn-light-green-active'
      : 'btn-light-red btn-light-red-active'
    : 'btn-light-green';

  const ansBtnClasses = `${selectedClass} ${answeredClass}`;

  const handleClick = !isAnswered ? onSelect : undefined;

  const answerRef = useRef(null);

  useEffect(() => {
    if (answerRef.current && parentRef.current) {
      const spanWidth = answerRef.current?.clientWidth;
      const containerWidth = parentRef.current?.clientWidth;
      const lineHeight = parseFloat(
        window.getComputedStyle(answerRef.current)?.lineHeight,
      );
      const height = answerRef.current?.clientHeight;
      const isWrapped = (height > lineHeight) || (spanWidth > containerWidth * 0.3);
      if (isWrapped) {
        onTextWrap(true);
      }
    }
  }, [answer, onTextWrap]);

  return (
    <button onClick={handleClick} className={ansBtnClasses}>
      <span className="answer-type">
        <span className="answer-type-inner">
          {isAnswered ? <img src={labels} /> : ` ${labels?.label} `}
        </span>
      </span>
      <span ref={answerRef}>{answer}</span>
    </button>
  );
};

export default QuizAnswerButton;
