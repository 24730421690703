import React from 'react';
import './NoDataCard.scss'

const NoDataCard = ({children}) => {
    return (
        <div className={`no-data-card-container`} >
            {children}
        </div>
    )
}

export default NoDataCard