import React, { useEffect, useState, useCallback } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import './registerFOA.scss';
import Select from 'react-select';
import {
  getCustomerFOA,
  validateCaptcha,
  validateRegister,
  generateOTPregis,
} from '../../../services/promotion/foa.service';
import moment from 'moment';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import {FOARegisterAddress} from './registerFOAAddress.jsx';
import { message, updateDeliveryCartPublic } from '../../../services/promotion/utils.service';
import {
  createAddress,
} from '../../../services/promotion/address.service';
import { osName } from 'react-device-detect';
import { customerService } from '../../../services';
import { liffGetProfile } from '../../../services/promotion/auth.service.js';

const { liff } = window;
const today = new Date();
const maxYear = today.getFullYear() + 540;
const minYear = today.getFullYear() + 443;

export const FOARegister = () => {
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [surName, setSurName] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('M | F');
  const [cid, setCid] = useState('');
  const [cidInput, showCidInput] = useState(false);
  const [isNotAllField, setIsNotAllField] = useState(true);
  const [foaAddressIsNotAllField, setFoaAddressIsNotAllField] = useState(true);
  const [customerData, setCustomerData] = useState(null);
  const [cartId, setCartId] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [validatePhone, setValidatePhone] = useState(false);
  const [validateName, setValidateName] = useState(false);
  const [validateSurname, setValidateSurName] = useState(false);
  const [validateCID, setValidateCID] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);

  const onCaptchaChange = useCallback((token) => {
    console.log('token', token)
    setCaptcha(token);
  },[]);

  const submitProfileHandler = async(address) => {
    setLoading(true)
    const customerData = JSON.parse(sessionStorage.getItem('customer'));

    const customer = {
      name: `${firstName} ${surName}`,
      birthday: birthDate,
      gender: (gender === 'M') ? 'male': 'female',
      province: address.province,
      phone: phone,
      info: osName === 'IOS' ? 'iPhone' : osName,
      _id: customerData._id,
    };
    if(cidInput) {
      customer['cid'] = cid;
    }

    const updated = await customerService.update(customer._id, customer);
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(`${firstName} ${surName}`)) {
      const errorMessage = updated.message instanceof Object
        ? updated.message.map(value => Object.values(value.constraints))
        : 'ไม่สามารถบันทึก ชื่อ-นามสกุล ที่มีตัวอักษรพิเศษ';

      alert(errorMessage);
      setLoading(false);
    }

    if (!updated._id || format.test(`${firstName} ${surName}`)) {
      const errorMessage = updated.message instanceof Object ?
        updated.message.map(value => Object.values(value.constraints)) :
        'ระบบไม่สามารถบันทึกข้อมูลได้กรุณาติดต่อเจ้าหน้าที่';

      alert(errorMessage);
      setLoading(false);
    }

    sessionStorage.setItem('customer', JSON.stringify(updated));
    setRefreshReCaptcha((r) => !r);
    const tokenCaptCha = captcha;
    console.log('tokenCaptCha', tokenCaptCha)
    const validateCaptchaData = await validateCaptcha(tokenCaptCha);
    console.log('validateCaptchaData.result.access_token', validateCaptchaData)
    if (validateCaptchaData && validateCaptchaData.result) {
      const validateRegisterData = await validateRegister(
        validateCaptchaData?.result?.access_token,
        phone,
      );
      setLoading(false);

      if (validateRegisterData.success) {
        const otpRegisData = await generateOTPregis(
          validateCaptchaData.result.access_token,
          phone,
          cartId,
        );

        const cartFoaData = {
          thFirstName: firstName,
          thLastName: surName,
          enFirstName: null,
          enLastName: null,
          gender,
          dateOfBirth: birthDate,
          phoneNumber: phone,
          address: address,
          deliveryMethod: {
            deliveryMode: null,
            deliveryContactNumber: null,
            address: address,
            shop: null,
          },
          isInfoSubscription: false,
          cartId,
        };
        sessionStorage.setItem('cartFoaData', JSON.stringify(cartFoaData));

        if (otpRegisData.success) {
          window.location.href = `/foa-otp?phoneNumber=${phone}&token=${validateCaptchaData.result.access_token}&cartId=${cartId}`;
        } else {
          alert('เกิดข้อผิดพลาดกรุณาติดต่อเจ้าหน้าที่');
        }
      } else {
        const errMsg =
          validateRegisterData.error[0]?.th_message ||
          'เกิดข้อผิดพลาดกรุณาติดต่อเจ้าหน้าที่';
        alert(errMsg);
      }
    } else {
      setLoading(false)
      const errMsg =
        validateCaptchaData.error?.message ||
        'เกิดข้อผิดพลาดกรุณาติดต่อเจ้าหน้าที่';
      alert(errMsg);
    }

   

  }

  const submitHandler = async () => {
    setLoading(true);
    await liff.ready;
    let profile = await liffGetProfile();
    const cartId = JSON.parse(localStorage.getItem('pifCartId'));
    const guid = sessionStorage.getItem('guid');
    const address = JSON.parse(sessionStorage.getItem('address'));
    address['contactPersonName'] = firstName || '';

    const result = await updateDeliveryCartPublic(
      profile.userId,
      null,
      phone,
      cartId,
      false,
      address,
      guid
    );
    setLoading(true);
    if(result == 200) {
      submitProfileHandler(address)
    } else {
      setLoading(false)
      const errMsg =
        result.error?.message ||
        'เกิดข้อผิดพลาดกรุณาติดต่อเจ้าหน้าที่';
      alert(errMsg);
    }
  };

  const fetchData = async () => {
    await liff.ready;
    let profile = await liffGetProfile();
    const cartId = JSON.parse(localStorage.getItem('pifCartId'));
    setCartId(cartId);
    const customerData = await getCustomerFOA(profile.userId);
    setCustomerData(customerData);
    if (customerData !== null) {
      const nameArr = customerData.name?.split(' ');
      const firstName = nameArr && nameArr[0];
      const surName = nameArr && nameArr[1];
      const birthDateArr = moment(customerData.birthday)
        .format('DD-MM-YYYY')
        .split('-');

      setBirthDate(moment(customerData?.birthday).format('DD/MM/YYYY'))
      setFirstName(firstName || '');
      setSurName(surName || '');
      setPhone(customerData.phone);
      if (customerData.gender) {
        if(customerData.gender === 'male'){
          setGender('M');
        }
        if(customerData.gender === 'female'){
          setGender('F')
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const buttonSubmitHandler = () => {
    return firstName !== '' &&
      surName !== '' &&
      birthDate !== '' &&
      phone?.length === 10 &&
      gender !== '' &&
      validatePhone === false &&
      validateName === false &&
      validateSurname === false &&
      updateAddressFieldStatus === false
      ? false
      : true;
  };


  const validatePhoneNumber = (value) => {
    const stPhone = value.toString();
    if (stPhone.slice(0, 1) !== '0') {
      setValidatePhone(true);
    } else {
      setValidatePhone(false);
    }
  };
  const validationName = (value) => {
    const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~1234567890๑๒๓๔ู฿๕๖๗๘๙`;
    const result = specialChars.split('').some((specialChar) => {
      if (value.includes(specialChar)) {
        return true;
      }
      return false;
    });
    setValidateName(result);
  };
  const validationSurname = (value) => {
    const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~1234567890๑๒๓๔ู฿๕๖๗๘๙`;
    const result = specialChars.split('').some((specialChar) => {
      if (value.includes(specialChar)) {
        return true;
      }
      return false;
    });
    setValidateSurName(result);
  };

  const isValidThaiIdCardFormat = (idCardNo) => {
    if (idCardNo.length === 13) {
      const digit = idCardNo.split("");
      let sum = 0;
      for (let i = 0; i < 12; i++) {
        sum += digit[i] * (13 - i);
      }
      const mod11 = sum % 11;
      const checksum = mod11 > 1 ? 11 - mod11 : 1 - mod11;
      setValidateCID(`${checksum}` === digit[12]);
    } else {
      setValidateCID(false);
    }
  }

  useEffect(() => {
    setIsNotAllField(buttonSubmitHandler());
  }, [
    firstName,
    surName,
    birthDay,
    month,
    year,
    phone,
    gender,
    validatePhone,
    validateName,
    validateSurname,
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  const setAddress = async (currentAddress, addressList) => {
    setLoading(true);
    setMapOpen(false);
    const haveAddressAlready = addressList.find(
      (addr) =>
        addr.fullAddress === currentAddress.fullAddress &&
        addr.phone === currentAddress.phone,
    );
    if (haveAddressAlready) {
      alert(message.error.alreadyAddress);
      setLoading(false);
    } else {
      if (customerData) {
        setLoading(false);
        sessionStorage.setItem('address', JSON.stringify(currentAddress));
      }
    }
  }

  const updateAddressFieldStatus = (value) => {
    setFoaAddressIsNotAllField(value);
    return value;
  }

  const buttonDisable = () => {
    return isNotAllField && foaAddressIsNotAllField;
  }

  return (
    <section>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%', value: '' } }}
      />
      <div className={`scroll ${!mapOpen ? 'product-panel-promotion' : ''}`}>
        {<FOARegisterAddress hasMapOpen={(val) => setMapOpen(val)} setUserAddress={setAddress}
          updateFieldStatus={updateAddressFieldStatus} />}

        {!mapOpen &&
          <div className="foa-create-address-container">
            <div className="body">

              <div className="address-fields-container">
                <div className="address-fields-title">
                  <img src="/images/promotion/icon_address_fields.png" />
                  <div className="address-fields-title-text">ข้อมูลของคุณ</div>
                </div>
                <div>
                  <label className="label-address">ชื่อ</label>
                  <div className="input-bars">
                    <input
                      className="input-address"
                      type="text"
                      placeholder="ชื่อ"
                      value={firstName}
                      onChange={(e) => {
                        const regEx = /[^\u0E00-\u0E3E\u0E40-\u0E7Fa-zA-Z\s]/g;
                        !regEx.test(e.target.value) && setFirstName(e.target.value);
                      }}
                    />
                  </div>
                  {validateName ? (
                      <p className="alert-message">กรุณากรอกชื่อให้ถูกต้อง</p>
                    ) : (
                      <></>
                    )}
                  <label className="label-address">นามสกุล</label>
                  <div className="input-bars">
                    <input
                      className="input-address"
                      type="text"
                      placeholder="นามสกุล"
                      value={surName}
                      onChange={(e) => {
                        const regEx = /[^\u0E00-\u0E3E\u0E40-\u0E7Fa-zA-Z\s]/g;
                        !regEx.test(e.target.value) && setSurName(e.target.value);
                      }}
                    />
                  </div>
                  {validateSurname ? (
                      <p className="alert-message">กรุณากรอกนามสกุลให้ถูกต้อง</p>
                    ) : (
                      <></>
                    )}
                  <label className="label-address">เพศ</label>
                  <div className="gender-option">
                    <div className="radio-style">
                      <div className="gender-type">
                      <input type="radio" id="a25" name="gender" value={gender} onClick={() => setGender('M')} checked={gender == 'M'}/>
                        <label for="M">{'ชาย'}</label>
                      </div>
                      <div className="gender-type">
                      <input type="radio" id="a25" name="gender" value={gender} onClick={() => setGender('F')} checked={gender == 'F'}/>
                        <label for="F">{'หญิง'}</label>
                      </div>
                    </div>
                  </div>

                  <label className="label-address">วันเกิด</label>
                  <br />
                  <div className="input-bars">
                    <input type="text"
                      className="input-date"
                      id="birthDate"
                      name="birthDate"
                      placeholder="วว / ดด / ปปปป"
                    onChange={(e) => {setBirthDate(e.target.value);}}
                    onPaste={(e) => { e.preventDefault(); return false;}}
                      // max={`01-01-${maxYear}`}
                      // min={`01-01-${minYear}`}
                      value={birthDate}
                    />
                  </div>

                  <label className="label-address">เบอร์โทรศัพท์ผู้รับสินค้า</label>
                  <div className="input-bars">
                    <input
                      className="input-address"
                      type="tel"
                      value={phone}
                      placeholder={'084-xxx-xxxx'}
                      maxLength={10}
                      onChange={(e) => {
                        const regEx = /^\d*\.?\d*$/;
                        validatePhoneNumber(e.target.value);
                        regEx.test(e.target.value) && setPhone(e.target.value);
                      }}
                    />
                  </div>
                  {validatePhone ? <p className="alert-message">กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง</p> : <></>}
                  <div className="checkbox-cid">
                   <input type="checkbox" id="a25" name="cid" checked={cidInput} onClick={() => showCidInput(!cidInput)}/>
                    <label className="label-address">ต้องการใบกำกับภาษี</label>
                  </div>

                  {cidInput &&
                    <>
                      <label className="label-address">เลขบัตรประชาชน 13 หลัก</label>
                      <div className="input-bars">
                        <input
                          className="input-address"
                          type="text"
                          value={cid}
                          placeholder={'เลขบัตรประชาชน 13 หลัก'}
                          maxLength={13}
                          onChange={(e) => {
                            const regEx = /^\d*\.?\d*$/;
                            isValidThaiIdCardFormat(e.target.value);
                            regEx.test(e.target.value) && setCid(e.target.value);
                          }}
                        />
                        {validatePhone ? <p className="alert-message">กรุณากรอกรหัสประจำตัวของคุณให้ถูกต้อง</p> : <></>}
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      {!mapOpen &&
        <footer>
          <div className="product-panel-footer">
            <div className="select-promotion-footer font-larger">
              <div className="text-center">
                <button className={`away-button ${!buttonDisable() ? 'active' : ''}`} disabled={buttonDisable()} onClick={submitHandler}>บันทึก</button>
              </div>
            </div>
          </div>
      </footer> }
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}>
        <GoogleReCaptcha
          onVerify={onCaptchaChange}
          refreshReCaptcha={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </section>
  );
};
