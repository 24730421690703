import React from 'react'
import { SUPPLEMENT_GOAL_HOME_HEADER_TITLE } from '../../../../../common/commonConstant'
import { getParamsString } from '../../../../../utill.func';

const HnWFoodCaloriesHeader = ({ routeTo, isSupplementGoalHeader, referrerPage }) => {

  const routePath = path => {
    let pathParamsObj = {};
    if (referrerPage) {
      pathParamsObj = {
        ...pathParamsObj,
        referrer: referrerPage
      }
    }
    const params = getParamsString(pathParamsObj);
    routeTo(path, `?${params}`);
  }

  return (
    <div className={`header-container ${isSupplementGoalHeader ? 'supplement-goal-list-container__header' : ''}`}>
      <div className="container-fluid header-wrapper">
        <div className="row align-items-center">
          <div className="col-9">
            <h2 className="home-title">
              <img src="/images/healthAndWellness/foodCalories/icons/home.svg" className='mr-1' alt="home" onClick={() => routePath('pathHealthnWellness')} />
              {isSupplementGoalHeader && SUPPLEMENT_GOAL_HOME_HEADER_TITLE}
            </h2>
          </div>
          <div className="col-3 text-right">
            {
              isSupplementGoalHeader ?
                <img src="/images/healthAndWellness/fi_user.svg" onClick={() => routePath('pathProfile')} alt="user_profile_icon" />
                : <img src="/images/healthAndWellness/foodCalories/icons/add_menu.svg" alt="addMenu" onClick={() => routePath('pathAddMenu')} />
            }
          </div>
        </div>
      </div>
      <img src="/images/healthAndWellness/foodCalories/food-list/food-header-curve.svg" alt="header_bg" className="w-100 food-header-curve-img" />
    </div>
  )
}

export default HnWFoodCaloriesHeader