import React from 'react';
import './HealthSummaryInfoCard.scss';
import { HEALTH_SUMMARY_INFO_CARD_DETAILS } from '../commonConstant';
import { round } from '../../services';

export default function HealthSummaryInfoCard({ dataItems }) {
  return (
    <div className="health-summary-container">
      <div className="health-summary-info-container">
        {dataItems.map((item) => <div key={item.componentName} className={`health-summary-info-wrapper ${item.componentName}`}>
          <div className="img-wrapper">
            <img src={HEALTH_SUMMARY_INFO_CARD_DETAILS[item.componentName].iconPath} alt={item.componentName} />
          </div>
          <div className="health-summary-wrapper">
            <span className="health-summary-name">{HEALTH_SUMMARY_INFO_CARD_DETAILS[item.componentName].name}</span>
            <span className="health-summary-value">
              {round(item.value, 0).toLocaleString() || '-'}
            </span>
            <span className="health-summary-unit">{HEALTH_SUMMARY_INFO_CARD_DETAILS[item.componentName].unit}</span>
          </div>
        </div>)}
      </div>
    </div>
  );
}
