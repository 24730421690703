import React, { useEffect, useState, useRef } from 'react';

import '../promotion/CartPromotion.scss';

export const LIFF_OPEN = 'open';

const LiffSopProductDetails = (props) => {
  const [isLiffOpen, setIsLiffOpen] = useState('');
  const detailsContainerRef = useRef(null);
  const detailsHeaderRef = useRef(null);

  useEffect(() => {
    const isLiffOpen = props.isOpen ? LIFF_OPEN : '';
    setIsLiffOpen(isLiffOpen);
  }, [props.isOpen]);

  const onClose = () => {
    props.onClose();
  };

  const getProfileContainerHeight = () => {
    const finalHeight = ((window.innerHeight || 0) * 0.9) - detailsHeaderRef?.current?.clientHeight;
    return finalHeight;
  }

  return (
    <section className={`liff-panel ${isLiffOpen}`}>
      <div className="select-coupon-panel" ref={detailsContainerRef}>
        <div className="header font-bold font-stock" ref={detailsHeaderRef}>
          <div className={props.headerClass ? props.headerClass : ''}>{props.title}</div>
          <button className="btn-close" onClick={onClose}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
              <path d="M18 18L6 6" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
        <div className="font-stock" style={{height: getProfileContainerHeight(), overflow:'auto'}}>{props.children}</div>
      </div>
    </section>
  );
};

export default LiffSopProductDetails;
