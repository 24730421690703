
import React, { useState } from 'react';
import './AssessmentProgressScreen.scss';
import { HnWDeleteModal, commonConstant } from '../../../../common';
import { DEFAULT_ASSESSMENT_PROGRESS_SCREEN } from '../../../../common/commonConstant';
import config from '../../../../config';
import HnWContentModal from '../../../../common/hnWDeleteModal/HnWContentModal';

export const AssessmentProgressScreen = ({ assessmentDetail, assessmentId, redirectToAssessment, redirectToAssessmentHome }) => {
    const redirectToScorePage = () => {
        const redirectUrl = `${commonConstant.pathHnWAssessmentScore}?assessment_id=${assessmentId}&answer_id=${assessmentDetail?.answer_id}`;
        window.location.href = redirectUrl;
    }
    const [openModal, setOpenModal] = useState(false);
    const btnTitle1 = `${DEFAULT_ASSESSMENT_PROGRESS_SCREEN.btnTitle1}`;
    const btnTitle2 = `${DEFAULT_ASSESSMENT_PROGRESS_SCREEN.btnTitle2}`;
    const renderContentModal = () => {
        return (
            <HnWContentModal btnTitle1={btnTitle1} btnTitle2={btnTitle2} assessmentId={assessmentId} btnOnClick1={redirectToAssessmentHome} btnOnClick2={redirectToScorePage} />
        );
    };
    return (<><div className='asm-progress-ctn'>
        <img className='progress-img'
            src={`${config.S3_URL}${assessmentDetail?.image_url}`}
            onClick={redirectToScorePage}
        />
        <div className='progress-footer'>
            <div className='btn1' onClick={() => window.location.href = commonConstant.pathHnWAssessmentHistoryLog} >
                <img
                    src="/images/healthAndWellness/assessment/assessment_history_log.svg"
                />
                <span >
                    {DEFAULT_ASSESSMENT_PROGRESS_SCREEN.btn1}
                </span>
            </div>
            <div className='btn2' onClick={() => setOpenModal(true)} >
                <img
                    src="/images/assesment/assessment_repeat.svg"
                />
                <span >
                    {DEFAULT_ASSESSMENT_PROGRESS_SCREEN.btn2}
                </span>
            </div>
        </div>
        <HnWDeleteModal
            onClose={() => setOpenModal(false)}
            isOpen={openModal} title={DEFAULT_ASSESSMENT_PROGRESS_SCREEN.title}
            subtitle={[DEFAULT_ASSESSMENT_PROGRESS_SCREEN.subtitle]}
        >
            <div className="scroll">
                {renderContentModal()}
            </div>
        </HnWDeleteModal>
    </div></>)
}

export default AssessmentProgressScreen;
