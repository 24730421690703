import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import './Confirmation.scss';

export const Confirmation = () => {
  const payment = useSelector(state => state.payment);
  
  const renderTitle = useCallback(() => {
    switch (payment.type){
      case 'amplus':
        return <div>สรุปรายการใช้คะแนนแอมพลัส</div>;
      default:
        return <div>สรุปรายการชำระเงิน</div>;
    }
  },[payment.type]);
  
  const renderData = useCallback(() => {
    switch (payment.type){
      case 'creditcard':
        return (
          <div>
            <div>บัตรเครดิต</div>
            <div className="bold">ไทยพาณิชย์</div>
            <div>เลขที่บัตร</div>
            <div className="bold">4417 70 • • • • • • 1811</div>
          </div>
        );
      case 'counterservice':
        return (
          <div>
            <div className="bold">เคาน์เตอร์เซอร์วิส</div>
            <div>สามารถชำระเงินได้ที่เคาน์เตอร์เชอร์วิส (7-11) ก่อนเวลา 23.30 น. หากชำระเงิน หลังเวลาดังกล่าว คำสั่งซื้อของท่าน จะถูกยกเลิกโดยอัตโนมัติ</div>
          </div>
        );
      case 'amplus':
        return (
          <div>
            <div>รหัสสมาชิก</div>
            <div className="bold">48201</div>
            <div>คะแนนทั้งหมดที่มี</div>
            <div className="bold">2,500</div>
          </div>
        );
      case 'amwayshop':
        return (
          <div>
            <div className="bold">แอมเวย์ ช็อป</div>
            <div>สามารถชำระเงินได้ที่ แอมเวย์ ช็อป ตามเวลาทำการช็อป</div>
          </div>
        );
      default: 
        return null;
    }
  },[payment.type]);

  const renderAmount = useCallback(() => {
    switch (payment.type){
      case 'amplus':
        return (
          <>
            <div>คะแนนที่ใช้</div>
            <div className="amount">1,250</div>
          </>
        );
      default: 
        return (
          <>
            <div>จำนวนเงินที่ต้องชำระ</div>
            <div className="amount">฿ 100.00</div>
          </>
        );
    }
  },[payment.type]);

  const renderFooter = useCallback(() => {
    switch (payment.type){
      case 'creditcard':
        return <button className="away-button active">ชำระเงิน</button>;
      default:
        return (
          <>
          <button className="away-button">เปลื่ยนแปลงช่องทางชำระเงิน</button>
          <button className="away-button active">ชำระเงิน</button>
          </>
        );
    }
  });

  return (
    <div className="payment-success-container">
      <div className="header">
        {renderTitle()}
      </div>
      <div className="body">
        {renderData()}
        <hr />
        <div className="bottom">
          {renderAmount()}
        </div>
      </div>
      <div className="footer">
        {renderFooter()}
      </div>
    </div>
  );
};
