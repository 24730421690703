import React from "react";
import "./BodyCardNoData.scss";
import { BODY_CARD_NO_DATA } from "../../../../common/homePageConstants";
import { commonConstant, NoDataCard } from "../../../../common";
import { useHistory } from "react-router-dom";

const BodyCardNoData = ( {change} ) => {
    const history = useHistory();
    const handleClickWaist = () =>{
        return history.push(commonConstant.pathWaist);
    }
    const handleClickFat = () =>{
        return history.push(commonConstant.pathFat);
    }
    const handleClickMuscle = () =>{
        return history.push(commonConstant.pathMuscle);
    }
    const handleClickHeight = () =>{
        return history.push(commonConstant.pathHeight);
    }
    const bodyNoDataClickHandlers = {
        waist: handleClickWaist,
        fat: handleClickFat,
        muscle: handleClickMuscle,
        height:handleClickHeight
      };
    return (
        <div className="home-composition-no-data-container">
                <NoDataCard>
                    <div className="no-data-wrapper" onClick={bodyNoDataClickHandlers[change]}>
                        <div className="no-data-wrapper-image">
                            <img src={BODY_CARD_NO_DATA[change].image} /></div>
                        <div className="no-data-wrapper-header">
                            <div className="no-data-wrapper-header-title">{BODY_CARD_NO_DATA[change].title}</div>
                            <div className="no-data-wrapper-header-unit">{BODY_CARD_NO_DATA[change].unit}</div>
                        </div>
                    </div>
                </NoDataCard>
        </div>

    )
}

export default BodyCardNoData;