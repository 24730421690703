import React, { useEffect, useState, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Checkbox } from '../../../helpers';
import { addSOPSubscription } from '../../../services/sop-sub.service';
import '../../sop/Subscription/SOPSubscription.scss';
import { commonConstant } from '../../../common';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { thaiDate } from '../../../../src/utill.func';
import { getPriceFormat, getCart, isCartABOType } from '../../../services/promotion/utils.service';
import CartIcon from '../../promotion/CartIcon';
import { customerService } from '../../../services';

const { liff } = window;

export const PDPItem = ({
  info,
  id,
  img,
  identifier,
  desc,
  price,
  amwayValue,
  sopPlans,
  isKitEntries,
}) => {
  const [choose, setChoose] = useState(sopPlans.length === 1 ? 0 : null);
  const [regist, setRegist] = useState(true);
  const [childProducts, setChildProducts] = useState([]);
  const [userType, setUserType] = useState('ABO');
  const scrollToDivRef = useRef(null)
  const [codePlan, setCodePlan] = useState(
    sopPlans.length === 1 ? sopPlans[0].code : null,
  );
  const [loading, setLoading] = useState(false);
  const [showScrollBottom, setShowScollBottom] = useState(true);
  const autoSub = regist;
  const selectedPlan = codePlan;
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const monthTH = commonConstant.SHORTMONTHS.th[today.getMonth()];
  const year = today.getFullYear();

  useEffect(()=>{
    const didMount = async () => {
      try {
        const customer = JSON.parse(sessionStorage.getItem('customer'));
        const customerData =  await customerService.get(customer.uid);
        if(customerData && customerData.type){
          setUserType(customerData.type.toUpperCase());
        }
      } catch (err){}
    }
    didMount();
  },[])

  const renderOption = () => {
    return sopPlans.map((value, index) => (
      <div className={"w-100 option-detail-box mt-10" + (index === choose ? ' sop-active-plans':'')} key={`sopPlan-${index}`}>
        <div className="option-content">
          <Checkbox
            checked={index === choose}
            containerClassname={'sop-plans-title'}
            svg={index === choose ?
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="7" fill="white" stroke="#38539A" strokeWidth="6" />
              </svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="9" fill="white" stroke="#2C2C2C" strokeWidth="2" />
              </svg>
            }
            onClick={() => {
              setChoose(index);
              setCodePlan(value.code);
            }}
          >
            {value.cycle.name}
          </Checkbox>
        </div>
        <div className="benefits-box box mr-20">
          {value.benefits.length === 0
            ? 'ไม่มีสิทธิประโยชน์ในแผนการซื้อนี้'
            : value.benefits.length === 1
            ? value.benefits[0]?.description
            : `${value.benefits[0]?.description}${'\n'}และ${
                value.benefits[1]?.description
              }`}
        </div>
        <div className="box">
          {value.elementTemplates.length === 1 ? (
            <ProgressBar
              percent={100}
              height={2}
              filledBackground="linear-gradient(to right, #2C2C2C, #2C2C2C)"
            >
              <Step transition="scale">
                {({ accomplished }) => (
                  <div className="sopPlan-tag-start-image">
                    <br />
                    <br />
                    <div className="month-position-benefits">{monthTH}</div>
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished }) => (
                  <div className="sopPlan-tag-start-image">
                    <br />
                    <br />
                    <div className="month-position-benefits">
                      {commonConstant.SHORTMONTHS.th[today.getMonth() + 1]}
                    </div>
                  </div>
                )}
              </Step>
            </ProgressBar>
          ) : (
            <ProgressBar
              percent={100}
              height={2}
              filledBackground="linear-gradient(to right, #2C2C2C, #2C2C2C)"
            >
              {value.elementTemplates.map((item, index) => (
                <Step transition="scale">
                  {({ accomplished }) =>
                    index === 0 ? (
                      <div className="sopPlan-tag-start-image">
                        <br />
                        <br />
                        <div className="month-position-benefits">{monthTH}</div>
                      </div>
                    ) : item.benefits.length > 0 ? (
                      <div className="sopPlan-tag-benefit-image">
                        <br />
                        <br />
                        <div className="month-position-benefits">
                          {
                            commonConstant.SHORTMONTHS.th[
                              (today.getMonth() +
                                index * value.cycle.frequency) %
                                12
                            ]
                          }
                        </div>
                      </div>
                    ) : (
                      <div className="sopPlan-tag-period-image">
                        <br />
                        <br />
                        {
                          commonConstant.SHORTMONTHS.th[
                            (today.getMonth() + index * value.cycle.frequency) %
                              12
                          ]
                        }
                      </div>
                    )
                  }
                </Step>
              ))}
            </ProgressBar>
          )}
        </div>
      </div>
    ));
  };

  const onRegist = async () => {
    if (choose === null) {
      return alert('กรุณาเลือกแผนการสั่งซื้อสินค้า');
    }
    setLoading(true);
    if (isKitEntries.length > 0) {
      isKitEntries.map((value) => {
        childProducts.push({
          kitEntryCode: value.kitEntryCode,
          productCode: value.product.id,
          quantity: value.configuredQty,
        });
      });
    }
    const payload = {
      sopProduct: {
        id,
      },
      selectedSopPlan: {
        code: selectedPlan,
      },
      selectedEntries: [
        {
          quantity: 1,
        },
      ],
      selectedCreditCard: {
        id: null,
      },
      deliveryAddress: {
        id: null,
      },
      startDay: day,
      startMonth: month,
      startYear: year,
      isAutoContinue: autoSub,
      phoneNumber: null,
      childProducts: childProducts,
    };
    try {
      liff
        .sendMessages([
          {
            type: 'text',
            text: `สั่งซื้อสินค้า ${identifier} จำนวน ${payload.selectedEntries[0].quantity} ชุด`,
          },
        ])
        .then(() => {
          console.log('message sent');
        })
        .catch((err) => {
          console.log('error', err);
        });
      const addSOPSubscriptionToCart = await addSOPSubscription(payload);
      setChildProducts([]);
      setLoading(false);
      window.location.href = commonConstant.pathCartPromotion;
    } catch (error) {
      if (
        error.response.data.statusCode === '400' &&
        error.response.data.message.errorCode === 'PLANEXCEED'
      ) {
        setLoading(false);
        liff.closeWindow();
      } else {
        setLoading(false);
        alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
      }
    }
  };

  const scrollToBottom = () => {
    setShowScollBottom(false);
    scrollToDivRef.current.scrollIntoView({behavior: 'smooth'})  
  }

  return (
    <section>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      <div className="sop-detail-container">
        <div className="image-detail">
          <div className='sop-pdp-cart-icon'>
            <CartIcon />
          </div>
          <img className="w-h-100" alt="product gift image" src={img} />
        </div>
        <div className="sop-name-box">
          <span className="gray-font mb-5 mt-5">#{id}</span>
          <h1 className="sop-pdp-title title-style">{identifier}</h1>
          <div className='display-flex sop-pdp-price'>
            <span>฿ {getPriceFormat(price)}</span>
          </div>
          <div className="display-flex align-item-center sop-pdp-pvbv">
            {isCartABOType(userType) && <div className="w-50">
              PV&thinsp;
              {amwayValue.pointValue ? (
                <>{amwayValue.pointValue.toLocaleString()}</>
              ) : (
                <>N/A</>
              )}{' '}
              / BV&thinsp;
              {amwayValue.businessVolume ? (
                <>{amwayValue.businessVolume.toLocaleString()}</>
              ) : (
                <>N/A</>
              )}
            </div>}
            <div className="medium-blue font-bold price-style text-right w-50">
              
            </div>
          </div>
        </div>
        <div className="sop-pdp-details" dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
      <div className="sop-pdp-plans option-field">
        <div>{renderOption()}</div>
      </div>
      <div ref={scrollToDivRef}></div>
      
      <div className="footer sop-footer">
        <div className="autoSub-box">
          <div className="autoSub-content">
            <Checkbox
              checked={regist}
              svg={regist ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="18" height="18" rx="3" fill="#38539A" stroke="#38539A" strokeWidth="2" />
                <rect x="2.5" y="2.5" width="15" height="15" stroke="#38539A" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.4786 5.53607C16.7349 5.80039 16.7284 6.22245 16.4641 6.47876L8.21408 14.4788C7.95549 14.7295 7.54447 14.7295 7.28588 14.4788L3.53588 10.8424C3.27156 10.5861 3.26507 10.164 3.52138 9.8997C3.77769 9.63538 4.19975 9.62889 4.46408 9.8852L7.74998 13.0715L15.5359 5.52156C15.8002 5.26525 16.2223 5.27174 16.4786 5.53607Z" fill="#FAFAFA" />
              </svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="18" height="18" rx="3" stroke="#2C2C2C" strokeWidth="2" />
              </svg>
              }
              containerClassname={'autocontinue-checkbox'}
              onClick={() => setRegist((regist) => !regist)}
            >
              เริ่มรอบการสั่งซื้อชุด SOP นี้ใหม่อัตโนมัติ
            </Checkbox>
          </div>
          {showScrollBottom && <div className='sop-scroll-bottom'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3001 9.7078L16.8901 8.2998L12.3001 12.8738L7.71011 8.2998L6.30011 9.7078L12.3001 15.6998L18.3001 9.7078Z" fill="white" />
            </svg>
            <span onClick={()=> scrollToBottom()}>
              ดูโปรแกรม</span>
          </div>}
        </div>
        <div className='sop-sub-footer sop-price-button-align'>
          <div className="font-small">
            <div className="sop-pdp-price price-style">
              ฿ {getPriceFormat(price)}
            </div>
            {isCartABOType(userType) && <div className="sop-pdp-pvbv">
              PV&thinsp;
              {amwayValue.pointValue ? (
                <>{amwayValue.pointValue.toLocaleString()}</>
              ) : (
                <>N/A</>
              )}{' '}
              / BV&thinsp;
              {amwayValue.businessVolume ? (
                <>{amwayValue.businessVolume.toLocaleString()}</>
              ) : (
                <>N/A</>
              )}
            </div>}
          </div>
          <button
            disabled={!codePlan}
            className="sop-btn-purchase floating-right"
            onClick={() => onRegist()}
          >
            สมัคร
          </button>
        </div>

      </div>
    </section>
  );
};
export default PDPItem;
