import React, { useState, useEffect } from 'react';
import { getPriceFormat } from '../../../../services/promotion/utils.service';
import { QuantityStepper } from '../../../../helpers';

export const ProfileListItem = props => {
  const { profile, image, isSolutionPlan, 
          getProfileSelected, cart, accountType, 
          subscriptionInCartEvent, sopDetailsEvent } = props;
  const [inCart, setIncart] = useState(false);

  useEffect(() => {
    if (profile && cart && cart.entries && cart.entries.length) {
      const subscriptionID = profile.id;
      cart.entries.forEach(entry => {
        if (entry.hasSopEntries && entry.qualifiedSubscriptions && entry.qualifiedSubscriptions.length) {
          const filteredQualification = entry.qualifiedSubscriptions.find(subs => subs.subscriptionIdentifier === subscriptionID);
          if (filteredQualification) {
            setIncart(true);
            subscriptionInCartEvent(profile.id);
          }
        }
      })
    }
  }, [JSON.stringify(cart), JSON.stringify(profile)]);

  const renderSolutionImage = () => {
    const productImages = profile.recommendedProduct && profile.recommendedProduct.length > 0 &&
      profile.recommendedProduct.map(product => product &&
        product.galleryImages &&
        product.galleryImages.length > 0 ? product.galleryImages[0].image_url : ''
      )
    const solutionType = profile.selectedSopPlan && profile.selectedSopPlan.qualificationRules &&
      profile.selectedSopPlan.qualificationRules.length ? profile.selectedSopPlan.qualificationRules[0].qualificationRuleType : '';
    const isBasic = solutionType.indexOf('BASIC') >= 0 ? true : false;
    if (productImages && productImages.length > 1) {
      return (
        <section>
          <span className={'solution-tag ' + (isBasic ? 'basic-tag' : 'beyond-tag')}>{isBasic ? 'SOP เบสิค' : 'SOP บียอนด์'}</span>
          <div className='row no-margin-row solution-image-container'>
            <div className='col-6 no-padding-col image-box'><img
              src={productImages[0]}
              alt="SOP product image"
            /></div>
            <div className='col-6 no-padding-col image-box'>
              {productImages[1] ? <img
                src={productImages[1]}
                alt="SOP product image"
              /> : <div className='empty-contianer'></div>}
            </div>
            <div className='col-6 no-padding-col image-box'>{productImages[2] ? <img
              src={productImages[2]}
              alt="SOP product image"
            /> : <div className='empty-contianer'></div>}</div>
            <div className='col-6 no-padding-col image-box'>{productImages[3] ? <img
              src={productImages[3]}
              alt="SOP product image"
            /> : <div className='empty-contianer'></div>}
              {productImages.length > 4 ? <div className='extra-count-container'>+{productImages.length - 4}</div> : null}</div>
          </div>
        </section>
      )
    } else {
      return (
        <section>
          <span className={'solution-tag ' + (isBasic ? 'basic-tag' : 'beyond-tag')}>{isBasic ? 'SOP เบสิค' : 'SOP บียอนด์'}</span>
          <img
            src={productImages[0]}
            alt="SOP product image"
            style={{ width: '100%', height: '100%' }}
          />
        </section>
      )
    }
  }

  const renderProfileProductPrice = () => {
    let profileProductPrice = 0;
    let productPV = 0;
    let productBV = 0
    if (isSolutionPlan) {
      let children = profile.currentElementView &&
        profile.currentElementView.entries &&
        profile.currentElementView.entries.length &&
        profile.currentElementView.entries[0].children ?
        profile.currentElementView.entries[0].children : [];
      if (children && children.length) {
        children.forEach(child => {
          const filteredProduct = profile.recommendedProduct.find(product => product.id === child.selectedProductCode);
          let aboPrice = 0;
          if (filteredProduct) {
            aboPrice = filteredProduct.prices.find((value) => value.type === 'ABO');
            aboPrice = parseInt(aboPrice.price);
            profileProductPrice += aboPrice * child.quantity;
            if (filteredProduct.amwayValue) {
              productPV += (filteredProduct.amwayValue.pointValue || 0) * child.quantity;
              productBV += (filteredProduct.amwayValue.businessVolume || 0) * child.quantity;
            }
          }
        });
      }
    } else {
      let aboPrice = profile && profile.sopProduct && 
                     profile.sopProduct.prices && 
                     profile.sopProduct.prices.length && 
                     profile.sopProduct.prices.find((value) => value.type === 'ABO');
      profileProductPrice = parseInt(aboPrice?.price);
      if (profile.sopProduct && profile.sopProduct.amwayValue) {
        productPV = profile.sopProduct.amwayValue.pointValue || 0;
        productBV = profile.sopProduct.amwayValue.businessVolume || 0;
      }
    }

    return (
      <div className='col-12 no-padding-col profile-product-price'>
        <span>฿ {getPriceFormat(profileProductPrice)}</span>
        {accountType === 'ABO' && <div className='product-pvbv'>PV {getPriceFormat(productPV)} / BV {getPriceFormat(productBV)}</div>}
      </div>
    )
  }

  const renderElementTemplates = () => {
    const elementTemplates = profile.selectedSopPlan && profile.selectedSopPlan.elementTemplates ?
      profile.selectedSopPlan.elementTemplates : [];
    if (elementTemplates && elementTemplates.length) {
      return (
        <section>
          <div className='row no-margin-row justify-left element-details'>
            รอบการสั่งซื้อ {profile?.currentElementView?.elementSequence} / {profile?.currentElementView?.elementTotal}
          </div>
          <div className='row no-margin-row display-flex justify-left'>
            {elementTemplates.map((item, index) => (
              <div className='profile-stepper display-flex' key={`profile-elements-${index}`}>
                <div className={`stepper-circle ${item.statusView}`}></div>
                {index != elementTemplates.length - 1 ? <div className={`stepper-dash ${item.statusView}`}></div> : null}
              </div>
            ))}
          </div>
        </section>
      )
    }
  }

  const getProfileQuantity = () => {
    const currentElement = profile?.currentElementView;
    let quantity = 0;
    if(currentElement) {
      const entriesQty = currentElement?.entries && currentElement.entries.length > 0 ? currentElement.entries[0].quantity : 0;
      quantity = entriesQty;
    }
    return quantity;
  }

  const profileStatusTag = () => {
    const statusView = profile?.currentElementView?.statusView;
    if (statusView === 'notPurchase' && !inCart) {
      return (<div className='profile-status-tag'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.05332 4.50049C8.10475 4.50049 7.33332 5.27468 7.33332 6.22716V6.83382H10.7733V6.22716C10.7733 5.27468 10.0019 4.50049 9.05332 4.50049ZM6.33332 9.00049V7.83382H5.66666V11.9272C5.66666 13.07 6.6738 14.0538 7.99332 14.0538H8.49332V15.0538H7.99332C6.19284 15.0538 4.66666 13.691 4.66666 11.9272V7.33382C4.66666 7.05768 4.89051 6.83382 5.16666 6.83382H6.33332V6.22716C6.33332 4.7263 7.54856 3.50049 9.05332 3.50049C10.5581 3.50049 11.7733 4.7263 11.7733 6.22716V6.83382H12.9467C13.2228 6.83382 13.4467 7.05768 13.4467 7.33382V9.50049H12.4467V7.83382H11.7733V9.00049C11.7733 9.27663 11.5495 9.50049 11.2733 9.50049C10.9972 9.50049 10.7733 9.27663 10.7733 9.00049V7.83382H7.33332V9.00049C7.33332 9.27663 7.10947 9.50049 6.83332 9.50049C6.55718 9.50049 6.33332 9.27663 6.33332 9.00049ZM13.2133 12.2406C13.2133 11.9644 12.9895 11.7406 12.7133 11.7406C12.4372 11.7406 12.2133 11.9644 12.2133 12.2406V12.9538H11.4467C11.1705 12.9538 10.9467 13.1776 10.9467 13.4538C10.9467 13.7299 11.1705 13.9538 11.4467 13.9538H12.2133V14.7806C12.2133 15.0567 12.4372 15.2806 12.7133 15.2806C12.9895 15.2806 13.2133 15.0567 13.2133 14.7806V13.9538H13.98C14.2561 13.9538 14.48 13.7299 14.48 13.4538C14.48 13.1776 14.2561 12.9538 13.98 12.9538H13.2133V12.2406ZM10.2067 13.4338C10.2067 12.0494 11.3289 10.9271 12.7133 10.9271C14.0977 10.9271 15.22 12.0494 15.22 13.4338C15.22 14.8181 14.0977 15.9404 12.7133 15.9404C11.3289 15.9404 10.2067 14.8181 10.2067 13.4338ZM12.7133 9.92708C10.7766 9.92708 9.20665 11.4971 9.20665 13.4338C9.20665 15.3704 10.7766 16.9404 12.7133 16.9404C14.65 16.9404 16.22 15.3704 16.22 13.4338C16.22 11.4971 14.65 9.92708 12.7133 9.92708Z" fill="#38539A" />
        </svg>
        รอใส่ตะกร้า
      </div>)
    } else if (statusView === 'notPurchase' && inCart) {
      return (<div className='profile-status-tag incart-status'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.50001 6.22716C7.50001 5.27468 8.27144 4.50049 9.22001 4.50049C10.1686 4.50049 10.94 5.27468 10.94 6.22716V6.83382H7.50001V6.22716ZM6.50001 7.83382V9.00049C6.50001 9.27663 6.72387 9.50049 7.00001 9.50049C7.27615 9.50049 7.50001 9.27663 7.50001 9.00049V7.83382H10.94V9.00049C10.94 9.27663 11.1639 9.50049 11.44 9.50049C11.7162 9.50049 11.94 9.27663 11.94 9.00049V7.83382H12.6133V9.00049V9.50049H13.6133V9.00049V7.33382C13.6133 7.05768 13.3895 6.83382 13.1133 6.83382H11.94V6.22716C11.94 4.7263 10.7248 3.50049 9.22001 3.50049C7.71525 3.50049 6.50001 4.7263 6.50001 6.22716V6.83382H5.33334C5.0572 6.83382 4.83334 7.05768 4.83334 7.33382V11.9272C4.83334 13.691 6.35953 15.0538 8.16001 15.0538H8.66001V14.0538H8.16001C6.84049 14.0538 5.83334 13.07 5.83334 11.9272V7.83382H6.50001ZM12.88 10.9271C11.4956 10.9271 10.3733 12.0494 10.3733 13.4338C10.3733 14.8181 11.4956 15.9404 12.88 15.9404C14.2644 15.9404 15.3867 14.8181 15.3867 13.4338C15.3867 12.0494 14.2644 10.9271 12.88 10.9271ZM9.37334 13.4338C9.37334 11.4971 10.9433 9.92708 12.88 9.92708C14.8167 9.92708 16.3867 11.4971 16.3867 13.4338C16.3867 15.3704 14.8167 16.9404 12.88 16.9404C10.9433 16.9404 9.37334 15.3704 9.37334 13.4338ZM14.346 13.0281C14.5453 12.8371 14.5521 12.5206 14.361 12.3212C14.1699 12.1218 13.8534 12.1151 13.6541 12.3062L12.4074 13.5009L12.0869 13.1803C11.8916 12.985 11.5751 12.985 11.3798 13.1803C11.1845 13.3756 11.1845 13.6921 11.3798 13.8874L12.0465 14.5541C12.2388 14.7464 12.5496 14.7497 12.746 14.5615L14.346 13.0281Z" fill="#546223" />
        </svg>
        ใส่ตะกร้าแล้ว
      </div>)
    } else if(statusView === 'payment_pending') {
      return (<div className='profile-status-tag payment-pending-status'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.47825 15.9576C8.26641 16.2976 9.10781 16.4672 10.0011 16.4672C10.8944 16.4672 11.7357 16.2975 12.5235 15.9574C13.3088 15.6184 13.9932 15.1577 14.5755 14.5751C15.1578 13.9926 15.6182 13.3079 15.9571 12.5223C16.297 11.7341 16.4667 10.8927 16.4667 9.99941C16.4667 9.10609 16.297 8.26484 15.9569 7.47701C15.6179 6.69176 15.1571 6.00737 14.5746 5.42507C13.992 4.84278 13.3074 4.38228 12.5218 4.04344C11.7336 3.70349 10.8922 3.53387 9.99889 3.53387C9.10557 3.53387 8.26432 3.70357 7.47649 4.04368C6.69124 4.38267 6.00686 4.84339 5.42455 5.42595C4.84226 6.0085 4.38176 6.69318 4.04293 7.47877C3.70298 8.26692 3.53336 9.10833 3.53336 10.0016C3.53336 10.895 3.70305 11.7362 4.04316 12.524C4.38215 13.3093 4.84287 13.9937 5.42543 14.576C6.00798 15.1583 6.69266 15.6188 7.47825 15.9576ZM13.6807 13.6812C12.667 14.695 11.4427 15.2005 10 15.2005C8.55736 15.2005 7.33301 14.695 6.31929 13.6812C5.30557 12.6675 4.80001 11.4432 4.80001 10.0005C4.80001 8.55788 5.30557 7.33353 6.31929 6.31981C7.33301 5.30609 8.55736 4.80052 10 4.80052C11.4427 4.80052 12.667 5.30609 13.6807 6.31981C14.6944 7.33353 15.2 8.55788 15.2 10.0005C15.2 11.4432 14.6944 12.6675 13.6807 13.6812ZM10.6667 7.33384C10.6667 6.96565 10.3682 6.66718 10 6.66718C9.63182 6.66718 9.33334 6.96565 9.33334 7.33384V10.0005C9.33334 10.1773 9.40358 10.3469 9.5286 10.4719L11.8619 12.8052C12.1223 13.0656 12.5444 13.0656 12.8047 12.8052C13.0651 12.5449 13.0651 12.1228 12.8047 11.8624L10.6667 9.72437V7.33384Z" fill="#A65523" />
        </svg>
        รอชำระเงิน
      </div>)
    } else if(statusView === 'purchased' || statusView === 'compensation') {
      return (<div className='profile-status-tag purchased-status display-flex'>
        <div>
          <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7849_68611)">
              <path d="M10 0C7.34784 0 4.80429 1.05356 2.92893 2.92892C1.05357 4.80429 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.80429 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.80429 17.0711 2.92892C15.1957 1.05356 12.6522 0 10 0ZM10 18.3333C7.78986 18.3333 5.67025 17.4554 4.10744 15.8925C2.54464 14.3297 1.66667 12.2101 1.66667 10C1.66667 7.78986 2.54464 5.67024 4.10744 4.10744C5.67025 2.54463 7.78986 1.66667 10 1.66667C12.2101 1.66667 14.3298 2.54463 15.8926 4.10744C17.4554 5.67024 18.3333 7.78986 18.3333 10C18.3333 12.2101 17.4554 14.3297 15.8926 15.8925C14.3298 17.4554 12.2101 18.3333 10 18.3333Z" fill="#73793B" />
              <path fillRule="evenodd" clipRule="evenodd" d="M12.8181 6.96688C13.1756 6.60944 13.7544 6.54468 14.1314 6.91793C14.3203 7.08184 14.41 7.32149 14.4164 7.55335C14.4229 7.78494 14.3466 8.02472 14.1825 8.19024L9.44321 13.3853C9.07427 13.8057 8.43976 13.8056 8.07099 13.3849L6.06338 11.1491C5.74889 10.7821 5.74846 10.2065 6.11549 9.83943L6.12254 9.83291C6.48947 9.51842 7.06511 9.51798 7.43218 9.88501L7.43737 9.89053L8.759 11.3827L12.8181 6.96688Z" fill="#73793B" />
            </g>
          </svg>
        </div>
        ซื้อแล้ว
      </div>)
    } else if(statusView === 'postpone') {
      return (<div className='profile-status-tag postpone-status'>
        <div>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.92893 2.92892C4.80429 1.05356 7.34784 0 10 0C12.6522 0 15.1957 1.05356 17.0711 2.92892C18.9464 4.80429 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.80429 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.80429 2.92893 2.92892ZM4.10744 15.8925C5.67025 17.4554 7.78986 18.3333 10 18.3333C12.2101 18.3333 14.3298 17.4554 15.8926 15.8925C17.4554 14.3297 18.3333 12.2101 18.3333 10C18.3333 7.78986 17.4554 5.67024 15.8926 4.10744C14.3298 2.54463 12.2101 1.66667 10 1.66667C7.78986 1.66667 5.67025 2.54463 4.10744 4.10744C2.54464 5.67024 1.66667 7.78986 1.66667 10C1.66667 12.2101 2.54464 14.3297 4.10744 15.8925ZM11.7292 4.85028L16.5867 8.32677C16.6776 8.39196 16.7318 8.49716 16.7318 8.60954C16.7318 8.72192 16.6776 8.82716 16.5868 8.89235L11.7292 12.3688C11.6222 12.4445 11.4846 12.4551 11.3687 12.395C11.2528 12.3356 11.1803 12.2164 11.1803 12.086V10.3576C8.6174 10.5057 6.3804 12.2806 5.66884 14.7752L5.61529 14.9629C5.56677 15.1342 5.39872 15.2356 5.23376 15.2115C5.06228 15.1874 4.9349 15.0407 4.9349 14.8672V13.4766C4.9349 9.95126 7.70587 7.0618 11.1803 6.88048V5.13309C11.1803 5.00274 11.2528 4.88356 11.3687 4.82415C11.4845 4.76403 11.6221 4.77388 11.7292 4.85028Z" fill="#396E75" />
          </svg>
        </div>
        ผ่อนผัน
      </div>)
    }
  }

  return (
    <div className='row no-margin-row'>
      <div className='col-12 no-padding-col'>
        <div className='row no-margin-row justify-left'>
          <div className='col-4 no-padding-col'>
            {isSolutionPlan ? renderSolutionImage() :
              <div className='sop-flex-image'>
                <span>SOP Flex</span>
                <img
                  src={image}
                  alt="SOP product image"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            }
          </div>
          <div className='col-8 no-padding-col sku-status'>
            <div className='row no-margin-row justify-space-between'>
              <div className='col-6 no-padding-col'>#{profile?.sopProduct?.id}</div>
              {profileStatusTag()}
            </div>
            <div className='col-12 no-padding-col profile-product-name'>{profile?.sopProduct?.identifier}</div>
            {renderProfileProductPrice()}
            <div className='col-12 no-padding-col details-anchor' onClick={() => sopDetailsEvent()}>
              ดูรายละเอียด<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.672 4L5.73334 4.94L8.78267 8L5.73334 11.06L6.672 12L10.6667 8L6.672 4Z" fill="#2C2C2C" />
              </svg>
            </div>
          </div>
        </div>
        <div className='row no-margin-row justify-left'>
          <div className='col-7 no-padding-col'>
            {renderElementTemplates()}
          </div>
          <div className='col-1 no-padding-col'></div>
          {profile?.currentElementView?.statusView === 'notPurchase' && !inCart ? <div className='col-4 no-padding-col'>
            {profile.isSelected ? <div>
              <QuantityStepper quantity={getProfileQuantity()}
                decreaseEvent={() => getProfileSelected(profile?.id, 'decrease')}
                increaseDisabled={true} />
            </div> :
              <button onClick={() => getProfileSelected(profile?.id, 'increase')} className="profile-btn-purchase floating-right">
                เลือก
              </button>}
          </div> : null}
        </div>
      </div>
    </div>
  )
}

