import config from '../config';
import axios from 'axios';

export const customerService = {
  get,
  findByID,
  findMemberNumber,
  findMemberbyABONumber,
  findCheckIdCard,
  create,
  update,
  deleteCustomer,
};

async function get(uid) {
  const customer = await axios.get(`${config.API_URL}/customers`);
  return customer.data;
}

async function findByID(user) {
  const customer = await axios.get(`${config.API_URL}/customers`, user);
  return customer.data;
}

async function findMemberNumber(amwayNumber) {
  const customer = await axios.get(
    `${config.API_URL}/customers/${amwayNumber}`,
  );

  return customer.data;
}

async function findMemberbyABONumber(amwayNumber) {
  try {
    const customer = await axios.get(
      `${config.API_URL}/customers/find/${amwayNumber}`,
    );
    return customer.data;
  } catch (err) {
    return;
  }
}

async function findCheckIdCard(amwayNumber, idCard) {
  try {
    const customer = await axios.get(
      `${config.API_URL}/customers/${amwayNumber}/${idCard}`,
    );
    return customer.data;
  } catch (err) {
    return;
  }
}

async function create(profile) {
  const customer = await axios.post(`${config.API_URL}/customers`, profile);
  return customer.data;
}

async function update(id, data) {
  console.log('customer update function', data);
  const customer = await axios
    .post(`${config.API_URL}/customers/update`, data)
    .catch((err) => {
      if (err.response) {
        return err.response.data;
      } else {
        return err.message;
      }
    });

  return customer.data || customer;
}

async function deleteCustomer(_id) {
  const customer = await axios.delete(`${config.API_URL}/customers/${_id}`);
  return customer;
}
