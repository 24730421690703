export class AssessmentProductsList {
  constructor(data) {
    if (data) {
      this.current_page = data.currentPage || 1;
      this.products =
        data?.data?.map((value) => new AssessmentProduct(value)) || [];
      this.per_page = data.perPage || 1;
      this.success = data.success || true;
      this.total = data.total || 1;
      this.total_pages = data.totalPages || 1;
    }
  }
}

export class AssessmentProduct {
  constructor(data) {
    this.id = data.id || '';
    this.amwayValue = new AmwayValue(data.amwayValue) || {};
    this.type = data.type || 'basic';
    this.bannerUrl =
      (Array.isArray(data.galleryImages) && data.galleryImages[0]?.image_url) ||
      '';
    this.icon = data.icon || '/images/assesment/person.svg';
    this.iconLabel = data.iconLabel || 'พื้นฐาน';
    this.title =
      data.identifier || 'นิวทริไลท์เครื่องหมายการค้า โคคิวเท็น พลัส';
    this.prices = data.prices.map((price) => new Price(price));
    this.kitEntries =
      data?.kitEntries?.map((value) => new AssessmentProductKitEntry(value)) ||
      [];
    this.quantity = data.quantity || 0;
    this.isSelected = false;
  }
}

export class AssessmentProductKitEntry {
  constructor(data) {
    this.configuredQty = data.configuredQty || 0;
    this.kitEntryCode = data.kitEntryCode || '';
    this.product = new AssessmentProductKitEntryProduct(data?.product) || {};
  }
}

export class AssessmentProductKitEntryProduct {
  constructor(data) {
    this.baseProduct = data.baseProduct ? true : false;
    this.baseOptions =
      data?.baseOptions?.map((value) => new AssessmentProductBaseOption(value)) ||
      [];
  }
}

export class AssessmentProductBaseOption {
  constructor(data) {
    this.options =
      data?.options?.map((value) => new AssessmentProductOption(value)) || [];
    this.selectedId = data.selectedId || '';
  }
}

export class AssessmentProductOption {
  constructor(data) {
    this.id = data.id || '';
    this.quantity = data.quantity || 0;
    this.lastQuantity = data.lastQuantity || 0;
  }
}

export class AmwayValue {
  constructor(data) {
    this.businessVolume = data.businessVolume || 0;
    this.pointValue = data.pointValue || 0;
    this.returnableBV = data.returnableBV || 0;
    this.returnablePV = data.returnablePV || 0;
  }
}

export class Price {
  constructor(data) {
    this.currency = new Currency(data.currency);
    this.price = data.price || 0;
    this.type = data.type || 'RETAIL';
  }
}

export class Currency {
  constructor(data) {
    this.ISOCode = data.ISOCode || 'THB';
    this.symbol = data.symbol || '฿';
  }
}
