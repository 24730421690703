import React from 'react';
import './ChipAssessment.scss';

const ChipAssessment = ({ text, isSelected, onClick }) => {
  return (
    <div className={`chip ${isSelected ? 'green-chip' : ''}`} onClick={onClick}>
      {text}
    </div>
  );
};

export default ChipAssessment;
