
import React from 'react';
import './AssessmentDescription.scss'
import { DEFAULT_ASSESSMENT_DESCRIPTION } from '../../../../../common/commonConstant';
import { useSelector } from 'react-redux';

export const AssessmentDescription = ({ setIsOpenLiffDes, assessmentList }) => {
    return (<div className='asm-des-ctn'>
        <div className='asm-des-ctn-w1'>
            <div className='asm-des-ctn-w1-hdr'>
                <div className='asm-des-ctn-w1-hdr-h1' dangerouslySetInnerHTML={{ __html: assessmentList?.title }}></div>
            </div>
            <div className='asm-des-ctn-w1-cnt'>
                <div className='asm-des-ctn-w1-cnt-c1' dangerouslySetInnerHTML={{ __html: assessmentList?.description }}></div>

            </div>
        </div>
        <div className='asm-des-ctn-w2'>
            <div className='asm-des-ctn-w2-btn'>
                <button className='btnClass' onClick={() => setIsOpenLiffDes(false)}>{DEFAULT_ASSESSMENT_DESCRIPTION.btn}</button>
            </div>
        </div>
    </div>)
}

export default AssessmentDescription;
