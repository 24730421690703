import React from 'react';
import LinearRowContent from './LinearRowContent';

export default function HnwWeightGoalCardBody({ type, content, healthGoal }) {
  return (
    <div className="card-body-section-weight">
      <LinearRowContent
        type={type}
        content={content}
        healthGoalValue={healthGoal?.targetWeight}
        options={healthGoal}
      />
    </div>
  );
}
