import React from 'react';
import { getPhotoName } from '../../challengeConstants';

export default function ImageCard({
  type,
  photo,
  photos,
  handleImageUpload,
  allowImageUpload,
  isChallengePeriod,
  isUserValid,
  loadGreenOrGreyIdleImages,
}) {
  return (
    <span
      key={getPhotoName(type, photo)}
      className="before-after-image-single before-after-image-uploaded pointer"
      onClick={() =>
        allowImageUpload(isUserValid, isChallengePeriod) &&
        handleImageUpload(getPhotoName(type, photo))
      }
    >
      <img
        src={
          photos[getPhotoName(type, photo)]
            ? photos[getPhotoName(type, photo)]
            : `/images/challenge/myupload/photo_${loadGreenOrGreyIdleImages(
                isChallengePeriod,
                photo,
              )}.svg`
        }
        className="before-after-img-uploaded"
        alt={getPhotoName(type, photo)}
      />
    </span>
  );
}
