import React from 'react';
import { getPhotoName } from '../../challengeConstants';

export default function MyPictureCardDetail({
  photos,
  photoPositionObj,
  beforeOrAfter,
  datetimeThai,
  handleImageUpload,
}) {
  return (
    <div className="picture-col-details">
      <img
        className="picture-col-img"
        src={photos[getPhotoName(beforeOrAfter.type, beforeOrAfter.position)]}
        alt={getPhotoName(beforeOrAfter.type, beforeOrAfter.position)}
      />
      <div className="picture-col-description">
        <div className="picture-col-left">
          <div className="picture-col-title">{photoPositionObj.thaiLabel}</div>
          {datetimeThai?.[
            getPhotoName(beforeOrAfter.type, beforeOrAfter.position)
          ] ? (
            <div className="picture-col-date">
              {
                datetimeThai[
                  getPhotoName(beforeOrAfter.type, beforeOrAfter.position)
                ]
              }
            </div>
          ) : null}
        </div>
        <div className="picture-col-right">
          <button
            className="btn-white-linear pull-right"
            onClick={() =>
              handleImageUpload(
                getPhotoName(beforeOrAfter.type, beforeOrAfter.position),
              )
            }
          >
            <img
              src="/images/challenge/myupload/edit_icon.svg"
              alt="edit_icon"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
