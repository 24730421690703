import AxiosHealthWellness from '.';
import config from '../../config';
import { ExerciseLog } from '../../model';
import { FoodLogParent, WaterLogParent } from '../../model/HealthLog.model';

export const getFoodCalendarData = async (params) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-log/health-data`,
      { params },
    );
    return response?.data?.data.map((logItem) => {
      return logItem.hasOwnProperty('myFoodLog')
        ? new FoodLogParent(logItem)
        : new WaterLogParent(logItem);
    });
  } catch (error) {
    console.log(error);
  }
};

export const getExerciseCalendarData = async (params) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-log/excercise-data`,
      { params },
    );
    return response?.data?.data.map((logItem) => new ExerciseLog(logItem));
  } catch (error) {
    console.log(error);
  }
};
