export class CustomerPolicy {
    constructor(data) {
        if (data) {
            this.id = data._id || '';
            this.customers = data.customers || '';
            this.privacies = data.privacies || '';
            this.status = data.status === 'true' || data.status === true ? true : false;
            this.user_id = data.user_id || '';
            this.createdDate = data.createdDate || '';
            this.updatedDate = data.updatedDate || '';
        }
    }
}