import { round } from "../services";
import { Customer } from "./Customer.model";

export class Profile {
    constructor(data) {
        if (data) {
            this.bmi = data.bmi && !isNaN(data.bmi) ? round(data.bmi, 1) : '';
            this.weight = data.weight && !isNaN(data.weight) ? round(data.weight) : '';
            this.height = data.height && !isNaN(data.height) ? round(data.height) : '';
            this.muscle = data.muscle && !isNaN(data.muscle) ? round(data.muscle) : '';
            this.fat = data.fat && !isNaN(data.fat) ? round(data.fat) : '';
            this.waist = data.waist && !isNaN(data.waist) ? round(data.waist) : '';
            this.day = data.day || '';
            this.year = data.year || '';
            this.month = data.month || '';
            this.createdDate = data.createdDate || '';
            this.updatedDate = data.updatedDate || '';
            this.heightUpdatedDate = data.heightUpdatedDate || '';
            this.weightUpdatedDate = data.weightUpdatedDate || '';
            this.waistUpdatedDate = data.waistUpdatedDate || '';
            this.muscleUpdatedDate = data.muscleUpdatedDate || '';
            this.fatUpdatedDate = data.fatUpdatedDate || '';
            this.customer = data.customers ? new Customer(data.customers) : null;
        }
    }
}