import React from "react";
import { BadgeRectCard } from "../../../../common";
import { CHALLENGE } from "../../../../common/challengeConstants";
import { filterActiveInactiveImages } from "../../../../utill.func";
import './ChallengeMyBadgesCards.scss';

const ChallengeMyBadgesCards = ({ redirectToBadgeScreen, redirectToBadgeDetail, badgesData, otherBadgesData }) => {

    const getBadgesValue = (key) => {
        const key_map = (key == 'quiz') ? 'score': 'value';
        return badgesData[key]?.data?.[key_map];
    }

    const getBadgesReceived = (key) => {
        return otherBadgesData[key]?.badgeReceived;
    }

    const renderBadgeCardBody = (title, badgeImage, values, maxBadgeValue, unit, redirectToBadgeDetail) => {
        return (
            <div className="challenge-card-body w-50" onClick={() => redirectToBadgeScreen()}>
                <BadgeRectCard cards
                    title={title}
                    badgeImage={badgeImage}
                    values={values}
                    maxBadgeValue={maxBadgeValue}
                    unit={unit}
                    redirectToBadgeDetail={redirectToBadgeDetail}
                />
            </div>
        )
    }

    return (
        <div className="my-badges-cards-container">
            <div className="align-half-cards">
                {renderBadgeCardBody(
                    CHALLENGE.myDashboard.bestDiscipliner,
                    filterActiveInactiveImages(getBadgesReceived('food'), 'food'),
                    getBadgesValue('food'),
                    CHALLENGE.myDashboard.maxRewardBadges,
                    CHALLENGE.myDashboard.days,
                    (event) => redirectToBadgeDetail('food', 'others', event.stopPropagation())
                )}
                {renderBadgeCardBody(
                    CHALLENGE.myDashboard.goalKeeper,
                    filterActiveInactiveImages(getBadgesReceived('calorie'), 'calorie'),
                    getBadgesValue('calorie'),
                    CHALLENGE.myDashboard.maxRewardBadges,
                    CHALLENGE.myDashboard.days,
                    (event) => redirectToBadgeDetail('calorie', 'others', event.stopPropagation())
                )}
            </div>
            <div className="align-half-cards">
                {renderBadgeCardBody(
                    CHALLENGE.myDashboard.bestLearner,
                    filterActiveInactiveImages(getBadgesReceived('vdo'), 'vdo'),
                    getBadgesValue('vdo'),
                    CHALLENGE.myDashboard.maxRewardBadges,
                    CHALLENGE.myDashboard.videos,
                    (event) => redirectToBadgeDetail('vdo', 'others', event.stopPropagation())
                )}
                {renderBadgeCardBody(
                    CHALLENGE.myDashboard.topLearner,
                    filterActiveInactiveImages(getBadgesReceived('quiz'), 'quiz'),
                    getBadgesValue('quiz'),
                    '',
                    CHALLENGE.myDashboard.points,
                    (event) => redirectToBadgeDetail('quiz', 'others', event.stopPropagation())
                )}
            </div>
        </div>
    )

}

export default ChallengeMyBadgesCards;