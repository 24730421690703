import React from 'react';
import './RectBadge.scss'

const RectBadge = (props) => {
    const { bgColor, textColor, badgeClassname, children } = props;
    let buttonStyle = {};

    if (!badgeClassname) {
        buttonStyle = {
            backgroundColor: bgColor,
            color: textColor,
        };
    }

    return (
        <div className={`rect-badge-container ${badgeClassname?.classname}`} style={buttonStyle}>
            {children}
        </div>
    )
}

export default RectBadge