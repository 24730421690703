import React, { useEffect, useState } from 'react';

import '../promotion/CartPromotion.scss';

export const LIFF_OPEN = 'open';

const Liff = (props) => {
  const [isLiffOpen, setIsLiffOpen] = useState('');

  useEffect(() => {
    const isLiffOpen = props.isOpen ? LIFF_OPEN : '';
    setIsLiffOpen(isLiffOpen);
  }, [props.isOpen]);

  const onClose = () => {
    props.onClose();
  };

  const onClickOutside = (event) => {
    props.onClickOutside && event.currentTarget === event.target && props.onClose();
  }

  return (
    <section className={`liff-panel ${isLiffOpen}`} onClick={onClickOutside}>
      <div className="select-coupon-panel">
        <div className="header font-bold font-stock">
          <div className={props.headerClass ? props.headerClass : ''}>{props.title}</div>
          {!props.hideCloseBtn && <button className="btn-close" onClick={onClose}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
              <path d="M18 18L6 6" stroke="#2C2C2C" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
            </svg>
          </button>}
        </div>
        <div className="font-stock">{props.children}</div>
      </div>
    </section>
  );
};

export default Liff;
