import { LeftOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { commonConstant } from '../../../common';
import config from '../../../config';
import { getAmwayCategories } from '../../../services/library/categories/categories.service';
import { countAmwayLibraryBook, libraryImageUrl } from '../../../utill.func';
import { EContentType } from '../common.library';
import './category.scss';

const { Option } = Select;

const AdminCategoryList = () => {
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState('');
  const [coverTabInfo, setCoverTabInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const itemIndex = location?.state?.index;

  const routeChange = (path, id) => {
    history.push({
      pathname: path,
      search: `id=${id}`,
      state: { isAmwayContent: EContentType.AMWAY_LIBRARY },
    });
  };

  const goToSubCategoryList = (id) => {
    const path = commonConstant.pathLibrarySubContentList;
    routeChange(path, id);
  };

  const handleChange = (index) => {
    let defaultIndex = 0;
    if (index) {
      defaultIndex = index;
    }
    setLoading(true);
    setSubCategories(categories[defaultIndex].subCategoryDetail);
    setCoverTabInfo(categories[defaultIndex]);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let defaultIndex = 0;
    if (itemIndex && categories) {
      defaultIndex = itemIndex;
      handleChange(defaultIndex);
    }
    if (categories) {
      handleChange(defaultIndex);
    }
  }, [categories]);

  const fetchData = async () => {
    setLoading(true);
    const categoryData = await getAmwayCategories();
    setCategories(categoryData);

    setLoading(false);
  };
  return (
    <Spin tip="Loading..." spinning={loading}>
      {categories && (
        <div className="library-category">
          <div className="hl-header">
            <div className="sub-header">
              <div className="header-bg">
                <img src={libraryImageUrl(coverTabInfo.imageFlex)} />
              </div>
              <div
                className="back-page"
                onClick={() => {
                  history.go(-1);
                }}
              >
                <LeftOutlined />
              </div>
              <div className="page-title-category">Categories</div>
              <div className="category-name">
                <h1 className="heading">{coverTabInfo.name}</h1>
                <div className="desc">
                  {`${countAmwayLibraryBook(coverTabInfo.contentList)} Books`}
                </div>
              </div>
            </div>
          </div>

          <div className="library-category-list">
            <div className="select-category">
              <label>เลือกหัวข้อ Category ที่ต้องการ</label>
              <div>
                <Select
                  defaultValue={categories[itemIndex].name}
                  style={{ height: 60 }}
                  onChange={(e) => handleChange(e)}
                >
                  {categories.map((options, index) => (
                    <Option key={(options, ':', index)}>{options.name}</Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="sub-category-lists">
              {subCategories &&
                subCategories.map((item, index) => (
                  <div
                    key={(item, ':', index)}
                    className="sub-category-item"
                    onClick={() => {
                      goToSubCategoryList(item._id);
                    }}
                  >
                    <div className="img">
                      <img
                        src={
                          subCategories && `${config.S3_URL}${item.imageFlex}`
                        }
                      />
                    </div>
                    <div className="title">{item.name}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </Spin>
  );
};
export default AdminCategoryList;
