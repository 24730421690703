import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  GOAL_SETTING_DYNAMIC_POSITION_VALUE_45,
  goalRoutesMap,
  HEALTH_GOAL_TYPES_EXERCISE,
  GOAL_SETTING_DYNAMIC_POSITION_VALUE_12,
  GOAL_DELETE_MODAL_CANCEL,
  GOAL_DELETE_MODAL_DELETE,
  GOAL_DELETE_MODAL_HEADER,
  HEALTH_GOAL_TYPES,
} from '../../../../../common/commonConstant';
import { CalendarLoader, HealthDeleteFoodModal } from '../../../../../common';
import HealthTwoButtonModal from '../../../../../common/healthTwoButtonModal/HealthTwoButtonModal';
import { deleteHealthGoal } from '../../../../../actions/hnwGoalSettingActions';
import { updateWeight } from '../../../../../actions';

export default function HnwGoalCardHeader({
  type,
  content,
  healthGoal,
  showDeleteModal = true,
  routeTo = () => { },
  routeParams = '',
}) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isManageModalOpen, setManageModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleEditClick = () => {
    showDeleteModal ? setManageModalOpen(true) : navigateToEditGoal();
  };

  const navigateToEditGoal = () => {
    let params = `${type}_id=${healthGoal.id}&activeFlow=${HEALTH_GOAL_TYPES.WEIGHT === type ? '1' : '0'
      }`;
    if (routeParams) {
      params += `&${routeParams}`;
    }
    routeTo(goalRoutesMap[type], `?${params}`);
  };

  const handleDeleteConfirmation = (towardsDelete) => {
    setManageModalOpen(!towardsDelete);
    setIsDeleteModalOpen(towardsDelete);
  };

  const handleDeleteGoal = () => {
    setLoading(true);
    dispatch(deleteHealthGoal(type))
      .then(() => dispatch(updateWeight()))
      .finally(() => {
        setIsDeleteModalOpen(false);
        setLoading(false);
      });
  };

  return (
    <div
      className={`goal-setup-wrapper${isDeleteModalOpen || isManageModalOpen ? ' --no_scroll' : ''
        }`}
    >
      {showDeleteModal && (
        <HealthTwoButtonModal
          isOpen={isManageModalOpen}
          type={type}
          onClickBack={() => setManageModalOpen(false)}
          onLeftBtnClick={() => handleDeleteConfirmation(true)}
          onRightBtnClick={navigateToEditGoal}
        />
      )}
      <div
        className={`goal-setup-header goal-setup-${type}-header d-flex flex-row justify-content-between align-items-center`}
      >
        {isDeleteModalOpen && (
          <HealthDeleteFoodModal
            isDeleteModalOpen={isDeleteModalOpen}
            onConfirm={handleDeleteGoal}
            onClose={() => handleDeleteConfirmation(false)}
            cancelBtnColor="blue"
            cancelBtnMsg={GOAL_DELETE_MODAL_CANCEL}
            deleteBtnMsg={GOAL_DELETE_MODAL_DELETE}
            deleteHeaderMsg={GOAL_DELETE_MODAL_HEADER}
          />
        )}
        <div className="goal-setup-header-left">
          <img src={content.iconPath} />
          <span className="goal-setup-title">{content.label}</span>
        </div>
        <img
          src={content.bgIconPath}
          className={`goal-settings-right-icon position-right-${healthGoal
            ? GOAL_SETTING_DYNAMIC_POSITION_VALUE_45
            : GOAL_SETTING_DYNAMIC_POSITION_VALUE_12
            }`}
        />
        {type !== HEALTH_GOAL_TYPES_EXERCISE && healthGoal ? (
          <div className="goal-setup-header-right" onClick={handleEditClick}>
            <img src="/images/healthAndWellness/goal-setting/icons/edit.svg" />
          </div>
        ) : null}
      </div>
      <CalendarLoader showLoading={loading} />
    </div>
  );
}
