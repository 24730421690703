import React from 'react'
import { commonConstant } from '../../common';
import { useHistory } from 'react-router-dom';

const Navigation = WrappedComponent => {

    return function WithNavigationComponent(props) {
        const history = useHistory();
        const routeTo = (pathKey, search = '') => {
            if (pathKey && commonConstant[pathKey]) {
                history.push({ pathname: commonConstant[pathKey], search });
            }
        }

        const routeReplaceTo = (pathKey, search = '') => {
            if (pathKey && commonConstant[pathKey]) {
                history.replace({ pathname: commonConstant[pathKey], search });
            }
        }

        return (
            <WrappedComponent routeTo={routeTo} routeReplaceTo={routeReplaceTo} {...props} />
        )
    }
}

export default Navigation