import React from "react";

const CircleRoundHalfTriple = () => {

    return (
        <div className="circle-round-half-2">
            <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* First circle */}
                <circle cx="95" cy="95" r="89" stroke="white" strokeWidth="12" strokeLinecap="round"></circle>
                {/* Second circle with gradient stroke */}
                <circle cx="95" cy="95" r="89" stroke="url(#paint0_linear_7142_422435)" strokeWidth="12" strokeLinecap="round"></circle>
                {/* Remaining path */}
                <path d="M95 6C111.862 6 128.376 10.7899 142.621 19.8121C156.866 28.8342 168.255 41.7174 175.462 56.9613C182.668 72.2053 185.396 89.1829 183.328 105.917C181.26 122.651 174.48 138.454 163.779 151.484" stroke="url(#paint1_linear_7142_422435)" strokeWidth="12" strokeLinecap="round" />
                {/* Linear gradient definitions */}
                <defs>
                    <linearGradient id="paint0_linear_7142_422435" x1="68" y1="12.3913" x2="109" y2="12.3913" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9FDC7D" />
                        <stop offset="1" stopColor="#6CC949" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_7142_422435" x1="95" y1="6.5" x2="141" y2="172.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6CC949" />
                        <stop offset="1" stopColor="#9FDC7D" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default CircleRoundHalfTriple;