import AxiosHealthWellness from '.';
import config from '../../config';
import { WeightGoal, WaterGoal, FoodGoal, SupplementGoal, ActivityExerciseCategoryGoal, ActivityGoal, ExerciseMenu } from '../../model/GoalSetting.model';
import { round } from '../utils.service';

export const getDefaultValues = (bmi, customerAge, customerGender) => {

  let defaultCalories;
  if (!(bmi.height && bmi.weight && customerAge && customerGender)) {
    defaultCalories = 0;
  }
  else {
    defaultCalories = ({
      male: (66 + (13.7 * bmi.weight) + (5 * bmi.height) - (6.8 * customerAge)) * 1.2,
      female: (665 + (9.6 * bmi.weight) + (1.8 * bmi.height) - (4.7 * customerAge)) * 1.2
    })[customerGender];
  }

  const MAPPING_FACTOR = {
    male: {
      protein: 0.2,
      carbs: 0.5,
      fat: 0.3,
      exercise: 0.15,
    },
    female: {
      protein: 0.2,
      carbs: 0.5,
      fat: 0.3,
      exercise: 0.15,
    },
    default: {
      protein: 0,
      carbs: 0,
      fat: 0,
      exercise: 0,
    }
  }[customerGender ?? 'default'];
  return {
    defaultCalories: (bmi.weight && bmi.height) ? round(defaultCalories) : 0,
    defaultProtein: (bmi.weight && bmi.height) ? round((MAPPING_FACTOR.protein / 4) * defaultCalories) : 0,
    defaultCarbs: (bmi.weight && bmi.height) ? round((MAPPING_FACTOR.carbs / 4) * defaultCalories) : 0,
    defaultFat: (bmi.weight && bmi.height) ? round((MAPPING_FACTOR.fat / 9) * defaultCalories) : 0,
    defaultExercise: (bmi.weight && bmi.height) ? round(MAPPING_FACTOR.exercise * defaultCalories) : 0,
    defaultWater: (bmi.weight && bmi.height) ? round(bmi.weight * 33) : 0,
  };
};

export const getGoalsWeight = async (weightGoal_id) => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/health-goal/weight-goal/${weightGoal_id}`);
    return new WeightGoal(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

export const postGoalsWeight = async ({ data, weightId }) => {
  try {
    const updateCall = weightId ? AxiosHealthWellness.put : AxiosHealthWellness.post;
    const response = await updateCall(`${config.API_URL}/health-goal/weight-goal/${weightId ?? ''}`, data);
    return new WeightGoal(response?.data);
  } catch (error) {
    console.log(error);
  }
};

export const getGoalsWater = async (waterGoal_id) => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/health-goal/water-goal/${waterGoal_id}`);
    return new WaterGoal(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

export const postGoalsWater = async ({ data, waterId }) => {
  try {
    const updateCall = waterId ? AxiosHealthWellness.put : AxiosHealthWellness.post;
    const response = await updateCall(`${config.API_URL}/health-goal/water-goal/${waterId ?? ''}`, data);
    return new WaterGoal(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

export const getGoalsNutrition = async (nutritionId) => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/health-goal/food-goal/${nutritionId}`);
    return new FoodGoal(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

export const postGoalsNutrition = async ({ data, nutritionId }) => {
  try {
    const updateCall = nutritionId ? AxiosHealthWellness.put : AxiosHealthWellness.post;
    const response = await updateCall(`${config.API_URL}/health-goal/food-goal/${nutritionId ?? ''}`, data);
    return new FoodGoal(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

export const getGoalsSupplements = async () => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/health-goal/user-supplement-goal/`);
    return new SupplementGoal(response?.data?.data[0]);
  } catch (error) {
    console.log(error);
  }
};

export const createGoalsSupplements = async ({ data, supplementsGoalId }) => {
  try {
    const response = await AxiosHealthWellness.post(`${config.API_URL}/health-goal/supplement-goal`, data);
    return new SupplementGoal(response?.data);
  } catch (error) {
    console.log(error);
  }
};

export const postGoalsSupplements = async ({ data, supplementsGoalId }) => {
  try {
    const response = await AxiosHealthWellness.put(`${config.API_URL}/health-goal/supplement-goal/${supplementsGoalId ?? ''}`, data);
    return new SupplementGoal(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

export const getActivityGoal = async (customerId) => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/health-goal/user-activity-goal/${customerId}`);
    return new ActivityExerciseCategoryGoal(response?.data?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getMenuByExerciseId = async (exerciseId, params) => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/health-goal/exercise-menu-list/${exerciseId}`, { params }
    );
    if (response?.data?.data?.length) {
      return response?.data?.data.map(em => new ExerciseMenu(em))
    }
    return []
  } catch (error) {
    console.error(error);
  }
};

export const postActivityGoal = async (payload) => {
  try {
    const response = await AxiosHealthWellness.post(`${config.API_URL}/health-goal/activity-goal`, payload);
    return response
  } catch (error) {
    console.error(error);
  }
};

export const putActivityGoal = async (payload) => {
  try {
    const response = await AxiosHealthWellness.put(`${config.API_URL}/health-goal/activity-goal/${payload.activityId}`, payload.activityState);
    return response
  } catch (error) {
    console.error(error);
  }
};
