import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions, customerFetch } from '../../actions';
import LoadingOverlay from 'react-loading-overlay';
import { deepEqual } from '../../helpers';
import { activityLogService } from '../../services/activity-log.service';

const LinkRichmenu = ({ location, params }) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const customer = useSelector((state) => state.customer);

    const [dst, setDst] = useState("");
    const [tag, setTag] = useState("");
    const [show, setShow] = useState(false);
    const [createlog, setCreatelog] = useState(false);

    useEffect(() => {
        let dstParam = params["linkrichmenu"].replace('dst=', '');
        let tagParam = params["tag"];

        if (tagParam === undefined) {
            const splitParams = dstParam.split('&tag=');
            if (splitParams.length === 2) {
                dstParam = splitParams[0];
                tagParam = splitParams[1];
            }
        }

        setDst(dstParam);
        setTag(tagParam);
        setShow(true);
    }, []);

    useEffect(() => {
        if ((Object.keys(profile).length || profile.length) && customer !== null && customer !== undefined) {
            let data = {};
            if (customer.type) {
                data = {
                    activityTag: tag,
                    actionType: 'LINK',
                    userType: customer.type.toUpperCase(),
                    mid: customer.uid,
                    displayName: customer.displayName,
                    amwayNumber: customer.amwayNumber,
                };
            } else {
                data = {
                    activityTag: tag,
                    actionType: 'LINK',
                    userType: "NON_ASC",
                    mid: profile.userId,
                    displayName: profile.displayName,
                };
            }

            activityLogService.createActivityLog(data).then((response) => {
                window.location.assign(dst);
            });
        }
    }, [profile, customer]);

    return (
        <div className="regisPage home">
            <LoadingOverlay
                active={true}
                spinner
                text='Loading'
                styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
            />
        </div>
    );
};

export default LinkRichmenu;
