// App.js
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  CalendarLoader,
  HnWSearchMenu,
  InfiniteScroll,
  SearchBar,
  Slideup,
} from '../../../../../common';
import './HnwSearchHistory.scss';
import {
  emptyProductList,
  fetchCategories,
  fetchHistoryKeywords,
  fetchRecommendedProducts,
  searchProducts,
} from '../../../../../thunk/foodCalories.thunk';
import commonConstant, {
  OTHERS,
  routePathKey,
  SUPPLEMENT_LIST_MAPPING,
} from '../../../../../common/commonConstant';
import SearchCategories from './searchCategories/HnwSearchCategories';
import SearchTitle from './searchTitle/HnwSearchTitle';
import HistoryKeywords from './historyKeywords/HnwHistoryKeywords';
import FoodListingItem from '../../../../../common/foodListingItem/FoodListingItem';
import EmptyListComponent from '../emptyList/EmptyListComponent';
import { NO_SEARCH_LIST_TEXT, SEARCH_TITLES } from '../../../../../constants';
import noResultAnimation from '../../../../../json/no-search-result.json';
import { FoodCategory } from '../../../../../model/FoodCategory.model';
import { getParamsString } from '../../../../../utill.func';

const HnWSearchHistory = ({ routeTo }) => {
  const pageSize = 10;
  const minLength = 1;
  const maxLength = 30;
  const dispatch = useDispatch();
  const myMenuCategory = new FoodCategory({
    _id: 'others',
    name: 'เมนูของฉัน',
    imageUrl:
      '/images/healthAndWellness/foodCalories/food-category/cat_my_menu.svg',
  });
  const defaultCategory = new FoodCategory({
    _id: 'all',
    name: 'ทั้งหมด',
    imageUrl:
      '/images/healthAndWellness/foodCalories/food-category/cat-all.svg',
  });
  const { searchedProducts, categories, historyKeywords, recommendedProducts } =
    useSelector((state) => state.foodCalorie);
  const categoryList = [myMenuCategory, defaultCategory, ...categories].sort(
    (a, b) => a.order - b.order,
  );
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [selectedCategoryID, setSelectedCategoryID] = useState('');
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState(null);
  const [showAllList, setShowAllList] = useState(false);
  const [pageStart, setPageStart] = useState({
    recommendedProducts: 0,
    searchedProducts: 0,
  });
  const searchRef = useRef(null);
  const searchInputRef = useRef(null);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const openSearch = params.get('openSearch');
  const referrerPage = params.get('referrer');
  const suggestedFoodGoal = params.get('suggestedFoodGoal');
  const supplementPageSet = new Set([
    SUPPLEMENT_LIST_MAPPING.GOAL_REFERRER_VALUE,
    SUPPLEMENT_LIST_MAPPING.LOG_REFERRER_VALUE,
  ]);

  const isSupplementOnly = supplementPageSet.has(referrerPage);
  const timeoutHandler = useRef(null);

  useEffect(() => {
    if (openSearch && searchInputRef.current) {
      searchInputRef.current.focus();
      setIsSearchOpen(true);
    }
  }, [openSearch]);

  const getRecommendedProducts = () => {
    setPageStart((start) => ({
      ...start,
      recommendedProducts: start.recommendedProducts + pageSize,
    }));
    const recordsTotal = recommendedProducts.recordsTotal;
    const loadedDataLength = recommendedProducts.data.length;
    if (!loadedDataLength || recordsTotal > loadedDataLength) {
      dispatch(
        fetchRecommendedProducts({
          isRecommended: true,
          limit: pageSize,
          start: pageStart.recommendedProducts,
          ...(isSupplementOnly && { type: 'Supplement' }),
        }),
      ).finally(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleCloseMenu);
    const fetchLoadData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          dispatch(fetchHistoryKeywords()),
          ...(!isSupplementOnly ? [dispatch(fetchCategories())] : []),
          dispatch(
            fetchRecommendedProducts({
              isRecommended: true,
              ...(isSupplementOnly && { type: 'Supplement' }),
            }),
          ),
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchLoadData();
    return () => {
      document.removeEventListener('click', handleCloseMenu);
    };
  }, []);

  const handleCloseMenu = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setIsSearchOpen(false);
    }
  };

  const debounce = (callback, delay) => {
    return function (...args) {
      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
      }
      timeoutHandler.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  // Debounced search function
  const handleSearch = debounce((searchQuery) => {
    setSearchKey(searchQuery);

    dispatch(
      searchProducts({
        limit: 6,
        search: searchQuery,
        ...(isSupplementOnly && { type: 'Supplement' }),
      }),
    ).finally(() => {
      setShowAllList(true);
    });
  }, 1000);

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    if (searchQuery) {
      setIsSearchOpen(true);
      handleSearch(searchQuery);
    } else {
      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
      }
      dispatch(emptyProductList());
    }
  };

  const handleSearchClick = () => {
    if (!searchInput) {
      dispatch(emptyProductList());
    }
    setIsSearchOpen(true);
  };

  const historyClick = (item, index) => {
    setLoading(true);
    setSelectedHistoryIndex(index);
    dispatch(emptyProductList());
    setSearchKey(item.name);
    fetchProducts({
      search: item.name,
      isKeyword: true,
      categoryId: selectedCategoryID,
      ...(isSupplementOnly && { type: 'Supplement' }),
    });
  };

  const handleListClick = (data, flag) => {
    if (flag) {
      let params = {
        id: data.id,
        search: searchKey,
      };
      if (referrerPage) {
        params = {
          ...params,
          referrer: referrerPage,
          ...(suggestedFoodGoal && { suggestedFoodGoal }),
        };
      }
      routeTo(routePathKey.foodDetail, `?${getParamsString(params)}`);
    } else {
      dispatch(emptyProductList());
      setLoading(true);
      fetchProducts({
        search: searchKey,
        isKeyword: true,
        ...(isSupplementOnly && { type: 'Supplement' }),
      });
      dispatch(fetchHistoryKeywords());
      setIsSearchOpen(false);
    }
  };

  const handleProductClick = (item) => {
    let params = {
      id: item.id,
    };
    if (referrerPage) {
      params = {
        ...params,
        referrer: referrerPage,
      };
    }
    routeTo(routePathKey.foodDetail, `?${getParamsString(params)}`);
  };

  const handleBackClick = () => {
    routeTo('pathSearchHistory');
  };

  const handleCategoryClick = (item, index) => {
    setLoading(true);
    setSelectedCategoryID(item.id);
    setSelectedCategoryIndex(index);
    fetchProducts({
      search: searchKey,
      ...(item?.id !== defaultCategory.id && { categoryId: item?.id }),
      ...(item?.id === OTHERS && { categoryId: null, categoryType: OTHERS }),
    });
  };

  const fetchProducts = (params) => {
    dispatch(searchProducts(params)).then((res) => {
      if (!res?.data?.length) {
        setNoResult(true);
        setSelectedCategoryIndex(null);
        setSelectedHistoryIndex(null);
      } else {
        setShowList(true);
        setNoResult(false);
      }
      setLoading(false);
    });
  };

  const showRecommendedProducts = () => {
    return recommendedProducts?.data?.length ? (
      <div className="product-suggestion-container">
        <SearchTitle title={SEARCH_TITLES.recommended} />
        <div className="dietary-supplement-section-wrapper">
          <InfiniteScroll
            items={recommendedProducts.data}
            itemComponent={FoodListingItem}
            itemProps={{ handleClick: handleProductClick }}
            fetchData={getRecommendedProducts}
          />
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      <div className="search-history-page">
        <div className="container-fluid p-0 banner-container">
          {noResult ? (
            <div className="back-container">
              <div className="back-wrapper">
                <img
                  src="/images/healthAndWellness/icon/arrow-left.svg"
                  alt="back"
                  onClick={handleBackClick}
                />
                <span className="back-title">กลับหน้าเมนูอาหาร</span>
              </div>
            </div>
          ) : null}
          <div ref={searchRef} className="search-img-container">
            <div className="container-fluid">
              <SearchBar
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                ref={searchInputRef}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                minLength={minLength}
                maxLength={maxLength}
              />
              {isSearchOpen && searchInput && (
                <HnWSearchMenu
                  searchedProducts={searchedProducts?.data}
                  searchInput={searchInput}
                  handleListClick={handleListClick}
                  showAllList={showAllList}
                />
              )}
            </div>
          </div>
          {!isSearchOpen && !noResult ? (
            <div className="search-category-container">
              <div className="container-fluid">
                {historyKeywords?.length ? (
                  <>
                    <SearchTitle title={SEARCH_TITLES.keywords} />
                    <HistoryKeywords
                      selectedHistoryIndex={selectedHistoryIndex}
                      historyKeywords={historyKeywords}
                      historyClick={historyClick}
                    />
                  </>
                ) : null}
                {!isSupplementOnly ? (
                  <>
                    <SearchTitle title={SEARCH_TITLES.category} />
                    <SearchCategories
                      selectedCategoryIndex={selectedCategoryIndex}
                      handleCategoryClick={handleCategoryClick}
                      categories={categoryList}
                    />
                  </>
                ) : null}
                {showList && searchedProducts?.data?.length ? (
                  <div className="product-search_results-container">
                    <SearchTitle title={SEARCH_TITLES.list} />
                    <div className="dietary-supplement-section-wrapper">
                      {searchedProducts.data.map((item) => (
                        <FoodListingItem
                          key={item.id}
                          item={item}
                          handleClick={handleProductClick}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
                {!isSupplementOnly && showRecommendedProducts()}
              </div>
            </div>
          ) : null}
          {noResult && (
            <div className="empty-list-state">
              {isSupplementOnly ? (
                <>
                  <img
                    src="/images/healthAndWellness/goals/common/no_supplement_result.svg"
                    alt="No Supplements Found Image"
                  />
                  <div className="search-category-container">
                    <div className="container-fluid">
                      {showRecommendedProducts()}
                    </div>
                  </div>
                </>
              ) : (
                <EmptyListComponent
                  emptyImg={noResultAnimation}
                  heading={NO_SEARCH_LIST_TEXT.HEADING}
                  descriptionTop={NO_SEARCH_LIST_TEXT.DESCRIPTION_TOP}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Slideup />
      <CalendarLoader showLoading={loading} />
    </>
  );
};

export default HnWSearchHistory;
