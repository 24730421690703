import React from 'react'
import './classification.scss'
import { BMIRange } from '../../../../common/commonConstant'

const Classification = ({ bmiData }) => {

    const renderList = (index, dotClass, name, range, activeClass = '') => {
        return (
            <div key={`classification${index}`} className={`bmi-card-single ${activeClass}`}>
                <div className="bmi-card-single-left">
                    <div className={`bmi-details-dot-icon ${dotClass}`}></div>
                    <div className="bmi-details-name">{name}</div>
                </div>
                <div className="bmi-details-value">{range}</div>
            </div>
        )
    }

    let weight1 = 0;
    let weight2 = 0;
    if (bmiData) {
        weight1 = Number((BMIRange.low * ((bmiData.height / 100) * (bmiData.height / 100))).toFixed(2));
        weight2 = Number((BMIRange.high * ((bmiData.height / 100) * (bmiData.height / 100))).toFixed(2));
    }

    const classficationList = [
        {
            parentClass: 'bmi-details-dot-blue-icon',
            name: 'น้ำหนักต่ำกว่าเกณฑ์',
            range: 'น้อยกว่า 18.5',
            condition: bmiData.bmi > 0 && bmiData.bmi < 18.5 ? 'bmi-card-single-blue' : ''
        },
        {
            parentClass: 'bmi-details-dot-green-icon',
            name: 'ร่างกายสมส่วน',
            range: '18.5 - 22.9',
            condition: bmiData.bmi >= 18.5 && bmiData.bmi < 23 ? 'bmi-card-single-green' : ''
        },
        {
            parentClass: 'bmi-details-dot-yellow-icon',
            name: 'อ้วนระดับ 1',
            range: '23.0 - 24.9',
            condition: bmiData.bmi >= 23 && bmiData.bmi < 25 ? 'bmi-card-single-yellow' : ''
        },
        {
            parentClass: 'bmi-details-dot-orange-icon',
            name: 'อ้วนระดับ 2',
            range: '25.0 - 29.9',
            condition: bmiData.bmi >= 25 && bmiData.bmi < 30 ? 'bmi-card-single-orange' : ''
        },
        {
            parentClass: 'bmi-details-dot-red-icon',
            name: 'อ้วนระดับ 3',
            range: 'มากกว่า 30',
            condition: bmiData.bmi >= 30 ? 'bmi-card-single-red' : ''
        }
    ]

    return (
        <div className="row classification">
            <div className="col-12">
                <div className="bmi-card">
                    {
                        classficationList.map((el, index) => (
                            renderList(index, el.parentClass, el.name, el.range, el.condition)
                        ))
                    }
                </div>
                <div className="bmi-card-total">
                    <div className="bmi-card-total-left">ช่วงน้ำหนักตามเกณฑ์</div>
                    <div className="bmi-card-total-right">{weight1 && weight2 ? `${weight1} - ${weight2}` : '-'}</div>
                </div>
            </div>
        </div>
    )
}

export default Classification