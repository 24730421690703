import { CREATE_CART, ADD_ITEM, VERIFY_OTP_FETCH, CHANGE_PHONE } from './types';
import axios from 'axios';

export const createUpgradeCart = params => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/renewal/carts`, params).then(res => {
    dispatch({
      type: CREATE_CART,
      payload: res.data,
    });
  });
};

export const addUpgradeEntry = params => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/renewal/renewalCartEntries`, params).then(res => {
    console.log('addUpgrade success -->', res.data);
    dispatch({
      type: ADD_ITEM,
      payload: res.data,
      hasError: false
    });
  }).catch(err => {
    console.log('addUpgrade fail -->', err.response);
    dispatch({
      type: ADD_ITEM,
      payload: err.response.data,
      hasError: true
    });
  });
};

export const verifyOTPWithoutUpdate = (params) => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/otp/verifyOTPWithoutUpdate`, params).then(res => {
    dispatch({
      type: VERIFY_OTP_FETCH,
      payload: res.data,
      timestamp: new Date(),
    });
  }).catch(err => {
    dispatch({
      type: VERIFY_OTP_FETCH,
      payload: err.response.data,
      timestamp: new Date(),
    });
  });
};

export const fetchChangePhoneNumber = (params) => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/renewal/changePhoneNumber`, params).then(res => {
    dispatch({
      type: CHANGE_PHONE,
      payload: res.data,
      timestamp: new Date(),
    });
  }).catch(err => {
    dispatch({
      type: CHANGE_PHONE,
      payload: err.response.data,
      timestamp: new Date(),
    });
  });
};
