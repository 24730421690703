
import React from 'react';
import './AssessmentPopup.scss';
import {ASSESSMENT_STATUS} from '../../../../../common/commonConstant'

export const AssessmentPopup = ({ assessmentStatus = '', firstCTAAction, secondCTAAction, firstCTAText, secondCTAText }) => {
    const retryModalClass = (assessmentStatus === ASSESSMENT_STATUS.done) ? "retryModalClass" : "";

    return (<div className='asm-modal-ctn'>
        <div className={`asm-modal-ctn-btn ${retryModalClass}`}>
            <button className='asm-modal-ctn-btn-btn1' onClick={firstCTAAction}>{firstCTAText}</button>
            <button className='asm-modal-ctn-btn-btn2' onClick={secondCTAAction}>{secondCTAText}</button>
        </div>
    </div>)
}

export default AssessmentPopup;
