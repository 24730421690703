import config from '../config';

export class ActiveAssessment {
    constructor(data, path = '') {
        if (data) {
            this.id = data._id || '';
            this.url = data.url || '';
            this.description = data.description || '';
            this.updatedDate = data.updatedDate || '';
            this.createdDate = data.createdDate || '';
            this.image_path = data.home_image_url || data.landing_image_url || '';
            this.imageUrl = this.image_path ? config.S3_URL + this.image_path : '';
            this.status = data.status || '';
            this.title = data.title || '';
            this.path = path || '';
        }
    }
}