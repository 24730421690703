import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, format } from 'date-fns';
import { produce } from 'immer';
import BasicTab from '../../../../shared/tab/BasicTab';
import {
  API_DATE_FORMAT,
  CALENDAR_DATA_LABELS,
  CALENDAR_LOG_LIST_CARD_MAPPING,
  DAY_XAXIS_MAPPING_WITH_HOURS,
  FOOD_BAR_NAME,
  FOOD_LOG_HOME_TITLE_TH,
  FOOD_PARAMETERS_MAPPING,
  foodAndWaterSeries,
  HNW_GOAL_SETTING,
  HOME_CALENDAR_TABS_MAPPING,
  HOME_CALENDAR_XAXIS_MAPPING,
  LABEL_CALORIE,
  LABEL_EN_DAY_CALENDAR_TAB,
  LABEL_EN_MONTH_CALENDAR_TAB,
  LABEL_EN_WEEK_CALENDAR_TAB,
  WATER_BAR_NAME,
  WEEK_XAXIS_MAPPING_WITH_INDEX,
} from '../../../../../common/commonConstant';
import {
  HealthRecordingCard,
  HealthSummaryResultCard,
  HealthSummaryInfoCard,
  HealthDateSelector,
  HealthLogFoodCard,
  CalendarLoader,
  Slideup,
} from '../../../../../common';
import { fetchFoodCalendarData } from '../../../../../actions/hnwCalendarActions';
import {
  Dropdown,
  BarGraphContainer,
  MonthGraphContainer,
  CalenderHeader,
} from '..';
import {
  calculateDefaultFoodGoal,
  calculateDefaultWaterGoal,
  capitalizeFirstLetter,
  getDataFromSession,
  getDatetimeStamp,
} from '../../../../../utill.func';
import variables from '../../../../../common/commonConstant.scss';
import './FoodLogCalendar.scss';
import {
  fixDataForMultibar,
  getMonthRange,
  getWeekRange,
} from '../../../../shared/graph/graph-util';
import LogProgressCard from '../logProgressCard/LogProgressCard';
import { extractFoodWaterLogData, handleInfinity } from '../utility';
import { formattedDropdownValue } from '../utility';
import { getHnwAllHealhGoalList } from '../../../../../actions/hnwGoalSettingActions';
import { fetchBmiData } from '../../../../../actions';

export default function FoodLogCalendar({ routeTo }) {
  const CURRENT_DATE_START = new Date();
  CURRENT_DATE_START.setHours(0, 0, 0, 0);
  const CURRENT_DATE_END = new Date(CURRENT_DATE_START);
  CURRENT_DATE_END.setHours(23, 59, 59, 999);

  const DEFAULT_SELECTED_DATE_ITEM = {
    date: CURRENT_DATE_START,
    food: null,
    water: null,
    highlightedLabel: '',
  };

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [startEndDate, setStartEndDate] = useState({
    startDate: CURRENT_DATE_START,
    endDate: CURRENT_DATE_END,
  });
  const startEndDateRef = useRef(startEndDate);

  const DefaultTotalValues = {
    TotalCarbs: 0,
    TotalProtein: 0,
    TotalFat: 0,
    TotalWater: 0,
    TotalCalories: 0,
  };

  const [healthObj, setHealthObj] = useState(DefaultTotalValues);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [monthlyActiveTab, setMonthlyActiveTab] = useState(
    CALENDAR_DATA_LABELS.food,
  );

  const { foodDark, foodLight, waterDark, waterCircleLight } = variables;
  const [activeTab, setActiveTab] = useState(
    HOME_CALENDAR_TABS_MAPPING.Day.name,
  );
  const [selectedDateItem, setSelectedDateItem] = useState(
    DEFAULT_SELECTED_DATE_ITEM,
  );

  const foodWaterData = useSelector((state) => state.hnwCalendar?.foodWater);
  const profileDetails = useSelector((state) => state.profile);
  const bmiData = useSelector((state) => state.bmi);
  const healthGoalList = useSelector(
    (state) => state.goalSetting.healthGoalList,
  );
  const defaultFoodGoal = calculateDefaultFoodGoal(bmiData);
  const defaultWaterGoal = calculateDefaultWaterGoal(bmiData);
  const healthGoal = {
    ...healthGoalList,
    foodGoal: healthGoalList?.foodGoal ?? defaultFoodGoal,
    waterGoal: healthGoalList?.waterGoal?.targetWater ?? defaultWaterGoal,
  };

  const foodData = extractFoodWaterLogData(foodWaterData, FOOD_BAR_NAME);
  const waterData = extractFoodWaterLogData(foodWaterData, WATER_BAR_NAME);

  const [daySeries, setDaySeries] = useState([]);
  const [weekSeries, setWeekSeries] = useState([]);
  const [monthSeries, setMonthSeries] = useState([]);
  const counter = { water: waterData.length, food: foodData.length };
  const totalValues = {
    water: healthObj.TotalWater,
    food: healthObj.TotalCalories,
  };

  const calculateGroupedHourlyData = (acc, obj) => {
    const date = new Date(obj.updatedDate);
    const { myFoodWeight, weight } = obj;
    const weightMultiplyFactor = handleInfinity(myFoodWeight / weight);

    let hour = Object.keys(DAY_XAXIS_MAPPING_WITH_HOURS).find((key) =>
      DAY_XAXIS_MAPPING_WITH_HOURS[key].includes(date.getHours()),
    );

    // Check if there is already an entry for the current day of the month
    if (!acc[hour]) {
      // If not, initialize a new entry with default values
      acc[hour] = {
        x: hour,
        calorie: 0,
        waterInML: 0,
      };
    }
    acc[hour].calorie += obj.caloriesPerDay * weightMultiplyFactor || 0;
    acc[hour].waterInML +=
      (obj.waterIntake || 0) +
      (obj.coffeeIntake || 0) +
      (obj.softDrinkIntake || 0);

    acc.TotalCarbs += obj.carbohydratePerDay * weightMultiplyFactor || 0;
    acc.TotalProtein += obj.proteinPerDay * weightMultiplyFactor || 0;
    acc.TotalFat += obj.fatPerDay * weightMultiplyFactor || 0;
    acc.TotalWater +=
      (obj.waterIntake || 0) +
      (obj.coffeeIntake || 0) +
      (obj.softDrinkIntake || 0);
    acc.TotalCalories += obj.caloriesPerDay * weightMultiplyFactor || 0;
    return acc;
  };

  const calculateGroupedWeeklyData = (acc, obj) => {
    const date = new Date(obj.updatedDate);
    let weekDay = WEEK_XAXIS_MAPPING_WITH_INDEX[date.getDay()];
    const { myFoodWeight, weight } = obj;
    const weightMultiplyFactor = handleInfinity(myFoodWeight / weight);

    // Check if there is already an entry for the current day of the month
    if (!acc[weekDay]) {
      // If not, initialize a new entry with default values
      acc[weekDay] = {
        x: weekDay,
        calorie: 0,
        waterInML: 0,
      };
    }
    acc[weekDay].calorie += obj.caloriesPerDay * weightMultiplyFactor || 0;
    acc[weekDay].waterInML +=
      (obj.waterIntake || 0) +
      (obj.coffeeIntake || 0) +
      (obj.softDrinkIntake || 0);

    acc.TotalCarbs += obj.carbohydratePerDay * weightMultiplyFactor || 0;
    acc.TotalProtein += obj.proteinPerDay * weightMultiplyFactor || 0;
    acc.TotalFat += obj.fatPerDay * weightMultiplyFactor || 0;
    acc.TotalWater +=
      (obj.waterIntake || 0) +
      (obj.coffeeIntake || 0) +
      (obj.softDrinkIntake || 0);
    acc.TotalCalories += obj.caloriesPerDay * weightMultiplyFactor || 0;
    return acc;
  };

  const calculateGroupedMonthData = (acc, obj) => {
    const date = new Date(obj.updatedDate);
    const dayOfMonth = date.getDate();
    date.setHours(0, 0, 0, 0);
    const { myFoodWeight, weight } = obj;
    const weightMultiplyFactor = handleInfinity(myFoodWeight / weight);

    // Check if there is already an entry for the current day of the month
    if (!acc[dayOfMonth]) {
      // If not, initialize a new entry with default values
      acc[dayOfMonth] = {
        date,
        data1: 0,
      };
    }
    acc[dayOfMonth].data1 +=
      monthlyActiveTab === CALENDAR_DATA_LABELS.food
        ? obj.caloriesPerDay * weightMultiplyFactor || 0
        : (obj.waterIntake || 0) +
        (obj.coffeeIntake || 0) +
        (obj.softDrinkIntake || 0);
    // acc[dayOfMonth].data2 += (obj.waterIntake ?? 0);
    acc.TotalCarbs += obj.carbohydratePerDay * weightMultiplyFactor || 0;
    acc.TotalProtein += obj.proteinPerDay * weightMultiplyFactor || 0;
    acc.TotalFat += obj.fatPerDay * weightMultiplyFactor || 0;
    acc.TotalWater +=
      (obj.waterIntake || 0) +
      (obj.coffeeIntake || 0) +
      (obj.softDrinkIntake || 0);
    acc.TotalCalories += obj.caloriesPerDay * weightMultiplyFactor || 0;
    return acc;
  };

  function onTabClick(tab) {
    setActiveTab(tab);
  }

  useEffect(() => {
    switch (activeTab) {
      case HOME_CALENDAR_TABS_MAPPING.Day.name:
        setStartEndDate({
          startDate: CURRENT_DATE_START,
          endDate: CURRENT_DATE_END,
        });
        break;

      case HOME_CALENDAR_TABS_MAPPING.Week.name:
        const { startOfWeek, endOfWeek } = getWeekRange(CURRENT_DATE_START);
        setStartEndDate({ startDate: startOfWeek, endDate: endOfWeek });
        break;

      case HOME_CALENDAR_TABS_MAPPING.Month.name:
        const { startOfMonth, endOfMonth } = getMonthRange(CURRENT_DATE_START);
        setStartEndDate({ startDate: startOfMonth, endDate: endOfMonth });
        break;

      default:
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);
    startEndDateRef.current = { ...startEndDate };
    const { _id: customerId } = getDataFromSession(
      HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
    );

    const fetchData = async () => {
      await Promise.all([
        dispatch(fetchBmiData()),
        dispatch(getHnwAllHealhGoalList(customerId)),
        dispatch(
          fetchFoodCalendarData({
            startDate: getDatetimeStamp(format(startEndDate.startDate, API_DATE_FORMAT)),
            endDate: getDatetimeStamp(format(startEndDate.endDate, API_DATE_FORMAT)),
          }),
        ),
      ]).finally(() => setLoading(false));
    };
    fetchData();
  }, [startEndDate]);

  useEffect(() => {
    switch (activeTab) {
      case HOME_CALENDAR_TABS_MAPPING.Day.name: {
        counter.food = foodData.length;
        counter.water = waterData.length;

        const {
          TotalCarbs,
          TotalProtein,
          TotalFat,
          TotalWater,
          TotalCalories,
          ...dailyData
        } = foodWaterData.reduce(
          calculateGroupedHourlyData,
          DefaultTotalValues,
        );
        const graphData = Object.values(dailyData);

        const newSeries = foodAndWaterSeries.map((foodWater) =>
          produce(foodWater, (draft) => {
            draft.data = graphData.map((dayData) => {
              const y =
                foodWater.name === FOOD_BAR_NAME
                  ? dayData.calorie
                  : dayData.waterInML;
              return { x: dayData.x, y };
            });
            fixDataForMultibar(draft.data, HOME_CALENDAR_XAXIS_MAPPING.Day);
          }),
        );
        setDaySeries(newSeries);
        setHealthObj({
          TotalCarbs,
          TotalProtein,
          TotalFat,
          TotalWater,
          TotalCalories,
        });
        const currentInstance = new Date();
        const currentHour = currentInstance.getHours();
        const index = Object.values(DAY_XAXIS_MAPPING_WITH_HOURS).findIndex(
          (item) => item.includes(currentHour),
        );
        const food = newSeries.find((series) => series.name === FOOD_BAR_NAME)
          .data[index].y;
        const water = newSeries.find((series) => series.name === WATER_BAR_NAME)
          .data[index].y;
        setSelectedDateItem({
          date: currentInstance,
          food,
          water,
          highlightedLabel: HOME_CALENDAR_XAXIS_MAPPING.Day[index],
        });
        break;
      }
      case HOME_CALENDAR_TABS_MAPPING.Week.name: {
        counter.food = foodData.length;
        counter.water = waterData.length;

        const {
          TotalCarbs,
          TotalProtein,
          TotalFat,
          TotalWater,
          TotalCalories,
          ...weeklyData
        } = foodWaterData.reduce(
          calculateGroupedWeeklyData,
          DefaultTotalValues,
        );
        const graphData = Object.values(weeklyData);
        const newSeries = foodAndWaterSeries.map((foodWater) =>
          produce(foodWater, (draft) => {
            draft.data = graphData.map((dayData) => {
              const y =
                foodWater.name === FOOD_BAR_NAME
                  ? dayData.calorie
                  : dayData.waterInML;
              return { x: dayData.x, y };
            });
            fixDataForMultibar(draft.data, HOME_CALENDAR_XAXIS_MAPPING.Week);
          }),
        );
        setWeekSeries(newSeries);
        setHealthObj({
          TotalCarbs,
          TotalProtein,
          TotalFat,
          TotalWater,
          TotalCalories,
        });
        const currentInstance = new Date();
        const index = currentInstance.getDay();
        const food = newSeries.find((series) => series.name === FOOD_BAR_NAME)
          .data[index].y;
        const water = newSeries.find((series) => series.name === WATER_BAR_NAME)
          .data[index].y;
        setSelectedDateItem({
          date: addDays(startEndDate.startDate, index),
          food,
          water,
          highlightedLabel: HOME_CALENDAR_XAXIS_MAPPING.Week[index],
        });
        break;
      }
      case HOME_CALENDAR_TABS_MAPPING.Month.name: {
        counter.food = foodData.length;
        counter.water = waterData.length;

        const {
          TotalCarbs,
          TotalProtein,
          TotalFat,
          TotalWater,
          TotalCalories,
          ...monthlyData
        } = foodWaterData.reduce(calculateGroupedMonthData, DefaultTotalValues);
        const CalendarData = Object.values(monthlyData);
        setMonthSeries(CalendarData);
        setHealthObj({
          TotalCarbs,
          TotalProtein,
          TotalFat,
          TotalWater,
          TotalCalories,
        });
        break;
      }
      default:
        break;
    }
  }, [foodWaterData, monthlyActiveTab]);

  const handleDateSelect = (item) => {
    setStartEndDate({ startDate: item.startDate, endDate: item.endDate });
    setDropdownOpen(false);
  };

  const onMouseMove = (data, index) => {
    const date = addDays(startEndDateRef.current.startDate, index);
    setSelectedDateItem({
      date,
      food: data?.food[index]?.y,
      water: data?.water[index]?.y,
      highlightedLabel:
        activeTab === HOME_CALENDAR_TABS_MAPPING.Day.name
          ? HOME_CALENDAR_XAXIS_MAPPING.Day[index]
          : HOME_CALENDAR_XAXIS_MAPPING.Week[index],
    });
  };

  const routeToLogList = (type) => {
    routeTo(
      CALENDAR_LOG_LIST_CARD_MAPPING[type],
      `?type=${type}&startDate=${format(
        startEndDate.startDate,
        API_DATE_FORMAT,
      )}&endDate=${format(startEndDate.endDate, API_DATE_FORMAT)}`,
    );
  };

  const routeToFoodLogHome = (params) => {
    const dateParams = `startDate=${format(
      startEndDate.startDate,
      API_DATE_FORMAT,
    )}&endDate=${format(startEndDate.endDate, API_DATE_FORMAT)}`;
    const finalParams = params ? `${params}&${dateParams}` : `?${dateParams}`;
    routeTo('pathFoodLogHome', `${finalParams}`);
  };

  const renderDailyGoalChart = () => {
    const healthParamsValues = FOOD_PARAMETERS_MAPPING.reduce(
      (acc, item) => ({
        ...acc,
        [item.name]: {
          ...item,
          consumed: healthObj[`Total${capitalizeFirstLetter(item.name)}`],
          total:
            healthGoal.foodGoal &&
            healthGoal.foodGoal[`target${capitalizeFirstLetter(item.name)}`],
        },
      }),
      {},
    );

    return (
      <>
        <HealthLogFoodCard
          isEmpty={!healthGoal.foodGoal}
          onClick={() => routeToFoodLogHome()}
          healthParamsValues={healthParamsValues}
          calorie={{
            total: healthGoal?.foodGoal?.targetCalories,
            consumed: healthObj?.TotalCalories,
            unit: LABEL_CALORIE,
          }}
        />
        <div className="round-corner-card">
          <LogProgressCard
            onClick={() => routeToFoodLogHome('?scrollTo=waterLog')}
            type={WATER_BAR_NAME.toLowerCase()}
            value={healthObj.TotalWater}
            maxValue={healthGoal?.waterGoal}
          />
        </div>
      </>
    );
  };

  return (
    <div className="food-log-calendar-container">
      <CalendarLoader showLoading={isLoading} />
      <CalenderHeader routeTo={routeTo} />
      <div className="profile-banner-container">
        <div className="profile-img">
          <img
            src={
              profileDetails?.pictureUrl ??
              '/images/healthAndWellness/profile/profile-img.svg'
            }
            className="profile-img-dimensions"
          />
        </div>
      </div>
      <div className="food-log-calendar-container-card">
        <div className="row align-items-center">
          <h2 className="header-typography">{FOOD_LOG_HOME_TITLE_TH}</h2>
        </div>

        <BasicTab
          tabs={HOME_CALENDAR_TABS_MAPPING}
          activeTab={activeTab}
          onTabClick={onTabClick}
        />
        <div className="graph-header">
          <h2 className="header-typography">
            {HOME_CALENDAR_TABS_MAPPING[activeTab].heading}
          </h2>
          <Dropdown
            name="date"
            displayText={formattedDropdownValue(
              activeTab,
              startEndDate.startDate,
              startEndDate.endDate,
            )}
            onClick={() => {
              setDropdownOpen(true);
            }}
          />
        </div>
        <HealthDateSelector
          view={activeTab}
          isOpen={isDropdownOpen}
          onClickOK={handleDateSelect}
          startDate={startEndDate.startDate}
          endDate={startEndDate.endDate}
        />
        {
          {
            [LABEL_EN_DAY_CALENDAR_TAB]: (
              <BarGraphContainer
                type={LABEL_EN_DAY_CALENDAR_TAB}
                xAxisCategories={HOME_CALENDAR_XAXIS_MAPPING.Day}
                yAxisCategories={daySeries}
                min={startEndDate.startDate}
                max={startEndDate.endDate}
                onMouseMove={onMouseMove}
                highlightedLabel={selectedDateItem.highlightedLabel}
                itemTypeList={[
                  { name: FOOD_BAR_NAME, value: selectedDateItem.food },
                  { name: WATER_BAR_NAME, value: selectedDateItem.water },
                ]}
              />
            ),
            [LABEL_EN_WEEK_CALENDAR_TAB]: (
              <BarGraphContainer
                date={selectedDateItem.date}
                type={LABEL_EN_WEEK_CALENDAR_TAB}
                xAxisCategories={HOME_CALENDAR_XAXIS_MAPPING.Week}
                yAxisCategories={weekSeries}
                min={startEndDate.startDate}
                max={startEndDate.endDate}
                onMouseMove={onMouseMove}
                highlightedLabel={selectedDateItem.highlightedLabel}
                itemTypeList={[
                  { name: FOOD_BAR_NAME, value: selectedDateItem.food },
                  { name: WATER_BAR_NAME, value: selectedDateItem.water },
                ]}
              />
            ),
            [LABEL_EN_MONTH_CALENDAR_TAB]: (
              <MonthGraphContainer
                typeList={[FOOD_BAR_NAME, WATER_BAR_NAME]}
                trackTotal={
                  monthlyActiveTab === CALENDAR_DATA_LABELS.food
                    ? healthGoal?.foodGoal?.targetCalories
                    : undefined
                }
                data={monthSeries}
                min={startEndDate.startDate}
                max={startEndDate.endDate}
                colorCircledark={
                  monthlyActiveTab === CALENDAR_DATA_LABELS.food
                    ? foodDark
                    : waterDark
                }
                colorCirclelight={
                  monthlyActiveTab === CALENDAR_DATA_LABELS.food
                    ? foodLight
                    : waterCircleLight
                }
                showCircleWithoutDataStrength
                onTabChange={setMonthlyActiveTab}
                showLegends={false}
              />
            ),
          }[activeTab]
        }
        {activeTab !== HOME_CALENDAR_TABS_MAPPING.Day.name && (
          <>
            <div className="health-summary-result-card-header">
              {HOME_CALENDAR_TABS_MAPPING[activeTab].summary_result_heading}
            </div>
            <div className="health-summary-result-card">
              {[FOOD_BAR_NAME, WATER_BAR_NAME]?.map((itemName) => (
                <HealthSummaryResultCard
                  type={itemName}
                  key={itemName}
                  quantity={totalValues[itemName]}
                />
              ))}
            </div>
            <div className="health-summary-info-card-header">
              {HOME_CALENDAR_TABS_MAPPING[activeTab].summary_info_heading_food}
            </div>
            <div className="health-summary-info-card">
              <HealthSummaryInfoCard
                dataItems={[
                  { componentName: 'carbs', value: healthObj.TotalCarbs },
                  { componentName: 'protein', value: healthObj.TotalProtein },
                  { componentName: 'fat', value: healthObj.TotalFat },
                ]}
              />
            </div>
          </>
        )}
        <div className="health-record-card-header">
          {HOME_CALENDAR_TABS_MAPPING[activeTab].recording_heading}
        </div>

        {activeTab === HOME_CALENDAR_TABS_MAPPING.Day.name ? (
          renderDailyGoalChart()
        ) : (
          <>
            <div className="health-recording-card">
              {[FOOD_BAR_NAME, WATER_BAR_NAME]?.map((itemName) => (
                <React.Fragment key={itemName}>
                  <HealthRecordingCard
                    type={itemName}
                    key={itemName}
                    quantity={counter[itemName]}
                    navigateTo={routeToLogList}
                  />
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </div>
      <Slideup />
    </div>
  );
}
