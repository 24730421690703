import { fetchDataRequest, fetchDataSuccess } from "../actions/foodCalorieActions";
import { EMPTY_PRODUCTS_REQUEST, GET_CATEGORIES, GET_HISTORY_KEYWORDS, GET_RECOMMENDED_PRODUCTS, GET_SEARCH_PRODUCTS } from "../actions/types";
import { PUBLISH_STATUS, FOOD_TABS_MAPPING } from "../common/commonConstant";
import { getHistoryKeywords, getProductCategories, getProducts } from "../services/healthWellness/foodCalories.service";

const defaultProductParams = { limit: 10, start: 0, status: PUBLISH_STATUS.PUBLISHED, type: FOOD_TABS_MAPPING.Food.name }

export const emptyProductList = () => dispatch => dispatch(fetchDataRequest(EMPTY_PRODUCTS_REQUEST));

export const searchProducts = params => dispatch => {
    return getProducts({ ...defaultProductParams, ...params })
        .then((res) => {
            dispatch(fetchDataSuccess(GET_SEARCH_PRODUCTS, res));
            return res;
        }).catch(function (error) {
            alert(error.message);
        });
}

export const fetchCategories = () => dispatch => getProductCategories()
    .then((res) => {
        dispatch(fetchDataSuccess(GET_CATEGORIES, res));
        return res;
    }).catch(function (error) {
        alert(error.message);
    });

export const fetchHistoryKeywords = () => dispatch => getHistoryKeywords()
    .then((res) => {
        dispatch(fetchDataSuccess(GET_HISTORY_KEYWORDS, res));
        return res;
    }).catch(function (error) {
        alert(error.message);
    });

export const fetchRecommendedProducts = params => dispatch => getProducts({ ...defaultProductParams, ...params })
    .then((res) => {
        dispatch(fetchDataSuccess(GET_RECOMMENDED_PRODUCTS, res));
        return res;
    }).catch(function (error) {
        alert(error.message);
    });
