import React from "react";
import { formatNumberWithCommas, handleBmiBadges } from "../../../utill.func";
import BmiBar from "../../bmiBar/BmiBar";
import { CHALLENGE, ChallengeTeamdashboardStatus } from "../../challengeConstants";
import RectBadge from "../../rectBadge/RectBadge";
import ChallengeProfileImage from "../profileImageContainer/ChallengeProfileImage";
import './TeamInfoCard.scss';

const TeamInfoCard = ({
    userName,
    userId,
    phoneNo,
    watchName,
    stepsTakenToday,
    totalStepsToday,
    cumulativeSteps,
    bmiValue,
    rankImage,
    pictureUrl,
    isLeader,
    teamDashboardStatus,
    isNewMember,
    isNewAbo,
    bmiHeading
}) => {

    const bmiClassname = handleBmiBadges(bmiValue)?.classname;
    const bmiTitle = handleBmiBadges(bmiValue)?.bmiTitle;

    const renderCardDescription = () => {
        return (
            <div className="card-ongoing-description">
                <div className="card-ongoing-name">
                    {userName}
                </div>
                <div className="card-ongoing-number">
                    {userId ? `#${userId}` : ''}
                </div>
            </div>
        )
    }

    const renderCardContactDetails = () => {
        return (
            <div className="card-ongoing-contact-details">
                <div className="card-ongoing-contactleft">
                    <img src="/images/challenge/icon-challenge-phone.svg" alt="" />
                    <div className="card-ongoing-contact-name">{phoneNo}</div>
                </div>
                <div className="card-ongoing-contactright">
                    <img src="/images/challenge/icon-challenge-watch.svg" alt="" />
                    <div className="card-ongoing-contact-name">{watchName}</div>
                </div>
            </div>
        )
    }

    const renderCardStepDetails = () => {
        return (
            <div className="card-ongoing-steps-details">
                <div className="card-ongoing-steps-details-left">
                    <div className="card-ongoing-steps-details-left-col1">{CHALLENGE.teamDashboard.teamInfoStepsHeading}</div>
                    <div className={`card-ongoing-steps-details-left-col2 ${teamDashboardStatus === ChallengeTeamdashboardStatus.remove_team ? 'disable-color' : ''}`}>{formatNumberWithCommas(stepsTakenToday) || ''}</div>
                    <div className="card-ongoing-steps-details-left-col3">
                        <span className="card-ongoing-steps-details-left-col3-title">{totalStepsToday ? `/${formatNumberWithCommas(totalStepsToday)}` : ''}</span>
                        <span>{CHALLENGE.teamDashboard.stepsUnit}</span>
                    </div>
                </div>
                <hr className="rounded-dividing-line"></hr>
                <div className="card-ongoing-steps-details-right">
                    <div className="card-ongoing-steps-details-right-col1">{CHALLENGE.teamDashboard.cumulativeSteps}</div>
                    <div className={`card-ongoing-steps-details-right-col2 ${teamDashboardStatus == ChallengeTeamdashboardStatus.remove_team ? 'disable-color' : ''}`}>{formatNumberWithCommas(cumulativeSteps) || ''}</div>
                    <div className="card-ongoing-steps-details-right-col3">
                        {CHALLENGE.teamDashboard.stepsUnit}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="user-info-carousel">
            <div className="card-ongoing-container-info">
                <ChallengeProfileImage pictureUrl={pictureUrl} isLeader={isLeader} />
                {(isNewAbo || isNewMember) && <div className="team-leader-card">
                    <RectBadge>
                    <div className='rect-badge-title'>{isNewAbo? CHALLENGE.teamDashboard.teamNewAboTitle: isNewMember ?CHALLENGE.teamDashboard.teamNewMemberTitle:''}</div>
                    </RectBadge>
                </div>}
                <div className="rank-card">
                    <img className="rank-card-image" src={rankImage} />
                </div>
                {renderCardDescription()}
                {renderCardContactDetails()}
                {renderCardStepDetails()}
                <div className="card-ongoing-bmi-details">
                    <span>{bmiHeading}</span>
                </div>
                <BmiBar teamDashboardStatus={teamDashboardStatus} bmiValue={bmiValue} bmiClassname={bmiClassname} bmiTitle={bmiTitle}/>
            </div>
        </div>
    )
}

export default TeamInfoCard;
