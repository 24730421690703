import React, { useState, useEffect } from 'react';
import { getPriceFormat } from '../../../../services/promotion/utils.service';

export const SolutionFooter = props => {
  const { solutionList, priceTotal, solutionPlan, selectedPlanType, checkoutSubmit } = props;
  const [totalCount, setTotalCount] = useState(0);
  const [checkoutDisable, setCheckoutDisable] = useState(true);
  const [productView, setProductView] = useState(false);

  useEffect(() => {
    if (solutionList && solutionList.products && solutionList.products.length > 0) {
      let addedQty = 0;
      solutionList.products.forEach(product => {
        if (product.addedQuantity && product.addedQuantity > 0) {
          addedQty += product.addedQuantity;
        }
      });
      setTotalCount(addedQty);
    }
  }, [JSON.stringify(solutionList)]);

  useEffect(() => {
    if (totalCount === 0) {
      setProductView(false)
    }
  }, [totalCount]);

  useEffect(() => {
    if (solutionPlan && solutionPlan.length) {
      solutionPlan.forEach(plans => {
        const qualificationRuleType = plans.qualificationRules &&
          plans.qualificationRules.length &&
          plans.qualificationRules[0].qualificationRuleType ?
          plans.qualificationRules[0].qualificationRuleType : '';
        const minValue = plans.qualificationRules &&
          plans.qualificationRules.length &&
          plans.qualificationRules[0].minValue ?
          plans.qualificationRules[0].minValue : 0;
        if (qualificationRuleType.toUpperCase().indexOf(selectedPlanType) >= 0) {
          if (priceTotal >= minValue) {
            setCheckoutDisable(false);
          } else {
            setCheckoutDisable(true);
          }
        }
      });
    }
  }, [priceTotal]);

  const getPlansPrice = option => {
    let price = 0;
    if (solutionPlan && solutionPlan.length) {
      solutionPlan.forEach(plans => {
        const qualificationRuleType = plans.qualificationRules &&
          plans.qualificationRules.length &&
          plans.qualificationRules[0].qualificationRuleType ?
          plans.qualificationRules[0].qualificationRuleType : '';
        const minValue = plans.qualificationRules &&
          plans.qualificationRules.length &&
          plans.qualificationRules[0].minValue ?
          plans.qualificationRules[0].minValue : 0;
        const maxValue = plans.qualificationRules &&
          plans.qualificationRules.length &&
          plans.qualificationRules[0].maxValue ?
          plans.qualificationRules[0].maxValue : 0;
        if (qualificationRuleType.toUpperCase().indexOf(selectedPlanType) >= 0) {
          price = option === 'min' ? minValue : maxValue;
        }
      });
    }
    return price ? getPriceFormat(price) : 0;
  }

  return (
    <section>
      <div className={(productView ? 'solution-plp-overlay' : '')} onClick={() => setProductView(false)}></div>
      <div className='row solution-footer no-margin-row'>
        <div className={'col-12 solution-subfooter ' + (productView ? 'footer-shadow' : '')}>
          <div className='row no-margin-row'>
            <div className='col-11 no-padding-col display-flex'>
              <span className='total-count'>รายการสินค้าที่เลือก ({totalCount})</span>
              <div className={'sop-tag ' + (selectedPlanType === 'BASIC' ? 'sop-tag-basic' : '')}>{selectedPlanType === 'BASIC' ? 'เบสิก' : 'บียอนด์'}</div>
            </div>
            <div className='col-1 no-padding-col qty-icon' onClick={() => totalCount > 0 ? setProductView(!productView) : {}}>
              {productView ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3 9.7078L16.89 8.2998L12.3 12.8738L7.71005 8.2998L6.30005 9.7078L12.3 15.6998L18.3 9.7078Z" fill="#2C2C2C" />
              </svg>
                : totalCount > 0 ? <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.3001 14.5695L16.8901 15.9775L12.3001 11.4035L7.71011 15.9775L6.30011 14.5695L12.3001 8.57754L18.3001 14.5695Z" fill="#2C2C2C" />
                </svg>
                  : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.3001 14.2922L16.8901 15.7002L12.3001 11.1262L7.71009 15.7002L6.30009 14.2922L12.3001 8.3002L18.3001 14.2922Z" fill="#9EA0A3" />
                  </svg>}
            </div>
          </div>
        </div>
        {productView ? props.children : null}
        <div className={'col-12 price-footer ' + (productView ? 'price-footer-shadow' : '')}>
          <div className='row checkout-section no-margin-row'>
            <div className='col-8 no-padding-col price-section'>
              <div className='solution-price'>฿ {getPriceFormat(priceTotal)}</div>
              <div className='solution-pvbv'>ยอดซื้อ ฿ {getPlansPrice('min')} – {getPlansPrice('max')}</div>
            </div>
            <div className='col-4  no-padding-col footer-btn'>
              <button
                disabled={checkoutDisable}
                onClick={() => checkoutSubmit()}
                className="solution-btn-purchase floating-right"
              >
                สมัคร
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}