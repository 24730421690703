import { FoodLogGoal } from './FoodLogGoal.model';
import { Food } from './FoodLog.model';
import { FoodLog, FoodMenu } from './HealthLog.model';
import config from '../config';

export class FoodHealth {
  constructor(data = null) {
    if (data) {
      if (data) {
        this.id = data._id || data.id || '';
        this.customers = data.customers || '';
        this.createdDate = data.createdDate || '';
        this.updatedDate = data.updatedDate || '';
        this.waterLevel = data.waterLevel || null;
        this.coffeeLevel = data.coffeeLevel || null;
        this.softDrinkLevel = data.softDrinkLevel || null;
        this.caloriesPerDay = data.caloriesPerDay || null;
        this.carbohydratePerDay = data.carbohydratePerDay || null;
        this.proteinPerDay = data.proteinPerDay || null;
        this.fatPerDay = data.fatPerDay || null;
        this.weight = data.productWeight || null;
        this.myFoodWeight = data.myFoodWeight?.myFoodWeight || null;
        this.foodGoal = data.foodGoal ? new FoodLogGoal(data.foodGoal) : null;
        this.myFoodLog = data.myFoodLog ? new FoodLog(data.myFoodLog) : null;
        this.myFoodMenu = data.myFoodMenu ? new FoodMenu(data.myFoodMenu) : null;
        this.myFoodWeight = data.myFoodWeight?.myFoodWeight || null;
        this.image = this.myFoodMenu?.image
          ? config.S3_URL + this.myFoodMenu?.image
          : '/images/healthAndWellness/foodCalories/food-details/food-default-suggest-card.svg';
        this.amount = data.myFoodMenu?.calorie || '';
        this.name = data.myFoodMenu?.name || '';
      }
    }
  }
}
