import { OTHERS, PUBLISH_STATUS } from '../common/commonConstant';
import { getFoodDetail, getProductCategories, getProducts } from '../services/healthWellness/foodCalories.service';
import { GET_FOOD_CATEGORIES, GET_FOOD_DETAIL, GET_FOOD_PRODUCTS } from './types';

export const fetchDataRequest = (type) => ({ type });
export const fetchDataSuccess = (type, data) => ({ type, payload: data });
export const fetchDataFailure = (type, error) => ({ type, payload: error });

export const fetchFoodCategories = () => (dispatch) => getProductCategories()
  .then((rest) => {
    dispatch({
      type: GET_FOOD_CATEGORIES,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const fetchFoodProducts = ({ categoryId, type, pageOffset, pageNo }) =>
  (dispatch) => getProducts(
    {
      ...['', 'all'].includes(categoryId) ? {} : { categoryId },
      ...[OTHERS].includes(categoryId) ? {categoryType: OTHERS, categoryId: null} : {},
      type,
      limit: pageOffset || 100,
      start: pageNo,
      status: PUBLISH_STATUS.PUBLISHED,
    })
    .then((rest) => {
      dispatch({
        type: GET_FOOD_PRODUCTS,
        payload: rest,
        productType: type.toLowerCase(),
        categoryId,
      });
      return rest;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const getFoodDetails = (params) => (dispatch) =>
  getFoodDetail(params)
    .then((result) => {
      dispatch({
        type: GET_FOOD_DETAIL,
        payload: result,
      });
      return result;
    }).catch(function (error) {
      alert(error.message);
    });
