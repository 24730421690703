import React from 'react';
import SlickCarousel from '../../../../../common/slickCarousel/slickCarousel';
import { ASSESSMENT_QUESTION_TEMPLATE } from '../../../../../common/commonConstant';
import './ProductCarousel.scss';

const ProductCarousel = ({ typeMapping, data = [], handleAddProductEvent }) => (
  <SlickCarousel slidesToShow='2.1'>
    {data.map((product, index) => {
      let ProductType;
      if (typeMapping) {
        ProductType = Object.entries(typeMapping).find(([, productIds]) =>
          productIds.includes(product.id),
        )[0];
      }
      return (
        <div
          key={product.title}
          className="products-recommend-card"
          data-slick-index={index}
        >
          <div className="products-recommend-img-container">
            <img src={product.bannerUrl} />
          </div>
          {ProductType && ASSESSMENT_QUESTION_TEMPLATE[ProductType] && (
            <div
              className={`product-card-tag product-card-${ASSESSMENT_QUESTION_TEMPLATE[ProductType].colorName}-tag`}
            >
              <div className="category-general-icon">
                <img
                  src={`/images/assesment/${ASSESSMENT_QUESTION_TEMPLATE[ProductType].icon}.svg`}
                />
              </div>
              <span>{ASSESSMENT_QUESTION_TEMPLATE[ProductType].productTag}</span>
            </div>
          )}
          <div className="products-recommend-card-title">{product.title}</div>
          <div className="products-recommend-price-cart-container">
            <div className="products-recommend-price">
              <span className="products-recommend-price-fix">
                {product.prices[0].currency.symbol}
              </span>
              <span className="products-recommend-price-dynamic">
                {product.prices[0].price}
              </span>
            </div>
            <div className={`products-recommend-cart-container${product?.isSelected ? ' selected' : ''}`} onClick={() => handleAddProductEvent(product)}>
              <img
                className="products-recommend-cart__shopping-icon"
                src={product?.isSelected ? "/images/healthAndWellness/assessment/assessment-shop-bag.svg" : "/images/assesment/shopping-bag.svg"}
              />
            </div>
          </div>
          <div className="products-recommend-pvbv">
            <div className="products-recommend-pv">
              <span className="products-recommend-pv-title">PV</span>
              <span className="products-recommend-pv-value">
                {product.amwayValue.pointValue}
              </span>
            </div>
            <div className="products-recommend-bv">
              <span className="products-recommend-pv-title">BV</span>
              <span className="products-recommend-pv-value">
                {product.amwayValue.businessVolume}
              </span>
            </div>
          </div>
        </div>
      );
    })}
  </SlickCarousel>
);

export default ProductCarousel;
