import {
  DAY_XAXIS_MAPPING_WITH_HOURS,
  FOOD_BAR_NAME,
  HOME_CALENDAR_TABS_MAPPING,
  LABEL_EN_MANUAL_EXERCISE,
  WEEK_XAXIS_MAPPING_WITH_INDEX,
} from '../../../../common/commonConstant';
import { thaiDate } from '../../../../utill.func';

export const extractFoodWaterLogData = (foodWaterData, type) =>
  foodWaterData.filter((foodWaterItem) =>
    foodWaterItem.hasOwnProperty(
      type === FOOD_BAR_NAME ? 'myFoodLog' : 'waterVolume',
    ),
  );

export const extractExerciseTypeData = (exerciseData, type) =>
  exerciseData.filter((execiseItem) => execiseItem.type === type);

export const handleInfinity = (value) => {
  return !Number.isFinite(value) ? 1 : value;
};

export const calculateGroupedFoodWaterLogData = (acc, obj) => {
  const { myFoodWeight, weight } = obj;
  const weightMultiplyFactor = handleInfinity(myFoodWeight / weight);
  acc.TotalCarbs += obj.carbohydratePerDay * weightMultiplyFactor || 0;
  acc.TotalProtein += obj.proteinPerDay * weightMultiplyFactor || 0;
  acc.TotalFat += obj.fatPerDay * weightMultiplyFactor || 0;
  acc.TotalWater +=
    (obj.waterIntake || 0) +
    (obj.coffeeIntake || 0) +
    (obj.softDrinkIntake || 0);
  acc.TotalCalories += obj.caloriesPerDay * weightMultiplyFactor || 0;
  acc.TotalExercise += obj.caloriesBurn || 0;
  return acc;
};

export const formattedDropdownValue = (activeTab, startDate, endDate) =>
  ({
    [HOME_CALENDAR_TABS_MAPPING.Day.name]: thaiDate(startDate, 'dd MMM yy'),
    [HOME_CALENDAR_TABS_MAPPING.Week.name]: `${thaiDate(
      startDate,
      'dd MMM',
    )} - ${thaiDate(endDate, 'dd MMM')}`,
    [HOME_CALENDAR_TABS_MAPPING.Month.name]: thaiDate(startDate, 'LLLL'),
  }[activeTab]);

export const dayHourFormatter = (dateValue, endDate) =>
  Object.keys(DAY_XAXIS_MAPPING_WITH_HOURS).find((item) =>
    DAY_XAXIS_MAPPING_WITH_HOURS[item].includes(
      new Date(dateValue + (dateValue !== endDate)).getHours(),
    ),
  );

export const weekDayFormatter = (dateValue, endDate) => {
  const weekDay = Object.keys(WEEK_XAXIS_MAPPING_WITH_INDEX).find(
    (item) =>
      WEEK_XAXIS_MAPPING_WITH_INDEX[item] ===
      new Date(dateValue + (dateValue !== endDate)).getDay(),
  );
  return weekDay;
};
