import React, { useEffect, useState } from 'react';
import './ChallengeCarouselLoader.scss';

const ChallengeCarouselLoader = () => {
  return (
    <div className="carousel-skeleton-container">
      <div className="carousel-skeleton-card-container">
        <div className="skeleton-card-image skeleton"></div>
        <div className="skeleton-card-details">
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
        </div>
      </div>
      <div className="carousel-skeleton-card-container">
        <div className="skeleton-card-image skeleton"></div>
        <div className="skeleton-card-details">
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeCarouselLoader;
