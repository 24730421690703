import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { isArray, isEqual } from 'lodash';

import { createUpgradeCart, addUpgradeEntry, customerFetch, getPickup, choosePickupStore, customerFindByNumber, createBankAccount, updateDelivery, customerFetchByNumber} from '../../../actions';
import { ConditionEBook } from '../../condition/PaymentCondition';
import { commonConstant } from '../../../common';
import variables from '../../../common/commonConstant.scss';
import { Card, Collapse, Checkbox, Modal, ImageUpload } from '../../../helpers';
import { activityService } from '../../../services';

import './ChooseMagType.scss';
import { getAccountList } from './getAccountList';
import { Fragment } from 'react';

const liff = window.liff;

const code = {
  electronic: ['296047', '291580'],
  physical: {
    currentAddr: ['254967', '291578'],
    atShop: ['254967', '291577'],
  },
};

const banksName = [
  { value: "BBL", label: "ธนาคารกรุงเทพ จำกัด (มหาชน)" },
  { value: "MSB", label: "ธนาคารซูมิโตโม มิทซุย แบงกิ้งคอร์ปอเรชั่น" },
  { value: "GSB", label: "ธนาคารออมสิน" },
  { value: "BOA", label: "ธนาคารยูไนเต็ด โอเวอร์ซีส์ (ไทย)" },
  { value: "BOT", label: "ธนาคารแห่งประเทศไทย" },
  { value: "BIS", label: "ธนาคารเครดิต อะกริกอล อินโดสุเอซ" },
  { value: "BUA", label: "ธนาคารแห่งอเมริกา เนชั่นแนล แอสโซซิเอชัน" },
  { value: "STC", label: "ธนาคารแสตนดาร์ดชาเตอร์" },
  { value: "LTB", label: "ธนาคารแหลมทอง" },
  { value: "CIM", label: "ธนาคารซีไอเอ็มบี ไทย จำกัด (มหาชน)" },
  { value: "ISB", label: "ธนาคารอิสลามแห่งประเทศไทย" },
  { value: "BCH", label: "ธนาคารสากลพาณิชย์แห่งประเทศจีน" },
  { value: "EXIM", label: "ธนาคารเพื่อการส่งออกและนำเข้าแห่ง ปทท." },
  { value: "GE", label: "GE FIRST CHOICE" },
  { value: "BAC", label: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร" },
  { value: "BTH", label: "ธนาคารไทยธนาคาร จำกัด (มหาชน)" },
  { value: "DIJ", label: "ธนาคารมิซูโฮ คอร์ปอเรต จำกัด" },
  { value: "FSB", label: "ธนาคารโอเวอร์ซี-ไชนีสแบงกิ้ง" },
  { value: "KTB", label: "ธนาคารกรุงไทย จำกัด (มหาชน)" },
  { value: "TCR", label: "ธนาคารไทยเครดิต เพื่อรายย่อย จำกัด (มหาชน)" },
  { value: "AIG", label: "ธนาคารเอไอจี เพื่อรายย่อย จำกัด (มหาชน)" },
  { value: "TOB", label: "ธนาคารดีบีเอส ไทยทนุ" },
  { value: "RHB", label: "ธนาคาร อาร์ เอช บี" },
  { value: "BAY", label: "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)" },
  { value: "LHB", label: "ธนาคารแลนด์ แอนด์ เฮ้าส์ เพื่อรายย่อย จำกัด(มหาชน)" },
  { value: "BOB", label: "ธนาคารภารตโอเวอร์ซีส์" },
  { value: "SAB", label: "ธนาคารเอเชียซีเคียวฯ" },
  { value: "KKB", label: "ธนาคารเกียรตินาคิน จำกัด (มหาชน)" },
  { value: "GHB", label: "ธนาคารอาคารสงเคราะห์" },
  { value: "SCI", label: "ธนาคารนครหลวงไทย จำกัด (มหาชน)" },
  { value: "BBC", label: "ธนาคารกรุงเทพพาณิชยการณ์" },
  { value: "NTB", label: "ธนาคารสแตนดาร์ด ชาร์ดเตอร์ด จำกัด (มหาชน)" },
  { value: "FCB", label: "FIRST BANGKOK CITY BANK PUBLIC COMPANY LTD." },
  { value: "SCB", label: "ธนาคารไทยพาณิชย์ จำกัด (มหาชน)" },
  { value: "UBB", label: "ธนาคารสหธนาคาร" },
  { value: "TFB", label: "ธนาคารกสิกรไทย จำกัด (มหาชน)" },
  { value: "CTB", label: "ธนาคารซิติแบงค์, เอ็น.เอ." },
  { value: "ABN", label: "ธนาคารเอบีเอ็น แอมโร เอ็น.วี." },
  { value: "ACL", label: "ธนาคารสินเอเชีย จำกัด (มหาชน) / ธ.ไอซีบีซี (ไทย)" },
  { value: "TIS", label: "ธนาคารทิสโก้ จำกัด (มหาชน)" },
  { value: "BTK", label: "ธนาคารแห่งโตเกียว-มิตซูบิชิ" },
  { value: "H&C", label: "ธนาคารฮ่องกงและเซี่ยงไฮ้" },
  { value: "SMT", label: "ธนาคารซูมิโตโม" },
  { value: "CHB", label: "ธนาคารเจพีมอร์แกน เชส สาขากรุงเทพฯ" },
  { value: "OEB", label: "ธนาคารดอยซ์แบงก์" },
  { value: "AEON", label: "อิออน ธนสินทรัพย์ (ไทยแลนด์)" },
  { value: "TMB", label: "ธนาคารทหารไทยธนชาต จำกัด (มหาชน)" }
];

const getBankNumberError = {
  "H&C": (bankNum) => (bankNum.length === 12 && bankNum.startsWith("0")) ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 12 หลัก และขึ้นต้นด้วยเลข 0',
  "BIS": (bankNum) => (bankNum.length === 12 && bankNum.endsWith("0")) ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 12 หลัก และลงท้ายด้วยเลข 0',
  "OEB": (bankNum) => (bankNum.length === 10 && (bankNum.endsWith("000") || bankNum.endsWith("300") || bankNum.endsWith("301"))) ? bankNum : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก และลงท้ายด้วยเลข 000, 300, 301',
  "GSB": (bankNum) => (bankNum.length === 12 || bankNum.length === 15) ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 12 หลัก หรือ 15 หลัก',
  "BAC": (bankNum) => (bankNum.length === 10 || bankNum.length === 12) ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก หรือ 12 หลัก',
  "BBL": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "TFB": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "ABN": (bankNum) => bankNum.length === 6 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 6 หลัก',
  "KTB": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "CHB": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "BTK": (bankNum) => bankNum.length === 6 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 6 หลัก',
  "SCB": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "SCI": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "CTB": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "MSB": (bankNum) => bankNum.length === 8 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 8 หลัก',
  "NTB": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "BOA": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "BCH": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "BUA": (bankNum) => bankNum.length === 8 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 8 หลัก',
  "GHB": (bankNum) => bankNum.length === 12 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 12 หลัก',
  "DIJ": (bankNum) => bankNum.length === 11 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 11 หลัก',
  "AIG": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "ACL": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "ISB": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "KKB": (bankNum) => bankNum.length === 14 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 14 หลัก',
  "LHB": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "TCR": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "TIS": (bankNum) => bankNum.length === 14 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 14 หลัก',
  "BAY": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "CIM": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
  "TMB": (bankNum) => bankNum.length === 10 ? false : 'หมายเลขบัญชีธนาคารจะต้องมีความยาว 10 หลัก',
}

const bankNumberTranform = {
  "GSB": (bankNum) => (bankNum.length === 12) ? `999${bankNum}` : bankNum,
  "BAC": (bankNum) => (bankNum.length === 10) ? `0${bankNum}` : bankNum
}

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}><img src="/images/Magnifying.svg" alt="" />{children}</components.ValueContainer>
);

export const ChooseMagType = () => {
  const dispatch = useDispatch();
  const didMount = useRef(false);
  const { profile, cart: cartData, addItem, customer, getStore, pickStore, find, createBank, delivery } = useSelector(state => state);
  const [type, setType] = useState('electronic');
  const [stores, setStores] = useState();
  const [deliveryType, setDeliveryType] = useState();
  const [deliveryStore, setDeliveryStore] = useState();
  const [modalContent, setModalContent] = useState();
  const [bankAccs, setBankAccs] = useState({});
  const [selectedBank, setSelectedBank] = useState();
  const [selectedNewBankAccount, setSelectedNewBankAccount] = useState();
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [images, setImages] = useState([]);

  const [isBankNameEmpty,setIsBankNameEmpty] = useState(false)
  const [isAccountNameEmpty,setIsAccountNameEmpty] = useState(false)
  const [isBranchNameEmpty,setIsBranchNameEmpty] = useState(false)
  const [branchName,setBranchName] = useState('')
  const [isAccountNumberEmpty,setIsAccountNumberEmpty] = useState(false)
  const [accountNumber,setAccountNumber] = useState('')
  const [isImageInputEmpty,setIsImageInputEmpty] = useState(false)

  const customerRef = useRef();
  const sendAvtivity = useCallback((status) => {
    activityService.create({
      uid: customer.uid,
      userId: customer._id,
      status: status,
      action: 'SELECT_MAGAZINE_TYPE',
    });
  });


  useEffect(() => {
    didMount.current = true;
    dispatch(getPickup());
    if (profile.token && !customer.amwayNumber) {
      dispatch(customerFetch(profile.userId));
    } else if (profile.token && customer.amwayNumber && !find.bankAccountDetailList) {
      dispatch(customerFindByNumber(customer.amwayNumber));
    }
    dispatch(customerFetchByNumber(customer.amwayNumber));
  }, []);

  useEffect(() => {
    if (!isEqual(customer, customerRef.current)) {
      if (customer.amwayNumber) {
        if (!find.bankAccountDetailList) {
          dispatch(customerFindByNumber(customer.amwayNumber));
        }
        const { amwayNumber, partyId, uid } = customer;
        dispatch(createUpgradeCart({ amwayNumber, partyId, type: 'member', lineId: uid, renewalType: 'UPGRADE' }));
      } else if (customer.type) {
        handleToggleModal('NOT_FOUND');
      }
    }
  }, [customer]);

  useEffect(() => {
    if (!isArray(find.partyList)) return;
    const partyList = JSON.parse(JSON.stringify(find.partyList))
    if(find.bankAccountDetailList){
        partyList[0].bankAccountDetailList = JSON.parse(JSON.stringify(find.bankAccountDetailList))
    }
    const bankAccountList = getAccountList(partyList);
    setBankAccs(bankAccountList.reduce((accum, curr) => ({
      ...accum,
      [curr.bankAcctId]: curr
    }), {}));
    
    if(bankAccountList.length === 1 && bankAccountList[0].bankAcctHolderName){
      const newBankAccs = { ...bankAccs, [bankAccountList[0].bankAcctId]: { bankAcctHolderName: bankAccountList[0].bankAcctHolderName, bankAcctId: bankAccountList[0].bankAcctId, issuingBankId: '', bankAcctNum: '', bankBranchName: '', editable: true } };
      const acc = {label:bankAccountList[0].bankAcctHolderName,value: bankAccountList[0].bankAcctId}
      setBankAccs(newBankAccs);
      setSelectedBank(acc);
    }

  }, [find]);

  useEffect(() => {
    if (cartData.data && getStore.data) {
      setStores(getStore.data.map(store => ({ label: store.displayName, value: store.name })));
      setLoading(false);
      if (customer.type === 'member') {
        setType('electronic');
        setDeliveryType();
      }
    } else if (cartData.errors) {

      handleToggleModal('CANT_RENEW');
    } else if ((!cartData.data || !getStore.data) && (cartData.statusCode && getStore.statusCode) && (cartData.statusCode !== 200 || getStore.statusCode !== 200)) {
      handleToggleModal('ERROR');
    }
  }, [cartData, getStore]);

  useEffect(() => {
    if (pickStore && pickStore.code) {
      if (pickStore.code === 200) {
        sendAvtivity('complete');
      } else {
        sendAvtivity('incomplete');
        handleToggleModal('ERROR');
      }
    }
  }, [pickStore]);

  useEffect(() => {
    if (delivery && delivery.code) {
      if (delivery.code === 200) {
        if (deliveryType === 'atShop' && deliveryStore && !pickStore.data && !pickStore.code) {
          const currStore = getStore.data.find(s => s.name === deliveryStore.value)
          dispatch(choosePickupStore({ cartCode: cartData.data.code, partyId: customer.partyId, storeId: deliveryStore.value, currStore }));
        } else {
          setRedirect({
            pathname: commonConstant.pathUpgradeConfirm,
            state: {
              customerType: customer.type,
              type,
              deliveryType,
            },
          });
        }
      } else {
        sendAvtivity('incomplete');
        handleToggleModal('ERROR');
      }
    }
  }, [delivery]);

  useEffect(() => {
    if (addItem.data && addItem.data[0].statusCode === 'success' && didMount.current) {
      if (addItem.data && addItem.code === 200) {
        let pickup = null;
        if (deliveryType === 'atShop') {
          pickup = 'pickup';
        } else if (deliveryType === 'currentAddr') {
          pickup = 'free-standard-shipping';
        }
        if (pickup) {
          dispatch(updateDelivery({ cartCode: cartData.data.code, partyId: customer.partyId, pickup: pickup }));
        } 
          setRedirect({
            pathname: commonConstant.pathUpgradeConfirm,
            state: {
              customerType: customer.type,
              type,
              deliveryType,
            },
          });
      } else {
        sendAvtivity('incomplete');
        handleToggleModal('ERROR');
      }
    } else if (addItem.data || (addItem.statusCode && addItem.statusCode !== 200)) {
      sendAvtivity('incomplete');
      handleToggleModal('ERROR');
    }
  }, [addItem]);

  useEffect(() => {
    if(createBank.code){
      if(createBank.code === 200){
        const upgradeCode = type === 'electronic' ? code[type] : code[type][deliveryType]
        dispatch(addUpgradeEntry({ cartCode: cartData.data.code, partyId: customer.partyId, code: upgradeCode }));
      }
      else{
        sendAvtivity('incomplete');
        handleToggleModal('ERROR');
      }
    }
  }, [createBank]);

  useEffect(()=>{
    if(deliveryStore){
      const currStore = getStore.data.find(s => s.name === deliveryStore.value)
      dispatch(choosePickupStore({ cartCode: cartData.data.code, partyId: customer.partyId, storeId: deliveryStore.value, currStore }));
    }
  },[deliveryStore])

  const handleSubmit = () => {

    if (!cartData || !cartData.data) {
      handleToggleModal('CANT_RENEW');
      return;
    }

    setSubmitLoading(true);

    let bankAccSelectedBank = '';
    let bankAcctNum = '';
    let bankBranchName = '';
    let bankBranchCode = '';
    let bankAcctHolderName = '';

    let bankAcc;

    if(selectedBank){
      bankAccSelectedBank = bankAccs[selectedBank.value];
      bankAcctNum = accountNumber ? accountNumber : '';
      bankBranchName = branchName ? branchName : '';
      bankBranchCode = bankAccSelectedBank.bankBranchCode ? bankAccSelectedBank.bankBranchCode : '';
      bankAcctHolderName = bankAccSelectedBank.bankAcctHolderName ? bankAccSelectedBank.bankAcctHolderName : '';

      bankAcc = {
        'amwayNumber': customer.amwayNumber,
        'partyId': customer.partyId,
        'bankAcctId': '',
        'bankAcctTypeCd': 'Saving',
        bankAcctName: bankAcctHolderName,
        bankAcctNum: bankNumberTranform[selectedNewBankAccount.value] ? bankNumberTranform[selectedNewBankAccount.value](bankAcctNum) : bankAcctNum,
        'primaryRoutingNum': '',
        issuingBankId: selectedNewBankAccount.value,
        issuingBankName: selectedNewBankAccount.label,
        'bankBranchCode': bankBranchCode ? bankBranchCode : '',
        'bankBranchName': bankBranchName ? bankBranchName : '',
        'secondaryRoutingNum': '',
        bankAcctHolderName,
        'currencyCd': 'THB',
        'statusFlg': '1',
        'bankAccStatus' : 'INVALID',
        'accountUseCodes': ['BonusPayments'],
        'customFld1': '',
        'customFld2': '',
        'images': images.base64,
      }

      dispatch(createBankAccount(bankAcc));
    }

    if(!bankAcc) return handleToggleModal('ERROR');
    
    const upgradeCode = type === 'electronic' ? code[type] : code[type][deliveryType]

    dispatch(addUpgradeEntry({ cartCode: cartData.data.code, partyId: customer.partyId, code: upgradeCode }));
    
    setRedirect({
      pathname: commonConstant.pathUpgradeConfirm,
      state: {
        customerType: customer.type,
        type,
        deliveryType,
        bankAcc
      },
    });
  };

  const skipToConfirm = () =>{
    const upgradeCode = type === 'electronic' ? code[type] : code[type][deliveryType]

    dispatch(addUpgradeEntry({ cartCode: cartData.data.code, partyId: customer.partyId, code: upgradeCode }));
    setRedirect({
      pathname: commonConstant.pathUpgradeConfirm,
      state: {
        customerType: customer.type,
        type,
        deliveryType,
      },
    });

  }

  const handleToggleModal = (content) => {
    setLoading(false);
    setModalContent(content);
    setShowModal(show => !show);
  };

  const fileSelectedHandeler = event => {
    setIsImageInputEmpty(false)
    setImages(event.target.value);
  };

  const getAllAccName = useCallback(() => {
    const allAcc = [];
    for (const id in bankAccs) {
      if(bankAccs[id].bankAcctHolderName){
        allAcc.push({ label: bankAccs[id].bankAcctHolderName, value: bankAccs[id].bankAcctId });
      }
    }
    return allAcc;
  }, [bankAccs]);

  const formValidate = ()=>{
    let bankName = selectedNewBankAccount; 
    let errorElement;

    if(images.length === 0){
      setIsImageInputEmpty('กรุณาอัพโหลดรูปสำเนาบัญชีธนาคารพร้อมเซ็นรับรอง')
      errorElement = document.getElementById("image-container");
    }

    if(bankName && accountNumber){
      const error = getBankNumberError[bankName.value](accountNumber)
      if(error) {
        setIsAccountNumberEmpty(error);
        errorElement = document.getElementById("number-container");
      }
    }

    if(!accountNumber) {
      setIsAccountNumberEmpty('กรุณาระบุเลขที่บัญชี')
      errorElement = document.getElementById("number-container");
    }

    if(!branchName) {
      setIsBranchNameEmpty('กรุณาระบุชื่อสาขา')
      errorElement = document.getElementById("branch-container");
    }

    if(!bankName) {
      setIsBankNameEmpty('กรุณาเลือกธนาคาร')
      errorElement = document.getElementById("bank-container");
    }


    if(!selectedBank) {
      setIsAccountNameEmpty('กรุณาเลือกชื่อบัญชี')
      errorElement = document.getElementById("account-container");
    }

    if(errorElement) return errorElement.scrollIntoView({block: "center"})

    handleSubmit()

  }

  const prefixBankNumber = () => {
    if(selectedBank && selectedNewBankAccount){
      let bankAcctNumber = bankAccs[selectedBank.value] ? bankAccs[selectedBank.value].bankAcctNum : '';
      let newBankNum = bankNumberTranform[selectedNewBankAccount.value] ? bankNumberTranform[selectedNewBankAccount.value](bankAcctNumber) : bankAcctNumber;
      const editedAcc = { ...bankAccs[selectedBank.value], bankAcctNum: newBankNum};
      const newBankAccs =  { ...bankAccs, [selectedBank.value] : editedAcc};
      setBankAccs(newBankAccs);
    }
  };

  const handleBankAccSelect = (acc, actionMeta) => {
    setIsAccountNameEmpty(false)
    if (actionMeta.action === 'create-option') {
      const newId = Date.now().toString();
      const newBankAccs = { ...bankAccs, [newId]: { bankAcctHolderName: acc.value, bankAcctId: newId, issuingBankId: '', bankAcctNum: '', bankBranchName: '', editable: true } };
      setBankAccs(newBankAccs);
      setSelectedBank({ label: acc.value, value: newId });
    } else if (acc && String(acc.value).includes('new')) {
      const newBankAccs = { ...bankAccs, [acc.value]: { bankAcctHolderName: acc.label, bankAcctId: acc.value, issuingBankId: '', bankAcctNum: '', bankBranchName: '', editable: true } };
      setBankAccs(newBankAccs);
      setSelectedBank(acc);
    } else if (acc && acc.value !== null) {
      setSelectedBank(acc);
    } else {
      setSelectedBank(null);
    }
  };

  const handleBankSelect = (acc, actionMeta) => {
    setIsBankNameEmpty(false)

    if (actionMeta.action === 'create-option') {
      const editedAcc = { ...bankAccs[selectedBank.value], issuingBankName: acc.value, issuingBankId: acc.value };
      const newBankAccs = { ...bankAccs, [selectedBank.value]: editedAcc };
      setBankAccs(newBankAccs);
      prefixBankNumber();
    } else {
      const newSelectedBank = banksName.filter(b => b.value === acc.value)[0]

      setSelectedNewBankAccount({ ...newSelectedBank })
      prefixBankNumber();
    }
  };

  const handleEditBranch = (e) => {
   setIsBranchNameEmpty(false)
   setBranchName(e.target.value)
  };

  const handleEditAccountNumber = (e)=>{
    const regex = /^(\s*|\d+)$/;
    if (!e.target.value.match(regex)) return;
    
    setIsAccountNumberEmpty(false)
    setAccountNumber(e.target.value)
  }


  const handleMouseOutEditBank = (e) => {
    prefixBankNumber();
  }

  const handleModalContent = () => {
    switch (modalContent) {
      case 'ERROR':
        return {
          content: 'เกิดข้อพิดพลาด กรุณาลองใหม่ในภายหลัง',
          type: 'danger',
          cancleBtn: false,
          okBtn: true,
          okText: 'ปิด',
          fnOk: () => liff.closeWindow(),
        };
      case 'CANT_RENEW':
        return {
          content: <> กรุณาเช็คสถานะการต่ออายุได้ที่เมนูบัญชีของฉัน<br />
            <a href={`https://liff.line.me/${process.env.REACT_APP_CLIENT_ID}?seamless=/my-account`}>https://www.amway.co.th/my-account</a><br />
                  หรือติดต่อ Amway Contact Center <a href="tel:02725800">02725800</a></>,
          type: 'danger',
          cancleBtn: false,
          okBtn: true,
          okText: 'โทร',
          fnOk: () => window.location = 'tel:027258000',
        };
      case 'NOT_FOUND':
        return {
          content: 'ไม่พบข้อมูลสมาชิก กรุณาติดต่อ 02-725-8000',
          type: 'danger',
          cancleBtn: false,
          okBtn: true,
          okText: 'โทร',
          fnOk: () => window.location = 'tel:027258000',
        };
      case 'INVALID_BANK_ACC_NUMBER':
        return {
          content: 'เลขที่บัญชีไม่ถูกต้อง กรุณากรอกใหม่อีกครัง',
          type: 'danger',
          cancleBtn: false,
          okBtn: true,
          okText: 'ตกลง',
          fnOk: () => { setShowModal(show => false); setSubmitLoading(false); }
        };
      case 'SKIP_BANK':
        return{
          content:(<p style={{whiteSpace:'pre-line',fontSize:'14px',fontWeight:'bold'}}>คุณสามารถส่งสำเนาหน้าสมุดบัญชีธนาคาร<br/>ผ่านทาง Line Official: Amway Thailand<br/>หรือทางอีเมล<br/><a style={{color:'#38539A'}} href = "mailto: th_sendbankbook@amway.com">th_sendbankbook@amway.com</a>
          </p>),
          closeBtn:true,
          okBtn: true,
          okText: 'ตกลง',
          fnOk: () => skipToConfirm() 
        }
      default:
        return;
    }
  };

  const handleRenderBody = () => (
    <div>
      <Collapse title={
        <Checkbox checked={type === 'electronic'} checkmarkColor={variables.success}>
          <div class='book-type-title-width-recommend'>
          <div class='book-type-title'>ชุด E - Starter Kit</div>
          <div class='book-type-recommend'>แนะนำ</div>
          </div>
        </Checkbox>
      } standalone isCollpased={type !== 'electronic'} onClick={() => {
        setType('electronic');
        setDeliveryType();
      }}
      titleClass="full-width"
      hideSuffix
      >
        <img style={{ width: '100%', minHeight: '100px' }} src="/images/upgradeMember/estarter.png" />
            <p class='book-type-subtitle'>ข้าพเจ้ายอมรับ <span className="link" style={{ fontWeight:'600'}} onClick={() => setShowConsent(true)}>เงื่อนไข E-Starter Kit</span> สำหรับนักธุรกิจ</p>
      </Collapse>
      <Collapse title={
        <Checkbox checked={type === 'physical'} checkmarkColor={variables.success}>
          <div class='book-type-title'>รับเป็นรูปเล่ม</div>
          <p class='book-type-subtitle'>ชุดคู่มือนักธุรกิจแอมเวย์จะจัดส่งให้ตามที่อยู่ปัจจุบัน</p>
        </Checkbox>
      } standalone isCollpased={type !== 'physical'} onClick={() => {
        setType('physical')
        setDeliveryType('currentAddr')
      }}
      hideSuffix
      >
        <div>กรุณาเลือกวิธีจัดส่ง</div>
        <div>
          <input className="radio-input" id="currentAddr" type="radio" checked={deliveryType === 'currentAddr'} onClick={() => setDeliveryType('currentAddr')} onChange={() => { }} />
          <label htmlFor="currentAddr">จัดส่งที่อยู่ที่บ้าน ( ที่อยู่ปัจจุบัน )</label>
        </div>
        <div>
          <input className="radio-input" id="atShop" type="radio" checked={deliveryType === 'atShop'} onClick={() => setDeliveryType('atShop')} onChange={() => { }} />
          <label htmlFor="atShop">รับด้วยตนเอง</label>
          <Select classNamePrefix="react-select" options={stores} menuPortalTarget={document.querySelector('.choosemag-container')} menuShouldScrollIntoView={true}
            onChange={(e) => setDeliveryStore(e)}
            placeholder="ค้นหาร้านค้าแอมเวย์"
            className={deliveryType === 'atShop' ? 'visible' : 'hide'}
            components={{
              ValueContainer,
            }}
            value={deliveryStore}
            styles={{
              indicatorsContainer: styles => ({ ...styles, display: 'none' }),
              menu: styles => ({ ...styles, top: 0, marginTop: '5px' }),
              control: styles => ({
                ...styles,
                borderRadius: '50px',
                boxShadow: 'none',
                marginTop: '5px'
              }),
              placeholder: styles => ({ ...styles, marginLeft: '20px', maxWidth: 'calc(100% - 32px)' }),
              singleValue: styles => ({ ...styles, marginLeft: '20px', maxWidth: 'calc(100% - 32px)' }),
              input: styles => ({ ...styles, marginLeft: '7px' }),
            }} />
        </div>
      </Collapse>
    </div>
  );

  const content = handleModalContent();

  if (loading) {
    return <div> <LoadingOverlay
      active={true}
      spinner
      text="Loading"
      styles={{ wrapper: { width: '100%', height: '100%', position: 'absolute', pointerEvents: 'none', top: 0, left: 0 } }}
    /></div>;
  }
  if (showConsent) {
    return <PaymentConsent onClick={() => setShowConsent(false)} />;
  } else if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (

    <div className="choosemag-container">
      {
        submitLoading && <LoadingOverlay
          active={true}
          spinner
          text="Loading"
          styles={{ wrapper: { width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 10 } }}
        />
      }
      {
        showModal && <Modal
          title={content.title}
          content={content.content}
          type={content.type}
          fnClose={() => setShowModal(false)}
          fnOk={content.fnOk}
          fnCancel={content.cancleBtn ? () => setShowModal(false) : null}
          closeBtn={content.closeBtn}
          okBtn={content.okBtn}
          okText={content.okText}
          cancleText="ยกเลิก" />
      }
      <div className="header">
        <div className="title">เลือกรูปแบบชุดคู่มือ</div>
      </div>
      <div className="body">
        {handleRenderBody(customer.type)}
        <div className="add-bank-container">
          <hr />
          <div className="header">เพิ่มบัญชีเพื่อรับโบนัสส่วนลด</div>
          <Card>
            <div className="input-container" id='account-container'>
              <label className={isAccountNameEmpty ? 'input-invalid' : undefined}>ชื่อบัญชี</label>
              <CreatableSelect
                id='account-input'
                className={isAccountNameEmpty ? 'input-invalid' : undefined}
                isSearchable
                isClearable
                classNamePrefix="react-select"
                onKeyDown={e => !e.target.value && e.key === 'Backspace' ? setSelectedBank(null) : null}
                value={selectedBank}
                options={getAllAccName()}
                menuPortalTarget={document.querySelector('.choosemag-container')}
                menuShouldScrollIntoView={true}
                onChange={handleBankAccSelect}
                placeholder="เลือกชื่อบัญชี"
                styles={{
                  menu: styles => ({ ...styles, top: 0, marginTop: '5px' }),
                  control: styles => ({
                    ...styles,
                    border: 'none',
                    borderBottom: '1px solid #2c2c2c',
                    background: 'hsl(0,0%,100%)',
                    borderRadius: '0',
                    boxShadow: 'none',
                    marginTop: '5px'
                  }),
                  input: styles => ({ ...styles, marginLeft: '7px' }),
                }} />
              <label>ชื่อของคุณและชื่อบัญชีธนาคารต้องเป็นชื่อเดียวกัน</label>
              { isAccountNameEmpty && <Fragment><br/><label className='input-invalid'>{isAccountNameEmpty}</label></Fragment>}

            </div>
            <div className="input-container" id="bank-container">
              <label className={isBankNameEmpty ? 'input-invalid' : undefined}>ธนาคาร</label>
              <Select
                className={isBankNameEmpty ? 'input-invalid' : undefined}
                classNamePrefix="react-select"
                value={selectedNewBankAccount ? selectedNewBankAccount : ''}
                options={banksName}
                menuPortalTarget={document.querySelector('.choosemag-container')}
                menuShouldScrollIntoView={true}
                onChange={handleBankSelect}
                placeholder="เลือกธนาคาร"
                styles={{
                  menu: styles => ({ ...styles, top: 0, marginTop: '5px' }),
                  control: styles => ({
                    ...styles,
                    border: 'none',
                    borderBottom: '1px solid #2c2c2c',
                    background: 'hsl(0,0%,100%)',
                    borderRadius: '0',
                    boxShadow: 'none',
                    marginTop: '5px'
                  }),
                  input: styles => ({ ...styles, marginLeft: '7px' }),
                }} />
              { isBankNameEmpty && <label className='input-invalid'>{isBankNameEmpty}</label>}
            </div>
            <div className="input-container" id="branch-container">
              <label className={isBranchNameEmpty ? 'input-invalid' : undefined}>สาขา</label>
              <input type="text"
              className={isBranchNameEmpty ? 'input-invalid' : undefined}
                placeholder="กรุณาระบุสาขา"
                id="bankBranchName"
                value={branchName}
                onChange={handleEditBranch}
                 />
                { isBranchNameEmpty && <label className='input-invalid'>{isBranchNameEmpty}</label>}
            </div>
            <div className="input-container" id="number-container">
              <label className={isAccountNumberEmpty ? 'input-invalid' : undefined}>เลขที่บัญชี</label>
              <input
              className={isAccountNumberEmpty ? 'input-invalid' : undefined}
              type="tel" pattern="[0-9]*"
                placeholder="กรุณาระบุเลขที่บัญชี"
                id="bankAcctNum"
                value={accountNumber}
                onChange={handleEditAccountNumber} 
                onMouseOut={handleMouseOutEditBank}/>
                { isAccountNumberEmpty && <label className='input-invalid'>{isAccountNumberEmpty}</label>}
            </div>
            <div className="description">
              อัพโหลดสำเนาหน้าสมุดบัญชีธนาคาร โดยต้องเขียน
              รับรองสำเนาถูกต้องพร้อมลายเซ็นต์
            </div>
            <div className="images-upload-container" style={{
              display: 'block'
            }} id="image-container">
              <div className="images">
              </div>
              <ImageUpload id="image-upload"
                exampleImage="/images/upgradeMember/example_img.png"
                onChange={fileSelectedHandeler} value={images}
                closeIcon="/images/Close-white.svg"
                label={<img className="preview-image" src="/images/upgradeMember/plus.png" />} />
              <label className="upload-btn" htmlFor="image-upload">อัพโหลด</label>
              {
                images.image && images.image.name ? <span className="image-name-text">{images.image.name}</span> :
                  <span className="upload-text">ขนาดรูปไม่ควรเกิน 5 MB</span>
              }
              {isImageInputEmpty && <Fragment><br/><label className='input-invalid'>{isImageInputEmpty}</label></Fragment>}
            </div>
          </Card>
          {(isBankNameEmpty || isAccountNameEmpty || isBranchNameEmpty || isAccountNumberEmpty || isImageInputEmpty) && 
          <div className='invalid-warning-container'>
            <p className='invalid-warning-text'>**กรุณาระบุข้อมูลให้ครบถ้วน**</p>
        </div>}
          
          <div className="submit-button-container">
          <button 
          className={`away-button active`} 
          onClick={formValidate}
          >ยืนยัน</button>
        </div>
          <div className="bottom-text" onClick={()=> handleToggleModal('SKIP_BANK')}>ข้ามขั้นตอนนี้และให้ข้อมูลบัญชีธนาคารภายหลัง {'>'}</div>
        </div>
      </div>
    </div>
  );
};



const PaymentConsent = ({ onClick }) => (
  <div className="payment-consent-container">
    <ConditionEBook />
    <div className="card-footer">
      <button className="away-button active" onClick={onClick}>กลับ</button>
    </div>
  </div>
);

