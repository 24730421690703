import React from 'react';
import { HNW_FOOD_LOG } from '../../../../../../common/commonConstant';
import {
  calculateCaloriesByWeight,
  formatNumberWithCommas,
} from '../../../../../../utill.func';

const MealItem = ({ items, totalCalories, handleItemClick }) => {
  return (
    <>
      <div className="food-overview-calc-progress">
        <span>รวม:</span> <span>{formatNumberWithCommas(Math.round(totalCalories))}</span>{' '}
        <span>แคลอรี</span>
      </div>
      <div className="dotted-separtor"></div>
      {items.map((el, index) => {
        const noImgClassPrefix = 'food-overview-meal-list-col-1--no-image';
        const noImgClass =
          HNW_FOOD_LOG.categoryMapping[el.productCategory] === 'supplement'
            ? `${noImgClassPrefix}-supplement`
            : `${noImgClassPrefix}-food`;
        return (
          <div
            key={`${el.type}${index}`}
            onClick={() => handleItemClick(el)}
            className="food-overview-meal-list"
          >
            <div className="food-overview-meal-list-single">
              <div className="food-overview-meal-list-col-1-container">
                <div className="food-overview-meal-list-col-1">
                  <img
                    className={`${
                      !el.imageUrl ? noImgClass : ''
                    } food-overview-meal-list-col-1--image `}
                    src={el.imageUrl}
                    alt="food-img"
                  />
                </div>
                <div className="food-overview-meal-list-col-2">
                  <div className="food-overview-meal-list-title">{el.name}</div>
                  <div className="food-overview-meal-list-quantity">
                    {el.myFoodWeight} {HNW_FOOD_LOG.unitMapping['grams']}
                  </div>
                </div>
              </div>

              <div className="food-overview-meal-list-col-3">
                <div className="food-overview-meal-calories-value">
                  {calculateCaloriesByWeight(el)}
                </div>
                <div className="food-overview-meal-calories-title">
                  {HNW_FOOD_LOG.unitMapping['calories']}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MealItem;
