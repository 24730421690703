import React from 'react';
import BmiBar from '../../../../common/bmiBar/BmiBar';
import './ChallengeMyBmiData.scss';
import { handleBmiBadges } from '../../../../utill.func';
import { routesMap } from '../../../../common/commonConstant';

const ChallengeMyBmiData = ({
  bmiHeading,
  bmiThaiHeading,
  bmiValue,
  teamDashboardStatus,
  routeTo,
}) => {
  const bmiClassname = handleBmiBadges(bmiValue)?.classname;
  const bmiTitle = handleBmiBadges(bmiValue)?.bmiTitle;

  const handleRoute = () => routeTo && routeTo(routesMap?.bmi);

  return (
    <div className="challenge-my-bmi-container">
        <div className="challenge-card-body" onClick={handleRoute}>
          <div className="card-ongoing-bmi-details">
            {bmiThaiHeading ? (
              <>
                <span className="bmi-heading">{bmiHeading}</span>
                <span className="bmi-heading-thai">{bmiThaiHeading}</span>
              </>
            ) : (
              <span>{bmiHeading}</span>
            )}
          </div>
          <BmiBar
            bmiValue={bmiValue}
            teamDashboardStatus={teamDashboardStatus}
            bmiClassname={bmiClassname}
            bmiTitle={bmiTitle}
          />
        </div>
    </div>
  );
};

export default ChallengeMyBmiData;
