import React from "react";
import "./BadgeUserDetailLoader.scss";

const BadgeUserDetailLoader = () => {
  return (
    <div className="badge-user-detail-container">
    <div className="steps-skeleton-container">
    <div className="steps-skeleton-card-container">
      <div className="skeleton-card-image skeleton"></div>
    </div>
  </div>
  </div>
  );
};

export default BadgeUserDetailLoader;
