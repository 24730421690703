export const selfChallengeData = [
    {
        imgUrl: '/images/selfChallengeDummy/selfchallenge-cycling.png',
        challengeType: 'ประเภทเดี่ยว',
        challengeName: 'ปั่นจักรยาน',
        date: 'เหลืออีก 15 วัน'
    },
    {
        imgUrl: '/images/selfChallengeDummy/selfchallenge-running.png',
        challengeType: 'ประเภทเดี่ยว',
        challengeName: 'วิ่ง',
        date: 'เหลืออีก 15 วัน'
    },
    {
        imgUrl: '/images/selfChallengeDummy/selfchallenge-cycling.png',
        challengeType: 'ประเภทเดี่ยว',
        challengeName: 'ปั่นจักรยาน',
        date: 'เหลืออีก 15 วัน'
    },
    {
        imgUrl: '/images/selfChallengeDummy/selfchallenge-running.png',
        challengeType: 'ประเภทเดี่ยว',
        challengeName: 'วิ่ง',
        date: 'เหลืออีก 15 วัน'
    }
]