import React from 'react';

export default function ExerciseMenuHeader({ name, image, templateImg }) {
  return (
    <div className="excercise-details-title-wrapper">
      <img src={templateImg} className="excercise-details-card-bg" alt="template-banner-image" />
      <div className="excercise-details-card">
        <img src={image} />
      </div>
      <div className="excercise-details-title">{name}</div>
    </div>
  );
}
