import { debounce } from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fetchChallengeTeamDetail, fetchUserDetailsData } from "../../../actions";
import { ChallengeHeader, ChallengeNavButton, ChallengeProgressBar, commonConstant } from "../../../common";
import { ALL_STEPS_BADGES, CHALLENGE, CHALLENGE_MAP_DETAIL_MAPPING, CHALLENGE_STEPS_FOOD_KEY_MAPPING, OTHER_BADGES_MAPPING, USER_OR_TEAM_MEDALS } from "../../../common/challengeConstants";
import { filterActiveInactiveImages, getDataFromSession, getMissionBadgesRange, getParamsString, getStepsBadgesRange, removeDisableForRedirection, updateStepsBadgeList } from "../../../utill.func";
import './ChallengeBadgeScreen.scss';
import { MissionBadgesLoader, StepsBadgeLoader } from './index';

const ChallengeBadgeScreen = () => {
    const dispatch = useDispatch();
    const customer = getDataFromSession('customer');
    const location = useLocation();
    const history = useHistory();
    const params = queryString.parse(location.search);
    const partyId = params['partyId'] || '';
    const eventId = params['eventId'];
    const [userData, setUserData] = useState([]);
    const [isHeaderSticky, setIsHeaderSticky] = useState(false);
    const [activeIndex, setActiveIndex] = useState();
    const [badgeList, setBadgeList] = useState([]);
    const [badgesData, setBadgesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stepsBadgeCount, setStepsBadgeCount] = useState(0);
    const [stepsValue, setStepsValue] = useState(0);
    const [backRedirection, setBackRedirection] = useState('');
    const [missionBadgesCount, setMissionBadgesCount] = useState(0);
    const [isNavSticky, setIsNavSticky] = useState(false);
    const { teamData, userDetail } = useSelector((state) => state.challengeTeamDashboard);
    const challengeId = params['challengeId'] || teamData?.eventId;
    const isTeamBadge = params['isTeam'];

    sessionStorage.setItem('badgeDetail', JSON.stringify('badge-screen'));
    const fromTeamDashboard = getDataFromSession('badgeScreen');

    useEffect(() => {
        const didMount = () => {
            if (!teamData?.challengePartyIds && !teamData?.teamName) {
                getChallengeTeamDashboardData();
            }
        }
        didMount();
    }, []);

    const getChallengeTeamDashboardData = async () => {
        try {
            setLoading(true);
            await dispatch(fetchChallengeTeamDetail({ amwayNumber: customer?.amwayNumber, partyId: customer?.partyId, challengeId }));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    useEffect(() => {
        const didMount = () => {
            if (teamData?.challengePartyIds && userDetail.length == 0) {
                getChallengeUserProfileData();
            }
        }
        didMount();
    }, [teamData?.challengePartyIds]);

    const getChallengeUserProfileData = async () => {
        if (teamData?.challengePartyIds) {
            await dispatch(fetchUserDetailsData(teamData?.challengePartyIds));
        }
    }

    useEffect(() => {
        const filterUserData = teamData?.users?.find((user) => user?.partyId === partyId);
        filterUserData && setUserData(filterUserData);
    }, [])

    useEffect(() => {
        const handleScroll = debounce(() => {
            const windowTop = window.scrollY;
            setIsHeaderSticky(windowTop > 30);
            setIsNavSticky(windowTop > 30);
        }, 20);

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const titleHide = isNavSticky ? 'title-hide' : '';

    const _getUserData = useCallback(() => {
        const stepsFoodKeys = Object.keys(CHALLENGE_STEPS_FOOD_KEY_MAPPING);
        const missionData = stepsFoodKeys.reduce((acc, key) => {
            acc[key] = teamData?.missionUserData?.[key]?.userData?.find(user => user?.partyId === partyId);
            return acc;
        }, {});
        const stepsBadgeCount = missionData?.steps?.data?.badges_count;
        setStepsBadgeCount(stepsBadgeCount);
        setStepsValue(missionData?.steps?.data?.value);
        setBadgeList(updateStepsBadgeList(ALL_STEPS_BADGES, stepsBadgeCount));
        const otherBadgesData = stepsFoodKeys.reduce((acc, key) => {
            acc[key] = teamData?.otherBadgeData?.[key]?.allUsersData?.find(user => user?.partyId === partyId);
            return acc;
        }, {});
        setBadgesData(otherBadgesData);
        const otherBadgesCount = stepsFoodKeys.reduce((acc, key) => {
            acc[key] = teamData?.otherBadgeData?.[key]?.allUsersData?.filter(user => user?.badgeReceived);
            return acc;
        }, {});
        setMissionBadgesCount(otherBadgesCount)
    }, [teamData, partyId]);

    useEffect(() => {
        _getUserData();
    }, [_getUserData]);

    useEffect(() => {
        if (isTeamBadge !== undefined) {
            return isTeamBadge == 'true' ? setActiveIndex(1) : setActiveIndex(0);
        } else {
            return fromTeamDashboard == "team-dashboard" ? setActiveIndex(1) : setActiveIndex(0);
        }
    }, [isTeamBadge])

    useEffect(() => {
        if (fromTeamDashboard == 'team-dashboard') {
            setBackRedirection(commonConstant.pathChallengeTeamDashboard);
        } else {
            setBackRedirection(commonConstant.pathChallengeMyDashboard);
        }
    }, [fromTeamDashboard])

    const countUsersWithStepsBadges = (key) => {
        const usersWithStepsBadges = teamData?.stepsBadgeData?.[removeDisableForRedirection(key)]?.allUsersData?.filter(data => data?.badgeReceived)
        return usersWithStepsBadges?.length;
    }

    const countUsersWithMissionBadges = (key) => {
        const usersWithMissionBadges = missionBadgesCount?.[key]?.length;
        return usersWithMissionBadges;
    }

    const userMissionBadgeValue = (key) => {
        return badgesData[key]?.badgeReceived;
    }

    const handleTabButtonChange = (index, value) => {
        setActiveIndex(index);
        window.scrollTo({
            top: 0,
            left: 0,
          });
    };

    const checkActiveIndex = () => {
        if (isTeamBadge !== undefined) {
            return isTeamBadge == 'true' ? 1 : 0;
        } else {
            return fromTeamDashboard == 'team-dashboard' ? 1 : 0;
        }
    }

    const handleBackRedirection = () => {
        const params = {
            challengeId: challengeId,
            eventId: eventId,
            partyId: partyId,
        }
        return history.push(`${backRedirection}?${getParamsString(params)}`);
    }

    const handleHomeRedirection = () => {
        return history.push(commonConstant.pathHealthnWellness);
    }

    const handleBadgeDetailRedirection = (selectedBadge, type) => {
        const params = {
            challengeId: challengeId,
            eventId: eventId,
            partyId: partyId,
            badge: removeDisableForRedirection(selectedBadge),
            type: type,
        }
        activeIndex == 1 && Object.assign(params, { isTeam: true });
        return history.push(`${commonConstant.pathChallengeBadgeDetail}?${getParamsString(params)}`);
    }

    const renderHeader = () => {
        return (
            <div className={`badges-header-wrapper`}>
                <ChallengeHeader leftIcon={'/images/challenge/arrow-black.svg'} leftIconRedirect={() => handleBackRedirection()}
                    rightIcon={'/images/challenge/challenge-home.svg'} rightIconRedirect={() => handleHomeRedirection()}
                    isHeaderSticky={isHeaderSticky} />
            </div>
        )
    }

    const handleProgressOrTeamTotalBadges = (value, index, minValueOfBadge) => {
        return (
            <>
                {activeIndex == 1 ?
                    <div className="progress-count-container">
                        {countUsersWithStepsBadges(value) > 0 ?
                            <div className="user-badge">
                                <img src='/images/challenge/icons/icon-single-user.svg' className="user-icon" />
                                <span className="badge-count">{countUsersWithStepsBadges(value)}</span>
                            </div> : ''
                        }
                    </div>
                    : activeIndex == 0 ?
                        index == stepsBadgeCount &&
                        <ChallengeProgressBar percentage={getStepsBadgesRange(stepsValue, minValueOfBadge)} />
                        : ''}
            </>
        )
    }

    const renderStepBadgesSection = () => {
        return (
            <div className="my-coins-container">
                <h4 className="my-coins-category-title">
                    {CHALLENGE.myDashboard.fitPerformer}
                </h4>
                <div className="my-all-badges-container">
                    {badgeList.map((value, index) => {
                        return (
                            <div className={`my-badges-single ${activeIndex == 1 ? 'team-height' : ''}`} key={index}>
                                <div className='img-badge-container'>
                                    <img src={`/images/challenge/badges/bg-badge@5x.png`} />
                                    <img className="badge-img" src={`/images/challenge/badges/steps-${value}-badge@5x.png`} alt="" onClick={() => {
                                        handleBadgeDetailRedirection(value, 'steps');
                                    }}
                                    />
                                </div>
                                <p>{CHALLENGE_MAP_DETAIL_MAPPING[value].enTitle}</p>
                                {handleProgressOrTeamTotalBadges(value, index, CHALLENGE_MAP_DETAIL_MAPPING[badgeList[stepsBadgeCount]]?.minValueOfBadge)}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const showOthersCount = (badgeValue) => {
        if (countUsersWithMissionBadges(badgeValue) > 0) {
            return (
                <div className="user-badge">
                    <img src='/images/challenge/icons/icon-single-user.svg' className="user-icon" />
                    <span className="badge-count">{countUsersWithMissionBadges(badgeValue)}</span>
                </div>
            )
        } else {
            return null
        }
    }

    const renderDietAndLearnerBadges = (firstBadgeImg, firstBadgeHeading, badgeValue) => {
        return (
            <div className="my-all-badges-container">
                <div className="my-badges-single">
                    <img src={`/images/challenge/badges/bg-circle-badge@5x.png`} className='badge-img-bg' />
                    <img src={firstBadgeImg} alt="" className='badge-image' onClick={() => {
                        handleBadgeDetailRedirection(badgeValue, 'others')
                    }}
                    />
                    <p>{firstBadgeHeading}</p>
                    {activeIndex == 1 ?
                        <div className="progress-count-container">
                            {showOthersCount(badgeValue)}
                        </div>
                        :
                        activeIndex == 0 && badgesData[badgeValue]?.totalValue < OTHER_BADGES_MAPPING[badgeValue]?.maxValueOfBadge ?
                            <ChallengeProgressBar percentage={getMissionBadgesRange(badgesData[badgeValue]?.totalValue, badgeValue)} />
                            : ''
                    }
                </div>
            </div>
        )
    }

    const renderLearnerSection = () => {
        return (
            <div>
                <h4 className="my-mission-badges-title">
                    {CHALLENGE.badges.smartLearnerHeading}
                </h4>
                <div className="my-mission-badges-container">
                    {renderDietAndLearnerBadges(
                        filterActiveInactiveImages(userMissionBadgeValue('vdo'), 'vdo'),
                        CHALLENGE.myDashboard.bestLearner,
                        'vdo',
                    )
                    }
                    {renderDietAndLearnerBadges(
                        filterActiveInactiveImages(userMissionBadgeValue('quiz'), 'quiz'),
                        CHALLENGE.myDashboard.topLearner,
                        'quiz',
                    )}
                </div>
            </div>
        )
    }

    const renderDietPlannerSection = () => {
        return (
            <div>
                <h4 className="my-mission-badges-title">
                    {CHALLENGE.badges.dietPlanner}
                </h4>
                <div className="my-mission-badges-container">
                    {renderDietAndLearnerBadges(
                        filterActiveInactiveImages(userMissionBadgeValue('food'), 'food'),
                        CHALLENGE.badges.perfectWorkout,
                        'food',
                    )}
                    {renderDietAndLearnerBadges(
                        filterActiveInactiveImages(userMissionBadgeValue('calorie'), 'calorie'),
                        CHALLENGE.myDashboard.goalKeeper,
                        'calorie',
                    )}
                </div>
            </div>
        )
    }

    return (
        <>
            {renderHeader()}
            <div className="my-badge-container">
                <h4 className={`my-badge-title ${titleHide}`}>{CHALLENGE.badges.heading}</h4>
                <div className={`nav-container ${titleHide}`}>
                    <ChallengeNavButton tabList={USER_OR_TEAM_MEDALS} onTabButtonChange={handleTabButtonChange} isNavSticky={isNavSticky} selectedIndex={checkActiveIndex()} />
                </div>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade active show" id="my-coins-tab" role="tabpanel"
                        aria-labelledby="my-coins-tab">
                        {loading ? <StepsBadgeLoader /> : renderStepBadgesSection()}
                        {loading ? <MissionBadgesLoader /> : renderLearnerSection()}
                        {loading ? <MissionBadgesLoader /> : renderDietPlannerSection()}
                    </div>

                </div>
            </div>
        </>
    )
}

export default ChallengeBadgeScreen;