import config from '../config';
import axios from 'axios'

export const renewalService = {
  updateDeliveryAddress,
};

async function updateDeliveryAddress(payload) {
  const result = await axios.post(`${config.API_URL}/renewal/updateDeliveryAddress`, payload)

  return result.data
}