import { getUserProfileUrl } from '../utill.func';

export class ChallengeProfile {
  constructor(data = null) {
    if (data) {
      this.id = data._id || data.id || '';
      this.name = data.name || '';
      this.type = data.type || '';
      this.slug = data.slug || '';
      this.startDate = data.start_date || null;
      this.endDate = data.end_date || null;
      this.maxTeamSize = data.maxTeamSize || null;
      this.teamInfo = new TeamInfo(data.team_info) || null;
      this.usersInfo = Array.isArray(data.users_info) 
      ? data.users_info.map(user => new UsersInfo(user))
      : null;
    }
  }
}

export class TeamInfo {
  constructor(data = null) {
    if(data) {
      this.id = data.id || null;
      this.eventId = data.event_id || null;
      this.name = data.name || null;
      this.nameSystem = data.name_system || null;
      this.isActive = data.is_active || null;
      this.teamSize = data.team_size || null;
      this.challengeDate = data.challenge_date|| null;
      this.approveDate = data.approve_date|| null;
      this.completeTeamIn = data.complete_team_in|| null;
      this.complete60Date = data.complete60_date|| null;
      this.weightoutDate = new WeightoutDate(data.weightout_date) || null;
      this.endChallenge = data.end_challenge|| null;
    }
  }
}

export class WeightoutDate {
  constructor(data = null) {
    if(data) {
      this.startDate = data.start_date || "";
      this.endDate = data.end_date || "";
      this.range = data.range || "";
    }
  }
}

export class UsersInfo {
    constructor(data = null) {
        if (data) {
            this.id = data.id || null;
            this.aboId = data.abo_id || null;
            this.partyId = data.party_id || null;
            this.teamId = data.team_id || null;
            this.eventId = data.event_id || null;
            this.isLeader = data.is_leader || null;
            this.profilePic = getUserProfileUrl(data.profilePic, this.partyId)
        }
    }
}
