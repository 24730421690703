import React, { useEffect } from "react";
import { useState } from "react";
import { ChallengeHeader, commonConstant } from "../../../../common";
import { DASHBOARD_HEADER, QUIZ_HEADER } from "../../../../common/challengeConstants";
import './ChallengeMyDashboardHeader.scss';

const ChallengeMyDashboardHeader = ({ handleTeamDashboardRedirection, handleHomeRedirection}) => {

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const windowTop = window.scrollY;
            setIsSticky(windowTop > 30);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    return (
        <div className={`my-dashboard-header-wrapper ${isSticky ? 'sticky' : ''}`}>
            <ChallengeHeader
                leftIcon={DASHBOARD_HEADER.LEFT_ICON}
                badgeClassname={null}
                challengeDate={null}
                rightIcon={QUIZ_HEADER.RIGHT_ICON}
                isHeaderSticky={isSticky}
                leftIconRedirect={handleHomeRedirection}
                rightIconRedirect={handleTeamDashboardRedirection}
                />
        </div>
    )
}

export default ChallengeMyDashboardHeader;