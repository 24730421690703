import AxiosLibrary from '../../../config/config.axios';
import config from '../../../config';

export const getCustomerByToken = async () => {
  const customer = await AxiosLibrary.get(`${config.API_URL}/customers`);
  return customer.data;
};

export const getAmwayCategories = async () => {
  try {
    const { data } = await AxiosLibrary.get(
      `${config.API_URL}/library/amway/categories`,
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const createAboCategory = async (body) => {
  try {
    const { data } = await AxiosLibrary.post(
      `${config.API_URL}/library/category`,
      body,
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const updateAboCategory = async (id, body) => {
  try {
    const { data } = await AxiosLibrary.put(
      `${config.API_URL}/library/category/${id}`,
      body,
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const deleteAboCategory = async (id) => {
  try {
    const { data } = await AxiosLibrary.delete(
      `${config.API_URL}/library/category/${id}`,
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const uploadImageLibaryCategory = async (body) => {
  try {
    const { data } = await AxiosLibrary.post(
      `${config.API_URL}/library/image/upload`,
      setFormData(body),
    );

    return data;
  } catch (error) {
    throw error.response;
  }
};

export const getAboCategories = async () => {
  try {
    const { data } = await AxiosLibrary.get(
      `${config.API_URL}/library/categories`,
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

const setFormData = (body) => {
  const formData = new FormData();
  formData.append('imageFile', body.image);
  formData.append('name', body.name);

  return formData;
};
