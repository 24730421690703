import React from 'react';
import LinearRowContent from './LinearRowContent';
import { formatNumberWithCommas } from '../../../../../utill.func';

export default function HnwWaterGoalCardBody({ type, content, healthGoal }) {
  return (
    <div className="card-body-section-water">
      <LinearRowContent
        type={type}
        content={content}
        healthGoalValue={healthGoal?.targetWater}
      />
    </div>
  );
}
