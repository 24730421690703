import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { Component } from 'react';
import {
  displayStockLevelStatus,
  getPriceFormat,
} from '../../../services/promotion/utils.service';
import ModalImage from '../../modal/ModalImage';
import '../../promotion/CartPromotion.scss';
import QuantityStepper from '../QuantityStepper';

export const ProductItemType = {
  SELECT: 'SELECT',
  CART: 'CART',
  REMOVE: 'REMOVE',
};
export const history = createBrowserHistory();

export class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderTotal: 0,
      modalIsOpen: false,
      hasSOPEntries: false,
      sopChildEntries: [],
    };

    this.setOpenModal = this.setOpenModal.bind(this);
    this.decreaseOrder = this.decreaseOrder.bind(this);
    this.increaseOrder = this.increaseOrder.bind(this);
  }

  componentDidMount() {
    const urlParams = queryString.parse(history.location.search);
    this.setState({
      orderTotal: this.props.total || 0,
      hasSOPEntries: this.props.hasSopEntries,
      sopChildEntries: this.props.sopChildEntries,
    });
    if (urlParams.productId == this.props.id) {
      this.increaseOrder();
    }
  }

  setOpenModal(modalIsOpen) {
    this.setState({ modalIsOpen });
  }

  increaseOrder() {
    if (this.props.isFlashSale && this.state.orderTotal === 1) {
      return;
    }
    const pointValue = this.props?.amwayValue?.pointValue || 0;
    const businessVolume = this.props?.amwayValue?.businessVolume || 0;

    const totalPrice = this.props.totalPrice + this.props.price;
    const totalPointValue = this.props.totalPointValue + pointValue;
    const totalBusinessVolume = this.props.totalBusinessVolume + businessVolume;
    this.setState({ orderTotal: this.state.orderTotal + 1 });

    if (this.props.productItemType === ProductItemType.CART) {
      this.props.callback(
        this.props.cartId,
        this.props.id,
        this.state.orderTotal + 1,
      );
      return;
    }
    this.props.setTotalPrice(totalPrice);
    this.props.setTotalPointValue(totalPointValue);
    this.props.setTotalBusinessVolume(totalBusinessVolume);
    this.props.setTotalSelected(this.props.totalSelected + 1);
    this.props.setProductSelected(this.props.id, this.state.orderTotal + 1);
  }

  decreaseOrder() {
    if (this.props.productItemType === ProductItemType.CART) {
      if (this.props.total === 1) {
        return alert('คุณสามารถรายการได้จากปุ่ม "แก้ไข" ด้านบน');
      }
      this.setState({ orderTotal: this.state.orderTotal - 1 });
      this.props.callback(
        this.props.cartId,
        this.props.id,
        this.state.orderTotal - 1,
      );
      return;
    }

    if (this.state.orderTotal !== 0) {
      const totalPrice = this.props.totalPrice - this.props.price;
      const pointValue = this.props?.amwayValue?.pointValue || 0;
      const businessVolume = this.props?.amwayValue?.businessVolume || 0;

      const totalPointValue = this.props.totalPointValue - pointValue;
      const totalBusinessVolume =
        this.props.totalBusinessVolume - businessVolume;
      this.setState({ orderTotal: this.state.orderTotal - 1 });

      this.props.setTotalPrice(totalPrice);
      this.props.setTotalPointValue(totalPointValue);
      this.props.setTotalBusinessVolume(totalBusinessVolume);
      this.props.setTotalSelected(this.props.totalSelected - 1);
      this.props.setProductSelected(this.props.id, this.state.orderTotal - 1);
    }
  }

  renderProductItem() {
    return (
      <div className="card-product-body">
        <ModalImage
          modalIsOpen={this.state.modalIsOpen}
          setOpenModal={this.setOpenModal}
          viewImageSrc={this.props.img}
        />
        <div className="flex">
          <div className="product-image-container">
            {this.state.hasSOPEntries && (
              <div className="card-product-sop-tag">สินค้า SOP</div>
            )}
            <div className="card-product-img-promotion mb-10">
              <img
                src={this.props.img}
                alt="product image"
                onClick={() => this.setOpenModal(true)}
              />
            </div>
          </div>
          <div>
            <div className="code-color">#{this.props.code}</div>
            <div className="card-product-title mb-10">
              <div className="font-black card-product-title-text">
                {this.props.title}
              </div>
              <div className="font-black font-x-small font-stock">
                {displayStockLevelStatus(this.props.stock)}
              </div>
            </div>
          </div>
        </div>
        <div className="price-selection-panel flex">
          <div>
            <div className="font-black footer-price-bold">
              ฿ {getPriceFormat(this.props.price)}
            </div>
            <div>
              {this.props.amwayValue && (
                <div className="amway-value-dark">
                  {this.displayAmwayPoint()}
                </div>
              )}
            </div>
          </div>
          <QuantityStepper
            orderTotal={this.state.orderTotal}
            increaseOrder={this.increaseOrder}
            decreaseOrder={this.decreaseOrder}
          />
        </div>
      </div>
    );
  }

  renderCartProductItem() {
    return (
      <div className="card-product-body-cart">
        <div className="cart-card">
          {this.renderProductImage()}
          <div className="cart-item-details-div">
            <div className="code-color cart-product-info-cart">
              #{this.props.code}
            </div>
            <div className="font-black card-product-title-text-cart">
              {this.props.title}
            </div>
            <div className="card-product-title-cart mb-10">
              <div className="cart-item-price-qty-card">
                <div className="cart-item-price-card">
                  <div className="code-color cart-product-info-cart">
                    {this.renderCartItemQuantity()}
                  </div>
                  <div className="cart-item-price-div">
                    {this.renderCartItemPrice()}
                  </div>
                  <div className="code-color cart-product-info-cart">
                    {this.displayAmwayPoint()}
                  </div>
                </div>
                <QuantityStepper
                  orderTotal={this.state.orderTotal}
                  increaseOrder={this.increaseOrder}
                  decreaseOrder={this.decreaseOrder}
                  disableIncreaseStepper={this.state.hasSOPEntries}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderSop()}
      </div>
    );
  }

  renderProductImage() {
    if (
      this.state.hasSOPEntries &&
      this.state.sopChildEntries &&
      this.state.sopChildEntries.length
    ) {
      const isSingleImageSopProfile = this.state.sopChildEntries.length === 1;

      if (isSingleImageSopProfile) {
        const firstChildProduct = this.state.sopChildEntries[0];
        return this.renderSingleProductImage(firstChildProduct?.img);
      } else {
        return this.renderMultipleProductImages();
      }
    } else {
      return this.renderSingleProductImage(this.props.img);
    }
  }

  renderSingleProductImage(img = '') {
    return (
      <div className="product-image-container-cart">
        <div className={`cart-product-sop-tag ${this.props.tagClr}`}>
          {this.props.sopTagMsg}
        </div>
        <div className="card-product-img-cart mb-10">
          <img
            src={img}
            alt="product image"
          />
        </div>
      </div>
    );
  }

  renderMultipleProductImages() {
    const productImages = this.state.sopChildEntries.map((product) =>
      product && product.img ? product.img : '',
    );

    return (
      <div className="product-image-container-cart">
        <div className={`cart-product-sop-tag ${this.props.tagClr}`}>
          {this.props.sopTagMsg}
        </div>

        <div className="cart-sop-solution-img">
          <div className="img-container">
            <img src={productImages[0]} />
            {productImages[1] ? (
              <img src={productImages[1]} />
            ) : (
              <div className="empty-img"></div>
            )}
          </div>

          <div className="img-container">
            {productImages[2] ? (
              <img src={productImages[2]} />
            ) : (
              <div className="empty-img"></div>
            )}
            {productImages[3] ? (
              <div className="last-img">
               <img src={productImages[3]} />

                {productImages.length > 4 && <section>
                  <div className="overlay"></div>
                  <div className="overlay-txt">
                    +{productImages.length - 4}
                  </div>
                </section>}
               
              </div>
            ) : (
              <div className="empty-img"></div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderSop() {
    if (
      this.state.hasSOPEntries &&
      this.state.sopChildEntries &&
      this.state.sopChildEntries.length
    ) {
      return (
        <div className="cart-sop-card w-h-100">
          <div className="line"></div>
          <div className="totalEntryCount">
            <div className="txtDiv">{`สินค้าในชุดผลิตภัณฑ์ (${this.props.totalSopChildQty})`}</div>
          </div>
          {this.renderSopChildEntry()}
        </div>
      );
    } else {
      return <></>;
    }
  }

  renderSopChildEntry() {
    return this.state.sopChildEntries.map((entry, index) => {
      return (
        <div className="sop-child-entry" key={index}>
          <img style={{ width: '48px', height: '48px' }} src={entry.img} />
          <div className="sop-child-info-div">
            <div className="child-info">
              <div className="info-text">{entry.identifier}</div>
            </div>
            <div className="info-text" style={{ textAlign: 'right' }}>
              x{entry.quantity}
            </div>
          </div>
        </div>
      );
    });
  }

  renderCartItemQuantity() {
    const { total, price, productItemType } = this.props;
    if (total > 1) {
      return (
        <span
          style={
            productItemType === ProductItemType.REMOVE
              ? { display: 'none' }
              : {}
          }
        >
          {total} x {`฿${getPriceFormat(price)}`}
        </span>
      );
    } else {
      return <span></span>;
    }
  }

  renderCartItemPrice() {
    const { totalPrice, total, price } = this.props;
    const normalPrice = total * price;
    if (normalPrice > totalPrice) {
      return (
        <span>
          <span className="font-black cart-item-price-bold">{`฿${getPriceFormat(
            totalPrice,
          )}`}</span>
          <span className="ml-4 cart-item-price-cut">{`฿ ${getPriceFormat(
            normalPrice,
          )}`}</span>
        </span>
      );
    }
    return (
      <span className="font-black cart-item-price-bold">{`฿ ${getPriceFormat(
        this.props.total * this.props.price,
      )}`}</span>
    );
  }

  displayAmwayPoint() {
    const pointValue = this.props?.amwayValue?.pointValue || 0;
    const businessVolume = this.props?.amwayValue?.businessVolume || 0;

    const { productItemType } = this.props;
    if (businessVolume && pointValue) {
      return (
        <span
          style={
            productItemType === ProductItemType.REMOVE
              ? { display: 'none' }
              : {}
          }
        >{`PV ${pointValue.toLocaleString()} / BV ${businessVolume.toLocaleString()}`}</span>
      );
    } else {
      return <></>;
    }
  }

  renderRemoveProductItem() {
    return (
      <div
        className="card-product-body-cart"
        style={{ width: '100%', gap: '5px' }}
      >
        <div className="cart-card">
          {this.renderProductImage()}
          <div className="cart-item-details-div">
            <div className="code-color cart-product-info-cart">
              #{this.props.code}
            </div>
            <div className="font-black card-product-title-text-cart">
              {this.props.title}
            </div>
            <div className="card-product-title-cart mb-0">
              <div className="cart-item-price-qty-card">
                <div className="cart-item-price-card">
                  {/* <div className="code-color cart-product-info-cart">
                    {this.renderCartItemQuantity()}
                  </div> */}
                  <div className="cart-item-price-div">
                    {this.renderCartItemPrice()}
                  </div>
                  {/* <div className="code-color cart-product-info-cart">
                    {this.displayAmwayPoint()}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderSop()}
      </div>
    );
  }

  render() {
    if (this.props.productItemType === ProductItemType.SELECT) {
      return this.renderProductItem();
    }

    if (this.props.productItemType === ProductItemType.CART) {
      return this.renderCartProductItem();
    }

    if (this.props.productItemType === ProductItemType.REMOVE) {
      return this.renderRemoveProductItem();
    }
    return <></>;
  }
}
export default Product;
