import React from 'react';
import { ExerciseGoalCard } from '../../../../../common';
import './ExerciseGoalList.scss';

const ExerciseGoalList = ({
  toggleTimeLog,
  setExerciseMenuId,
  headerText,
  list,
  exerciseTimeLogList,
  isTargetCalorieCalculated,
  targetCalorie,
  routeTo
}) => {
  return (
    <div className="exercise-goal-container-wrapper">
      <div className="header">{headerText}</div>
      <div className="exercise-goal-card-container">
        {list?.map((item, key) => (
          <div className="exercise-goal-card" key={`ExerciseGoalCard_${key}`}>
            <ExerciseGoalCard
              item={item}
              toggleTimeLog={toggleTimeLog}
              setExerciseMenuId={setExerciseMenuId}
              exerciseTimeLogList={exerciseTimeLogList}
              routeTo={routeTo}
              isTargetCalorieCalculated={isTargetCalorieCalculated}
              targetCalorie={targetCalorie}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExerciseGoalList;
