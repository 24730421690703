import React, { useState, useEffect } from 'react';
import { getPriceFormat } from '../../../../services/promotion/utils.service';
import { QuantityStepper } from '../../../../helpers';
import '../SOPSolution.scss';
export const SolutionItem = (props) => {
	const { product, img, price, amwayValue, addProductToList, increaseDisabled, accountType } = props;

	return (
		<div className="sop-product-box">
			<div className="sop-product-content">
				<div className="row no-margin-row display-flex">
					<div className="product-image-container">
						<div className="card-product-img">
							<img
								src={img}
								alt="SOP product image"
								style={{ width: '100%', height: '100%' }}
							/>
						</div>
					</div>
				</div>
				<div className='display-flex'>
					<span className="new-solution-title-sku">#{product.sku}</span>
				</div>
				<div className='display-flex'>
					<span className="new-solution-title-name">{product.identifier}</span>
				</div>
				<div className="display-flex sop-price-container solution-price">
					<h2>฿ {getPriceFormat(price)}</h2> &ensp; &ensp;
				</div>
				{accountType === 'ABO' && <div className='display-flex solution-pvbv'>
					<span>
						PV&thinsp;
						{amwayValue.pointValue ? (
							<>{amwayValue.pointValue.toLocaleString()}</>
						) : (
							<>N/A</>
						)}{' '}
						/ BV&thinsp;
						{amwayValue.businessVolume ? (
							<>{amwayValue.businessVolume.toLocaleString()}</>
						) : (
							<>N/A</>
						)}
					</span>
				</div>}
			
				<div className='display-flex add-sop-btn'>
					{product.addedQuantity ?
						<div style={{width: '152px'}}>
							<QuantityStepper quantity={product.addedQuantity}
								decreaseEvent={() => addProductToList(product.sku, 'decrease')}
								increaseEvent={() => addProductToList(product.sku, 'increase')}
								increaseDisabled={increaseDisabled} />
						</div> :
						<button
							onClick={() => addProductToList(product.sku, 'increase')}
							disabled={increaseDisabled}
							className="sop-btn-purchase"
						>
							เลือก
						</button>}
				</div>
			</div>
		</div>
	)
}