import React, { useEffect, useMemo, useState } from 'react';
import MultiBar from '../../../../shared/graph/multiBar/MultiBar';
import GraphTitle from '../../../../shared/graph/graphTitle/GraphTitle';
import './style.scss';
import HnwTabs from '../hnwTabs/HnwTabs';
import { CALENDAR_DATA_LABELS } from '../../../../../common/commonConstant';

export default function BarGraphContainer({
  type,
  graphType,
  xAxisFormatter,
  xAxisCategories,
  yAxisCategories,
  min,
  max,
  onMouseMove,
  highlightedLabel,
  itemTypeList,
  showTabs,
  activeTab,
  setActiveTab,
  tabList,
  date
}) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);;
  }, [])

  const GraphComponent = useMemo((() => (
    <MultiBar
      graphType={graphType}
      type={type}
      yAxisCategories={yAxisCategories}
      highlightedLabel={highlightedLabel}
      min={min}
      max={max}
      xAxisCategories={xAxisCategories}
      onMouseMove={onMouseMove}
    />
  )), [yAxisCategories, min, max, graphType, highlightedLabel]);

  return isMounted && <div className="bar-graph-container">
    <div className="bar-graph-container-content">
      <GraphTitle date={date} itemTypeList={itemTypeList} />
      {GraphComponent}
      {showTabs && <div className="month-graph-tabs">
        <HnwTabs onClick={setActiveTab} typeList={tabList.map(itemName => CALENDAR_DATA_LABELS[itemName])} activeTab={activeTab} />
      </div>}
    </div>
  </div>;
}
