import React from 'react';
import './QuizQuestionCardLoader.scss';

const QuizQuestionCardLoader = () => {
  return (
    <div className="shimmer-question-card-single">
      <div className="shimmer-question-card-name">
        <div className="shimmer shimmer-text shimmer-title"></div>
      </div>
      <div className="shimmer-question-card-answer-options">
          <div className="shimmer shimmer-answer-option"></div>
      </div>
    </div>
  );
};

export default QuizQuestionCardLoader;
