import {
  GET_WEIGHT_GOAL,
  UPDATE_WEIGHT_GOAL,
  GET_WATER_GOAL,
  UPDATE_WATER_GOAL,
  GET_NUTRITION_GOAL,
  UPDATE_NUTRITION_GOAL,
  GET_SUPPLEMENTS_GOAL,
  UPDATE_SUPPLEMENTS_GOAL,
  GET_ACTIVITY_GOAL,
  GET_EXERCISE_MENU_GOAL,
  RESET_EXERCISE_MENU_GOAL,
  POST_ACTIVITY_GOAL,
  PUT_ACTIVITY_GOAL,
  DELETE_GOAL,
} from '../actions/types';

const INITIAL_STATE = {
  weightGoal: null,
  waterGoal: null,
  nutritionGoal: null,
  activityGoal: null,
  exerciseMenuList: [],

};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_WEIGHT_GOAL:
      return { ...state, weightGoal: action.payload };
    case UPDATE_WEIGHT_GOAL:
      return { ...state, weightGoal: action.payload };
    case GET_WATER_GOAL:
      return { ...state, waterGoal: action.payload, hasError: action.hasError };
    case UPDATE_WATER_GOAL:
      return { ...state, waterGoal: action.payload, hasError: action.hasError };
    case GET_NUTRITION_GOAL:
      return { ...state, nutritionGoal: action.payload };
    case UPDATE_NUTRITION_GOAL:
      return { ...state, nutritionGoal: action.payload };
    case GET_SUPPLEMENTS_GOAL:
      return {
        ...state,
        supplementsGoal: action.payload,
        hasError: action.hasError,
      };
    case UPDATE_SUPPLEMENTS_GOAL:
      return {
        ...state,
        supplementsGoal: action.payload,
        hasError: action.hasError,
      };
    case GET_ACTIVITY_GOAL:
      return { ...state, activityGoal: action.payload };
    case GET_EXERCISE_MENU_GOAL:
      return { ...state, exerciseMenuList: action.payload };
    case RESET_EXERCISE_MENU_GOAL:
      return { ...state, exerciseMenuList: [] };
    case DELETE_GOAL:
      return { ...state.hnwGoals, [action.payload.goalType]: null };
    default:
      return state;
  }
}
