import AxiosHealthWellness from '.';
import config from '../../config';
import { HealthGoalSettingItems } from '../../model/GoalSetting.model';
import { DELETE_GOAL_API_MAPPING } from '../../common/commonConstant';

export const fetchAllHealthGoalList = async (pathParam) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-goal/all/${pathParam}`,
    );
    return new HealthGoalSettingItems(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

export const deleteGoal = async (payload) => {
  try {
    const response = await AxiosHealthWellness.delete(`${config.API_URL}/health-goal/${DELETE_GOAL_API_MAPPING[payload]}`);
    return response
  } catch (error) {
    console.error(error);
  }
};