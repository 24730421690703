import React from 'react';
import './AutoSyncOtpVerification.scss';
import { AUTO_SYNC_MODAL_THAI } from '../../challengeConstants';

const supportCustomerDetail = (detail, idx, className) => (
  <span key={detail} className={`${className}-${idx}`}>
    {detail}
    <br />
  </span>
);

export default function AutoSyncOtpVerification({
  phoneNumber,
  bodyKeyOtp,
  otpTitle,
  otpTimer,
  otpErrorDetail,
  onChangeHandler,
  onClose,
  onVerify,
  otpValidationFailed,
}) {
  return (
    <div className="auto-sync-body">
      <div className="auto-sync-body-title">{otpTitle}</div>
      <div className="auto-sync-body-info">
        {!!otpErrorDetail && otpErrorDetail.length > 0
          ? otpErrorDetail.map((detail, idx) =>
              supportCustomerDetail(detail, idx, 'phone-number-green-fail'),
            )
          : AUTO_SYNC_MODAL_THAI.OTP_DETAIL(phoneNumber).map((detail, idx) =>
              supportCustomerDetail(detail, idx, 'phone-number-green'),
            )}
      </div>
      <div className="auto-sync-body-input-field">
        <input
          className={`auto-sync-input-box ${
            otpValidationFailed ? 'error-value' : ''
          }`}
          value={bodyKeyOtp}
          onChange={onChangeHandler}
          name="bodyKeyOtp"
          inputMode="tel"
          type="text"
        />
      </div>
      <div className="auto-sync-body-action-box">
        <button
          className="btn btn-outline-black"
          onClick={onClose}
          disabled={parseInt(otpTimer) !== 0}
        >
          {parseInt(otpTimer) === 0
            ? `${AUTO_SYNC_MODAL_THAI.OTP_RESEND_BTN}`
            : `${parseInt(otpTimer) === 60 ? '01:00' : `00:${otpTimer}`} ${
                parseInt(otpTimer) === 60
                  ? AUTO_SYNC_MODAL_THAI.OTP_WAIT_MIN
                  : AUTO_SYNC_MODAL_THAI.OTP_WAIT_SEC
              }`}
        </button>
        <button
          className="btn btn-green btn-green-linear"
          disabled={otpValidationFailed}
          onClick={() => {
            if (!otpValidationFailed) {
              onVerify();
            }
          }}
        >
          <span className="text-color-white">
            {AUTO_SYNC_MODAL_THAI.SUCCESS_BTN}
          </span>
        </button>
      </div>
    </div>
  );
}
