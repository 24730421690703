import {
  DELETE_HNW_ASSESSMENT_DETAIL,
  FETCH_CUSTOMER_PIF_CART,
  FETCH_HNW_ASSESSMENT,
  FETCH_HNW_ASSESSMENT_DETAIL,
  FETCH_HNW_ASSESSMENT_HISTORY_LOG,
  FETCH_HNW_ASSESSMENT_LIST,
  GET_HNW_PRODUCTS,
  GET_HNW_SCORE,
  UPDATE_HNW_ANSWER
} from '../actions/types';

export default function (state = {}, action) {
  console.log(action, 'payload');
  switch (action.type) {
    case FETCH_HNW_ASSESSMENT_LIST:
      return { ...state, assessmentList: action.payload };
    case FETCH_HNW_ASSESSMENT:
      return { ...state, assessmentQuestions: action.payload };
    case UPDATE_HNW_ANSWER:
      return {
        ...state,
        assessmentAnswerResult: action.payload,
        assessmentAnswerError: action.hasError,
      };
    case FETCH_HNW_ASSESSMENT_DETAIL:
      return { ...state, assessmentDetail: action.payload };
    case FETCH_HNW_ASSESSMENT_HISTORY_LOG:
      return { ...state, assessmentHistoryLog: action.payload };
    case DELETE_HNW_ASSESSMENT_DETAIL:
      return { ...state, assessmentDeleteResult: action.payload };
    case GET_HNW_SCORE:
      return { ...state, assessmentScore: action.payload };
    case FETCH_CUSTOMER_PIF_CART:
      return { ...state, customerCart: action.payload };
    case GET_HNW_PRODUCTS:
      return {
        ...state,
        assessmentProducts: {
          ...state.assessmentProducts,
          [action.payload.type]: action.payload.result,
        },
      };
    default:
      return state;
  }
}
