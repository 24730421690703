import React from 'react';
import './libraryProfile.scss';
import Categories from '../profile/categories';
import DiscoverNew from '../profile/discoverNew';
import { EContentType } from '../common.library';

const MyLibrary = () => {
  return (
    <div className="my-library">
      <Categories type={EContentType.MY_LIBRARY} />
      <DiscoverNew type={EContentType.MY_LIBRARY} />
    </div>
  );
};
export default MyLibrary;
