import React, { useEffect } from "react";
import variables from '../../common/commonConstant.scss';
import { percentCalculation } from "../../utill.func";
import { LABEL_CALORIES } from "../commonConstant";
import CircleRoundDouble from "./HealthFoodCircleComponents/CircleRoundDouble";
import CircleRoundHalfDouble from "./HealthFoodCircleComponents/CircleRoundHalfDouble";
import CircleRoundHalfTriple from "./HealthFoodCircleComponents/CircleRoundHalfTriple";
import CircleRoundTriple from "./HealthFoodCircleComponents/CircleRoundTriple";
import './HealthFoodOverviewCircle.scss';

const HealthFoodOverviewCircle = ({ progressValue, maxValue = 0, label = LABEL_CALORIES, icon,
    iconMax, isGradient = false, imageName = '/images/healthAndWellness/foodLog/icons/food-icon.svg', strokeColor }) => {

    let overProgressValue = progressValue;
    let overMaxValue = maxValue;

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--stroke-color",
            strokeColor
        );
    }, [strokeColor]);

    const percentageCalculation = () => {
        if (percentCalculation(progressValue, maxValue) === 0 || !progressValue) {
            return 0;
        }
        else if (percentCalculation(progressValue, maxValue) <= 100) {
            return percentCalculation(progressValue, maxValue);
        }
        else {
            return 100;
        }
    }

    const innerCirclePercentageCalculation = () => {

        if (percentCalculation(overProgressValue - maxValue, overMaxValue) <= 100) {
            return percentCalculation(overProgressValue - maxValue, overMaxValue);
        }
        else {
            return 100;
        }
    }

    const renderCalculationCircle = () => {
        return (
            <svg>
                <circle cx="95" cy="95" r="88"></circle>
                <circle cx="95" cy="95" r="88" style={{ "--percent": percentageCalculation() }}></circle>
                <linearGradient id="cl1" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
                    <stop stopColor={variables.flGreen} />
                    <stop offset="99.62%" stopColor={variables.foodLight} />
                </linearGradient>
            </svg>
        );
    }

    const renderOuterCircle = () => {
        return (
            <svg>
                <circle cx="95" cy="95" r="88"></circle>
                <circle cx="95" cy="95" r="88" style={{ "--percent": percentageCalculation() }}></circle>
            </svg>
        );
    }

    const renderInnerCircle = () => {
        return (
            <div className="inner-circle">
                <svg>
                    <circle cx="95" cy="95" r="72"></circle>
                    <circle cx="95" cy="95" r="72" style={{ "--innerpercent": innerCirclePercentageCalculation() }}></circle>w
                </svg>
            </div>
        );
    }

    const renderSvg = () => {
        if (percentageCalculation() <= 100) {
            return renderCalculationCircle();
        }
        else if (percentageCalculation() > 100 && percentageCalculation() < 200) {
            return <CircleRoundHalfDouble />;
        }
        else if (percentageCalculation() === 200) {
            return <CircleRoundDouble />;
        }
        else if (percentageCalculation() > 200 && percentageCalculation() < 300) {
            return <CircleRoundHalfTriple />;
        }
        else if (percentageCalculation() >= 300) {
            return <CircleRoundTriple />;
        }
    }

    const renderGradientCircles = () => {
        return (
            <div className="card-circle progress-card-circle progress-card-green-1-circle">
                <div className={percentageCalculation() <= 100 ? `percent-circle` : `svg-circle`}>
                    {renderSvg()}
                    <div className="number-circle">
                        <div className="progress-value-circle">
                            <img src={imageName} />
                            <div className="progress-value-unit-circle">{label}</div>
                            <div className="progress-value-inner-circle"> {progressValue ? progressValue.toLocaleString() : '-'}</div>
                        </div >
                    </div >
                </div >
            </div >
        )
    }

    const renderDoubleCircles = () => {
        return (
            <div className="card-circle progress-card-circle progress-card-green-1-circle">
                <div className={"outer-percent-circle"}>
                    {renderOuterCircle()}
                    {progressValue > maxValue && renderInnerCircle()}
                    <div className="number-centered">
                        <div className="progress-value-circle">
                            <img src={`/images/healthAndWellness/foodLog/icons/${progressValue > maxValue ? iconMax : icon}`} />
                            <div className={`progress-value-inner ${progressValue > maxValue ? "color-max" : "color-green"}`}>
                                {progressValue ? progressValue?.toLocaleString() : "-"}
                            </div>
                            <div className="progress-value-unit-container">
                                <div className="health-log-food-card-nutrition-single-info-title">
                                </div>
                                <div
                                    className="health-log-food-card-nutrition-single-info-separtor">
                                    /
                                </div>
                                <div className="health-log-food-card-nutrition-single-info-goal">
                                    {maxValue?.toLocaleString() || "-"}
                                </div>
                                <div className="health-log-food-card-nutrition-single-info-unit">
                                    {label}
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        )
    }

    return (
        !isGradient ? renderDoubleCircles() : renderGradientCircles()
    )

}

export default HealthFoodOverviewCircle;