import AxiosPromotion from '.';
import config from '../../config';

export const CreateOrders = async (cartIds, uid, isInstallment) => {
  const body = { cartIds, uid, isInstallment };
  const response = await AxiosPromotion.post(
    `${config.API_URL}/promotion/payment/orders`,
    body,
  );
  return response.data.data;
};

export const PaymentConfirmation = async (paymentData) => {
  const { lineMid, amwayNumber, feature, orderId, success } = paymentData;
  //! remark expand payment data in this function
  const body = { uid: lineMid, amwayNumber, orderId, success };
  const { data } = await AxiosPromotion.post(
    `${config.API_URL}/promotion/payment/confirmation`,
    body,
  );
  return data;
};
