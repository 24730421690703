import React from 'react';
import { Modal, Button } from 'antd';
import './modal.scss';
import { useHistory } from 'react-router-dom';
import { commonConstant } from '../../../common';
import { EContentType, EModalTitle } from '../common.library';

const ModalCategorySuccess = (props) => {
  const { visibleModal, setVisibleModal, category, title } = props;
  const history = useHistory();

  const handleCancel = () => {
    setVisibleModal(false);
    history.push({
      pathname: commonConstant.pathLibraryProfile,
      search: `currentTab=${EContentType.MY_LIBRARY}`,
    });
  };
  const addContent = () => {
    setVisibleModal(false);
    history.push({
      pathname: commonConstant.pathLibraryContentForm,
      state: { category: category._id },
    });
  };

  return (
    <Modal
      className="warp-modal"
      open={visibleModal}
      centered
      footer={null}
      onCancel={handleCancel}
      closable={true}
      maskClosable={false}
    >
      <div className="modal-image-icon">
        <img src="../../images/library/icon_createSave_complete_category.svg"></img>
      </div>
      <div className="modal-title">{title}</div>
      {title === EModalTitle.CREATE_SUCCESS ? (
        <div className="modal-desc">
          สร้าง Category สำเร็จ <br />
          สามารถดูข้อมูลได้ที่หน้าหน้า My Library
        </div>
      ) : (
        <div className="modal-desc">
          บันทึกข้อมูลสำเร็จ <br />
          สามารถดูข้อมูลได้ที่หน้าหน้า My Library
        </div>
      )}

      <Button
        className="btn"
        onClick={() => {
          addContent();
        }}
        block
      >
        + Add Content
      </Button>
    </Modal>
  );
};
export default ModalCategorySuccess;
