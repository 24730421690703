import React from 'react';
import './RoundChipLoader.scss';

const RoundChipLoader = () => {
  return (
    <div className="shimmer-quiz-tab-single">
      <div className="shimmer-btn-white shimmer"></div>
    </div>
  );
};

export default RoundChipLoader;
