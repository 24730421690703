import { GET_SELF_CHALLENGE, GET_SELF_CHALLENGE_FAILURE, GET_SELF_CHALLENGE_SUCCESS } from "../../actions/types";

const initialState = {
    challengeSelfList: [],
    loading: false,
    error: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SELF_CHALLENGE:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_SELF_CHALLENGE_SUCCESS:
            return {
                ...state,
                loading: false,
                challengeSelfList: action.payload,
                error: null,
            };
        case GET_SELF_CHALLENGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}