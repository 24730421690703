import axios from 'axios';
import {
  getAccessToken,
  getFacadeAccessToken,
  liffGetProfile,
} from './promotion/auth.service';
import { getCustomerFOA } from './promotion/foa.service';

const liff = window.liff;
const AxiosInterceptor = axios.create({
  timeout: process.env.NETWORK_TIMEOUT,
});

export const clearFacadeToken = () => {
  sessionStorage.removeItem('facadeToken');
};

export const setFacadeToken = async (accessToken) => {
  return sessionStorage.setItem('facadeToken', JSON.stringify(accessToken));
};

export const isNotValidSessionABOMember = (facadeToken, customer) => {
  return (
    !facadeToken ||
    facadeToken.account.accountType.toLowerCase() !==
    customer.type.toLowerCase()
  );
};

export const getFacadeToken = async () => {
  const facadeToken = sessionStorage.getItem('facadeToken');
  return facadeToken && facadeToken !== 'undefined'
    ? JSON.parse(facadeToken)
    : null;
};

AxiosInterceptor.interceptors.request.use(async (config) => {
  const token = await getAccessToken();
  config.headers.authorization = `Bearer ${token}`;
  let facadeToken = await getFacadeToken();
  const liffProfile = await liffGetProfile();
  const customer = await getCustomerFOA(liffProfile.userId);

  if (facadeToken && isNotValidSessionABOMember(facadeToken, customer)) {
    clearFacadeToken();
    facadeToken = null;
  }

  if (!facadeToken && !sessionStorage.getItem('facadeTokenMDMSError')) {
    try {
      const accessToken = await getFacadeAccessToken();
      if (
        (customer.type === 'member' ||
          customer.type === 'abo' ||
          customer.type === 'foa') &&
        accessToken
      ) {
        if (!accessToken) {
          alert('เกิดข้อพิดพลาด กรุณาลองใหม่ในภายหลัง');
          setTimeout(() => liff.closeWindow(), 2000);
          return config;
        }
        setFacadeToken(accessToken);
        config.headers['x-req-token'] = accessToken.access_token;
        config.headers.account = `${accessToken.account.accountId}`;
        config.headers.customer = `${accessToken.account.primaryParty.uid}`;
      } else {
        //case type public
        if (!customer) {
          alert('ไม่พบข้อมูลผู้ใช้ กรุณาลองใหม่ในภายหลัง');
          setTimeout(() => liff.closeWindow(), 2000);
          return config;
        }
        sessionStorage.setItem('customerFOAData', JSON.stringify(customer));
      }
    } catch (error) {
      console.error(error);
    }
  } else if(facadeToken) {
    config.headers['x-req-token'] = facadeToken.access_token;
    config.headers.account = `${facadeToken.account.accountId}`;
    config.headers.customer = `${facadeToken.account.primaryParty.uid}`;
  }
  return config;
});

AxiosInterceptor.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.code === 'ECONNABORTED') {
      alert('Request timed out');
      console.error('Request timed out');
    }
    if (
      (error.response !== undefined && error.response.status === 401) ||
      error.response.status === 403
    ) {
      sessionStorage.removeItem('facadeToken');
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default AxiosInterceptor;
