import { GENDER } from '../common/commonConstant';
import config from '../config';
import axios from 'axios';

export const utilsService = {
  deleteUserFromRichmenu,
};

async function deleteUserFromRichmenu(lineId) {
  await axios.delete(
    `${config.API_URL}/utils/deleteUserFromRichmenu/${lineId}`,
  );
}

export const bmiClassification = (bmi, wrapper) => {
  if (bmi || bmi === 0) {
    if (bmi >= 0 && bmi < 18.5) {
      return wrapper[1];
    } else if (bmi >= 18.5 && bmi < 23) {
      return wrapper[2];
    } else if (bmi >= 23 && bmi < 25) {
      return wrapper[3];
    } else if (bmi >= 25 && bmi < 30) {
      return wrapper[4];
    } else if (bmi >= 30) {
      return wrapper[5];
    }
  }
  return wrapper[0] ?? '';
};

export const round = (value, upto = 2) => {
  if (value) {
    const roundUpto = +(1 + ''.padEnd(upto, 0));
    return Math.round(+value * roundUpto) / roundUpto;
  }
  return 0;
};

export const parseUptoDecimal = (value, upto = 2) => {
  if (value) {
    value = value.toString();
    if (value.includes('.')) {
      value = value.slice(0, (value.indexOf(".")) + upto + 1);
    }
    return +value;
  }
  return 0;
}

export const waistlineAnswerScore = (waist, gender, wrapper) => {
  if (gender == GENDER.male) {
    if (waist >= 0 && waist < 35) {
      return wrapper[0];
    } else if (waist >= 35 && waist < 38) {
      return wrapper[1];
    } else if (waist >= 38) {
      return wrapper[2];
    }
  } else {
    if (waist >= 0 && waist < 31) {
      return wrapper[3];
    } else if (waist >= 31 && waist < 35) {
      return wrapper[4];
    } else if (waist >= 35) {
      return wrapper[5];
    }
  }
};

export const yearSelector = (start, stop, step) => {
  const range = (start, stop, step) =>
    Array.from({ length: stop - start }, (_, i) => `${start + i * step}`);
  return range(2400, 2700, 1);
};
