import {
  GET_FOOD_LOGS,
  GET_FOOD_LOG_DATA,
  GET_WATER_LOGS,
  GET_WATER_VOLUME,
  UPDATE_WATER_VOLUME,
  POST_WATER_LOGS,
  ADD_FOOD_MENU,
  ADD_FOOD_IMAGE,
  GET_LOG_LIST,
  UPDATE_HEALTH_DATA
} from '../actions/types';

const initialState = {
  loading: false,
  healthData: [],
  foodChartData: [],
  drinksData: null,
  volumeData: null,
  foodLogData: null,
  goalData: null,
  error: null,
  addFoodMenu: null,
  addFoodImage: null,
  logList: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WATER_LOGS:
      return { ...state, healthData: action.payload };
    case GET_FOOD_LOGS:
      return { ...state, foodChartData: action.payload };
    case POST_WATER_LOGS:
      return { ...state, drinksData: action.payload };
    case UPDATE_HEALTH_DATA:
      return { ...state, foodChartData: action.payload };
    case GET_WATER_VOLUME:
      return { ...state, volumeData: action.payload };
    case UPDATE_WATER_VOLUME:
      return { ...state, volumeData: action.payload };
    case GET_FOOD_LOG_DATA:
      return { ...state, foodLogData: action.payload };
    case ADD_FOOD_IMAGE:
      return { ...state, addFoodImage: action.payload };
    case ADD_FOOD_MENU:
      return { ...state, addFoodMenu: action.payload };
    case GET_LOG_LIST:
      return {
        ...state,
        logList: action.payload,
      };
    default:
      return state;
  }
}
