import React, { useEffect, useRef } from 'react';
import './HealthCategoryCarousel.scss';
import { ALL } from '../commonConstant';
import SlickCarousel from '../slickCarousel/slickCarousel';

const HealthCategoryCarousel = ({ data = [], selectedCategory, onClick = () => { } }) => {
    const SliderRef = useRef(null);

    useEffect(() => {
        const indexToSlide = data.findIndex(category => category.id === selectedCategory.id);
        data.length > 1 && selectedCategory.id === ALL ?
            SliderRef.current.slickGoTo(indexToSlide - 1) :
            SliderRef.current.slickGoTo(indexToSlide)
    }, [selectedCategory.id, data.length]);

    return <SlickCarousel slidesToShow={4} ref={SliderRef}>
        {data.map((item) => {
            return <div key={item?.id}
                onClick={() => onClick(item)}
                className={`category-card${selectedCategory.id === item?.id ? ' category-card-active' : ''}`}>
                <div className="category-img-container">
                    <img src={item?.image} />
                </div>
                <div className="category-card-title">
                    {item?.name}
                </div>
            </div>
        })}
    </SlickCarousel>
};

export default HealthCategoryCarousel;
