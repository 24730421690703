import {
  getFoodLogDetails,
  addFoodMenuData,
  addFoodImage,
  addFoodLogData,
  getTargetCaloriesData,
  getLogListData,
  deleteFoodAndSupplement,
} from '../services/healthWellness/foodLog.service';
import {
  ADD_FOOD_IMAGE,
  ADD_FOOD_LOG,
  ADD_FOOD_MENU,
  GET_TARGET_CALORIES,
  GET_LOG_LIST,
  DELETE_FOOD_LOG,
} from './types';

export const fetchDataRequest = (type) => ({ type });
export const fetchDataSuccess = (type, data) => ({ type, payload: data });
export const fetchDataFailure = (type, error) => ({ type, payload: error });

export const fetchFoodLogDetails = () => (dispatch) =>
  getFoodLogDetails()
    .then((result) => {
      dispatch({
        // type: GET_FOOD_CATEGORIES,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const addFoodMenuDetails = (foodMenuData) => (dispatch) =>
  addFoodMenuData(foodMenuData)
    .then((result) => {
      dispatch({
        type: ADD_FOOD_MENU,
        payload: { foodMenuData },
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const uploadFoodImage = (image) => (dispatch) =>
  addFoodImage(image)
    .then((result) => {
      dispatch({
        type: ADD_FOOD_IMAGE,
        payload: { image },
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const addFoodLog =
  (id, type, myFoodWeight, suggestGoalPayload) => (dispatch) =>
    addFoodLogData(id, type, myFoodWeight, suggestGoalPayload)
      .then((result) => {
        dispatch({
          type: ADD_FOOD_LOG,
          payload: { id, type, myFoodWeight },
        });
        return result;
      })
      .catch(function (error) {
        alert(error.message);
        throw new Error(error);
      });

export const getTargetCalories = () => (dispatch) =>
  getTargetCaloriesData()
    .then((result) => {
      dispatch({
        type: GET_TARGET_CALORIES,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const getLogList = (params) => (dispatch) =>
  getLogListData(params)
    .then((result) => {
      dispatch({
        type: GET_LOG_LIST,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const deleteFoodAndSupplementProducts = (myFoodWeightId) => (dispatch) =>
  deleteFoodAndSupplement(myFoodWeightId)
    .then((result) => {
      dispatch({
        type: DELETE_FOOD_LOG,
        payload: { myFoodWeightId },
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
      throw new Error(error);
    });
