import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { isEmpty, isEqual } from 'lodash';

import { otpFetch, verifyOtpFetch } from '../../actions';
import { OTPInput,Modal } from '../../helpers';

import './Otp.scss';
import { commonConstant } from '../../common';

export class Otp extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      invalid: false,
      initialLoading: true,
      invalidCount: 0,
      showModal: false,
    };
    if (!props.location.state.aboNumber){
      this.props.history.replace(commonConstant.pathHome);
    }
    this.otpRef = createRef();
  }

  componentDidMount(){
    if (isEmpty(this.props.location.state)){
      this.props.history.push(commonConstant.pathHome);
    }
    this.props.otpFetch(this.props.location.state.aboNumber);
  }

  componentDidUpdate(prevProps){
    if (!isEqual(prevProps.otp,this.props.otp)){
      this.setState({
        loading: false,
        initialLoading: false,
      });
    }
    if (!isEqual(prevProps.verifyOtp,this.props.verifyOtp)){
      if (this.props.verifyOtp.code === 200){
        this.props.history.push(commonConstant.pathSuccess);
      } else {
        const { invalidCount } = this.state;
        if (invalidCount < 3){
          this.setState(state => ({
            loading: false,
            invalid: true,
            invalidCount: state.invalidCount + 1,
          }));
          this.otpRef.current.clear();
        } else {
          this.otpRef.current.clear();
          this.setState({
            showModal: true,
          });
        }
      }
    }
  }

  handleSubmitOtp = (otp,props) => {
    const customer = JSON.parse(sessionStorage.getItem('customer'));
    const { isFilled } = props;
    if (isFilled){
      this.props.verifyOtpFetch({
        otp,
        'accountId': this.props.location.state.aboNumber,
        'eventName': 'LOGIN',
        'newLineId': customer.uid,
        'oldLineId': this.props.location.state.customer.uid,
        'newObjectId': customer._id,
        'displayName': customer.displayName,
        'pictureUrl': customer.pictureUrl,
      });
      this.setState({ loading: true });
    }
  }
  
  handleFetchOtp = () => {
    this.setState({
      loading: true,
    });
    this.props.otpFetch(this.props.location.state.aboNumber);
  }

  render() {
    const { handleSubmitOtp, handleFetchOtp } = this;
    const { loading, invalid, initialLoading, showModal } = this.state;
    if (initialLoading){
      return <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />;
    }
    return (
      <div className="regisPage">
        {
          showModal && <Modal
            title="คุณกรอกรหัส OTP ผิดเกินกำหนด"
            content="กรุณาติดต่อ Amway Contact Center"
            type="warning"
            fnOk={() => window.location = 'tel:027258000'}
            fnCancel={() => this.props.history.replace(commonConstant.pathAbo)}
            okText="โทร"
            cancleText="ยกเลิกรายการ" />
        }
        <div className="logoAmway"><img src="/images/logoAmway-black.svg" alt="" /></div>
        <h2 className="mb-90">กรอกรหัส OTP เพื่อยืนยัน</h2>
        <div className="otp-content">
          <div>หมายเลข OTP จะถูกส่งไปที่หมายเลข</div>
          <div>{this.props.otp.phoneNumber}</div>
          <OTPInput
            loading={loading} 
            ref={this.otpRef} 
            invalid={invalid} 
            count={4} 
            onChange={invalid ? () => this.setState({ invalid: false }) : null} 
            onLastInput={(val,props) => handleSubmitOtp(val,props)}
            resendFn={handleFetchOtp}
            resendBtn={true}
            delay={true}
            resendDelay={60}
            invalidMessage="รหัส OTP ไม่ถูกต้องกรุณาลองอีกครั้ง"
          />
        </div>
        <div className="otp-footer">
          <div className="ref-text">ระบบจะทำการเปลื่ยนบัญชี LINE ของท่าน</div>
          <div className="ref-text">เมื่อทำการยืนยันตัวตนผ่านเบอร์โทรศัพท์เสร็จสมบูรณ์</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ otp, verifyOtp }) => ({
  otp,
  verifyOtp,
});

const mapDispatchToProps = (dispatch) => ({
  otpFetch: uid => dispatch(otpFetch(uid)),
  verifyOtpFetch: params => dispatch(verifyOtpFetch(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(Otp);
