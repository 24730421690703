import { PAYMENT_CREATE } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case PAYMENT_CREATE:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
