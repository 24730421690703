import React, { useEffect, useState, useRef } from 'react';
import './HnwInputModal.scss';
import Numpad from '../hnWNumpadModal/Numpad';
const HnwInputModal = ({
  isOpen,
  onBackgroundClick,
  onNumpadValueChange,
  onNumpadDelete,
  showNumpad,
  onNumpadSubmit,
  children,
}) => {
  const dialogRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.close();
    }
  }, [isOpen]);

  const handleBackgroundClick = (event) => {
    if (onBackgroundClick) {
      event.target === event.currentTarget && onBackgroundClick(event);
    }
  };

  const numpadContainerClass = !showNumpad
    ? 'input-modal-dialog__numpad-container-blank'
    : '';

  return (
    <dialog
      ref={dialogRef}
      className="input-modal-dialog"
      onClick={(e) => handleBackgroundClick(e)}
    >
      <div className="input-modal-dialog__content">{children}</div>
      <div
        className={`input-modal-dialog__numpad-container ${numpadContainerClass}`}
      >
        {showNumpad && (
          <Numpad
            handleInputChange={onNumpadValueChange}
            handleDelete={onNumpadDelete}
            handleOKClick={onNumpadSubmit}
          />
        )}
      </div>
    </dialog>
  );
};

export default HnwInputModal;
