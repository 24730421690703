import config from "../config";

export class AddMenuModel {
    constructor(data) {
        if (data) {
            if (data._id) { this._id = data._id };
            this.name = data.name || '';
            this.productCategory = data.productCategory || '';
            this.image = data.image || '';
            this.quantity = data.quantity || 1;
            this.unit = data.unit || '';
            this.weight = data.weight || 1;
            this.calorie = data.calorie || 0;
            this.carbs = data.carbs || 0;
            this.protein = data.protein || 0;
            this.fat = data.fat || 0;
        }
    }
}