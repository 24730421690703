import React from "react";
import { RectBadge } from "../../../../common";
import { CHALLENGE } from "../../../../common/challengeConstants";
import { inactiveClassName } from "../../../../utill.func";
import './ChallengeMyCurrentDayBadge.scss';

const ChallengeMyCurrentDayBadge = ({ teamData, headerBadge, teamDashboardStatus, isNewAbo, isNewMember }) => {

    const activeColorCheck = inactiveClassName(teamDashboardStatus, 'disable-color', 'active-color');
    const headerClass = headerBadge ? '' : 'noHeaderBadge'

    return (
        <>
            {
                <div className={`my-challenge-day-container ${headerClass}`}>
                    {headerBadge && <RectBadge rectTitle={`${CHALLENGE.teamDashboard.teamStepsCardHeader}`} rectUnit={`${teamData?.challengeDay}`}>
                        <div className="rect-badge-description">
                            <div className={`rect-badge-title ${activeColorCheck}`}>{`${CHALLENGE.teamDashboard.teamStepsCardHeader}`}</div>
                            <div className={`rect-badge-value ${activeColorCheck}`}>{`${teamData?.challengeDay}`}</div>
                        </div>
                    </RectBadge>}
                    {(isNewAbo || isNewMember) && <div className="team-leader-card">
                    <RectBadge>
                    <div className='rect-badge-title'>{isNewAbo? CHALLENGE.teamDashboard.teamNewAboTitle: isNewMember ?CHALLENGE.teamDashboard.teamNewMemberTitle:''}</div>
                    </RectBadge>
                </div>}
                </div>
            }
        </>
    )
}

export default ChallengeMyCurrentDayBadge;