import React from 'react';
import { getPhotoName } from '../../challengeConstants';

export default function MyPictureCardIdle({
  photoPositionObj,
  beforeOrAfter,
  handleImageUpload,
}) {
  return (
    <div
      className="picture-col"
      onClick={() =>
        handleImageUpload(
          getPhotoName(beforeOrAfter.type, beforeOrAfter.position),
        )
      }
    >
      <img src={photoPositionObj.image} alt={photoPositionObj.image} />
      <p>
        {photoPositionObj.uploadPictureThai}
        <br />
        {photoPositionObj.positionImageThai} ({beforeOrAfter.typeThai})
      </p>
    </div>
  );
}
