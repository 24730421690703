import { waterImagesMapping } from "../common/commonConstant";
import { DRINK_NAME, DRINK_STATUS } from "../constants";
import { WaterLog } from "./HealthLog.model";

export class Drinks {

    constructor(data = null) {

        if (data) {
            this.id = data._id || data.id || '';
            this.customers = data.customers || '';
            this.createdDate = data.createdDate || '';
            this.updatedDate = data.updatedDate || '';
            this.waterLevel = data.waterLevel || null;
            this.coffeeLevel = data.coffeeLevel || null;
            this.softDrinkLevel = data.softDrinkLevel || null;
            this.waterIntake = data.waterIntake || null;
            this.coffeeIntake = data.coffeeIntake || null;
            this.softDrinkIntake = data.softDrinkIntake || null;
            this.status = this.waterIntake ? this.waterLevel : this.coffeeIntake ? this.coffeeLevel
                : this.softDrinkIntake ? this.softDrinkLevel : data.status ? data.status : DRINK_STATUS.empty;
            this.waterVolume = data.waterVolume ? new WaterVolume(data.waterVolume) : null;
            this.waterVolume = data.waterVolume ? new WaterLog(data.waterVolume) : null;
            this.amount = data.waterIntake || data.softDrinkIntake || data.coffeeIntake || '';
            this.image = data.waterIntake
                ? waterImagesMapping.water.image
                : data.coffeeIntake
                    ? waterImagesMapping.tea.image
                    : data.softDrinkIntake
                        ? waterImagesMapping.softdrink.image
                        : '';
            this.name = data.waterIntake ? waterImagesMapping.water.title
                : data.coffeeIntake ? waterImagesMapping.tea.title
                    : data.softDrinkIntake ? waterImagesMapping.softdrink.title
                        : '';
            this.keyName = data.name ? data.name : this.waterIntake ? DRINK_NAME.water
                : this.coffeeIntake ? DRINK_NAME.coffee : DRINK_NAME.softDrink;
        }
    }
}

export class WaterVolume {

    constructor(data = null) {
        if (data) {
            this.id = data._id || null;
            this.waterVolume = data.waterVolume || null;
            this.coffeeVolume = data.coffeeVolume || null;
            this.softDrinkVolume = data.softDrinkVolume || null;
        }
    }

}