import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';

import { addrFetch, customerFetch } from '../../../actions';
import commonConstant from '../../../common/commonConstant';
import { Checkbox } from '../../../helpers';
import { renewalService, addressService } from '../../../services';
import variables from '../../../common/commonConstant.scss';

import './ChooseAddress.scss';

const Address = ({ address, onClick, selected, isPrimary }) => (
  <div className="address-container" onClick={onClick}>
    <Checkbox checked={selected} checkmarkColor={variables.success} />
    {isPrimary && (
      <div>
        <div className="address-primary-tag">[ ที่อยู่หลัก ]</div>
        <div>{address}</div>
      </div>
    )}
    {!isPrimary && (
      <div>
        <div>{address}</div>
      </div>
    )}
  </div>
);

export const ChooseAddress = ({ location }) => {
  const dispatch = useDispatch();
  const { profile, customer, address } = useSelector((state) => state);

  const maxCount = 6;
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(location?.state?.contactId);
  const [redirect, setRedirect] = useState();
  const [addresses, setAddresses] = useState();

  useEffect(() => {
    dispatch(customerFetch(profile.userId));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(customer)) {
      dispatch(addrFetch(customer.amwayNumber));
    }
  }, [customer]);

  useEffect(() => {
    if (!_.isEmpty(address)) {
      const addressList = _.chain(address.data?.addressBookList)
        .map((addressBook) => {
          const address = _.head(addressBook.addressList);
          return {
            addressBookId: addressBook.addressBookId,
            fullAddress: [
              address.addrStreet,
              address.subDistrictName,
              address.districtName,
              address.cityName,
              address.postalCd,
            ].join(', '),
            isPrimary: address.usageList[0]?.primaryFlag,
            ..._.pick(address, [
              'addrStreet',
              'subDistrictName',
              'districtName',
              'cityName',
              'postalCd',
              'cntryCd',
              'stateCd',
            ]),
          };
        })
        .value();
      const primaryAddress = addressList.filter((address) => address.isPrimary);
      const excludePrimaryAddress = addressList.filter(
        (address) => !address.isPrimary,
      );
      const sortedAddressList = [...primaryAddress, ...excludePrimaryAddress];
      setLoading(false);
      setAddresses({
        paging: {
          totalCount: sortedAddressList.length,
        },
        data: sortedAddressList,
      });
    }
  }, [address]);

  const submitAddress = useCallback(
    (address) => {
      const province_name = address.cityName;
      addressService.getProvinces().then((res) => {
        if (res.code === 200) {
          let isocode = address.stateCd;
          const province = res.data.filter((prv) => prv.name == province_name);
          if (province.length > 0) {
            isocode = isocode;
          }

          const payload = {
            amwayNumber: customer.amwayNumber,
            partyId: customer.partyId,
            cartCode: location?.state?.cartCode,
            address: {
              id: address.addressBookId,
              country: {
                isocode: address.cntryCd,
              },
              postalCode: address.postalCd,
              county: address.districtName,
              line1: address.addrStreet,
              district: address.districtName,
              subDistrict: address.subDistrictName,
              region: {
                countryIso: address.cntryCd,
                isocode: isocode || 'BK',
              },
              town: '',
            },
          };
          setLoading(true);
          setSelected(address.addressBookId);
          renewalService.updateDeliveryAddress(payload).then(() => {
            setLoading(false);
            setRedirect(commonConstant.pathMySOP);
          });
        }
      });
    },
    [profile, customer, location],
  );

  if (redirect === commonConstant.pathMySOP) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: {
            reload: true,
          },
        }}
      />
    );
  } else if (redirect === commonConstant.pathCreateAddress) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: {
            cartCode: location?.state?.cartCode,
          },
        }}
      />
    );
  }
  const addAble = addresses ? addresses.paging.totalCount < maxCount : false;
  return (
    <div className="choose-address-container">
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading"
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      <div className="body">
        <div className="addresses-wrapper">
          {addresses &&
            addresses.data.map((addr, idx) => (
              <Address
                key={idx}
                address={addr.fullAddress}
                onClick={() => submitAddress(addr)}
                selected={selected === addr.addressBookId}
                isPrimary={addr.isPrimary}
              />
            ))}
        </div>
        <div className="description center">
          * คุณสามารถเพิ่มที่อยู่ได้สูงสุด {maxCount} รายการ
          หากต้องการแก้ไขหรือเพิ่มที่อยู่ กรุณาติดต่อ 02-725-8000
        </div>
        <button
          className="away-button"
          disabled={!addAble}
          onClick={() => setRedirect(commonConstant.pathCreateAddress)}
        >
          เพิ่มที่อยู่
        </button>
      </div>
    </div>
  );
};
