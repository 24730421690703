import React, { useEffect } from 'react';
import { useLottie } from 'lottie-react';
import successAnimation from '../../json/set-goal-complete.json';
import './HnwSuccessOverlayModal.scss';

const HnwSuccessOverlayModal = ({
  show,
  timeout = 2000,
  onTimeout = () => {},
  animationPath,
  loop = false,
  renderDetail = null,
}) => {
  const options = {
    loop,
    animationData: animationPath ?? successAnimation,
  };

  useEffect(() => {
    if (show) {
      setTimeout(onTimeout, timeout);
    }
  }, [show]);

  const { View } = useLottie(options);
  return (
    <div className="hnw-success-animation-overlay">
      <div className="hnw-success-animation-container">
        {View}
        {renderDetail && renderDetail()}
      </div>
    </div>
  );
};

export default HnwSuccessOverlayModal;
