import { ALERT_ERROR, ALERT_SUCCESS, ALERT_CLEAR } from "../actions/types"

export default function(state = { message: "", error: false }, action) {
    
    switch (action.type) {
        case ALERT_SUCCESS:
            return {
                ...state,
                message: action.payload
            };
        case ALERT_ERROR:
           
            return {
                ...state,
                error: action.error,
                message: action.message
            };
        case ALERT_CLEAR:
            return {
                ...state,
                error: action.error,
                message: action.message
            };
        default:
            return state
    }

}
