import React from "react";
import {
    WATER_BAR_NAME, WATER_CARD_HEADING, WATER_CIRCLE_BG_ICON, WATER_CIRCLE_GRADIENT_DEGREE, WATER_CIRCLE_GRADIENT_ID, WATER_CIRCLE_START_COLOR, WATER_CIRCLE_STOP_COLOR
} from "../commonConstant";
import HealthHomeCircleChart from "../healthHomeCircleChart/HealthHomeCircleChart";
import HealthLogItemEmptyCard from "../healthLogItemEmptyCard/HealthLogItemEmptyCard";
import './HealthLogWaterCard.scss';

const HealthLogWaterCard = ({ isEmpty, waterLog, onClick = () => { } }) => {

    const renderCircularChart = () => {
        return (
            <div onClick={onClick} className='health-log-water-card'>
                <div className="health-log-water-card-header">
                    <div className="health-log-water-card-header-left">
                        <img src="/images/healthAndWellness/foodLog/icons/water.svg" />
                        <h6>{WATER_CARD_HEADING}</h6>
                    </div>
                    <div className="health-log-water-card-header-right">
                        <button className="btn-icon-round">
                            <img src="/images/healthAndWellness/foodLog/icons/arrow-right.svg" />
                        </button>
                    </div>
                </div>
                <div className="water-circle-chart">
                    <HealthHomeCircleChart stopColorStart={WATER_CIRCLE_START_COLOR}
                        stopColorEnd={WATER_CIRCLE_STOP_COLOR}
                        bgIcon={WATER_CIRCLE_BG_ICON}
                        usedQty={waterLog.consumed}
                        totalQty={waterLog.total}
                        unit={waterLog.unit}
                        gradientId={WATER_CIRCLE_GRADIENT_ID}
                        gradientDegree={WATER_CIRCLE_GRADIENT_DEGREE} />
                </div>
            </div>
        )
    }

    return (isEmpty ? <HealthLogItemEmptyCard itemType={WATER_BAR_NAME} onClick={onClick} /> : renderCircularChart())
}

export default HealthLogWaterCard