import React, { Component } from 'react';
import { displayStockLevelStatus } from '../../../services/promotion/utils.service';
import LiffVariant from '../../modal/LiffVariant';
import '../../promotion/CartPromotion.scss';
import { UpdateState } from '../SelectProduct/PromotionProduct';
import ProductVariant from './ProductVariant';
import { ProductItemType } from './Product';

export class KitEntryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLiffopen: false,
      selectedTotal: 0,
      selectedVariant: [],
      isConfirmVariant: false,
    };

    this.onOpenLiff = this.onOpenLiff.bind(this);
    this.onCloseLiff = this.onCloseLiff.bind(this);
    this.setTotalSelected = this.setTotalSelected.bind(this);
    this.onConfirmVariant = this.onConfirmVariant.bind(this);
  }

  componentDidMount() {
    this.setState({
      orderTotal: this.props.total || 0,
      kitEntry: this.props.kitEntries,
    });
  }

  onCloseLiff() {
    this.setState({ isLiffopen: false, isConfirmVariant: false });
  }

  onOpenLiff() {
    this.setState({ isLiffopen: true });
  }

  setTotalSelected(total) {
    this.setState({ selectedTotal: total });
  }

  isMaxSelected() {
    return this.state.selectedTotal === this.props.kitEntry.configuredQty;
  }

  onConfirmVariant() {
    this.setState({ isLiffopen: false, isConfirmVariant: true });
    this.props.setProductSelected(
      this.props.productId,
      1,
      UpdateState.FINISH,
      this.props.alias,
    );
  }

  setDisableStepperBox() {
    return this.state.selectedTotal == this.props.kitEntry.configuredQty;
  }

  renderProductVariant(productVariant, configuredQty, kitEntryCode) {
    return productVariant.map((value, index) => {
      const isFirstVariant = index == 0;
      return (
        <ProductVariant
          key={index}
          quantity={value.quantity}
          productVariant={value}
          kitEntryCode={kitEntryCode}
          configuredQty={configuredQty}
          isFirstVariant={isFirstVariant}
          productId={this.props.productId}
          isLiffopen={this.state.isLiffopen}
          selectedTotal={this.state.selectedTotal}
          setTotalSelected={this.setTotalSelected}
          productItemType={this.props.productItemType}
          isConfirmVariant={this.state.isConfirmVariant}
          setProductSelected={this.props.setProductSelected}
          disableStepperBox={this.setDisableStepperBox()}
          disableIncreaseStepper={this.setDisableStepperBox()}
        />
      );
    });
  }

  renderKitEntry() {
    const { configuredQty, product, kitEntryCodeAlias } = this.props.kitEntry;
    const { identifier, baseOptions, stock } = product;
    const img = product.galleryImages[0]
      ? product.galleryImages[0].image_url
      : '';
    const productVariant =
      baseOptions.length !== 0 ? baseOptions[0].options : null;

    return (
      <div className="kit-container-item" key={identifier}>
        <div className="card-kit-img-kit">
          <img
            className="card-kit-img-size-kit"
            alt="product image"
            src={img}
          />
        </div>
        <div className="bundle-product-view">
          <div className="flex-between">
            <div className="kit-entry-identifier">
              <span>{identifier}</span>
            </div>
            {productVariant &&
            !product.baseProduct &&
            this.props.productItemType !== ProductItemType.REMOVE ? (
              <div className="btn-edit-kit" onClick={this.onOpenLiff}>
                <span className="btn-edit-kit-text">{'เปลี่ยนสินค้า'}</span>
                <span>{' >'}</span>
              </div>
            ) : (
              <span className="conf-qty-text">{`x${configuredQty}`}</span>
            )}
          </div>
          <div className="stocklevel-status-view">
            <div className="font-xsmall light-blue conf-qty-text">
              {productVariant &&
                productVariant.map((value) => {
                  if (value.quantity === 0) {
                    return <></>;
                  }
                  return (
                    <>
                      {`${value.identifier} x ${value.quantity}`}
                      <div className="font-x-small conf-qty-text">
                        {displayStockLevelStatus(value.stock)}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          {!productVariant && (
            <div className="font-x-small conf-qty-text">{displayStockLevelStatus(stock)}</div>
          )}
        </div>
        {productVariant && (
          <LiffVariant
            title={`เลือกสินค้าได้ ${configuredQty} ชิ้น`}
            maxProducts={configuredQty}
            selectedProducts={this.state.selectedTotal}
            onClose={this.onCloseLiff}
            isOpen={this.state.isLiffopen}
          >
            <div className="scroll" style={{ height: '70vh' }}>
              {this.renderProductVariant(
                productVariant,
                configuredQty,
                kitEntryCodeAlias,
              )}
            </div>
            <div className="product-panel-footer-variant">
              <button
                className="btn-bottom-new"
                onClick={this.onConfirmVariant}
                disabled={!this.isMaxSelected()}
              >
                ตกลง
              </button>
            </div>
          </LiffVariant>
        )}
      </div>
    );
  }

  render() {
    return this.renderKitEntry();
  }
}

export default KitEntryItem;
