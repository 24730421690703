import React from 'react';
import { commonConstant } from '../../../../common';
const today = new Date();
const month = today.getMonth() + 1;

export const ProfileMonthlyView = props => {
  const { profileDetails } = props;

  const handleBenefitsMapping = () => {
    if (profileDetails?.selectedSopPlan?.qualificationRules &&
      profileDetails?.selectedSopPlan?.qualificationRules.length === 0) {
      return (profileDetails?.selectedSopPlan?.benefits?.reverse().map((benefit, index) => {
        return (
          <li key={`sop-profile-details-benefits-${index}`}><span>{benefit.description}</span></li>
        )
      }))
    }
    else {
      return (profileDetails?.selectedSopPlan?.benefits?.map((benefit, index) => {
        return (
          <li key={`sop-profile-details-benefits-${index}`}><span>{benefit.description}</span></li>
        )
      }))
    }
  }
  
  return (
    <div className='row no-margin-row monthly_benefits'>
      <div className='col-12 month_section'>
        <div className='row no-margin-row justify-left'>
          {profileDetails?.selectedSopPlan?.elementTemplates.map((elements, index) => {
            return (
              <div className={`col-2 month_view ${elements?.statusView}`} key={`sop-profile-details-elements-${index}`}>
                <div className='row'>
                  {elements?.statusView ?
                    <div className='elements'>
                      <img
                        src={`/images/sop/profile-details-${elements?.statusView}.svg`} />
                     </div> :
                    <div className='elements'> {elements?.elementSequence}</div>}

                  <div className={`months ` + (elements?.processingMonth === month ? 'active' : '')}> {commonConstant.SHORTMONTHS.th[elements?.processingMonth - 1]}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='col-12 benefit section'>
        <div className='col-1'>
          <img
            src={"/images/sop/icon-discount-blue.svg"} />
        </div>
        <div className='col-10'>
          {handleBenefitsMapping()}
        </div>
      </div>
    </div>
  )
}

export default ProfileMonthlyView;