import React from 'react';

import './InputNumber.scss';

export const InputNumber = ({ onChange,max,min,disableMinus,disablePlus,value, ...rest }) => {
  const isMinusDisable = disableMinus || value <= min;
  const isPlusDisable = disablePlus || value >= max;
  return (
    <div className="input-number-container">
      <button className={isMinusDisable && 'disable'} onClick={!isMinusDisable && onChange ? () => onChange(value - 1) : null}>-</button>
      <input {...rest} value={value} max={max} min={min} type="number" onChange={(e) => onChange ? e.target.value >= max ? onChange(max) : onChange(e.target.value) : null} />
      <button className={isPlusDisable && 'disable'} onClick={!isPlusDisable && onChange ? () => onChange(value + 1) : null}>+</button>
    </div>
  );
};
