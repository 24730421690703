import React from 'react';
import PropTypes from 'prop-types';
import './RoundChip.scss';

const RoundChip = ({ children, isActive }) => {
  return (
    <div className={`quiz-tab-single ${isActive ? 'quiz-tab-active' : ''}`}>
      <button className="btn-white">{children}</button>
    </div>
  );
};

RoundChip.propTypes = {
  children: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default RoundChip;
