import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import {
  getDateFormat,
  getPriceFormat,
  getPromotionById,
  getTimeFormat,
} from '../../../services/promotion/utils.service';
import ModalImage from '../../modal/ModalImage';
import '../../promotion/CartPromotion.scss';
import ExpanderComponent from '../ExpanderComponent';
import { ABO_MEMBER } from '../SelectProduct/PromotionProduct';
import { commonConstant } from '../../../common';
import CartIcon from '../CartIcon';

export const history = createBrowserHistory();

const header = {
  promotion: { icon: '/images/promotion/image_empty.png' },
  detail: { text: 'รายละเอียด', icon: '/images/promotion/icon_detail.png' },
  paymentDetail: {
    text: 'รายละเอียดในการจำหน่าย',
    icon: '/images/promotion/icon_date_detail.png',
  },
  productPromotion: {
    text: 'ระยะเวลาในการจำหน่าย',
    icon: '/images/promotion/image_empty.png',
  },
  recommendedPromotion: {
    text: 'สินค้าในโปรโมชัน',
    icon: '/images/promotion/image_empty.png',
  },
  condition: {
    text: 'หมายเหตุ/เงื่อนไข',
    icon: '/images/promotion/icon_consent.png',
  },
};

export const PromotionDetail = () => {
  const [promotion, setPromotion] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectImage, setSelectImage] = useState('');

  const customer = JSON.parse(sessionStorage.getItem('customer'));

  useEffect(() => {
    const urlParams = queryString.parse(history.location.search);
    const promotionId = urlParams.id;

    if (!promotionId) {
      return alert('ไม่พบข้อมูลโปรโมชั่น');
    }

    const fetchData = async () => {
      const promotionData = await getPromotionById(promotionId);
      setPromotion(promotionData);
    };
    fetchData();
  }, []);

  const displayPromotionAvailables = () => {
    const { startDateTime, endDateTime } = promotion.availables[0];
    const startDate = getDateFormat(startDateTime);
    const startTime = getTimeFormat(startDateTime);
    const endDate = getDateFormat(endDateTime);
    const endTime = getTimeFormat(endDateTime);
    return `${startDate} เวลา ${startTime} ถึง ${endDate} เวลา ${endTime}`;
  };

  const redirects = (id='') => {
    const urlParams = queryString.parse(history.location.search);
    const promotionId = urlParams.id;
    const redirectUrl = `${commonConstant.pathProductPromotion}?id=${promotionId}&productId=${id}`;
    window.location.href = redirectUrl;
  };

  const displayProductPromotion = () => {
    return promotion.relatedProducts.map((product, index) => {
      let aboPrice = product.prices.find((value) => value.type === ABO_MEMBER);
      const img = product?.galleryImages[0]?.image_url;
      aboPrice = parseInt(aboPrice.price);
      return (
        <div key={index}>
          <div className="desc-product-container">
            <div className="flex desc-product-div">
              <div className="img" style={{height: '130px', width: '130px'}}>
                <img
                    className="w-h-100"
                    alt={product.identifier}
                    src={img ? img : header.productPromotion.icon}
                    onClick={() => {
                      img && setModalIsOpen(true);
                      setSelectImage(img);
                    }}
                  />              
                </div>

              <div className='desc-product-id'>
                <div className="product-id font-stock font-dark-gray pb_5">{`#${product.id}`}</div>
                <div className="image-detail-name font-stock font-bold pb_5">{product.identifier}</div>
                <div className="product-price font-stock font-bold pb_5">{`฿ ${getPriceFormat(aboPrice)}`}</div>
                {customer?.type.toUpperCase() === ABO_MEMBER &&
                <div className="product-pvbv font-stock font-dark-gray pb_5">PV {`${product?.amwayValue?.pointValue.toLocaleString()}`} / BV {`${product?.amwayValue?.businessVolume.toLocaleString()}`}</div>
                }
              </div>
            </div>
            <div className="text-center pb-20">
              <button style={{width: '90%'}} className={`away-button active`} onClick={() => redirects(product.id)} >ซื้อเลย</button>
             </div>
          </div>
        </div>
      );
    });
  };

  if (!promotion) {
    return (
      <LoadingOverlay
        text="Loading"
        spinner
        active={true}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
    );
  }

  return (
    <section>
      <ModalImage
        modalIsOpen={modalIsOpen}
        setOpenModal={setModalIsOpen}
        viewImageSrc={selectImage}
      />
      <div className="product-panel-promotion scroll">
        <div className='promotion-banner'>
          <img
            alt="banner"
            className="w-h-100"
            src={promotion?.galleryImages[0]?.image_url || header.promotion.icon}
          />
          <CartIcon />
        </div>
        <div className="promotion-section">
            <div className='product-section-div'>
              <h1 className="font-bold font-stock promotion-heading">{promotion.name}</h1>
              <div className='product-section-promo'>{displayPromotionAvailables()}</div>
              <div className="product-section-summary" style={{padding: '5px 0px'}} dangerouslySetInnerHTML={{ __html: promotion.shortDescription }}></div>
            </div>

            <div className='product-section-div'>
              <div className="font-bold font-stock product-section-heading">
                  <img
                    src="/images/promotion/calendar.svg"
                  />{header.productPromotion.text}
              </div>
              <div className="product-section-summary" dangerouslySetInnerHTML={{ __html: promotion.summary }}></div>
            </div>

            <div style={{background: '#FFF1E8', marginBottom: '10px'}}>
              <div className="font-bold font-stock product-section-recommend">
                  <img
                    src="/images/promotion/announcement.svg"
                  />{header.recommendedPromotion.text}
                  <span>{`สินค้าเข้าร่วม ${promotion.relatedProducts && promotion.relatedProducts.length} รายการ`}</span>
              </div>
              <div style={{ width: '100%', overflow: 'scroll'}}>
                {displayProductPromotion()}
              </div>
            </div>

            <div className='product-section-div'>
              <div className="font-bold font-stock product-section-heading">
                <img
                  src="/images/promotion/notes.svg"
                />{header.condition.text}
              </div>
              <div className="product-section-summary" style={{padding: '0px 20px'}}  dangerouslySetInnerHTML={{ __html: promotion.condition }}></div>
            </div>
        </div>
      </div>

      <div className="product-panel-footer">
        <div className="select-promotion-footer font-larger">
        <div className="text-center">
          <button className={`away-button active`} onClick={() => redirects()} >สั่งซื้อสินค้า</button>
        </div>
        </div>
      </div>
      
    </section>
  );
};

export default PromotionDetail;
