import React, { useEffect, useRef, useState } from "react";
import "./HomeExerciseLog.scss";
import { HOME_PAGE, intervalArray, MAX_HEIGHT } from "../../../../common/homePageConstants";
import variables from '../../../../common/commonConstant.scss';
import { round } from "../../../../services";
import { checkIsBeforeNoon, createInterval, encapsulatePercent, timeToMinutes } from "../../../../utill.func";

const HomeExerciseLog = ({ exerciseData, exerciseTimeLogList}) => {
    const totalCalories = exerciseData?.total;
    const percent = round((exerciseData?.consumed * 100) / exerciseData?.total, 0);
    const innerGraphColor = percent == 0 ? 'transparent' : variables.homeExerciseCircle;
    const barRef = useRef(null);
    const EXERCISE_LOG_TIME_DATA = {};
    const calculateBarHeight = (value) =>{
        const intermediateValue = totalCalories / MAX_HEIGHT;
        const calculatedHeightRatio = round((value / intermediateValue),0);
        const height = Math.min(calculatedHeightRatio, MAX_HEIGHT);
        return `${height}px`;
    }
    for (let hour = 0; hour < 24; hour += 4) {
        const key = `${String(hour)}:00`;
        EXERCISE_LOG_TIME_DATA[`interval_${hour / 4 + 1}`] = {
            key: key,
            intervals: createInterval(hour, intervalArray)
        };
    }
    exerciseTimeLogList.forEach(exercise => {
        const startMin = timeToMinutes(exercise?.startTime);
        const endMin = timeToMinutes(exercise?.endTime);

        Object.values(EXERCISE_LOG_TIME_DATA).forEach(intervalData => {
            intervalData.intervals.forEach(interval => {
                const intervalStartMin = timeToMinutes(interval.startTime);
                const intervalEndMin = timeToMinutes(interval.endTime);

                if (startMin < intervalEndMin && endMin > intervalStartMin) {
                    interval.caloriesBurn += exercise.caloriesBurn;
                }
            });
        });
    });

    const staticExerciseLines = (index) => [0, 5, 10].includes(index);

    const firstThreeIntervals = checkIsBeforeNoon()?  Object.keys(EXERCISE_LOG_TIME_DATA).slice(0, 3):Object.keys(EXERCISE_LOG_TIME_DATA).slice(3, 6);
    return (
        <div className="home-exercise-log-container">
            <div className="excercise-card-container white-card-container">
                <div className="white-card-title">
                    {HOME_PAGE.EXERCISE_CARD_TITLE}
                </div>

                <div className="home-exercise-graph">
                {firstThreeIntervals.map((intervalKey, index) => {
                        const interval = EXERCISE_LOG_TIME_DATA[intervalKey].intervals;
                        const keys = EXERCISE_LOG_TIME_DATA[intervalKey].key;
                        return interval.map((interval, intervalIndex) => (
                            <>
                            {staticExerciseLines(intervalIndex) && (<div className={`excercise-line-wrapper`}><div className={`home-exercise-lines`}></div> <div className='home-exercise-line-text'>{keys}</div></div>)}
                            <div className={`excercise-line-wrapper ${interval?.caloriesBurn > 0 ? 'calorie': ''}`}>
                            <div className={`home-exercise-lines`} ref={barRef} style={{ height: interval?.caloriesBurn > 0 ? calculateBarHeight(interval?.caloriesBurn) : '37px' }}></div>
                            </div>
                            </>
                            ))

                    })}
                </div>
                <div className="excercise-details-container">
                    <div class="progress-bar-circular">
                        <div className="card progress-card progress-card-green-1">
                            <div className="outer-circle">
                                <div className="percent">
                                    <svg>
                                        <defs>
                                            <linearGradient id="home-exercise-gradient"
                                                gradientTransform="rotate(52.77)">
                                                <stop offset="0%" stopColor={innerGraphColor}></stop>
                                                <stop offset="17.44%" stopColor={variables.homeExerciseCircle3}></stop>
                                                <stop offset="51.37%" stopColor={variables.homeExerciseCircle}></stop>
                                                <stop offset="81.46%" stopColor={variables.homeExerciseCircle2}></stop>
                                            </linearGradient>
                                        </defs>
                                        <circle cx="23" cy="23" r="17.5"></circle>
                                        <circle cx="23" cy="23" r="17.5"
                                            style={{ "--percent": encapsulatePercent(percent), stroke: 'url(#home-exercise-gradient)' }}></circle>
                                    </svg>
                                    <div className="number">
                                        <div className="progress-value">
                                            {encapsulatePercent(percent)}
                                            <span className="progress-value-percent">%</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="excercise-description">
                        <div class="used-value">{exerciseData?.consumed || '-'}</div>
                        <div className="target">
                            <div class="total-value">{`/${exerciseData?.total || '-'}`}</div>
                            <div className="unit">{HOME_PAGE.EXERCISE_CARD_UNIT}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeExerciseLog;