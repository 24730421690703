import React from 'react';
import './Checkbox.scss';

export const Checkbox = ({
  children,
  checked = false,
  className,
  containerClassname,
  checkmarkColor = '#2C2C2C',
  onClick,
  svg,
}) => (
  <div className={`checkbox-container ${containerClassname}`}>
    <label onClick={onClick}>{children}</label>
    <input
      className={className}
      type="checkbox"
      checked={checked}
      onClick={onClick}
      onChange={() => {}}
    />
    <span className="checkmark" onClick={onClick}>
      {svg ? (
        svg
      ) : (
        <svg
          width="12"
          height="9"
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.85273 1.09275C10.2524 0.69304 10.9005 0.69304 11.3002 1.09275C11.6999 1.49247 11.6999 2.14053 11.3002 2.54024L5.15904 8.68142C4.75933 9.08113 4.11126 9.08113 3.71155 8.68142L0.299785 5.26965C-0.0999283 4.86994 -0.0999283 4.22188 0.299785 3.82217C0.699498 3.42245 1.34756 3.42245 1.74727 3.82217L4.43529 6.51019L9.85273 1.09275Z"
            fill={checkmarkColor}
          />
        </svg>
      )}
    </span>
  </div>
);
