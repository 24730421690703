import React, { useState, useEffect } from 'react';
import ExerciseLogHeader from '../exerciseLogHeader/ExerciseLogHeader';
import { CalendarLoader, HealthCategoryCarousel } from '../../../../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchExerciseCategories,
  fetchExerciseMenuListById,
} from '../../../../../actions';
import ExerciseRecommendedList from '../exerciseRecommendedList/ExerciseRecommendedList';
import './ExerciseCategoryList.scss';
import { ExerciseCategory } from '../../../../../model/GoalSetting.model';
import { useLocation } from 'react-router-dom';

const ExerciseCategoryList = ({ routeTo }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const exerciseCatId = params.get('exerciseCatId');
  const [loading, setLoading] = useState(false);
  const { exerciseCategoryList, exerciseMenuList } = useSelector(
    (state) => state?.exerciseLog,
  );
  const noCategorySelectedValue = new ExerciseCategory({ _id: '', name: '' });
  const [selectedCategory, setSelectedCategory] = useState(
    noCategorySelectedValue,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const fetchData = async () => {
        setLoading(true);
        dispatch(await fetchExerciseCategories()).finally(() => {
          setLoading(false);
        });
      }
      fetchData();
    }
    catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    try {
      const fetchData = async () => {
        setLoading(true);
        dispatch(await fetchExerciseMenuListById(exerciseCatId)).finally(() => {
          setLoading(false);
        });
      }
      if (exerciseCatId) {
        fetchData();
      }
    } catch (error) {
      setLoading(false);
    }

  }, [exerciseCatId]);

  useEffect(() => {
    if (exerciseCatId && exerciseCategoryList?.length) {
      exerciseCategoryList.forEach((cat) => {
        if (cat.id === exerciseCatId) {
          setSelectedCategory(cat);
        }
      })
    }
  }, [exerciseCatId, exerciseCategoryList]);

  const handleCategoryClick = (item) => {
    setSelectedCategory(item);
    routeTo("pathHnwExerciseCategoryList", `?exerciseCatId=${item.id}`)
  }

  return (
    <>
      <CalendarLoader showLoading={loading} />
      <div className="exercise-listing-container">
        {(<><div className="exercise-log-home-header">
          <ExerciseLogHeader routeTo={routeTo} />
        </div>
          <div className="activity-category-container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="activity-list-container">
                    <div className="activity-list-container-wrapper slick-slider">
                      <HealthCategoryCarousel
                        data={exerciseCategoryList}
                        selectedCategory={selectedCategory}
                        onClick={handleCategoryClick}
                      />
                    </div>
                  </div>
                  <div className={`exercise-list-container-banner`}><img src={selectedCategory?.bannerImage} /></div>
                  {selectedCategory?.id && (
                    <div className="recommended-exercise">
                      <ExerciseRecommendedList
                        isRecommended={true}
                        exerciseMenuId={selectedCategory.id || ''}
                        routeTo={routeTo}
                        data={exerciseMenuList}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div></>)}
      </div>
    </>
  );
};

export default ExerciseCategoryList;
