import {
    FETCH_DATA_REQUEST, FETCH_POLICIES_STATUS_SUCCESS, FETCH_POLICIES_STATUS_FAILURE,
    FETCH_ASSESSMENT_DATA_SUCCESS, FETCH_ASSESSMENT_DATA_FAILURE,
    UPDATE_POLICIES_STATUS, GET_POLICIES_DATA
} from "../actions/hnwHome.action";

const initialState = {
    loading: false,
    bmiData: null,
    policies: [],
    policyStatus: false,
    error: null
};

const hnwHomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_POLICIES_STATUS_SUCCESS:
            return { ...state, loading: false, policyStatus: action.payload };
        case UPDATE_POLICIES_STATUS:
            return { ...state, loading: false, policyStatus: action.payload };
        case FETCH_POLICIES_STATUS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case FETCH_ASSESSMENT_DATA_SUCCESS:
            return { ...state, loading: false, assessmentList: action.payload };
        case FETCH_ASSESSMENT_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case GET_POLICIES_DATA:
            return { ...state, policies: action.payload };
        default:
            return state;
    }
};

export default hnwHomeReducer;