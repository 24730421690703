import React,{ useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { InputNumber } from '../../../helpers';

import './SOPDetail.scss';

const Choices = props => (
  <div className="choice-container">
    <span className="name">
      {props.detail.name}
    </span>
    <InputNumber disablePlus={props.isPlusDisable} value={props.value ? props.value : 0} min={0} max={props.max} onChange={val => props.onChange({ [props.detail.name]: val })} /> 
  </div>
);

export const SOPDetail = () => {
  const selectedSOP = useSelector(state => state.selectedSOP);
  const SOPCart = useSelector(state => state.SOPCart);
  const [values, setValues] = useState({});

  useEffect(() => {
    console.log('values', values);
  }, [values]);

  const isBuyable = status => status === 'notbought' || status === 'compensated';
  const selectedCount = vals => Object.values(vals).reduce((acc,val) => acc + val); 
  const isPlusDisable = Object.values(values).length !== 0 && selectedCount(values) >= selectedSOP.choices.max;
  
  const handleChange = (data) => {
    const newData = { ...values, ...data };
    if (Object.values(values).length !== 0){
      if (selectedCount(values) < selectedSOP.choices.max || selectedCount(newData) < selectedCount(values)){
        setValues(newData);
      }
    } else {
      setValues(newData);
    }
  };

  const renderCardStatus = status => {
    switch (status) {
      case 'bought':
        return <span className="buy-status success">ซื้อแล้ว</span>;
      case 'notbought':
        return <span className="buy-status danger">ไม่ได้ซื้อ</span>;
      case 'compensated':
        return <span className="buy-status compensated">ชดเชย</span>;
      case 'postponed':
        return <span className="buy-status postponed">ผ่อนผัน</span>;
      case 'discount':
        return <span className="buy-status discount">ส่วนลด</span>;
      case 'cancelled':
        return <span className="buy-status">ยกเลิก</span>;
      default:
        break;
    }
  };

  const renderChoices = (choices) => choices.value.map((obj,idx) => <Choices isPlusDisable={isPlusDisable} key={idx} detail={obj} max={choices.max} value={values[obj.name]} onChange={handleChange}/>);

  if (isEmpty(selectedSOP)){
    return <div>Loading...</div>;
  }
  return <div className="sopdetail-container">
    <div className="header">
      <div className="title-container">
        <div className="title">SOP ของฉัน</div>
        <div className="right">
          <i className="fas fa-share-alt" />
          <i className="fas fa-shopping-bag" />
          <span>{SOPCart.length}</span>
        </div>
      </div>
    </div>
    <div className="body">
      <img src="https://via.placeholder.com/150" />
      <div className="header">
        <div className="title">
          {selectedSOP.title}
        </div>
        <div className="sub-title">
          <div className="info-container">
            <div className="id">รหัส # {selectedSOP.id}</div>
            <div className="status-container">
              <span className="count">{selectedSOP.count.round} ({selectedSOP.count.current}/{selectedSOP.count.required})</span>
              <span className="status">{renderCardStatus(selectedSOP.status)}</span>
            </div>
          </div>
          <div className="payment-container">
            <div className="price-info">
              <div className="price">฿ {selectedSOP.price}</div>
              <div className="bonus">+ {selectedSOP.bonus.pv} PV / {selectedSOP.bonus.bv} BV</div>
            </div>
            <div className="payment-info">
              <span>{selectedSOP.cardInfo.type === 'visa' ? <img src="/images/payment/visa.svg" /> : <img src="/images/payment/mastercard.svg" /> } {selectedSOP.cardInfo.cardNo}</span>
              <span>ทุกวันที่ {selectedSOP.renewalDay}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="body">
        <div className="choice-header">
          <div className="header">ตัวเลือก : {selectedSOP.choices.name}</div>
          <div>{Object.values(values).length !== 0 ? selectedCount(values) : '0'} / {selectedSOP.choices.max}</div>
        </div>
        <div className="content-container">
          {
            renderChoices(selectedSOP.choices)
          }
        </div>
      </div>
      <div className="footer">
        <div className="flex">
          <InputNumber /> 
          <button className={`register-next-button ${isBuyable && 'active'}`}>เพิ่มลงตระกร้า</button>
        </div>
      </div>
    </div>
  </div>;
};
