import config from '../config';
import axios from 'axios'

export const sopCartService = {
  getSOPCart,
  updateSOPCart,
};

async function getSOPCart(uid) {
  const result = await axios.get(`${config.API_URL}/sop-cart/${uid}`)

  return result.data
}

async function updateSOPCart(uid, cart) {
  const result = await axios.patch(`${config.API_URL}/sop-cart/${uid}`, cart)

  return result.data
}