import React, { useState, useEffect } from 'react';
import { Input, Upload, Form, Button, Image, Spin, Modal } from 'antd';
import { UploadOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import ModalCategorySuccess from '../modal/modalCategorySuccess';
import ModalCategoryRemove from '../modal/modalCategoryRemove';
import ModalCategoryRemoveSuccess from '../modal/modalCategoryRemoveSuccess';
import { useHistory, useLocation } from 'react-router-dom';
import config from '../../../config';
import ImgCrop from 'antd-img-crop';
import {
  createAboCategory,
  updateAboCategory,
  uploadImageLibaryCategory,
} from '../../../services/library/categories/categories.service';
import { getAboContentsByCategoryId } from '../../../services/library/contents/contents.service';
import {
  EFormAction,
  EImageType,
  EModalTitle,
  EStatus,
} from '../common.library';

const LibraryCategoryForm = () => {
  const [form] = Form.useForm();
  const [categoryCoverImage, setCategoryCoverImage] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [category, setCategory] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [isImageChange, setIsImageChange] = useState(true);
  const [visibleModalRemove, setVisibleModalRemove] = useState(false);
  const [visibleModalRemoveSuccess, setVisibleModalRemoveSuccess] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidateImage, setIsValidateImage] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const isReloadPage = location?.state?.isReloadPage ?? false;
  useEffect(() => {
    if (isReloadPage) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const categoryId = new URLSearchParams(location.search).get('id');
    if (categoryId) {
      fetchData(categoryId);
    }
  }, []);

  useEffect(() => {
    if (categoryCoverImage.length > 0) {
      getPreviewImage();
    }
  }, [categoryCoverImage]);

  const fetchData = async (id) => {
    setLoading(true);
    const response = await getAboContentsByCategoryId(null, id);
    setCategory(response);
    setDataToForm(response);
    setImageUrl(`${config.S3_URL}${response.imageFlex}`);
    setLoading(false);
  };

  const setDataToForm = (category) => {
    form.setFields([{ name: 'name', value: category.name ?? '' }]);
    setIsValidateImage(category?.imageContent || categoryCoverImage === null);
  };

  const onChangeUploadCategoryImage = ({ file, fileList }) => {
    setCategoryCoverImage(fileList);
    setIsImageChange(true);
    setIsValidateImage(false);
  };

  const dummyUploadRequest = async (options) => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const getPreviewImage = async () => {
    const previewimg = await getBase64(categoryCoverImage[0].originFileObj);
    setImageUrl(previewimg);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const setFormDataToApiBody = (value) => {
    const apiBody = {
      name: value.name,
      status: EStatus.PUBLISH,
    };
    return apiBody;
  };

  const onValidateType = (_, value) => {
    if (!value && isValidateImage) {
      return Promise.reject(new Error('กรุณาอัพโหลดรูป'));
    }
    if (value) {
      const isImageTpye =
        value.fileList[0].type === EImageType.JPG ||
        value.fileList[0].type === EImageType.PNG ||
        value.fileList[0].type === EImageType.JPEG;

      if (!isImageTpye) {
        return Promise.reject(new Error('รับเฉพาะไฟล์ประเภท: JPG, PNG'));
      }
    }
    return Promise.resolve();
  };

  const onFinish = async (value) => {
    const categoryId = new URLSearchParams(location.search).get('id');
    const action = !categoryId ? EFormAction.CREATE : EFormAction.UPDATE;
    setLoading(true);
    const apiBody = setFormDataToApiBody(value);
    try {
      const body = {
        ...apiBody,
      };
      if (isImageChange) {
        if (categoryCoverImage[0]?.originFileObj) {
          const imageUrl = await uploadImageLibaryCategory({
            image: categoryCoverImage[0]?.originFileObj,
            name: 'category',
          });
          body.imageFlex = imageUrl.imageLink;
        }
      }

      if (action === EFormAction.CREATE) {
        const create = await createAboCategory(body);
        setCategory(create);
      }

      if (action === EFormAction.UPDATE) {
        const update = await updateAboCategory(categoryId, body);
        setCategory(update);
      }

      setVisibleModal(true);
    } catch (error) {
      let title = 'มีบางอย่างผิดพลาด';
      if (error.status === 400) {
        title = error.data?.message;
      }
      Modal.error({
        title,
      });
    }
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <div className="category-form-wrap">
        <Form form={form} onFinish={onFinish} className="category-flex-form">
          <div className={`upload-category-img ${imageUrl ? 'active' : ''}`}>
            <Image
              className={imageUrl ? 'active' : ''}
              width={'100%'}
              src={imageUrl}
              preview={false}
            />
            <Form.Item
              name="images"
              rules={[
                {
                  required: isValidateImage,
                  validator: onValidateType,
                },
              ]}
            >
              <ImgCrop>
                <Upload.Dragger
                  name="files"
                  accept="image/*"
                  multiple={false}
                  className={['upload-file-area', imageUrl ? 'active' : '']}
                  maxCount={1}
                  fileList={categoryCoverImage}
                  onChange={onChangeUploadCategoryImage}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                  customRequest={dummyUploadRequest}
                >
                  {imageUrl ? (
                    <div>
                      <div className="ant-upload-hint">
                        <p>
                          <EditFilled /> แก้ไขรูปภาพ
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <div className="ant-upload-hint">
                        <p>อัพโหลดรูปภาพปก Category</p>
                        <p>(ขนาดไฟล์รูปภาพไม่เกิน 2 MB.) </p>
                      </div>
                    </div>
                  )}
                </Upload.Dragger>
              </ImgCrop>
            </Form.Item>
          </div>
          <div className="category-name">
            <Form.Item
              name="name"
              label="ชื่อ"
              rules={[
                {
                  required: true,
                  message: 'กรุณาใส่ชื่อหมวดหมู่',
                },
              ]}
            >
              <Input placholder="New Category" maxLength={50}></Input>
            </Form.Item>
          </div>
          <div className="action">
            <Form.Item>
              <div className="action-edit-category">
                <Button className="btn" htmlType="submit">
                  บันทึก
                </Button>
                {category && (
                  <Button
                    className="btn-outline"
                    onClick={() => {
                      setVisibleModalRemove(true);
                    }}
                  >
                    <DeleteFilled />
                  </Button>
                )}
              </div>
            </Form.Item>
            <Button
              type="link"
              onClick={() => {
                history.go(-1);
              }}
            >
              ยกเลิก
            </Button>
          </div>
        </Form>
        <ModalCategorySuccess
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          category={category}
          title={
            category ? EModalTitle.UPDATE_SUCCESS : EModalTitle.CREATE_SUCCESS
          }
        />
        <ModalCategoryRemove
          visibleModal={visibleModalRemove}
          setVisibleModal={setVisibleModalRemove}
          setVisibleModalRemoveSuccess={setVisibleModalRemoveSuccess}
          category={category}
          setLoading={setLoading}
        />
        <ModalCategoryRemoveSuccess
          visibleModal={visibleModalRemoveSuccess}
          setVisibleModal={setVisibleModalRemoveSuccess}
        />
      </div>
    </Spin>
  );
};
export default LibraryCategoryForm;
