import React from "react";
import { CHALLENGE, teamMaxSize } from "../../../../common/challengeConstants";
import { RectBadge, SlickCarousel, TeamInfoCard } from "../../../../common";
import { getPhoneFormat } from "../../../../services/promotion/utils.service";
import './TeamInfoCarousel.scss';

const TeamInfoCarousel = ({
    teamSize,
    teamDashboardStatus,
    teamData,
    badgeClassname,
    redirectionMyDashboard,
    challengeUserData
}) => {

    const getUserProfileUrl = (partyId) => {
        const userProfileInfo = challengeUserData.find(data => data?.partyId === partyId);
        return userProfileInfo?.pictureUrl;
    }

    return (
        <div class="col-12 card-ongoing-outer">
            <div class="card-ongoing-title-wrapper">
                <div class="card-ongoing-title">{CHALLENGE.teamDashboard.teamInfoHeading}</div>
                {teamSize < teamMaxSize &&

                    <RectBadge badgeClassname={badgeClassname}>
                        <div className='rect-badge-title'>{`${CHALLENGE.teamDashboard.missingTeam} ${CHALLENGE.teamDashboard.maxTeamMembers - teamSize || ''} ${CHALLENGE.teamDashboard.membersTeam}`}</div>
                    </RectBadge>}
            </div>
            <SlickCarousel
                slidesToShow={1}
                slidesToScroll={1}
            >
                {teamData?.map((user) => {
                    return (
                        <div onClick={() => redirectionMyDashboard(user?.partyId)}>
                        <TeamInfoCard
                            userName={user.userName}
                            userId={user.aboId}
                            phoneNo={user.phoneNumber ? getPhoneFormat(user.phoneNumber.toString()) : ''}
                            watchName={user.userDevice}
                            stepsTakenToday={user.todaySteps}
                            totalStepsToday={CHALLENGE.teamDashboard.totalStepsToBeTakenToday}
                            cumulativeSteps={user.userTotalSteps}
                            bmiValue={user?.hasUserWeighOut ? user.weightOutUserData?.bmi : user.registerUserData?.bmi}
                            rankImage={user.userRank ? `/images/challenge/icon-challenge-rank_${user.userRank}.svg` : ''}
                            pictureUrl={getUserProfileUrl(user?.partyId) || '/images/challenge/default_challenge_profile.svg'}
                            userRankData={user.userRank}
                            isLeader={user.isLeader}
                            isNewMember={user.isNewMember}
                            isNewAbo={user.isNewAbo}
                            teamDashboardStatus={teamDashboardStatus}
                            bmiHeading={user?.hasUserWeighOut? CHALLENGE.teamDashboard.bmiweighOutHeading: CHALLENGE.teamDashboard.bmiHeading}
                        />
                        </div>
                    )
                })}
            </SlickCarousel>
        </div>
    )
}

export default TeamInfoCarousel;