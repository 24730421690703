import { LeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Input, Spin } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { commonConstant } from '../../../common';
import {
  getAboContents,
  getAmwayContent,
} from '../../../services/library/contents/contents.service';
import { libraryImageUrl, thaiDate } from '../../../utill.func';
import { EContentType } from '../common.library';
import './content.scss';

const LibraryContent = () => {
  const history = useHistory();
  const location = useLocation();
  const [contents, setContents] = useState([]);
  const [contentLoding, setContentLoading] = useState();
  const type = location.state.type;

  useEffect(() => {
    fetchData();
  }, []);

  const onSearch = async (values) => {
    setContentLoading(true);
    await fetchData(values.search);
    setContentLoading(false);
  };

  const viewContentDetail = (id) => {
    const path = commonConstant.pathLibraryViewContent;
    let queryParam;
    if (location.state.type === EContentType.AMWAY_LIBRARY) {
      queryParam = `Id=${id}`;
    } else {
      queryParam = `aboId=${id}`;
    }

    history.push({
      pathname: path,
      search: queryParam,
    });
  };

  const fetchData = async (query) => {
    setContentLoading(true);
    let contentData;
    if (type === EContentType.AMWAY_LIBRARY) {
      contentData = await getAmwayContent(query);
    }
    if (type === EContentType.MY_LIBRARY) {
      contentData = await getAboContents(query);
    }
    setContents(contentData);
    setContentLoading(false);
  };

  return (
    <div className="content-list-wrap">
      <div className="hl-header">
        <div
          className="back-page"
          onClick={() => {
            history.go(-1);
          }}
        >
          <LeftOutlined />
        </div>
        <div className="page-title-category">
          {type === EContentType.AMWAY_LIBRARY ? (
            <p>All Contents ({contents.length})</p>
          ) : (
            <p>My Contents ({contents.length})</p>
          )}
        </div>
      </div>

      <div className="content-list card-discovery-new ">
        <div className="search-input">
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ status: false }}
            onFinish={onSearch}
            autoComplete="off"
          >
            <Form.Item name="search">
              <Input placeholder="ค้นหา" prefix={<SearchOutlined />} />
            </Form.Item>
          </Form>
        </div>
        <Spin tip="Loading content..." spinning={contentLoding}>
          <div className="card-body">
            {contents &&
              contents.map((item, index) => (
                <div
                  className="card-item"
                  key={`${item}${index}`}
                  onClick={() => {
                    viewContentDetail(item._id);
                  }}
                >
                  <div className="card-item-img">
                    <img src={libraryImageUrl(item.imageContent)} />
                  </div>
                  <div className="card-desc">
                    <div className="title">{item.name}</div>
                    <div className="desc">
                      {type === 'AmwayLibrary'
                        ? thaiDate(item.createdDate, 'dd-MM-yyyy')
                        : item.aboCategoryId.name}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default LibraryContent;
