import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  DELETE_BUTTON_CONFIRM,
  multiplyFactorObject,
  nutritionValues,
  SAVE_MEAL_BUTTON,
  goalRoutesMap,
  HEALTH_GOAL_TYPES,
  FOOD_DETAILS_PICKER,
  FOOD_TYPE_MAPPER,
  foodTypeReferrers,
  SUPPLEMENT_LIST_MAPPING,
  FOOD_DETAILS_QTY_TITLE,
  FOOD_DETAILS_AMT_TITLE,
  FOOD_DETAILS_AMT_UNIT,
  FOOD_DETAILS_ENERGY_TITLE,
  FOOD_DETAILS_ENERGY_UNIT,
  FOOD_DETAILS_CONSUM_QTY_TITLE,
  FOOD_DELETE_MODAL_CANCEL,
  FOOD_DELETE_MODAL_DELETE,
  FOOD_DELETE_MODAL_HEADER,
  FOOD_DETAILS_DESCRIPTION_HEAD_TEXT,
} from '../../../../../common/commonConstant';

import FoodCalorieChart from '../hnwFoodCalorieChart/hnwFoodCalorieChart';
import './hnwFoodDetail.scss';
import { round } from '../../../../../services';
import { getFoodDetails } from '../../../../../actions/foodCalorieActions';
import { addGoalsSupplements } from '../../../../../actions/hnwGoalsActions';
import {
  getParamsString,
  getSuggestedGoalPayload,
} from '../../../../../utill.func';
import {
  HealthDeleteFoodModal,
  MobilePicker,
  CartAssessmentHeader,
  HnwSuccessOverlayModal,
  CalendarLoader,
} from '../../../../../common';
import {
  addFoodLog,
  deleteFoodAndSupplementProducts,
} from '../../../../../actions';
import addMenuSuccess from '../../../../../json/add-menu-success.json';
import { handleInfinity } from '../../../hnwFoodLog/components/utility';

const HnwFoodDetail = ({ routeTo, routeReplaceTo }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const isDelete = params.get('isDelete');
  const searchkey = params.get('search');
  const referrerPage = params.get('referrer');
  const referrerFrom = params.get('referrerFrom');
  const showSuccess = params.get('showSuccess');
  const activeFlow = params.get('activeFlow');
  const myFoodWeight = params.get('myFoodWeight');
  const myFoodWeightId = params.get('myFoodWeightId');
  const suggestedFoodGoal = params.get('suggestedFoodGoal');
  const suggestedFoodGoalObj = JSON.parse(
    decodeURIComponent(suggestedFoodGoal),
  );

  const [showSuccessModal, setShowSuccessModal] = useState(showSuccess);
  const isFromSupplementGoal =
    referrerPage === SUPPLEMENT_LIST_MAPPING.GOAL_REFERRER_VALUE;
  const isFromSupplementLog =
    referrerPage === SUPPLEMENT_LIST_MAPPING.LOG_REFERRER_VALUE;
  const isFromFoodLog = foodTypeReferrers.includes(referrerPage);

  const [foodDetails, setFoodDetails] = useState({});
  const [selectedScale, setSelectedScale] = useState(0);
  const [multiplyFactor, setMultiplyFactor] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isSelectorModalOpen, setIsSelectorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const nutritionMapper = {
    carbs: {
      img: 'icon-carb',
      class: 'progress-card-green-1',
      'icon-bg': 'icon-carb-lg',
    },
    protein: {
      img: 'icon-protein',
      class: 'progress-card-green-2',
      'icon-bg': 'icon-protein-lg',
    },
    fat: {
      img: 'icon-fat',
      class: 'progress-card-green-3',
      'icon-bg': 'icon-fat-lg',
    },
  };

  useEffect(() => {
    const didMount = async () => {
      await getFoodDetailsData();
    };
    didMount();
  }, []);

  const getFoodDetailsData = async () => {
    try {
      setLoading(true);
      dispatch(
        getFoodDetails({
          id,
          search: searchkey,
          isKeyword: searchkey ? true : false,
        }),
      )
        .then((res) => {
          setLoading(false);
          setFoodDetails(res);
          if (myFoodWeight && isDelete) {
            const updatedWeightFactor = handleInfinity(
              +myFoodWeight / res.weight,
            );
            const selectedWeightIndex = multiplyFactorObject.findIndex(
              (obj) => obj.value === updatedWeightFactor,
            );
            setSelectedScale(selectedWeightIndex);
            setMultiplyFactor(updatedWeightFactor);
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const setIndexAndMultiplyFactor = (value, index) => {
    if (!isDelete) {
      setSelectedScale(index);
      setMultiplyFactor(value);
    }
  };

  const calculateScale = (weight, multiply_factor) =>
    round(weight * multiply_factor);

  const renderWeightScales = () => (
    <div className="service-size-card-container">
      {multiplyFactorObject.map((values, index) => (
        <div
          key={`weight${index}`}
          className={`service-size-card-single ${selectedScale === index ? 'service-size-card-single-active' : ''
            }`}
          onClick={() => setIndexAndMultiplyFactor(values.value, index)}
        >
          <div className="service-size-card-title">
            <img
              src={`${selectedScale === index
                ? '/images/healthAndWellness/foodCalories/scale-active.svg'
                : '/images/healthAndWellness/foodCalories/scale-normal.svg'
                }`}
            />
            <span>กรัม</span>
          </div>
          <div className="service-size-card-value">
            {calculateScale(foodDetails?.weight, values.value)
              ? calculateScale(foodDetails?.weight, values.value)
              : '-'}
          </div>
        </div>
      ))}
    </div>
  );

  const renderNutritionValueCharts = () => (
    <div className="nutrition-chart-container">
      {nutritionValues.map((foodData, index) => (
        <div key={`nutrval${index}`} className="nutrition-chart-single">
          <div className="nutrition-chart-title-wrapper">
            <img
              src={`/images/healthAndWellness/foodCalories/${nutritionMapper[foodData.name].img
                }.svg`}
            />
            <div className="nutrition-chart-title">{foodData.label}</div>
          </div>
          <div
            className={`card progress-card ${nutritionMapper[foodData.name].class
              }`}
          >
            <FoodCalorieChart
              foodData={foodData}
              foodDetails={foodDetails}
              multiplyFactor={multiplyFactor}
              calculateScale={calculateScale}
              icon={nutritionMapper[foodData.name]['icon-bg']}
            />
          </div>
        </div>
      ))}
    </div>
  );

  const onModalClick = (type) => {
    onOKClick(FOOD_TYPE_MAPPER[type.foodType]);
  };

  const handleSaveClick = () => {
    if (isFromSupplementGoal) {
      handleAddSupplementGoal();
    } else if (isFromFoodLog || isFromSupplementLog) {
      onOKClick(FOOD_TYPE_MAPPER[referrerPage]);
    } else {
      setIsSelectorModalOpen(true);
    }
  };

  const handleAddSupplementGoal = () => {
    const { name, unit, foodImage, quantity } = foodDetails;
    setLoading(true);
    dispatch(
      addGoalsSupplements({
        data: {
          supplementId: id,
          name,
          image: foodImage,
          unit,
          quantity,
        },
      }),
    )
      .then((res) => {
        const supplementsGoalId = res?.id;
        const params = supplementsGoalId
          ? `?${getParamsString({
            supplementGoal_id: supplementsGoalId,
            referrerFrom,
          })}`
          : '';
        if (parseInt(activeFlow) === 1) {
          routeReplaceTo(
            goalRoutesMap[HEALTH_GOAL_TYPES.SUPPLEMENT],
            params.concat(`&activeFlow=${activeFlow}`),
          );
        } else {
          routeTo(goalRoutesMap[HEALTH_GOAL_TYPES.SUPPLEMENT], params);
        }
      })
      .finally(() => setLoading(false));
  };

  const onOKClick = async (type) => {
    //API call with food menu and type whether lunch, breakfast dinner
    try {
      setLoading(true);
      setIsSelectorModalOpen(false);
      let suggestGoalPayload;
      if (suggestedFoodGoalObj) {
        suggestGoalPayload = getSuggestedGoalPayload(suggestedFoodGoalObj);
      }
      await dispatch(
        addFoodLog(
          id,
          type,
          round(foodDetails?.weight * multiplyFactor),
          suggestGoalPayload,
        ),
      );
      setLoading(false);
      routeTo('pathFoodLogHome');
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleConfirmBtnClick = async () => {
    try {
      setLoading(true);
      setIsDeleteModalOpen(false);
      await dispatch(deleteFoodAndSupplementProducts(myFoodWeightId));
      setLoading(false);
      routeTo('pathFoodLogHome');
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="food-detail-page --no_scroll">
        {foodDetails?.foodImageUrl ? (
          <div className="food-details-banner">
            <CartAssessmentHeader
              leftIcon={
                '/images/healthAndWellness/foodLog/icons/chevron-icon.svg'
              }
              leftLabel={FOOD_DETAILS_PICKER.backLabel}
              headerRoute={'pathFoodCalories'}
              isSupplementRoute={isFromSupplementLog}
              isDelete={isDelete}
              referrerPage={referrerPage}
              routeTo={routeTo}
            />
            <img src={foodDetails?.foodImageUrl} className="w-100" />
          </div>
        ) : null}
        <div
          className={`food-details-description-card-container description-card-container-height${!foodDetails?.foodImageUrl
            ? ' no-box-shadow description-card-container-height-full'
            : ''
            }`}
        >
          {isDelete && (
            <span className="description-head-text">
              {FOOD_DETAILS_DESCRIPTION_HEAD_TEXT}
            </span>
          )}
          {foodDetails?.tagImage && (
            <div className="category-img-wrapper">
              <img className="tag-img" src={foodDetails?.tagImage} />
            </div>
          )}
          <br />
          <div className="food-details-description">
            <div className="food-details-title">{foodDetails?.name}</div>
            <div
              className={`${!foodDetails?.foodImageUrl
                ? 'scroll-content max-height'
                : 'scroll-content pb-60'
                }`}
            >
              <div className="food-details-quantity-container">
                <div className="food-details-quantity-title">
                  {FOOD_DETAILS_QTY_TITLE}
                </div>
                <div className="food-details-quantity-value-container">
                  <div className="food-details-quantity-value green">
                    {foodDetails?.quantity}
                  </div>
                  <div className="food-details-quantity-value-unit">
                    {foodDetails?.unit}
                  </div>
                </div>
              </div>
              <div className="line" />
              <div className="food-details-amount-energy-container">
                <div className="food-details-amount-container">
                  <div className="food-details-amount-title">
                    {FOOD_DETAILS_AMT_TITLE}
                  </div>
                  <div className="food-details-amount-value">
                    {calculateScale(foodDetails?.weight, multiplyFactor) ||
                      foodDetails?.weight ||
                      '-'}
                  </div>
                  <div className="food-details-amount-unit">
                    {FOOD_DETAILS_AMT_UNIT}
                  </div>
                </div>
                <div className="food-details-energy-container">
                  <div className="food-details-energy-title">
                    {FOOD_DETAILS_ENERGY_TITLE}
                  </div>
                  <div className="food-details-energy-value">
                    {calculateScale(foodDetails?.calorie, multiplyFactor) ||
                      foodDetails?.calorie ||
                      '-'}
                  </div>
                  <div className="food-details-energy-unit">
                    {FOOD_DETAILS_ENERGY_UNIT}
                  </div>
                </div>
              </div>
              {renderNutritionValueCharts()}
              <div className="consumption-quantity-container">
                <div className="consumption-quantity-title">
                  {FOOD_DETAILS_CONSUM_QTY_TITLE}
                </div>
                <div>{renderWeightScales()}</div>
              </div>
            </div>
          </div>
        </div>
        {/* button for selecting food meal category */}
        <div className="footer-container-wrapper">
          <img
            src="/images/healthAndWellness/exercise-log/footer-wave.svg"
            className="w-100 footer-curve-bg"
          />
          <div className="footer-container-btn-wrapper">
            {!isDelete ? (
              <button
                className="btn mt-0 btn-green"
                data-bs-toggle="modal"
                data-bs-target="#successModal"
                onClick={handleSaveClick}
              >
                {SAVE_MEAL_BUTTON}
              </button>
            ) : (
              <button
                className="btn mt-0 btn-red"
                data-bs-toggle="modal"
                data-bs-target="#deleteModalAndroid"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                {DELETE_BUTTON_CONFIRM}
              </button>
            )}
          </div>
        </div>
        <CalendarLoader showLoading={loading} />
        {/* modal for selecting food meal category */}
        {isSelectorModalOpen && (
          <MobilePicker
            modalHeight={255}
            pickerHeader={FOOD_DETAILS_PICKER.header}
            isOpen={isSelectorModalOpen}
            selections={{
              foodType: foodDetails?.isFood
                ? FOOD_DETAILS_PICKER.pickerTypes.slice(0, 3)
                : FOOD_DETAILS_PICKER.pickerTypes,
            }}
            defaultValue={{ foodType: FOOD_DETAILS_PICKER.defaultvalue }}
            onOKClick={onModalClick}
            showSelected={false}
          />
        )}
        {/* modal for deleting recorded entry */}
        {isDeleteModalOpen && (
          <HealthDeleteFoodModal
            isDeleteModalOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={handleConfirmBtnClick}
            cancelBtnMsg={FOOD_DELETE_MODAL_CANCEL}
            deleteBtnMsg={FOOD_DELETE_MODAL_DELETE}
            deleteHeaderMsg={FOOD_DELETE_MODAL_HEADER}
          />
        )}
      </div>
      {showSuccessModal && (
        <HnwSuccessOverlayModal
          show={showSuccessModal}
          onTimeout={() => setShowSuccessModal(false)}
          animationPath={addMenuSuccess}
        />
      )}
    </>
  );
};

export default HnwFoodDetail;
