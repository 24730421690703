import React from 'react';
import './BoxAssessment.scss';

const BoxAssessment = ({ text, image, isSelected, onClick, selectedNumber }) => {
  return (
    <div className={`box ${isSelected ? 'selected-box-border' : ''}`} onClick={onClick}>
      {isSelected && <div className="selected-number">{selectedNumber}</div>}
      <div className="box-content">
        <div className="box-text">{text}</div>
        <img className="box-image" src={image} alt="Box" />
      </div>
    </div>
  );
};

export default BoxAssessment;
