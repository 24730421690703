import { getCustomerAge } from '../services/healthWellness/healthWellness.service';

export class Customer {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.uid = data.uid || '';
      this.cid = data.cid || '';
      this.action = data.action || '';
      this.displayName = data.displayName || '';
      this.pictureUrl = data.pictureUrl || '';
      this.name = data.name || '';
      this.status = data.status || '';
      this.birthday = data.birthday || '';
      this.type = data.type || '';
      this.amwayNumber = data.amwayNumber || '';
      this.partyId = data.partyId || '';
      this.socialType = data.socialType || '';
      this.status = data.status || '';
      this.age = getCustomerAge() || '';
      this.gender = data.gender || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
      this.registerDate = data.registerDate || '';
      this.isTncAck = data.isTncAck || false;
    }
  }
}
