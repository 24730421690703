import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { HnWNumpadModal, Numpad, HnwLogStepper, HnwSuccessOverlayModal, CalendarLoader } from '../../../../common';
import { GOAL_TARGET_TEXT, GOALS_SUBMIT_BUTTON_TITLE, GOALS_WEIGHT_DETAILS_TEXT, H_W_VALIDATION, HEALTH_GOAL_TYPES, HNW_GOAL_SETTING } from '../../../../common/commonConstant';
import { getDataFromSession, thaiDate } from '../../../../utill.func';
import { HnwWeightInputBox } from '../components';
import { fetchGoalsWeight, updateGoalsWeight } from '../../../../actions/hnwGoalsActions';
import { fetchBmiData, getHnwAllHealhGoalList, updateBmiData, updateWaterGoalInHealthList } from '../../../../actions';
import './WeightGoal.scss';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();

export default function WeightGoal({ routeTo, routeReplaceTo }) {
  let WeightStepperArray = ['-0.5', '-1', '-1.5', '-2'];
  const MAX_WEIGHT_DIFFERENCE = 20;

  const dispatch = useDispatch();
  const [stepperValue, setStepperValue] = useState(WeightStepperArray[0]);
  const [openNumpad, setOpenNumpad] = useState(false);
  const [isDirty, setIsDirty] = useState({ currentWeight: false, targetWeight: false });
  const [weightInfo, setWeightInfo] = useState({ currentWeight: '', targetWeight: '' });
  const [currentInput, setCurrentInput] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isApiHit, setIsApiHit] = useState(false);
  const WeightGoalData = useSelector((state) => state.hnwGoals.weightGoal);
  const healthGoals = useSelector((state) => state.goalSetting.healthGoalList);
  const BMIWeight = useSelector((state) => state?.bmi?.weight);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const weightId = params.get(`${HEALTH_GOAL_TYPES.WEIGHT}_id`);
  const activeFlow = params.get("activeFlow");

  const isWeightOutsideLimit = Math.abs(weightInfo.currentWeight - weightInfo.targetWeight) > MAX_WEIGHT_DIFFERENCE;
  const isSubmitEnbabled = (weightInfo.currentWeight && weightInfo.targetWeight && !isWeightOutsideLimit)
  const OutsideLimitImage = <div className="hnw-goals-weight-details-suggestion-tooltip_image-wrapper">
    <img src="/images/healthAndWellness/goals/weight/machine.svg" />
    <img src="/images/healthAndWellness/goals/weight/icon-weight.svg" />
  </div>;

  const handleOKClick = () => {
    toggleNumpad(false);
  };

  const updateWeightInfo = (key, value) => {
    setWeightInfo(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleInputChange = (value) => {
    const regex = (H_W_VALIDATION.weight.pattern);
    const newValue = (weightInfo[currentInput] ?? '') + value;
    if (regex.test(newValue)) {
      setIsDirty({ ...isDirty, [currentInput]: true });
      updateWeightInfo(currentInput, isDirty[currentInput] ? newValue : value);
    }
  };

  const handleDelete = () => {
    const value = `${weightInfo[currentInput]}` ?? '';
    if (value) {
      const updatedValue = value.slice(0, -1);
      updateWeightInfo(currentInput, updatedValue);
    }
  };

  const renderNumpad = () => (
    <Numpad handleInputChange={handleInputChange}
      handleDelete={handleDelete}
      handleOKClick={handleOKClick}
    />
  );
  const toggleNumpad = (type) => {
    if (type) {
      if (type === 'targetWeight' && !weightInfo.currentWeight) {
        return;
      }
      setCurrentInput(type);
      updateWeightInfo(type, '');
      setOpenNumpad(true);
    }
    else {
      setOpenNumpad(false);
      setIsDirty({ ...isDirty, [currentInput]: false });
    }
  };

  const handleSuccessRedirect = () => {
    if (parseInt(activeFlow) === 1) {
      routeReplaceTo('pathHnwGoalsActivity', `?activeFlow=${activeFlow}`);
    } else {
      routeTo('pathHnwGoalsHome');
    }
    setIsApiHit(false);
  };

  useEffect(() => {
    if (WeightGoalData && Object.keys(WeightGoalData).length) {
      setWeightInfo({ currentWeight: WeightGoalData.weight, targetWeight: WeightGoalData.targetWeight });
      setStepperValue(WeightGoalData.targetWeightPerDay);
      isApiHit && setShowSuccess(true);
    }
    if (!BMIWeight) {
      setLoading(true);
      dispatch(fetchBmiData())
        .finally(() => setLoading(false));
    }

  }, [WeightGoalData]);

  useEffect(() => {
    BMIWeight && setWeightInfo({ targetWeight: WeightGoalData?.targetWeight ?? '', currentWeight: BMIWeight });
  }, [BMIWeight]);

  useEffect(() => {
    if (weightId) {
      setLoading(true);
      dispatch(fetchGoalsWeight(weightId))
        .finally(() => setLoading(false));
    }
    if (parseInt(activeFlow) === 1) {
      const customer = getDataFromSession(
        HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
      );
      if (customer?._id && (!healthGoals?.waterGoal || !healthGoals?.foodGoal || !healthGoals?.activityGoal)) {
        dispatch(getHnwAllHealhGoalList(customer?._id))
      }
    }
  }, []);

  const handleSubmitWeightGoal = () => {
    setIsApiHit(true);
    setLoading(true);
    dispatch(updateGoalsWeight({
      weightId, data: {
        weight: +weightInfo.currentWeight ?? '',
        targetWeight: +weightInfo.targetWeight ?? '',
        targetWeightPerDay: +stepperValue,
        weightGoalType: +weightInfo.currentWeight === +weightInfo.targetWeight ? GOAL_TARGET_TEXT.MAINTAIN_WEIGHT :
          (+weightInfo.currentWeight < +weightInfo.targetWeight ? GOAL_TARGET_TEXT.GAIN_WEIGHT : GOAL_TARGET_TEXT.LOSE_WEIGHT),
      }
    })).then(res => {
      healthGoals.weightGoal = res;
      dispatch(updateWaterGoalInHealthList(healthGoals));
      dispatch(updateBmiData({ weight: +weightInfo.currentWeight }));
    }).finally(() => setLoading(false));
  };

  if (weightInfo.currentWeight < weightInfo.targetWeight) {
    WeightStepperArray = WeightStepperArray.map((item) => item.replace('-', '+'));
  }

  const GoalTargetTextValue = (weightInfo.currentWeight < weightInfo.targetWeight) ? GOAL_TARGET_TEXT.GAIN_WEIGHT :
    (weightInfo.currentWeight > weightInfo.targetWeight ? GOAL_TARGET_TEXT.LOSE_WEIGHT : GOAL_TARGET_TEXT.MAINTAIN_WEIGHT);

  const CalculateTargetDate = () => {
    const goalDiffInKG = weightInfo.targetWeight - weightInfo.currentWeight;
    const weeksRequired = goalDiffInKG / stepperValue;
    const todaysDate = new Date();
    todaysDate.setDate(todaysDate.getDate() + (7 * weeksRequired));
    return todaysDate;
  };

  return (
    <div className="hnw-goals-weight-container">
      <img src="/images/healthAndWellness/goals/weight/goalSettingHeaderWeight.svg"
        alt="header-image" className="hnw-goals-weight-header" />
      <div className="hnw-goals-weight-details-container">
        <div className="hnw-goals-weight-details-wrapper">
          <div className="hnw-goals-weight-details__current-weight">
            <HnwWeightInputBox name="currentWeight" value={`${openNumpad && currentInput === "currentWeight" && !isDirty.currentWeight ? '' : weightInfo.currentWeight}`} onClick={toggleNumpad} weightHeader={GOALS_WEIGHT_DETAILS_TEXT.CURRENT_HEADER} />
          </div>
          <div className="hnw-goals-weight-details__target-weight">
            <HnwWeightInputBox name="targetWeight" value={`${openNumpad && currentInput === "targetWeight" && !isDirty.targetWeight ? '' : weightInfo.targetWeight}`} hasError={isWeightOutsideLimit} onClick={toggleNumpad} weightHeader={GOALS_WEIGHT_DETAILS_TEXT.TARGET_HEADER} />
            {weightInfo.currentWeight && weightInfo.targetWeight &&
              <div className="hnw-goals-weight-details__target-weight-setting-wrapper">
                <div className="hnw-goals-weight-details__target-weight-setting-text">
                  <span>{GOALS_WEIGHT_DETAILS_TEXT.TARGET}</span>
                  <span>{GoalTargetTextValue}</span>
                </div>
                {!openNumpad && !isWeightOutsideLimit && (+weightInfo.currentWeight !== +weightInfo.targetWeight) && <>
                  <div className="hnw-goals-weight-details__target-weight-setting-description">
                    {GOALS_WEIGHT_DETAILS_TEXT.DESCRIPTION}
                  </div>
                  <div className="hnw-goals-weight-details__target-weight-setting-stepper">
                    <HnwLogStepper dataArray={WeightStepperArray} onClick={setStepperValue} />
                  </div></>}
              </div>
            }
          </div>
        </div>
        <div className="hnw-goals-weight-details-suggestion">
          {weightInfo.currentWeight && weightInfo.targetWeight && !openNumpad && (+weightInfo.currentWeight !== +weightInfo.targetWeight) &&
            <div className="hnw-goals-weight-details-suggestion-tooltip">
              {isWeightOutsideLimit ? OutsideLimitImage : (weightInfo.currentWeight !== weightInfo.targetWeight) ?
                <img src="/images/healthAndWellness/goals/weight/model.svg" /> : null
              }<div>
                <div className="hnw-goals-weight-details-suggestion-tooltip-text">
                  {isWeightOutsideLimit ? GOALS_WEIGHT_DETAILS_TEXT.TOOLTIP_TOP_OUTSIDE_LIMIT : GOALS_WEIGHT_DETAILS_TEXT.TOOLTIP_TOP}
                </div>
                <div>
                  <span className="hnw-goals-weight-details-suggestion-tooltip-text">
                    {isWeightOutsideLimit ? ':' : GOALS_WEIGHT_DETAILS_TEXT.TOOLTIP_BOTTOM}
                  </span>
                  <span className={`hnw-goals-weight-details-suggestion-tooltip-value${isWeightOutsideLimit ? ' --has-error' : ''}`}>
                    {isWeightOutsideLimit ?
                      (+weightInfo.currentWeight < +weightInfo.targetWeight ?
                        GOALS_WEIGHT_DETAILS_TEXT.TOOLTIP_BOTTOM_OUTSIDE_UPPER_LIMIT :
                        GOALS_WEIGHT_DETAILS_TEXT.TOOLTIP_BOTTOM_OUTSIDE_LOWER_LIMIT) :
                      thaiDate(CalculateTargetDate(), 'dd MMM yyyy')}</span>
                </div>
              </div>
            </div>}
          <div className="hnw-goals-weight-details-suggestion-description">
            <ul>
              <li>{GOALS_WEIGHT_DETAILS_TEXT.SUGGESTION_TOP}</li>
              <li>{GOALS_WEIGHT_DETAILS_TEXT.SUGGESTION_BOTTOM}</li>
              {!openNumpad && !isWeightOutsideLimit && isSubmitEnbabled && (+weightInfo.currentWeight !== +weightInfo.targetWeight) &&
                <li>{GOALS_WEIGHT_DETAILS_TEXT.SUGGESTION_LAST}</li>}
            </ul>
          </div>
        </div>
      </div>
      <div className="hnw-goals-weight-footer">
        <img src="/images/healthAndWellness/goals/goalSettingFooter.svg"
          alt="footer-image" />
        <button className="btn" disabled={!isSubmitEnbabled} onClick={handleSubmitWeightGoal}>{GOALS_SUBMIT_BUTTON_TITLE}</button>
      </div>
      <CalendarLoader showLoading={loading} />
      {showSuccess && <HnwSuccessOverlayModal show={showSuccess} onTimeout={handleSuccessRedirect} />}
      <div className="hnw-goals-numpad-modal">
        <HnWNumpadModal onClose={() => toggleNumpad(false)} openNumpad={openNumpad} onBackgroundClick={handleOKClick}>
          {renderNumpad()}
        </HnWNumpadModal>
      </div>
    </div >
  );
}
