import React from 'react';
import { CartAssessmentHeader } from '../../../../../common';

const ExerciseLogHeader = ({ routeTo }) => {
  return (
    <CartAssessmentHeader
      leftIcon={
        '/images/healthAndWellness/exercise-log/icons/exercise-home.svg'
      }
      headerRoute={'pathHealthnWellness'}
      rightSecondIcon={
        '/images/healthAndWellness/exercise-log/icons/calendar-activity.svg'
      }
      rightSecondIconRoute={'pathHnwExerciseLogCalendar'}
      routeTo={routeTo}
    />
  );
};

export default ExerciseLogHeader;
