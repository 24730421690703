import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import Swal from "sweetalert2";

import './HnWAssessment.scss';

import { Header, Footer } from './components';
import AssessmentTemplate from './AssessmentTemplate';
import { getHnWAssessment, updateAssessmentAnswer, fetchBmiData } from '../../../actions';
import { message } from '../../../services/promotion/utils.service';
import { DEFAULT_BUTTON_TITLE } from '../../../common/commonConstant';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();

const Assessment = ({ routeTo }) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const dispatch = useDispatch();
  const { assessmentQuestions } = useSelector((state) => state.assessment);
  const bmi = useSelector((state) => state.bmi);

  const [loading, setLoading] = useState(false);

  const [templateType, setTemplateType] = useState('HealthInfo');
  const [templateHeader, setTemplateHeader] = useState('พื้นฐาน');
  const [questions, setQuestions] = useState([]);
  const [minAnswerSelected, setMinAnswerSelected] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answerId, setAnswerId] = useState('');
  const [assessmentId, setAssessmentId] = useState('');

  useEffect(() => {
    const didMount = async () => {
      await fetchAssessmentInfo();
    };
    didMount();
  }, []);

  const fetchAssessmentInfo = async () => {
    try {
      const assessment_id = (urlParams && urlParams.get('assessment_id')) ? urlParams.get('assessment_id') : '';
      setAssessmentId(assessment_id);
      setLoading(true);
      dispatch(getHnWAssessment(assessment_id)).finally(() => setLoading(false))
      if (!bmi?.bmi) {
        setLoading(true);
        dispatch(fetchBmiData()).finally(() => setLoading(false));
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getQuestionType = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      const { question_type: questionType } = data[0];
      return questionType || null;
    }
    return null;
  }

  useEffect(() => {
    if (assessmentQuestions && assessmentQuestions?.hw_assessments) {
      setQuestions(assessmentQuestions?.hw_assessments);
      // Get the first question type
      const questionType = assessmentQuestions && getQuestionType(assessmentQuestions?.hw_assessments[currentIndex]);
      const answerId = assessmentQuestions && assessmentQuestions.answer_id;
      setAnswerId(answerId);
      setTemplateType(questionType);
    }

  }, [assessmentQuestions]);

  useEffect(() => {
    checkHealthAnswers()
  }, [bmi]);


  const checkAnswers = (questions) => {
    for (const question of questions) {
      // to remove null, undefined values
      const answers = question?.answer.filter(Boolean) || [];
      if (answers.length < parseInt(question.minimum_answer)) {
        return false;
      }
    }
    return true;
  };

  const checkHealthAnswers = (template) => {
    const heightCheck = bmi?.height && (bmi?.height !== undefined);
    const weightCheck = bmi?.weight && (bmi?.weight !== undefined);
    const waistCheck = bmi?.waist && (bmi?.waist !== undefined);
    if (template == 'HealthInfo' && (heightCheck && weightCheck && waistCheck)) {
      setMinAnswerSelected(true);
      return true;
    }
    return false;
  };

  const onSubmitAnswer = (answer) => {
    let template = (getQuestionType(questions[currentIndex]));
    if (template === templateType) {
      if (templateType == 'HealthInfo') {
        setMinAnswerSelected(checkHealthAnswers(templateType));
      } else {
        setMinAnswerSelected(checkAnswers(answer));
      }
      setCurrentAnswer(answer);
    }
  }


  const AlertError = (message) => {
    Swal.fire({
      title: "",
      text: message,
      confirmButtonText: "ตกลง"
    });
  };

  const handleNextClick = () => {
    const lastQuestion = ((questions.length - 1) === currentIndex);
    const assessmentStatus = lastQuestion ? 'DONE' : 'PENDING';
    setLoading(true);
    dispatch(updateAssessmentAnswer(assessmentId, currentAnswer, answerId, assessmentStatus)).then(res => {
      if (res?.success && res?.answerId) {
        setAnswerId(res?.answerId);
        setMinAnswerSelected(false);
        if (lastQuestion) {
          routeTo('pathHnWAssessmentScore', `?assessment_id=${assessmentId}&answer_id=${answerId}`)
        } else {
          const index = currentIndex + 1;
          setCurrentIndex(index);
          setTemplateType(getQuestionType(questions[index]));
        }
        setLoading(false);
      } else {
        setLoading(false);
        AlertError(message.error.somethingWrong);
      }
    })
  };
  const btn1 = DEFAULT_BUTTON_TITLE.btn_title;
  const btn2 = DEFAULT_BUTTON_TITLE.btn_title2;
  const lastQuestion = ((questions.length - 1) === currentIndex);
  const btnTitle = lastQuestion ? btn2 : btn1;

  return (

    <section>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      <div
        className="assessment-container"
        style={{
          backgroundImage: `url(/images/healthAndWellness/assessment/bg_${templateType.toLowerCase()}.svg)`,
        }}
      >
        <Header
          templateType={templateType}
          templateHeader={templateHeader}
        />

        <div className="assessment-body scroll-auto">
          {questions && <AssessmentTemplate
            questions={questions}
            currentIndex={currentIndex}
            templateType={templateType}
            onSubmitAnswer={onSubmitAnswer} />}
        </div>


        {questions && currentIndex <= questions.length - 1 && (
          <Footer title={btnTitle} buttonActive={minAnswerSelected} handleSubmit={handleNextClick} />
        )}
      </div>
    </section>


  )
}

export default Assessment