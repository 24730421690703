import React from "react";
import './ChallengeMyActivityReward.scss';
import { CHALLENGE } from "../../../../common/challengeConstants";

const ChallengeMyActivityReward = ({rewardButtonClick, rewardDate}) => {

    return (
            <div class="challenge-my-activity-reward-container">
                <img src="/images/challenge/trophy-fill.svg"/>

                <div class="reward-tile-wrapper">
                    <div class="reward-title">{CHALLENGE.reward.activityRewards}</div>
                    <div class="reward-subtitle">{`${CHALLENGE.reward.rewardPrize} ${rewardDate} ${CHALLENGE.reward.rewardDays}`}</div>
                </div>

                <button class="btn-rounded btn-green-linear" onClick={rewardButtonClick}>{CHALLENGE.reward.rewardButton}</button>
            </div>
    )
}

export default ChallengeMyActivityReward