import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Modal } from '../../helpers';
import './ImageUpload.scss';

export const ImageUpload = ({
  label = 'เลือกไฟล์',
  dropText = 'วาง',
  className,
  id = 'image-upload',
  onChange,
  // xhr = false,
  // endpoint = '',
  multiple = false,
  value = [],
  deleteButton = true,
  maxFileSize = 26214400,
  sizeExceedWarning = 'ไฟล์มีขนาดเกิน  25 mb',
  exampleImage = null,
  closeIcon = null,
  ...rest
}) => {
  const [status, setStatus] = useState(label);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleDrop = event => {
    event.preventDefault();
    const supportedFilesTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const { type } = event.dataTransfer.files[0];
    if (supportedFilesTypes.indexOf(type) > -1) {
      fileSelectedHandeler(event.dataTransfer.files);
    } else {
      setShowErrorModal(true);
    }
  };

  const onDragEnter = event => {
    event.preventDefault();
    setStatus(dropText);
  };

  const onDragLeave = event => {
    event.preventDefault();
    setStatus(label);
  };

  const doNothing = event => event.preventDefault();

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const fileSelectedHandeler = async (files, input) => {
    if (files.length > 0) {
      const supportedFilesTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      const { type } = files[0];
      if (supportedFilesTypes.indexOf(type) <= -1) {
        setShowErrorModal(true);
        return;
      }
      if (files[0].size < maxFileSize) {
        const base64 = await getBase64(files[0]);
        if (onChange && multiple) {
          onChange({
            target: {
              id, value: [...value, {
                selectedFile: URL.createObjectURL(files[0]),
                image: files[0],
                base64,
              }]
            }
          });
        } else if (onChange && !multiple) {
          onChange({
            target: {
              id,
              value: {
                selectedFile: URL.createObjectURL(files[0]),
                image: files[0],
                base64,
              },
            }
          });
        }
        setStatus(label);
      } else {
        alert(sizeExceedWarning);
      }
    }
    input.value = '';
  };

  const handleDeleteImage = image => {
    if (multiple) {
      onChange({ target: { id, value: value.filter(img => img.selectedFile !== image.selectedFile) } });
    } else {
      onChange({
        target: {
          id,
          value: {},
        }
      });
    }
  };

  return (
    <div className="input-container image-upload-container" onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={doNothing} onDrop={handleDrop}>
      <div className={(multiple || exampleImage) && 'preview-images-container'}>
        {
          exampleImage && <div className="preview-image-container">
            <img className="preview-image" src={exampleImage} alt="preview-image" />
          </div>
        }
        {
          label ?
            (value && !isEmpty(value) ?
              (multiple ?
                <>
                  {value.map((val, idx) =>
                    <div key={idx} className="preview-image-container">
                      {deleteButton && <div className="del-image" onClick={() => handleDeleteImage(val)}>{closeIcon ? <img src={closeIcon} /> : <i className="far fa-times-circle" />}</div>}
                      <img className="preview-image" src={val.selectedFile}  alt="preview-image" />
                    </div>,
                  )}
                  <label htmlFor={id}>{status}</label>
                </>
                : <div className="preview-image-container">
                  {deleteButton && <div className="del-image" onClick={() => handleDeleteImage(value)}>{closeIcon ? <img src={closeIcon} /> : <i className="far fa-times-circle" />}</div>}
                  <img className="preview-image" src={value.selectedFile}  alt="preview-image" />
                </div>
              )
              : <label htmlFor={id}>{status}</label>)
            : null
        }
        {

          showErrorModal && <Modal
            content="โปรดเลือกไฟล์รูป jpg และ png เท่านั้น"
            type="danger"
            fnClose={() => setShowErrorModal(false)}
            fnOk={() => setShowErrorModal(false)}
            fnCancel={null}
            closeBtn={false}
            okBtn={true}
            okText="ตกลง"
            cancleText="ยกเลิก" />
        }
      </div>
      <input type="file" accept=".jpg,.jpeg,.png" id={id} className={`image-upload ${className}`}
        onChange={(e) => fileSelectedHandeler(e.target.files, e.target)}
        {...rest}
      />
    </div>
  );
};
