import { CUSTOMER_FETCH_LOADER, GET_CUSTOMER_BMI, GET_CUSTOMER_BMI_FAILURE, GET_CUSTOMER_BMI_SUCCESS, GET_CUSTOMER_MONTHLY_BMI, GET_LAST_MONTH_BMI, GET_MONTHLY_BMI_LIST, UPDATE_BMI } from '../actions/types';

const INITIAL_STATE = {
  monthlyBmi: [],
  lastMonthBmi: [],
  bmiMonthlyList: [],
  loading: false,
  error: null,
  hnwBMILoader: true,
  hnwCustomerLoader: true
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CUSTOMER_FETCH_LOADER:
      return { ...state, hnwCustomerLoader: false }
    case GET_CUSTOMER_BMI:
      return { ...state, loading: true, hnwBMILoader: true, error: null };
    case GET_CUSTOMER_BMI_SUCCESS:
      return { ...state, ...action.payload, loading: false, hnwBMILoader: false };
    case GET_CUSTOMER_BMI_FAILURE:
      return { ...state, loading: false, error: action.payload, hnwBMILoader: false };
    case GET_CUSTOMER_MONTHLY_BMI:
      return { ...state, monthlyBmi: action.payload };
    case GET_LAST_MONTH_BMI:
      return { ...state, lastMonthBmi: action.payload };
    case UPDATE_BMI:
      return { ...state, ...action.payload, loading: false, error: null };
    case GET_MONTHLY_BMI_LIST:
      return { ...state, bmiMonthlyList: action.payload };
    default:
      return state;
  }
}
