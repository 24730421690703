import React from 'react';

const Condition = ({ fn }) => {
  window.scrollTo(0, 0);
  return (
    <div className="Condition">
      <div className="details">
        <h1>เงื่อนไขและข้อตกลง</h1>
        <h2>ข้อตกลงในการลงทะเบียนร่วมโครงการ Amway Social Connect</h2>
        <p>1. ผู้ลงทะเบียนรับทราบและยินยอมให้บริษัท แอมเวย์ (ประเทศไทย) จำกัด มีสิทธิ์ในการเข้าถึงข้อมูลส่วนบุคคลจากรายละเอียดที่ได้ทำการลงทะเบียนไว้</p>
        <p>2. ผู้ลงทะเบียนรับทราบและยินยอมให้บริษัท แอมเวย์ (ประเทศไทย) จำกัด ทำการส่งข้อมูลข่าวสารต่างๆ ของบริษัทผ่านช่องทาง Amway Thailand Line Official Account</p>
        <p>
          3. ในกรณีที่ผู้ลงทะเบียนดำรงสถานภาพสมาชิกแอมเวย์ ท่านรับทราบและยินยอมให้บริษัท แอมเวย์ (ประเทศไทย) จำกัด ทำการส่งนิตยสาร Amnews ในรูปแบบดิจิทัลโบรชัวร์ให้ท่านผ่านช่องทาง Amway Thailand Line
          Official Account โดยจะมีการยกเลิกการส่งในรูปแบบสิ่งพิมพ์
        </p>
        <p>4. ทางบริษัท แอมเวย์ (ประเทศไทย) จำกัด ขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</p>
        <h2>นโยบายความเป็นส่วนตัว (Privacy Policy)</h2>
        <p>
          ทางบริษัท แอมเวย์ (ประเทศไทย) จำกัด (“บริษัท” “เรา” หรือ “เว็บไซต์”) มีนโยบายการบริหารและใช้ข้อมูลของผู้ลงทะเบียน (“ท่าน”) ดังนี้ การเก็บและใช้ข้อมูลโดย Amway Thailand Line Official Account
          ข้อมูล log และ activity เราจะเก็บข้อมูลที่เกี่ยวข้องกับการใช้งานแอพพลิเคชั่นของท่านโดยอัตโนมัติ เราใช้ข้อมูลประเภทนี้เพื่อดูแลช่องทาง และปรับปรุงการให้บริการ
          เรายังใช้ข้อมูลนี้เพื่อการวิเคราะห์พฤติกรรมของผู้ใช้ เพื่อวัดความสนใจในการใช้ฟังก์ชั่นต่างๆ ของช่องทาง การใช้ข้อมูลโดยบุคคลอื่น
        </p>
        <p>
          บุคคลที่สาม: บนแอพพลิเคชั่นหรือเว็บไซต์ของบริษัทอาจจะมีโฆษณาของบุคคลที่สาม เราสงวนสิทธิ์ ในการใช้จังหวัดที่ท่านอาศัย วันเดือนปีเกิดของท่าน อายุ เพศ และความสนใจ
          เพื่อแสดงโฆษณาที่เกี่ยวข้องกับท่าน ในบางกรณีเราอาจจะติดต่อท่านทางอีเมลในนามของบุคคลที่สามเพื่อวัตถุประสงค์ทางการตลาดโดยตรงได้ ธุรกิจในเครือ: เราสามารถเปิดเผยข้อมูลของท่านให้แก่ บริษัทแม่
          บริษัทย่อยของเรา บริษัทร่วมทุน หรือ บริษัทอื่นที่เป็น Partner กัน ภายใต้เงื่อนไขที่จะระบุในคำชี้แจงนี้ในอนาคต
        </p>
        <h2>การใช้ข้อมูลในการสอบสวน</h2>
        <p>
          เราสงวนสิทธิ์ที่จะตรวจสอบการละเมิดกฎหมายหรือศีลธรรมบนเว็บไซต์ และการละเมิดข้อบังคับและเงื่อนไขการบริการของเรา เราอาจเปิดเผยข้อมูลเกี่ยวกับท่านต่อบุคคลที่สาม
          ถ้าเรามีความเชื่อโดยสุจริตว่าการเปิดเผยข้อมูลดังกล่าวเป็นเหตุจำเป็นต้อง (ก) ดำเนินการเกี่ยวกับข้อสงสัยว่ามีกิจกรรมที่ผิดกฎหมาย (ข) บังคับใช้เงื่อนไขการบริการของเรา (ค)
          ปฏิบัติตามกระบวนการทางกฎหมาย (ง) ปกป้องสิทธิ ชื่อเสียงและทรัพย์สินของบริษัทและสมาชิกท่านอื่น การรักษาความปลอดภัย
          เราปฏิบัติตามมาตรฐานสากลที่เป็นที่รองรับทั่วไปในการป้องกันข้อมูลส่วนตัวของท่านที่ส่งถึงเรา ทั้งในระหว่างการส่งและหลังจากที่เราได้รับข้อมูลดังกล่าว
          อย่างไรก็ตามโปรดตระหนักว่าไม่มีวิธีการส่งผ่านทางอินเทอร์เน็ตหรือวิธีการเก็บอิเล็กทรอนิกส์ใดที่ปลอดภัย 100% ดังนั้นเราจึงไม่สามารถจะรับประกันความสมบูรณ์ของการรักษาความปลอดภัยได้
          และทางบริษัทขอสงวนสิทธิ์ในการปฏิเสธความรับผิดชอบในกรณีที่ข้อมูลสูญหายหรือถูกขโมยไม่ว่ากรณีใดๆ ทั้งสิ้น การปรับเปลี่ยนคำชี้แจง เราอาจปรับปรุงคำชี้แจงนี้เวลาใดก็ได้
          โดยท่านสามารถเข้ามาดูนโยบายความเป็นส่วนตัวฉบับล่าสุดได้ที่นี่เสมอ ถ้าเราเปลี่ยนแปลงในคำชี้แจงเราจะแจ้งให้ทราบอย่างชัดเจน โดยการโพสต์แจ้งให้ทราบการเปลี่ยนแปลงบนหน้านี้
          ก่อนที่จะดำเนินการเปลี่ยนแปลงบนแอพพลิเคชั่น/เว็บไซต์เป็นเวลา 24 ชั่วโมง
        </p>
      </div>
      <div className="text-center">
        <button onClick={fn} className="away-button active" type="submit">
          กลับ
        </button>
      </div>
    </div>
  );
};

export default Condition;
