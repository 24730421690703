import React, { useEffect } from 'react';
import { initializeLiff, initializeFbSDK } from '../../utill.func';
import { CHALLENGE, CHALLENGE_UNLOCKED } from '../challengeConstants';
import './ChallengeShare.scss'

const ChallengeShare = ({ imgPath, message }) => {

  useEffect(() => {
    initializeFbSDK();
  }, []);

  const shareOnFacebook = () => {
    // TO do: Will remove the console log once the functionality runs fine
    console.log({ method: 'share', href: imgPath, hashtag: message });
    window.FB.ui({ method: 'share', href: imgPath, hashtag: message },
      (response) => {
        alert(response && !response.error_message ?
          CHALLENGE_UNLOCKED.SHARE_SUCCESS_MESSAGE : CHALLENGE_UNLOCKED.SHARE_FAILURE_MESSAGE);
      });
  };

  const shareOnInstagram = () => {
    //no support for instagram
    alert(CHALLENGE_UNLOCKED.INSTA_MESSAGE);
  };

  const shareOnLine = async () => {
    try {
      const liff = await initializeLiff();
      if (liff) {
        await liff.shareTargetPicker(
          [{ type: "image", originalContentUrl: imgPath, previewImageUrl: imgPath },
          { type: "text", text: message }],
          { isMultiple: true }
        );
      }
    } catch (error) {
      alert(CHALLENGE_UNLOCKED.SHARE_FAILURE_MESSAGE, error);
    }
  };


  return (
    <div className={'challenge-share-container'}>
      <div className='map-goal-share-section'>
        <span className='share-title'>{CHALLENGE.mapDetail.share}</span>
        <img onClick={shareOnFacebook} src={'/images/challenge/icons/fb_icon.svg'} />
        {/* hiding below instagram icon until approach is finalized */}
        {/* <img onClick={shareOnInstagram} src={'/images/challenge/icons/insta_icon.svg'} /> */}
        <img onClick={shareOnLine} src={'/images/challenge/icons/line_icon.svg'} />
      </div>
    </div>
  )
}

export default ChallengeShare