import React from "react";
import "./MyDashboardMissionLoader.scss";

const MyDashboardMissionLoader = () => {
  return (
    <div className="my-dashboard-vdo-container">
     <div className="vdo-skeleton-container">
      <div className="vdo-skeleton-card-container">
        <div className="skeleton-card-image skeleton"></div>
        <div className="skeleton-card-details">
        </div>
      </div>
      <div className="vdo-skeleton-card-container">
        <div className="skeleton-card-image skeleton"></div>
        <div className="skeleton-card-details">
        </div>
      </div>
    </div>
  </div>
  );
};

export default MyDashboardMissionLoader;
