import React, { useState, useEffect } from 'react';
import { getPriceFormat } from '../../../../services/promotion/utils.service';
import CartIcon from '../../../promotion/CartIcon';

export const SolutionHeader = (props) => {
	const {solutionPlan,selectedPlanType,cartEntries} = props;
  const [minimumValue, setMinimumValue] = useState(0);
	
	useEffect(()=>{
		if(solutionPlan && solutionPlan.length) {
			solutionPlan.forEach(plans=>{
				const qualificationRuleType = plans.qualificationRules && 
																			plans.qualificationRules.length &&
																			plans.qualificationRules[0].qualificationRuleType ? 
																			plans.qualificationRules[0].qualificationRuleType : '';
				const minValue = plans.qualificationRules && 
												plans.qualificationRules.length &&
												plans.qualificationRules[0].minValue ? 
												plans.qualificationRules[0].minValue : 0;
				if(qualificationRuleType.toUpperCase().indexOf(selectedPlanType) >= 0) {
					setMinimumValue(minValue)
				}
			});
		}
	},[selectedPlanType, JSON.stringify(solutionPlan)]);
	return (
		<div className='solution-header'>
			<div className="sop-header">
				<h2 className='new-h2'>{selectedPlanType === 'BASIC' ? 'เบสิก' : 'บียอนด์'} {getPriceFormat(minimumValue)}</h2>
				<div className='subheading'>คุ้มค่ามากกว่า ด้วยการสั่งซื้อสินค้าแบบต่อเนื่อง</div>
			</div>
			<div className='cart-icon'>
				<CartIcon />
			</div>
			<div className='sop-header-image'>
				<img
					src={selectedPlanType === 'BASIC' ? "/images/sop/subscription/solution_basic_header.png" : "/images/sop/subscription/solution_beyond_header.png"}
					className="w-100"
				/>
			</div>
		</div>
	)
}

