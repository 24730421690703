import { CUSTOMER_FETCH, CUSTOMER_CREATE, CUSTOMER_MEMBER, CUSTOMER_UPDATE, CUSTOMER_FIND, CUSTOMER_FETCH_LOADER } from './types';
import axios from 'axios';

export const updateCustomerInfo = (uid) => dispatch => {
  axios.put(`${process.env.REACT_APP_API_URL}/customers/updateCustomerInfo`, { uid: uid })
  .then((res) => {
    if(res?.data) {
      sessionStorage.setItem('customer', JSON.stringify(res.data));
    }
    dispatch({
      type: CUSTOMER_UPDATE
    })
  })
}
export const customerCreate = (params) => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/customers`, {
    params,
    timeout: process.env.NETWORK_TIMEOUT,
  }).then(res => {
    dispatch({ type: CUSTOMER_CREATE });
  }).catch(function (error) {
    alert(error.message);
  });
};

export const customerFetch = (uid, query='') => async dispatch => {
  let URL = `${process.env.REACT_APP_API_URL}/customers`
  if (query) {
    URL = `${URL}?${query}`
  }
 return axios.get(URL, {
      params: { uid: uid },
      timeout: process.env.NETWORK_TIMEOUT,
    })
  .then(res => {
    dispatch({
      type: CUSTOMER_FETCH,
      payload: res.data,
    });
    dispatch({
      type: CUSTOMER_FETCH_LOADER
    })
  return res.data;
}).catch(error => {
  if (error.code === 'ECONNABORTED') {
    alert('Request timed out');
  } else {
    alert(error.message)
  }
  dispatch({
    type: CUSTOMER_FETCH_LOADER
  })
}).finally(()=>{
  dispatch({
    type: CUSTOMER_FETCH_LOADER
  })
});
}
export const customerNemberFetch = amwayNumber => dispatch => {
  axios.get(`${process.env.REACT_APP_API_URL}/customers/amwayNumber`, { amwayNumber }).then(res => {
    dispatch({
      type: CUSTOMER_MEMBER,
      payload: res.data[0],
    });
  }).catch(function (error) {
    alert(error.message);
  });
};

export const customerUpdate = (uid, params) => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/customers/${uid}`, params).then(res => {
    dispatch({ type: CUSTOMER_UPDATE });
  }).catch(function (error) {
    alert(error.message);
  });
};

export const customerFindByNumber = (uid) => dispatch => {
  axios.get(`${process.env.REACT_APP_API_URL}/customers/find/${uid}`).then(res => {
    dispatch({
      type: CUSTOMER_FIND,
      payload: res.data,
    });
  });
};

export const customerFetchByNumber = (uid) => dispatch => {
  console.log('FetchCustomerByNumber');
  axios.get(`${process.env.REACT_APP_API_URL}/customers/fetch/${uid}`).then(res => {
    dispatch({
      type: CUSTOMER_FIND,
      payload: res.data,
    });
  })
    .catch((err) => {
      console.log('ERROR FetchCustomerByNumber')
      console.log(err)
    });
}
