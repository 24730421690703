import React from "react";
import { RectBadge, TeamRectCard } from "../../../../common";
import { CHALLENGE } from "../../../../common/challengeConstants";
import './TeamStepsCard.scss';
import { formatStringWithDecimal } from "../../../../utill.func";

const TeamStepsCard = ({
    teamName,
    totalTeamSteps,
    totalDistance,
    defaultTeamSteps,
    teamDashboardHeaderBadge,
    challengeDay,
    teamDashboardStatus
}) => {

    const renderTeamNameContainer = () => {
        return (
            <div className="team-steps-container-header-right">
            <RectBadge rectTitle={`${CHALLENGE.teamDashboard.teamStepsCardHeader}`} rectUnit={`${challengeDay}`}>
                <div className="rect-badge-description">
                    <div className='rect-badge-title'>{`${CHALLENGE.teamDashboard.teamStepsCardHeader}`}</div>
                    <div className='rect-badge-value'>{`${challengeDay}`}</div>
                </div>
            </RectBadge>
        </div>
        )
    }
    return (
        <div className="team-steps-container">
            <div className="team-steps-container-header">
                <div className="team-name">{`${CHALLENGE.teamDashboard.teamName} ${teamName || ''}`}</div>
                {teamDashboardHeaderBadge && renderTeamNameContainer()}
            </div>
            <div className="challenge-card-body">
                <TeamRectCard
                    title={CHALLENGE.teamDashboard.totalStepsHeading}
                    quantity={totalTeamSteps}
                    content={CHALLENGE.teamDashboard.average}
                    amount={defaultTeamSteps}
                    stepsUnit={CHALLENGE.teamDashboard.stepsUnit}
                    unit={CHALLENGE.teamDashboard.stepsUnit}
                    teamDashboardStatus={teamDashboardStatus}
                ></TeamRectCard>
                <hr className="rounded-dividing-line"></hr>
                <TeamRectCard
                    title={CHALLENGE.teamDashboard.totalDistanceHeading}
                    quantity={totalDistance ? totalDistance : 0}
                    unit={CHALLENGE.teamDashboard.kilometerUnit}
                    teamDashboardStatus={teamDashboardStatus}
                ></TeamRectCard>
            </div>
        </div>
    )
}

export default TeamStepsCard;