import { OTP_FETCH, VERIFY_OTP_FETCH, CREATE_BANK } from './types';
import axios from 'axios';

export const otpFetch = (uid) => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/otp/getOTP/${uid}`).then(res => {
    dispatch({
      type: OTP_FETCH,
      payload: res.data,
      timestamp: new Date(),
    });
  });
};

export const verifyOtpFetch = (params) => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/otp/verifyOTP`, params).then(res => {
    dispatch({
      type: VERIFY_OTP_FETCH,
      payload: res.data,
      timestamp: new Date(),
    });
  }).catch(err => {
    dispatch({
      type: VERIFY_OTP_FETCH,
      payload: err.response.data,
      timestamp: new Date(),
    });
  });
};

export const createBankAccount = params => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/renewal/createBankAccountByPartyId`, params).then(res => {
    dispatch({
      type: CREATE_BANK,
      payload: res.data,
      timestamp: new Date(),
    });
  }).catch(err => {
    dispatch({
      type: CREATE_BANK,
      payload: err.response.data,
      timestamp: new Date(),
    });
  });
};
