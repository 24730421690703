import React from 'react';
import { HNW_GOAL_SETTING } from '../../../../../common/commonConstant';

export default function HnWActivityNotes() {
  return (
    <>
      <li>
        <span></span>
        <span>
          {HNW_GOAL_SETTING.ACTIVITY.ACTIVITY_NOTE_1}
          <br />
          {HNW_GOAL_SETTING.ACTIVITY.ACTIVITY_NOTE_2}
        </span>
      </li>
      <li>
        {HNW_GOAL_SETTING.ACTIVITY.ACTIVITY_NOTE_2_PREFIX}{' '}
        <a>
          <img src="/images/healthAndWellness/goals/common/stepper_left_icon.svg" />
        </a>{' '}
        <a>
          <img src="/images/healthAndWellness/goals/common/stepper_right_icon.svg" />
        </a>{' '}
        {HNW_GOAL_SETTING.ACTIVITY.ACTIVITY_NOTE_2_SUFFIX}
      </li>
    </>
  );
}
