import React, { useState, useEffect } from 'react';
import { getPriceFormat } from '../../../../services/promotion/utils.service';
import { QuantityStepper } from '../../../../helpers';

export const SolutionFooterProduct = props => {
  const { product, img, price, amwayValue, addProductToList, increaseDisabled } = props;
  return (
    <div className='row product'>
      <div className='col-3'>
        <img
          src={img}
          alt="SOP product image"
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div className='col-9 description-section'>
        <span className='row footer-sku'>
          #{product.sku}
        </span>
        <span className='row footer-name'>
          {product.identifier}
        </span>
        <div className='row footer-price-stepper'>
          <div className='col-5 footer-price'>
            <h2>฿ {getPriceFormat(price)}</h2> &ensp; &ensp;
          </div>
          <div className='col-7'>
            <div>
              <QuantityStepper quantity={product.addedQuantity}
                decreaseEvent={() => addProductToList(product.sku, 'decrease')}
                increaseEvent={() => addProductToList(product.sku, 'increase')}
                increaseDisabled={increaseDisabled} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}