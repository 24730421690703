import config from '../config';

export class Product {
  constructor(data) {
    if (data) {
      this.id = data?._id || '';
      this.name = data?.name || '';
      this.type = data?.type || '';
      this.supplementItem = data?.supplementItem && data?.supplementItem.length ? data.supplementItem : '';
      this.image = data?.image || '';
      this.imageUrl = this?.image ? `${config.S3_URL}${this.image}` :  '/images/healthAndWellness/foodCalories/food-details/food-default-suggest-card.svg';
      this.category = data?.category || '';
      this.createdDate = data?.createdDate || '';
      this.updatedDate = data?.updatedDate || '';
      this.isRecommended = data?.isRecommended || false;
      this.calorie = data?.calorie || 0;
      this.carbs = data?.carbs || 0;
      this.fat = data?.fat || 0;
      this.quantity = data?.quantity || 0;
      this.protein = data?.protein || 0;
      this.unit = data?.unit || null;
      this.weight = data?.weight || 0;
    }
  }
}
