import React from "react";

const UpArrowSvg = ({ arrowColor }) => {

    return (
        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.86603 0.5C5.48113 -0.166667 4.51887 -0.166667 4.13397 0.5L0.669873 6.5C0.284972 7.16667 0.766098 8 1.5359 8L8.4641 8C9.2339 8 9.71503 7.16667 9.33013 6.5L5.86603 0.5Z" fill={arrowColor} />
        </svg>
    )
}

export default UpArrowSvg;