import React from "react";
import { CHALLENGE } from "../../../../common/challengeConstants";

const FoodBadgeDetail = ({ disabled }) => {

    const renderActiveFoodBadgeDetail = () => {
        return (
            <p>
                {CHALLENGE.badges.activeMissionBadgeHeading}<br />
                {CHALLENGE.badges.activeFoodBadgeSubHeading}<br />
                {CHALLENGE.badges.activeQuizBadgeSubtext} {CHALLENGE.badges.bodyKey10Heading}
            </p>
        )
    }

    const renderInactiveFoodBadgeDetail = () => {
        return (
            <p>
                {CHALLENGE.badges.inactiveMissionBadgeHeading}<br />
                {CHALLENGE.badges.inactiveFoodSubHeading}<br />
                {CHALLENGE.badges.inactiveFoodSubText}<br />
                {CHALLENGE.badges.activeQuizBadgeSubtext} {CHALLENGE.badges.bodyKey10Heading}
            </p>
        )
    }

    return (
        disabled ? renderInactiveFoodBadgeDetail() : renderActiveFoodBadgeDetail()
    )

}

export default FoodBadgeDetail;