import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import './HnWAssessment.scss';

import { Chips, Boxes, Slider, Health } from './components';

const AssessmentTemplate = ({questions, currentIndex, templateType, onSubmitAnswer}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
   
  }, []);

  const getAnswer = (answerData, questionData) => {
    const currentQuestion = questions[currentIndex];
    const selectedQuestion = currentQuestion.find((q) => q._id === questionData._id);
    // Update the answer array of the selected question with the IDs from answerData
    if (selectedQuestion) {
      if(answerData && answerData.length) {
        selectedQuestion.healthInfo = answerData?.find((answer) => answer?.healthData);
        // to handle Answer id mapping
        answerData = answerData.filter((answer) => !answer?.healthData);

        selectedQuestion.answer = answerData?.map((answer) => answer?._id);
        selectedQuestion.total_score = answerData?.map((answer) => answer?.score);
      } else {
        selectedQuestion.answer = [answerData?._id];
        selectedQuestion.total_score = [answerData?.score];

      }
    }
    onSubmitAnswer(currentQuestion);
  };

  const renderQuestionComponent = (key, question) => {
    switch (key) {
      case 'HealthInfo':
        return <Health healthData={question} getAnswer={getAnswer}/>;
      case 'Activities':
        return <Chips chipsData={question} getAnswer={getAnswer}/>;
      case 'Attitude':
        return <Slider sliderData={question} getAnswer={getAnswer}/>;
      case 'FoodHabits':
        return <Chips chipsData={question} getAnswer={getAnswer}/>;
      case 'Sleep':
        return <Slider sliderData={question}  getAnswer={getAnswer}/>;
      case 'Stress':
        return <Slider sliderData={question} getAnswer={getAnswer} sliderType={'vertical'}/>;
      case 'Others':
        return <Boxes boxData={question} getAnswer={getAnswer}/>;
      default:
        return null;
    }
  };

  return (
    <section className="container-fluid template-wrapper">
        <LoadingOverlay
            text="Loading"
            spinner
            active={loading}
            styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
        />

        {questions && questions?.length > 0 && questions[currentIndex].map((question, index) => (
            <div className="assessment-wrapper" key={index}>
                <div key={index}>
                    {renderQuestionComponent(templateType, question)}
                </div>
            </div>
        ))}

    </section>


  )
}

export default AssessmentTemplate