import React from "react";
import "./WaterLog.scss";
import { calculateScaleLabels, handleWaterLogGlass } from "../../../../utill.func";
import { WATER_CARD_HEADING } from "../../../../common/commonConstant";
import { BASE_WATER_LEVEL, DEFAULT_WATER_LEVEL, WATER_LEVEL_MULTIPLIER } from "../../../../common/homePageConstants";

const WaterLog = ({ waterData }) => {
    const { waterLog } = waterData;
    const { water_level_one, water_level_two, water_level_three } = calculateScaleLabels(waterLog.total / 1000);
    const { waterLevels, isEmpty } = handleWaterLogGlass(waterLog.consumed, waterLog.total);

    const water_level_full = waterLog.consumed > waterLog.total ? parseFloat((waterLog.consumed / 1000).toFixed(1)) : parseFloat((waterLog.total / 1000).toFixed(1));

    const waterLevelCount = waterLevels;
    const waterLevelHeight = BASE_WATER_LEVEL + (waterLevelCount - 1) * WATER_LEVEL_MULTIPLIER;

    return (
        <div className="drink-card-container white-card-container">
            <div className="white-card-title">
                {WATER_CARD_HEADING}
            </div>
            <div className="drink-water-details-container">
                <div className="glass-image-levels">
                    {waterLevels !== -1 && (
                        <img
                            src="/images/healthAndWellness/water-log/water_head.svg"
                            className="water-head-img"
                            alt="Water Head"
                        />
                    )}
                    {waterLevelCount > 0 && (
                        <img
                            src="/images/healthAndWellness/water-log/water_level.svg"
                            className="water-level-img"
                            style={{
                                height: `${waterLevelHeight}px`,
                            }}
                            alt="Water Level"
                        />
                    )}
                    {waterLevels !== -1 && (
                        <img
                            src="/images/healthAndWellness/water-log/water_bottom.svg"
                            className="water-bottom-img"
                            alt="Water Bottom" />
                    )}
                </div>
                <div className="drink-glass-img-container">
                    <img src="/images/healthAndWellness/water-log/water_no_data.svg" className="glass-scale-img empty-glass" alt="Empty Glass" />

                    <div className="scale-value-wrapper">
                        <div className="scale-value value1">{water_level_full || DEFAULT_WATER_LEVEL.default_water_l1}</div>
                        <div className="scale-value value2">{water_level_two || DEFAULT_WATER_LEVEL.default_water_l2}</div>
                        <div className="scale-value value3">{water_level_one || DEFAULT_WATER_LEVEL.default_water_l3}</div>
                    </div>

                </div>
                <div className="drink-glass-description">
                    <h5 className="used-value">{waterLog.consumed || '-'}</h5>
                    <p className="total-value">/<span>{waterLog.total}</span><span> {waterLog.unit}</span></p>
                </div>
            </div>
        </div>
    );
}

export default WaterLog;