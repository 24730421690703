import React from "react";

const CircleRoundTriple = () => {

    return (
        <div className="circle-round-2">
            <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* First circle */}
                <circle cx="95" cy="95" r="89" stroke="white" strokeWidth="12" strokeLinecap="round"></circle>
                {/* Second circle with gradient stroke */}
                <circle cx="95" cy="95" r="89" stroke="url(#paint0_linear_7142_422342)" strokeWidth="12"
                    strokeLinecap="round"></circle>
                {/* Remaining path */}
                <path
                    d="M17.9237 50.5C25.8481 36.7745 37.2939 25.4141 51.0784 17.5927C64.8628 9.77129 80.4857 5.77264 96.3328 6.00998"
                    stroke="#9FDC7D" strokeWidth="12" strokeLinecap="round" />
                {/* Linear gradient definition */}
                <defs>
                    <linearGradient id="paint0_linear_7142_422342" x1="68" y1="12.3913" x2="109" y2="12.3913"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9FDC7D" />
                        <stop offset="1" stopColor="#6CC949" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default CircleRoundTriple;