import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getAboCategories,
  getAmwayCategories,
} from '../../../services/library/categories/categories.service';
import { countAmwayLibraryBook, libraryImageUrl } from '../../../utill.func';
import { EContentType } from '../common.library';
import './libraryProfile.scss';

const Categories = ({ type }) => {
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState();
  const [limitAddQuota, setLimitAddQuota] = useState(false);
  const history = useHistory();

  const routeChange = (path, data) => {
    if (type === EContentType.AMWAY_LIBRARY) {
      history.push({
        pathname: path,
        state: { isAmwayContent: type, index: data },
      });
    } else {
      history.push({
        pathname: path,
        search: data ? `id=${data._id}` : '',
        state: { isAmwayContent: type },
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setCategoriesLoading(true);
    if (type === EContentType.AMWAY_LIBRARY) {
    }
    let categoryData;
    if (type === EContentType.AMWAY_LIBRARY) {
      categoryData = await getAmwayCategories();
    }
    if (type === EContentType.MY_LIBRARY) {
      categoryData = await getAboCategories();
      if (categoryData.length === 5) {
        setLimitAddQuota(true);
      }
    }
    setCategories(categoryData);
    setCategoriesLoading(false);
  };

  const categoryRenderButton = () => {
    if (type === EContentType.AMWAY_LIBRARY) {
      return (
        <a
          onClick={() => {
            routeChange('/library/category/admin');
          }}
        >
          See all &gt;
        </a>
      );
    } else {
      return (
        <a
          hidden={limitAddQuota}
          onClick={() => {
            routeChange('/library/category/form');
          }}
        >
          + Add category
        </a>
      );
    }
  };

  return (
    <Spin tip="Load categories..." spinning={categoriesLoading}>
      <div className="library-categories-slide card-library card-categories">
        <div className="card-header">
          <div className="title">
            <img
              className="icon"
              src="../../images/library/icon_category.svg"
            />
            Categories
          </div>
          <div className="see-all">{categoryRenderButton()}</div>
        </div>
        <div className="card-body">
          {categories &&
            categories.map((item, index) => (
              <div
                className="card-item"
                key={`${item}${index}`}
                onClick={() => {
                  routeChange(
                    type === EContentType.AMWAY_LIBRARY
                      ? '/library/category/admin'
                      : '/library/content/list/subCate',
                    type === EContentType.AMWAY_LIBRARY ? index : item,
                  );
                }}
              >
                <div className="card-item-img">
                  <img src={libraryImageUrl(item.imageFlex)} />
                </div>
                <div className="card-desc">
                  <div className="title">{item.name}</div>
                  <div className="total-books">
                    {`${countAmwayLibraryBook(item.contentList)} Books`}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Spin>
  );
};
export default Categories;
