import React from 'react';
import './HomeNutritionBar.scss';
import NutritionBarSingle from './nutritionBarSingle/NutritionBarSingle';
import { round } from '../../../../services';
import { dailyNutrition } from '../../../../common/homePageConstants';

const HomeNutritionBar = ({ defaultNutrition, nutritionData }) => {
    const { healthParamsValues } = nutritionData;

    return (
        <div class="home-nutrition-bar-container">
            {dailyNutrition.map((nutritionVal, index) => (
                <NutritionBarSingle
                    key={`progress${index}`}
                    value={round(healthParamsValues[nutritionVal.name].consumed, 0) || '-'}
                    label={nutritionVal.label}
                    max={round(healthParamsValues[nutritionVal.name].total, 0) ? round(healthParamsValues[nutritionVal.name].total, 0) : defaultNutrition[nutritionVal.name]} />
            ))}
        </div>
    )
}

export default HomeNutritionBar;