import React from 'react'
import { DRINK_STATUS } from '../../../../../../constants'

const FoodLogBeverages = ({ data, handleDrinkClick, drinkName }) => {
    return (
        <div className={`food-overview-drink-img-container food-overview-drink-${drinkName}-container`}>
            {
                data.map((elem, index) => (
                    <button key={`${drinkName}${index}`} 
                        onClick={() => handleDrinkClick(elem, index)} 
                        className="btn btn-drink"
                        disabled={elem.status === DRINK_STATUS.empty}
                    >
                        {
                            elem.status === DRINK_STATUS.add ? <img src={`/images/healthAndWellness/foodLog/foodOverview/icons/add-drink-${drinkName}.svg`} alt="add" />
                                : elem.status === DRINK_STATUS.half ? <img src={`/images/healthAndWellness/foodLog/foodOverview/icons/half-drink-${drinkName}.svg`} alt="half" />
                                    : elem.status === DRINK_STATUS.full ? <img src={`/images/healthAndWellness/foodLog/foodOverview/icons/full-drink-${drinkName}.svg`} alt="active" />
                                        : <img src={`/images/healthAndWellness/foodLog/foodOverview/icons/empty-drink-${drinkName}.svg`} alt="empty" />
                        }
                    </button>
                ))
            }
        </div >
    )
}

export default FoodLogBeverages