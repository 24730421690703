import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import {
  addPremiumItemToCart,
  deleteCartItems,
  getPriceFormat,
  updateCart
} from '../../../services/promotion/utils.service';
import LiffPromotionList from '../../modal/LiffPromotionList';
import '../../promotion/CartPromotion.scss';
import QuantityStepper from '../QuantityStepper';

export const PromotionItem = ({
  index,
  img,
  identifier,
  quantity,
  premiumItemsOption,
  cart,
  lastPremiumItemsId,
  elligibleTargetQty,
  hasElligibleTargetQty,
  totalPrice,
  callback,
  fetchCart
}) => {
  const [isOpenLiff, setIsOpenLiff] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderTotal, setOrderTotal] = useState(0);

  useEffect(() => {
    setOrderTotal(elligibleTargetQty + hasElligibleTargetQty + getQuantity());
  }, [elligibleTargetQty, hasElligibleTargetQty]);

  const closeLiffEvent = () => {
    if (!isRequiredQtySelected()) {
      return alert('Kindly select the complete configured promotion quantity.');
    }
    setIsOpenLiff(false);
    fetchCart();
  };

  const increaseOrder = async (option) => {
    if (isRequiredQtySelected()) {
      return alert(
        'ไม่สามารถเพิ่มจำนวนสินค้าได้ เนื่องจากถึงจำนวนที่จำกัดแล้ว',
      );
    }
    setLoading(true);
    const product = cart.entries.find(
      (value) => value.product.id === option.id,
    );
    const itemId = cart.entries.findIndex((v) => v.product.id === option.id);
    const deleteLocal = JSON.parse(localStorage.getItem('deleteIds'));
    totalPrice = totalPrice + option.targetPrice;
    if (!product) {
      await addPremiumItemToCart(cart.id, option, 1);
      const newDeleteLocal = deleteLocal.filter(
        (v) => v.product.id !== option.id,
      );
      localStorage.setItem('deleteIds', JSON.stringify(newDeleteLocal));
      callback(product, option, 1, totalPrice);
    }
    if (product) {
      await updateCart(cart.id, itemId, product.quantity + 1);
      callback(product, option, product.quantity + 1, totalPrice);
    }
    setLoading(false);
  };

  const decreaseOrder = async (option) => {
    const product = await cart.entries.find(
      (value) => value.product.id === option.id,
    );
    totalPrice = totalPrice - option.targetPrice;
    quantity = product.quantity - 1;
    const itemId = cart.entries.findIndex((v) => v.product.id === option.id);
    setLoading(true);
    if (quantity > 0) {
      await updateCart(cart.id, itemId, quantity);
    }
    if (quantity === 0) {
      await deleteCartItems(cart.id, [product.id], false);
      const deleteId = JSON.parse(localStorage.getItem('deleteIds'));
      const hasLocalStorage = deleteId.find((v) => v.product.id === option.id);
      if (!hasLocalStorage) {
        deleteId.push(product);
        localStorage.setItem('deleteIds', JSON.stringify(deleteId));
        setIsOpenLiff(true);
      }
    }
    callback(product, option, quantity, totalPrice);
    setLoading(false);
  };

  const isRequiredQtySelected = () => {
    return quantity + getQuantity() === orderTotal ? true : false;
  };

  const isQtyStepperDisabled = (quantityProduct) => {
    return quantityProduct && quantity ? false : isRequiredQtySelected();
  };

  const getIsDecrementDisabled = (quantityProduct, quantityOption) => {
    return (quantityProduct && quantity) || quantityOption > 0 ? false : true;
  };

  const getIsIncrementDisabled = (quantityProduct) => {
    return quantityProduct && quantity && isRequiredQtySelected()
      ? true
      : isRequiredQtySelected();
  };

  const getQuantity = () => {
    const entryInCartArr = cart.entries;
    let quantityOptions = 0;
    if (premiumItemsOption) {
      for (const target of premiumItemsOption) {
        const quantityProduct = entryInCartArr.find((value) => {
          return value.product.id === target.id;
        });
        for (const item of entryInCartArr) {
          if (item.product.id === target.id) {
            if (item.product.id !== lastPremiumItemsId) {
              quantityOptions = quantityOptions + item.quantity;
            }
          }
        }
      }
      return quantityOptions;
    } else {
      return;
    }
  };

  const displayOptions = () => {
    const entryInCartArr = cart.entries;
    return premiumItemsOption.map((option, index) => {
      const quantityProduct = entryInCartArr.find((value) => {
        return value.product.id === option.id;
      });

      let quantityOption = 0;
      for (const item of entryInCartArr) {
        if (item.product.id === option.id) {
          if (item.product.id !== lastPremiumItemsId) {
            quantityOption = item.quantity;
          }
        }
      }

      const img =
        option.galleryImages[0].image_url ||
        '/images/promotion/image_empty.png';
      return (
        <section className="prm-choice-item" key={index}>
          <img style={{ width: '72px', height: '72px' }} src={img} />
          <div className="description">
            <div className="identifier">{option.identifier}</div>
            <div className="price-row">
              <div className="disc-price">
                ฿ {getPriceFormat(option.targetPrice)}
              </div>
              <div className="actual-price">
                ฿ {getPriceFormat(option.prices[0].price)}
              </div>
            </div>
          </div>
          <div>
            <QuantityStepper
              orderTotal={
                quantityProduct &&
                quantityProduct.product.id === lastPremiumItemsId
                  ? quantity
                  : quantityOption
              }
              increaseOrder={() => {
                increaseOrder(option);
              }}
              decreaseOrder={() => {
                decreaseOrder(option);
              }}
              disableDecreaseStepper={getIsDecrementDisabled(
                quantityProduct,
                quantityOption,
              )}
              disableIncreaseStepper={getIsIncrementDisabled(quantityProduct)}
              disableStepperBox={isQtyStepperDisabled(quantityProduct)}
            />
          </div>
        </section>
      );
    });
  };

  const renderFooter = () => {
    const isDisabled = !isRequiredQtySelected();
    return (
      <div className="prm-choice-footer" style={{height: 'calc(15vh - 50px)'}}>
        <div onClick={() => closeLiffEvent()} className="footer-btn" style={{backgroundColor: (isDisabled) ? '#D9D9D9' : '#2C2C2C', pointerEvents: (isDisabled) ? 'none' : 'auto'}}>
          <div className={isDisabled ? "btn-text-disabled" : "btn-text"} style={{}}>
            <div>ตกลง</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mb-10" key={index}>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      <div className="card-product-body-cart">
        <div className="gift-product-container">
          <div
            className="gift-label-div card-product-title-text-cart font-black"
            style={{ flex: '1 1 0', wordWrap: 'break-word' }}
          >
            สินค้าแลกซื้อ
          </div>
        </div>
        <div className="gift-info-container">
          <img className="gift-img" src={img} alt="gift image" />
          <div className="info-div">
            <div className="gift-title-container">
              <span
                className="cart-product-info-cart font-black"
                style={{ alignSelf: 'stretch', wordWrap: 'break-word' }}
              >
                {identifier}
                {premiumItemsOption && (
                  <div onClick={() => setIsOpenLiff(true)}>
                    <span className="underline">เปลี่ยนสินค้า</span>
                    {' >'}
                  </div>
                )}
              </span>
            </div>
            <div
              className="cart-product-info-cart font-black"
              style={{ textAlign: 'right', wordWrap: 'break-word' }}
            >{`x${quantity}`}</div>
          </div>
        </div>
      </div>
      <LiffPromotionList
        title={`เลือกสินค้าได้ ${orderTotal} ชิ้น`}
        onClose={() => closeLiffEvent()}
        isOpen={isOpenLiff}
        bgColor={'#ffffff'}
        disableClose={!isRequiredQtySelected()}
      >
        <div
          className="content scroll-auto prm-choice"
          style={{ height: 'calc(75vh - 50px)' }}
        >
          {premiumItemsOption && (
            <>
              <div className="prm-current-qty">
                <div className="qtyValue">
                  {quantity + getQuantity()} / {orderTotal}
                </div>
              </div>
              {displayOptions(cart)}
            </>
          )}
        </div>
        {renderFooter()}
      </LiffPromotionList>
    </div>
  );
};

export default PromotionItem;
