import React, { useEffect, useState } from 'react';
import './ChallengeUsers.scss';

const ChallengeUsersLoader = ({count = 4}) => {
  return (
    <div className="challenge-users-loader-container">
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="challenge-users-loader-img" />
      ))}
    </div>
  );
};

export default ChallengeUsersLoader;
