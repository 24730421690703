import React from 'react';
import { Modal, Button } from 'antd';
import './modal.scss';
import { useHistory } from 'react-router-dom';
import { commonConstant } from '../../../common';

const ModalCategoryRemoveSuccess = (props) => {
  const { visibleModal, setVisibleModal } = props;
  const history = useHistory();

  const handleCancel = () => {
    setVisibleModal(false);
    history.push({
      pathname: commonConstant.pathLibraryProfile,
    });
  };

  const goToAddCategory = () => {
    setVisibleModal(false);
    window.location.replace(commonConstant.pathLibraryCategoryForm);
  };

  return (
    <Modal
      className="warp-modal"
      open={visibleModal}
      centered
      footer={null}
      onCancel={handleCancel}
      closable={true}
      maskClosable={false}
    >
      <div className="modal-image-icon">
        <img src="../../images/library/icon_delete_done.svg"></img>
      </div>
      <div className="modal-title">ลบสำเร็จ</div>
      <div className="modal-desc">
        หากต้องการสร้าง Category ใหม่ <br />
        กรุณา เลือก + Add category ค่ะ
      </div>
      <Button
        className="btn"
        onClick={() => {
          goToAddCategory();
        }}
        block
      >
        + Add category
      </Button>
    </Modal>
  );
};
export default ModalCategoryRemoveSuccess;
