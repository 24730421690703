import { th } from "date-fns/locale";
import { thaiDate } from "../utill.func";

export class ChallengeQuiz {
    constructor(data) {
        if (data) {
            this.id = data.id || '';
            this.quiz_part = new QuizPart(data?.exam_part) || null;
            this.quiz_question = data.exam_question.map(eq => new QuizQuestion(eq)) || null;
            this.quiz_part_id = data.exam_part_id || '';
            this.start_date = data.start_date || '';
            this.end_date = data?.end_date || '';
            this.thai_end_date = thaiDate(data?.end_date, 'dd MMM yy') || '';
            this.ref_user_id = data.ref_user_id || '';
            this.ref_event_id = data.ref_event_id || '';
            this.ref_score_id = data.ref_score_id || '';
            this.ref_period_id = data.ref_period_id || '';
            this.created_at = data.created_at || '';
            this.updated_at = data.updated_at || '';
            this.deleted_at = data.deleted_at || '';
            this.custom_data = data.custom_data || null;
            this.quiz_parts = data.exam_parts || null;
            this.quiz_user_answers = new QuizUserAnswers(data?.exam_user_answers) || null;
            this.allCorrectAnswers = getCorrectAnswers(this.quiz_question);
        }
    }
}

export class QuizPart {
    constructor(data) {
        if (data) {
            this.quiz_name = data.exam_name || '';
            this.quiz_title = data.exam_title || '';
            this.quiz_description = data.exam_description || '';
            this.max_score = data.max_score || null;
            this.score_per_question = data.score_per_question || null;
            this.required_question_count = data.required_question_count || null;
        }
    }
}

export class QuizQuestion {
    constructor(data) {
        if (data) {
            this.id = data.id || '';
            this.question_title = data.question_title || '';
            this.question_choice = data.question_choice || [];
            this.question_choice_competency = data.question_choice_competency || null;
            this.question_choice_score = data.question_choice_score || null;
            this.is_open_text = data.is_open_text || null;
            this.open_text_slot_count = data.open_text_slot_count || null;
            this.is_select_all = data.is_select_all || null;
            this.is_select_none = data.is_select_none || null;
            this.answer = data.answer >= 0 ? data.answer : null || null;
            this.raw_question = new RawQuestion(data?.raw_question) || null;
            this.question_type = data.question_type || '';
        }
    }
}

export class QuizUserAnswers {
    constructor(data) {
        if (data) {
            this.quiz_user_id = data.exam_user_id || '';
            this.user_answer = data.user_answer || [];
            this.score = data.score || 0;
            this.created_at = data.created_at || '';
            this.updated_at = data?.updated_at || '';
            this.thai_updated_at = thaiDate(data?.updated_at, 'dd MMM yy') || '';
            this.hasUserAnswered = (data?.user_answer?.length > 0) || false ;
        }
    }
}

export class RawQuestion {
    constructor(data) {
        if (data) {
            this.id = data.id || '';
            this.quiz_part_id = data.exam_part_id || '';
            this.question_title = data.question_title || '';
            this.question_choice = data.question_choice || [];
            this.question_answer = data.question_answer || null;
            this.created_at = data.created_at || '';
            this.updated_at = data.updated_at || '';
            this.deleted_at = data.deleted_at || null;
            this.question_type = data.question_type || '';
            this.quiz_parts = data.exam_parts || null;
            this.seq = data.seq || null;
        }
    }
}

export class RequestId {
    constructor(data) {
        if(data){
            this.request_id = data.request_id || '';
        }
    }
}

const getCorrectAnswers = (quizQuestions) => {
    const answers = quizQuestions.map(question => question.answer !== null && question.answer != undefined ? question.answer : null);
    return answers;
}

