import config from '../config';
import axios from 'axios'
export const activityService = {
    get,
    create,
    update,
};

async function create(params) {

    const response = await axios.post(`${config.API_URL}/customer-activities`, params)

    return response.data
}

async function update(params) {

    const response = await axios.post(`${config.API_URL}/customer-activities/${params.userId}`, params)

    return response.data._id
}

async function get(userId) {

    const customerActivity = await axios.get(`${config.API_URL}/customer-activities`, {
        params: {
            userId: userId
        }
    })

    return customerActivity.data[0]
}
