import React, { Component } from 'react';
import {
  displayStockLevelStatus,
  getPriceFormat,
} from '../../../services/promotion/utils.service';
import ModalImage from '../../modal/ModalImage';
import '../../promotion/CartPromotion.scss';
import QuantityStepper from '../QuantityStepper';
import KitEntryItem from './KitEntryItem';
import { ProductItemType } from './Product';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export class BundleProductItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderTotal: 0,
      modalIsOpen: false,
      hasSOPEntries: false,
      kitEntries: [],
      disableDecreaseStepper: false,
      disableIncreaseStepper: false,
    };

    this.setOpenModal = this.setOpenModal.bind(this);
    this.decreaseOrder = this.decreaseOrder.bind(this);
    this.increaseOrder = this.increaseOrder.bind(this);
  }

  componentDidMount() {
    const urlParams = queryString.parse(history.location.search);
    this.setState({
      orderTotal: this.props.quantity || 0,
      kitEntries: this.props.kitEntries,
      hasSOPEntries: this.props.hasSopEntries,
    });
    if(urlParams.productId == this.props.id) {
      this.increaseOrder();
    }
  }

  setOpenModal(modalIsOpen) {
    this.setState({ modalIsOpen });
  }

  increaseOrder() {
    if (this.state.orderTotal !== 1) {
      const totalPrice = this.props.totalPrice + this.props.price;
      const pointValue = this.props?.amwayValue?.pointValue || 0;
      const businessVolume = this.props?.amwayValue?.businessVolume || 0;
      const totalPointValue = this.props.totalPointValue + pointValue;
      const totalBusinessVolume = this.props.totalBusinessVolume + businessVolume;
      this.props.setTotalPrice(totalPrice);
      this.props.setTotalPointValue(totalPointValue);
      this.props.setTotalBusinessVolume(totalBusinessVolume);
      this.setState({ orderTotal: this.state.orderTotal + 1 });
      this.props.setProductSelected(this.props.id, this.state.orderTotal + 1);
      this.setState({disableIncreaseStepper : true});
    }
  }

  decreaseOrder() {
    if (this.state.orderTotal !== 0) {
      const totalPrice = this.props.totalPrice - this.props.price;
      const pointValue = this.props?.amwayValue?.pointValue || 0;
      const businessVolume = this.props?.amwayValue?.businessVolume || 0;

      const totalPointValue = this.props.totalPointValue - pointValue;
      const totalBusinessVolume = this.props.totalBusinessVolume - businessVolume;
      this.props.setTotalPrice(totalPrice);
      this.props.setTotalPointValue(totalPointValue);
      this.props.setTotalBusinessVolume(totalBusinessVolume);
      this.setState({ orderTotal: this.state.orderTotal - 1 });
      this.props.setTotalSelected(this.props.totalSelected - 1);
      this.props.setProductSelected(this.props.id, this.state.orderTotal - 1);
      this.setState({disableIncreaseStepper : false});
    }
  }

  renderKitEntry() {
    const kitEntries = this.state.kitEntries;
    return kitEntries.map((kitEntry, index) => {
      return (
        <KitEntryItem
          productId={this.props.id}
          key={index}
          alias={this.props.alias}
          kitEntry={kitEntry}
          setProductSelected={this.props.setProductSelected}
          productItemType={this.props.productItemType}
        />
      );
    });
  }

  totalConfiguredQuantity() {
    const kitEntries = this.state.kitEntries;
    let totalConfiguredQty = 0;
    const kitEntryArray = kitEntries.map((kitEntry) => {
      return kitEntry.configuredQty;
    });
    kitEntryArray.forEach((element) => {
      totalConfiguredQty += element;
    });
    return totalConfiguredQty;
  }

  displayAmwayPoint() {
    const pointValue = this.props?.amwayValue?.pointValue || 0;
    const businessVolume = this.props?.amwayValue?.businessVolume || 0;

    const { productItemType } = this.props;
    if (businessVolume && pointValue) {
      return (
        <span
          style={
            productItemType === ProductItemType.REMOVE
              ? { display: 'none' }
              : {}
          }
        >{`PV ${pointValue.toLocaleString()} / BV ${businessVolume.toLocaleString()}`}</span>
      );
    } else {
      return <></>;
    }
  }

  renderCartItemQuantity() {
    const { total, price } = this.props;
    if (total > 1) {
      return (
        <span>
          {total} x {`฿${getPriceFormat(price)}`}
        </span>
      );
    } else {
      return <></>;
    }
  }

  renderCartItemPrice() {
    const { totalPrice, total, price } = this.props;
    const normalPrice = total * price;
    if (normalPrice > totalPrice) {
      return (
        <>
          <span className="font-black cart-item-price-bold">{`฿${getPriceFormat(
            totalPrice,
          )}`}</span>
          <span className="ml-4 cart-item-price-cut">{`฿ ${getPriceFormat(
            normalPrice,
          )}`}</span>
        </>
      );
    }
    return (
      <span className="font-black cart-item-price-bold">{`฿ ${getPriceFormat(
        this.props.total * this.props.price,
      )}`}</span>
    );
  }

  renderProductItem() {
    return (
      <React.Fragment>
        <div className="product-bundle-container" style={{padding: '0px'}}>
          <div className="pd-20">
            <ModalImage
              modalIsOpen={this.state.modalIsOpen}
              setOpenModal={this.setOpenModal}
              viewImageSrc={this.props.img}
            />
            <div className="flex">
              <div className="product-image-container-cart">
                {this.state.hasSOPEntries && (
                  <div className="card-product-sop-tag">สินค้า SOP</div>
                )}
                <div className="card-product-img-promotion mb-10">
                  <img
                    src={this.props.img}
                    alt="product image"
                    onClick={() => this.setOpenModal(true)}
                  />
                </div>
              </div>
              <div>
                <div className="code-color">#{this.props.code}</div>
                <div className="card-product-title-cart mb-10">
                  <div className="font-black card-product-title-text">
                    {this.props.title}
                  </div>
                  <div className="font-black font-x-small font-stock">
                    {displayStockLevelStatus(this.props.stock)}
                  </div>
                </div>
              </div>
            </div>
            <div className="price-selection-panel flex">
              <div>
                <div className="font-black footer-price-bold">
                  ฿ {getPriceFormat(this.props.price)}
                </div>
                <div>
                  {this.props.amwayValue && (
                    <div className="amway-value-dark">
                      {this.displayAmwayPoint()}
                    </div>
                  )}
                </div>
              </div>
              <QuantityStepper
                orderTotal={this.state.orderTotal}
                increaseOrder={this.increaseOrder}
                decreaseOrder={this.decreaseOrder}
                disableIncreaseStepper={this.state.disableIncreaseStepper}
              />
            </div>
          </div>
          <div className="pd-5" style={{ display: this.state.orderTotal === 0 ? 'none' : '' }}>
            <div className="divider-header-bundle"></div>
            <div className="flex header-bundle font-xsmall">
              <div className="header-heading">
                <div className="text-configure-qty">
                  สินค้าในชุดผลิตภัณฑ์ {`(`}
                  {this.totalConfiguredQuantity()}
                  {`)`}
                </div>
              </div>
            </div>
            {this.renderKitEntry()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderCartProductItem() {
    return (
      <React.Fragment>
        <div className="product-bundle-container">
          <ModalImage
            modalIsOpen={this.state.modalIsOpen}
            setOpenModal={this.setOpenModal}
            viewImageSrc={this.props.img}
          />
          <div className="cart-card">
            <div className="product-image-container-cart">
              {this.state.hasSOPEntries && (
                <div className="card-product-sop-tag">สินค้า SOP</div>
              )}
              <div className="card-product-img-cart mb-10">
                <img
                  src={this.props.img}
                  alt="product image"
                  onClick={() => this.setOpenModal(true)}
                />
              </div>
            </div>
            <div className="cart-item-details-div">
              <div className="code-color cart-product-info-cart">
                #{this.props.code}
              </div>
              <div className="font-black card-product-title-text-cart">
                {this.props.title}
              </div>
              <div className="card-product-title-remove mb-10">
                <div className="cart-item-price-qty-card">
                  <div className="cart-item-price-card">
                    <div className="code-color cart-product-info-cart">
                      {this.renderCartItemQuantity()}
                    </div>
                    <div className="cart-item-price-div">
                      {this.renderCartItemPrice()}
                    </div>
                    <div className="code-color cart-product-info-cart">
                      {this.displayAmwayPoint()}
                    </div>
                  </div>
                  <QuantityStepper
                    orderTotal={this.state.orderTotal}
                    increaseOrder={this.increaseOrder}
                    decreaseOrder={this.decreaseOrder}
                    disableDecreaseStepper={true}
                    disableIncreaseStepper={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: this.state.orderTotal === 0 ? 'none' : '' }}>
            <div className="divider-header-bundle"></div>
            <div className="flex header-bundle font-xsmall">
              <div className="header-heading">
                <div className="text-configure-qty">
                  สินค้าในชุดผลิตภัณฑ์ {`(`}
                  {this.totalConfiguredQuantity()}
                  {`)`}
                </div>
              </div>
            </div>
            {this.renderKitEntry()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderRemoveProductItem() {
    return (
      <React.Fragment>
        <div className="product-bundle-container">
          <ModalImage
            modalIsOpen={this.state.modalIsOpen}
            setOpenModal={this.setOpenModal}
            viewImageSrc={this.props.img}
          />
          <div className="cart-card" style={{ height: 'auto' }}>
            <div className="product-image-container-cart">
              {this.state.hasSOPEntries && (
                <div className="card-product-sop-tag">สินค้า SOP</div>
              )}
              <div className="card-product-img-cart mb-10">
                <img
                  src={this.props.img}
                  alt="product image"
                  onClick={() => this.setOpenModal(true)}
                />
              </div>
            </div>
            <div className="cart-item-details-div">
              <div className="code-color cart-product-info-cart">
                #{this.props.code}
              </div>
              <div className="font-black card-product-title-text-cart">
                {this.props.title}
              </div>
              <div className="card-product-title-remove mb-10">
                <div className="cart-item-price-qty-card">
                  <div className="cart-item-price-card">
                    <div className="cart-item-price-div">
                      {this.renderCartItemPrice()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="divider-header-bundle"></div>
            <div className="flex header-bundle font-xsmall">
              <div className="header-heading">
                <div className="text-configure-qty">
                  สินค้าในชุดผลิตภัณฑ์ {`(`}
                  {this.totalConfiguredQuantity()}
                  {`)`}
                </div>
              </div>
            </div>
            {this.renderKitEntry()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    if (this.props.productItemType === ProductItemType.CART) {
      return this.renderCartProductItem();
    } else if (this.props.productItemType === ProductItemType.REMOVE) {
      return this.renderRemoveProductItem();
    } else {
      return this.renderProductItem();
    }
  }
}
export default BundleProductItem;
