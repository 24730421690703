import {
  GET_BODY_KEY_VERIFY,
  GET_BODY_KEY_VERIFY_ERROR,
  GET_BODY_KEY_VERIFY_SUCCESS,
  GET_BODY_KEY_RECORD,
  GET_BODY_KEY_RECORD_SUCCESS,
  GET_BODY_KEY_RECORD_ERROR,
  POST_OTP_VERIFY_BODY_KEY_SUCCESS,
  POST_OTP_VERIFY_BODY_KEY_ERROR,
  POST_OTP_GENERATE_BODY_KEY_ERROR,
  POST_OTP_GENERATE_BODY_KEY_SUCCESS,
  POST_OTP_VERIFY_BODY_KEY,
  POST_OTP_GENERATE_BODY_KEY,
} from '../../actions/types';

const initialState = {
  // pair verification
  loading: false,
  verificationResponse: null,
  isSuccess: false,
  error: null,
  bodyKeyVerifyError: null,
  // records
  hnwRecordsLoader: true,
  loadingRecords: false,
  bodyKeyRecords: [],
  isRecordSuccess: false,
  recordError: null,
  phoneNumber: '',
  otpFailure: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BODY_KEY_VERIFY:
    case POST_OTP_GENERATE_BODY_KEY:
    case POST_OTP_VERIFY_BODY_KEY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_BODY_KEY_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,bodyKeyVerifyError: null,
        isSuccess: !!action.payload?.isSuccess,
        verificationResponse: action.payload,
      };
    case GET_BODY_KEY_VERIFY_ERROR:
    return {
        ...state,
        loading: false,
        error: action.payload,
        bodyKeyVerifyError: action.payload,
        isSuccess: false,
        verificationResponse: null,
      };
    case GET_BODY_KEY_RECORD:
      return {
        ...state,
        loadingRecords: true,
        recordError: null,
      };
    case GET_BODY_KEY_RECORD_SUCCESS:
      return {
        ...state,
        loadingRecords: false,
        hnwRecordsLoader: false,
        recordError: null,
        bodyKeyRecords: action.payload?.data || [],
        isRecordSuccess: !!action.payload?.isSuccess,
      };
    case GET_BODY_KEY_RECORD_ERROR:
      return {
        ...state,
        loadingRecords: false,
        hnwRecordsLoader: false,
        recordError: action.payload?.errorMsg,
        bodyKeyRecords: [],
        isRecordSuccess: false,
      };
    case POST_OTP_GENERATE_BODY_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        phoneNumber: action.payload?.phoneNumber,
        error: null,
      };
    case POST_OTP_GENERATE_BODY_KEY_ERROR:
      return {
        ...state,
        loading: false,
        phoneNumber: '',
        error: action.payload,
      };
    case POST_OTP_VERIFY_BODY_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        otpFailure: null,
      };
    case POST_OTP_VERIFY_BODY_KEY_ERROR:
      return {
        ...state,
        loading: false,
        otpFailure: action.payload,
      };
    default:
      return state;
  }
}
