import React from 'react'
import './NoResultFound.scss';


export default function NoResultFound(props) {


    return (
        <div className="no-result-found-container">
            <img className="empty-img-wrapper" src={props.emptyImg} />
            <div className="header">{props?.header}</div>
            <div className="description">{props?.description}</div>
        </div>
    )
}