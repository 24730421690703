
import React from 'react';
import Liff from '../../../../modal/Liff';
import './AssessmentPopup.scss';

export const AssessmentPopup = ({ isOpen, children, title, type, onClick = () => { } }) => (
  <div className={`answer-card-modal-popup --${type?.toLowerCase()}`}>
    <Liff
      hideCloseBtn={true}
      isOpen={isOpen}
      title={title}
    >
      <div className="answer-card-modal-popup-body">
        <p className="answer-card-modal-popup-description">{children}</p>
        <button className="answer-card-modal-popup-btn" onClick={onClick}>{'เข้าใจแล้ว'}</button>
      </div>
    </Liff>
  </div>);

export default AssessmentPopup;
