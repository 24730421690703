import React from "react";
import "./TeamDashboardStepsLoader.scss";

const TeamDashboardStepsLoader = () => {
  return (
    <div className="team-dashboard-step-container">
    <div className="steps-skeleton-container">
    <div className="steps-skeleton-card-container">
      <div className="skeleton-card-image skeleton"></div>
    </div>
  </div>
  </div>
  );
};

export default TeamDashboardStepsLoader;
