import React from 'react';
import { HNW_GOAL_SETTING } from '../../../../../common/commonConstant';
export default function HnwExerciseMenuItem({
  item,
  selectedExerciseMenu,
  onClickExerciseMenu,
  activityState,
}) {
  const exerciseMenu = activityState.exerciseCategory.flatMap((ec) => ec?.exerciseMenu);

  const addedExerciseMenu = exerciseMenu
    .filter((a) => a)
    .map((a) => a.id);

  const selectedExercise = exerciseMenu.find(menu => menu.id === item.id);

  return (
    <div
      className={`activity-goal-card-single ${selectedExerciseMenu.map((a) => a.id).includes(item.id)
        ? 'activity-goal-card-active'
        : ''
        } ${addedExerciseMenu.includes(item.id) ? 'activity-goal-card-disabled' : ''
        }`}
      onClick={() => {
        if (addedExerciseMenu.includes(item.id)) {
          return;
        }
        onClickExerciseMenu(item);
      }}
    >
      <img
        src={
          item.image
            ? item.image
            : '/images/healthAndWellness/goals/activity/default-exercise-menu.svg'
        }
        className={`activity-goal-card-img ${addedExerciseMenu.includes(item.id) ? 'filter-grayscale' : ''
          }`}
      />
      <div className="activity-goal-card-description">
        <div className="activity-goal-card-title">
          <div className='activity-goal-card-title-text'>
            {item.name}
          </div>
        </div>
        <div className="activity-goal-card-bottom">
          <img src="/images/healthAndWellness/goals/common/clock.svg" />
          <span className="selected-title">
            {addedExerciseMenu.includes(item.id) ? HNW_GOAL_SETTING.ACTIVITY.SELECTED_IN_TH
              : HNW_GOAL_SETTING.ACTIVITY.RECOMMENDED_IN_TH}
          </span>
          <span className="selected-value">
            {addedExerciseMenu.includes(item.id) ? selectedExercise?.suggestionTime : item.suggestionTime} {HNW_GOAL_SETTING.ACTIVITY.MINUTE_UNIT}
          </span>
        </div>
      </div>
    </div>
  );
}
