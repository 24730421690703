import React, { Component } from 'react';
import { Modal } from '../../../helpers';

import './CreateCreditCard.scss';

const visaRegex = /^4[0-9]{6,15}$/;
const mastercardRegex = /^5[1-5][0-9]{5,}|^222[1-9][0-9]{3,}|^22[3-9][0-9]{4,}|^2[3-6][0-9]{5,}|^27[01][0-9]{4,}|^2720[0-9]{3,}$/;

export class CreateCreditCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      expireDate: null,
      cardNo: null,
      showModal: false,
      modalContent: null,
    };
  }

  onChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  toggleModal = (type) => {
    this.setState(state => ({
      showModal: !state.showModal,
      modalContent: type,
    }));
  }

  creditCardFormat = (value) => {
    const valueMerged = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = valueMerged.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];
    const len = match.length;
    for (let i = 0; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      this.setState({ cardNo: parts.join('-') });
    } else {
      this.setState({ cardNo: value });
    }
  }

  expireDateFormat = (value) => {
    const valueMerged = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = valueMerged.match(/\d{2,4}/g);
    const match = matches && matches[0] || '';
    const parts = [];
    const len = match.length;
    for (let i = 0; i < len; i += 2) {
      parts.push(match.substring(i, i + 2));
    }
    if (parts.length) {
      this.setState({ expireDate: parts.join('/') });
    } else {
      this.setState({ expireDate: value });
    }
  }

  checkValidExpireDate = (expireDate) => {
    if (expireDate){
      const matches = expireDate.match(/\d{2}/g);
      if (Array.isArray(matches)){
        if (matches[0] > 12){
          return false;
        }
        return true;
      }
    }
    return true;
  }

  handleCheckCardType = cardNo => {
    if (cardNo){
      const valueMerged = cardNo.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
      if (visaRegex.test(valueMerged)){
        return 'visa';
      } else if (mastercardRegex.test(valueMerged)){
        return 'mastercard';
      } else {
        return null;
      }
    }
    return null;
  }

  handleSubmit = () => {
    const { toggleModal } = this;
    const { cardNo } = this.state;
    if (cardNo){
      toggleModal('CONFIRM');
    } else {
      toggleModal('EMPTY');
    }
  }

  handleModalOk = () => {
    const { toggleModal } = this;
    const { modalContent } = this.state;
    switch (modalContent) {
      case 'CONFIRM':
        this.setState({
          redirect: '/',
        });
        break;
      default:
        toggleModal();
    }
  }

  renderModalContent = () => {
    const { modalContent } = this.state;
    switch (modalContent) {
      case 'CVV':
        return ({
          type: null,
          okBtn: false,
          cancleBtn : false,
          content: 
          <>
          <img src="/images/payment/Wallet.svg" />
          <div className="title">รหัส CVV คือ</div>
          <div>รหัสที่อยู่บนบัตรเครดิตมีไว้เพื่อการยืนยัน ตัวตนของผู้ถือบัตร โดยบัตรวีซ่าและ มาสเตอร์การ์ดจะมีรหัส 3 หลัก อยู่ที่ด้านหลังบัตรเครดิต</div>
          </>,
        });
      case 'SUCCESS':
        return ({ type: 'success',okBtn: false,cancleBtn : false, content: <div>บันทึกแล้ว</div> });
      case 'CONFIRM':
        return ({
          type: 'warning',
          okBtn: true,
          cancleBtn : true,
          content: <>
          <div>ยืนยันบันทึกบัตรเครดิตเลขที่</div>
          <div>4417 70 • • • • • • 1234</div>
          </>,
        });
      case 'REDIRECT':
        return ({ type: 'warning',okBtn: true,cancleBtn : true, content: <div>ยืนยันออกจากหน้านี้โดยไม่บันทึก</div> });
      case 'EMPTY':
        return ({ type: 'warning',okBtn: true,cancleBtn : false, content: <div>กรุณากรอกข้อมูลให้ครบถ้วน</div> });
      default:
        return ({ type: null,content: null });
    }
  }
  
  render() {
    const { handleCheckCardType,creditCardFormat,expireDateFormat, checkValidExpireDate, handleModalOk, renderModalContent } = this;
    const { cardNo,expireDate, showModal, modalContent } = this.state;
    const cardType = handleCheckCardType(cardNo);
    const isValidExpireDate = checkValidExpireDate(expireDate);
    const content = renderModalContent();
    return (
      <div className="create-creditcard-container">
        {
          showModal && <Modal
            content={content.content}
            type={content.type}
            fnClose={handleModalOk}
            fnOk={handleModalOk}
            fnCancel={content.cancleBtn ? handleModalOk : null}
            closeBtn={modalContent === 'CVV'}
            okBtn={content.okBtn}
            okText="ยืนยัน"
            cancleText="ยกเลิก" />
        }
      
        <div className="body">
          <div className="title">เพิ่มบัตรเครดิต / เครดิต</div>
          <label>หมายเลขบัตรเครดิต / เดบิต</label>
          <div className="relative">
            <input
              id="cardNo"
              value={cardNo}
              onInput={e => creditCardFormat(e.target.value)}
              className="form-control input-line-bottom"
            />
            <img className={`visa ${cardType === 'visa' && 'active'}`} src="/images/payment/visa.svg" />
            <img className={`mastercard ${cardType === 'mastercard' && 'active'}`} src="/images/payment/mastercard.svg" />
          </div>

          <label>ชื่อผู้ถือบัตร</label>
          <input
            className="form-control input-line-bottom"
          />
          <div className="bottom">
            <div>
              <label>วันหมดอายุ (MM/YY)</label>
              <input
                onInput={e => expireDateFormat(e.target.value)}
                value={expireDate}
                className={`form-control input-line-bottom ${isValidExpireDate ? '' : 'invalid'}`}
              /> 
            </div>
            <div className="relative">
              <label>CVV</label>
              <input
                type="password"
                maxLength="3"
                className="form-control input-line-bottom"
              />
              <i className="question-mark" onClick={() => this.toggleModal('CVV')}>
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.99756 8.45508C5.62256 8.45508 5.34131 8.75977 5.34131 9.11133C5.34131 9.48633 5.62256 9.76758 5.99756 9.76758C6.34912 9.76758 6.65381 9.48633 6.65381 9.11133C6.65381 8.75977 6.34912 8.45508 5.99756 8.45508ZM6.16162 7.89258C6.32568 7.89258 6.44287 7.77539 6.44287 7.61133C6.44287 6.60352 8.271 6.67383 8.271 5.10352C8.271 3.9082 7.19287 3.20508 5.97412 3.20508C4.91943 3.20508 4.3335 3.62695 3.84131 4.25977C3.74756 4.40039 3.771 4.56445 3.91162 4.6582L4.21631 4.89258C4.3335 4.96289 4.49756 4.93945 4.59131 4.82227C4.94287 4.37695 5.29443 4.14258 5.97412 4.14258C6.91162 4.14258 7.3335 4.63477 7.3335 5.10352C7.3335 6.11133 5.50537 5.9707 5.50537 7.61133C5.50537 7.77539 5.646 7.89258 5.78662 7.89258H6.16162ZM5.99756 1.42383C8.76318 1.42383 11.0601 3.69727 11.0601 6.48633C11.0601 9.29883 8.78662 11.5488 5.99756 11.5488C3.18506 11.5488 0.935059 9.29883 0.935059 6.48633C0.935059 3.69727 3.18506 1.42383 5.99756 1.42383ZM5.99756 0.673828C2.78662 0.673828 0.185059 3.29883 0.185059 6.48633C0.185059 9.69727 2.78662 12.2988 5.99756 12.2988C9.18506 12.2988 11.8101 9.69727 11.8101 6.48633C11.8101 3.29883 9.18506 0.673828 5.99756 0.673828Z" fill="#2C2C2C"/>
                </svg>

              </i>
            </div>
          </div>
        </div>
        <div className="footer">
          <button className={'register-next-button'}>ยืนยัน</button>
        </div>
      </div>
    );
  }
}

export default CreateCreditCard;
