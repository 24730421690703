import React from 'react';
import './HnwGoalCard.scss';
import {
  GOAL_SETTING_MINUTE_UNIT,
  HEALTH_GOAL_TYPES,
  HEALTH_GOAL_TYPES_EXERCISE,
} from '../../../../../common/commonConstant';
import {
  HnwAddGoalBtn,
  HnwGoalCardHeader,
  HnwWeightGoalCardBody,
  HnwWaterGoalCardBody,
  HnwSupplementGoalCardBody,
  HnwActivityGoalCardBody,
  HnwNutritionGoalCardBody,
} from '../index';
import LinearRowThumbnailContentEMenu from './LinearRowThumbnailContentEMenu';

const GoalDescription = ({ descriptionContent }) => {
  return (
    <>
      <span>{descriptionContent.description}</span>
      <br />
      <span>{descriptionContent.suffixDescription}</span>
    </>
  );
};
export default function HnwGoalCard({
  content,
  healthGoal,
  type,
  routeTo,
  showDeleteModal = true,
  routeParams = '',
}) {
  function renderHealthGoalType(healthGoalType) {
    switch (healthGoalType) {
      case HEALTH_GOAL_TYPES.WEIGHT:
        return (
          <HnwWeightGoalCardBody
            type={healthGoalType}
            content={content}
            healthGoal={healthGoal}
          />
        );
      case HEALTH_GOAL_TYPES.NUTRITION:
        return (
          <HnwNutritionGoalCardBody
            type={healthGoalType}
            content={content}
            healthGoal={healthGoal}
          />
        );
      case HEALTH_GOAL_TYPES.SUPPLEMENT:
        return (
          <HnwSupplementGoalCardBody
            type={healthGoalType}
            content={content}
            healthGoal={healthGoal}
          />
        );
      case HEALTH_GOAL_TYPES.WATER:
        return (
          <HnwWaterGoalCardBody
            type={healthGoalType}
            content={content}
            healthGoal={healthGoal}
          />
        );
      case HEALTH_GOAL_TYPES.ACTIVITY:
        return (
          <HnwActivityGoalCardBody
            type={healthGoalType}
            content={content}
            healthGoal={healthGoal}
          />
        );
      default:
        return null;
    }
  }

  const renderDescription = (healthGoalType, descriptionContent) => {
    switch (healthGoalType) {
      case HEALTH_GOAL_TYPES.WEIGHT:
        return <GoalDescription descriptionContent={descriptionContent} />;
      case HEALTH_GOAL_TYPES.NUTRITION:
        return descriptionContent.description;
      case HEALTH_GOAL_TYPES.SUPPLEMENT:
        return <GoalDescription descriptionContent={descriptionContent} />;
      case HEALTH_GOAL_TYPES.WATER:
        return <GoalDescription descriptionContent={descriptionContent} />;
      case HEALTH_GOAL_TYPES.ACTIVITY:
        return descriptionContent.description(healthGoal?.activities?.length);
      default:
        break;
    }
  };

  return (
    <div
      className={`goal-setup-card goal-setup-${type}-card d-flex flex-column`}
    >
      <HnwGoalCardHeader
        type={type}
        content={content}
        healthGoal={healthGoal}
        routeTo={routeTo}
        routeParams={routeParams}
        showDeleteModal={showDeleteModal}
      />
      <div className={`goal-setup-body goal-setup-${type}-body`}>
        <p className="goal-setup-description">
          {renderDescription(type, content)}
        </p>
        {healthGoal ? (
          renderHealthGoalType(type)
        ) : (
          <HnwAddGoalBtn type={type} routeTo={routeTo} />
        )}
      </div>
      {type === HEALTH_GOAL_TYPES.ACTIVITY && healthGoal?.activities?.length ? (
        <div
          className={`goal-setup-card goal-setup-exerciseGoal-card d-flex flex-column`}
        >
          <HnwGoalCardHeader
            type={HEALTH_GOAL_TYPES_EXERCISE}
            content={content[HEALTH_GOAL_TYPES_EXERCISE]}
            healthGoal={healthGoal?.activities[0]}
            showDeleteModal={showDeleteModal}
          />
          <div
            className={`goal-setup-body goal-setup-${HEALTH_GOAL_TYPES.ACTIVITY}-body`}
          >
            <p className="goal-setup-description">
              {content[HEALTH_GOAL_TYPES_EXERCISE].description}
            </p>
            <div className="card-body-section-exercise">
              {healthGoal?.activities
                .flatMap((activity) => activity.exerciseMenu)
                .map((exerciseMenu, i) => (
                  <LinearRowThumbnailContentEMenu
                    key={`${exerciseMenu?.exerciseId}_${i}`}
                    type={type}
                    content={content}
                    healthGoalImgUri={exerciseMenu?.image}
                    healthGoalName={exerciseMenu?.name}
                    healthGoalValue={parseInt(exerciseMenu?.suggestionTime)}
                    healthGoalUnit={GOAL_SETTING_MINUTE_UNIT}
                    crop
                  />
                ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
