import React, { useState } from 'react';
import './MyImagePreview.scss';
import { PERSONAL_MEMBER_PICTURE } from '../../challengeConstants';
import ImageCropper from './ImageCropper';

// PARENT
export default function MyImagePreview({
  crop,
  setCrop,
  zoom,
  setZoom,
  setCoord,
  previewBlob,
  uploadCallback,
  retakeCallback,
  closePreview,
  activePosition,
}) {
  
  return (
    <div className="upload-image-wrapper">
      <div className="upload-image-container">
        <div className="container-fluid">
          <div className="row upload-img-row">
            <div className="col-6">
              <div className="upload-image-title">{activePosition}</div>
            </div>
            <div className="col-6 pull-right text-right" onClick={closePreview}>
              <a>
                <img
                  src="/images/challenge/myupload/icon_close.svg"
                  alt="close-icon"
                />
              </a>
            </div>
          </div>
          <ImageCropper
            crop={crop}
            setCrop={setCrop}
            zoom={zoom}
            setZoom={setZoom}
            setCoord={setCoord}
            image={previewBlob}
          />
        </div>
        <div className="text-center ab-pic-bottom-wrapper ab-pic-bottom-sticky">
          <div className="container-fluid">
            <div className="ab-pic-bottom-footer">
              <button className="btn btn-outline-red" onClick={retakeCallback}>
                {PERSONAL_MEMBER_PICTURE.PREVIEW_RETAKE_BTN}
              </button>
              <button
                className="btn btn-green btn-green-linear"
                onClick={uploadCallback}
              >
                {PERSONAL_MEMBER_PICTURE.PREVIEW_SUCCESS_BTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
