import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HnwAddItemCard,
  HnwSetterCardList,
  HnwCheckbox,
  HealthDeleteFoodModal,
  HnwSuccessOverlayModal,
  CalendarLoader,
} from '../../../../common';
import commonConstant, {
  GOAL_SUPPLEMENTS_DETAILS,
  SUPPLEMENT_LIST_MAPPING,
  GOALS_SUBMIT_BUTTON_TITLE,
  HNW_FOOD_LOG,
} from '../../../../common/commonConstant';
import { getParamsString } from '../../../../utill.func';
import { createBrowserHistory } from 'history';
import {
  fetchGoalsSupplements,
  updateGoalsSupplements,
} from '../../../../actions/hnwGoalsActions';
import './SupplementsGoal.scss';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();

export default function SupplementsGoal({ routeTo }) {
  const dispatch = useDispatch();
  const supplementsGoalData = useSelector(
    (state) => state.hnwGoals.supplementsGoal,
  );

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const supplementsGoalId = params.get('supplementGoal_id');
  const referrerFrom = params.get('referrerFrom');

  const minCardValue = GOAL_SUPPLEMENTS_DETAILS.MINIMUM_CARD_VALUE;
  const maxCardValue = GOAL_SUPPLEMENTS_DETAILS.MAXIMUM_CARD_VALUE;

  const [supplementsGoalVal, setSupplementsGoalVal] = useState(
    supplementsGoalData?.supplementData || [],
  );
  const [isListEdit, setIsListEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiHit, setIsApiHit] = useState(false);
  const [isDeleteApiHit, setIsDeleteApiHit] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    fetchSupplementsGoalData();
  }, [supplementsGoalId]);

  useEffect(() => {
    const supplementData = supplementsGoalData?.supplementData;
    const isError = supplementsGoalData?.hasError;
    if (supplementData) {
      setSupplementsGoalVal(supplementData);
    }
    if (supplementData || isError || isApiHit) {
      setIsLoading(false);
    }
    if (isDeleteApiHit) {
      setIsDeleteApiHit(false);
    }
  }, [supplementsGoalData]);

  useEffect(() => {
    if (isListEdit && !supplementsGoalVal.length) {
      setIsListEdit(false);
    }
    if (isDeleteApiHit) {
      handleSubmitSupplementGoal('isDelete');
    }
    const isInvalidVal = supplementsGoalVal?.some(
      (supplement) =>
        parseInt(supplement.quantity) < parseInt(minCardValue) ||
        parseInt(supplement.quantity) > parseInt(maxCardValue),
    );
    setIsButtonDisabled(isInvalidVal);
  }, [supplementsGoalVal]);

  const fetchSupplementsGoalData = async () => {
    if (supplementsGoalId && !supplementsGoalData) {
      setIsLoading(true);
      await dispatch(fetchGoalsSupplements());
      setIsLoading(false);
    }
  };

  const handleAddButtonClick = () => {
    const params = getParamsString({
      referrer: SUPPLEMENT_LIST_MAPPING.GOAL_REFERRER_VALUE,
      ...(referrerFrom && { referrerFrom: referrerFrom }),
      ...(supplementsGoalId && { supplementsGoalId }),
    });
    if (supplementsGoalId) {
      handleSubmitSupplementGoal('isUpdate').then(() =>
        routeTo('pathFoodCalories', `?${params}`))
        .catch(e => {
          throw e;
        });
    } else {
      routeTo('pathFoodCalories', `?${params}`);
    }
  };

  const handleEditBtnClick = () => {
    setIsListEdit(true);
  };

  const handleCancelBtnClick = () => {
    setIsListEdit(false);
  };

  const handleDeleteBtnClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmBtnClick = () => {
    setIsDeleteApiHit(true);
    setSupplementsGoalVal((prevState) =>
      prevState.filter((supplement) => !supplement.isChecked),
    );
    setShowDeleteModal(false);
    setIsListEdit(false);
  };

  const handleCardValueChange = (newVal, name) => {
    if (newVal === '0') {
      setIsDeleteApiHit(true);
      setSupplementsGoalVal((prevState) =>
        prevState.filter((supplement) => supplement.name !== name),
      );
    } else {
      setSupplementsGoalVal((prevState) =>
        prevState.map((item) =>
          item.name === name ? { ...item, quantity: newVal } : item,
        ),
      );
    }
  };

  const handleCheckValueChange = (isChecked, name) => {
    setSupplementsGoalVal((prevState) =>
      prevState.map((item) =>
        item.name === name ? { ...item, isChecked: !isChecked } : item,
      ),
    );
  };

  const handleListCheckValueChange = () => {
    setSupplementsGoalVal((prevState) =>
      prevState.map((item) => ({
        ...item,
        isChecked:
          listCheckBoxState !== 'intermediate' ? !item.isChecked : true,
      })),
    );
  };

  const handleSubmitSupplementGoal = async (type) => {
    setIsApiHit(true);
    setIsLoading(true);
    if (type !== 'isDelete') {
      setIsDeleteApiHit(false);
    }
    await dispatch(
      updateGoalsSupplements({
        supplementsGoalId,
        data: {
          supplementData: supplementsGoalVal,
        },
      }),
    );
    setIsLoading(false);
    if (type === 'isSubmit') {
      setShowSuccess(true);
    }
  };

  const handleSuccessRedirect = () => {
    let routePath = 'pathHnwGoalsHome';
    if (referrerFrom && referrerFrom === HNW_FOOD_LOG.routeMapping.referrer) {
      routePath = HNW_FOOD_LOG.routeMapping.routePath;
    }
    routeTo(routePath);
    setIsApiHit(false);
  };

  const calculateCheckboxState = (cardList) => {
    const allChecked = cardList.every((obj) => obj.isChecked === true);
    const allUnchecked = cardList.every(
      (obj) => !('isChecked' in obj) || obj.isChecked === false,
    );

    if (allChecked) {
      return 'checked';
    } else if (allUnchecked) {
      return 'unchecked';
    } else {
      return 'intermediate';
    }
  };

  const listCheckBoxState =
    isListEdit && calculateCheckboxState(supplementsGoalVal);

  return (
    <div className="hnw-goals-supplements-container">
      <img
        src="/images/healthAndWellness/goals/supplements/supplements_goal_header.svg"
        alt="header-image"
        className="hnw-goals-supplements-header"
      />
      <div className="hnw-goals-supplements-details-container">
        {supplementsGoalVal?.length > 0 ? (
          <HnwSetterCardList
            headerText={GOAL_SUPPLEMENTS_DETAILS.LIST_HEADER_TEXT}
            cardDetailsList={supplementsGoalVal}
            defaultCardImg="/images/healthAndWellness/goals/common/supplement_default.svg"
            btnText={GOAL_SUPPLEMENTS_DETAILS.LIST_BUTTON_TEXT}
            showAddBtn
            showEditBtn
            isEditMode={isListEdit}
            minCardValue={minCardValue}
            maxCardValue={maxCardValue}
            stepperCardValue={1}
            onAddBtnClick={handleAddButtonClick}
            onEditBtnClick={handleEditBtnClick}
            onCancelBtnClick={handleCancelBtnClick}
            onValueChange={handleCardValueChange}
            onCheckValueChange={handleCheckValueChange}
          />
        ) : (
          <div className="hnw-goals-supplements-details-container__add-card-wrapper">
            <HnwAddItemCard
              title={GOAL_SUPPLEMENTS_DETAILS.ADD_ITEM_CARD_TITLE}
              handleAddButtonClick={handleAddButtonClick}
            />
          </div>
        )}
        <div className="hnw-goals-supplements-details-container__suggestions-wrapper">
          <img
            src="/images/healthAndWellness/goals/supplements/supplements_suggestion.svg"
            alt="suggestion-group-image"
          />
          <ul>
            <li>{GOAL_SUPPLEMENTS_DETAILS.SUGGESTION_LIST_FIRST}</li>
            <li>{GOAL_SUPPLEMENTS_DETAILS.SUGGESTION_LIST_SECOND}</li>
          </ul>
        </div>
      </div>
      <div className="hnw-goals-supplements-footer">
        <img
          className="hnw-goals-supplements-footer-img"
          src="/images/healthAndWellness/goals/goalSettingFooter.svg"
          alt="footer-image"
        />
        {isListEdit ? (
          <div className="hnw-goals-supplements-footer-wrapper hnw-goals-supplements-footer__checkbox-wrapper">
            <HnwCheckbox
              state={listCheckBoxState}
              label={GOAL_SUPPLEMENTS_DETAILS.CHECKBOX_EDIT_LABEL}
              onCheckValueChange={handleListCheckValueChange}
            />
            <button
              onClick={handleDeleteBtnClick}
              disabled={listCheckBoxState === 'unchecked'}
              className="hnw-goals-supplements-footer-btn hnw-goals-supplements-footer__checkbox-wrapper-btn"
            >
              {GOAL_SUPPLEMENTS_DETAILS.DELETE_BUTTON_TEXT}
            </button>
          </div>
        ) : (
          <button
            className="hnw-goals-supplements-footer-wrapper hnw-goals-supplements-footer-btn"
            disabled={!supplementsGoalVal.length || isButtonDisabled}
            onClick={() => handleSubmitSupplementGoal('isSubmit')}
          >
            {GOALS_SUBMIT_BUTTON_TITLE}
          </button>
        )}
      </div>
      {showDeleteModal && (
        <HealthDeleteFoodModal
          isDeleteModalOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleConfirmBtnClick}
          cancelBtnColor={'black'}
          titleColor={'black'}
        />
      )}
      <CalendarLoader showLoading={isLoading} />
      {showSuccess && (
        <HnwSuccessOverlayModal
          show={showSuccess}
          onTimeout={handleSuccessRedirect}
        />
      )}
    </div>
  );
}
