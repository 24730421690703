import { POLICY_FETCH, ADDR_FETCH, CHECK_RENEWAL, ADD_ADDRESS, CREATE_CART, ADD_ITEM, STORE_FETCH, CAL_CART, VALIDATE_CART, PICK_STORE, UPDATE_DELIVERY } from '../actions/types';

export function policy(state = {}, action) {
  switch (action.type) {
    case POLICY_FETCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function address(state = {}, action) {
  switch (action.type) {
    case ADDR_FETCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function addAddress(state = {}, action) {
  switch (action.type) {
    case ADD_ADDRESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function checkrenew(state = {}, action) {
  switch (action.type) {
    case CHECK_RENEWAL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}


export function cart(state = {}, action) {
  switch (action.type) {
    case CREATE_CART:
      return { ...state, ...action.payload };
    case CAL_CART:
      return { ...state, ...action.payload, calculated: action.calculated };
    case VALIDATE_CART:
      return { ...state, validate: action.payload };
    default:
      return state;
  }
}


export function addItem(state = {}, action) {
  switch (action.type) {
    case ADD_ITEM:
      return { ...state, ...action.payload, hasError: action.hasError };
    default:
      return state;
  }
}

export function getStore(state = {}, action) {
  switch (action.type) {
    case STORE_FETCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function pickStore(state = {}, action) {
  switch (action.type) {
    case PICK_STORE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function delivery(state = {}, action) {
  switch (action.type) {
    case UPDATE_DELIVERY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}