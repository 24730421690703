import React, { useEffect } from "react";
import { AMWAY_CLICK_DEEPLINK } from "../../common/commonConstant";

const AmwayClickDeeplink = () => {

    useEffect(() => {
        window.location = new URL(AMWAY_CLICK_DEEPLINK)
    }, [])
    return <></>
}

export default AmwayClickDeeplink;