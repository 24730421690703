import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import LoadingOverlay from 'react-loading-overlay';
import commonConstant from '../../../common/commonConstant';
import { PlacePicker, SelectorAddress } from '../../../helpers';
import {
  getAddress,
  getDistrict,
  getProvince,
  getSubdistrict
} from '../../../services/promotion/address.service';
import { message } from '../../../services/promotion/utils.service';
import './registerFOA.scss';
import { liffGetProfile } from '../../../services/promotion/auth.service';

const { pathPromotionAddressList, pathCartPromotion } = commonConstant;

const { liff } = window;

const GeocodeapiKey = process.env.REACT_APP_GOOGLE_API_GEOCODE_KEY;
//! Waiting Google Api Key
const defaultLang = 'th';
const defaultRegion = 'th';
Geocode.setApiKey(GeocodeapiKey);
Geocode.enableDebug();

export const FOARegisterAddress = (props) => {
  const [contactPersonName, setContactPersonName] = useState('');
  const [addr, setAddr] = useState('');
  const [province, setProvice] = useState('');
  const [district, setDistrict] = useState('');
  const [subDistrict, setSubDistrict] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [openMap, setOpenMap] = useState(false);
  const [provinceList, setProvicesList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [subdistrictList, setSubdistrictList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isNotAllField, setIsNotAllField] = useState(true);
  const [addressList, setAddressList] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [showModal, setShowModal] = useState();

  const genlistData = (data) => {
    const list = [];
    data.map((item) =>
      list.push({ value: item.thName, label: item.thName, data: item }),
    );
    return list;
  };

  const genlistDidtrictData = (data) => {
    const list = [];
    data.map((item) =>
      list.push({
        value: item.thName,
        label: (
          <div>
            <span style={{ marginRight: '10px' }}>{item.thName}</span>
            <span
              style={{
                color: '#c5c9d3',
                textAlign: 'end',
                fontSize: '0.5em',
              }}
            >
              {item.province.thName}
            </span>
          </div>
        ),
        data: item,
      }),
    );
    return list;
  };

  const genlistSubdistrictData = (data) => {
    const list = [];
    data.map((item) =>
      list.push({
        value: item.thName,
        label: (
          <div>
            <span style={{ marginRight: '10px' }}>{item.thName}</span>
            <span
              style={{
                color: '#c5c9d3',
                textAlign: 'end',
                fontSize: '0.5em',
              }}
            >
              {item.district.thName},{item.province.thName}
            </span>
          </div>
        ),
        data: item,
      }),
    );
    return list;
  };

  useEffect(() => {
    fetchPreData();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (subDistrict) {
      const districtData = subDistrict.district;
      const provinceData = subDistrict.province;
      if (districtData && provinceData) {
        fillDataAll(districtData, provinceData, subDistrict.zipcode);
      }
    }
  }, [subDistrict]);

  useEffect(() => {
    if (district) {
      const provinceData = district.province;
      fillProvince(provinceData);
    }
  }, [district]);

  useEffect(() => {
    if (
      province &&
      district &&
      subDistrict &&
      zipcode &&
      addr?.length > 0
    ) {
      setIsNotAllField(false);
    } else {
      setIsNotAllField(true);
    }
  }, [province, district, subDistrict, addr, zipcode, props.updateFieldStatus(isNotAllField)]);

  const onSubmit = async () => {
    try {
      const addrArr = addr.split(' ');
      const data = {
        line1: addrArr.slice(0, 4).join(' '),
        line2: addrArr.slice(4, 8).join(' '),
        line3: addrArr.slice(8, 12).join(' '),
        line4: addrArr.slice(12, addrArr.length).join(' '),
        province: province.thName,
        district: district.thName,
        subDistrict: subDistrict.thName,
        zipcode,
        fullAddress: `${addr} ${subDistrict.thName} ${district.thName} ${province.thName} ${zipcode}`,
        refProvince: province.refCode,
      };
      props && props.setUserAddress(data, addressList)
    } catch (error) {
      alert(message.error.somethingWrong);
      console.log(error);
    }
  };

  const onSubmitAutoFill = async (streetPlace, subDistrict, district, province, zipcode) => {
    try {
      const addrArr = streetPlace.split(' ');
      const data = {
        line1: addrArr.slice(0, 4).join(' '),
        line2: addrArr.slice(4, 8).join(' '),
        line3: addrArr.slice(8, 12).join(' '),
        line4: addrArr.slice(12, addrArr.length).join(' '),
        province: province.thName,
        district: district.thName,
        subDistrict: subDistrict.thName,
        zipcode,
        fullAddress: `${addr} ${subDistrict.thName} ${district.thName} ${province.thName} ${zipcode}`,
        refProvince: province.refCode
      };
      props && props.setUserAddress(data, addressList)
    } catch (error) {
      alert(message.error.somethingWrong);
      console.log(error);
    }
  };

  const fetchPreData = async () => {
    console.log('here')
    const province = await fetchProvice('');
    const district = await fetchDistrict('');
    const subdistrict = await fetchSubdistrict('');
    const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
    if (customerFOA) {
      setCustomerData(customerFOA);
    } else {
      await fetchAddress();
    }
    if (province && district && subdistrict) {
      setLoading(false);
    }
  };

  const fetchProvice = async (keyword) => {
    const newProvinceData = await getProvince(keyword);
    const newProvinces = newProvinceData && genlistData(newProvinceData.data);
    setProvicesList(newProvinces);
    return newProvinces;
  };

  const fetchDistrict = async (keyword) => {
    const newDistrictData = await getDistrict(keyword);
    const newDistrict =
      newDistrictData && genlistDidtrictData(newDistrictData.data);
    setDistrictList(newDistrict);
    return newDistrict;
  };

  const fetchSubdistrict = async (keyword) => {
    const newSubdistrictData = await getSubdistrict(keyword);
    const newSubdistrict =
      newSubdistrictData && genlistSubdistrictData(newSubdistrictData.data);
    setSubdistrictList(newSubdistrict);
    return newSubdistrict;
  };

  const fetchAddress = async () => {
    const lineProfile = await liffGetProfile();
    const { data } = await getAddress(lineProfile.userId);
    setAddressList(data);
    return data;
  };

  const fillDataAll = async (district, province, zipcode) => {
    const districtNewList = await fetchDistrict(district.thName);
    if (districtNewList) setDistrict(district);
    const provinceNewList = await fetchProvice(province.thName);
    if (provinceNewList) setProvice(province);
    setZipcode(zipcode);
    setLoading(false);
  };

  const fillProvince = async (province) => {
    const provinceNewList = await fetchProvice(province.thName);
    if (provinceNewList) setProvice(province);
  };

  const getValueByType = (addressComponent, type) => {
    console.log(addressComponent, 'type',type)
    return addressComponent.find((obj) => obj.types.indexOf(type) !== -1);
  };

  const getStreetByMap = (addressComponent) => {
    const street = getValueByType(addressComponent, 'street_number');
    const route = getValueByType(addressComponent, 'route');
    return `${street?.long_name || ''} ${route?.long_name && route.long_name !== 'Unnamed Road'
      ? route.long_name
      : ''
      }`.trim();
  };

  const getSubDistrictByMap = (addressComponent) => {
    const sub = addressComponent.find(
      (obj) => obj.types.indexOf('sublocality_level_2') !== -1,
    );
    const sublocal = addressComponent.find(
      (obj) => obj.types.indexOf('sublocality_level_1') !== -1,
    );
    const locality = addressComponent.find(
      (obj) => obj.types.indexOf('locality') !== -1,
    );
    const realSub = sub ? sub : sublocal ? sublocal : locality;
    return realSub?.long_name.replace(/แขวง|ตำบล/gi, '').trim() || '';
  };

  const handlePlaceSelect = async (place) => {
    const { address_components } = place;
    // TODO: for dev only
    // const address_components = [
    // {'long_name': '2', 'short_name': '2', 'types':['street_number']},
    // {'long_name': 'ถนน มิตรไมตรี', 'short_name': 'ถนน มิตรไมตรี', 'types':['route']},
    // {'long_name': 'แขวงดินแดง', short_name: 'แขวงดินแดง', 'types': ['political', 'sublocality', 'sublocality_level_2']},
    // {'long_name': 'เขตดินแดง', short_name: 'เขตดินแดง', 'types': ['political', 'sublocality', 'sublocality_level_1']},
    // {'long_name': 'กรุงเทพมหานคร', short_name: 'กรุงเทพมหานคร', 'types': ['administrative_area_level_1', 'political']},
    // {'long_name': 'ประเทศไทย', short_name: 'TH', 'types': ['country', 'political']},
    // {'long_name': '10400', short_name: '10400', 'types': ['postal_code']}
    // ];
    setOpenMap(false);
    props.hasMapOpen(false);
    setLoading(true);
    if (!place) return;
    const streetPlace = getStreetByMap(address_components);
    const subdistrictPlace = getSubDistrictByMap(address_components);
    const zipcode = getValueByType(address_components, 'postal_code');
    const subList = await fetchSubdistrict(subdistrictPlace);
    const foundSub =
      subList.length > 0 &&
      subList.find((item) => {
        if (
          item.value === subdistrictPlace &&
          item.data.zipcode === zipcode.long_name
        ) {
          return item;
        }
      });
    if (foundSub) {
      setAddr(streetPlace);
      setSubDistrict(foundSub.data);
      fillDataAll(
        foundSub.data.district,
        foundSub.data.province,
        foundSub.data.zipcode,
      );
      setLoading(false);
      onSubmitAutoFill(streetPlace, foundSub.data, foundSub.data.district, foundSub.data.province, foundSub.data.zipcode);
    } else {
      setLoading(false);
    }

  };

  if (openMap) {
    return <PlacePicker onPlaceSelect={handlePlaceSelect} />;
  }

  const getCurrentLocale = () => {
    Geocode.setLanguage(defaultLang);
    Geocode.setRegion(defaultRegion);

    navigator.geolocation.getCurrentPosition(
      function (position) {
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          (response) => {
            const address = response.results[0];
            handlePlaceSelect(address);
          },
          () => {
            setShowModal('Something went wrong please try again later!');
          },
        );
      },
      function onError(error) {
        switch (error.code) {
          case 1:
            setShowModal(
              'กรุณาอนุญาตการเข้าถึงสถานที่ตั้ง แล้วลองใหม่อีกครั้ง',
            );
            break;
          case 2:
            setShowModal(
              'ไม่สามารถเข้าถึงสถานที่ตั้งของคุณได้ กรุณาลองใหม่อีกครั้ง',
            );
            break;
          case 3:
            setShowModal(
              'ไม่สามารถเข้าถึงสถานที่ตั้งของคุณได้ กรุณาลองใหม่อีกครั้ง',
            );
            break;

          default:
            setShowModal(
              `code: ${error.code}\ren` + `message: ${error.message}\n`,
            );
            break;
        }
      },
      { timeout: 7000 },
    );
  };

  // const onChangeCenter = () => {
  //   const lat = mapRef.center.lat();
  //   const lng = mapRef.center.lng();
  //   if (!searchString) {
  //     inputRef.current.value = '';
  //   } else {
  //     inputRef.current.value = searchString;
  //     setSearchString(null);
  //   }
  //   Geocode.fromLatLng(lat, lng).then(
  //     (response) => {
  //       const address = response.results[0];
  //       setSeletedAddress(address);
  //       setShowAddress(true);
  //     },
  //     () => {
  //       setShowModal('Something went wrong please try again later!');
  //     },
  //   );
  // };

  return (
    <section>

      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      <div className="foa-create-address-container">
        <div className="title">กรุณาตรวจสอบบัญชีของคุณ</div>
        <div className="body">
          <div className="address-search-container">
            <div className="address-search-title">
              <img src="/images/promotion/icon_search.png" />
              <div className="address-search-title-text">ค้นหาที่อยู่</div>
            </div>
            <div className="location-container">
              <div className="search-bar-container">
                <div className="search-bar">
                  <input className="search-input-box" placeholder="ค้นหาจากแผนที่" disabled></input>
                </div>
              </div>
              <div className="map-button-align">
                <span className="map-button" onClick={() => {setOpenMap(true); props && props.hasMapOpen(true);}}>
                  <div className="map-button-text">แผนที่</div>
                </span>
              </div>
            </div>
            <div className="autofill-button-container">
              <img
                style={{ width: 20, paddingBottom: 5 }}
                src="/images/promotion/icon-my-location.png"
              />
                <span className="autofill-button-text" onClick={() => {getCurrentLocale()}}>ใช้ตำแหน่งปัจจุบัน</span>
            </div>
          </div>
          <div className="address-fields-container">
            <div className="address-fields-title">
              <img src="/images/promotion/icon_address_fields.png" />
              <div className="address-fields-title-text">ที่อยู่ในการจัดส่ง</div>
            </div>
            <div>

              <label className="label-address">ที่อยู่</label>
              <div className="input-bars">
                <input
                  className="input-address"
                  value={addr}
                  placeholder={'บ้านเลขที่'}
                  maxLength={50}
                  onChange={(e) => {
                    const regEx = /[^\u0E00-\u0E3E\u0E40-\u0E7Fa-zA-Z\d\s\(\)\/\-\*\.,]/g;
                    !regEx.test(e.target.value) && setAddr(e.target.value); onSubmit();
                  }}
                />
              </div>
              <label className="label-address">แขวง</label>
              <SelectorAddress
                option={subdistrictList}
                onChange={setSubDistrict}
                fetch={fetchSubdistrict}
                stateValue={subDistrict}
                placeholder={'เลือกแขวง'}
              />
              <label className="label-address">เขต</label>
              <SelectorAddress
                option={districtList}
                onChange={setDistrict}
                fetch={fetchDistrict}
                stateValue={district}
                placeholder={'เลือกเขต'}
              />
              <label className="label-address">จังหวัด</label>
              <SelectorAddress
                option={provinceList}
                onChange={setProvice}
                fetch={fetchProvice}
                stateValue={province}
                placeholder={'เลือกจังหวัด'}
              />
              <label className="label-address">รหัสไปรษณีย์</label>
              <div className="input-bars">
                <input
                  className="input-address"
                  type="number"
                  value={zipcode}
                  placeholder={'กรอกรหัสไปรษณีย์'}
                onChange={(e) => {setZipcode(e.target.value); onSubmit();}}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};
