import React from 'react';
import LinearRowContent from './LinearRowContent';
import { round } from '../../../../../services';

export default function HnwActivityGoalCardBody({ type, content, healthGoal }) {
  return (
    <div className="card-body-section-activity">
      {content?.data.map((a, i) => (
        <LinearRowContent
          key={`HnwActivityGoalCardBody_${i}`
          }
          type={type}
          content={{ dataName: a.label, dataUnit: a.unit }}
          healthGoalValue={round(healthGoal[a.value], 0)}
        />
      ))}
    </div>
  );
}
