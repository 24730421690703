import { EditOutlined, LeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, message } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { commonConstant } from '../../../common';
import {
  getAboContentsByCategoryId,
  getAmwayContentBySubCat,
} from '../../../services/library/contents/contents.service';
import { countAmwayLibraryBook, libraryImageUrl } from '../../../utill.func';
import { EContentType } from '../common.library';
import './content.scss';

const liff = window.liff;

const LibraryContent = () => {
  const history = useHistory();
  const location = useLocation();
  const [contents, setContents] = useState();
  const [categoryData, setCategoryData] = useState();
  const [contentType, setContentType] = useState(EContentType.AMWAY_LIBRARY);
  const [loading, setLoading] = useState(false);

  const onSearch = async (values) => {
    if (contentType === EContentType.AMWAY_LIBRARY) {
      const id = location.search.substring(4);
      setLoading(true);
      await fetchAmwayContent(values.search, id);
      setLoading(false);
    } else {
      const id = location.search.substring(4);
      setLoading(true);
      await fetchAboContent(values.search, id);
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search);
    const id = queryParam.get('id');
    const isAmwayContent = location.state.isAmwayContent;
    setContentType(isAmwayContent);
    if (isAmwayContent === EContentType.AMWAY_LIBRARY) {
      fetchAmwayContent(null, id);
    } else {
      fetchAboContent(null, id);
    }
  }, []);

  const fetchAmwayContent = async (query, id) => {
    setLoading(true);
    const amwayContent = await getAmwayContentBySubCat(query, id);
    if (amwayContent === '') {
      message.error('Category not found');
      setTimeout(() => liff.closeWindow(), 2000);
    }
    setCategoryData(amwayContent);
    setContents(amwayContent.contentList);
    setLoading(false);
  };

  const fetchAboContent = async (query, id) => {
    setLoading(true);
    const aboContent = await getAboContentsByCategoryId(query, id);
    if (aboContent === '') {
      message.error('Category not found');
      setTimeout(() => liff.closeWindow(), 2000);
    }
    setCategoryData(aboContent);
    setContents(aboContent.contentList);
    setLoading(false);
  };

  const viewContentDetail = (id) => {
    const path = commonConstant.pathLibraryViewContent;
    let queryString;
    if (contentType === EContentType.AMWAY_LIBRARY) {
      queryString = `Id=${id}`;
    } else {
      queryString = `aboId=${id}`;
    }

    history.push({
      pathname: path,
      search: queryString,
    });
  };

  const addContent = (categoryData) => {
    history.push({
      pathname: '/library/content/form',
      state: { category: categoryData._id },
    });
  };
  return (
    <Spin tip="Loading content..." spinning={loading}>
      {categoryData && (
        <div className="content-list-wrap sub-content">
          <div className="hl-header">
            <div className="sub-header">
              <div className="header-bg">
                <img src={libraryImageUrl(categoryData.imageFlex)} />
              </div>
              <div
                className="back-page"
                onClick={() => {
                  history.go(-1);
                }}
              >
                <LeftOutlined />
              </div>
              {contentType != EContentType.AMWAY_LIBRARY ? (
                <div
                  className="edit-category"
                  onClick={() => {
                    history.push({
                      pathname: '/library/category/form',
                      search: `id=${categoryData._id}`,
                    });
                  }}
                >
                  <EditOutlined />
                </div>
              ) : (
                <></>
              )}
              <div className="page-title-category">Contents</div>
              <div className="category-name">
                <h1 className="heading">{categoryData.name}</h1>
                <div className="desc">
                  {`${countAmwayLibraryBook(categoryData.contentList)} Books`}
                </div>
              </div>
            </div>
          </div>

          <div className="content-list card-discovery-new ">
            <div className="search-input">
              <Form
                name="basic"
                layout="vertical"
                initialValues={{ status: false }}
                onFinish={onSearch}
                autoComplete="off"
              >
                <Form.Item name="search">
                  <Input placeholder="ค้นหา" prefix={<SearchOutlined />} />
                </Form.Item>
              </Form>
            </div>
            {contentType != EContentType.AMWAY_LIBRARY ? (
              <div className="add-new-content">
                <Button
                  disabled={
                    categoryData?.contentList.length >= 20 ? true : false
                  }
                  className="btn-outline"
                  onClick={() => {
                    addContent(categoryData);
                  }}
                >
                  + Add New Content
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div className="card-body">
              {contents && contents.length != 0 ? (
                contents.map((list, index) => (
                  <div
                    className="card-item"
                    key={list._id + index}
                    onClick={() => {
                      viewContentDetail(list._id);
                    }}
                  >
                    <div className="card-item-img">
                      <img src={libraryImageUrl(list.imageContent)} />
                    </div>
                    <div className="card-desc">
                      <div className="title">{list.name}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-data">
                  <img src="../../../images/library/icon_noContent.svg" />
                  <p className="desc">ยังไม่มีข้อมูล Content ใน Category นี้</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Spin>
  );
};
export default LibraryContent;
