import React from 'react'
import './description.scss'

const Description = () => {
    return (
        <div className="bmi-details-description">
            <div className="row">
                <div className="col-12">
                    <h5 className="bmi-details-description-title">เกี่ยวกับ ดัชนีมวลกาย (BMI)</h5>
                    <p>
                        ดัชนีมวลกาย หรือ BMI ย่อมาจาก Body Mass Index
                        เป็นค่าสากลที่ใช้เพื่อคำนวณเพื่อหาน้ำหนักตัวที่ควรจะเป็น
                        และประมาณระดับไขมันในร่างกายโดยใช้น้ำหนักตัว และส่วนสูง<br />
                        การคำนวณดัชนีมวลกายไม่ใช่การวัดโดยตรงแต่ก็เป็นตัวชี้วัดไขมันในร่างกายที่ค่อนข้างเชื่อถือได้สำหรับคนส่วนใหญ่<br />
                        ค่า BMI สามารถใช้บ่งบอกความเสี่ยงในการเกิดโรคต่างๆได้อีก ด้วย เช่น โรคเบาหวาน
                        ความดันโลหิตสูง ไขมันในเลือด ระบบหัวใจ รวมไปถึงมะเร็งบางชนิด<br />
                        แต่อย่างไรก็ตามค่า BMI เป็นแค่การคำนวณเบื้องต้นเท่านั้น
                        เนื่องจากคุณจำเป็นต้องนำปัจจัยอื่นๆ มาประกอบด้วย ทั้งเรื่องของพันธุกรรม ปริมาณกล้ามเนื้อ
                        พฤติกรรมการกิน การใช้ชีวิต การออกกำลังกาย และอื่นๆ
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Description