import { EditFilled, UploadOutlined } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Form, Image, Input, Modal, Spin, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import config from '../../../config';
import { getAboCategories } from '../../../services/library/categories/categories.service';
import {
  createAboContent,
  getAboPublicContent,
  updateAboContent,
  uploadImage,
} from '../../../services/library/contents/contents.service';
import {
  EContentType,
  EFormAction,
  EImageType,
  EModalTitle,
  EStatus,
} from '../common.library';
import ImgCrop from 'antd-img-crop';
import ModalContentSuccess from '../modal/modalContentSuccess';

const LibraryContentForm = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [aboContent, setAboContent] = useState(null);
  const [contentCoverImage, setContentCoverImage] = useState([] || null);
  const [imageUrl, setImageUrl] = useState('' || null);
  const [content, setContent] = useState('' || null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paramId, setParamId] = useState('');
  const [isValidateImage, setIsValidateImage] = useState(true);
  const [limitContent, setLimitContent] = useState(false);
  const [isImageChange, setIsImageChange] = useState(false);

  const isAmway = location?.state?.isAmwayContent ?? false;
  const setCategoryId = location?.state?.category;

  useEffect(() => {
    if (setCategoryId) {
      getAboCategoryList(setCategoryId);
    }
  }, [setCategoryId]);

  useEffect(() => {
    const contentId = new URLSearchParams(location.search).get('id');
    if (contentId) {
      getAboContentDetail(contentId);
      setParamId(contentId);
    }
  }, []);

  useEffect(() => {
    if (contentCoverImage.length > 0) {
      getPreviewImage();
    }
  }, [contentCoverImage]);

  const getAboCategoryList = async (id) => {
    setIsLoading(true);
    const aboCategories = await getAboCategories();
    aboCategories.find((element) => {
      if (element._id === id) {
        form.setFields([{ name: 'category', value: element.name }]);
      }
    });
    setIsLoading(false);
  };

  const getAboContentDetail = async (contentId) => {
    setIsLoading(true);
    const aboContent = await getAboPublicContent(contentId);
    setAboContent(aboContent);
    setImageUrl(`${config.S3_URL + aboContent.imageContent}`);
    setDataToForm(aboContent);
    setIsLoading(false);
  };

  const onChangeUploadContentImage = ({ file, fileList }) => {
    setContentCoverImage(fileList);
    setIsImageChange(true);
    setIsValidateImage(false);
  };

  const dummyUploadRequest = async (options) => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const getPreviewImage = async () => {
    const previewimg = await getBase64(contentCoverImage[0].originFileObj);
    setImageUrl(previewimg);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onFinish = async (value) => {
    if (limitContent) {
      message.error('ช่องเนื้อหาไม่ควรใส่ตัวอักษรเกิน 300 ตัวอักษร');
    } else {
      const action = !paramId ? EFormAction.CREATE : EFormAction.UPDATE;
      setIsLoading(true);
      const apiBody = setFormDataToApiBody(value);
      if (!apiBody.content) {
        message.error('Content is empty');
        setIsLoading(false);
      } else {
        try {
          const body = {
            ...apiBody,
          };

          if (isImageChange) {
            if (contentCoverImage[0]?.originFileObj) {
              const imageUrl = await uploadImage({
                image: contentCoverImage[0].originFileObj,
                name: 'content',
              });
              body.imageContent = imageUrl.imageLink;
            }
          }

          if (action === EFormAction.CREATE) {
            await createAboContent(body);
          }

          if (action === EFormAction.UPDATE) {
            await updateAboContent(paramId, body);
          }
          setVisibleModal(true);
        } catch (error) {
          let title = 'มีบางอย่างผิดพลาด';
          if (error?.message) {
            title = error.message;
          }
          if (error?.status === 400) {
            title = error.data.message;
          }
          if (error.data.message[0].property === 'content') {
            title = 'กรุณาใส่เนื้อหา';
          }
          Modal.error({
            title,
          });
        }
        setIsLoading(false);
      }
    }
  };

  const setDataToForm = (aboCategory) => {
    form.setFields([
      { name: 'name', value: aboCategory.name ?? '' },
      { name: 'content', value: aboCategory.content ?? '' },
      { name: 'status', value: aboCategory.status },
      { name: 'category', value: aboCategory.category.name },
    ]);
    if (aboCategory?.imageContent || contentCoverImage != null) {
      setIsValidateImage(false);
    }
  };

  const setFormDataToApiBody = (value) => {
    const apiBody = {
      name: value.name,
      content,
      status: EStatus.PUBLISH,
      aboCategoryId: setCategoryId,
    };
    return apiBody;
  };

  const onValueChange = (value, rawString) => {
    if (rawString.length > 300) {
      setLimitContent(true);
    } else {
      setLimitContent(false);
    }
    setContent(value);
  };

  const onValidateType = (_, value) => {
    if (!value && isValidateImage) {
      return Promise.reject(new Error('กรุณาอัพโหลดรูป'));
    }
    if (value) {
      const isImageTpye =
        value.fileList[0].type === EImageType.JPG ||
        value.fileList[0].type === EImageType.PNG ||
        value.fileList[0].type === EImageType.JPEG;
      if (!isImageTpye) {
        return Promise.reject(new Error('รับเฉพาะไฟล์ประเภท: JPG, PNG'));
      }
    }
    return Promise.resolve();
  };

  return (
    <Spin spinning={isLoading}>
      <div className="content-form-wrap">
        <Form form={form} onFinish={onFinish} initialValues={aboContent}>
          <div className={`upload-content-img ${imageUrl ? 'active' : ''}`}>
            <Image
              className={imageUrl ? 'active' : ''}
              width={'100%'}
              src={imageUrl}
              preview={false}
            />
            <Form.Item
              name="images"
              rules={[
                {
                  required: isValidateImage,
                  validator: onValidateType,
                },
              ]}
            >
              <ImgCrop>
                <Upload.Dragger
                  name="files"
                  accept="image/*"
                  disabled={isAmway}
                  multiple={false}
                  className={['upload-file-area', imageUrl ? 'active' : '']}
                  maxCount={1}
                  fileList={contentCoverImage}
                  onChange={onChangeUploadContentImage}
                  showUploadList={{ showRemoveIcon: false }}
                  customRequest={dummyUploadRequest}
                >
                  {imageUrl ? (
                    <div>
                      <div className="ant-upload-hint">
                        <p>
                          <EditFilled disabled={isAmway} /> แก้ไขรูปภาพ
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined disabled={isAmway} />
                      </p>
                      <div className="ant-upload-hint">
                        <p>อัพโหลดรูปภาพปก Content</p>
                        <p>(ขนาดไฟล์รูปภาพไม่เกิน 2 MB.)</p>
                      </div>
                    </div>
                  )}
                </Upload.Dragger>
              </ImgCrop>
            </Form.Item>
          </div>
          <div className="edit-content-detail-wrap">
            <Form.Item
              name="name"
              label="หัวข้อ"
              rules={[
                {
                  required: true,
                  message: 'กรุณาใส่ชื่อเรื่อง',
                },
              ]}
            >
              <Input placholder="Title" disabled={isAmway} maxLength={50} />
            </Form.Item>
            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  message: 'กรุณาเลือกหมวดหมู่',
                },
              ]}
            >
              <Input placholder="cateogry" disabled={true} />
            </Form.Item>

            <label>รายละเอียด</label>
            <div className="ck-wrap">
              <CKEditor
                editor={ClassicEditor}
                data={aboContent?.content}
                config={{
                  toolbar: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    '|',
                    'bulletedList',
                    '|',
                    'blockQuote',
                    'undo',
                    'redo',
                  ],
                  mediaEmbed: {
                    previewsInData: true,
                  },
                }}
                onChange={(data, editor) => {
                  const body = editor.getData();
                  var regex = /(<([^>]+)>)/gi,
                    rawString = body.replace(regex, '').replace(/\&nbsp;/g, '');
                  onValueChange(editor.getData(), rawString);
                }}
              />
            </div>
          </div>

          <div className="action">
            <Form.Item>
              <Button className="btn" htmlType="submit" disabled={isAmway}>
                บันทึก
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="link"
                htmlType="button"
                onClick={() => {
                  history.go(-1);
                }}
              >
                ยกเลิก
              </Button>
            </Form.Item>
          </div>
        </Form>
        <ModalContentSuccess
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          setCategoryId={setCategoryId}
          aboContent={aboContent}
          contentType={
            isAmway ? EContentType.AMWAY_LIBRARY : EContentType.MY_LIBRARY
          }
          title={
            !paramId ? EModalTitle.CREATE_SUCCESS : EModalTitle.UPDATE_SUCCESS
          }
        />
      </div>
    </Spin>
  );
};
export default LibraryContentForm;
