import React from 'react';
import "./ChallengeVideoHeader.scss";
import { CHALLENGE } from '../../../../common/challengeConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { commonConstant } from '../../../../common';

const ChallengeVideoHeader = ({ handleSeeAll }) => {

  return (
    <>
      <div className="quiz-video-title-wrapper">
        <div className="quiz-video-title">{CHALLENGE.challengeQuiz.quizVideoText}</div>
        <button onClick={() => handleSeeAll()} className="btn-light-green btn-sm btn-rounded">
          {CHALLENGE.challengeQuiz.seeAllText}
        </button>
      </div>
    </>
  );
};

export default ChallengeVideoHeader;
