import { SELECT_SOP, ADD_CART, REMOVE_CART } from './types';

export function selectSOP(data) {
  return { type: SELECT_SOP, data };
}

export function addToBasket(data) {
  return { type: ADD_CART, data };
}

export function removeFromBasket(id) {
  return { type: REMOVE_CART, id };
}
