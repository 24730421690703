import { fetchCustomerBmi, updateCustomerBmi, fetchCustomerMonthlyBmi } from '../services/healthWellness/healthWellness.service';

import { GET_CUSTOMER_MONTHLY_BMI, GET_CUSTOMER_BMI, GET_LAST_MONTH_BMI, GET_MONTHLY_BMI_LIST, GET_CUSTOMER_BMI_FAILURE, GET_CUSTOMER_BMI_SUCCESS,UPDATE_BMI } from './types';
export const fetchBmiData = () => (dispatch) => {
  dispatch({ type: GET_CUSTOMER_BMI });

  return fetchCustomerBmi()
    .then(({ customers, ...rest }) => {
      dispatch({
        type: GET_CUSTOMER_BMI_SUCCESS,
        payload: rest,
      });
      return rest;
    })
    .catch((error) => {
      dispatch({
        type: GET_CUSTOMER_BMI_FAILURE,
        payload: error.message,
      });
      alert(error.message);
    });
};

export const updateBmiData = (healthInfo) => (dispatch) => updateCustomerBmi(healthInfo)
  .then(({ customers, ...rest }) => {
    dispatch({
      type: UPDATE_BMI,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const fetchMonthlyBmiData = (month, year, isPrevious) => dispatch => fetchCustomerMonthlyBmi(month, year)
  .then(res => {
    dispatch({
      type: isPrevious ? GET_LAST_MONTH_BMI : GET_CUSTOMER_MONTHLY_BMI,
      payload: res,
    });
  })
  .catch(function (error) {
    alert(error.message);
  });

export const fetchListMonthlyBmiData = (monthsList) => dispatch =>
  Promise.all(monthsList.map(({ month, year }) => fetchCustomerMonthlyBmi(month, year)))
    .then(res => {
      dispatch({
        type: GET_MONTHLY_BMI_LIST,
        payload: res,
      })
    })
    .catch(function (error) {
      alert(error.message);
    });


