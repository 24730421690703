import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
import './TeamDashboardSwiperDetail.scss';
import { ALL_STEPS_BADGES, CHALLENGE, WEIGHT_FAT_MUSCLE_MAPPING } from '../challengeConstants'
import { formatNumberWithCommas, removeMinusFromString } from '../../utill.func';
import RectBadge from '../rectBadge/RectBadge';
import DownArrowSvg from '../upAndDownArrowsSvgs/DownArrowSvg';
import UpArrowSvg from '../upAndDownArrowsSvgs/UpArrowSvg';
import variables from '../commonConstant.scss';
SwiperCore.use([Navigation, EffectCoverflow]);


const TeamDashboardSwiperDetail = ({
    swiperData,
    bodyDataSlider,
    weighOutData
}) => {

    const bodyDataClass = bodyDataSlider ? 'bodyDataClass' : '';
    const bodySwiperRef = useRef(null);

    useEffect(() => {
        bindNextSlideClick();
    }, []);

    const goToNextSlide = () => {
        if (bodySwiperRef.current) {
            bodySwiperRef.current.swiper.slideNext();
        }
        let swiperClass = bodyDataSlider ? '.body-swiper' : '.mission-swiper';    
        const swiperParent = document.querySelector(swiperClass).parentElement;
        if (!swiperParent.classList.contains('swiper-transition')) {
            swiperParent.classList.add('swiper-transition');
        }
    };

    const bindNextSlideClick = () => {
        let selectedClass = bodyDataSlider ? '.body-slider' : '.mission-slider';
        let swiperClass = bodyDataSlider ? '.body-swiper' : '.mission-swiper';    
        document.querySelectorAll(selectedClass).forEach(slide => {
            slide.removeEventListener('click', goToNextSlide);
        });

        document.querySelectorAll(selectedClass).forEach(slide => {
            slide.addEventListener('click', goToNextSlide);
        });

        const nextButton = document.querySelector(`${swiperClass} .swiper-button-next`);
        if (nextButton) {
            nextButton.addEventListener('click', () => {
                const swiperParent = document.querySelector(swiperClass).parentElement;
                if (!swiperParent.classList.contains('swiper-transition')) {
                    swiperParent.classList.add('swiper-transition');
                }
            });
        }
    };

    const selectBadgeClassName = (data, key) => {
        return key == 'muscle' ?
        data?.diffValue >= 0 ?
            WEIGHT_FAT_MUSCLE_MAPPING.muscleInc : WEIGHT_FAT_MUSCLE_MAPPING.muscleDec :
        data?.diffValue >= 0 ?
            WEIGHT_FAT_MUSCLE_MAPPING.increase : WEIGHT_FAT_MUSCLE_MAPPING.decrease
    }

    const renderUpAndDownArrows = (diffValue, key) => {
        // const removeTeamStatus = teamDashboardStatus == ChallengeTeamdashboardStatus.remove_team;
        let arrowIcon = ''
        const inactiveDownArrow = <DownArrowSvg arrowColor={variables.inactiveBadgeContent} />;
        const inactiveUpArrow = <UpArrowSvg arrowColor={variables.inactiveBadgeContent} />;
        if (key === 'muscle') {
            if (diffValue <= 0) {
                arrowIcon = <DownArrowSvg arrowColor={variables.darkRed} />;
            } else {
                arrowIcon = <UpArrowSvg arrowColor={variables.arrowUp} />;
            }
        } else {
            if (diffValue <= 0) {
                arrowIcon = <DownArrowSvg arrowColor={variables.arrowUp} />;
            } else {
                arrowIcon = <UpArrowSvg arrowColor={variables.darkRed} />;
            }
        }
        return arrowIcon;
    }


    const _renderStepsBadges = (data) => {
        let badgesToRender = [];
        for (const badge of ALL_STEPS_BADGES.slice(0, data?.badges_count)) {
            badgesToRender.push(
                <img src={`/images/challenge/badges/steps-${badge}-badge@5x.png`} alt={`steps-${badge}`} />
            );
        }
        return (
            <span class="badge-total">
                {badgesToRender}
            </span>
        )
    }

    const _renderOtherBadges = (key) => {
        return (
            <span class="badge-total">
                <img src={`/images/challenge/badges/${key}-badge@5x.png`} alt={`${key}`} />
            </span>
        )
    }

    const _swiperValueContainer = (data, key) => {
        const showValue = (key === 'food' || key === 'calorie' || key === 'vdo') ? true : false;
        const value = (key === 'quiz') ? data?.score : data?.value;
        const dataValue = (value === 0) ? 'black-text' : '';
        const showDiffValue = (key === 'weight' || key === 'fat' || key === 'muscle') ? true : false;
        return (
            <div class="dashboard-slider-steps">
                <div class={`dashboard-slider-steps-total ${bodyDataClass} ${dataValue}`}>
                    {(value == 0 && showValue) ? '-' : formatNumberWithCommas(value)}
                    {showValue && <span class='dashboard-slider-steps-total-outof'>/60</span>}
                </div>
                <span class="badge-total">
                    {data?.badges_count > 0 ? (key == 'steps') ? _renderStepsBadges(data) : _renderOtherBadges(key) : null}
                </span>
                {/* TODO: Add key for weight out */}
                {showDiffValue && weighOutData &&
                    <span className="diff-value-container">
                        <RectBadge
                            badgeClassname={selectBadgeClassName(data, key)}
                        >
                            <div className="rect-badge-description">
                                {renderUpAndDownArrows(data?.diffValue, key)}
                                <div className='rect-badge-title'>{removeMinusFromString((data?.diffValue))}</div>
                                <div className='rect-badge-value'>{CHALLENGE.teamDashboard.kilogram}</div>
                            </div>
                        </RectBadge>
                    </span>}
            </div>
        )
    }

    const _swiperValueHeader = (title, unit) => {
        return (
            <div className="dashboard-slider-steps-title">
                <span className='dashboard-slider-steps-title-header'>{title}</span>
                <span className='dashboard-slider-steps-title-unit'>{unit}</span>
            </div>
        )
    }

    const _swiperSlideContainer = (data) => {
        return (
            <SwiperSlide className={`dashboard-slider-content swiper-slide ${bodyDataSlider ? 'body-slider' : 'mission-slider'}`}>
                {_swiperValueHeader(swiperData[data]?.title, swiperData[data]?.unit)}
                <div class="dashboard-slider-steps-wrapper">
                    {
                        swiperData[data]?.userData.map(currentSwiper => {
                            return _swiperValueContainer(currentSwiper?.data, data)
                        })
                    }
                </div>
            </SwiperSlide>
        )
    }

    return (
        <>
            <Swiper className={`swiper swiper-carousel dashboard-slider-parent ${bodyDataSlider ? 'body-swiper' : 'mission-swiper'}`}
                ref={bodySwiperRef}
                id="dashboard-slider-parent"
                loop={true}
                speed={1000}
                effect="coverflow"
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 80,
                    depth: 160,
                    slideShadows: false,
                }}
                breakpoints={{
                    430: { coverflowEffect: { depth: 39 } },
                    428: { coverflowEffect: { depth: 60 } },
                    414: { coverflowEffect: { depth: 90 } },
                    410: { coverflowEffect: { depth: 110 } },
                    390: { coverflowEffect: { depth: 150 } },
                    380: { coverflowEffect: { depth: 190 } },
                    375: { coverflowEffect: { depth: 200 } },
                    360: { coverflowEffect: { depth: 260 } },
                    353: { coverflowEffect: { depth: 50 } },
                }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
            >
                <div class="dashboard-slider-carousel swiper-wrapper">
                    {
                        swiperData && Object.keys(swiperData).map(data => {
                            return _swiperSlideContainer(data)
                        })
                    }
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </Swiper>
        </>

    );
};

export default TeamDashboardSwiperDetail