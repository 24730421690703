import React from 'react'

const SearchCategories = ({ selectedCategoryIndex, categories, handleCategoryClick }) => {
    return (
        <div className="category-carousel-container">
            {
                categories.map((el, index) => (
                    <div key={`category${index}`} onClick={() => handleCategoryClick(el, index)}
                        className={`category-card${selectedCategoryIndex === index ? ' active' : ''}`}>
                        <img src={el.imageUrl} />
                        <div className="sh-category-card-title">
                            {el.name}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default SearchCategories