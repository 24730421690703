import React from 'react';
import { formatNumberWithCommas } from '../../utill.func';
import { HEALTH_RECORDING_CARD_DETAILS } from '../commonConstant';
import './HealthRecordingCard.scss';

export default function HealthRecordingCard({ type, quantity, navigateTo = () => { } }) {
  const item = HEALTH_RECORDING_CARD_DETAILS[type];
  const handleClick = () => navigateTo(type);
  return (
    <div className={`health-record-wrapper --${type}`} onClick={handleClick}>
      <div className='health-record-info'>
        <div className="health-record-info-wrapper">
          <div className='health-record-info-content'>
            <span className="left">{quantity ? `x${formatNumberWithCommas(quantity)}` : "-"}</span>
            <span className="right">{item.recordUnit}</span>
          </div>
          <div className="health-record-name">{item.recordName}</div>
        </div>
      </div>
    </div>
  );
}
