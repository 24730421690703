export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';

export const FETCH_POLICIES_STATUS_SUCCESS = 'FETCH_POLICIES_STATUS_SUCCESS';
export const DELETE_POLICIES_STATUS = 'DELETE_POLICIES_STATUS';
export const UPDATE_POLICIES_STATUS = 'UPDATE_POLICIES_STATUS';
export const FETCH_POLICIES_STATUS_FAILURE = 'FETCH_POLICIES_STATUS_FAILURE';

export const FETCH_ASSESSMENT_DATA_SUCCESS = 'FETCH_ASSESSMENT_DATA_SUCCESS';
export const FETCH_ASSESSMENT_DATA_FAILURE = 'FETCH_ASSESSMENT_DATA_FAILURE';

export const GET_POLICIES_DATA = 'GET_POLICIES_DATA';
export const GET_POLICIES_ERROR = 'GET_POLICIES_ERROR';


export const fetchDataRequest = (type) => ({ type });
export const fetchDataSuccess = (type, data) => ({ type, payload: data });
export const fetchDataFailure = (type, error) => ({ type, payload: error });