import React, { useEffect, useState, useRef } from "react";
import MobilePicker from "../mobilePicker/MobilePicker";
import HnWNumpadModal from "../hnWNumpadModal/HnWNumpadModal";
import { addMonths, addYears } from "date-fns";
import { thaiDate } from "../../utill.func.js";
import { getMonthFirstDayAndLastDay, getMonthlyDateRanges, getWeeklyDateRanges, getYearFirstDayAndLastDay } from "../../components/shared/graph/graph-util.js";
import commonConstant, { HOME_CALENDAR_TABS_MAPPING, RELEASE_DATE, SAVE_BUTTON_MODAL } from "../commonConstant.js";
import "./HealthDateSelector.scss";

const HealthDateSelector = ({ onClickOK, isOpen, view = HOME_CALENDAR_TABS_MAPPING.Day.name, startDate: inputStartDate, endDate: inputEndDate }) => {
  const defaultDateStart = useRef(new Date(new Date().setHours(0, 0, 0, 0)));
  const defaultDateEnd = useRef(new Date(new Date().setHours(23, 59, 59, 999)));
  const [monthTotalDays, setMonthTotalDays] = useState(new Date(defaultDateStart.current.getFullYear(), defaultDateStart.current.getMonth() + 1, 0).getDate());

  const [value, setValue] = useState({ startDate: inputStartDate ?? defaultDateStart.current, endDate: inputEndDate ?? defaultDateEnd.current });
  const [dateDisabled, setDateDisabled] = useState(false);

  const [modalStartEndDate, setModalStartEndDate] = useState({ startDate: defaultDateStart.current, endDate: defaultDateEnd.current });

  useEffect(() => {
    setValue({ startDate: inputStartDate ?? defaultDateStart.current, endDate: inputEndDate ?? defaultDateEnd.current });
    let modalStartDate, modalEndDate;
    if (view === HOME_CALENDAR_TABS_MAPPING.Week.name) {
      const { firstDayOfMonth, lastDayOfMonth } = getMonthFirstDayAndLastDay(defaultDateStart.current.getMonth(), defaultDateStart.current.getFullYear())
      modalStartDate = firstDayOfMonth;
      modalEndDate = lastDayOfMonth;
    }
    if (view === HOME_CALENDAR_TABS_MAPPING.Month.name) {
      const { firstDayOfYear, lastDayOfYear } = getYearFirstDayAndLastDay(defaultDateStart.current.getFullYear());
      modalStartDate = firstDayOfYear;
      modalEndDate = lastDayOfYear;
    }
    if (view === HOME_CALENDAR_TABS_MAPPING.Day.name) {
      return;
    }
    setModalStartEndDate({ startDate: modalStartDate, endDate: modalEndDate });
  }, [inputStartDate, inputEndDate]);

  const handleOKClick = () => {
    onClickOK(value);
  }

  const isValidDate = (date) => !isNaN(date.getTime()) && RELEASE_DATE <= date && date <= defaultDateEnd.current;

  const handleDateClick = (item) => {
    const englishYear = item.year - 543;
    const EnglishMonth = commonConstant.MONTHS.th.indexOf(item.month);

    const selectedStartDate = new Date(englishYear, EnglishMonth, item.day);
    const selectedEndDate = new Date(englishYear, EnglishMonth, +item.day + 1, 0, 0, 0, -1);
    if (isValidDate(selectedStartDate))
      onClickOK({ startDate: selectedStartDate, endDate: selectedEndDate });
  }

  const handleDayValueChange = (item) => {
    const englishYear = item.year - 543;
    const EnglishMonth = commonConstant.MONTHS.th.indexOf(item.month);
    setMonthTotalDays(new Date(englishYear, EnglishMonth + 1, 0).getDate());
    const selectedDate = new Date(englishYear, EnglishMonth, item.day);
    return setDateDisabled(!isValidDate(selectedDate));
  }

  const WeekList = getWeeklyDateRanges(modalStartEndDate.startDate.getMonth(), modalStartEndDate.startDate.getFullYear());
  const MonthlyList = getMonthlyDateRanges(modalStartEndDate.startDate.getFullYear());

  const isPastDate = modalStartEndDate.startDate < RELEASE_DATE;
  const isFutureDisabled = defaultDateEnd.current <= modalStartEndDate.endDate;
  const CalendarDateHeader = ({ children, onClickPrevious, onClickNext }) => <div className="calendar-date-modal-header">
    <img className={`calendar-date-modal-header-icon --left${isPastDate ? ' --hide' : ''}`} src="/images/healthAndWellness/foodLog/icons/chevron-icon.svg" onClick={onClickPrevious} />
    {children}
    {<img className={`calendar-date-modal-header-icon --right${isFutureDisabled ? ' --hide' : ''}`} src="/images/healthAndWellness/foodLog/icons/chevron-icon.svg" onClick={onClickNext} />}
  </div>


  const handleChangeMonth = (monthToAdd) => {
    const newStartDate = addMonths(modalStartEndDate.startDate, monthToAdd);
    const { lastDayOfMonth: newEndDate } = getMonthFirstDayAndLastDay(newStartDate.getMonth(), newStartDate.getFullYear());
    setModalStartEndDate({ startDate: newStartDate, endDate: newEndDate });
  }

  const handleChangeYear = (yearToAdd) => {
    const newStartDate = addYears(modalStartEndDate.startDate, yearToAdd);
    const newEndDate = addYears(modalStartEndDate.endDate, yearToAdd);
    setModalStartEndDate({ startDate: newStartDate, endDate: newEndDate });
  }

  const ViewMapping = {
    [HOME_CALENDAR_TABS_MAPPING.Week.name]: <>
      <CalendarDateHeader onClickPrevious={() => handleChangeMonth(-1)} onClickNext={() => handleChangeMonth(1)}>
        <div className="calendar-date-modal-header_label">{thaiDate(modalStartEndDate.startDate, "LLLL")}</div>
      </CalendarDateHeader>
      <div className="modal-picker-body week-view">
        {WeekList.map(({ startOfWeek, endOfWeek }) => {
          const formattedStart = thaiDate(startOfWeek, "dd MMM");
          const formattedEnd = thaiDate(endOfWeek, "dd MMM");
          const isNextDate = startOfWeek > defaultDateEnd.current || endOfWeek < RELEASE_DATE;
          const isPreviousDate = startOfWeek <= defaultDateStart.current;
          const isSelectedDate = startOfWeek.getTime() <= value.startDate.getTime() && value.endDate.getTime() <= endOfWeek.getTime();
          const itemClassName = isSelectedDate ? 'current-value' : (isNextDate ? 'next-value' : isPreviousDate ? 'previous-value' : '');
          return (<div
            key={formattedStart}
            className={`date-picker-item ${itemClassName}`}
            disabled={isNextDate}
            onClick={() => { !isNextDate && setValue({ startDate: startOfWeek, endDate: endOfWeek }) }}>
            {`${formattedStart} - ${formattedEnd}`}
          </div>);
        })}
      </div></>,

    [HOME_CALENDAR_TABS_MAPPING.Month.name]: <>
      {RELEASE_DATE.getFullYear() < defaultDateStart.current.getFullYear() && <CalendarDateHeader onClickPrevious={() => handleChangeYear(-1)} onClickNext={() => handleChangeYear(1)}>
        <div className="calendar-date-modal-header_label">{thaiDate(modalStartEndDate.startDate, "yyyy")}</div>
      </CalendarDateHeader>}
      <div className={`modal-picker-body month-view${RELEASE_DATE.getFullYear() < defaultDateStart.current.getFullYear() ? '' : ' --no-header'}`}>
        {MonthlyList.map(({ startOfMonth, endOfMonth }) => {
          const formattedMonth = thaiDate(startOfMonth, "LLLL");
          const isNextDate = startOfMonth > defaultDateEnd.current || endOfMonth < RELEASE_DATE;
          const isPreviousDate = startOfMonth <= defaultDateStart.current;
          const isSelectedDate = startOfMonth.getTime() <= value.startDate.getTime() && value.endDate.getTime() <= endOfMonth.getTime();
          const itemClassName = isSelectedDate ? 'current-value' : (isNextDate ? 'next-value' : isPreviousDate ? 'previous-value' : '');
          return (<div
            key={formattedMonth}
            className={`date-picker-item ${itemClassName}`}
            disabled={isNextDate}
            onClick={() => { !isNextDate && setValue({ startDate: startOfMonth, endDate: endOfMonth }) }}>
            {formattedMonth}
          </div>);
        })}
      </div>
    </>
  }

  const CommonWrapper = <div className="modal-picker">
    <HnWNumpadModal openNumpad={isOpen} onBackgroundClick={handleOKClick}>
      <div className="container-fluid">
        <div className="header-btn">
          <button className='btnClass' onClick={handleOKClick}>{SAVE_BUTTON_MODAL}</button>
        </div>
      </div>
      {ViewMapping[view]}
    </HnWNumpadModal>
  </div>


  const dailyDateSelection = () => {

    const YearRange = Array.from({ length: defaultDateEnd.current.getFullYear() - RELEASE_DATE.getFullYear() + 1 }, (_, index) => thaiDate(new Date(RELEASE_DATE.getFullYear() + index, 0), "yyyy"));

    return {
      day: Array.from({ length: monthTotalDays }, (v, i) => {
        return i + 1 + '';
      }),
      month: commonConstant.MONTHS.th,
      year: YearRange
    }

  };

  const defaultValue = { day: inputStartDate.getDate().toString(), month: thaiDate(inputStartDate, 'LLLL'), year: thaiDate(inputStartDate, 'yyyy') };

  return view === HOME_CALENDAR_TABS_MAPPING.Day.name ?
    <div className="hnw-date-selector">
      <MobilePicker showSelected={false}
        isOpen={isOpen}
        selections={dailyDateSelection()}
        defaultValue={defaultValue}
        onChange={handleDayValueChange}
        disabled={dateDisabled}
        onOKClick={handleDateClick} />
    </div> :
    CommonWrapper;
}

export default HealthDateSelector;