
export const NO_SEARCH_LIST_TEXT = {
    HEADING: 'ลองค้นหาใหม่อีกครั้ง',
    DESCRIPTION_TOP: 'ขออภัยเราไม่พบเมนูที่คุณสนใจ',
    IMAGE: '/images/healthAndWellness/foodCalories/food-list/search-no-result.gif'
};

export const SEARCH_TITLES = {
    keywords: 'ค้นหาล่าสุด',
    category: 'หมวดหมู่',
    list: 'ผลลัพท์ทั้งหมด',
    recommended: 'เมนูที่คุณอาจจะสนใจ',
    supp_recommended: 'รายการที่คุณอาจจะสนใจ',
}

export const SEARCH_REGEX = /^[\u0E00-\u0E3E\u0E40-\u0E7Fa-zA-Z0-9\s]{0,30}$/;

export const PAGE_SIZE = 10;
export const MIN_LENGTH = 1;
export const MAX_LENGTH = 30;