import React, { useState, useEffect, useRef } from 'react';
import { getSOPProfileList, getSOPProfileToCart } from '../../../services/sop-sub.service';
import LoadingOverlay from 'react-loading-overlay';
import './SOPProfileList.scss';
import { commonConstant } from '../../../common';
import { ProfileListItem, ProfileFooter,ProfileDetails } from './index'
import { getCart } from '../../../services/promotion/utils.service';
import CartIcon from '../../promotion/CartIcon';
import LiffSopProductDetails from '../../modal/LiffSopProductDetails';


export const SOPProfileList = props => {
  const { notFromCartPage = true } = props;
  const [notPurchaseProfile, setNotPurchaseProfile] = useState([]);
  const [accountType, setAccountType] = useState('ABO') 
  const [purchasedProfile, setPurchasedProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profileTabSelected, setProfileTabSelected] = useState('notPurchase');
  const today = new Date();
  const date = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const lastDate = date.getDate();
  const month = date.getMonth();
  const monthTH = commonConstant.SHORTMONTHS.th[month];
  const [cart, setCart] = useState({});
  const headerRef = useRef(null);
  const subHeaderRef = useRef(null);
  const profileContainerRef = useRef(null);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [detailsProfile, setDetailsProfile] = useState({});
  const [isDetailsLiffOpen, setIsDetailsLiffOpen] = useState(false);


  useEffect(() => {
    const didMount = async () => {
      try {
        let cartId = '';
        const cartData = await getCart(); 
        if(cartData && cartData.length) { 
          setCart(cartData[0]);
          setAccountType(cartData[0].account?.accountType);
          cartId = cartData[0]?.id;
        }
        const nonPurchasedProfile = await getSOPProfileList('notPurchase', cartId);
        if (nonPurchasedProfile && nonPurchasedProfile.data && nonPurchasedProfile.data.length) {
          setNotPurchaseProfile(nonPurchasedProfile.data)
        }
        setProfileLoaded(true);
        setLoading(false);
        if(notFromCartPage) {
          const purchasedProfile = await getSOPProfileList('purchased', cartId);
          if (purchasedProfile && purchasedProfile.data && purchasedProfile.data.length) {
            setPurchasedProfile(purchasedProfile.data)
          }
        }
      } catch (error) {
        setLoading(false);
      }
    }
    didMount();
  }, []);

  const renderProfileHeader = () => {
    return (
      <div className='solution-header' ref={headerRef}>
        <div className="sop-header">
          <h2 className='new-h2'>รายการ SOP ของฉัน ({notPurchaseProfile.length + purchasedProfile.length})</h2>
          <div className='subheading'>คุณมี SOP จำนวน {notPurchaseProfile.length} ชุด ที่ยังไม่ได้ซื้อ</div>
        </div>
        <div className='cart-icon'>
          <CartIcon/>
        </div>
        <div className='sop-header-image'>
          <img
            src="/images/sop/sop_profile_list_header.png"
            className="w-100"
          />
        </div>
      </div>
    )
  }

  const fetchProfileList = async(option) => {
    try {
      setLoading(true);
      setProfileTabSelected(option);
      const profileList = await getSOPProfileList(option, cart?.id);
      if (profileList && profileList.data && profileList.data.length) {
        if(option === 'notPurchase') {
          setNotPurchaseProfile(profileList.data)
        } else {
          setPurchasedProfile(profileList.data)
        }
      } else {
        if(option === 'notPurchase') {
          setNotPurchaseProfile([])
        } else {
          setPurchasedProfile([])
        }
      }
      setLoading(false);
    } catch(error) {
      setLoading(false);
    }
  }

  const renderProfileTab = () => {
    return (
      <div className='row sop_profile_tab no-margin-row' ref={subHeaderRef}>
        <div onClick={() => fetchProfileList('notPurchase')} className={'col-5 ' + (profileTabSelected === 'notPurchase' ? 'active-tab' : '')}>
          <span className='profile_tabheader'>ยังไม่ได้ซื้อ ({notPurchaseProfile.length})</span>
        </div>
        <div className='col-1'></div>
        <div onClick={() => fetchProfileList('purchased')} className={'col-5 ' + (profileTabSelected === 'purchased' ? 'active-tab' : '')}>
          <span className='profile_tabheader'>ซื้อแล้ว ({purchasedProfile.length})</span>
        </div>
      </div>
    )
  }

  const getProfileSelectedEvent = (profileID, option) => {
    if (profileTabSelected === 'notPurchase') {
      const profileList = notPurchaseProfile;
      profileList.forEach(list => {
        if (list.id === profileID) {
          list.isSelected = option === 'increase' ? true : false;
        }
      })
      setNotPurchaseProfile(JSON.parse(JSON.stringify(profileList)))
    }
  }

  const subscriptionInCart = (profileId) => {
    const profileList = notPurchaseProfile;
    profileList.forEach(list => {
      if (list.id === profileId) {
        list.isInCart = true;
      }
    })
    setNotPurchaseProfile(JSON.parse(JSON.stringify(profileList)))
  }

  const emptyButtonEvent = () => {
    if (profileTabSelected === 'notPurchase') { 
      window.location.href = commonConstant.pathToSOPOptions;
    } else {
      fetchProfileList('notPurchase');
    }
  }

  const sopDetailsModalHandler = (profile) => {
    setDetailsProfile(JSON.parse(JSON.stringify(profile)));
    setIsDetailsLiffOpen(true);
  }

  const renderProfileList = () => {
    const selectedProfileList = profileTabSelected === 'notPurchase' ? notPurchaseProfile : purchasedProfile;
    if (selectedProfileList && selectedProfileList.length) {
      return selectedProfileList.map((profile, index) => {
        const image = profile && profile.sopProduct &&
          profile.sopProduct.galleryImages &&
          profile.sopProduct.galleryImages.length > 0 ? profile.sopProduct.galleryImages[0].image_url : '';
        const isSolutionPlan = profile && profile.selectedSopPlan &&
          profile.selectedSopPlan.qualificationRules &&
          profile.selectedSopPlan.qualificationRules.length > 0 ? true : false;
        if (notFromCartPage || 
           (!notFromCartPage && profile && 
            !profile.isInCart && profile?.currentElementView?.statusView === 'notPurchase')) {
          return (
            <div className='col-12 profile-list' key={`profile-list-${index}`}>
              <ProfileListItem profile={profile}
                isSolutionPlan={isSolutionPlan}
                image={image}
                cart={cart}
                accountType={accountType}
                subscriptionInCartEvent={(id) => subscriptionInCart(id)}
                sopDetailsEvent={() => sopDetailsModalHandler(profile)}
                getProfileSelected={(id, option) => getProfileSelectedEvent(id, option)} />
            </div>
          )
        }
      })
    } else {
      if (!loading && profileLoaded) {
        return (
          <div className='col-12 empty_container'>
            <div className='row empty_profile_image'>
              <img src={profileTabSelected === 'notPurchase' ? "/images/sop/empty_non_purchased.svg" : "/images/sop/empty_purchased_profile.svg"} alt="" />
            </div>
            <span className='row empty_profile_heading'>{profileTabSelected === 'notPurchase' ? 'ซื้อครบแล้ว' : 'ยังไม่มีสินค้า'}</span>
            <span className='row empty_profile_subheading'>{profileTabSelected  === 'notPurchase' ? 'คุณซื้อสินค้าครบตามกำหนดแล้ว' : 'คุณยังไม่ได้ซื้อสินค้าตามกำหนด'}</span>
            <div className='row empty_profile_btn'>
              <button
                onClick={() => emptyButtonEvent()}
                className="col-6 profile-btn-purchase profile-footer-btn floating-right">
                {profileTabSelected === 'notPurchase' ? 'ไปสมัครเพิ่มเลย' : 'ไปซื้อเลย'}
              </button>
            </div>
          </div>
        )
      }
    }
  }

  const addProfileToCartEvent = async () => {
    try {
      if (notPurchaseProfile && notPurchaseProfile.length) {
        setLoading(true);
        const orderEntries = [];
        notPurchaseProfile.forEach(profile => {
          if (profile.isSelected) {
            const isSolutionPlan = profile && profile.selectedSopPlan &&
              profile.selectedSopPlan.qualificationRules &&
              profile.selectedSopPlan.qualificationRules.length > 0 ? true : false;
            if (isSolutionPlan) {
              const currentEntries = profile.currentElementView && profile.currentElementView.entries && profile.currentElementView.entries.length ?
                profile.currentElementView.entries[0] : []
              const entries = {
                product: {
                  id: currentEntries.selectedProductCode
                },
                kitEntryCode: null,
                hasSopEntries: true,
                qualifiedSubscriptions: [{
                  subscriptionIdentifier:profile?.id,
                  elementSequence: profile?.currentElementView?.elementSequence,
                  cycleSequence:profile?.currentElementView?.cycleSequence
                }],
                quantity: currentEntries.quantity,
                groupingIdentifier: `${profile?.id}_${profile?.currentElementView?.elementSequence}_${profile?.currentElementView?.cycleSequence}`,
                alias:currentEntries.selectedProductCode
              }
              orderEntries.push(entries);
              if(currentEntries && currentEntries.children && currentEntries.children.length) {
                currentEntries.children.forEach(child=>{
                  const entries = {
                    product: {
                      id: child.selectedProductCode
                    },
                    kitEntryCode: child.kitEntryCode,
                    productCode:child.selectedProductCode,
                    hasSopEntries: true,
                    qualifiedSubscriptions: [],
                    quantity: child.quantity,
                    groupingIdentifier: `${profile?.id}_${profile?.currentElementView?.elementSequence}_${profile?.currentElementView?.cycleSequence}`
                  }
                  orderEntries.push(entries);
                })
              }
            } else {
              const currentEntries = profile.currentElementView && profile.currentElementView.entries && profile.currentElementView.entries.length ?
              profile.currentElementView.entries[0] : []
              const entries = {
                product: {
                  id: currentEntries.selectedProductCode
                },
                kitEntryCode: null,
                hasSopEntries: true,
                quantity: currentEntries.quantity,
                alias:currentEntries.selectedProductCode,
                qualifiedSubscriptions: [{
                  subscriptionIdentifier:profile?.id,
                  elementSequence: profile?.currentElementView?.elementSequence,
                  cycleSequence:profile?.currentElementView?.cycleSequence
                }]
              }
              orderEntries.push(entries);
            }
          }
        })
        const cartID = cart?.id
        const data = await getSOPProfileToCart(cartID,orderEntries);
        const cartData = await getCart(); 
        if(cartData && cartData.length) { 
          setCart(cartData[0]);
        }
        setLoading(false);
        window.location.href = commonConstant.pathCartPromotion;
      }
    } catch (error) { setLoading(false); }
  }

  const getProfileContainerHeight = () => {
    const height = profileContainerRef?.current?.scrollHeight;
    const filterHeight = notFromCartPage ?
      window.innerHeight - (headerRef?.current?.clientHeight + subHeaderRef?.current?.clientHeight) :
      window.innerHeight - 100;
    const finalHeight = height > filterHeight ? notFromCartPage ? '100%' : filterHeight : filterHeight;
    return finalHeight;
  }

  return (
    <section className='sop_profile'>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      {notFromCartPage && renderProfileHeader()}
      {notFromCartPage && renderProfileTab()}
      <div className='row no-margin-row sop-plp-container sop-profile-container' ref={profileContainerRef} style={{height: getProfileContainerHeight()}}>
        {profileTabSelected === 'notPurchase' && profileLoaded && notPurchaseProfile?.length > 0 ? <div className='col-12 subheader'>
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.9167 2.94434C11.9167 2.53012 11.5809 2.19434 11.1667 2.19434C10.7525 2.19434 10.4167 2.53012 10.4167 2.94434V3.52767H6.58335V2.94434C6.58335 2.53012 6.24757 2.19434 5.83335 2.19434C5.41914 2.19434 5.08335 2.53012 5.08335 2.94434V3.52767H4.35187C3.3803 3.52767 2.41669 4.23681 2.41669 5.31471V7.611V12.574C2.41669 13.6519 3.3803 14.361 4.35187 14.361H12.6482C13.6197 14.361 14.5834 13.6519 14.5834 12.574V7.611V5.31471C14.5834 4.23681 13.6197 3.52767 12.6482 3.52767H11.9167V2.94434ZM13.0834 6.861V5.31471C13.0834 5.27056 13.0647 5.20912 12.9923 5.14584C12.9185 5.08127 12.7991 5.02767 12.6482 5.02767H11.9121C11.8707 5.40267 11.5527 5.69434 11.1667 5.69434C10.7806 5.69434 10.4627 5.40267 10.4213 5.02767H6.57878C6.53732 5.40267 6.2194 5.69434 5.83335 5.69434C5.44731 5.69434 5.12938 5.40267 5.08793 5.02767H4.35187C4.20093 5.02767 4.0815 5.08127 4.0077 5.14584C3.93538 5.20912 3.91669 5.27056 3.91669 5.31471V6.861H13.0834ZM3.91669 8.361H13.0834V12.574C13.0834 12.6181 13.0647 12.6795 12.9923 12.7428C12.9185 12.8074 12.7991 12.861 12.6482 12.861H4.35187C4.20093 12.861 4.0815 12.8074 4.0077 12.7428C3.93538 12.6795 3.91669 12.6181 3.91669 12.574V8.361Z" fill="#7F3E3E" />
          </svg>   สั่งซื้อก่อนวันที่ {lastDate} {monthTH}
        </div> : null}
        {renderProfileList()}
      </div>
      {profileTabSelected === 'notPurchase' && profileLoaded && notPurchaseProfile?.length > 0 ?
        <ProfileFooter notPurchaseProfile={notPurchaseProfile}
          accountType={accountType}
          addProfileToCart={() => addProfileToCartEvent()} /> : null}
      {isDetailsLiffOpen &&
        <LiffSopProductDetails title={'รายละเอียดชุด SOP'}
          headerClass={'profileDetails_header'}
          onClose={() => setIsDetailsLiffOpen(false)}
          isOpen={isDetailsLiffOpen}>
          <ProfileDetails profile={detailsProfile} cart={cart}/>
        </LiffSopProductDetails>}
    </section>
  )
}