class BodyDataDifference {
    constructor(latest = null, latestUpdatedDate = null, last = null, lastUpdatedDate = null, diff = null) {
        this.latest = latest;
        this.latestUpdatedDate = latestUpdatedDate;
        this.last = last;
        this.lastUpdatedDate = lastUpdatedDate;
        this.diff = diff;
    }
}

class BodyDataKey {
    constructor(height, fat, weight, muscle, waist) {
        this.height = new BodyDataDifference(...height);
        this.fat = new BodyDataDifference(...fat);
        this.weight = new BodyDataDifference(...weight);
        this.muscle = new BodyDataDifference(...muscle);
        this.waist = new BodyDataDifference(...waist);
    }

    static fromAPI(data) {
        return new BodyDataKey(
            [data.height.latest, data.height.latestUpdatedDate, data.height.last, data.height.lastUpdatedDate, data.height.diff],
            [data.fat.latest, data.fat.latestUpdatedDate, data.fat.last, data.fat.lastUpdatedDate, data.fat.diff],
            [data.weight.latest, data.weight.latestUpdatedDate, data.weight.last, data.weight.lastUpdatedDate, data.weight.diff],
            [data.muscle.latest, data.muscle.latestUpdatedDate, data.muscle.last, data.muscle.lastUpdatedDate, data.muscle.diff],
            [data.waist.latest, data.waist.latestUpdatedDate, data.waist.last, data.waist.lastUpdatedDate, data.waist.diff]
        );
    }
}

export { BodyDataDifference, BodyDataKey };
