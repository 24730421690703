import React, { Children } from 'react'
import './MapGoalCard.scss';


export default function MapGoalCard({ children }) {
    return (
        <div className="map-goal-card-container">
            {children}
        </div>
    )
}