import { getChallengeSelf } from "../../services/challenge/challengeSelf.service";
import { GET_SELF_CHALLENGE, GET_SELF_CHALLENGE_FAILURE, GET_SELF_CHALLENGE_SUCCESS } from "../types";

export const fetchChallengeSelf = () => (dispatch) => {
    dispatch({ type: GET_SELF_CHALLENGE });

    return getChallengeSelf()
        .then((result) => {
            dispatch({
                type: GET_SELF_CHALLENGE_SUCCESS,
                payload: result,
            });
            return result;
        })
        .catch(function (error) {
            dispatch({ type: GET_SELF_CHALLENGE_FAILURE, payload: error.message });
        });
};