import {
    FETCH_DATA_REQUEST, FETCH_POLICIES_STATUS_SUCCESS,
    FETCH_POLICIES_STATUS_FAILURE, fetchDataFailure, fetchDataRequest,
    FETCH_ASSESSMENT_DATA_SUCCESS, FETCH_ASSESSMENT_DATA_FAILURE,
    fetchDataSuccess, GET_POLICIES_DATA, GET_POLICIES_ERROR,
    UPDATE_POLICIES_STATUS
} from "../actions/hnwHome.action";
import { policyTitle } from "../common/commonConstant";
import { getAssessmentList, getPolicies, getPolicyStatus, updateProfilePicture } from "../services/healthWellness/healthWellness.service";

export const fetchPolicyStatus = selectedPolicy => dispatch => {
    dispatch(fetchDataRequest(FETCH_DATA_REQUEST));
    return getPolicyStatus(selectedPolicy)
        .then((res) => {
            dispatch(fetchDataSuccess(FETCH_POLICIES_STATUS_SUCCESS, res));
            return res;
        }).catch(function (error) {
            dispatch(fetchDataFailure(FETCH_POLICIES_STATUS_FAILURE, error.message));
            alert(error.message);
        });
};

export const fetchAssessmentList = () => dispatch => {
    dispatch(fetchDataRequest(FETCH_DATA_REQUEST));
    return getAssessmentList()
        .then((res) => {
            dispatch(fetchDataSuccess(FETCH_ASSESSMENT_DATA_SUCCESS, res));
            return res;
        }).catch(function (error) {
            dispatch(fetchDataFailure(FETCH_ASSESSMENT_DATA_FAILURE, error.message));
            alert(error.message);
        });
};

export const fetchPolicies = () => async (dispatch) => {
    try {
        const policies = await getPolicies();
        const selectedPolicy = (policies.length &&
            policies.find((el) => el.title.toLowerCase() === policyTitle.home_policy)) ?? null;
        dispatch(fetchDataSuccess(GET_POLICIES_DATA, [selectedPolicy]));
        return policies;
    } catch (error) {
        dispatch(fetchDataFailure(GET_POLICIES_ERROR, error.message));
        alert(error.message);
    }
}

export const updatePolicyStatus = (policyStatus = false) => dispatch => {
    dispatch(fetchDataSuccess(UPDATE_POLICIES_STATUS, policyStatus));
}

export const updateProfileImage = (payload) => async (dispatch) => {
    try {
        await updateProfilePicture(payload);
    } catch(err) {
        alert(err.message);
    }
}