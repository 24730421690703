import React, { useState, useEffect } from 'react';

import './ChipsAssessment.scss';
import { ChipAssessment } from '../../../../../common';

const Chips = ({ chipsData, getAnswer }) => {
  const [selectedChip, setSelectedChip] = useState(null);

  useEffect(() => {
    setSelectedChip(null);
    //default value handling
    getAnswer([], chipsData);
  }, [chipsData]);

  const toggleChip = (chip) => {
    if (selectedChip && selectedChip._id === chip._id) {
      // If the clicked chip is already selected, deselect it
      setSelectedChip(null);
      getAnswer([], chipsData);
    } else {
      // If a different chip is clicked, select it
      setSelectedChip(chip);
      getAnswer([chip], chipsData);
    }
  };

  return (
    <div className="chips-container">
      <div className="chip-question">{chipsData?.question}</div>
      <div className="chip-question-value">
      {chipsData?.question_value.map((chip, index) => (
        <>
          <ChipAssessment
            key={chip?._id || index}
            text={chip?.value}
            isSelected={selectedChip && selectedChip._id === chip._id}
            onClick={() => toggleChip(chip)}
          />
        </>
      ))}
      </div>
    </div>
  );
};

export default Chips;