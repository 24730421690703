import React, { useState, useEffect } from 'react';

import './BoxesAssessment.scss';
import {BoxAssessment} from '../../../../../common';
import config from '../../../../../config';


const Boxes = ({ boxData, getAnswer }) => {
  const [selectedBoxes, setSelectedBoxes] = useState([]);

  useEffect(() => {
    setSelectedBoxes([]);
  }, [boxData]);

  const toggleBox = (box) => {
    let updatedBoxes = [...selectedBoxes, box];
    if (selectedBoxes.includes(box)) {
      updatedBoxes = selectedBoxes.filter((selectedBox) => selectedBox !== box);
    } 
    setSelectedBoxes(updatedBoxes);
    getAnswer(updatedBoxes, boxData)
  };

  return (
    <div className="box-container">
      <div className="box-question">{boxData?.question}</div>
      <div className="box-div">
      {boxData?.question_value.map((box, index) => (
        <BoxAssessment
          key={box?._id || index}
          text={box?.value}
          image={`${config.S3_URL}${box?.image_url}`}
          isSelected={selectedBoxes.includes(box)}
          onClick={() => toggleBox(box)}
          selectedNumber={selectedBoxes.indexOf(box) + 1}
        />
      ))}
      </div>
    </div>
  );
};

export default Boxes;
