import axios from 'axios';
import { commonConstant } from '../common';

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    console.log(err.response);
    if (err.response && err.response.status === 401) {
      sessionStorage.removeItem('customer');
      // window.location = commonConstant.pathHome;
    }

    return Promise.reject(err);
  },
);
export * from './customers.service';
export * from './products.service';
export * from './activity.service';
export * from './amway.service';
export * from './question.service';
export * from './payment.service';
export * from './utils.service';
export * from './activity-log.service';
export * from './sop-cart.service';
export * from './renewal.service';
export * from './address.service';
export * from './axiosInterceptor.service';
