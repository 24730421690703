import React from 'react';
import { ExerciseRecommendedCard } from '../../../../../common';
import './ExerciseRecommendedList.scss';

const ExerciseRecommendedList = ({
  isRecommended,
  header,
  routeTo,
  setExerciseMenuId,
  exerciseTimeLogList,
  data,
  toggleTimeLog,
  isTargetCalorieCalculated,
  targetCalorie,
}) => {
  return (
    <div className="recommended-exercise-container-wrapper">
      <div className="header">{header}</div>
      <div className="recommended-exercise-card-container">
        {data?.length ? (
          <div>
            {data?.map((item, key) => (
              <div
                className="recommended-exercise-card"
                key={`ExerciseRecommendedCard_${key}`}
              >
                <ExerciseRecommendedCard
                  item={item}
                  toggleTimeLog={toggleTimeLog}
                  isRecommended={isRecommended}
                  routeTo={routeTo}
                  setExerciseMenuId={setExerciseMenuId}
                  exerciseTimeLogList={exerciseTimeLogList}
                  isTargetCalorieCalculated={isTargetCalorieCalculated}
                  targetCalorie={targetCalorie}
                />
              </div>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ExerciseRecommendedList;
