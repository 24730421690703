import {
  addProductToCart, deleteAssessmentDetail,
  deleteHWProfile,
  fetchAssessmentProducts,
  fetchAssessmentScore,
  fetchHnWAssessment,
  fetchHnWAssessmentDetail,
  fetchHnWAssessmentHistoryLog,
  fetchHnWAssessmentList,
  fetchPifCart,
  saveAssessmentAnswer,
  getChallenges
} from '../services/healthWellness/healthWellness.service';
import { UPDATE_POLICIES_STATUS } from './hnwHome.action';
import {
  GET_HNW_SCORE, FETCH_HNW_ASSESSMENT_LIST, FETCH_HNW_ASSESSMENT, UPDATE_HNW_ANSWER, DELETE_PROFILE_DATA, FETCH_HNW_ASSESSMENT_DETAIL,
  FETCH_HNW_ASSESSMENT_HISTORY_LOG, DELETE_HNW_ASSESSMENT_DETAIL, GET_HNW_PRODUCTS, FETCH_CUSTOMER_PIF_CART, ADD_PRODUCT, GET_BODYKEY_CHALLENGES
} from './types';

export const getHnWAssessmentList = (assessment_id) => (dispatch) =>
  fetchHnWAssessmentList(assessment_id)
    .then((result) => {
      dispatch({
        type: FETCH_HNW_ASSESSMENT_LIST,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const getHnWAssessment = (assessment_id) => (dispatch) =>
  fetchHnWAssessment(assessment_id)
    .then((result) => {
      dispatch({
        type: FETCH_HNW_ASSESSMENT,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const updateAssessmentAnswer =
  (assessment_id, assessment_data, answer_id, assessment_status) =>
    (dispatch) =>
      saveAssessmentAnswer(
        assessment_id,
        assessment_data,
        answer_id,
        assessment_status,
      )
        .then((result) => {
          if (result?.success) {
            dispatch({
              type: UPDATE_HNW_ANSWER,
              payload: result?.answerId,
              hasError: false,
            });
          } else {
            dispatch({
              type: UPDATE_HNW_ANSWER,
              payload: result?.errors,
              hasError: true,
            });
          }
          return result;
        })
        .catch(function (error) {
          alert(error.message);
        });

export const getHnWAssessmentDetail = (assessment_id) => (dispatch) =>
  fetchHnWAssessmentDetail(assessment_id)
    .then((result) => {
      dispatch({
        type: FETCH_HNW_ASSESSMENT_DETAIL,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const getHnWAssessmentHistoryLog = () => (dispatch) =>
  fetchHnWAssessmentHistoryLog()
    .then((result) => {
      dispatch({
        type: FETCH_HNW_ASSESSMENT_HISTORY_LOG,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const deleteHnWAssessmentDetail = (answer_id) => (dispatch) =>
  deleteAssessmentDetail(answer_id)
    .then((result) => {
      dispatch({
        type: DELETE_HNW_ASSESSMENT_DETAIL,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const getAssessmentScore = (assesmentAnswer) => (dispatch) =>
  fetchAssessmentScore(assesmentAnswer)
    .then((result) => {
      dispatch({
        type: GET_HNW_SCORE,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const getAssessmentProducts = (type, productdIds) => (dispatch) =>
  fetchAssessmentProducts(productdIds)
    .then((result) => {
      dispatch({
        type: GET_HNW_PRODUCTS,
        payload: { type, result },
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const getCartDetail = (fields) => (dispatch) =>
  fetchPifCart(fields)
    .then((result) => {
      dispatch({
        type: FETCH_CUSTOMER_PIF_CART,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const addSelectedProductToCart = (cartId, product) => (dispatch) =>
  addProductToCart(cartId, product)
    .then((result) => {
      dispatch({
        type: ADD_PRODUCT,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      console.log(error);
    });

export const deleteHWProfileData = () => (dispatch) =>
  deleteHWProfile()
    .then((result) => {
      dispatch({
        type: DELETE_PROFILE_DATA,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const getBodyKeyChallengeDetail = (payload) =>
  (dispatch) => getChallenges(payload)
    .then(result => {
      dispatch({
        type: GET_BODYKEY_CHALLENGES,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      console.log(error.message);
    });