import React from 'react';
import './CartAssessmentHeader.scss';
import { commonConstant } from '../index';
import { QUERY_PARAMS_MAPPER } from '../../constants';

const CartAssessmentHeader = ({
  leftIcon,
  rightFirstIcon,
  rightSecondIcon,
  leftLabel,
  headerRoute = 'pathHealthnWellness',
  rightFirstIconRoute = 'pathHnWAssessmentHistoryLog',
  rightSecondIconRoute = 'pathCartPromotion',
  isSupplementRoute,
  routeTo,
  isDelete,
  referrerPage
}) => {

  const handleBackIconClick = (route) => {
    let pathParam;
    if (isSupplementRoute) {
      pathParam = `?referrer=${QUERY_PARAMS_MAPPER['dietarySupplement'].type}`;
    } else if (isDelete) {
      pathParam = `?referrer=${referrerPage}`;
    }
    routeTo(route, pathParam);
  };

  return (
    <div className="white-box">
      <div
        className="left-icon"
        onClick={leftIcon ? () => handleBackIconClick(headerRoute) : null}
      >
        {leftIcon && (
          <>
            <img src={leftIcon} />
            <span className="left-label">{leftLabel}</span>
          </>
        )}
      </div>
      <div className="right-icons">
        {rightFirstIcon && (
          <img
            onClick={() => routeTo(rightFirstIconRoute)}
            src={rightFirstIcon}
          />
        )}
        {rightSecondIcon && (
          <img
            onClick={() => routeTo(rightSecondIconRoute)}
            src={rightSecondIcon}
          />
        )}
      </div>
    </div>
  );
};

export default CartAssessmentHeader;
