import { GET_EXERCISE_CALENDAR_DATA, GET_FOOD_CALENDAR_DATA } from '../actions/types';

export default function (state = { foodWater: [], exercise: [] }, action) {
  switch (action.type) {
    case GET_FOOD_CALENDAR_DATA:
      return { ...state, foodWater: [...action.payload] };
    case GET_EXERCISE_CALENDAR_DATA:
      return { ...state, exercise: [...action.payload] };
    default:
      return state;
  }
}
