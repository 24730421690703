import {
  generateOtpBodyKey,
  getBodyKeyRecord,
  verifyBodyKeyId,
  verifyOtpBodyKey,
} from '../../services/challenge/challengeBodyKey.service';
import {
  GET_BODY_KEY_RECORD,
  GET_BODY_KEY_RECORD_ERROR,
  GET_BODY_KEY_RECORD_SUCCESS,
  GET_BODY_KEY_VERIFY,
  GET_BODY_KEY_VERIFY_ERROR,
  GET_BODY_KEY_VERIFY_SUCCESS,
  POST_OTP_GENERATE_BODY_KEY,
  POST_OTP_GENERATE_BODY_KEY_SUCCESS,
  POST_OTP_GENERATE_BODY_KEY_ERROR,
  POST_OTP_VERIFY_BODY_KEY,
  POST_OTP_VERIFY_BODY_KEY_SUCCESS,
  POST_OTP_VERIFY_BODY_KEY_ERROR,
} from '../types';

export const verifyBodyKeyIdAction = (params) => (dispatch) => {
  dispatch({ type: GET_BODY_KEY_VERIFY });
  return verifyBodyKeyId(params)
    .then((result) => {
      dispatch({
        type: GET_BODY_KEY_VERIFY_SUCCESS,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      dispatch({ type: GET_BODY_KEY_VERIFY_ERROR, payload: error });
    });
};

export const getBodyKeyRecordAction = (params) => (dispatch) => {
  dispatch({ type: GET_BODY_KEY_RECORD });
  return getBodyKeyRecord(params)
    .then((result) => {
      dispatch({
        type: GET_BODY_KEY_RECORD_SUCCESS,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      dispatch({ type: GET_BODY_KEY_RECORD_ERROR, payload: error.message });
    });
};

export const generateOtpBodyKeyAction = (amwayNumber) => (dispatch) => {
  dispatch({ type: POST_OTP_GENERATE_BODY_KEY });
  return generateOtpBodyKey(amwayNumber)
    .then((result) => {
      dispatch({
        type: POST_OTP_GENERATE_BODY_KEY_SUCCESS,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      dispatch({
        type: POST_OTP_GENERATE_BODY_KEY_ERROR,
        payload: error.message,
      });
    });
};

export const verifyOtpBodyKeyAction = (payload) => (dispatch) => {
  dispatch({ type: POST_OTP_VERIFY_BODY_KEY });
  return verifyOtpBodyKey(payload)
    .then((result) => {
      dispatch({
        type: POST_OTP_VERIFY_BODY_KEY_SUCCESS,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      dispatch({
        type: POST_OTP_VERIFY_BODY_KEY_ERROR,
        payload: error,
      });
      return error
    });
};
