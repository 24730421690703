import React from "react";
import {
    EXCERCISE_CARD_HEADING, EXCERCISE_CIRCLE_BG_ICON, EXCERCISE_CIRCLE_GRADIENT_DEGREE, EXCERCISE_CIRCLE_GRADIENT_ID, EXCERCISE_CIRCLE_START_COLOR, EXCERCISE_CIRCLE_STOP_COLOR, EXERCISE_BAR_NAME
} from "../commonConstant";
import HealthHomeCircleChart from "../healthHomeCircleChart/HealthHomeCircleChart";
import HealthLogItemEmptyCard from "../healthLogItemEmptyCard/HealthLogItemEmptyCard";
import './HealthLogExcerciseCard.scss';

const HealthLogExcerciseCard = ({ isEmpty, exerciseLog, onClick = () => { } }) => {

    const renderCircularChart = () => {
        return (
            <div className='health-log-excercise-card' onClick={onClick}>
                <div className="health-log-excercise-card-header">
                    <div className="health-log-excercise-card-header-left">
                        <img src="/images/healthAndWellness/foodLog/icons/burn.svg" />
                        <h6>{EXCERCISE_CARD_HEADING}</h6>
                    </div>
                    <div className="health-log-excercise-card-header-right">
                        <button className="btn-icon-round">
                            <img src="/images/healthAndWellness/foodLog/icons/arrow-right.svg" />
                        </button>
                    </div>
                </div>
                <div className="excercise-circle-chart">
                    <HealthHomeCircleChart
                        stopColorStart={EXCERCISE_CIRCLE_START_COLOR}
                        stopColorEnd={EXCERCISE_CIRCLE_STOP_COLOR}
                        bgIcon={EXCERCISE_CIRCLE_BG_ICON}
                        usedQty={exerciseLog?.consumed}
                        totalQty={exerciseLog?.total}
                        unit={exerciseLog?.unit}
                        gradientId={EXCERCISE_CIRCLE_GRADIENT_ID}
                        gradientDegree={EXCERCISE_CIRCLE_GRADIENT_DEGREE} />
                </div>
            </div>
        )
    }

    return (isEmpty ? <HealthLogItemEmptyCard itemType={EXERCISE_BAR_NAME} onClick={onClick} /> : renderCircularChart())
}

export default HealthLogExcerciseCard;