import React, { useState } from 'react';
import ReactCalendar from 'react-calendar';
import CircularChart from '../circularChart/CircularChart';
import { getMonthFirstDayAndLastDay } from '../../components/shared/graph/graph-util';
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.scss';
import { thaiDate } from '../../utill.func';

export default function CustomCalendar({ data,
  colorCircledark,
  colorCirclelight,
  color1Dot,
  color2Dot,
  min,
  max,
  onDateChange,
  trackTotal,
  showCircleWithoutDataStrength = !trackTotal,
  showDataDots }) {
  const currentDate = new Date();

  const renderCircularChartData = (inputDate) => {

    const calendarData = data.find(({ date }) => date?.getDate() === inputDate.getDate());
    return <>
      {inputDate <= currentDate ? <CircularChart
        data1Id={inputDate.getTime()}
        color1dark={colorCircledark}
        color1light={colorCirclelight}
        showOverflow={calendarData?.data1 > trackTotal}
        trackTotal={showCircleWithoutDataStrength ? 100 : trackTotal}
        track1Value={showCircleWithoutDataStrength ? (calendarData?.data1 || calendarData?.data2) && 100 : calendarData?.data1}
      /> : <div className="calendar-future-date-tile"></div>}
      {showDataDots && <div className="calendar-data-pointer-list">
        <div className="calendar-data-pointer" style={{ ...calendarData?.data1 ? { background: color1Dot } : {} }}></div>
        <div className="calendar-data-pointer" style={{ ...calendarData?.data2 ? { background: color2Dot } : {} }}></div>
      </div >}
    </>;
  };

  const handleCalenderData = ({ date, view }) =>
    view === 'month' && renderCircularChartData(date);
  return (
    <div className="custom-calendar">
      <div className="custom-calendar-header">
        {thaiDate(new Date(min), 'LLLL yy')}
      </div>
      <ReactCalendar
        locale="th"
        calendarType="gregory"
        activeStartDate={min}
        showNeighboringMonth={false}
        tileContent={handleCalenderData}
        showNavigation={false}
        onChange={onDateChange}
      />
    </div>
  );
}
