import React from "react";
import "./BodyDataLoader.scss";

const BodyDataLoader = () => {
  return (
    <div className="home-body-data-loader">
    <div className="vdo-skeleton-container">
     <div className="vdo-skeleton-card-container">
       <div className="skeleton-card-image skeleton"></div>
       <div className="skeleton-card-details">
       </div>
     </div>
     <div className="vdo-skeleton-card-container">
       <div className="skeleton-card-image skeleton"></div>
       <div className="skeleton-card-details">
       </div>
     </div>
   </div>

   <div className="vdo-skeleton-container">
     <div className="vdo-skeleton-card-container">
       <div className="skeleton-card-image skeleton"></div>
       <div className="skeleton-card-details">
       </div>
     </div>
     <div className="vdo-skeleton-card-container">
       <div className="skeleton-card-image skeleton"></div>
       <div className="skeleton-card-details">
       </div>
     </div>
   </div>
 </div>
  );
};

export default BodyDataLoader;
