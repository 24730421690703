import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ChallengeQuiz.scss';
import RoundChip from '../../../common/roundChipButton/RoundChip';
import ChallengeVideoHeader from "./challengeVideo/ChallengeVideoHeader";
import ChallengeVideoCard from './challengeVideo/ChallengeVideoCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChallengeVideos } from '../../../actions/challenge/challengeVideoActions';
import { fetchChallengeQuiz, updateChallengeAnswer } from '../../../actions';
import QuizDetail from './quizSection/QuizDetail';
import QuizQuestionCard from './quizSection/QuizQuestionCard';
import ChallengeSubmit from './challengeSubmit/ChallengeSubmit';
import { CHALLENGE, CHALLENGE_ANSWER_LABELS, CHALLENGE_QUIZ_LABELS, QUIZ_HEADER, QUIZ_DETAILS } from '../../../common/challengeConstants';
import QuizHeader from './challengeHeader/QuizHeader';
import queryString from 'query-string';
import { getDataFromSession, getParamsString } from '../../../utill.func';
import ChallengeVideoCardLoader from './challengeQuizLoader/ChallengeVideoCardLoader';
import RoundChipLoader from './challengeQuizLoader/RoundChipLoader';
import QuizQuestionCardLoader from './challengeQuizLoader/QuizQuestionCardLoader';
import { commonConstant } from '../../../common';
import { useLocation, useHistory } from "react-router-dom";
import HnWSlideup from '../../../common/slideup/HnWSlideup';
import { message } from '../../../services/promotion/utils.service';
import Swal from 'sweetalert2';

const ChallengeQuiz = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [filteredQuiz, setFilteredQuiz] = useState({});
  const [isSticky, setIsSticky] = useState(false);
  const [isQuizAnswered, setIsQuizAnswered] = useState();
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const [quizRoundAll, setQuizRoundAll] = useState([]);
  const componentRef = useRef(null);
  const tabRef = useRef(null);
  const videoCardRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const challengeId = params['challengeId'];
  const eventId = params['eventId'];
  const userId = params['userId'];
  const { partyId, amwayNumber } = getDataFromSession(
    'customer'
  );
  const { challengeVideoList, loading: videoLoading } = useSelector(state => state.challengeVideo);
  const { challengeQuizList, loading: quizLoading } = useSelector(state => state.challengeQuiz);

  const settings = {
    dots: false,
    slidesToShow: 4,
    infinite: false,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    swipe: !quizLoading,
  };

  const videoSettings = {
    dots: false,
    slidesToShow: 1.5,
    infinite: false,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
  };

  const handleAnswersSelection = (orderNumber, choiceIndex) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[orderNumber] = choiceIndex;
    setSelectedAnswers(updatedAnswers);
  }

  const updateUserAnswers = () => {
    const answersToUpdate = selectedAnswers;
    dispatch(updateChallengeAnswer(filteredQuiz?.id, answersToUpdate, true)).then(res => {
      if (res?.is_error) {
        Swal.fire({
          title: "",
          text: message.error.somethingWrong,
          confirmButtonText: "ตกลง"
        });
      }
    });
  }

  const handleLeftIconRedirection = () => {
    history.push(`${commonConstant.pathHealthnWellness}`);
  }

  const handleRightIconRedirection = () => {
    const params = {
      challengeId: challengeId,
      eventId: eventId
    }
    history.push(`${commonConstant.pathChallengeTeamDashboard}?${getParamsString(params)}`);
  }

  const handleChallengeVideoRedirection = () => {
    const params = {
      challengeId: challengeId,
      eventId: eventId,
      userId: userId,
      videoId: videoIndex
    }
    history.push(`${commonConstant.pathChallengeVideo}?${getParamsString(params)}`);
  }


  const quizDetailProps = !isQuizAnswered ?
    {
      quizScore: filteredQuiz?.quiz_part?.required_question_count,
      scoreText: QUIZ_DETAILS?.quiz_pending?.score_text,
      dateText: QUIZ_DETAILS?.quiz_pending?.date_text,
      quizDate: filteredQuiz?.thai_end_date || "",
      timeImage: QUIZ_DETAILS?.quiz_pending?.time_img_url,
      timeClass: QUIZ_DETAILS?.quiz_pending?.time_className
    } :
    {
      quizScore: filteredQuiz?.quiz_user_answers?.score,
      scoreText: QUIZ_DETAILS?.quiz_completed?.score_text,
      dateText: QUIZ_DETAILS?.quiz_completed?.date_text,
      quizDate: filteredQuiz?.quiz_user_answers?.thai_updated_at || "",
      timeImage: QUIZ_DETAILS?.quiz_completed?.time_img_url,
      timeClass: QUIZ_DETAILS?.quiz_completed?.time_className
    };

  const quizHeaderProps = {
    leftIcon: QUIZ_HEADER.LEFT_ICON,
    rightIcon: QUIZ_HEADER.RIGHT_ICON,
    isSticky: isSticky,
    handleLeftIconRedirection: handleLeftIconRedirection,
    handleRightIconRedirection: handleRightIconRedirection,
  };

  const quizQuestionCardProps = {
    labels: !isQuizAnswered ? CHALLENGE_QUIZ_LABELS : CHALLENGE_ANSWER_LABELS,
    isAnswered: isQuizAnswered,
    quizData: filteredQuiz,
    selectedAnswers: !isQuizAnswered ? selectedAnswers : null,
    onAnswerSelect: !isQuizAnswered ? handleAnswersSelection : null
  }

  useEffect(() => {
    const allAnswered = selectedAnswers.every(answer => answer != null);
    setAllQuestionsAnswered(allAnswered)
  }, [selectedAnswers]);

  useEffect(() => {
    const didMount = () => {
      const paramsObj = {
        eventId: challengeId,
        userIds: userId
      }
      const paramsObjV = {
        eventId: challengeId,
        aboId: amwayNumber,
        partyId: partyId
      }

      if (challengeQuizList.length == 0) {
        getChallengeQuizData(paramsObj);
      }
      if (challengeVideoList.length == 0) {
        getChallengeVideoData(paramsObjV);
      }
    }
    didMount();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowTop = window.scrollY;
      setIsSticky(windowTop > 30);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => {
    if(challengeQuizList?.length > 0 && challengeVideoList?.length > 0){
      const availableRounds = Math.floor(challengeVideoList?.length / 5);

      const adjustedList = challengeQuizList.slice(0,availableRounds);
      setQuizRoundAll(adjustedList);
    }
  }, [challengeQuizList, challengeVideoList]);

  useEffect(() => {
    const sessionData = sessionStorage.getItem('challengeData');
    const existingData = sessionData ? JSON.parse(sessionData) : {};
    const sessionActiveIndex = existingData?.quizActiveRound;

    if(sessionActiveIndex) {
      setActiveIndex(sessionActiveIndex);
    }
    else if (quizRoundAll?.length > 0 && !activeIndex)
      setActiveIndex(quizRoundAll[0]?.quiz_part_id);
  }, [quizRoundAll]);

  useEffect(() => {
    
    const sessionData = sessionStorage.getItem('challengeData');
    const existingData = sessionData ? JSON.parse(sessionData) : {};
    existingData.quizActiveRound = activeIndex;
    sessionStorage.setItem('challengeData', JSON.stringify(existingData));

  }, [activeIndex])

  useEffect(() => {
    if (tabRef?.current && activeIndex != null) {
      const activeSlideIndex = quizRoundAll.findIndex(
        (quiz) => quiz.quiz_part_id === activeIndex
      );
      tabRef.current.slickGoTo(Math.floor(activeSlideIndex / settings.slidesToShow) * settings.slidesToScroll);
    }
  }, [activeIndex, quizRoundAll]);

  useEffect(() => {
    if (videoCardRef?.current) {
      videoCardRef.current.slickGoTo(0);
    }
  }, [filteredVideos]);

  useEffect(() => {
    if (quizRoundAll?.length > 0) {
      const filteredChallenge = quizRoundAll.find((quiz, index) => {
        if (quiz?.quiz_part_id === activeIndex) {
          setVideoIndex(index);
          return true;
        }
        return false;
      });;
      const quizQuestionsLength = filteredChallenge?.quiz_question?.length;

      setFilteredQuiz(filteredChallenge ? filteredChallenge : {});
      setIsQuizAnswered(filteredChallenge?.quiz_user_answers?.hasUserAnswered);
      setSelectedAnswers(Array(quizQuestionsLength).fill(null));
    }
  }, [activeIndex, quizRoundAll]);

  useEffect(() => {
    if (challengeVideoList?.length > 0) {
      const startIndex = videoIndex * 5;
      const endIndex = startIndex + 5;

      const filteredVideoData = challengeVideoList.slice(startIndex, endIndex);
      setFilteredVideos(filteredVideoData);
    }
  }, [videoIndex, challengeVideoList]);

  const getChallengeQuizData = async (params) => {
    try {
      dispatch(fetchChallengeQuiz(params));
    } catch (error) {
      throw new Error(error);
    }
  }

  const getChallengeVideoData = async (params) => {
    try {
      dispatch(fetchChallengeVideos(params));
    } catch (error) {
      throw new Error(error);
    }
  }

  const handleYoutubeModal = (
    videoId = null
  ) => {
    const params = {
      eventId: challengeId,
      challengeId: eventId,
      userId: userId,
      videoId: videoId,
      screenType: 'quiz'
    }
    history.push(`${commonConstant.pathChallengeVideoPlayer}?${getParamsString(params)}`);
  }

  return (
    <>
      <div className="quiz-page">
        <QuizHeader {...quizHeaderProps} />
        {quizRoundAll?.length > 0 &&
          <>
            <div className="container-fluid">
              <div className="quiz-tab-container">
                <Slider ref={tabRef} {...settings}>
                  {quizLoading ? (Array.from({ length: 12 }).map((_, idx) => <RoundChipLoader key={idx} />)) : quizRoundAll?.map((quiz) => (
                    <div key={quiz.quiz_part_id} onClick={() => setActiveIndex(quiz.quiz_part_id)}>
                      <RoundChip isActive={quiz.quiz_part_id === activeIndex} >
                        {quiz?.quiz_part?.quiz_title}
                      </RoundChip>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </>
        }
        {challengeVideoList?.length > 0 &&
          <>
            <div className="quiz-video-container">
              <div className="container-fluid">
                <ChallengeVideoHeader handleSeeAll={handleChallengeVideoRedirection} />
              </div>
              <div className='quiz-card-container-wrapper'>
                <div className="quiz-card-container">
                  <Slider ref={videoCardRef} {...videoSettings}>{videoLoading ? (Array.from({ length: 2 }).map((_, idx) => <ChallengeVideoCardLoader key={idx} />)) : filteredVideos?.map((video) => <ChallengeVideoCard key={video.videoId} videoData={video} openModal={handleYoutubeModal} />)}</Slider>
                </div>
              </div>
            </div>
          </>
        }
        {quizRoundAll?.length > 0 &&
          <>
            <div className={`question-card-container ${isQuizAnswered ? 'question-card-answer-container' : ''}`}>
              <div className="container-fluid">
                <div className="question-card-title">
                  {CHALLENGE.challengeQuiz.quizContainerHeading}
                </div>
                <QuizDetail {...quizDetailProps} />
                {
                  (quizLoading ? (Array.from({ length: 5 }).map((_, idx) => <QuizQuestionCardLoader key={idx} />)) : filteredQuiz?.quiz_question?.map((question, index) =>
                    <QuizQuestionCard
                      key={question?.id}
                      orderNumber={index}
                      question={question}
                      {...quizQuestionCardProps}
                    />))
                }
              </div>
            </div>
          </>
        }
        {quizRoundAll?.length > 0 &&
          <>
            <div ref={componentRef}><HnWSlideup bottomGap={!isQuizAnswered ? '106px' : '0px'} scrollHide={true} /></div>
            {!isQuizAnswered &&
              <div className="quiz-footer-bottom">
                <div className="container-fluid">
                  <ChallengeSubmit updateUserAnswers={updateUserAnswers} enableSubmit={allQuestionsAnswered} />
                </div>
              </div>}
          </>
        }
      </div>
    </>
  );
};

export default ChallengeQuiz;
