import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';

import './TermsCondition.scss';
import {
  updateCustomerPolicy,
  updateTnC,
} from '../../../services/healthWellness/healthWellness.service';
import useViewOnScreen from '../../../hooks/useViewOnScreen';
import { useLocation } from 'react-router-dom';
import commonConstant, {
  TERMS_AND_CONDITIONS,
  policyTermsList,
  policyTitle,
} from '../../../common/commonConstant';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { Customer } from '../../../model/Customer.model';
import { CUSTOMER_FETCH, CUSTOMER_FETCH_LOADER } from '../../../actions/types';
import { useHistory } from 'react-router-dom';
import { fetchPolicies, updatePolicyStatus } from '../../../thunk/hnwHome.thunk';

const { liff } = window;

export const TermsCondition = ({ routeTo }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customer = useSelector((state) => new Customer(state.customer));
  const { policies } = useSelector((state) => state.home);

  const [loading, setLoading] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(true);
  const btnref = useRef(null);
  const isVisible = useViewOnScreen(btnref);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const readOnlyFlag = params.get('readOnly');
  const path = params.get('path');

  const handleScroll = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (isVisible) {
      setShowTopBtn(false);
    } else {
      setShowTopBtn(true);
    }
  }, [isVisible]);

  useEffect(() => {
    fetchPolicy();
  }, []);

  const fetchPolicy = async () => {
    try {
      if (!policies?.length) {
        setLoading(true);
        dispatch(fetchPolicies()).finally(() => setLoading(false));
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const updatePolicy = async () => {
    try {
      const homePolicy = policies.find(
        (el) => el.title.toLowerCase() === policyTitle.home_policy,
      );
      await updateCustomerPolicy({
        privacyId: homePolicy.id,
        status: true,
      });
      customer.isTncAck = true;
      customer.isPolicyAccepted = true;
      dispatch({
        type: CUSTOMER_FETCH,
        payload: customer,
      });
      dispatch({
        type: CUSTOMER_FETCH_LOADER
      })
      dispatch(updatePolicyStatus(true))
      setLoading(false);
      routeTo(path);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const updateAcceptance = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await updateTnC();
      updatePolicy();
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const onTnCReject = (e) => {
    e.preventDefault();
    liff.closeWindow();
  };

  return (
    <>
      <div className="terms-condition-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2 className="terms-condition-title">
                {TERMS_AND_CONDITIONS.title}
              </h2>
            </div>
          </div>
        </div>
        <div className="terms-conditions-content-wrapper">
          <div className="row">
            <div className="col-12">
              <div
                className={`card ${readOnlyFlag === 'true' ? 'card-full' : ''}`}
              >
                <div className="card-content">
                  <p className="content-top-text">
                    {TERMS_AND_CONDITIONS.contentText}
                  </p>
                  <div className="card-list">
                    {policyTermsList.map((el, index) => (
                      <div key={`list${index}`} className="card-list-single">
                        <span className="card-list-dot"></span>
                        <span className="card-list-text">{el}</span>
                      </div>
                    ))}
                  </div>
                  <p>{TERMS_AND_CONDITIONS.cardListText}</p>
                </div>
              </div>
              <div ref={btnref} className="terms-condition-button-group">
                {readOnlyFlag !== 'true' ? (
                  <>
                    <button
                      onClick={(e) => onTnCReject(e)}
                      className="btn btn-secondary btn-secondary-custom mr-10"
                    >
                      <span>{TERMS_AND_CONDITIONS.cancelButtonText}</span>
                    </button>
                    <button
                      className="btn btn-primary btn-primary-tc"
                      onClick={(e) => updateAcceptance(e)}
                    >
                      <span>{TERMS_AND_CONDITIONS.saveButtonText}</span>
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div
            className={`terms-condition-fixed-button${showTopBtn ? '' : ' --hide'
              }`}
          >
            <button
              onClick={handleScroll}
              className="btn btn-primary btn-primary-tc btn-rounded"
            >
              <span>{TERMS_AND_CONDITIONS.scrollButtonText}</span>
              <img src="../images/chevlon-circle-down.svg" />
            </button>
          </div>
        </div>
      </div>
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading"
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
    </>
  );
};
