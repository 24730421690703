import React from "react";
import "./MissionBadgesLoader.scss";

const MissionBadgesLoader = () => {
  return (
    <div className="mission-badge-container">
    <div className="steps-skeleton-container">
    <div className="steps-skeleton-card-container">
      <div className="skeleton-card-image skeleton"></div>
    </div>
  </div>
  </div>
  );
};

export default MissionBadgesLoader;
