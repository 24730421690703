import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Checkbox } from '../../helpers';
import {
  addCouponToCart,
  getCoupons,
  getDateFormat,
} from '../../services/promotion/utils.service';
import InputCoupon from './InputCoupon';

const CouponPanel = (props) => {
  const [coupons, setCoupons] = useState([]);

  const [loading, setLoading] = useState(false);

  const [couponCode, setCouponCode] = useState('');

  const [selected = [], setSelected] = useState([]);
  const [eligibleCoupons, setEligibleCoupons] = useState([]);
  const [nonEligibleCoupons, setNonEligibleCoupons] = useState([]);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const customerFOA = JSON.parse(localStorage.getItem('customerFOAData'));
    if (!customerFOA) {
    const coupons = props.cartData ?  props.cartData?.coupons : [];
    setCoupons(coupons);
    if(coupons && coupons.length > 0) {
      const eligibleCoupons = coupons.filter((item) => {
        return item.cartCouponType === 'redeem'
      })
      const nonEligibleCoupons = coupons.filter((item) => {
        return item.cartCouponType === 'nonRedeem'
      })
      setEligibleCoupons(eligibleCoupons)
      setNonEligibleCoupons(nonEligibleCoupons)
    }
    }
  };

  useEffect(() => {
    if (props.isOpen) {
      fetchData();
    }
    initState();
  }, [props.isOpen]);

  const initState = () => {
    setCouponCode('');
    setSelected(undefined);
  };

  const onCodeChange = (e) => {
    e.preventDefault();
    const couponCode = e.target.value;
    setCouponCode(couponCode);
  };

  const onSubmitCode = async () => {
    if (!couponCode) {
      return alert('โปรดระบุรหัสโค้ดส่วนลด');
    }

    try {
      setLoading(true);
      const couponList = [couponCode];
      const response = await addCouponToCart(couponList, props.cartId);
      if (!response.success) {
        const errorMessage = response.error[0].th_message;
        alert(errorMessage);
        setLoading(false);
        return;
      }
      setLoading(false);
      props.onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (value) => {
    const index = selected.indexOf(value);
    if (index >= 0) {
      selected.splice(index, 1);
    } else {
      selected.push(value);
    }
    setSelected([...selected]);
  };

  const onSubmit = async () => {
    if (!selected) {
      return alert('คุณยังไม่ได้เลือกคูปอง');
    }
    try {
      setLoading(true);
      const response = await addCouponToCart(selected, props.cartId);
      if (!response.success) {
        const errorMessage = response.error[0].th_message;
        alert(errorMessage);
        setLoading(false);
        return;
      }
      setLoading(false);
      props.onClose();
    } catch (error) {
      console.log(error);
    }
  };


  const renderCouponContainer = (value, index) => {
    return (
      <div key={index} className={`coupon-container bg-coupon ${(value.cartCouponType === 'nonRedeem') ? 'disable-coupon': ''}`}>
            <div className="dark-red coupon-detail">
              <div className="coupon-title">{`${value.name}`}</div>
              <div className="coupon-desc">{`ลด ${value.value}`}</div>
            </div>
            <div className="box-right">
              <div className="dark-red text-stock">
                <div className="text-center">
                  <button className={`away-button coupon-button ${selected.includes(value.code) ? 'coupon-button-inactive': (value.cartCouponType === 'nonRedeem') ? 'disable-coupon-button' : 'coupon-button-active'}`} 
                  onClick={() => (value.cartCouponType === 'redeem') ? handleSelect(value.code):  null} >{`${selected.includes(value.code) ? 'เลือกแล้ว' : 'เลือก' }`}</button>
                </div>
                <div className="coupon-date">{`ใช้ได้ถึง ${getDateFormat(value.endDate)}`}</div>
              </div>
            </div>
          </div>
    )
  }

  const renderCoupons = (couponList) => {
    return (
      Array.isArray(couponList) &&
      couponList.length > 0 &&
      couponList.map((value, index) => {
        return (
          renderCouponContainer(value, index)
        );
      })
    );
  };

  return (
    <section>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      <div className="content">
        {/* <InputCoupon cartId={props.cartId} callback={props.onClose} /> */}
        <div
          className="scroll"
          style={{ height: 'calc(100vh - 256px)' }}
        >
          {Array.isArray(eligibleCoupons) && eligibleCoupons.length > 0 && 
            <>
              <div className="label font-stock">{'คูปองที่ใช้ได้ในตะกร้านี้'}</div>
              {renderCoupons(eligibleCoupons)}
            </>
          }
          {Array.isArray(nonEligibleCoupons) && nonEligibleCoupons.length > 0 && 
            <>
              <div className="label disable-label font-stock">{'คูปองทั้งหมด'}</div>
              {renderCoupons(nonEligibleCoupons)}
            </>
          }
        </div>
      </div>
        <div className="product-panel-footer">
          <div className="select-promotion-footer font-larger">
          <div className="text-center">
            <button className={`away-button active`} onClick={() => onSubmit()}>{'สั่งซื้อสินค้า'}</button>
          </div>
          </div>
        </div>
    </section>
  );
};

export default CouponPanel;
