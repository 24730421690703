import React, { useEffect, useState } from 'react';
import './ChallengeList.scss';
import ChallengeCarousel from '../challengeCarousel/ChallengeCarousel';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChallengeList, fetchChallengeProfile } from '../../../actions';
import { CHALLENGE_LIST } from '../../../common/challengeConstants';
import ChallengeDefault from './ChallengeDefault';
import { getDataFromSession, getParamsString } from '../../../utill.func';
import HnwFooter from '../../../common/hnwFooter/HnwFooter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { commonConstant } from '../../../common';
import { FETCH_CHALLENGES_DETAIL_SUCCESS, SET_FOOTER_STATE_CHALLENGE } from '../../../actions/types';
import { setChallengeType } from '../../../actions/hnwFooterAction';
import { getFacadeToken, setFacadeToken } from '../../../services';
import { getFacadeAccessToken } from '../../../services/promotion/auth.service';

const ChallengeDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isTokenFetched, setIsTokenFetched] = useState(false);

  const challengeList = useSelector(
    (state) => state.challengeListObj?.challengeList
  );
  const challengeProfileList = useSelector(
    (state) => state.challengeListObj?.challengeProfile?.profileList
  );

  const { isAuthTokenSaved } = useSelector(
    (state) => state.tokenStatus,
  );

  const upcomingChallenge = challengeList?.upcoming;
  const ongoingChallenge = challengeList?.ongoing;
  
  const noChallenge =
    (!upcomingChallenge || upcomingChallenge.length === 0) &&
    (!ongoingChallenge || ongoingChallenge.length === 0);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const facadeToken = await getFacadeToken();
        if (!facadeToken) {
          const accessTokenObj = await getFacadeAccessToken();
          if(accessTokenObj?.access_token) {
            setFacadeToken(accessTokenObj);
          }
        }
      } catch (error) {
        console.error("Error fetching tokens:", error);
      } finally {
        setIsTokenFetched(true);
      }
    };
  
    fetchTokens();
  }, []);

  useEffect(() => {
    const fetchChallenge = async (type) => {
      setIsLoading(true);
      const paramsObj = {
        challengeStatus: type,
      };
      await dispatch(fetchChallengeList(paramsObj));
      setIsLoading(false);
    };

    const fetchProfile = async(eventIdList) => {
      const {partyId, amwayNumber} = getDataFromSession(
        'customer'
      );
      const payloadObj = {
        partyId,
        aboId: amwayNumber,
        eventIds: eventIdList
      }
      setIsProfileLoading(true);
      await dispatch(fetchChallengeProfile(`?${getParamsString(payloadObj)}`));
      setIsProfileLoading(false);
    }

    if (!challengeList?.ongoing && isAuthTokenSaved && isTokenFetched) {
      fetchChallenge('ongoing');
    }
    if (!challengeList?.upcoming && isAuthTokenSaved && isTokenFetched) {
      fetchChallenge('upcoming');
    }
    if (ongoingChallenge?.length > 0 && !challengeProfileList && isAuthTokenSaved && isTokenFetched) {
      const eventIdList = ongoingChallenge
        .filter((challenge) => challenge.eventId)
        .map((challenge) => challenge.eventId)
        .join(',');
      fetchProfile(eventIdList);
    }
  }, [challengeList, isAuthTokenSaved, isTokenFetched]);

  useEffect(() => {
    dispatch(setChallengeType(SET_FOOTER_STATE_CHALLENGE));
  },[])

  const handleCardClick = async (cardObj, isRegistered) => {
    const { id, eventId, challengeStatus: type, completeDate, teamDashboardImage } = cardObj;
    // TODO: need to store in localstorage for camera screen's navigate back
    await dispatch({
      type: FETCH_CHALLENGES_DETAIL_SUCCESS,
      payload: cardObj,
    });
    sessionStorage.setItem("challengeData", JSON.stringify({challengeId: eventId, challengeEventId: id, challengeRewardDate: completeDate, myDashboardBg: teamDashboardImage}));
    let paramsObj = {};
    if (type===CHALLENGE_LIST.TYPES.upcoming || (type===CHALLENGE_LIST.TYPES.ongoing && !isRegistered)) {
      paramsObj = {
        challengeId: id,
        isRegistered
      }
      history.push(`${commonConstant.pathChallengeDetail}?${getParamsString(paramsObj)}`);
    }
    else if(type===CHALLENGE_LIST.TYPES.ongoing && isRegistered) {
      paramsObj = {
        challengeId : eventId,
        eventId : id
      }
      history.push(`${commonConstant.pathChallengeTeamDashboard}?${getParamsString(paramsObj)}`);
    }
  }

  return (
    <div>
      {!isLoading && noChallenge ? (
        <ChallengeDefault />
      ) : (
        <>
          <div className="challenge-list-main">
            <ChallengeCarousel
              title={CHALLENGE_LIST.TITLE_MAP.ongoing}
              carouselList={challengeList?.ongoing}
              
              isLoading={isLoading}
              challengeProfileList={challengeProfileList} 
              isProfileLoading={isProfileLoading}
              showTag
              onCardClick={handleCardClick}
            />
            <ChallengeCarousel
              title={CHALLENGE_LIST.TITLE_MAP.upcoming}
              carouselList={challengeList?.upcoming}
              
              isLoading={isLoading}
              onCardClick={handleCardClick}
            />
          </div>
          <HnwFooter />
        </>
      )}
    </div>
  );
};

export default ChallengeDetails;
