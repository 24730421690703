export class Policy {
    constructor(data) {
        if (data) {
            this.id = data._id || '';
            this.title = data.title || '';
            this.type = data.type || '';
            this.description = data.description || '';
            this.createdDate = data.createdDate || '';
            this.updatedDate = data.updatedDate || '';
        }
    }
}