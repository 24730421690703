// App.js
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './GoalsSearchHistory.scss';
import SearchTitle from '../../foodCalories/components/hnwSearchHistory/searchTitle/HnwSearchTitle';
import {
  CalendarLoader,
  FoodListingItem,
  HnWSearchMenu,
  InfiniteScroll,
  SearchBar,
  Slideup,
} from '../../../../common';
import { getParamsString, routeTo } from '../../../../utill.func';
import {
  emptyProductList,
  fetchRecommendedProducts,
  searchProducts,
} from '../../../../thunk/foodCalories.thunk';
import {
  FOOD_TABS_MAPPING,
  routePathKey,
} from '../../../../common/commonConstant';
import {
  MAX_LENGTH,
  MIN_LENGTH,
  PAGE_SIZE,
  SEARCH_TITLES,
} from '../../../../constants';

const GoalsSearchHistory = ({ routeTo }) => {
  const dispatch = useDispatch();
  const { searchedProducts, recommendedProducts } = useSelector(
    (state) => state.foodCalorie,
  );
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [showAllList, setShowAllList] = useState(false);
  const [pageStart, setPageStart] = useState({
    recommendedProducts: 0,
    searchedProducts: 0,
  });
  const searchRef = useRef(null);
  const searchInputRef = useRef(null);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const referrerPage = params.get('referrer');
  const referrerFrom = params.get('referrerFrom');
  const openSearch = params.get('openSearch');
  const timeoutHandler = useRef(null);

  useEffect(() => {
    if (openSearch) {
      searchInputRef.current.focus();
      setIsSearchOpen(true);
    }
  }, [openSearch]);

  const getRecommendedProducts = () => {
    setPageStart((start) => ({
      ...start,
      recommendedProducts: start.recommendedProducts + PAGE_SIZE,
    }));
    const recordsTotal = recommendedProducts.recordsTotal;
    const loadedDataLength = recommendedProducts.data.length;
    if (!loadedDataLength || recordsTotal > loadedDataLength) {
      dispatch(
        fetchRecommendedProducts({
          isRecommended: true,
          limit: PAGE_SIZE,
          start: pageStart.recommendedProducts,
          type: FOOD_TABS_MAPPING.Supplement.name,
        }),
      ).finally(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleCloseMenu);
    const fetchLoadData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          dispatch(
            fetchRecommendedProducts({
              type: FOOD_TABS_MAPPING.Supplement.name,
              isRecommended: true,
            }),
          ),
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchLoadData();
    return () => {
      document.removeEventListener('click', handleCloseMenu);
    };
  }, []);

  const handleCloseMenu = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setIsSearchOpen(false);
    }
  };

  const debounce = (callback, delay) => {
    return function (...args) {
      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
      }
      timeoutHandler.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  // Debounced search function
  const handleSearch = debounce((searchQuery) => {
    setSearchKey(searchQuery);
    dispatch(
      searchProducts({
        limit: 6,
        search: searchQuery,
        type: FOOD_TABS_MAPPING.Supplement.name,
      }),
    ).finally(() => {
      setShowAllList(true);
    });
  }, 1000);

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    if (searchQuery) {
      setIsSearchOpen(true);
      handleSearch(searchQuery);
    } else {
      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
      }
      dispatch(emptyProductList());
    }
  };

  const handleSearchClick = () => {
    if (!searchInput) {
      dispatch(emptyProductList());
    }
    setIsSearchOpen(true);
  };

  const handleListClick = (data, flag) => {
    if (flag) {
      let params = {
        id: data.id,
        search: searchKey,
      };
      if (referrerPage) {
        params = {
          ...params,
          referrer: referrerPage,
          ...(referrerFrom && { referrerFrom }),
        };
      }
      routeTo(routePathKey.foodDetail, `?${getParamsString(params)}`);
    } else {
      dispatch(emptyProductList());
      setLoading(true);
      fetchProducts({
        search: searchKey,
        type: FOOD_TABS_MAPPING.Supplement.name,
      });
      setIsSearchOpen(false);
    }
  };

  const handleProductClick = (item) => {
    let params = {
      id: item.id,
    };
    if (referrerPage) {
      params = {
        ...params,
        referrer: referrerPage,
        ...(referrerFrom && { referrerFrom }),
      };
    }
    routeTo(routePathKey.foodDetail, `?${getParamsString(params)}`);
  };

  const fetchProducts = (params) => {
    dispatch(searchProducts(params)).then((res) => {
      if (!res?.data?.length) {
        setNoResult(true);
      } else {
        setShowList(true);
        setNoResult(false);
      }
      setLoading(false);
    });
  };

  return (
    <>
      <div className="goal-search-history-page">
        <div className="container-fluid p-0 banner-container">
          <div ref={searchRef} className="search-img-container">
            <div className="container-fluid">
              <SearchBar
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                ref={searchInputRef}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                minLength={MIN_LENGTH}
                maxLength={MAX_LENGTH}
              />
              {isSearchOpen && searchInput && (
                <HnWSearchMenu
                  searchedProducts={searchedProducts?.data}
                  searchInput={searchInput}
                  handleListClick={handleListClick}
                  showAllList={showAllList}
                />
              )}
            </div>
          </div>
          <div className="search-category-container">
            <div className="container-fluid">
              {!isSearchOpen && !noResult ? (
                showList && searchedProducts?.data?.length ? (
                  <div className="product-suggestion-container">
                    <SearchTitle title={SEARCH_TITLES.list} />
                    <div className="dietary-supplement-section-wrapper">
                      {searchedProducts.data.map((item) => (
                        <FoodListingItem
                          key={item.id}
                          item={item}
                          handleClick={handleProductClick}
                        />
                      ))}
                    </div>
                  </div>
                ) : null
              ) : null}
              {noResult && (
                <div className="empty-list-state">
                  {
                    <img
                      src="/images/healthAndWellness/goals/common/no_supplement_result.svg"
                      alt="No Supplements Found Image"
                    />
                  }
                </div>
              )}
              {!isSearchOpen &&
                noResult &&
                recommendedProducts?.data?.length ? (
                <div className="product-suggestion-container">
                  <SearchTitle title={SEARCH_TITLES.supp_recommended} />
                  <div className="dietary-supplement-section-wrapper">
                    <InfiniteScroll
                      items={recommendedProducts.data}
                      itemComponent={FoodListingItem}
                      itemProps={{ handleClick: handleProductClick }}
                      fetchData={getRecommendedProducts}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Slideup />
      <CalendarLoader showLoading={loading} />
    </>
  );
};

export default GoalsSearchHistory;
