import { PUBLISH_STATUS } from '../common/commonConstant';
import {
  getGoalsWeight,
  postGoalsWeight,
  getGoalsWater,
  postGoalsWater,
  getGoalsNutrition,
  postGoalsNutrition,
  getGoalsSupplements,
  createGoalsSupplements,
  postGoalsSupplements,
  getActivityGoal,
  putActivityGoal,
  postActivityGoal,
  getMenuByExerciseId
} from '../services/healthWellness/hnwGoals.service';
import {
  GET_WEIGHT_GOAL,
  UPDATE_WEIGHT_GOAL,
  GET_WATER_GOAL,
  UPDATE_WATER_GOAL,
  GET_NUTRITION_GOAL,
  UPDATE_NUTRITION_GOAL,
  GET_SUPPLEMENTS_GOAL,
  UPDATE_SUPPLEMENTS_GOAL,
  GET_ACTIVITY_GOAL,
  GET_EXERCISE_MENU_GOAL,
  RESET_EXERCISE_MENU_GOAL,
  PUT_ACTIVITY_GOAL,
  POST_ACTIVITY_GOAL
} from './types';

export const fetchGoalsWeight = (weightGoal_id) => (dispatch) => getGoalsWeight(weightGoal_id)
  .then((rest) => {
    dispatch({
      type: GET_WEIGHT_GOAL,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const updateGoalsWeight = (params) => (dispatch) => postGoalsWeight(params)
  .then((rest) => {
    dispatch({
      type: UPDATE_WEIGHT_GOAL,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const updateWeight = (payload = null) => dispatch => dispatch({
  type: UPDATE_WEIGHT_GOAL,
  payload
});

export const fetchGoalsWater = (waterGoal_id) => (dispatch) => getGoalsWater(waterGoal_id)
  .then((rest) => {
    dispatch({
      type: GET_WATER_GOAL,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const updateGoalsWater = (params) => (dispatch) => {
  postGoalsWater(params).then((result) => {
    if (result.data) {
      dispatch({
        type: UPDATE_WATER_GOAL,
        payload: result.data,
        hasError: false
      });
    } else {
      dispatch({
        type: UPDATE_WATER_GOAL,
        payload: result.error,
        hasError: true
      });
    }
    return result;
  })
    .catch(function (error) {
      alert(error.message);
    });
};

export const fetchGoalsNutrition = (nutritionGoal_id) => (dispatch) => getGoalsNutrition(nutritionGoal_id)
  .then((rest) => {
    dispatch({
      type: GET_NUTRITION_GOAL,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const updateGoalsNutrition = (params) => (dispatch) => postGoalsNutrition(params)
  .then((rest) => {
    dispatch({
      type: UPDATE_NUTRITION_GOAL,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const fetchGoalsSupplements = () => (dispatch) => getGoalsSupplements()
  .then((rest) => {
    dispatch({
      type: GET_SUPPLEMENTS_GOAL,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const addGoalsSupplements = (params) => (dispatch) =>
  createGoalsSupplements(params)
    .then((result) => {
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    })



export const updateGoalsSupplements = (params) => (dispatch) =>
  postGoalsSupplements(params).then((result) => {
    if (result.data) {
      dispatch({
        type: UPDATE_SUPPLEMENTS_GOAL,
        payload: result.data,
        hasError: false
      });
    } else {
      dispatch({
        type: UPDATE_SUPPLEMENTS_GOAL,
        payload: result.error,
        hasError: true
      });
    }
    return result;
  })
    .catch(function (error) {
      alert(error.message);
    });

export const fetchActivityGoalAction = (customerId) => (dispatch) => getActivityGoal(customerId)
  .then((payload) => {
    dispatch({
      type: GET_ACTIVITY_GOAL,
      payload,
    });
    return payload;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const fetchMenuByExerciseId = (exerciseId) => (dispatch) => getMenuByExerciseId(exerciseId, { status: PUBLISH_STATUS.PUBLISHED })
  .then((payload) => {
    dispatch({
      type: GET_EXERCISE_MENU_GOAL,
      payload,
    });
    return payload;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const createActivityGoal = (payload) => (dispatch) => postActivityGoal(payload)
  .then((payload) => payload)
  .catch(function (error) {
    alert(error.message);
  });

export const updateActivityGoal = (payload) => (dispatch) => putActivityGoal(payload)
  .then((payload) => payload)
  .catch(function (error) {
    alert(error.message);
  });

export const resetExerciseMenu = (dispatch) => dispatch({ type: RESET_EXERCISE_MENU_GOAL });
