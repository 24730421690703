import React from 'react';
import './DashboardFooter.scss'
import { ChallengeButton } from '../../../../common';

const DashboardFooter = ({firstButtonTitle, secondButtonTitle, onFirstBtnClick, onSecondBtnClick}) => {
    return (
        <div className='team-dashboard-footer-container'>
           <ChallengeButton btnTitle={firstButtonTitle} onBtnClick={() => onFirstBtnClick()}/>
           <ChallengeButton btnTitle={secondButtonTitle} successBtn={true} onBtnClick={() => onSecondBtnClick()}/>
        </div>
    )
}

export default DashboardFooter