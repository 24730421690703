import React, { useState } from 'react';
import Picker from 'react-mobile-picker';
import './MobilePicker.scss';
import HnWNumpadModal from '../hnWNumpadModal/HnWNumpadModal';
import { SAVE_BUTTON_MODAL } from '../commonConstant';

export default function MobilePicker(
  {
    // Use following sample date for usage reference
    defaultValue = {
      // text1: 'Sample1',
      // text2: 'SampleB',
      // text3: 'SampleR'
    },
    selections = {
      // text1: ['Sample1', 'Sample2', 'Sample3'],
      // text2: ['SampleA', 'SampleB', 'SampleC'],
      // text3: ['SampleP', 'SampleQ', 'SampleR']
    },
    onOKClick = () => { },
    disabled,
    onChange,
    isOpen,
    pickerHeader,
    modalHeight,
    pickerHeight = modalHeight && modalHeight - 100,
    showSelected = true
  }) {


  const [pickerValue, setPickerValue] = useState(defaultValue);

  const handleOKClick = () => {
    !disabled && onOKClick(pickerValue);
  }

  const HandlePickerChange = (...obj) => {
    setPickerValue(...obj);
    onChange && onChange(...obj);
  }

  return (
    <div className="modal-picker">
      <HnWNumpadModal openNumpad={isOpen} onBackgroundClick={handleOKClick}>
        <div className="container-fluid">
          <div className="header-btn">
            <button className='btnClass' disabled={disabled} onClick={() => handleOKClick()}>{SAVE_BUTTON_MODAL}</button>
          </div>

        </div>
        <div className="header-section">
          <div className="header">
            {pickerHeader}
          </div>

        </div>
        <Picker value={pickerValue} onChange={HandlePickerChange} height={pickerHeight} itemHeight={42.58} className={`modal-picker-wrapper${disabled ? ' --disabled' : ''}`}>
          {Object.keys(selections).map(name => (
            <Picker.Column key={name} name={name} className='modal-picker-column'>
              {selections[name].map(option => (
                <Picker.Item key={option} value={option}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected item */
                    <div className={`modal-picker-item${selected && showSelected ? ' --selected' : ''}`}>
                      {option}
                    </div>
                  )}
                </Picker.Item>
              ))}
            </Picker.Column>
          ))}
        </Picker>
      </HnWNumpadModal>
    </div>)
}
