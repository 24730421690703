export class HistorySearchKeywords {
    constructor(data) {
        if (data) {
            this.id = data._id || '';
            this.name = data.name || '';
            this.customers = data.customers || '';
            this.createdDate = data.createdDate || '';
            this.updatedDate = data.updatedDate || '';
        }
    }
}