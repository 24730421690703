import React from 'react';
import { Modal, Button } from 'antd';
import './modal.scss';
import { useHistory } from 'react-router-dom';
import { commonConstant } from '../../../common';

const ModalContentRemoveSuccess = (props) => {
  const { visibleModal, setVisibleModal, content } = props;
  const history = useHistory();

  const handleCancel = () => {
    history.push({
      pathname: commonConstant.pathLibrarySubContentList,
      search: `id=${content[0]?.category._id}`,
      state: { isAmwayContent: false },
    });
    setVisibleModal(false);
  };

  const addContent = () => {
    setVisibleModal(false);
    history.push({
      pathname: commonConstant.pathLibraryContentForm,
      state: { category: content[0]?.category._id },
    });
  };

  return (
    <Modal
      className="warp-modal"
      open={visibleModal}
      centered
      footer={null}
      onCancel={handleCancel}
      closable={true}
      maskClosable={false}
    >
      <div className="modal-image-icon">
        <img src="../../images/library/icon_delete_done.svg"></img>
      </div>
      <div className="modal-title">ลบสำเร็จ</div>
      <div className="modal-desc">
        หากต้องการสร้าง Category ใหม่ <br />
        กรุณา เลือก + Add category ค่ะ
      </div>
      <Button
        className="btn"
        onClick={() => {
          addContent();
        }}
        block
      >
        + Add Content
      </Button>
    </Modal>
  );
};
export default ModalContentRemoveSuccess;
