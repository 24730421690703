import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";

import { commonConstant } from '../../common';
import config from "../../config"
import { customerService } from "../../services";

export class LoginSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      loading: true,
      redirect: null,
    }
  }
  async componentDidMount() {
    if (sessionStorage.getItem("customer") === null || sessionStorage.getItem("customer") === "undefined") {
      return window.location.replace(commonConstant.pathHome);
    }

    const customer = JSON.parse(sessionStorage.getItem("customer"));
      const params = {
        action: "LOGIN_COMPLETE",
      };
      await customerService.update(customer._id, params);
    customerService.get(customer.uid).then((res) => {
      this.setState({
        userInfo: res,
        loading: false,
      })
    });
  }

  handleButtonInterest = async () => {
    if (sessionStorage.getItem("customer") === null || sessionStorage.getItem("customer") === "undefined") {
      return window.location.replace(commonConstant.pathHome);
    }

    const customer = JSON.parse(sessionStorage.getItem("customer"));
    const params = {
      action: "SELECT_PRODUCT",
    };
    await customerService.update(customer._id, params);
    this.setState({
      redirect: '/interest'
    })
  }

  render() {
    const { redirect } = this.state;
    if(redirect){
      return <Redirect to={redirect} />
    }
    if (this.state.loading) {
      return <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: "100%", minHeight: "100%" } }} />
    }
    const { pictureUrl, displayName, amwayNumber, type } = this.state.userInfo;
    return (
      <div className="Success">
        {/* <div className="logoAmway">
          <img src="/images/logoAmway-black.svg" alt="" />
        </div> */}
        <h1>หมายเลขสมาชิกแอมเวย์ของคุณ</h1>
        <div className="picture">
          <img src={pictureUrl || config.AVATAR_DEFAULT} alt="" />
        </div>
        <h2 className="name">คุณ {displayName}</h2>
        {
          amwayNumber ?
            <>
              <small>{type === 'abo' ? 'หมายเลขนักธุรกิจ' : 'หมายเลขสมาชิก'}</small>
              <h2 className="away-num">{amwayNumber ? amwayNumber : '-'}</h2>
            </> : null
        }
        <div className="footer">
          <button onClick={this.handleButtonInterest} className="away-button active">ลงทะเบียนต่อ</button>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ profile }) {
  return { profile };
}

export default connect(mapStateToProps)(LoginSuccess);
