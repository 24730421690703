import config from "../config";

export class FoodLog {
    constructor(data = null) {
        if (data) {
            this.id = data._id || '';
            this.customers = data.customers || '';
            this.createdDate = data.createdDate || '';
            this.updatedDate = data.updatedDate || '';
            this.breakfast = data.breakfast?.length ? data.breakfast.map(el => new Food({...el.myFoodMenu, myFoodWeightId: el?._id,myFoodWeight:el?.myFoodWeight})) : [];
            this.lunch = data.lunch?.length ? data.lunch.map(el => new Food({...el.myFoodMenu, myFoodWeightId: el?._id,myFoodWeight:el?.myFoodWeight})) : [];
            this.dinner = data.dinner?.length ? data.dinner.map(el => new Food({...el.myFoodMenu, myFoodWeightId: el?._id,myFoodWeight:el?.myFoodWeight})) : [];
            this.dietarySupplement = data.dietarySupplement?.length ? data.dietarySupplement.map(el => new Food({...el.myFoodMenu, myFoodWeightId: el?._id,myFoodWeight:el?.myFoodWeight})) : [];
        }
    }
}

export class Food {
    constructor(data = null) {
        if (data) {
            this.id = data._id || '';
            this.name = data.name || '';
            this.productCategory = data.productCategory || '';
            this.unit = data.unit || '';
            this.image = data.image || '';
            this.imageUrl = this.image ? config.S3_URL + this.image : '';
            this.amount = data.amount || '';
            this.weight = data.weight || '';
            this.carbs = data.carbs || '';
            this.calorie = data.calorie || '';
            this.protein = data.protein || '';
            this.fat = data.fat || '';
            this.myFoodWeightId = data.myFoodWeightId || '';
            this.myFoodWeight =data.myFoodWeight || this.weight;
            this.createdDate = data.createdDate || '';
            this.updatedDate = data.updatedDate || '';
        }
    }
}