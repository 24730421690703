import React from 'react';
import { Modal, Button } from 'antd';
import './modal.scss';
import { useHistory } from 'react-router-dom';
import { deleteAboCategory } from '../../../services/library/categories/categories.service';

const ModalCategoryRemove = (props) => {
  const {
    visibleModal,
    setVisibleModal,
    setVisibleModalRemoveSuccess,
    category,
    setLoading,
  } = props;
  const history = useHistory();

  const handleCancel = () => {
    setVisibleModal(false);
  };

  const confirmRemove = async () => {
    setLoading(true);
    await deleteAboCategory(category._id);
    setVisibleModal(false);
    setVisibleModalRemoveSuccess(true);
    setLoading(false);
  };

  return (
    <Modal
      className="warp-modal"
      open={visibleModal}
      centered
      footer={null}
      onCancel={handleCancel}
      closable={true}
      maskClosable={false}
    >
      <div className="modal-image-icon">
        <img src="../../images/library/icon_confirm_delete_category.svg"></img>
      </div>
      {category && (
        <div className="modal-title">ต้องการลบ {category.name} ?</div>
      )}
      <div className="modal-desc">
        กรุณากดปุ่ม “ยืนยัน” เพื่อลบ Category นี้ <br />
        ซึ่งหากลบแล้ว Contents ที่อยู่ภายใต้ Category นี้จะหายไปทั้งหมด
      </div>
      <Button
        className="btn"
        onClick={() => {
          confirmRemove();
        }}
        block
      >
        ยืนยัน
      </Button>
      <Button
        type="link"
        className="link"
        onClick={() => {
          handleCancel();
        }}
        block
      >
        ยกเลิก
      </Button>
    </Modal>
  );
};
export default ModalCategoryRemove;
