import React from 'react'

const HistoryKeywords = ({ selectedHistoryIndex, historyKeywords, historyClick }) => {
    return (
        <div className='history-keyword-container'>
            {
                historyKeywords.map((el, index) => {
                    return (
                        <div key={`history-keywords${index}`} onClick={() => historyClick(el, index)}
                            className={`history-keyword-content${selectedHistoryIndex === index ? ' active' : ''}`}>
                            <img src='/images/healthAndWellness/foodCalories/search-history/history.svg' />
                            <div className="sh-category-card-title">
                                {el.name}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default HistoryKeywords