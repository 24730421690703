import { PUBLISH_STATUS } from '../common/commonConstant';
import {
  getExerciseLogDetails,
  postExerciseLog,
  putExerciseLog,
  getExerciseTimeLogs,
  getExerciseMenuAndTemplatesById,
  getExerciseLogById,
  getPrevExerciseLogById,
  deleteExerciseLogById,
  getExerciseMenuListById,
  getExerciseCategories,
  getExerciseSuggestedMenuList,
  getUserActivityGoal,
} from '../services/healthWellness/exerciseLog.service';
import {
  CREATE_EXERCISE_LOG_TYPE,
  GET_EXERCISE_LOG_BY_ID_TYPE,
  GET_PREV_EXERCISE_LOG_BY_ID_TYPE,
  DELETE_EXERCISE_LOG_TYPE,
  GET_EXERCISE_MENU_AND_TEMPLATES_TYPE,
  UDPATE_EXERCISE_LOG_TYPE,
  GET_EXERCISE_TIMELOG_LIST_TYPE,
  GET_EXERCISE_CATEGORY_LIST_TYPE,
  GET_EXERCISE_MENU_LIST_TYPE,
  GET_SUGGESTED_EXERCISE_MENU_LIST_TYPE,
  GET_USER_ACTIVITY_GOAL,
  CLEAR_EXERCISE_LOG_TYPE,
  GET_EXERCISE_TIMELOG_LIST_TYPE_FAILURE,
  GET_EXERCISE_TIMELOG_LIST_TYPE_SUCCESS,
  GET_USER_ACTIVITY_GOAL_SUCCESS,
  GET_USER_ACTIVITY_GOAL_FAILURE,
} from './types';

export const clearExerciseLogData = () => (dispatch) => dispatch({ type: CLEAR_EXERCISE_LOG_TYPE });

export const fetchExerciseLogDetails = () => (dispatch) =>
  getExerciseLogDetails()
    .then((rest) => {
      dispatch({
        payload: rest,
      });
      return rest;
    })
    .catch((error) => {
      throw error;
    });

export const createExerciseLog = (payload) => (dispatch) =>
  postExerciseLog(payload)
    .then((res) => {
      dispatch({
        type: CREATE_EXERCISE_LOG_TYPE,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      throw error;
    });

export const updateExerciseLog =
  (exerciseLogId, requestPayload) => (dispatch) =>
    putExerciseLog(exerciseLogId, requestPayload)
      .then((res) => {
        dispatch({
          type: UDPATE_EXERCISE_LOG_TYPE,
          payload: res,
        });
        return res;
      })
      .catch((error) => {
        throw error;
      });

export const fetchExerciseMenuAndTemplatesById =
  (exerciseMenuId) => (dispatch) =>
    getExerciseMenuAndTemplatesById(exerciseMenuId)
      .then((res) => {
        dispatch({
          type: GET_EXERCISE_MENU_AND_TEMPLATES_TYPE,
          payload: res,
        });
        return res;
      })
      .catch((error) => {
        throw error;
      });

export const fetchExerciseTimeLogs = (request) => (dispatch) => {
  dispatch({ type: GET_EXERCISE_TIMELOG_LIST_TYPE });

  return getExerciseTimeLogs(request)
    .then((res) => {
      dispatch({
        type: GET_EXERCISE_TIMELOG_LIST_TYPE_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_EXERCISE_TIMELOG_LIST_TYPE_FAILURE,
        payload: error.message,
      });
      alert(error.message);
    });

}


export const fetchExerciseLogById = (exerciseLogId) => (dispatch) =>
  getExerciseLogById(exerciseLogId)
    .then((res) => {
      dispatch({
        type: GET_EXERCISE_LOG_BY_ID_TYPE,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      throw error;
    });

export const fetchPrevExerciseLogById = (request) => (dispatch) =>
  getPrevExerciseLogById(request.excerciseCatId, request.type)
    .then((res) => {
      dispatch({
        type: GET_PREV_EXERCISE_LOG_BY_ID_TYPE,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      throw error;
    });

export const removeExerciseLogById = (exerciseLogId) => (dispatch) =>
  deleteExerciseLogById(exerciseLogId)
    .then((res) => {
      dispatch({
        type: DELETE_EXERCISE_LOG_TYPE,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      throw error;
    });

export const fetchUserActivityGoal = (customerId) => (dispatch) =>{

  dispatch({ type: GET_USER_ACTIVITY_GOAL });

  return getUserActivityGoal(customerId)
    .then(( res ) => {
      dispatch({
        type: GET_USER_ACTIVITY_GOAL_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_USER_ACTIVITY_GOAL_FAILURE,
        payload: error.message,
      });
      alert(error.message);
    });

  }


export const fetchExerciseCategories = () => (dispatch) =>
  getExerciseCategories()
    .then((res) => {
      dispatch({
        type: GET_EXERCISE_CATEGORY_LIST_TYPE,
        payload: res,
      });
      return res;
    })
    .catch(function (error) {
      throw error;
    });

export const fetchExerciseMenuListById = (exerciseCatId) => (dispatch) =>
  getExerciseMenuListById(exerciseCatId, { status: PUBLISH_STATUS.PUBLISHED })
    .then((res) => {
      dispatch({
        type: GET_EXERCISE_MENU_LIST_TYPE,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      throw error;
    });

export const fetchExerciseSuggestedMenuList = () => (dispatch) =>
  getExerciseSuggestedMenuList({ status: PUBLISH_STATUS.PUBLISHED })
    .then((res) => {
      dispatch({
        type: GET_SUGGESTED_EXERCISE_MENU_LIST_TYPE,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      throw error;
    });
