import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select, { components } from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';

import './CreateAddress.scss';

import { PlacePicker, Modal } from '../../../helpers';
import { commonConstant } from '../../../common';
import { addNewAddress, setAddNewAddress, setAddress as setAddressRedux } from '../../../actions';
import { addressService } from '../../../services';

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>{children}</components.ValueContainer>
);

const SelectCustom = ({ options, onChange, value }) => {
  return <Select classNamePrefix="react-select"
    options={options}
    menuShouldScrollIntoView={true}
    onChange={(e) => onChange(e)}
    placeholder=""
    className="visible"
    components={<ValueContainer />}
    styles={{
      indicatorSeparator: styles => ({ ...styles, display: 'none' }),
      control: styles => ({
        ...styles,
        borderRadius: 0,
        borderTop: '0px',
        borderRight: '0px',
        borderLeft: '0px',
        boxShadow: 'none',
      }),
      valueContainer: styles => ({ ...styles, padding: '0px' }),
      // placeholder: styles => ({ ...styles, maxWidth: 'calc(100% - 32px)' }),
      // singleValue: styles => ({ ...styles, maxWidth: 'calc(100% - 32px)' }),
      input: styles => ({ ...styles, margin: '0px', padding: '0px' }),
    }}
    value={value}
  />
}

export const CreateAddress = ({ location }) => {
  const defaultOption = { label: 'เลือก', value: '' };
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [address, setAddress] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(defaultOption);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(defaultOption);
  const [subDistricts, setSubDistricts] = useState([]);
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(defaultOption);
  const { customer, addAddress } = useSelector(state => state);
  const [isFetchPlace, setIsFetchPlace] = useState(false)

  const handlePlaceSelect = place => {
    setOpenMap(false);
    setIsFetchPlace(true);
    if (!place) return
    //เลขที่
    const street = place.address_components.find(obj => obj.types.indexOf('street_number') !== -1);
    //ถนน
    const route = place.address_components.find(obj => obj.types.indexOf('route') !== -1);
    const combinedStreet = `${(street?.long_name || '')} ${(route?.long_name && route.long_name !== 'Unnamed Road' ? route.long_name : '')}`.trim();
    // แขวงหรือตำบล
    const sub = place.address_components.find(obj => obj.types.indexOf('sublocality_level_2') !== -1);
    const sublocal = place.address_components.find(obj => obj.types.indexOf('sublocality_level_1') !== -1);
    const locality = place.address_components.find(obj => obj.types.indexOf('locality') !== -1);
    let realSub = sub ? sub : sublocal ? sublocal : locality;
    realSub = realSub?.long_name.replace(/แขวง|ตำบล/gi, '').trim() || ''
    // เขตหรืออำเภอ
    const dist = place.address_components.find(obj => obj.types.indexOf('administrative_area_level_2') !== -1);
    let realDist = sub ? sublocal : dist;
    realDist = realDist?.long_name.replace(/เขต|อำเภอ/gi, '').trim() || ''
    // จังหวัด
    const province = place.address_components.find(obj => obj.types.indexOf('administrative_area_level_1') !== -1);
    const selectedProvinceData = province ? provinces.find(p => p.name === province.long_name) : ''
    // รหัสไปรษณีย์
    const post = place.address_components.find(obj => obj.types.indexOf('postal_code') !== -1);

    setAddress({
      street: combinedStreet || "",
      sub: realSub || "",
      district: realDist || "",
      province: province?.long_name || "",
      stateCd: selectedProvinceData?.isocode || "",
      post: post?.long_name || "",
    });

    setSelectedProvince(selectedProvinceData ? { label: selectedProvinceData.name, value: selectedProvinceData.isocode } : { label: 'เลือก', value: '' })
    setSelectedDistrict(realDist ? { label: realDist, value: realDist } : { label: 'เลือก', value: '' })
    setSelectedSubDistrict(realSub ? { label: realSub, value: realSub } : { label: 'เลือก', value: '' })

  };

  const handelAddressChange = useCallback(e => {
    setAddress({
      ...address,
      [e.target.id]: e.target.value,
    });
  }, [address]);

  const onSave = useCallback(() => {
    setShowConfirmModal(true);
  }, [address]);

  const onConfirmSave = useCallback(() => {
    const contactId = [...Array(32)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    const payload = {
      amwayNumber: customer.amwayNumber,
      addressBookInput: {
        addressList: [
          {
            partyId: customer.partyId,
            statusCd: "Valid",
            addressBookId: "",
            addressValidatedDate: "",
            usageList: [
              {
                careOf: "",
                contactPointPurposeCd: "Shipping",
                primaryFlag: false
              }
            ],
            addrStreet: address.street,
            districtName: address.district,
            subDistrictName: address.sub,
            cityName: address.province,
            postalCd: address.post,
            stateCd: address.stateCd,
            cntryCd: "TH",
            countyName: "ประเทศไทย",
            contactPointTypeCd: "BusinessAddress",
            addrLineTwo: address.detail,
            languageCd: "th",
            taxJursidictionCd: "",
            addrDeliveryTypeCd: "MailStop",
            validationResultCd: "Valid",
            districtNm: address.district,
            subDistrictNm: address.sub,
            contactId,
          }
        ]
      }
    }
    setLoading(true);
    setShowConfirmModal(false);
    dispatch(addNewAddress(payload));
  }, [address, customer]);

  const ableToSave = [
    address['street'],
    address['sub'],
    address['district'],
    address['province'],
    address['post'],
    address['detail'],
  ].every((v) => !_.isNil(v) && !_.isEmpty(v));

  useEffect(() => {
    addressService.getProvinces()
      .then((res) => {
        if (res.code === 200) {
          setProvinces(res.data);
        }
      })
    dispatch(setAddNewAddress({ code: null, data: null }));
    return () => {
      dispatch(setAddNewAddress({ code: null, data: null }));
    }
  }, []);


  useEffect(() => {
    setDistricts([]);
    setSubDistricts([]);
    if (!isFetchPlace) {
      setSelectedDistrict(defaultOption);
      setSelectedSubDistrict(defaultOption);
      setAddress({
        ...address,
        province: selectedProvince.label,
        stateCd: selectedProvince.value,
        sub: null,
        district: null,
        post: null,
      });
    }
    if (!_.isEmpty(selectedProvince?.value)) {
      addressService.getDistricts({
        provinceCode: selectedProvince?.value,
      })
        .then((res) => {
          if (res.code === 200) {
            setDistricts(res.data);
          }
        })
    }
  }, [selectedProvince?.value]);

  useEffect(() => {
    setSubDistricts([]);
    if (!isFetchPlace) {
    setSelectedSubDistrict(defaultOption);
    setAddress({
      ...address,
      district: selectedDistrict.label,
      sub: null,
      post: null,
    });}
    if (
      !_.isEmpty(selectedProvince?.value)
      && !_.isEmpty(selectedDistrict?.value)
    ) {
      addressService.getSubDistricts({
        provinceCode: selectedProvince?.value,
        district: selectedDistrict?.value,
      })
        .then((res) => {
          if (res.code === 200) {
            setSubDistricts(res.data);
          }
        })
    }
  }, [selectedDistrict?.value]);
  
  useEffect(() => {
    if (!isFetchPlace) {
      setAddress({
        ...address,
        sub: selectedSubDistrict.label,
        post: null,
      });
    }

    if (
      !_.isEmpty(selectedProvince?.value)
      && !_.isEmpty(selectedDistrict?.value)
      && !_.isEmpty(selectedSubDistrict?.value)
    ) {
      addressService.getPostalCode({
        provinceCode: selectedProvince?.value,
        district: selectedDistrict?.value,
        subDistrict: selectedSubDistrict?.value,
      })
        .then((res) => {
          if (res.code === 200) {
            setAddress({
              ...address,
              sub: selectedSubDistrict.label,
              post: _.head(res.data),
            });
          }
        })
    }
  }, [selectedSubDistrict?.value]);

  useEffect(() => {
    if (addAddress && addAddress.code) {
      if (addAddress.code === 200) {
        dispatch(setAddressRedux(addAddress));
        history.push({
          pathname: commonConstant.pathChooseAddress,
          state: {
            cartCode: location?.state?.cartCode,
          },
        });
      }
      setLoading(false);
    }
  }, [addAddress]);

  if (openMap) {
    return <PlacePicker onPlaceSelect={handlePlaceSelect} />;
  }
  return (
    <div className="create-address-container">
      <LoadingOverlay active={loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
      {showConfirmModal && (
        <Modal
          title={'ยืนยันที่อยู่ใหม่'}
          type={'warning'}
          closeBtn={false}
          fnCancel={() => setShowConfirmModal(false)}
          fnOk={() => onConfirmSave()}
          okBtn={true}
          cancleText="ยกเลิก"
          okText="ยืนยัน"
        />
      )}
      <div className="body">
        <div className="header">เพิ่มที่อยู่</div>
        <button className="away-button map-button" onClick={() => setOpenMap(true)} >เลือกแผนที่</button>
        <div className="address-form">
          <label>ที่อยู่</label>
          <input type="text" id="street" value={address.street} className="form-control input-line-bottom" onChange={handelAddressChange} />
          <label>จังหวัด</label>
          <SelectCustom
            options={[
              defaultOption,
              ...provinces.map((province) => ({ label: province.name, value: province.isocode })),
            ]}
            onChange={(e) => {
              setIsFetchPlace(false)
              setSelectedProvince(e)
            }}
            value={selectedProvince}
          />
          <label>เขต / อำเภอ</label>
          <SelectCustom
            options={[
              defaultOption,
              ...districts.map((district) => ({ label: district, value: district })),
            ]}
            onChange={(e) => {
              setIsFetchPlace(false)
              setSelectedDistrict(e)
            }}
            value={selectedDistrict}
          />
          <label>แขวง / ตำบล</label>
          <SelectCustom
            options={[
              defaultOption,
              ...subDistricts.map((subDistrict) => ({ label: subDistrict, value: subDistrict })),
            ]}
            onChange={(e) => {
              setIsFetchPlace(false)
              setSelectedSubDistrict(e)
            }}
            value={selectedSubDistrict}
          />
          <label>รหัสไปรษณีย์</label>
          <input className="form-control input-line-bottom" value={address.post || ''} id="post" onChange={handelAddressChange} />
          <label>รายละเอียดที่อยู่</label>
          <input type="text" className="form-control input-line-bottom" value={address.detail || ''} id="detail" onChange={handelAddressChange} />
        </div>
      </div>
      <div className="footer">
        <button className={ableToSave ? "away-button submit-button" : "away-button submit-button disabled"} onClick={() => ableToSave ? onSave() : () => null}>บันทึก</button>
      </div>
    </div>
  );
};
