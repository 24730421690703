import React, { useEffect, useRef, useState } from "react";
import { TeamRectCard } from "../../../../common";
import { CHALLENGE, dividerWidth, MAX_WIDTH_FOR_DIVIDER, SMALL_SCREEN_WIDTH } from "../../../../common/challengeConstants";
import './ChallengeMyStepsCard.scss';

const ChallengeMyStepsCard = ({ userData, teamDashboardStatus }) => {
    const [dividerMargin, setDividerMargin] = useState();
    const stepsDivWidth = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= SMALL_SCREEN_WIDTH) {
                if (stepsDivWidth?.current?.offsetWidth > MAX_WIDTH_FOR_DIVIDER) {
                    setDividerMargin(dividerWidth);
                }
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [userData]);

    return (
        <div className="challenge-my-steps-container">
            <div className="challenge-card-body">
                <TeamRectCard
                    title={CHALLENGE.myDashboard.stepTodayHeading}
                    quantity={userData?.todaySteps}
                    amount={CHALLENGE.myDashboard.maxUserSteps}
                    stepsUnit={CHALLENGE.myDashboard.stepsUnit}
                    teamDashboardStatus={teamDashboardStatus}
                />
                <hr className="rounded-dividing-line" style={{ marginLeft: dividerMargin }}></hr>
                <div className="total-steps" ref={stepsDivWidth}>
                    <TeamRectCard
                        title={CHALLENGE.myDashboard.accStepHeading}
                        quantity={userData?.userTotalSteps}
                        amount={' '}
                        stepsUnit={CHALLENGE.myDashboard.stepsUnit}
                        teamDashboardStatus={teamDashboardStatus}
                    />
                </div>
            </div>
        </div>
    )
}

export default ChallengeMyStepsCard;