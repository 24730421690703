import React, { useState } from 'react';
import './dropdown.scss';
import ClickOutsideListener from '../clickOutsideListener';

const Dropdown = ({ disabled, onDropdownSelect, defaultValue, data = [{ displayText: '', value: '' }] }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(defaultValue);
  const handleToggle = () => setOpenDropdown(!disabled && !openDropdown);

  const handleSelect = ({ target: { innerText, dataset: { value }, ...rest } }) => {
    setDropdownValue({ displayText: innerText, value });
    setOpenDropdown(false);
    onDropdownSelect && onDropdownSelect({ displayText: innerText, value }, rest);
  };

  const handleOutsideClick = () => setOpenDropdown(false);

  return (
    <ClickOutsideListener onOutsideClick={handleOutsideClick}>
      <div className="custom-dropdown" id="customSelect">
        <div onClick={handleToggle} className={`select-styled${disabled ? ' --centered' : ''}`}>
          <div className="select-styled-text">{dropdownValue.displayText}</div>
          {!disabled && <div className="select-green-arrow">
            <img src="/images/healthAndWellness/bmi/icons/arrow-down-green.svg" />
          </div>
          }
        </div>
        <ul className={`select-options${openDropdown ? '' : ' --hide'}`}>
          {data.map((item) =>
            <li onClick={handleSelect} key={item.value} data-value={item.value}>
              {item.displayText}
            </li>)
          }
        </ul>
      </div>
    </ClickOutsideListener>
  );
};

export default Dropdown;
