import { FETCH_CHALLENGE_TEAM_DETAIL, FETCH_CHALLENGE_TEAM_SUCCESS, FETCH_CHALLENGE_TEAM_FAILURE, FETCH_USER_DETAIL, FETCH_USER_DETAIL_SUCCESS, FETCH_USER_DETAIL_FAILURE, SYNC_USER_BADGE_COUNT_SUCCESS, SYNC_USER_BADGE_COUNT_FAILURE } from '../actions/types';
import { CHALLENGE } from '../common/challengeConstants';
import {sortBadgeUserList} from '../utill.func'

const initialState = {
  teamData: {},
  stepsBadgeViewedData: {},
  userDetail: [],
  users: [],
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CHALLENGE_TEAM_DETAIL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CHALLENGE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        teamData: action.payload,
        error: null,
      };
    case FETCH_CHALLENGE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_USER_DETAIL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_DETAIL_SUCCESS:
      // to add profile image of users match patyid from challenge system
      const defaultProfile = '/images/challenge/default_challenge_profile.svg'
      const userDetail = state?.teamData?.challengeUserData?.map(user => {
        const data = action?.payload?.lineUserData?.find(val => val?.partyId == user?.partyId);
        return {
          userName: user?.userName || CHALLENGE.teamDashboard.noUser,
          pictureUrl: data?.pictureUrl || defaultProfile,
          partyId: user?.partyId || '',
          aboId: user?.aboId || '',
          firstName: user?.firstName || '' ,
          lastName: user?.lastName || ''
        }
      });

      const updatedUsers = state?.teamData?.users?.map(user => {
        const data = action?.payload?.lineUserData?.find(val => val?.partyId == user?.partyId);
        return {
          ...user,
          pictureUrl: data?.pictureUrl || defaultProfile
        };
      });

      let updatedBadgeUsers = Object.keys(state?.teamData?.stepsBadgeData).reduce((acc, badgeData) => {
        const badge = state?.teamData?.stepsBadgeData[badgeData];
        const updatedUsers = badge?.allUsersData.map(user => {
          const data = action?.payload?.lineUserData?.find(val => val?.partyId === user?.partyId);
          return {
            ...user,
            pictureUrl: data?.pictureUrl || defaultProfile
          };
        });
        acc[badgeData] = {
          ...badge,
          allUsersData: updatedUsers
        };
        return acc;
      }, {});
      updatedBadgeUsers = sortBadgeUserList(updatedBadgeUsers, 'badgeReceived','receivedAt', 'total_steps');

      let updatedOtherBadgeUsers = Object.keys(state?.teamData?.otherBadgeData).reduce((acc, badgeData) => {
        const badge = state?.teamData?.otherBadgeData[badgeData];
        const updatedUsers = badge?.allUsersData.map(user => {
          const data = action?.payload?.lineUserData?.find(val => val?.partyId === user?.partyId);
          return {
            ...user,
            pictureUrl: data?.pictureUrl || defaultProfile
          };
        });
        acc[badgeData] = {
          ...badge,
          allUsersData: updatedUsers
        };
        return acc;
      }, {});

      updatedOtherBadgeUsers = sortBadgeUserList(updatedOtherBadgeUsers, 'badgeReceived','receivedAt','totalValue', 'desc')

      return {
        ...state,
        loading: false,
        userDetail: userDetail,
        teamData: {
          ...state.teamData,
          userDetail: userDetail,
          users: updatedUsers,
          stepsBadgeData: updatedBadgeUsers,
          otherBadgeData: updatedOtherBadgeUsers
        },
        error: null,
      };
    case FETCH_USER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SYNC_USER_BADGE_COUNT_SUCCESS:
      const stepsBadgeViewedData = action.payload;
      return {
        ...state,
        stepsBadgeViewedData,
        loading: false,
        error: null,
      };

    case SYNC_USER_BADGE_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
