import React from 'react';
import '../scss/modal.scss';

const Modal = ({ title , detail, fnOk, fnCancel,okText,cancleText ,type, fnClose, closeBtn, okBtn = true, content }) => {
  const renderHeaderIcon = () => {
    switch (type) {
      case 'danger':
        return <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="15.5" cy="15" r="14" stroke="#EA6766" strokeWidth="2"/>
          <path d="M16.7046 14.6108L16.351 14.9644L16.7046 15.3179L19.7832 18.3966C19.9504 18.5638 19.981 18.9128 19.7146 19.1793C19.4481 19.4458 19.099 19.4152 18.9318 19.248L15.8532 16.1693L15.4994 15.8156L15.1459 16.1695L12.0682 19.2506L12.068 19.2508C11.9007 19.4179 11.5518 19.4484 11.2854 19.182C11.019 18.9155 11.0496 18.5665 11.2168 18.3993L11.217 18.3991L14.2949 15.3177L14.6482 14.964L14.2945 14.6106L11.282 11.6008C11.2819 11.6008 11.2819 11.6007 11.2819 11.6007C11.2819 11.6007 11.2818 11.6007 11.2818 11.6006C11.1147 11.4334 11.0842 11.0844 11.3506 10.818C11.6171 10.5515 11.9661 10.5822 12.1334 10.7494L12.1335 10.7496L15.1462 13.7596L15.4998 14.1128L15.8532 13.7594L18.8632 10.7494C19.0304 10.5822 19.3794 10.5515 19.6459 10.818C19.9124 11.0845 19.8818 11.4336 19.7146 11.6008L20.0567 11.9429L19.7146 11.6008L16.7046 14.6108Z" fill="#EA6766" stroke="#EA6766"/>
        </svg>;
      case 'warning':
        return <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path d="M15.5 1.30435C19.1323 1.30435 22.6159 2.74728 25.1843 5.31571C27.7527 7.88414 29.1957 11.3677 29.1957 15H30.5C30.5 11.0218 28.9196 7.20644 26.1066 4.3934C23.2936 1.58035 19.4782 0 15.5 0V1.30435ZM1.80435 15C1.80435 11.3677 3.24728 7.88414 5.81571 5.31571C8.38414 2.74728 11.8677 1.30435 15.5 1.30435V0C11.5218 0 7.70644 1.58035 4.8934 4.3934C2.08035 7.20644 0.5 11.0218 0.5 15H1.80435ZM15.5 28.6957C11.8677 28.6957 8.38414 27.2527 5.81571 24.6843C3.24728 22.1159 1.80435 18.6323 1.80435 15H0.5C0.5 18.9782 2.08035 22.7936 4.8934 25.6066C7.70644 28.4196 11.5218 30 15.5 30V28.6957ZM29.1957 15C29.1957 18.6323 27.7527 22.1159 25.1843 24.6843C22.6159 27.2527 19.1323 28.6957 15.5 28.6957V30C19.4782 30 23.2936 28.4196 26.1066 25.6066C28.9196 22.7936 30.5 18.9782 30.5 15H29.1957Z" fill="#FEC147"/>
            <path d="M14.5088 9.79564V15.6522H16.4653V9.79564H14.5088ZM14.5088 15.6652C14.5088 15.9247 14.6119 16.1735 14.7953 16.3569C14.9788 16.5404 15.2276 16.6435 15.4871 16.6435C15.7465 16.6435 15.9953 16.5404 16.1788 16.3569C16.3622 16.1735 16.4653 15.9247 16.4653 15.6652H14.5088ZM16.4653 9.79564C16.4653 9.53619 16.3622 9.28737 16.1788 9.10391C15.9953 8.92045 15.7465 8.81738 15.4871 8.81738C15.2276 8.81738 14.9788 8.92045 14.7953 9.10391C14.6119 9.28737 14.5088 9.53619 14.5088 9.79564H16.4653Z" fill="#FEC147"/>
            <path d="M15.4707 19.7952H16.796C16.796 20.1466 16.6564 20.4837 16.4078 20.7323C16.1593 20.9808 15.8222 21.1204 15.4707 21.1204V19.7952ZM15.4376 19.7952H15.4707V21.1204C15.2939 21.1249 15.118 21.0938 14.9534 21.0292C14.7887 20.9646 14.6387 20.8677 14.5121 20.7442C14.3855 20.6207 14.2849 20.4731 14.2163 20.3101C14.1476 20.1471 14.1122 19.972 14.1123 19.7952H15.4376ZM15.4707 19.7952C15.4655 19.7928 15.4599 19.7915 15.4541 19.7915C15.4484 19.7915 15.4428 19.7928 15.4376 19.7952H14.1123C14.1122 19.6183 14.1476 19.4432 14.2163 19.2802C14.2849 19.1172 14.3855 18.9696 14.5121 18.8461C14.6387 18.7226 14.7887 18.6257 14.9534 18.5611C15.118 18.4965 15.2939 18.4655 15.4707 18.4699V19.7952ZM15.4707 19.7952V18.4699C15.8222 18.4699 16.1593 18.6095 16.4078 18.858C16.6564 19.1066 16.796 19.4437 16.796 19.7952H15.4707Z" fill="#FEC147"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="30" height="30" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg>;
      case 'success': 
        return <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path d="M15.5 1.30435C19.1323 1.30435 22.6159 2.74728 25.1843 5.31571C27.7527 7.88414 29.1957 11.3677 29.1957 15H30.5C30.5 11.0218 28.9196 7.20644 26.1066 4.3934C23.2936 1.58035 19.4782 0 15.5 0V1.30435ZM1.80435 15C1.80435 11.3677 3.24728 7.88414 5.81571 5.31571C8.38414 2.74728 11.8677 1.30435 15.5 1.30435V0C11.5218 0 7.70644 1.58035 4.8934 4.3934C2.08035 7.20644 0.5 11.0218 0.5 15H1.80435ZM15.5 28.6957C11.8677 28.6957 8.38414 27.2527 5.81571 24.6843C3.24728 22.1159 1.80435 18.6323 1.80435 15H0.5C0.5 18.9782 2.08035 22.7936 4.8934 25.6066C7.70644 28.4196 11.5218 30 15.5 30V28.6957ZM29.1957 15C29.1957 18.6323 27.7527 22.1159 25.1843 24.6843C22.6159 27.2527 19.1323 28.6957 15.5 28.6957V30C19.4782 30 23.2936 28.4196 26.1066 25.6066C28.9196 22.7936 30.5 18.9782 30.5 15H29.1957Z" fill="#17D852"/>
            <path d="M14.0389 20.6479L23.1694 10.2131L22.1781 9.35225L13.0476 19.787L14.0389 20.6479ZM7.89546 16.1479L13.1129 20.7131L13.9737 19.7218L8.75633 15.1566L7.89546 16.1479ZM23.1694 10.2131C23.2835 10.0817 23.3408 9.91025 23.3286 9.73657C23.3163 9.5629 23.2356 9.40119 23.1042 9.28703C22.9727 9.17288 22.8013 9.11561 22.6276 9.12784C22.4539 9.14007 22.2922 9.2208 22.1781 9.35225L23.1694 10.2131ZM13.5433 20.2175L13.1129 20.7131C13.2453 20.8251 13.4162 20.881 13.5892 20.8688C13.7621 20.8566 13.9235 20.7773 14.0389 20.6479L13.5433 20.2175ZM8.75633 15.1566C8.62488 15.0424 8.45346 14.9852 8.27978 14.9974C8.10611 15.0096 7.9444 15.0904 7.83025 15.2218C7.71609 15.3533 7.65883 15.5247 7.67106 15.6984C7.68329 15.872 7.76401 16.0337 7.89546 16.1479L8.75633 15.1566Z" fill="#17D852"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="30" height="30" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg>;
         
      default:
        break;
    }
  };

  return (
    <div className="Modal">
      <div className="modal-container">
        <div className="details">
          {renderHeaderIcon()}
          {closeBtn ? <img className="close-btn" onClick={fnClose} src="/images/Close.svg" alt="" /> : null}
          {title && <h3>{title}</h3>}
          <p dangerouslySetInnerHTML={{ __html: detail }} />
          <div>{content}</div>
        </div>
        <div className="footer">
          {
            typeof fnCancel === 'function' ? <button onClick={fnCancel} className="cancel">{cancleText ? cancleText : 'กลับ'}</button> : null
          }
          {
            okBtn ? <button onClick={fnOk} className="ok">{okText ? okText : 'ตกลง'}</button> : null
          }
        </div>
      </div>
    </div>
  );};

export default Modal;
