import React from "react";
import "./StepsBadgeLoader.scss";

const StepsBadgeLoader = () => {
  return (
    <div className="steps-badge-container">
    <div className="steps-skeleton-container">
    <div className="steps-skeleton-card-container">
      <div className="skeleton-card-image skeleton"></div>
    </div>
  </div>
  </div>
  );
};

export default StepsBadgeLoader;
