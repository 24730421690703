import commonConstant from './commonConstant';

export default {};

export const HOME_PAGE = {
    FOOD_SEARCH_PLACEHOLDER: 'ค้นหาเมนูอาหาร',
    HOME_GREETING_MSG: 'สวัสดี,',
    BMI_HEADING: 'BMI',
    BMI_HEADING_THAI: ' ดัชนีมวลกาย',
    ASSESSMENT_BADGE_TEXT: 'เริ่มเลย!',
    SELF_CHALLENGE_TAG: 'ประเภทเดี่ยว',
    WATER_CARD_TEXT: 'ดื่มน้ำ',
    EXERCISE_CARD_TITLE: 'ออกกำลังกาย',
    EXERCISE_NO_DATA: 'ตั้งเป้าหมาย',
    EXERCISE_NO_DATA_SUBTITLE:'การเผาผลาญ',
    EXERCISE_CARD_UNIT:'แคลอรี',
    STEPS_CALORIE_UNIT:'แคลอรี',
    WATER_LOG_NO_DATA: 'ตั้งเป้าหมาย',
    WATER_LOG_NO_DATA_SUBTITLE: 'การดื่มน้ำ'
}

export const HOME_IMG = {
    calendarIconUrl: "/images/challenge/icons/team-dashboard-calendar-icon.svg",
    cartIconUrl: "/images/challenge/icons/shopping-bag.svg",
    foodSearchUrl: "/images/challenge/icons/search.svg",
}

export const badgeClassName = {
    classname: 'points-badge',
    title: '99,999 พอยท์',
    icon: '/images/healthAndWellness/coin.svg',
}

export const BODY_DATA_CHANGE = {
    homeWaistlineHeading: 'รอบเอว',
    homeFatHeading: 'ไขมัน',
    homeMuscleHeading: 'มวลกล้ามเนื้อ',
    homeHeightHeading: 'ส่วนสูง',
    unitFinger: 'นิ้ว',
    unitPercentage: '%',
    unitKilogram: 'กก.',
    unitCentimetre: 'ซม.'
}

export const HOME_WEIGHT_GOAL = {
    title: 'ตั้งเป้าหมายน้ำหนัก',
}

export const HOME_NO_BMI = {
    title: 'ดัชนีมวลกาย',
    bmi: 'BMI',
    des: '*ระบุน้ำหนัก และส่วนสูงเพื่อทราบ BMI ของคุณ',
    value: '-'
}

export const BODY_CARD_NO_DATA = {
    waist: {
        image: '/images/healthAndWellness/weight-no-data.svg',
        title: 'ระบุรอบเอว',
        unit: '(นิ้ว)'
    },
    fat: {
        image: '/images/healthAndWellness/fat-no-data.svg',
        title: 'ระบุไขมัน',
        unit: '(%)'
    },
    muscle: {
        image: '/images/healthAndWellness/muscle-no-data.svg',
        title: 'ระบุมวลกล้ามเนื้อ',
        unit: '(กก.)'
    },
    height: {
        image: '/images/healthAndWellness/height-no-data.svg',
        title: 'ระบุส่วนสูง',
        unit: '(ซม.)'
    },

}

export const HOME_FOOD_LOG = {
    header: 'แคลอรี',
    calories: 'กินไปแล้ว',
    unit: 'แคลอรี',
    subtitle: 'กินได้อีก',
    noFoodGoal: 'ตั้งเป้าหมายระยะทางก้าวเดิน',
    overSubtitle: 'กินเกิน'
}

export const dailyNutrition = [
    {
        name: 'protein',
        label: 'โปรตีน',
    },
    {
        name: 'carbs',
        label: 'คาร์โบไฮเดรต',
    },
    {
        name: 'fat',
        label: 'ไขมัน',
    },
];

export const STEPS_CARD_TITLE = 'ก้าวเดิน'

export const WATER_LOG_ICONS = {
    waterLogIcon1: "images/healthAndWellness/water-log/water_no_data.svg",
    waterLogIcon2: "images/healthAndWellness/water-log/water_0.5.svg",
    waterLogIcon3: "images/healthAndWellness/water-log/water_0.75.svg",
    waterLogIcon4: "images/healthAndWellness/water-log/water_1.svg",
    waterLogIcon5: "images/healthAndWellness/water-log/water_1.25.svg",
    waterLogIcon6: "images/healthAndWellness/water-log/water_1.5.svg"
}

export const DEFAULT_WATER_LEVEL = {
    default_water_l1: 1.5,
    default_water_l2: 1,
    default_water_l3: 0.5,
}

export const HOME_FOOD_OVER_POINTER = 12;
export const HOME_FOOD_NO_POINTER = 5;
export const HOME_STEPS_DEFAULT = '0859992399';

export const intervalArray = Array(4);
export const MAX_HEIGHT = 37;

export const BASE_WATER_LEVEL = 3;
export const WATER_LEVEL_MULTIPLIER = 8.75;