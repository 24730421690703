import React from 'react';
import './HeaderAssessment.scss';
import { ASSESSMENT_QUESTION_TEMPLATE } from '../../../../../common/commonConstant';

const Header = ({ templateType }) => {

  const renderProgress = () => {
    let allProgressParentDiv = [];
    const totalActivityCount = Object.keys(ASSESSMENT_QUESTION_TEMPLATE).length;
    const totalActivityValues = Object.keys(ASSESSMENT_QUESTION_TEMPLATE);
    for (let index = 0; index < totalActivityCount; index++) {
      const progressClass =
        index <= ASSESSMENT_QUESTION_TEMPLATE[templateType].progress_tracker ? 'active' : 'inactive';
      allProgressParentDiv.push(
        <>
          <img
            src={`/images/healthAndWellness/assessment/assessment_${totalActivityValues[index].toLowerCase()}-${progressClass}.svg`}
            className="activity-div"
            key={index}
          />
          {index < totalActivityCount - 1 ? (<div className={`spacer-div ${progressClass}`}></div>) : (<></>)}
        </>
      );
    }
    return allProgressParentDiv;
  };

  return (
    <div className="assessment-header-vector">
      <div className="header-parent">
        <div className="header-progress-base">
          <div className="title word-wrap-break">{ASSESSMENT_QUESTION_TEMPLATE[templateType].header}</div>
          <div className="progress-parent">{renderProgress()}</div>
        </div>
      </div>
      <img src={`/images/healthAndWellness/header-wave.svg`} className="w-100 header-curve-bg" />
    </div>
  );
}

export default Header;
