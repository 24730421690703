import React from "react";
import "./ChallengeVideoPlayer.scss";
import YoutubeVideoModal from "../../../common/youtubeVideoModal/YoutubeVideoModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { convertMinutesToSeconds, getDataFromSession, getParamsString } from "../../../utill.func";
import queryString from 'query-string';
import { commonConstant } from "../../../common";

const ChallengeVideoPlayer = () => {

    const history = useHistory();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const eventId = params['eventId'];
    const challengeId = params['challengeId'];
    const userId = params['userId']
    const videoId = params['videoId'];
    const screenType = params['screenType'];
    const videoIndex = params['videoIndex'];
    const { partyId, amwayNumber } = getDataFromSession(
        'customer'
    );
    const { challengeVideoList, loading: videoLoading } = useSelector(state => state.challengeVideo);

    const videoData = challengeVideoList.find(video => video.videoId === videoId);

    const handleBackNavigation = () => {
                if (screenType === 'quiz') {
                    const quizParams = {
                        challengeId: eventId,
                        eventId: challengeId,
                        userId: userId
                    }
                    history.push(`${commonConstant.pathChallengeQuiz}?${getParamsString(quizParams)}`);
                }
                else if (screenType === 'video') {
                    const vdoParams = {
                        challengeId: eventId,
                        eventId: challengeId,
                        userId: userId,
                        videoId: videoIndex
                    }
                    history.push(`${commonConstant.pathChallengeVideo}?${getParamsString(vdoParams)}`);
                }
    };

    return <>
        <YoutubeVideoModal
            videoId={videoData?.videoId}
            videoLink={videoData?.link}
            duration={videoData?.youtubeDetails?.duration}
            isOpen={true}
            onBack={() => handleBackNavigation()}
            eventId={eventId}
            aboId={amwayNumber}
            partyId={partyId}
            startFromDuration={convertMinutesToSeconds(videoData?.stoppedAt)}
            videoStatus={videoData?.status} />
    </>
}

export default ChallengeVideoPlayer;