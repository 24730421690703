import {
  fetchDataRequest,
  fetchDataSuccess,
  fetchDataFailure,
} from '../actions/foodLogActions';
import {
  EMPTY_FOOD_LOGS_REQUEST,
  GET_FOOD_LOGS,
  GET_FOOD_LOGS_ERROR,
  GET_FOOD_LOG_DATA,
  GET_FOOD_LOG_DATA_ERROR,
  GET_WATER_LOGS,
  GET_WATER_LOGS_ERROR,
  GET_WATER_VOLUME,
  GET_WATER_VOLUME_ERROR,
  POST_WATER_LOGS,
  POST_WATER_LOGS_ERROR,
  UPDATE_HEALTH_DATA,
  UPDATE_WATER_VOLUME,
  UPDATE_WATER_VOLUME_ERROR,
} from '../actions/types';
import { FOODTYPES } from '../constants';
import {
  changeWaterVolume,
  deleteWaterLog,
  fetchFoodLogData,
  fetchHealthLogs,
  fetchWaterVolume,
  postWaterLog,
  postWaterVolume,
  updateWaterLog,
} from '../services/healthWellness/foodLog.service';

export const emptyWaterLogs = () => (dispatch) =>
  dispatch(fetchDataRequest(EMPTY_FOOD_LOGS_REQUEST));

export const getHealthLogs = (params) => (dispatch) =>
  fetchHealthLogs(params)
    .then((res) => {
      if (params.type === FOODTYPES.water) {
        dispatch(fetchDataSuccess(GET_WATER_LOGS, res));
      } else {
        dispatch(fetchDataSuccess(GET_FOOD_LOGS, res));
      }
      return res;
    })
    .catch((error) => {
      if (params.type === FOODTYPES.water) {
        dispatch(fetchDataFailure(GET_WATER_LOGS_ERROR, error.message));
      } else {
        dispatch(fetchDataFailure(GET_FOOD_LOGS_ERROR, error.message));
      }
      alert(error.message);
    });

export const saveWaterLogs = (payload) => (dispatch) =>
  postWaterLog(payload)
    .then((res) => {
      dispatch(fetchDataSuccess(POST_WATER_LOGS, res));
      return res;
    })
    .catch((error) => {
      dispatch(fetchDataFailure(POST_WATER_LOGS_ERROR, error.message));
      alert(error.message);
    });

export const changeWaterLogs = (id, payload) => (dispatch) =>
  updateWaterLog(id, payload)
    .then((res) => {
      dispatch(fetchDataSuccess(POST_WATER_LOGS, res));
      return res;
    })
    .catch((error) => {
      dispatch(fetchDataFailure(POST_WATER_LOGS_ERROR, error.message));
      alert(error.message);
    });

export const getWaterVolume = (customerId) => (dispatch) =>
  fetchWaterVolume(customerId)
    .then((res) => {
      dispatch(fetchDataSuccess(GET_WATER_VOLUME, res));
      return res;
    })
    .catch((error) => {
      dispatch(fetchDataFailure(GET_WATER_VOLUME_ERROR, error.message));
      alert(error.message);
    });

export const saveWaterVolume = (payload) => (dispatch) =>
  postWaterVolume(payload)
    .then((res) => {
      dispatch(fetchDataSuccess(UPDATE_WATER_VOLUME, res));
      return res;
    })
    .catch((error) => {
      dispatch(fetchDataFailure(UPDATE_WATER_VOLUME_ERROR, error.message));
      alert(error.message);
    });

export const updateWaterVolume =
  (customerId, id, type, payload) => (dispatch) =>
    changeWaterVolume(id, customerId, type, payload)
      .then((res) => {
        dispatch(fetchDataSuccess(UPDATE_WATER_VOLUME, res));
        return res;
      })
      .catch((error) => {
        dispatch(fetchDataFailure(UPDATE_WATER_VOLUME_ERROR, error.message));
        alert(error.message);
      });

export const getFoodLogDetails = (customerId, payload) => (dispatch) =>
  fetchFoodLogData(customerId, payload)
    .then((res) => {
      dispatch(fetchDataSuccess(GET_FOOD_LOG_DATA, res));
      return res;
    })
    .catch((error) => {
      dispatch(fetchDataFailure(GET_FOOD_LOG_DATA_ERROR, error.message));
      alert(error.message);
    });

export const deleteLog = (id) => () =>
  deleteWaterLog(id)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      alert(error.message);
    });

export const updateHealthData = payload => dispatch => {
  dispatch(fetchDataSuccess(UPDATE_HEALTH_DATA, payload));
}

export const updateVolumeAfterLog = volumeData => dispatch => dispatch(fetchDataSuccess(UPDATE_WATER_VOLUME, volumeData));
