import axios from 'axios';
import config from '../../config';
import { commonConstant } from '../../common';

export const getContentById = async (contentId) => {
  const response = await axios.get(
    `${config.API_URL}/abuddy/content/${contentId}`);

  return response.data;
};
