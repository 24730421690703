import React from 'react';
import './ChallengeRewardCard.scss'
import { CHALLENGE } from '../challengeConstants';

const ChallengeRewardCard = ({rewardData, openRewardModal, isTeamTab}) => {
    return (
        <div className={`challenge-reward-card-container`}>
            <div className={`reward-card-single ${isTeamTab}`}data-bs-toggle="modal" data-bs-target="#rewardDetailsModal" onClick={()=>openRewardModal(rewardData)}>
                <div className="reward-img-container">
                    <div className="reward-img-inner-container">
                        <img src={rewardData?.rewardImage} alt="" />
                        <div className="reward-overlay"></div>
                    </div>

                    <div className={`reward-tag`} >
                        <img src={rewardData?.rewardStatusIconAndTitle?.rewardIcon} alt="" />
                        <span className={`reward-tag-title ${rewardData?.rewardStatusIconAndTitle?.rewardClass}`}>{rewardData?.rewardStatusIconAndTitle?.rewardTitle}</span>
                    </div>

                </div>
                <div className="reward-description">
                    <div className="reward-card-title">
                       {rewardData?.rewardActualName}
                    </div>
                    <div className="price-range">
                        <span className="price-range-title">{CHALLENGE.reward.rewardValue}</span>
                        <span className="price-range-value">{rewardData?.rewardPrice}</span>
                        <span className="price-range-currency">{CHALLENGE.reward.rewardCurrency}</span>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ChallengeRewardCard;