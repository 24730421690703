import React, { useState, useEffect } from 'react';

import './SliderAssessment.scss';
import { SliderAssessment, VerticalSliderAssessment } from '../../../../../common';
import config from '../../../../../config';

const Slider = ({ sliderData, getAnswer, sliderType }) => {

  const [data, setData] = useState(0);
  const [questionType, setQuestionType] = useState('');

  useEffect(() => {
    setQuestionType(sliderData?.question_type);
  }, []);

  const handleChange = (data) => {
    setData(data);
    getAnswer(sliderData?.question_value[data], sliderData);
  };

  return (
    <div className="slider-wrapper">
      <div className="slider-question">{sliderData?.question}</div>
      {sliderType === 'vertical' ?
        <VerticalSliderAssessment sliderData={sliderData?.question_value} onSlide={handleChange} />
        :
        <SliderAssessment sliderData={sliderData?.question_value} onSlide={handleChange} />
      }
      {!sliderType &&
        <div className={`selected-data ${questionType == 'Attitude' ? 'attitude-type-media': ''}`}>
          <img src={`${config.S3_URL}${sliderData?.question_value[data]?.image_url}`} alt={sliderData?.question_value[data]?.value} />
        </div>
      }

    </div>
  );
};

export default Slider;