import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import "./profile.scss";
import axios from "axios";
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useSwipeable } from 'react-swipeable';
import { Col, Row } from 'antd';
import Swal from "sweetalert2";
import * as echarts from 'echarts';
import { liffGetProfile } from '../../services/promotion/auth.service'

const liff = window.liff;

const Profile = (props) => {

    const history = useHistory();
    let User = localStorage.getItem("User");
    const cover_user = User ? JSON.parse(User) : '';

    const [loading2, setLoading2] = useState(false);
    const [databmi, setdatabmi] = useState(true)

    const chartRef = useRef(null);
    const chartRef2 = useRef(null);

    let color1 = '';
    let text = '';
    let bmi = databmi && Number(databmi.bmi) || 0;
    let maxWeight = bmi > 30 ? bmi : 40;

    if (bmi < 18.50) {
        color1 = '#99d2fd';
        text = 'Underweight';
    } else if (bmi >= 18.50 && bmi <= 23) {
        color1 = '#0F774A';
        text = 'Normal weight';
    } else if (bmi >= 23 && bmi <= 25) {
        color1 = '#ecbb42';
        text = 'Obese class I';
    } else if (bmi >= 25 && bmi <= 30) {
        color1 = '#f09c39';
        text = 'Obese class II';
    } else {
        color1 = '#eb5f52';
        text = 'Obese class III';
    }


    const weight1 = Number((18.5 * ((databmi.height / 100) * (databmi.height / 100))).toFixed(2));
    const weight2 = Number((22.9 * ((databmi.height / 100) * (databmi.height / 100))).toFixed(2));



    useEffect(() => {

        var pathnameendecodeURI = decodeURIComponent(window.location)
            .replace(/\?liff.state=%3F/g, "?")
            .replace(/%3D/g, "=")
            .replace(/%26/g, "&");
        var url = new URL(pathnameendecodeURI);
        var roomid = url.searchParams.get("roomId");
        var code2 = url.searchParams.get("code");

        if (roomid) {
            sessionStorage.setItem(
                "roomid",
                JSON.stringify(roomid)
            );
        } else if (roomid == null && code2 == null) {
            sessionStorage.removeItem("roomid")
        }

        let roomidsestion = sessionStorage.getItem(
            "roomid"
        );

        if (roomidsestion) {
            localStorage.setItem(
                "roomid",
                roomidsestion
            );


        } else {

            localStorage.setItem(
                "roomid",
                JSON.stringify(null)
            );

        }





        liff.init({ liffId: process.env.REACT_APP_LIFF_CLIENT_ID_UAT_TEST, withLoginOnExternalBrowser: false }, async () => {
            if (liff.isLoggedIn()) {
                const liffLine = await liffGetProfile();
                const lineToken = liff.getAccessToken();
                let roomidsestion = localStorage.getItem("roomid");
                const normalizedRoomid = roomidsestion.replace(/\"/g, "");
                console.log(normalizedRoomid)
                console.log('roomidsestion2', roomidsestion);
                if (roomidsestion == "null") {
                    var events = {
                        lineAccessToken: lineToken,
                        "type": "member",
                    };
                } else {

                    var events = {
                        lineAccessToken: lineToken,
                        "type": "abuddy",
                        "roomId": normalizedRoomid
                    };

                }

                setLoading2(true)
                axios.post(`${process.env.REACT_APP_API_URL_UAT}/webhook/lineAuth`, events, {headers: {authorization : 'Bearer Dummy'}})
                    .then(async (res) => {
                        setLoading2(false)
                        if (res.data.data == null) {
                            localStorage.clear("User");
                            Swal.fire({
                                html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                         <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                                         <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                                         กรุณาสมัครสมาชิกก่อน
                                         </p>
                                       </div>`,
                                showCancelButton: false,
                                allowOutsideClick: false,
                                focusConfirm: false,
                                confirmButtonText: "ok",
                                showCancelButton: false,
                                allowOutsideClick: false,
                                confirmButtonText: "OK",

                                hideClass: {


                                    confirmButton: 'swal2-noanimation'
                                }


                            })
                                .then((result) => {


                                    if (result.value) {


                                        window.location.href = process.env.REACT_APP_REDIRECT_URL;


                                    }
                                });

                        } else {

                            localStorage.setItem("User", JSON.stringify(res.data.data));
                            getdata(res.data.data);


                            let pdpa = localStorage.getItem("pdpa" ? "pdpa" : "");



                            if (!pdpa) {


                                history.push("/assesment/pdpaasesment");
                            }
                        }
                    })
                    .catch(async (err) => {
                        setLoading2(false)
                    });
            } else {
                liff.login();
            }
        });
        const chartInstance2 = echarts.init(chartRef2.current);

        let bmi2 = 0;
        let color = '#2c2c2c';
        let value = 0;

        // เงื่อนไขที่กำหนด
        if (bmi2 < 18.50) {
            value = 4; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
        } else if (bmi2 >= 18.50 && bmi2 < 23) {
            value = 11.5; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
        } else if (bmi2 >= 23 && bmi2 < 25) {
            value = 20; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
        } else if (bmi2 >= 25 && bmi2 < 30) {
            value = 29; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
        } else {
            value = 36; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
        }

        const option = {
            series: [
                {
                    type: 'gauge',
                    max: 40,
                    progress: {
                        show: false,
                        width: 18,
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [
                                [0.185, '#99d2fd'], // Underweight
                                [0.23, '#66ba6d'], // Normal weight
                                [0.25, '#ecbb42'], // Obese class I
                                [0.275, '#f09c39'], // Obese class II
                                [1, '#eb5f52'], // Obese class III
                            ],
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        length: 0,
                        lineStyle: {
                            width: 2,
                            color: '#999',
                        },
                    },
                    axisLabel: {
                        show: false,
                    },
                    anchor: {
                        show: false,
                        showAbove: false,
                        size: 25,
                        itemStyle: {
                            borderWidth: 20,
                        },
                    },
                    title: {
                        show: false,
                    },
                    detail: {
                        show: false,
                    },
                    data: [
                        {

                            value: 4,
                            itemStyle: {
                                color: color,
                            },
                        },
                    ],
                    pointer: {
                        itemStyle: {
                            color: color, // Change the color of the pointer to match the specified color
                        },
                        value: 0, // Set the value of the pointer to the desired axisLabel
                        length: '70%', // Adjust the length of the pointer
                        width: 8, // Adjust the width of the pointer
                    },
                },
            ],
        };

        const colorLength = 1 / option.series[0].axisLine.lineStyle.color.length;
        option.series[0].axisLine.lineStyle.color.forEach((item, index) => {
            item[0] = colorLength * (index + 1);
        });

        option && chartInstance2.setOption(option);

        return () => {
            chartInstance2.dispose();
        };


    }, []);




    const getdata = async (data) => {
        const liffProfile = await liffGetProfile();
        setLoading2(true);
        console.log('data', data);
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL_UAT}/assessment/customerBMI`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${data.token}`
            },
        })
            .then((res) => {
                console.log(res.data.data);
                setLoading2(false);
                setdatabmi(res.data.data);
                const chartInstance = echarts.init(chartRef.current);

                let bmi2 = res.data.data.bmi;
                let color = '#2c2c2c';
                let value = 0;

                // เงื่อนไขที่กำหนด
                if (bmi2 < 18.50) {
                    value = 4; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
                } else if (bmi2 >= 18.50 && bmi2 < 23) {
                    value = 11.5; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
                } else if (bmi2 >= 23 && bmi2 < 25) {
                    value = 20; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
                } else if (bmi2 >= 25 && bmi2 < 30) {
                    value = 29; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
                } else {
                    value = 36; // เลขที่ axisLabel ที่ต้องการให้เข็มชี้ไป
                }

                const option = {
                    series: [
                        {
                            type: 'gauge',
                            max: 40,
                            progress: {
                                show: false,
                                width: 18,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 10,
                                    color: [
                                        [0.185, '#99d2fd'], // Underweight
                                        [0.23, '#66ba6d'], // Normal weight
                                        [0.25, '#ecbb42'], // Obese class I
                                        [0.275, '#f09c39'], // Obese class II
                                        [1, '#eb5f52'], // Obese class III
                                    ],
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                length: 0,
                                lineStyle: {
                                    width: 2,
                                    color: '#999',
                                },
                            },
                            axisLabel: {
                                show: false,
                            },
                            anchor: {
                                show: false,
                                showAbove: false,
                                size: 25,
                                itemStyle: {
                                    borderWidth: 20,
                                },
                            },
                            title: {
                                show: false,
                            },
                            detail: {
                                show: false,
                            },
                            data: [
                                {
                                    value: value, // Use the value variable instead of bmi2 to set the initial value
                                    // value: bmi2,
                                    itemStyle: {
                                        color: color,
                                    },
                                },
                            ],
                            pointer: {
                                itemStyle: {
                                    color: color,

                                    // Change the color of the pointer to match the specified color
                                },
                                value: bmi, // Set the value of the pointer to the desired axisLabel
                                length: '70%', // Adjust the length of the pointer
                                width: 8, // Adjust the width of the pointer
                            },
                        },
                    ],
                };

                const colorLength = 1 / option.series[0].axisLine.lineStyle.color.length;
                option.series[0].axisLine.lineStyle.color.forEach((item, index) => {
                    item[0] = colorLength * (index + 1);
                });

                option && chartInstance.setOption(option);

                return () => {
                    chartInstance.dispose();
                };


            })
            .catch((err) => {
                setLoading2(false);
                // จัดการข้อผิดพลาด
            });


    }




    const PageInsertW = () => {

        history.push("/assesment/insertw");
    }



    const handlers = useSwipeable({
        onSwipedLeft: () => {

            if (bmi == 0) {
                Swal.fire({
                    html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                             <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                             <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                             กรุณากด Update เพื่อคำนวน BMI ของท่าน
                             </p>
                           </div>`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    focusConfirm: false,
                    confirmButtonText: "ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: "OK",

                    hideClass: {


                        confirmButton: 'swal2-noanimation'
                    }


                });
            } else {
                history.push("/assesment/pageassesment");
            }

        },
        onSwipedRight: () => {
            // history.push("/assesment/insertw");
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });
    return (

        <div {...handlers}>
            <div style={{ backgroundColor: 'white' }}>
                <header style={{ textAlign: 'center' }}>
                    <div style={{ position: 'relative' }}>
                        <img src="images/assesment/BGPF.png" alt="รูปภาพ" style={{ maxWidth: '100%', maxHeight: '200px', width: '70vh', position: 'relative' }} />
                        <img src="images/assesment/avartart.png" alt="รูปภาพ1" style={{ position: 'absolute', top: '90%', left: '50%', width: '14vh', transform: 'translate(-50%, -50%)', zIndex: 1 }} />
                    </div>
                    <div style={{ position: 'absolute', top: '45px', left: '50%', transform: 'translate(-50%, -50%)', lineHeight: '25px' }}>

                        <span style={{ fontSize: '20px', fontFamily: 'Sukhumvit Set Extra Bold' }}>{cover_user.displayName}</span><br />
                        <span style={{ color: '#707070', fontSize: '18px', fontWeight: '700', textTransform: 'uppercase' }}>
                            {cover_user.customer?.type && cover_user.customer?.type ? cover_user.customer?.type : ''}
                            {cover_user.customer?.amwayNumber && cover_user.customer?.amwayNumber ? '#' + cover_user.customer?.amwayNumber : ''}
                        </span>

                    </div>
                </header>
                <div className="page overflow_disable" style={{
                    minHeight: '100vh',
                }}>
                    <div className="containerAssesment" style={{ marginTop: '40px' }}>
                        <div>
                            <Row style={{ paddingBottom: '10px' }}>
                                <Col span={12} >
                                    <Row>
                                        <Col span={8} style={{ textAlign: 'end' }}>
                                            <img src="images/assesment/date.png" alt="รูปภาพ1" className="imgicon" />
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                                {databmi && databmi.age ? databmi.age : '-'}
                                            </span>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'left' }}>
                                            <span className="fonticon2"> ปี </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={8} style={{ textAlign: 'end' }}>
                                            <img src="images/assesment/scal.png" alt="รูปภาพ1" className="imgicon" />
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Sukhumvit Set Extra Medium' }}>  {databmi && databmi.weight ? databmi.weight : '-'}</span>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'left' }}>
                                            <span className="fonticon2"> กก. </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Row>
                                        <Col span={8} style={{ textAlign: 'end' }}>
                                            <img src="images/assesment/temp.png" alt="รูปภาพ1" className="imgicon" />
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }} >
                                            <span style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Sukhumvit Set Extra Medium' }}>

                                                {databmi && databmi.waistline ? databmi.waistline : '-'}
                                            </span>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'left' }}>
                                            <span className="fonticon2"> นิ้ว </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={8} style={{ textAlign: "center" }}>
                                            <img src="images/assesment/ruler.png" alt="รูปภาพ1" className="imgicon" style={{ width: '19px', marginRight: '10px' }} />
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                                {databmi && databmi.height ? databmi.height : '-'}
                                            </span>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'left' }}>
                                            <span className="fonticon2"> ซม. </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <div>

                            {cover_user && cover_user.displayName ?  <button
                                    style={{ borderRadius: '20px', backgroundColor: 'rgb(15, 119, 74)', border: '2px solid rgb(15, 119, 74)', width: '20vh' }}
                                    type="button"
                                    className="btn btn-primary btn-lg btn-block button"
                                    onClick={() => PageInsertW()}
                                >
                                    <span style={{ fontWeight: 'bold', fontFamily: 'Sukhumvit Set Extra Bold' }}>Update</span>
                                </button> :  <button
                                    style={{ borderRadius: '20px', backgroundColor: 'rgb(15, 119, 74)', border: '2px solid rgb(15, 119, 74)', width: '20vh' }}
                                    type="button"
                                    className="btn btn-primary btn-lg btn-block button"
                                  
                                >
                                    <span style={{ fontWeight: 'bold', fontFamily: 'Sukhumvit Set Extra Bold' }}>Update</span>
                                </button>
                            }
                               
                            </div>
                        </div>

                        <div style={{ backgroundColor: '#f2f7ed', padding: '20px', borderRadius: '22px', marginTop: '20px' }}>
                            <div style={{ paddingBottom: '10px' }} >
                                <span style={{ fontSize: '18px', fontFamily: 'Sukhumvit Set Extra Bold' }}>ภาวะอ้วนลงพุง</span>
                            </div>
                            <Row style={{ paddingBottom: '10px' }}>
                                <Col span={12} >
                                    <Row>
                                        <Col span={8} style={{ textAlign: 'end' }}>
                                            <img src="images/assesment/temp.png" alt="รูปภาพ1" className="imgicon" />
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <span className="fonticon" style={{ margin: '2px', marginLeft: '6px' }}> {databmi && databmi.waistline ? (databmi.waistline * 2.54).toFixed(2) : '-'}
                                            </span>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'left' }}>
                                            <span className="fonticon2"> ซม. </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={8} style={{ textAlign: 'end' }}>
                                            <img src="images/assesment/ruler.png" alt="รูปภาพ1" style={{ width: '19px', marginRight: '20px' }} />
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <span className="fonticon3"> {databmi && databmi.height ? (databmi.height / 2).toFixed(2) : '-'}
                                            </span>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'left' }}>
                                            <span className="fonticon2"> ซม. </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <div style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', padding: '15px', borderRadius: '30px' }}>
                                <div style={{ width: '50px', height: '50px' }}>
                                    <img src="images/assesment/slander.png" style={{ width: '100%', height: '100%' }} alt="รูปภาพ1" />
                                </div>
                                <p style={{ marginLeft: '5px', flex: 1, fontFamily: 'Sukhumvit Set Extra Medium', fontSize: '12px', lineHeight: '1.4' }}>
                                    รอบเอวที่เหมาะสม คือ <span style={{ fontFamily: 'Sukhumvit Set Extra Bold' }}>ไม่ควรเกินส่วนสูง (เซนติเมตร) หาร 2</span>
                                    หากมีรอบเอวเกินเกณฑ์ มีความเสี่ยงต่อการเกิด โรคอ้วนลงพุง โรคหัวใจ
                                    ไขมันในเลือดสูง โรคเบาหวาน โรคความดันโลหิตสูง เป็นต้น
                                </p>
                            </div>



                        </div>
                        <div className="speedometer-container" >
                            {databmi &&
                                databmi.bmi ? <div ref={chartRef} style={{ width: '150px', height: '150px' }}>

                                <div style={{ fontSize: '20px', marginTop: '-53px', display: 'flex', justifyContent: 'center', fontFamily: 'Sukhumvit Set Extra Bold' }}>{databmi.bmi}</div>

                            </div>




                                : <div ref={chartRef2} style={{ width: '150px', height: '150px' }} />}





                        </div>
                        <div style={{ padding: '20px', borderRadius: '22px', marginTop: '-20px' }}>
                            <div className="row">
                                <div className="col">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {databmi &&
                                            databmi.height ? <span style={{ fontSize: '20px', fontFamily: 'Sukhumvit Set Extra Bold', color: color1 }}>{text}</span> : '-'}

                                    </div>
                                </div>
                                <div className="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {databmi &&
                                            databmi.height ? <FaCheck style={{ color: color1, fontSize: '23px' }}></FaCheck> : '-'}
                                    </div>
                                </div>
                            </div>
                            <hr className="divider" />
                            <div className="row">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                    <img src="images/assesment/BMI.png" alt="รูปภาพ1" style={{ maxWidth: '90%' }} />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '15px' }}>
                                <div className="col">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ fontSize: '20px', fontFamily: 'Sukhumvit Set tadmai bold', color: "#0F774A" }}>{weight1 ? "Normal Weight" : ''}</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span style={{ fontSize: '20px', fontWeight: 'bold', color: "#0F774A", fontFamily: 'Sukhumvit Set tadmai bold' }}>{weight1 ? weight1.toFixed(2) : ''} - {weight2 ? weight2.toFixed(2) : ''}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ul
                                style={{
                                    width: "100%",
                                    padding: "0",
                                    margin: "0",
                                    listStyle: "none",
                                    textAlign: "center"
                                }}
                            >

                                <li

                                    style={{
                                        display: "inline-block",
                                        margin: "0 4px",
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: "#83c346"
                                    }}
                                />
                                <li

                                    style={{
                                        display: "inline-block",
                                        margin: "0 4px",
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: "gray"
                                    }}
                                />

                            </ul>
                        </div>
                    </div>
                </div>
                <LoadingOverlay active={loading2} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
                {/* {loading2 ? <Spinner /> : null} */}
            </div >
        </div >

    );
};

export default Profile;
