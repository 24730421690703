import React, { useMemo, useState } from 'react';
import { round } from '../../../services';
import { calculateDistanceOnTeamSteps } from '../../../model/ChallengeTeamDashboard.model';
import { BADGE_MAPPING, CHALLENGE_MAP_PULLUP, MAP_TARGET_BADGE_IMG } from '../../../common/challengeConstants';
import { formatNumberWithCommas } from '../../../utill.func';
import { getStepsActiveIndex, getStepsPercentage } from '../../../common/challenge/mapHelper';
import { ChallengeDetailSlider } from '../../../common';
import variables from '../../../common/commonConstant.scss';
import './ChallengePullUp.scss';

export default function ChallengePullUp({ title = '', stepsCompleted = 0, calories = 0, dailyData = { steps: 0, calories: 0 }, badgesCount = 0 }) {

  const [dailyStepsActiveTab, setDailyStepsActiveTab] = useState(false);

  const handleTabClick = (isDailyStepTab, event) => {
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    setDailyStepsActiveTab(isDailyStepTab);
  };

  const achievableTargetData = MAP_TARGET_BADGE_IMG[badgesCount] ?? {};
  const maxStepsTarget = CHALLENGE_MAP_PULLUP.TOTAL_TARGET;
  const isTargetCompleted = stepsCompleted >= maxStepsTarget;

  const DailyStepsPercentage = getStepsPercentage(Math.min(dailyData.steps, CHALLENGE_MAP_PULLUP.DAILY_STEPS_TARGET), CHALLENGE_MAP_PULLUP.DAILY_STEPS_TARGET);
  const innerGraphColor = DailyStepsPercentage === 0 ? 'transparent' : variables.flgreen1;
  const TotalStepsPercentage = getStepsPercentage(Math.min(stepsCompleted, maxStepsTarget), maxStepsTarget);
  const ActiveStepsIndex = getStepsActiveIndex(stepsCompleted);

  const SliderContent = useMemo((() => <><div id="challenge-drag">
    <h5>{title}</h5>
    {!isTargetCompleted && <nav className="white-outline-nav">
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        <button onClick={(event) => handleTabClick(false, event)} className={`nav-link${!dailyStepsActiveTab ? ' active' : ''}`}
          id="accumulated-steps-tab" data-bs-toggle="tab"
          data-bs-target="#accumulated-steps" type="button" role="tab"
          aria-controls="accumulated-steps" aria-selected={!dailyStepsActiveTab}>
          {CHALLENGE_MAP_PULLUP.TAB_TOTAL_STEPS}
        </button>
        <button onClick={(event) => handleTabClick(true, event)} className={`nav-link${dailyStepsActiveTab ? ' active' : ''}`}
          id="take-step-today-tab" data-bs-toggle="tab"
          data-bs-target="#take-step-today" type="button" role="tab" aria-controls="take-step-today"
          aria-selected={dailyStepsActiveTab}>
          {CHALLENGE_MAP_PULLUP.TAB_DAILY_STEPS}
        </button>
      </div>
    </nav>}
    <div className="tab-content" id="nav-tabContent">
      <div className={`tab-pane fade${!dailyStepsActiveTab ? ' active show' : ''}`} id="accumulated-steps-tab" role="tabpanel"
        aria-labelledby="accumulated-steps-tab">
        {isTargetCompleted &&
          <div className="accumulated-steps-tab_wrapper">
            <div className="accumulated-steps-challenge-completed-text">
              <div className="accumulated-steps-challenge-completed-heading">
                {CHALLENGE_MAP_PULLUP.GREETING_TEXT}
              </div>
              <div className="accumulated-steps-challenge-completed-description pb-2">
                {CHALLENGE_MAP_PULLUP.CHALLENGE_COMPLETE_MESSAGE}
              </div>
            </div>
            <div className="accumulated-steps-tab_badge-list">
              {BADGE_MAPPING.map((location) => <div className="badge-img-wrapper">
                <img src="/images/challenge/badges/bg-dark-badge@5x.png" alt="" className="badge-img-bg" />
                <img src={`/images/challenge/badges/steps-${location.imgPath}-badge@5x.png`} className="accumulated-steps-tab_badge" />
              </div>)}
            </div>
          </div>
        }
        <div className="range-item">
          <div className="range-input d-flex position-relative">
            <progress id="range-progress" max="100" value={TotalStepsPercentage} className="form-progress"></progress>
            <div className="range-line">
              <span className="active-line" style={{ width: `${TotalStepsPercentage}%` }}></span>
            </div>
            <div className="dot-line">
              <span className="active-dot" style={{ left: `${TotalStepsPercentage}%` }}>
                <img src="/images/challenge/icons/walk.svg" alt="" />
              </span>
            </div>
          </div>
          <ul className="list-inline list-unstyled">
            {Array.from({ length: 7 }, (_, index) => {
              return <li className={`list-inline-item ${ActiveStepsIndex >= index ? ' active' : ''}`}>
                <span></span>
              </li>
            })}
          </ul>
        </div>
        <div className="total-statistics-container">
          <div className="total-statistics-col">
            <span>{formatNumberWithCommas(stepsCompleted)}</span>
            <span>{CHALLENGE_MAP_PULLUP.STEPS_UNIT}</span>
          </div>
          <div className="total-statistics-col">
            <span>{round(calculateDistanceOnTeamSteps(stepsCompleted))}</span>
            <span>{CHALLENGE_MAP_PULLUP.DISTANCE_UNIT}</span>
          </div>
          <div className="total-statistics-col">
            <span>{formatNumberWithCommas(calories)}</span>
            <span>{CHALLENGE_MAP_PULLUP.CALORIE_UNIT}</span>
          </div>
        </div>
        {!isTargetCompleted && <div className="accumulated-steps-bottom">
          <div className="accumalated-steps-left">
            <div className="accumalated-steps-lefttop-text">
              <span>{CHALLENGE_MAP_PULLUP.WALK_TITLE}</span>
              <span className="accumalated-steps-value">{formatNumberWithCommas(round(CHALLENGE_MAP_PULLUP.STEPS_INTERVAL - (stepsCompleted % CHALLENGE_MAP_PULLUP.STEPS_INTERVAL)))}</span>
              <span>{CHALLENGE_MAP_PULLUP.STEPS_UNIT}</span>
            </div>
            <div>{CHALLENGE_MAP_PULLUP.DESTINATION}</div>
          </div>
          <div className="accumalated-steps-right">
            <div className="accumalated-steps-img-container">
              <img src={`/images/challenge/step_${achievableTargetData.target_img}.png`} alt={achievableTargetData.target_img} />
            </div>
          </div>
        </div>}
      </div>
      <div className={`tab-pane fade${dailyStepsActiveTab ? ' active show' : ''}`} id="take-step-today-tab" role="tabpanel"
        aria-labelledby="take-step-today-tab">
        <div className="take-step-today-section">
          <div className="take-step-today-left-section">
            <div className="take-step-pace-section">
              <div className="take-step-pace-value">{formatNumberWithCommas(dailyData.steps)}</div>
              <div className="take-step-pace-unit">{CHALLENGE_MAP_PULLUP.STEPS_UNIT}</div>
            </div>
            <div className="take-step-left-bottom-section">
              <div className="take-step-distance-section">
                <div className="take-step-distance-value">{formatNumberWithCommas(round(calculateDistanceOnTeamSteps(dailyData.steps)))}</div>
                <div className="take-step-distance-unit">{CHALLENGE_MAP_PULLUP.DISTANCE_UNIT}</div>
              </div>
              <div className="take-step-calories-section">
                <div className="take-step-calories-value">{formatNumberWithCommas(round(dailyData.calories))}</div>
                <div className="take-step-calories-unit">{CHALLENGE_MAP_PULLUP.CALORIE_UNIT}</div>
              </div>
            </div>
          </div>
          <div className="take-step-today-right-section">
            <div className="card progress-card progress-card-green-1">
              <div className="outer-circle">
                <div className="percent">
                  <svg>
                    <defs>
                      <linearGradient id="gradient"
                        gradientTransform="rotate(180)">
                        <stop offset="0%" stopColor={innerGraphColor}></stop>
                        <stop offset="100%" stopColor={variables.flgreen2}></stop>
                      </linearGradient>
                    </defs>
                    <circle cx="36.5" cy="36.5" r="31.5"></circle>
                    <circle cx="36.5" cy="36.5" r="31.5"
                      style={{ "--percent": DailyStepsPercentage, stroke: 'url(#gradient)' }}></circle>
                  </svg>
                  <div className="number">
                    <div className="progress-value">
                      {DailyStepsPercentage}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></>), [dailyStepsActiveTab, stepsCompleted]);

  return (
    <div className="challenge-details-section hidden-md" id="challenge-details-section">
      <ChallengeDetailSlider styleObj={{ backgroundColor: variables.challengeWhiteTranslucent }} autoHeight isAutoMaxHeight>
        {SliderContent}
      </ChallengeDetailSlider>
    </div>
  )
}
