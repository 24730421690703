import React from 'react';
import { HEALTH_SUMMARY_RESULT_CARD_DETAILS } from '../commonConstant';
import './HealthSummaryResultCard.scss';
import { round } from '../../services';

const HealthSummaryResultCard = ({ type, quantity }) => {
  const item = HEALTH_SUMMARY_RESULT_CARD_DETAILS[type];
  return (
    <div className={`health-summary-result-wrapper --${type}`}>
      <div className='health-summary-result-info'>
        <div className="health-summary-result-img-wrapper">
          <img src={item.icon} />
          <div className="health-summary-result-name">{item.name}</div>
        </div>
        <div className="health-summary-result-info-wrapper">
          <span className="header">{quantity ? round(quantity, 0).toLocaleString() : "-"}</span>
          <span className="info">{item.unit}</span>
        </div>
      </div>
    </div>
  );
};

export default HealthSummaryResultCard;
