export const ON_LOAD_ACTIVITY = 'ON_LOAD_ACTIVITY';
export const ON_CHANGE_ACTIVITY = 'ON_CHANGE_ACTIVITY';
export const ON_RESET_ACTIVITY = 'ON_RESET_ACTIVITY';
export const ON_CHANGE_ACTIVITIES = 'ON_CHANGE_ACTIVITIES';
export const ON_CHANGE_EXERCISE_MENU = 'ON_CHANGE_EXERCISE_MENU';
export const ON_CHECK_EXERCISE_MENU = 'ON_CHECK_EXERCISE_MENU';
export const ON_CANCEL_CHECKS = 'ON_CANCEL_CHECKS';
export const ON_ALL_CHECK_BY_STATE = 'ON_ALL_CHECK_BY_STATE';

export const HNW_GOAL_SETTING_ACTIVITY_INITIAL_STATE = {
  id: '',
  exerciseTime: 30,
  targetCalories: 0,
  exerciseCategory: [],
};

export default function activityReducer(
  state = HNW_GOAL_SETTING_ACTIVITY_INITIAL_STATE,
  action,
) {
  switch (action.type) {
    case ON_LOAD_ACTIVITY:
      return action.payload;
    case ON_CHANGE_ACTIVITY:
      return { ...state, ...action.payload }
    case ON_CHANGE_ACTIVITIES:
      if (!action.payload.exerciseCategory) {
        return state;
      }
      return {
        ...state,
        exerciseCategory: state.exerciseCategory.map((ec) => {
          if (ec.id === action.payload.exerciseCategory) {
            if (!!ec?.exerciseMenu?.length) {
              return {
                ...ec,
                exerciseMenu: [
                  ...ec.exerciseMenu,
                  ...action.payload.exerciseMenu,
                ],
              };
            } else {
              return { ...ec, exerciseMenu: action.payload.exerciseMenu };
            }
          }
          return ec;
        }),
      };
    case ON_CHANGE_EXERCISE_MENU:
      if (
        !action.payload.exerciseCategoryId ||
        !action.payload.exerciseMenuId
      ) {
        return state;
      }
      return {
        ...state,
        exerciseCategory: state.exerciseCategory.map((ec) => {
          if (ec.id === action.payload.exerciseCategoryId) {
            if (!!ec?.exerciseMenu?.length) {
              return {
                ...ec,
                exerciseMenu: ec.exerciseMenu.map((em) => {
                  if (em.id === action.payload.exerciseMenuId) {
                    return { ...em, suggestionTime: action.payload.value };
                  }
                  return em;
                }),
              };
            } else {
              return { ...ec, exerciseMenu: action.payload.exerciseMenu };
            }
          }
          return ec;
        }),
      };
    case ON_CHECK_EXERCISE_MENU:
      if (
        !action.payload.exerciseCategoryId ||
        !action.payload.exerciseMenuId
      ) {
        return state;
      }
      return {
        ...state,
        exerciseCategory: state.exerciseCategory.map((ec) => {
          if (ec.id === action.payload.exerciseCategoryId) {
            if (!!ec?.exerciseMenu?.length) {
              return {
                ...ec,
                exerciseMenu: ec.exerciseMenu.map((em) => {
                  if (em.id === action.payload.exerciseMenuId) {
                    return {
                      ...em,
                      isChecked: !action.payload.value,
                    };
                  }
                  return em;
                }),
              };
            } else {
              return { ...ec, exerciseMenu: action.payload.exerciseMenu };
            }
          }
          return ec;
        }),
      };
    case ON_CANCEL_CHECKS:
      if (!action.payload.exerciseCategoryId) {
        return state;
      }
      return {
        ...state,
        exerciseCategory: state.exerciseCategory.map((ec) => {
          if (ec.id === action.payload.exerciseCategoryId) {
            if (!!ec?.exerciseMenu?.length) {
              return {
                ...ec,
                exerciseMenu: ec.exerciseMenu.map((em) => ({
                  ...em,
                  isChecked: false,
                })),
              };
            } else {
              return { ...ec, exerciseMenu: action.payload.exerciseMenu };
            }
          }
          return ec;
        }),
      };
    case ON_ALL_CHECK_BY_STATE:
      return {
        ...state,
        exerciseCategory: state.exerciseCategory.map((ec) => {
          if (!!ec?.exerciseMenu?.length) {
            return {
              ...ec,
              exerciseMenu: ec.exerciseMenu.map((em) => ({
                ...em,
                isChecked:
                  action.payload.calculatedCheckboxState !== 'intermediate'
                    ? !em.isChecked
                    : true,
              })),
            };
          }
          return ec;
        }),
      };
    case ON_RESET_ACTIVITY:
      return HNW_GOAL_SETTING_ACTIVITY_INITIAL_STATE;
    default:
      return state;
  }
}
