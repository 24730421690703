import React from 'react';
import './AutoSyncBodyKeyVerification.scss';
import {
  AUTO_SYNC_MODAL_THAI,
  BODY_KEY_VERIFY_ERROR,
} from '../../challengeConstants';

const supportCustomerDetail = (support) => (
  <span key={support}>
    {support}
    <br />
  </span>
);

export default function AutoSyncBodyKeyVerification({
  bodyKeyId,
  onChangeHandler,
  onClose,
  onVerify,
  validationFailed,
  errorDetail,
  amwayNumberError,
}) {
  return (
    <div className="auto-sync-body">
      <div className="auto-sync-body-title">{AUTO_SYNC_MODAL_THAI.TITLE} </div>
      <div className="auto-sync-body-info">
        {errorDetail.length > 0
          ? errorDetail.map(supportCustomerDetail)
          : AUTO_SYNC_MODAL_THAI.SUPPORT_CUSTOMER.map(supportCustomerDetail)}
        {amwayNumberError ? (
          <span className='abo-id-not-found-err'>{BODY_KEY_VERIFY_ERROR.ABO_ID_NOT_FOUND}</span>
        ) : null}
      </div>
      <div className="auto-sync-body-input-field">
        <input
          className={`auto-sync-input-box ${
            validationFailed ? 'error-value' : ''
          }`}
          value={bodyKeyId}
          onChange={onChangeHandler}
          name="bodyKeyId"
          inputMode="tel"
          type="text"
        />
      </div>
      <div className="auto-sync-body-action-box">
        <button className="btn btn-outline-black" onClick={onClose}>
          {AUTO_SYNC_MODAL_THAI.CANCEL_BTN}
        </button>
        <button
          className="btn btn-green btn-green-linear"
          disabled={!bodyKeyId || validationFailed}
          onClick={() => {
            if (!validationFailed) {
              onVerify();
            }
          }}
        >
          <span className="text-color-white">
            {AUTO_SYNC_MODAL_THAI.SUCCESS_BTN}
          </span>
        </button>
      </div>
    </div>
  );
}
