import { DeleteFilled, EditFilled, ShareAltOutlined } from '@ant-design/icons';
import { Button, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import config from '../../../config';
import { getContentById } from '../../../services/abuddy/content.service';
import { getCustomerByToken } from '../../../services/library/categories/categories.service';
import { getAboPublicContent } from '../../../services/library/contents/contents.service';
import { thaiDate } from '../../../utill.func';
import { EContentType } from '../common.library';
import ModalContentRemove from '../modal/modalContentRemove';
import ModalContentRemoveSuccess from '../modal/modalContentRemoveSuccess';

const liff = window.liff;

const ViewLibraryContent = () => {
  const [visibleModalRemove, setVisibleModalRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState([]);
  const [contentType, setContentType] = useState();
  const [visibleModalRemoveSuccess, setVisibleModalRemoveSuccess] =
    useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [isFromOutSide, setIsFromOutSide] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let isAmway;
    let id;
    const aboId = new URLSearchParams(location.search).get('aboId');
    const libraryId = new URLSearchParams(location.search).get('Id');
    let outSide = new URLSearchParams(location.search).get('outside');
    outSide = outSide === 'true';

    setIsFromOutSide(outSide);
    if (aboId) {
      id = aboId;
      isAmway = EContentType.MY_LIBRARY;
    }
    if (libraryId) {
      id = libraryId;
      isAmway = EContentType.AMWAY_LIBRARY;
    }
    fetchData(id, isAmway);
  }, []);

  const checkEditPermission = (amwayNumber, createdBy) => {
    if (amwayNumber === createdBy) {
      setEditPermission(true);
    }
  };
  const fetchData = async (id, isAmway) => {
    setLoading(true);
    try {
      if (isAmway === EContentType.AMWAY_LIBRARY) {
        const content = await getContentById(id);
        if (content === '') {
          message.error('Content not found');
          setTimeout(() => liff.closeWindow(), 2000);
        }
        setContent([content]);
      } else {
        const content = await getAboPublicContent(id);
        if (content === '') {
          message.error('Content not found');
          setTimeout(() => liff.closeWindow(), 2000);
        }
        setContent([content]);
        const customer = await getCustomerByToken();
        if (customer?.amwayNumber) {
          checkEditPermission(customer.amwayNumber, content.createdBy);
        } else {
          setEditPermission(false);
        }
      }
      setContentType(isAmway);
    } catch (error) {
      let Errortext = 'Something went wrong';
      if (error?.response?.status === 404) {
        Errortext = error?.response.statusText;
      }
      alert(Errortext);
    }
    setLoading(false);
  };

  const replaceNewLineHTML = (text) => {
    return text.replaceAll(/\n/g, '<br/>');
  };

  const shareData = async () => {
    const param = window.location.search;
    const liffUrl = process.env.REACT_APP_LIFF_URL;
    let url;
    if (param.includes('abo')) {
      if (param.includes('outside')) {
        url = `${liffUrl}/ln/library/content${param}`;
      } else {
        url = `${liffUrl}/ln/library/content${param}&outside=true`;
      }
    } else {
      if (param.includes('outside')) {
        url = `${liffUrl}/library/content${param}`;
      } else {
        url = `${liffUrl}/library/content${param}&outside=true`;
      }
    }

    const share = await liff
      .shareTargetPicker([
        {
          type: 'text',
          text: url ? `${url}` : 'link',
        },
      ])
      .then(() => {
        console.log('sharing');
      })
      .catch((err) => {
        console.log('error', err);
        alert('err', err);
        liff.closeWindow();
      });
    setTimeout(() => liff.closeWindow(), 2000);
  };

  return (
    <Spin tip="Loading" spinning={loading}>
      {content &&
        content.map((item, index) => (
          <div className="view-content-wrap" key={item._id + index}>
            <div className="content-heading-hl">
              <img
                className="view-content-image"
                src={`${config.S3_URL}${item.imageContent}`}
              />
              {contentType === EContentType.MY_LIBRARY && (
                <Button className="btn-share" onClick={() => shareData()}>
                  <ShareAltOutlined />
                  แชร์
                </Button>
              )}
            </div>
            <div className="content-box">
              <div className="content-desc">
                <h1 className="title">{item.name}</h1>
                {contentType === EContentType.MY_LIBRARY ? (
                  <div className="category-name">
                    <img
                      className="icon"
                      src="../../images/library/icon_category.svg"
                    />
                    {item.category.name}
                  </div>
                ) : (
                  <></>
                )}
                <div className="content-date">
                  <div className="content-date">
                    {thaiDate(item.createdDate, 'dd-MM-yyyy')}
                  </div>
                </div>

                <div
                  className="library-ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: `${replaceNewLineHTML(item.content)}`,
                  }}
                ></div>
              </div>
            </div>
            <div className={'action'}>
              {contentType === EContentType.MY_LIBRARY && editPermission ? (
                <div className="action-edit-content">
                  <Button
                    className="btn"
                    htmlType="submit"
                    onClick={() => {
                      history.push({
                        pathname: `/library/content/form`,
                        search: `id=${item._id}`,
                      });
                    }}
                  >
                    <EditFilled /> แก้ไข Content
                  </Button>

                  <Button
                    className="btn-outline"
                    onClick={() => {
                      setVisibleModalRemove(true);
                    }}
                  >
                    <DeleteFilled />
                  </Button>
                </div>
              ) : (
                <Button className="btn-share" onClick={() => shareData()}>
                  <ShareAltOutlined />
                  แชร์
                </Button>
              )}
              {!isFromOutSide ? (
                <Button
                  type="link"
                  onClick={() => {
                    history.go(-1);
                  }}
                >
                  ย้อนกลับ
                </Button>
              ) : (
                <></>
              )}
            </div>
            <ModalContentRemove
              visibleModal={visibleModalRemove}
              setVisibleModal={setVisibleModalRemove}
              setVisibleModalRemoveSuccess={setVisibleModalRemoveSuccess}
              title={item.name}
              item={item._id}
              setLoading={setLoading}
            />
            <ModalContentRemoveSuccess
              visibleModal={visibleModalRemoveSuccess}
              setVisibleModal={setVisibleModalRemoveSuccess}
              content={content}
            />
          </div>
        ))}
    </Spin>
  );
};
export default ViewLibraryContent;
