export class DrinksVolume {

    constructor(data = null) {

        if (data) {
            this.id = data._id || '';
            this.coffeeVolume = data.coffeeVolume || null;
            this.softDrinkVolume = data.softDrinkVolume || null;
            this.waterVolume = data.waterVolume || null;
            this.createdDate = data.createdDate || '';
            this.updatedDate = data.updatedDate || '';
        }

    }

}