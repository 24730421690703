import React, { useEffect, useState } from 'react';
import './UserErrorModal.scss';
import { NOTIFICATION_WRONG_USER_1, NOTIFICATION_WRONG_USER_2, NOTIFICATION_WRONG_USER_3 } from '../../../../../common/commonConstant';

export const LIFF_OPEN = 'open';

const UserErrorModal = (props) => {
    const [isLiffOpen, setIsLiffOpen] = useState('');

    useEffect(() => {
        const isLiffOpen = props.isOpen ? LIFF_OPEN : '';
        setIsLiffOpen(isLiffOpen);
    }, [props.isOpen]);

    const onClose = () => {
        props.onClose();
    };

    return (
        <section className={`error-panel ${isLiffOpen}`}>
            <div className='modal-dialog-center-warning'>
                <div className='modal-content-warning'>
                    <div className='modal-body-warning'>
                        <div className='line-id-message-wrapper'>
                            <img src='/images/assessment/icon_notification.svg'></img>
                            <p className='warning-text'>
                                {NOTIFICATION_WRONG_USER_1}
                                <br></br>
                                {NOTIFICATION_WRONG_USER_2}
                                <br></br>
                                {NOTIFICATION_WRONG_USER_3}
                            </p>
                        </div>
                    </div>
                    <div className='modal-footer-warning'>
                        <button onClick={onClose} type="button" className='btn-footer-warning'>
                            เลือกใหม่
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UserErrorModal;
