import React, { useState } from 'react';
import './FloatingWidget.scss';

export default function FloatingWidget({ onClick, children }) {
  const [isDragging, setIsDragging] = useState(false);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const handleDragStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
    handleDragMove(e);
  };

  const handleDragStop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    handleDragMove(e);
  };

  const handleDragMove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (isDragging) {
      setTranslate({
        x: translate.x + event.movementX,
        y: translate.y + event.movementY,
      });
    }
  };

  return (
    <div
      className="floating-widget"
      onClick={onClick}
      onPointerDown={handleDragStart}
      onPointerUp={handleDragStop}
      onPointerMove={handleDragMove}
      draggable={true}
      style={{
        transform: `translate(${translate.x}px, ${translate.y}px)`,
      }}
    >
      {children}
    </div>
  );
}
