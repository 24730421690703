import { get, memoize, filter, map, isArray, head, join, isEmpty, negate, isNil } from "lodash";

const APPCO_SEPARATOR = "และ";

const PARTY_TYPE_ORGANIZATION = "Organization";
const PARTY_TYPE_PERSON = "Person";
const PARTY_SUCCESSION_APP = "1";
const PARTY_SUCCESSION_CO = "2";

export const CORP = (party) => party.partyMst.partyTypeCd === PARTY_TYPE_ORGANIZATION;
export const CO = (party) => party.partyMst.partyTypeCd === PARTY_TYPE_PERSON && party.partyMst.successionSeqNo === PARTY_SUCCESSION_CO;
export const APP = (party) => party.partyMst.partyTypeCd === PARTY_TYPE_PERSON && party.partyMst.successionSeqNo === PARTY_SUCCESSION_APP;
export const hasBankAcc = (party) => isArray(party.bankAccountDetailList) && party.bankAccountDetailList.length > 0;
export const COhasBankAcc = (party) => CO(party) && hasBankAcc(party);
export const APPhasBankAcc = (party) => APP(party) && hasBankAcc(party);
export const takePartyOfType = (typeFn) => (partyList) => memoize((list) => filter(list, typeFn))(partyList);
export const takeOnlyCORP = takePartyOfType(CORP);
export const takeOnlyCO = takePartyOfType(CO);
export const takeOnlyCOhasBankAcc = takePartyOfType(COhasBankAcc);
export const takeOnlyAPP = takePartyOfType(APP);
export const takeOnlyAPPhasBankAcc = takePartyOfType(APPhasBankAcc);
export const constructName = (o) => join(filter([get(o, "givenName"), get(o, "middleName", ""), get(o, "familyName", "")], negate(isEmpty)), " ");
export const combineAccountName = (a, b) => `${a.bankAcctHolderName} ${APPCO_SEPARATOR} ${b.bankAcctHolderName}`;
export const bankAccount = (bankAcctId = `new-${Math.random() * 999999999}`, bankAcctHolderName = null, extras = {}) => ({ bankAcctId, bankAcctHolderName, ...extras });
export const getBankAccount = (party) => {
  if (party && hasBankAcc(party)) {
    const acc = head(party.bankAccountDetailList);
    return bankAccount(acc.bankAcctId, acc.bankAcctName, acc);
  } else if (party && isArray(party.nameList) && party.nameList.length > 0) {
    return bankAccount(undefined, constructName(head(party.nameList).localeName));
  }
  return bankAccount();
};

export const getAccountList = (partyList) => {

  if (takeOnlyCORP(partyList).length > 0) {
    return [getBankAccount(head(takeOnlyCORP(partyList)))];
  } else if (takeOnlyCO(partyList).length > 0 && takeOnlyAPP(partyList).length > 0) {
    const bestAcc = head(filter([
      getBankAccount(head(takeOnlyAPPhasBankAcc(partyList))),
      getBankAccount(head(takeOnlyCOhasBankAcc(partyList))),
    ], x => x.bankAccStatus === "VALID"));
    if (bestAcc) {
      return [bestAcc];
    }

    const APPAcc = getBankAccount(head(takeOnlyAPP(partyList)));
    const COAcc = getBankAccount(head(takeOnlyCO(partyList)));
    const APPCOAcc = bankAccount(COAcc.bankAccId, combineAccountName(APPAcc, COAcc));
    return [APPAcc, COAcc, APPCOAcc];
  }
  return map(takeOnlyAPP(partyList), getBankAccount);
};
