
import React from 'react';
import './termsAndPolicy.scss';

export const UserDataDeleteInfo = ({ retryPopup, setRetryPopup, deleteAssessmentDetail }) => {
    const retryModalClass = retryPopup ? "retryModalClass" : "";
    return (
    <div className='asm-modal-ctn'>
        <div className={`asm-modal-ctn-btn ${retryModalClass}`}>
            <button onClick={deleteAssessmentDetail} className='asm-modal-ctn-btn-btn1'>{'ลบข้อมูลทั้งหมด'}</button>
            <button onClick={() => setRetryPopup(false)} className='asm-modal-ctn-btn-btn2'>{'ฉันไม่ต้องการลบข้อมูล'}</button>
        </div>
    </div>)
}

export default UserDataDeleteInfo;
