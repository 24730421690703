import React, { useState, useEffect } from 'react';
import { getPriceFormat } from '../../../../services/promotion/utils.service';

export const ProfileFooter = props => {
  const { notPurchaseProfile, addProfileToCart, accountType } = props;

  const calculatePricePVBV = () => {
    const details = {
      price: 0, pv: 0, bv: 0
    };
    if (notPurchaseProfile && notPurchaseProfile.length > 0) {
      notPurchaseProfile.forEach(profile => {
        if (profile.isSelected) {
          const isSolutionPlan = profile && profile.selectedSopPlan &&
            profile.selectedSopPlan.qualificationRules &&
            profile.selectedSopPlan.qualificationRules.length > 0 ? true : false;
          if (isSolutionPlan) {
            let children = profile.currentElementView &&
              profile.currentElementView.entries &&
              profile.currentElementView.entries.length &&
              profile.currentElementView.entries[0].children ?
              profile.currentElementView.entries[0].children : [];
            if (children && children.length) {
              children.forEach(child => {
                const filteredProduct = profile.recommendedProduct.find(product => product.id === child.selectedProductCode);
                let aboPrice = 0;
                if (filteredProduct) {
                  aboPrice = filteredProduct.prices.find((value) => value.type === 'ABO');
                  aboPrice = parseInt(aboPrice.price);
                  details.price += aboPrice * child.quantity;
                  if (filteredProduct.amwayValue) {
                    details.pv += (filteredProduct.amwayValue.pointValue || 0) * child.quantity;
                    details.bv += (filteredProduct.amwayValue.businessVolume || 0) * child.quantity;
                  }
                }
              });
            }
          } else {
            let aboPrice = profile.sopProduct.prices.find((value) => value.type === 'ABO');
            details.price += parseInt(aboPrice.price);
            if (profile.sopProduct && profile.sopProduct.amwayValue) {
              details.pv += profile.sopProduct.amwayValue.pointValue || 0;
              details.bv += profile.sopProduct.amwayValue.businessVolume || 0;
            }
          }
        }
      })
    }

    return (
      <div className='col-5'>
        <span className='row justify-left footer-price'>฿ {getPriceFormat(details.price)}</span>
        {accountType === 'ABO' && <span className='row justify-left footer-pvbv'>PV {getPriceFormat(details.pv)} / BV {getPriceFormat(details.bv)}</span>}
      </div>
    )
  }

  const getTotalProfileAdded = () => {
    let profileAddedCount = 0;
    if (notPurchaseProfile && notPurchaseProfile.length > 0) {
      const profileAdded = notPurchaseProfile.filter(profile => profile.isSelected);
      if (profileAdded && profileAdded.length) {
        profileAddedCount = profileAdded.length;
      }
    }
    return profileAddedCount;
  }

  return (
    <div className='row no-margin-row profile-list-footer justify-left'>
      {calculatePricePVBV()}
      <div>
        <button disabled={getTotalProfileAdded() === 0}
          onClick={() => addProfileToCart()}
          className="profile-btn-purchase profile-footer-btn floating-right">
          {getTotalProfileAdded() === 0 ? <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.25 7.00698C9.25 5.20703 10.7064 3.75 12.5002 3.75C14.2939 3.75 15.75 5.20694 15.75 7.00698V8.25H9.25V7.00698ZM7.75 9.75V12C7.75 12.4142 8.08579 12.75 8.5 12.75C8.91421 12.75 9.25 12.4142 9.25 12V9.75H15.75V12C15.75 12.4142 16.0858 12.75 16.5 12.75C16.9142 12.75 17.25 12.4142 17.25 12V9.75H18.75V17.2714C18.75 19.4175 16.8593 21.25 14.4076 21.25H10.5924C8.1407 21.25 6.25 19.4175 6.25 17.2714V9.75H7.75ZM7.75 8.25V7.00698C7.75 4.381 9.87556 2.25 12.5002 2.25C15.1249 2.25 17.25 4.38109 17.25 7.00698V8.25H19.5C19.9142 8.25 20.25 8.58579 20.25 9V17.2714C20.25 20.3486 17.5811 22.75 14.4076 22.75H10.5924C7.4189 22.75 4.75 20.3486 4.75 17.2714V9C4.75 8.58579 5.08579 8.25 5.5 8.25H7.75Z" fill="#9EA0A3" />
          </svg>
            : <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.25 7.00698C9.25 5.20703 10.7064 3.75 12.5002 3.75C14.2939 3.75 15.75 5.20694 15.75 7.00698V8.25H9.25V7.00698ZM7.75 9.75V12C7.75 12.4142 8.08579 12.75 8.5 12.75C8.91421 12.75 9.25 12.4142 9.25 12V9.75H15.75V12C15.75 12.4142 16.0858 12.75 16.5 12.75C16.9142 12.75 17.25 12.4142 17.25 12V9.75H18.75V17.2714C18.75 19.4175 16.8593 21.25 14.4076 21.25H10.5924C8.1407 21.25 6.25 19.4175 6.25 17.2714V9.75H7.75ZM7.75 8.25V7.00698C7.75 4.381 9.87556 2.25 12.5002 2.25C15.1249 2.25 17.25 4.38109 17.25 7.00698V8.25H19.5C19.9142 8.25 20.25 8.58579 20.25 9V17.2714C20.25 20.3486 17.5811 22.75 14.4076 22.75H10.5924C7.4189 22.75 4.75 20.3486 4.75 17.2714V9C4.75 8.58579 5.08579 8.25 5.5 8.25H7.75Z" fill="#FAFAFA" />
            </svg>}
          ใส่ตะกร้า ({getTotalProfileAdded()}/{notPurchaseProfile?.length})
        </button>
      </div>
    </div>
  )
}