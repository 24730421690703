import { GET_CHALLENGE_VIDEOS, GET_CHALLENGE_VIDEOS_FAILURE, GET_CHALLENGE_VIDEOS_SUCCESS, GET_YOUTUBE_VIDEOS, GET_YOUTUBE_VIDEOS_FAILURE, GET_YOUTUBE_VIDEOS_SUCCESS, UPDATE_VIDEO_STATUS, UPDATE_VIDEO_STATUS_FAILURE, UPDATE_VIDEO_STATUS_SUCCESS } from "../../actions/types";

const initialState = {
  challengeVideoList: [],
  loading: false,
  error: null
}
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CHALLENGE_VIDEOS:
    case GET_YOUTUBE_VIDEOS:
    case UPDATE_VIDEO_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CHALLENGE_VIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        challengeVideoList: action.payload,
        error: null,
      };
    case GET_YOUTUBE_VIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        challengeVideoList: action.payload,
      }
    case UPDATE_VIDEO_STATUS_SUCCESS:
      const { link, thumnail, updated_at, date, day, status, stoppedAt } = action.payload;

      const updatedChallengeVideoList = state.challengeVideoList.map(video => {
        if (video.link === link) {
          return { ...video, updated_at, status, stoppedAt };
        }
        return video;
      });

      return {
        ...state,
        loading: false,
        challengeVideoList: updatedChallengeVideoList,
      };
    case GET_CHALLENGE_VIDEOS_FAILURE:
    case GET_YOUTUBE_VIDEOS_FAILURE:
    case UPDATE_VIDEO_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}