import React, { useEffect, useState } from 'react';
import { displayStockLevelStatus } from '../../../services/promotion/utils.service';
import '../../promotion/CartPromotion.scss';
import QuantityStepper from '../QuantityStepper';
import { UpdateState } from '../SelectProduct/PromotionProduct';
import { ProductItemType } from './Product';

export const ProductVariant = (props) => {
  let [orderTotal, setOrderTotal] = useState(0);
  const [variant, setVariant] = useState();
  const [disableQtyBox, setDisableQtyBox] = useState(false);

  useEffect(() => {
    if (props.isFirstVariant) {
      props.setTotalSelected(props.configuredQty);
    }
    props.isLiffopen && setOrderTotal(props.quantity);

    if (!props.isLiffopen && !props.isConfirmVariant && variant) {
      const { productId, quantity } = variant;
      const productAlias =
        props.productItemType === ProductItemType.SELECT
          ? props.productId
          : props.kitEntryCode;
      props.setProductSelected(
        productId,
        quantity,
        UpdateState.CLEAR,
        productAlias,
      );
    }
  }, [props.isLiffopen]);

  useEffect(() => {
    if(orderTotal > 0) {
      setDisableQtyBox(true)
    }
  }, [orderTotal]);


  const increaseOrder = () => {
    const selectedTotal = props.selectedTotal + 1;
    if (selectedTotal > props.configuredQty) {
      return;
    }

    setVariant({
      quantity: orderTotal,
      productId: props.productVariant.id,
    });
    orderTotal += 1;
    setOrderTotal(orderTotal);

    props.setTotalSelected(selectedTotal);
    const productAlias =
      props.productItemType === ProductItemType.SELECT
        ? props.productId
        : props.kitEntryCode;

    props.setProductSelected(
      props.productVariant.id,
      orderTotal,
      UpdateState.CHANGE,
      productAlias,
    );
    setDisableQtyBox(false)
  };

  const decreaseOrder = () => {
    if (orderTotal === 0) {
      return;
    }
    setDisableQtyBox(false)
    setVariant({
      quantity: orderTotal,
      productId: props.productVariant.id,
    });
    orderTotal -= 1;
    setOrderTotal(orderTotal);

    const selectedTotal = props.selectedTotal - 1;
    props.setTotalSelected(selectedTotal);
    const productAlias =
      props.productItemType === ProductItemType.SELECT
        ? props.productId
        : props.kitEntryCode;

    props.setProductSelected(
      props.productVariant.id,
      orderTotal,
      UpdateState.CHANGE,
      productAlias,
    );
  };

  const renderProductVariant = () => {
    const productVariant = props.productVariant;
    const { identifier, stock } = productVariant;
    const img =
      productVariant.galleryImages && productVariant.galleryImages.length > 0
        ? productVariant.galleryImages[0].image_url
        : '';
    return (
      <div className="product-container-variant">
        <div className="card-product-img-variant">
          <img
            className="card-product-img-variant-size"
            src={img}
            alt="product image"
          />
        </div>
        <div className="flex product-variant-identifier">
          <div className="card-product-title-variant m-auto pr-5">
            {identifier}
            {displayStockLevelStatus(stock)}
          </div>
          <QuantityStepper
            orderTotal={orderTotal}
            increaseOrder={increaseOrder}
            decreaseOrder={decreaseOrder}
            disableIncreaseStepper={props.disableIncreaseStepper}
            disableStepperBox = {props.disableStepperBox}
            disableQtyBox = {disableQtyBox}

          />
        </div>
      </div>
    );
  };

  return renderProductVariant();
};

export default ProductVariant;
