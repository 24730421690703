import React, { useEffect, useState } from 'react';
import './HnWDeleteModal.scss';
export const LIFF_OPEN = 'open';

const HnWDeleteModal = (props) => {
  const [isLiffOpen, setIsLiffOpen] = useState('');

  useEffect(() => {
    const isLiffOpen = props.isOpen ? LIFF_OPEN : '';
    setIsLiffOpen(isLiffOpen);
  }, [props.isOpen]);

  const onClose = () => {
    props.onClose();
  };

  return (
    <section className={`hnw-panel-delete ${isLiffOpen}`}>
      <div className="select-coupon-panel">
        <div className="header">
          <button className="btn-close" onClick={onClose}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#2C2C2C"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
              <path
                d="M18 18L6 6"
                stroke="#2C2C2C"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <div className="hdr hnw-delete-modal-class">
            <div className="header-text">{props.title}</div>
            {props.subtitle?.length
              ? props.subtitle.map((title) => (
                  <div className="subheader-text">{title}</div>
                ))
              : null}
          </div>
        </div>
        <div className="font-stock">{props.children}</div>
      </div>
    </section>
  );
};

export default HnWDeleteModal;
