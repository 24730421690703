
import React, { useEffect, useState } from 'react';
import { Slideup } from '../../../../../common';
import { FOOD_TABS_MAPPING } from '../../../../../common/commonConstant';
import './FoodNavTabs.scss';

export const FoodNavTabs = ({ handleSearchClick = () => { }, onTabClick = () => { }, onMenuClick = () => { }, activeTab, parentRef }) => {
  const [scrolledAboveTop, setScrolledAboveTop] = useState(false);

  const toggleStickyMenu = () => {
    const foodNavElemTop = parentRef.current.offsetTop;

    // Check if scrolled above the topmost point of the screen
    if (window.scrollY > foodNavElemTop) {
      setScrolledAboveTop(true);
    } else {
      setScrolledAboveTop(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', toggleStickyMenu);
    return () => document.removeEventListener('scroll', toggleStickyMenu);
  }, []);

  const stickyHeaderClass = scrolledAboveTop ? ' sticky' : '';

  return <>
    <nav className={`supplement-list-nav${stickyHeaderClass}`}>
      <div onClick={handleSearchClick} className="input-search-sticky">
        <img src="/images/icon-search.svg" />
      </div>
      <div className="list-sticky">
        <button type="button" onClick={onMenuClick} data-bs-toggle="modal" data-bs-target="#welcomeModal">
          <img src="/images/healthAndWellness/foodCalories/icons/menu.svg" />
        </button>
      </div>
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        {Object.values(FOOD_TABS_MAPPING).map((item) => (
          <button key={item.name} onClick={() => onTabClick(item.name)} className={`nav-link${activeTab === item.name ? ' active' : ''}`} id={`nav-${item.name}-tab`} data-bs-toggle="tab"
            data-bs-target={`#nav-${item.name}`} type="button" role="tab" aria-controls={`nav-${item.name}`}
            aria-selected={activeTab === item.name}>
            <img src={item.iconUrl} className="nav-tabs-normal-img" />
            <img src={item.iconUrlActive} className="nav-tabs-normal-active-img" />
            <span>{item.label}</span>
          </button>))
        }
      </div>
    </nav>
    {scrolledAboveTop && <Slideup />}
  </>;
};

export default FoodNavTabs;
