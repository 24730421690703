import React from 'react';
import { EDIT_GOAL_MODAL_TEXT, EDIT_GOAL_MODAL } from '../commonConstant';
import './HealthTwoButtonModal.scss';
import HnWNumpadModal from '../hnWNumpadModal/HnWNumpadModal';

export const DELETE_OPEN = 'open';

const HealthTwoButtonModal = ({
  type,
  onClickBack,
  onLeftBtnClick = () => {},
  onRightBtnClick = () => {},
  isOpen,
}) => {
  return (
    <div className="hnw-two-button-modal">
      <HnWNumpadModal openNumpad={isOpen}>
        <div className="modal-header">
          <div className="modal-header-content">
            <button
              onClick={onClickBack}
              type="button"
              className="btn btn-green-rounded"
              data-bs-dismiss="modal"
            >
              <img src="/images/healthAndWellness/icon/arrow-left.svg" />
            </button>
            <span className="">{EDIT_GOAL_MODAL_TEXT.BACK_BTN}</span>
          </div>
        </div>

        <div className="modal-body">
          {EDIT_GOAL_MODAL_TEXT.MANAGE_GOAL}
          {EDIT_GOAL_MODAL[type]}
        </div>

        <div className="modal-footer">
          <button
            onClick={onLeftBtnClick}
            className="btn btn-red"
            data-bs-toggle="modal"
            data-bs-target="#deleteModalios"
            data-bs-dismiss="modal"
          >
            {EDIT_GOAL_MODAL_TEXT.DELETE_BUTTON}
          </button>
          <button
            onClick={onRightBtnClick}
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#successModal"
            data-bs-dismiss="modal"
          >
            {EDIT_GOAL_MODAL_TEXT.EDIT_BUTTON}
          </button>
        </div>
      </HnWNumpadModal>
    </div>
  );
};

export default HealthTwoButtonModal;
