import React, { useEffect } from 'react';
import './HnwFooter.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import commonConstant, { FOOTER_MENU_MAPPING, policyTitle, routePathKey } from '../commonConstant';
import { fetchPolicies, fetchPolicyStatus } from '../../thunk/hnwHome.thunk';


const ChallengeFooter = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeStatus = useSelector(
    (state) => state.footerActiveStatus?.footerActiveState,
  );
  const customerData = useSelector((state) => state.customer);
  const { policyStatus, policies } = useSelector((state) => state.home);
  const { isAuthTokenSaved } = useSelector(
    (state) => state.tokenStatus,
  );

  const footerImg = FOOTER_MENU_MAPPING.find(
    (obj) => obj.id === activeStatus,
  )?.navBarUrl;

  const handleMenuClick = (url) => history.push(policyStatus ? url : commonConstant.pathPolicy);

  useEffect(() => {
    if (isAuthTokenSaved &&!customerData.hasOwnProperty('isPolicyAccepted') && !policies?.length) {
      dispatch(fetchPolicies());
    }
  },[isAuthTokenSaved])

  useEffect(() => {
    if (!policyStatus && policies.length) {
      dispatch(
        fetchPolicyStatus(
          policies.find(
            (el) => el.title.toLowerCase() === policyTitle.home_policy,
          ),
        ) ?? null,
      )
    }
  },[policies])
  
  return (
    <div className="hnw-footer-main">
      <img src={footerImg} className="hnw-footer-main-img" />
      <div className="hnw-footer-main__bottom-wrapper">
        {FOOTER_MENU_MAPPING.map((menuObj) => {
          const menuClass =
            menuObj.id === activeStatus
              ? 'footer-tab footer-tab-active'
              : 'footer-tab';
          return (
            <label
              key={menuObj.id}
              className={menuClass}
              onClick={() => handleMenuClick(menuObj.routeUrl)}
            >
              <img src={menuObj.iconUrl} />
              <div className="footer-tab-name">{menuObj.label}</div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default ChallengeFooter;
