import { getBodyKeyDifference } from "../services/healthWellness/bodyDataDifference.service";
import { GET_BODY_DATA_DIFFERENCE, GET_BODY_DATA_DIFFERENCE_FAILURE, GET_BODY_DATA_DIFFERENCE_SUCCESS } from "./types";

export const fetchBodyDifference = () => (dispatch) => {
    dispatch({ type:GET_BODY_DATA_DIFFERENCE });

    return getBodyKeyDifference()
        .then((result) => {
            dispatch({
                type: GET_BODY_DATA_DIFFERENCE_SUCCESS,
                payload: result,
            });
            return result;
        })
        .catch(function (error) {
            dispatch({ type: GET_BODY_DATA_DIFFERENCE_FAILURE, payload: error.message });
        });
};