import React from 'react';
import './style.scss';

export default function BasicTab({ tabs, onTabClick = () => {}, activeTab }) {
  return (
    <nav className="supplement-list-nav">
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        {Object.values(tabs).map((item) => (
          <button
            key={item.name}
            onClick={() => onTabClick(item.name)}
            className={`nav-link${activeTab === item.name ? ' active' : ''}`}
            id={`nav-${item.name}-tab`}
            data-bs-toggle="tab"
            data-bs-target={`#nav-${item.name}`}
            type="button"
            role="tab"
            aria-controls={`nav-${item.name}`}
            aria-selected={activeTab === item.name}
          >
            {item?.iconUrl ? (
              <img src={item.iconUrl} className="nav-tabs-normal-img" />
            ) : null}
            {item?.iconUrlActive ? (
              <img
                src={item.iconUrlActive}
                className="nav-tabs-normal-active-img"
              />
            ) : null}
            <span>{item.label}</span>
          </button>
        ))}
      </div>
    </nav>
  );
}
