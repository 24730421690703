import React, { useEffect, useCallback } from 'react';
import { Loader } from '../';

const InfiniteScroll = ({ items, loading, itemComponent: ItemComponent, fetchData, itemProps, shouldFetchData = true }) => {
  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (!loading && scrollTop + clientHeight >= scrollHeight - 20) {
      shouldFetchData && fetchData(); // Assuming each page has 10 items
    }
  }, [items, loading, fetchData]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (<>
    {items.map((item, index) => (
      <ItemComponent item={item} key={`${item.id}${index}`} {...itemProps} />
    ))}
    {loading && <Loader />}
  </>
  );
};

export default InfiniteScroll;
