import React, { useEffect, useState } from 'react';
import './QuantityStepper.scss';
export const QuantityStepper = (props) => {
	const { decreaseDisabled = false, increaseDisabled = false,decreaseEvent,quantity = 0,increaseEvent} = props;
	return (
		<div className='quantity-stepper'>
			<div className="increment-container">
				<button
					disabled={decreaseDisabled}
					className="btn-decrease"
					onClick={()=> decreaseEvent()}
				>
					<svg width="13" height="4" viewBox="0 0 13 4" fill="none" xmlns="http://www.w3.org/2000/svg">
						<line y1="2.3335" x2="13" y2="2.3335" stroke="#2C2C2C" strokeWidth="3" />
					</svg>
				</button>
				<div className='quanity-text' style={{ margin: 'auto 5px' }}>
				  {quantity}
				</div>
				<button
					disabled={increaseDisabled}
					className="btn-increase"
					onClick={() => increaseEvent()}
				>
					{increaseDisabled ? <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<line y1="6.7829" x2="13" y2="6.7829" stroke="#9EA0A3" strokeWidth="3" />
						<line x1="6.5" y1="0.282959" x2="6.5" y2="13.283" stroke="#9EA0A3" strokeWidth="3" />
					</svg>
						: <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M5 8.27783V13.2778H8V8.27783H13V5.27783H8V0.277832H5V5.27783H0V8.27783H5Z" fill="#2C2C2C" />
						</svg>}
				</button>
			</div>
		</div>
	)
}