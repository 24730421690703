import { getChallengeQuiz, updateQuizUserAnswers, getUpdatedAnswers } from "../../services/challenge/challengeQuiz.service";
import { FETCH_CHALLENGE_QUIZ_FAILURE, FETCH_CHALLENGE_QUIZ_SUCCESS, UPDATE_CHALLENGE_QUIZ_ANSWERS_SUCCESS, UPDATE_CHALLENGE_QUIZ_ANSWERS_FAILURE, UPDATE_CHALLENGE_QUIZ_ANSWERS, FETCH_CHALLENGE_QUIZ, FETCH_UPDATED_ANSWER, FETCH_UPDATED_ANSWER_SUCCESS, FETCH_UPDATED_ANSWER_FAILURE } from "../types";

export const fetchChallengeQuiz = (params) => (dispatch) => {
    dispatch({ type: FETCH_CHALLENGE_QUIZ });

    return getChallengeQuiz(params)
        .then((result) => {
            dispatch({
                type: FETCH_CHALLENGE_QUIZ_SUCCESS,
                payload: result,
            });
            return result;
        })
        .catch(function (error) {
            dispatch({ type: FETCH_CHALLENGE_QUIZ_FAILURE, payload: error.message });
        });
};

export const updateChallengeAnswer = (examUserId, answers, isSendToChallenge) => async (dispatch) => {
    dispatch({
        type: UPDATE_CHALLENGE_QUIZ_ANSWERS
    });

    try {
        const requestId = await updateQuizUserAnswers(examUserId, answers, isSendToChallenge);

        await new Promise(resolve => setTimeout(resolve, 3000));

        const result = await getUpdatedAnswers(requestId);

        if (result?.is_error) {
            dispatch({
                type: FETCH_UPDATED_ANSWER_FAILURE,
                payload: result,
                hasError: true
            })
        }
        else {
            dispatch({
                type: FETCH_UPDATED_ANSWER_SUCCESS,
                payload: result,
                hasError: false
            });
        }
        return result;
    } catch (error) {
        if (error.message) {
            dispatch({
                type: FETCH_UPDATED_ANSWER_FAILURE,
                payload: error.message
            });
        } else {
            dispatch({
                type: UPDATE_CHALLENGE_QUIZ_ANSWERS_FAILURE,
                payload: error.message
            });

        }
        throw error;
    }
}
