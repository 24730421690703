import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { osName } from 'react-device-detect';
import { connect } from 'react-redux';

import { alertActions } from '../../actions';
import { commonConstant } from '../../common';
import { Modal, AWDatepicker, OTPInput } from '../../helpers';
import { customerService, activityService, amwayService } from '../../services';
import Condition from '../condition/condition.jsx';
import _ from 'lodash';
import {checkLineMID} from '../../utill.func';

const today = new Date();
const maxYear = today.getFullYear() + 540;
const minYear = today.getFullYear() + 443;


class Forgot extends Component {
  constructor(props) {
    super(props);

    if (sessionStorage.getItem('customer') === null || sessionStorage.getItem('customer') === 'undefined') {
      window.location.replace(commonConstant.pathHome);
    }
    const customer = JSON.parse(sessionStorage.getItem('customer'));
    const { location } = props;
    const { state } = location;
    // const isCondition = location.state && location.state.from.pathname === '/abo';
    this.state = {
      customer: customer,
      cid:null,
      name: '',
      lastname: '',
      birthday: '',
      phone: '',
      loading: false,
      modalContent: '',
      isOpenModal: false,
      accept: false,
      condition: false,
      state: state,
      // isCondition: isCondition,
    };
  }

  handleChange = e => this.setState({ [e.target.id]: e.target.value });
  handleCheckBox = () => this.setState({ accept: !this.state.accept })
  handleCondition = () => this.setState({ condition: !this.state.condition })
  handleTextChange = e => {
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (e.target.value === '' || !regex.test(e.target.value)) {
      this.setState({ [e.target.id]: e.target.value });
    }
  }

  handleMobileChange = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || e.target.value.match(regex)) {
      this.setState({ [e.target.id]: e.target.value });
    }
  }

  handleCheckValidDate = () => {
    const { birthday } = this.state;
    const dateParts = birthday.split('-');
    const dateObject = new Date(birthday);
    if (dateObject.getFullYear() === +dateParts[0] && dateObject.getMonth() === dateParts[1] - 1 && dateObject.getDate() === +dateParts[2]) {
      return true;
    }
    return false;
  }

  handleSubmit = async (e) => {
    window.scrollTo(0, 1);
    e.preventDefault();

    this.setState({
      loading: true,
    });

    if (!this.handleCheckValidDate()) {
      this.toggleModal('invalidbd');
      this.setState({ loading: false });
      return false;
    }

    const date = new Date(this.state.birthday);
    const convertYearBEtoAD = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthWithZero = month <= 9 ? `0${month.toString()}` : month.toString();
    const day = date.getDate().toString();
    const dayWithZero = day <= 9 ? `0${day}` : day;
    const birthdayKey = convertYearBEtoAD.toString() + monthWithZero + dayWithZero;
    const cidKey = this.state.cid;
    const forgetKey = cidKey + birthdayKey;
    console.log('forgetKey',forgetKey)
    const response = await amwayService.forgot(forgetKey).catch(() => {
      return {
        status: 500,
        data: undefined
      }
    });

    console.log('forgot response',response)
    
    if(response.status && response.status === 500){
      this.toggleModal('notfound');
      this.setState({ loading: false});
      return false;
    } else if (!response.data) {
      this.toggleModal('cantsave');
      this.setState({ loading: false });
      return false;
    }

    const { AboName, DistNum, Message, Status, BusinessNature, partyId } = response.data.ForgetAboNoResult;

    const customerFromDistNum = await customerService.findMemberbyABONumber(DistNum);

    this.setState({ DistNum: DistNum});
    this.setState({ customerFromDistNum: customerFromDistNum});

    const cleanData = _.filter(customerFromDistNum.partyList, function (o) { return o.taxList[0] && o.taxList[0].taxTypeCd === 'CID' && o.partyMst && o.partyMst.roleCd === 'BusinessOwner'; });
    const last4Digit = cleanData.map(function (raw) {
      return raw.taxList[0].taxId;
    });

    let found = false;
    let citizenId = cidKey;

    for(let i in last4Digit){
      if(last4Digit[i].substr(-4) === cidKey){
        found = true;
        citizenId = last4Digit[i];
        break;
      }
    }

    if (!found) {
      this.setState({ isOpenModal: false, loading: false });
      this.toggleModal('notfound');
      return;
    }

    if (Status === 'I') {
      this.toggleModal('notfound');
      this.setState({ loading: false });
      return false;
    } else if (Status === 'E') {
      this.props.showError(Message);
      this.setState({ loading: false });
      return false;
    }

    const resMemberNumber = await customerService.findMemberNumber(DistNum);

    if (resMemberNumber && (resMemberNumber.uid !== this.state.customer.uid)) {
      this.setState({ isOpenModal: false, loading: false, oldLineId: resMemberNumber.uid, oldMember: resMemberNumber});
      this.toggleModal('duplicate');
      return;
    } 

    const customer = {
      name: AboName,
      _id: this.state.customer._id,
      birthday: `${birthdayKey}`,
      amwayNumber: parseInt(DistNum),
      cid: citizenId.substr(-4),
      partyId: partyId,
      socialType: 'LINE',
      type: BusinessNature.toLowerCase()
    };
    const updated = await customerService.update('notuseid',customer);
    if (!updated._id) {
      const errorMessage = updated.message instanceof Object ? updated.message.map((value) => Object.values(value.constraints).join('<br>')) : 'ระบบไม่สามารถบันทึกข้อมูลได้กรุณาติดต่อเจ้าหน้าที่';

      this.props.showError(errorMessage);
      this.setState({ loading: false });
      return false;
    }

    updated.name = AboName;
    sessionStorage.setItem('customer', JSON.stringify(updated));

    if (Status === 'A') {
      const params = {
        mid: this.state.customer.uid,
        cid: citizenId,
        customerId: this.state.customer._id,
        info: osName === 'IOS' ? 'iPhone' : osName,
        status: 'unblock',
        displayname: this.state.customer.displayName,
      };
      const replymessage = await checkLineMID(params);

      if (replymessage !== 'Success') {
        this.toggleModal('cantsave');
        this.setState({
          loading: false,
        });
        return false;
      }
    }

    activityService.create({
      uid: this.state.customer.uid,
      userId: this.state.customer._id,
      status: 'incomplete',
      action: 'FORGOT',
    });
    return this.props.history.push(updated.type === 'abo' ? '/success' : '/loginsuccess');
  };

  handleCloseModal = () => {
    this.setState({
      isOpenModal: false,
    });
  }
  toggleModal = (content) => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      modalContent: content
    });
  }

  handleModalOk = () => {
    const { modalContent } = this.state;
    this.toggleModal();
    switch (modalContent) {
      case 'duplicate':
        return  this.props.history.push('/changeMID', {myMember:this.state.customerFromDistNum, oldLineId:this.state.oldLineId, oldMember: this.state.oldMember})
      case 'notfound':
        return window.location = 'tel:027258000';
      case 'cantsave':
        return window.location = 'tel:027258000';
      default:
        break;
    }
  }

  handleModalContent = () => {
    const { modalContent, DistNum } = this.state;
    switch (modalContent) {
      case 'duplicate':
        return { title: `รหัส ${DistNum} มีการผูกกับ LINE ID อื่น`, detail: 'กรุณาคลิกปุ่มเปลี่ยนบัญชี เพื่อลงทะเบียน\nAmway Social Connect',type:'warning'  };
      case 'notfound':
        return { title: 'ไม่พบข้อมูล', detail: 'กรุณาติดต่อ Amway Contact Center<br /> 02-725-8000' };
      case 'cantsave':
        return { title: 'ระบบไม่สามารถบันทึกข้อมูลได้', detail: 'กรุณาติดต่อ Amway Contact Center<br /> 02-725-8000' };
      case 'card':
        return { title: null, detail: '<img src="/images/id-card-hint.png" alt="" />' };
      case 'invalidbd':
        return { title: 'วันเกิดไม่ถูกต้อง', detail: 'กรุณาระบุวันที่ให้ถูกต้อง' };
      default:
        break;
    }
  }

  render() {
    const { birthday, name, lastname, phone, isOpenModal, cid, modalContent, accept, condition } = this.state;
    const isTrue = birthday && name && lastname && phone && cid ? true : false;
    const btnActive = isTrue && accept  ? true : false;

    const content = this.handleModalContent();
    if (condition) { return <Condition fn={this.handleCondition} />; }
    return (
      <div className="regisPage no-bg _pt-35">
        <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
        {
          isOpenModal ?
            <Modal
              title={content.title}
              detail={content.detail}
              type='warning'
              closeBtn
              fnClose={this.toggleModal}
              fnOk={this.handleModalOk}
              okBtn={modalContent !== 'card'}
              okText={ modalContent === 'duplicate' ? 'เปลี่ยนบัญชี' : 'โทร'}
            /> : null
        }
        <form onSubmit={this.handleSubmit}>
          <div className="input-personal">
            <div className="_header">กรุณากรอกข้อมูลยืนยันตัวตน<br />
            เพื่อตรวจสอบการเป็นนักธุรกิจแอมเวย์</div>

            <div className="away-form">
              <div className="form-group">
                <label htmlFor="name">ชื่อ</label>
                <input onChange={this.handleTextChange} type="text" id="name" required value={name} placeholder="ชื่อ" maxLength="25" />
              </div>
              <div className="form-group">
                <label htmlFor="lastname">นามสกุล</label>
                <input onChange={this.handleTextChange} type="text" id="lastname" required value={lastname} placeholder="นามสกุล" maxLength="25" />
              </div>
              <div className="form-group">
                <label htmlFor="birthday">วันเกิด</label>
                <AWDatepicker
                  id="birthday" name="birthday"
                  maxYear={maxYear}
                  minYear={minYear}
                  value={birthday}
                  onChange={this.handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="phone">เบอร์โทรศัพท์ตามที่ให้ไว้กับบริษัท</label>
                <input pattern="[0-9]*" required type="tel" id="phone" value={phone} placeholder="เบอร์โทรศัพท์" maxLength="10" onChange={this.handleMobileChange} />
              </div>
              <div className="form-group _group">
                <label>เลขบัตรประชาชน 4 หลักสุดท้าย</label>
                <OTPInput id="cid" count={4} onChange={(value,obj) => this.handleChange({ target: { id:obj.id,value:obj.value } })}/>
                <i className="question-mark" onClick={() => this.toggleModal('card')}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.99756 10.4551C7.62256 10.4551 7.34131 10.7598 7.34131 11.1113C7.34131 11.4863 7.62256 11.7676 7.99756 11.7676C8.34912 11.7676 8.65381 11.4863 8.65381 11.1113C8.65381 10.7598 8.34912 10.4551 7.99756 10.4551ZM8.16162 9.89258C8.32568 9.89258 8.44287 9.77539 8.44287 9.61133C8.44287 8.60352 10.271 8.67383 10.271 7.10352C10.271 5.9082 9.19287 5.20508 7.97412 5.20508C6.91943 5.20508 6.3335 5.62695 5.84131 6.25977C5.74756 6.40039 5.771 6.56445 5.91162 6.6582L6.21631 6.89258C6.3335 6.96289 6.49756 6.93945 6.59131 6.82227C6.94287 6.37695 7.29443 6.14258 7.97412 6.14258C8.91162 6.14258 9.3335 6.63477 9.3335 7.10352C9.3335 8.11133 7.50537 7.9707 7.50537 9.61133C7.50537 9.77539 7.646 9.89258 7.78662 9.89258H8.16162ZM7.99756 3.42383C10.7632 3.42383 13.0601 5.69727 13.0601 8.48633C13.0601 11.2988 10.7866 13.5488 7.99756 13.5488C5.18506 13.5488 2.93506 11.2988 2.93506 8.48633C2.93506 5.69727 5.18506 3.42383 7.99756 3.42383ZM7.99756 2.67383C4.78662 2.67383 2.18506 5.29883 2.18506 8.48633C2.18506 11.6973 4.78662 14.2988 7.99756 14.2988C11.1851 14.2988 13.8101 11.6973 13.8101 8.48633C13.8101 5.29883 11.1851 2.67383 7.99756 2.67383Z" fill="#2C2C2C" />
                  </svg>
                </i>
              </div>
            </div>
          </div>
  
          {
            isTrue ?
              <div className="abo-text-center">
                <div className="input-checkbox">
                  <span onClick={this.handleCheckBox} className={`_box-check ${accept ? '_active' : ''}`}>
                    {
                      accept ?
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.87987 11.8301L13.8799 3.83012L13.1199 3.17012L6.11987 11.1701L6.87987 11.8301ZM2.16987 8.38012L6.16987 11.8801L6.82987 11.1201L2.82987 7.62012L2.16987 8.38012ZM13.8799 3.83012C13.9232 3.78021 13.9563 3.72226 13.9772 3.65958C13.9982 3.59689 14.0066 3.53069 14.0019 3.46476C13.9973 3.39883 13.9797 3.33446 13.9502 3.27533C13.9207 3.21619 13.8798 3.16345 13.8299 3.12012C13.78 3.07678 13.722 3.0437 13.6593 3.02276C13.5966 3.00181 13.5304 2.99343 13.4645 2.99807C13.3986 3.00271 13.3342 3.0203 13.2751 3.04982C13.2159 3.07934 13.1632 3.12021 13.1199 3.17012L13.8799 3.83012ZM6.49987 11.5001L6.16987 11.8801C6.27139 11.966 6.40241 12.0088 6.53504 11.9994C6.66766 11.9901 6.79139 11.9293 6.87987 11.8301L6.49987 11.5001ZM2.82987 7.62012C2.72909 7.53259 2.59767 7.48869 2.46452 7.49807C2.33137 7.50745 2.20739 7.56933 2.11987 7.67012C2.03235 7.7709 1.98845 7.90232 1.99783 8.03547C2.0072 8.16862 2.06909 8.29259 2.16987 8.38012L2.82987 7.62012Z" fill="#2C2C2C" />
                        </svg> : null
                    }
                  </span>
                  <Link onClick={this.handleCondition}>ยอมรับเงื่อนไข</Link>
                </div>
              </div>
              : null
          }
          <div className="text-center">
            <button className={`away-button ${btnActive ? 'active' : ''}`} type="submit" disabled={!btnActive}>ยืนยัน</button>
          </div>
        </form>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    showError: (message) => dispatch(alertActions.showError(message)),
  };
}

export default connect(null, mapDispatchToProps)(Forgot);
