import {
  SET_FOOTER_STATE_HOME,
  SET_FOOTER_STATE_CALORIES,
  SET_FOOTER_STATE_CHALLENGE,
  SET_FOOTER_STATE_EXERCISE,
  SET_FOOTER_STATE_PROFILE,
} from '../actions/types';
import { FOOTER_ROUTE_MAPPING } from '../common/commonConstant';

const initialState = {
  footerActiveState: FOOTER_ROUTE_MAPPING.home,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FOOTER_STATE_HOME:
      return {
        footerActiveState: FOOTER_ROUTE_MAPPING.home,
      };
    case SET_FOOTER_STATE_CALORIES:
      return {
        footerActiveState: FOOTER_ROUTE_MAPPING.calories,
      };

    case SET_FOOTER_STATE_CHALLENGE:
      return {
        footerActiveState: FOOTER_ROUTE_MAPPING.challenge,
      };
    case SET_FOOTER_STATE_EXERCISE:
      return {
        footerActiveState: FOOTER_ROUTE_MAPPING.exercise,
      };
    case SET_FOOTER_STATE_PROFILE:
      return {
        footerActiveState: FOOTER_ROUTE_MAPPING.profile,
      };

    default:
      return state;
  }
}
