import React, { useState } from 'react';
import {
  HealthFoodOverviewCircle,
  HealthNutritionCard,
  HealthNutritionProgressBar,
} from '../../../../../common';
import {
  dailyNutrition,
  HNW_FOOD_LOG,
  ICON_SALAD,
  ICON_SALAD_MAX,
  LABEL_CALORIES,
  nutritionCard,
} from '../../../../../common/commonConstant';
import variables from '../../../../../common/commonConstant.scss';
import SlickCarousel from '../../../../../common/slickCarousel/slickCarousel';
import { MAX_VAL_TO_SHOW } from '../../../../../constants';
import { round } from '../../../../../services';
import {
  getTodayFormatDate,
  getTotalFoodLogValues,
} from '../../../../../utill.func';
import Dropdown from '../dropdown/Dropdown';

const FoodLogOverview = ({ foodChartData = [], goalData }) => {
  const [displayDate, setDisplayDate] = useState(
    getTodayFormatDate(new Date()),
  );

  const [showText, setShowText] = useState(false);

  const selectedFood = getTotalFoodLogValues(foodChartData);
  const tableData =
    goalData && selectedFood
      ? nutritionCard.map((el) => ({
        ...el,
        diff: goalData[el.maxKeyName] - selectedFood[el.totalKeyName],
        value: selectedFood[el.totalKeyName],
        max: goalData[el.maxKeyName],
      }))
      : [];

  const beforeChange = (curr, next) => {
    setShowText(next !== 0);
  };

  return (
    <div className="food-overview-banner-content">
      <div className="container-fluid">
        <div
          className={`food-overview-banner-header${
            showText ? ' justify-content-space-between' : ''
            }`}
        >
          {showText && (
            <span className="overview-text">
              {HNW_FOOD_LOG.labelMapping.overviewBannerHeader}
            </span>
          )}
          <Dropdown displayText={displayDate} disabled />
        </div>
        <div className="nutrition-carousel-container">
          <SlickCarousel
            slidesToShow={1}
            slidesToScroll={1}
            dots={true}
            beforeChange={beforeChange}
          >
            <div className="first-slider-container">
              <HealthFoodOverviewCircle
                maxValue={goalData?.targetCalories}
                progressValue={round(selectedFood?.totalCalories, 0)}
                label={LABEL_CALORIES}
                icon={ICON_SALAD}
                iconMax={ICON_SALAD_MAX}
                strokeColor={variables.foodCircleStroke}
              />
              <div className="nutrition-bar-container">
                {dailyNutrition.map((el, index) => (
                  <HealthNutritionProgressBar
                    key={`progress${index}`}
                    value={selectedFood ? selectedFood[el.totalKeyName] : null}
                    max={goalData ? goalData[el.maxKeyName] : null}
                    label={el.label}
                  />
                ))}
              </div>
            </div>
            <div className="second-slider-container">
              <HealthNutritionCard nutritionData={tableData} maxValueToShow={MAX_VAL_TO_SHOW} />
            </div>
          </SlickCarousel>
        </div>
      </div>
    </div>
  );
};

export default FoodLogOverview;
