import config from "../../config";
import { BodyDataKey } from "../../model/BodyDataDifference.model";
import AxiosInterceptor from "../axiosInterceptor.service";

export const getBodyKeyDifference = async () => {
    try {
      const response = await AxiosInterceptor.get(
        `${config.API_URL}/customers/bmi-data-difference`
      );
      
      const bodyDataDifference = BodyDataKey.fromAPI(response?.data);
  
      return bodyDataDifference;
    } catch (error) {
      throw error;
    }
  };
  
  