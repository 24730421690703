import React, { useEffect, useState } from 'react';

import '../promotion/CartPromotion.scss';

export const LIFF_OPEN = 'open';

const LiffVariant = (props) => {
    const [isLiffOpen, setIsLiffOpen] = useState('');

    useEffect(() => {
        const isLiffOpen = props.isOpen ? LIFF_OPEN : '';
        setIsLiffOpen(isLiffOpen);
    }, [props.isOpen]);

    const onClose = () => {
        props.onClose();
    };

    return (
        <section className={`liff-panel-variant ${isLiffOpen}`}>
            <div className="select-coupon-panel-variant">
                <div className="header-variant font-bold">
                    <div className="header-variant-text">{props.title}</div>
                    <button className="btn-close-variant" onClick={onClose}>
                        X
                    </button>
                </div>
                <div className="product-count-container">
                    <div className="product-count">
                        <div className="product-count-text">{props.selectedProducts} / {props.maxProducts}</div>
                    </div>
                </div>
                <div className="font-stock">{props.children}</div>
            </div>
        </section>
    );
};

export default LiffVariant;
