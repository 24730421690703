import React from 'react';
import BoxContent from './BoxContent';
import LinearRowContent from './LinearRowContent';

export default function HnwNutritionGoalCardBody({
  type,
  content,
  healthGoal,
}) {
  return (
    <div className="card-body-section-nutrition d-flex flex-column">
      <LinearRowContent
        type={type}
        content={content}
        healthGoalValue={healthGoal?.targetCalories}
      />
      <div className="card-body-section-nutrition-grid">
        <BoxContent
          keyProperty={content.childDataCarbo}
          keyPropertyValue={healthGoal?.targetCarbs}
          keyPropertyUnit={content.childDataUnit}
        />
        <BoxContent
          keyProperty={content.childDataProtien}
          keyPropertyValue={healthGoal?.targetProtein}
          keyPropertyUnit={content.childDataUnit}
        />
        <BoxContent
          keyProperty={content.childDataFat}
          keyPropertyValue={healthGoal?.targetFat}
          keyPropertyUnit={content.childDataUnit}
        />
      </div>
    </div>
  );
}
