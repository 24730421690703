import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Circle,
} from '@react-google-maps/api';

import Geocode from 'react-geocode';

import './PlacePicker.scss';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const GeocodeapiKey = process.env.REACT_APP_GOOGLE_API_GEOCODE_KEY;
//! Waiting Google Api Key
const defaultLang = 'th';
const defaultRegion = 'th';

//Geocode configs

Geocode.setApiKey(GeocodeapiKey);
Geocode.enableDebug();

const containerStyle = {
  width: '100vw',
  height: '100vh',
};

const defaultMapOptions = {
  disableDefaultUI: true,
};

const defaultCircleOptions = {
  strokeColor: '#38539A',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#38539A',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 50,
  zIndex: 1,
};

const libraries = ['places'];

const PlacePicker = ({
  onPlaceSelect,
  showCenterMarker = true,
  showPlaceBubble = true,
  showResetBtn = true,
  onResetBtnClick,
  onPlaceSearch,
  type,
  showUserLocation = true,
  useUserLocation = true,
  mapOptions,
  userCircleOptions,
  lang,
  region,
  resetIconSrc,
  centerMarkerSrc,
}) => {
  const [center, setCenter] = useState({
    lat: 13.7563,
    lng: 100.5018,
  });
  const [userPosition, setUserPosition] = useState();
  const [zoom, setZoom] = useState(10);
  const [searchBox, setSearch] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [seletedAddress, setSeletedAddress] = useState();
  const inputRef = useRef();
  const [searchString, setSearchString] = useState(false);
  const [showModal, setShowModal] = useState();
  const options = useCallback(
    () => ({ ...defaultMapOptions, ...mapOptions }),
    [defaultMapOptions, mapOptions],
  );
  const circleOptions = useCallback(
    () => ({ ...defaultCircleOptions, ...userCircleOptions }),
    [defaultCircleOptions, userCircleOptions],
  );

  useEffect(() => {
    Geocode.setLanguage(lang ? lang : defaultLang);
    Geocode.setRegion(region ? region : defaultRegion);
    useUserLocation &&
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setUserPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setZoom(18);
        },
        function onError(error) {
          switch (error.code) {
            case 1:
              setShowModal(
                'กรุณาอนุญาตการเข้าถึงสถานที่ตั้ง แล้วลองใหม่อีกครั้ง',
              );
              break;
            case 2:
              setShowModal(
                'ไม่สามารถเข้าถึงสถานที่ตั้งของคุณได้ กรุณาลองใหม่อีกครั้ง',
              );
              break;
            case 3:
              setShowModal(
                'ไม่สามารถเข้าถึงสถานที่ตั้งของคุณได้ กรุณาลองใหม่อีกครั้ง',
              );
              break;

            default:
              setShowModal(
                `code: ${error.code}\ren` + `message: ${error.message}\n`,
              );
              break;
          }
        },
        { timeout: 7000 },
      );
  }, []);

  const onPlacesChanged = () => {
    setSearchString(inputRef.current.value);
    const places = searchBox.getPlaces()[0];
    setCenter({
      lat: places.geometry.location.lat(),
      lng: places.geometry.location.lng(),
    });
    setZoom(18);
    setSeletedAddress(places);
    onPlaceSearch && onPlaceSearch(places);
  };

  const onChangeCenter = () => {
    const lat = mapRef.center.lat();
    const lng = mapRef.center.lng();
    if (!searchString) {
      inputRef.current.value = '';
    } else {
      inputRef.current.value = searchString;
      setSearchString(null);
    }
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0];
        setSeletedAddress(address);
        setShowAddress(true);
      },
      () => {
        setShowModal('Something went wrong please try again later!');
      },
    );
  };

  const onReset = () => {
    if (userPosition) {
      mapRef.setCenter(userPosition);
      onResetBtnClick && onResetBtnClick(userPosition);
    }
  };

  const handlePlaceBubbleClick = () => {
    if (type && Array.isArray(type)) {
      if (type.some((val) => seletedAddress.types.indexOf(val) === -1)) {
        setShowModal('กรุณาเลือกที่อยู่ที่ถูกต้อง');
        return;
      }
    } else if (type && typeof type === 'string') {
      if (seletedAddress.types.indexOf(type) === -1) {
        setShowModal('กรุณาเลือกที่อยู่ที่ถูกต้อง');
        return;
      }
    }
    onPlaceSelect(seletedAddress);
  };

  return (
    <div className="placepicker-container">
      <div
        className={`setcenter-button ${showResetBtn && 'show'}`}
        onClick={onReset}
      >
        <img
          src={resetIconSrc ? resetIconSrc : '/images/mapHelper/Position.svg'}
        />
      </div>
      <div className={`modal-container ${showModal && 'show'}`}>
        <div className="card-container">
          <div>{showModal}</div>
          <button className="submit-btn" onClick={() => setShowModal()}>
            ok
          </button>
        </div>
      </div>
      <LoadScript
        googleMapsApiKey={apiKey}
        language={lang ? lang : defaultLang}
        region={region ? region : defaultRegion}
        libraries={libraries}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          options={options()}
          onDragStart={() => setShowAddress(false)}
          onIdle={() => (mapRef ? onChangeCenter() : null)}
          onLoad={(ref) => setMapRef(ref)}
        >
          <StandaloneSearchBox
            onLoad={(ref) => setSearch(ref)}
            onPlacesChanged={onPlacesChanged}
          >
            <div className="input-container">
              <input
                ref={inputRef}
                type="text"
                placeholder="Customized your placeholder"
                className="searchbox-input"
                onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
              />
            </div>
          </StandaloneSearchBox>
          <div
            className={`address-box ${showPlaceBubble && 'show'}`}
            style={{
              display: showAddress ? 'block' : 'none',
            }}
            onClick={() => onPlaceSelect && handlePlaceBubbleClick()}
          >
            <div className="header">share location &gt;</div>
            <div className="addr">
              {seletedAddress && seletedAddress.formatted_address}
            </div>
          </div>
          <img
            className={`custom-marker ${showCenterMarker && 'show'}`}
            src={
              centerMarkerSrc ? centerMarkerSrc : '/images/mapHelper/marker.svg'
            }
          />
          {showUserLocation && (
            <Circle center={userPosition} options={circleOptions()} />
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

PlacePicker.propTypes = {
  onPlaceSelect: PropTypes.func,
  showCenterMarker: PropTypes.bool,
  showPlaceBubble: PropTypes.bool,
  showResetBtn: PropTypes.bool,
  onResetBtnClick: PropTypes.func,
  onPlaceSearch: PropTypes.func,
  showUserLocation: PropTypes.bool,
  useUserLocation: PropTypes.bool,
  mapOptions: PropTypes.object,
  userCircleOptions: PropTypes.object,
  lang: PropTypes.string,
  region: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  resetIconSrc: PropTypes.string,
  centerMarkerSrc: PropTypes.string,
};

export default React.memo(PlacePicker);
