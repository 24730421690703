import React, { Component } from 'react';
import Modal from 'react-modal';

const btnCloseStyle = {
  border: 0,
  float: 'right',
  fontSize: '1.5em',
  backgroundColor: 'white',
};

export class ModalImage extends Component {
  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.props.setOpenModal(false);
  }

  render() {
    return (
      <Modal
        ariaHideApp={false}
        contentLabel="Image Modal"
        isOpen={this.props.modalIsOpen}
      >
        <div>
          <button style={btnCloseStyle} onClick={this.onCloseModal}>
            X
          </button>
          <img
            alt="product image"
            src={this.props.viewImageSrc}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </Modal>
    );
  }
}

export default ModalImage;
