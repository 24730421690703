import React, { useState, useEffect } from 'react';
import './AssessmentHistoryLog.scss';
import { thaiDate } from '../../../../../utill.func';
import LoadingOverlay from 'react-loading-overlay';
import config from '../../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { getHnWAssessmentHistoryLog } from '../../../../../actions';
import { DEFAULT_HISTORY_LOG } from '../../../../../common/commonConstant';

export const AssessmentHistoryLog = () => {
    const dispatch = useDispatch();
    const { assessmentHistoryLog } = useSelector((state) => state.assessment);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const didMount = async () => {
            await fetchAssessmentLogs();
        };
        didMount();
    }, []);


    const fetchAssessmentLogs = async () => {
        try {
            setLoading(true);
            await dispatch(getHnWAssessmentHistoryLog());
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    }
    const historyLogClass = assessmentHistoryLog?.length ? 'history-log' : 'no-history-log'
    return (
        <section>
            <LoadingOverlay
                text="Loading"
                spinner
                active={loading}
                styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
            />
            <div className={`asm-hist ${historyLogClass}`}>
                <div className='asm-hist-log'>
                    <div className='asm-hist-log-hdr'>
                        {DEFAULT_HISTORY_LOG.header}
                    </div>
                    <div className='asm-hist-log-body'>
                        {assessmentHistoryLog?.length ? (<div className='asm-hist-log-body-ctn'>
                            {assessmentHistoryLog?.map(item =>
                                <div className='asm-hist-log-body-ctn-in1' key={`${item._id}`}>
                                    <div className='asm-hist-log-body-ctn-in1-left'>
                                        <span className='left-t1'>{thaiDate(`${item.updated_date}`, 'dd LLL yy')}</span>
                                        <span className='left-t1'> {thaiDate(`${item?.updated_date}`, 'pp')}</span>
                                    </div>
                                    <div className='asm-hist-log-body-ctn-in1-right'>
                                        <span className='right-t1'>{`${item.title}`}</span>
                                        <img
                                            src={`${config.S3_URL}${item.image_url}`}
                                            alt=''
                                        />
                                    </div>
                                </div>
                            )}
                        </div>) : <img className='asm-hist-log-body-img' src='/images/healthAndWellness/assessment/assessment_no_history_log.svg' />}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AssessmentHistoryLog;
