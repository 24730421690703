import React from "react";
import './TeamVdoBadgeCard.scss'
import { TeamRectCard } from "../../../../common";
import { CHALLENGE, minContent, ChallengeTeamdashboardStatus } from "../../../../common/challengeConstants";

const TeamVdoBadgeCard = ({
    totalStepBadges,
    teamDashboardStatus,
    fullTeamRegistered,
    onVdoBtnClick,
    onBadgeBtnClick,
    badgeScreenRedirect
}) => {

    const renderNoDataBody = () => {
        return (
            <div className="no-medal-data-wrapper">
            <div className="challenge-card-body">
                <div className="no-medal-data-card">
                    <div className="walking-steps-heading">{CHALLENGE.teamDashboard.medals}</div>
                    <div className="walking-steps-amt">{minContent}</div>
                </div>
            </div>
            </div>
        )
    }

    return (
        <div className="vdo-badge-card-container">
            {teamDashboardStatus == ChallengeTeamdashboardStatus.ready_to_start || teamDashboardStatus == ChallengeTeamdashboardStatus.not_yet_started ?
                renderNoDataBody()
                :
                <div className="social-media-details-wrapper">
                    <div className="challenge-card-body w-50" onClick={onVdoBtnClick}>
                        <TeamRectCard vdoCard
                            imgIcon={'/images/challenge/icon-challenge-video.svg'}
                            imgArrow={'/images/healthAndWellness/foodLog/icons/arrow-right.svg'}
                            headingText={CHALLENGE.teamDashboard.vdoHeading}
                            subHeadingText={CHALLENGE.teamDashboard.vdoSubHeading}
                            teamDashboardStatus={teamDashboardStatus}
                        />
                    </div>
                    <div className="challenge-card-body w-50" onClick={() => badgeScreenRedirect(true)}>
                        <TeamRectCard vdoCard
                            imgIcon={'/images/challenge/icon-challenge-medal.svg'}
                            imgArrow={'/images/healthAndWellness/foodLog/icons/arrow-right.svg'}
                            headingText={CHALLENGE.teamDashboard.medals}
                            medalsWon={totalStepBadges}
                            subHeadingText={CHALLENGE.teamDashboard.maxBadges}
                            teamDashboardStatus={teamDashboardStatus}
                            medalPoints={true}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default TeamVdoBadgeCard;