import React, { useEffect, useState } from 'react';
import './libraryProfile.scss';
import config from '../../../config';
import AmwayLibrary from './amwayLibrary';
import MyLibrary from './myLibrary';
import { getCustomerByToken } from '../../../services/library/categories/categories.service';
import { Spin } from 'antd';
import { EContentType } from '../common.library';
import { useLocation } from 'react-router-dom';

const LibraryProfile = () => {
  const [currentTab, setCurrentTab] = React.useState(
    EContentType.AMWAY_LIBRARY,
  );
  const [customer, setCustomer] = useState([]);
  const [isLoadProfile, setIsLoadProfile] = useState([]);
  const [isAboAccount, setIsAboAccount] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    checkPermission();
  }, []);

  const checkPermission = async () => {
    setLoading(true);
    const customer = await getCustomerByToken();
    if (customer?.type === 'abo' || customer?.type === 'member') {
      setIsAboAccount(true);
    } else {
      setIsAboAccount(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    const contentTab = new URLSearchParams(location.search).get('currentTab');
    if (contentTab) {
      setCurrentTab(contentTab);
    }

    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoadProfile(true);
    const customerData = await getCustomerByToken();
    setCustomer(customerData);
    setIsLoadProfile(false);
    setLoading(false);
  };
  const { pictureUrl, name, amwayNumber } = customer;
  return (
    <Spin tip="loading..." spinning={loading}>
      <div className="library-profile-container">
        {isAboAccount ? (
          <div className="library-profile-row">
            <Spin tip="Loading profile..." spinning={isLoadProfile}>
              <div className="library-col-profile">
                <img
                  className="library-profile-image"
                  src={pictureUrl || config.AVATAR_DEFAULT}
                  alt=""
                />
                <div className="library-profile-info">
                  <p>สวัสดีคุณ</p>
                  <h4 className="name">{name}</h4>
                  <span className="user-code-tag">
                    {customer.type === 'member'
                      ? 'รหัสสมาชิก'
                      : 'รหัสนักธุรกิจ'}{' '}
                    : {amwayNumber}
                  </span>
                </div>
              </div>
            </Spin>
            <div className="library-col-content">
              <div className="library-tab-wrap">
                <div
                  className={`library-col-content-tab ${
                    currentTab === EContentType.AMWAY_LIBRARY ? 'active' : ''
                  }`}
                  onClick={() => {
                    setCurrentTab(EContentType.AMWAY_LIBRARY);
                  }}
                >
                  <img
                    className="icon"
                    src="../../images/library/icon_amway_library.svg"
                  />
                  Amway Library
                </div>
                <div
                  className={`library-col-content-tab ${
                    currentTab === EContentType.MY_LIBRARY ? 'active' : ''
                  }`}
                  onClick={() => {
                    setCurrentTab(EContentType.MY_LIBRARY);
                  }}
                >
                  <img
                    className="icon"
                    src="../../images/library/icon_mylibrary.svg"
                  />
                  My Library
                </div>
              </div>

              <div className="content">
                {currentTab === EContentType.AMWAY_LIBRARY ? (
                  <AmwayLibrary />
                ) : (
                  <MyLibrary />
                )}
              </div>
            </div>
          </div>
        ) : !loading && !isAboAccount ? (
          <div className="empty-data">
            <img src="../../images/library/icon_not_abo.svg" />
            <p className="desc">
              ข้อมูลเฉพาะ
              <br /> Amway Business Owner (ABO)
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Spin>
  );
};
export default LibraryProfile;
