import React from "react";
import './HealthNutritionProgressBar.scss';
import { round } from "../../services";

const HealthNutritionProgressBar = ({ value, max, label, nutritionUnit = 'กรัม' }) => {

    return (
        <div className="nutrition-bar-single">
            <div className="nutrition-bar-header">
                <div className="nutrition-bar-name">{label}</div>
                <progress value={value} max={max} />   {/* for value and maxvalue */}
            </div>
            <div className="nutrition-bar-value-group">
                <div className="nutrition-bar-value">{value ? round(value,0) : '-'}</div>  {/* for nutritionValue */}
                <div className="nutrition-bar-value-unit">{nutritionUnit}</div>  {/* for unit */}
            </div>
        </div>
    )
}

export default HealthNutritionProgressBar;