import React from 'react';
import Slider from 'react-slick';

const SlickCarousel = React.forwardRef(({ children, slidesToShow = 2, slidesToScroll, ...props }, ref) => {
  const settings = {
    nav: false,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: slidesToScroll ?? (slidesToShow - 1),
    arrows: false,
  };

  return <Slider ref={ref} {...settings} {...props} className="slick-list">
    {children}
  </Slider>;
});

export default SlickCarousel;
