import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';

import { addToBasket, removeFromBasket, customerFetch } from '../../../actions';
import { commonConstant } from '../../../common';
import { InputNumber } from '../../../helpers';
import { sopCartService } from '../../../services';

import './MySOP.scss';

const liff = window.liff;

export class MySOP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirect: false,
      invalidProducts: [],
      selectedSop: [],
    };
  }

  componentDidMount() {
    if (this.props.profile?.userId) {
      this.getSOPCart(this.props.profile.userId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile?.userId !== this.props.profile?.userId) {
      this.getSOPCart(this.props.profile.userId);
    }
  }

  getSOPCart = uid => {
    this.setState({ loading: true });
    sopCartService.getSOPCart(uid)
      .then((res) => {
        const cart = res.data;
        if (this.props.SOPCart.sopList) {
          cart.sopList = this.props.SOPCart.sopList;
        }
        this.setState({ loading: false });
        this.props.addToBasket(cart);
      });
  }

  seeMore = id => {
    const { sopList } = this.props.SOPCart;
    this.props.addToBasket({
      sopList: sopList.map((sop) => {
        if (sop.id === id) {
          sop.seeMore = !sop.seeMore;
        }
        return sop;
      }),
    });
  }

  removeSop = id => {
    const { sopList } = this.props.SOPCart;
    this.props.addToBasket({
      sopList: sopList.map((sop) => {
        if (sop.id === id) {
          sop.isDeleted = true;
        }
        return sop;
      })
    });
  }

  updateDeliveryAddress = () => {
    this.setState({
      redirect: commonConstant.pathChooseAddress,
    });
  }

  updateVariantQty = data => {
    const { sopList } = this.props.SOPCart;
    const { sopId, kitCode, variantCode, qty } = data;
    this.props.addToBasket({
      sopList: sopList.map((sop) => {
        if (sop.id === sopId) {
          sop.kitList = sop.kitList.map((kit) => {
            if (kit.kitCode === kitCode) {
              kit.variants = kit.variants.map((variant) => {
                if (variant.variantCode === variantCode) {
                  variant.qty = qty;
                }
                return variant;
              })
            }
            return kit;
          });
        }
        return sop;
      })
    });
  }

  submitUpdateSOP = (invalidProducts) => {
    const { sopList } = this.props.SOPCart;
    if (!_.isEmpty(invalidProducts)) {
      this.setState({ invalidProducts })
    } else {
      this.setState({
        loading: true,
        invalidProducts: [],
      }, () => {
        sopCartService.updateSOPCart(this.props.profile.userId, sopList)
          .then((res) => {
            this.setState({ loading: false }, () => {
              liff.closeWindow();
            })
          });
      })
    }
  }

  render() {
    const { seeMore, removeSop, submitUpdateSOP, updateVariantQty, updateDeliveryAddress } = this;
    const { redirect } = this.state;
    const { sopList, shippingAddress, code } = this.props.SOPCart;
    if (redirect) {
      return <Redirect to={{
        pathname: redirect,
        state: {
          cartCode: code,
          contactId: shippingAddress.contactId
        }
      }} />;
    }
    const invalidProducts = _.filter(sopList, (p) => {
      const kits = _.filter(p.kitList, (k) => {
        const sumVariants = _.sumBy(k.variants, (o) => o.qty)
        return k.max !== sumVariants
      })
      return !_.isEmpty(kits) && !p.isDeleted
    })
    return (
      <div className="mysop-wrapper">
        <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
        {(sopList && shippingAddress) && (
          <>
            <div className="mysop-container">
              <div className="body">
                <SopCard
                  content={sopList}
                  seeMore={seeMore}
                  removeSop={removeSop}
                  submitUpdateSOP={submitUpdateSOP}
                  updateVariantQty={updateVariantQty}
                  updateDeliveryAddress={updateDeliveryAddress}
                />
              </div>
            </div>
            <div className="footer">
              {!_.isEmpty(this.state.invalidProducts) && (
                <div className="footer-invalid-header">
                  <div className="title">กรุณาเลือกสินค้าให้ครบตามจำนวนที่กำหนด</div>
                </div>
              )}
              <div className="footer-header">
                <div className="title">ที่อยู่สำหรับจัดส่ง</div>
                <div
                  className="edit"
                  onClick={() => updateDeliveryAddress()}
                >แก้ไข</div>
              </div>
              <div className="footer-body">
                {shippingAddress.address}
              </div>
              <div className="footer-footer">
                <div
                  className={!_.isEmpty(invalidProducts) ? "submit-button disabled" : "submit-button"}
                  onClick={() => submitUpdateSOP(invalidProducts)}
                >ยืนยัน</div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ SOPCart, profile }) => {
  return {
    SOPCart,
    profile,
  }
};

const mapDispatchToProps = dispatch => ({
  addToBasket: params => dispatch(addToBasket(params)),
  removeFromBasket: params => dispatch(removeFromBasket(params)),
  getCustomer: () => dispatch(customerFetch())
});

export default connect(mapStateToProps, mapDispatchToProps)(MySOP);

const SopCard = props => {
  const products = _.chain(props.content)
    .filter((obj) => !obj.isDeleted)
    .value()
  if (_.isEmpty(products)) {
    return (
      <div className="no-items">
        คุณไม่มีรายการสั่งซื้อสินค้า SOP ในตะกร้า
      </div>
    )
  } else {
    return _.chain(products)
      .filter((obj) => !obj.isDeleted)
      .map((obj, idx) => {
        const benefits = _.chain(obj.benefits)
          .map((benefit) => {
            const texts = [benefit.title?.trim(), benefit.description?.trim()].filter((t) => !_.isNil(t));
            return !_.isEmpty(texts) ? texts.join(' ') : null;
          })
          .filter((benefit) => !_.isNil(benefit))
          .value();
        return (
          <div key={idx} className="sopcard-container">
            <div className="card-body">
              <div className="body-content">
                <div className="img">
                  <div className="tag-sop">สินค้า SOP</div>
                  <img src={obj.img} />
                </div>
                <div className="content">
                  <div className="title">
                    <div>
                      {obj.title}
                    </div>
                    <img
                      src="/images/Trash.svg"
                      alt=""
                      onClick={() => props.removeSop(obj.id)}
                    />
                  </div>
                  <div className="body">
                    <div className="price-container">
                      <div className="price">
                        {obj.price.formattedValue}
                      </div>
                      {obj.bonus && (
                        <div className="bonus">
                          + {obj.bonus.pv} PV / {obj.bonus.bv} BV
                        </div>
                      )}
                    </div>
                    <div className="qty-container">
                      x{obj.qty}
                    </div>
                  </div>
                  {!_.isEmpty(benefits) && (
                    <div className="benefits">
                      <div className="tag-benefit">สิทธิพิเศษ</div>
                      <div>
                        {benefits.map((benefit) => <div className="name-benefit">{benefit}</div>)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="footer-content">
                {obj.seeMore && !_.isEmpty(obj.kitList) && (
                  <div className="child-products">
                    {!_.isEmpty(obj.kitList) && (obj.kitList.map((k) => {
                      const sum = _.sumBy(k.variants, (o) => o.qty)
                      return (
                        <div>
                          <div className="title-wrapper">
                            <div className="title">{k.name}</div>
                            <div className={sum === k.max ? "qty" : "qty invalid"}>{sum}/{k.max}</div>
                          </div>
                          {!_.isEmpty(k.variants) && (k.variants.map((v) => {
                            return (
                              <div className="variants-wrapper">
                                <div className="variants-title-wrapper">
                                  <img src={v.img} />
                                  <div className="title">{v.name}</div>
                                </div>
                                <div className="qty">
                                  <InputNumber
                                    value={v.qty}
                                    min={0}
                                    max={v.qty + k.max - sum}
                                    onChange={val => props.updateVariantQty({
                                      sopId: obj.id,
                                      kitCode: k.kitCode,
                                      variantCode: v.variantCode,
                                      qty: val,
                                    })}
                                  />
                                </div>
                              </div>
                            )
                          }))}
                        </div>
                      )
                    }))}
                  </div>
                )}
                {!_.isEmpty(obj.kitList) && (
                  <div className="see-more" onClick={() => props.seeMore(obj.id)}>
                    สินค้าในชุดนี้
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })
      .value();
  }
};
