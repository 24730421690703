import React, { useState, useEffect } from 'react';
import './header.scss';
import SquareFloatButton from "../squareFloatButton/SquareFloatButton";
import { HOME_IMG, HOME_PAGE } from '../homePageConstants';
import commonConstant, { routePathKey } from '../commonConstant';
import { createCartPublic, getCart, getCartPublic } from '../../services/promotion/utils.service';
import { getDataFromSession } from '../../utill.func';

const Header = ({ routeTo }) => {
    const [cartCount, setCartCount] = useState(0);
    useEffect(() => {
      const didMount = async() => {
          await getCartData();
      }
      didMount();
    }, []);

    const getCartData = async () => {
      try {
          const customerFOA = await getDataFromSession('customerFOAData');
          const guid = await  getDataFromSession('guid');

          const cart = !customerFOA ? await getCart() : guid
              ? await getCartPublic(guid) : await createCartPublic();
          if (Array.isArray(cart)) {
              const count = cart[0].entries.reduce((total, item) =>
                  total + (item.kitEntryCode || item.isGiveAway ? 0 : item.quantity || 0),
                  0,
              );
              setCartCount(count);
          }
      } catch (error) {
        throw new Error(error);
      }
    }

    const handleCart = () =>{
        window.location.href = commonConstant.pathCartPromotion;
    }

    return (
        <div class="align-items-center">
            <div className="home-header-bottom-wrapper">
                <div className="search-input-container">
                    <img src={HOME_IMG.foodSearchUrl} className="search-img" />
                    <input
                        type="text"
                        onClick={() => routeTo(routePathKey.searchHistory, '?openSearch=true')}
                        className="input-search-shadow"
                        placeholder={HOME_PAGE.FOOD_SEARCH_PLACEHOLDER}
                    />
                </div>
                <div className='header-bottom-right'>
                    <div className="home-calendar-icon" onClick={() => routeTo('pathHnwHomeCalendar')}>
                        <SquareFloatButton className="home-square-float-btn">
                            <img src={HOME_IMG.calendarIconUrl} />
                        </SquareFloatButton>
                    </div>
                    <div className="home-cart-icon" onClick={()=>handleCart()}>
                        <SquareFloatButton className="home-square-float-btn">
                            <img src={HOME_IMG.cartIconUrl} />
                           { cartCount > 0 && (<span className="circle-notification">
                            </span>)}
                        </SquareFloatButton>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Header;
