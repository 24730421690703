import React, { useEffect, useState } from 'react';
import './HnwLogStepper.scss';

export default function HnwLogStepper({ dataArray = ['1'], onClick, quickAccessActionValue = "", valueSuffix = "" , disableOnMount=false }) {
  const [stepperValue, setStepperValue] = useState(dataArray[0]);
  const handleClick = (event) => {
    const { value } = event?.target;
    setStepperValue(value);
    onClick && onClick(value, event);
  };

  useEffect(() => {
    if(!disableOnMount){
      onClick(stepperValue);
    }
  }, []);

  useEffect(() => {
    if(quickAccessActionValue){
      setStepperValue(quickAccessActionValue)
    }
  }, [quickAccessActionValue])
  
  return (
    <div className="hnw-log-stepper">
      {dataArray.map(item => <button
        className={`btn${stepperValue === item ? ' --btn-active' : ''}`}
        key={item}
        onClick={handleClick}
        value={item}>
        {`${item} ${valueSuffix}`}
      </button>)}
    </div>
  );
}
