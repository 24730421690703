import React, { useEffect, useState } from 'react';
import { routesMap } from '../../../../common/commonConstant';
import themeVariable from '../../../../common/commonConstant.scss';
import { bmiClassification } from '../../../../services';
import BmiStatusBar from '../bmiStatusBar/bmiStatusBar';
import './detail.scss';

const Detail = ({ routeTo, profileData }) => {
  const activeDataWrapper = {
    1: { parent: 'blue-card-container', child: themeVariable.profileblue, text: 'ต่ำกว่าเกณฑ์' },
    2: { parent: 'green-card-container', child: themeVariable.homegreen1, text: 'ร่างกายสมส่วน' },
    3: { parent: 'yellow-card-container', child: themeVariable.profileyellow, text: 'อ้วนระดับ 1' },
    4: { parent: 'orange-card-container', child: themeVariable.profileorange, text: 'อ้วนระดับ 2' },
    5: { parent: 'red-card-container', child: themeVariable.profilered, text: 'อ้วนระดับ 3' },
  };

  const [activeData, setActiveData] = useState(null);

  useEffect(() => {
    setDetailClass();
  }, [profileData]);

  const setDetailClass = () => {
    const data = bmiClassification(profileData.bmi, activeDataWrapper);
    setActiveData(data);
  };

  const renderImage = (image, label, parentClass = 'profile-card-top') => (
    <div className={parentClass}>
      <img src={`/images/healthAndWellness/profile/icon/${image}.svg`} />
      <div className="profile-card-name">
        {label}
      </div>
    </div>
  );

  const renderValue = (value, descClass, descValue) => (
    <>
      <div className={'profile-card-middle'}>
        {value ? value : '-'}
      </div>
      {descClass ? <div className={descClass}>
        {descValue}
      </div> : null}
    </>
  );

  const renderCard = (path, rowClass, subClass, image, desc, value, descClass, descValue) => (
    <div onClick={() => routeTo(path)} className={rowClass}>
      <div className={`profile-card text-center ${subClass}`}>
        {renderImage(image, desc)}
        {renderValue(value, descClass, descValue)}
      </div>
    </div>
  );

  return (
    <div className={`container-fluid ${(activeData && activeData.parent) ?? ''}`}>
      <div className="first-row">
        <div onClick={() => routeTo(routesMap['bmi'])} className="first-col">
          <div className="profile-card profile-card-big">
            <div className="profile-card-left">
              {renderImage('bmi', 'BMI', 'profile-card-left-bmi-name')}
              <p className="profile-card-left-bmi-description">{(activeData && activeData.text) ?? 'กรุณาระบุน้ำหนัก'}</p>
            </div>
            <div className="profile-card-right">
              <BmiStatusBar color={(activeData && activeData.child) ?? ''} value={profileData.bmi} />
            </div>
          </div>
        </div>
        {renderCard(routesMap['weight'], 'second-col', 'profile-card-small', 'weight', 'น้ำหนัก', profileData.weight, 'profile-card-footer', 'กิโลกรัม')}
        {renderCard(routesMap['height'], 'third-col', 'profile-card-small', 'height', 'ส่วนสูง', profileData.height, 'profile-card-footer', 'เซนติเมตร')}
      </div>
      <div className="second-row">
        <div onClick={() => routeTo(routesMap['fat'])} className="first-col">
          <div className="profile-card profile-card-small">
            {renderImage('fat', 'ไขมัน')}
            <div className="profile-card-middle-description">
              {renderValue(profileData.fat ? <>{profileData.fat}</> : '', 'profile-card-middle-right', 'กิโลกรัม')}
            </div>
          </div>
        </div>
        <div onClick={() => routeTo(routesMap['muscle'])} className="second-col">
          <div className="profile-card profile-card-medium">
            {renderImage('muscle', 'มวลกล้ามเนื้อ')}
            <div className="profile-card-middle-description">
              {renderValue(profileData.muscle, 'profile-card-middle-right', 'กิโลกรัม')}
            </div>
          </div>
        </div>
        <div onClick={() => routeTo(routesMap['waist'])} className="third-col">
          <div className="profile-card profile-card-medium">
            {renderImage('waist', 'เส้นรอบเอว')}
            <div className="profile-card-middle-description">
              {renderValue(profileData.waist, 'profile-card-middle-right', 'นิ้ว')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
