import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { createPayment } from '../../../actions';
import { Checkbox } from '../../../helpers';
import commonConstant from '../../../common/commonConstant';
import variables from '../../../common/commonConstant.scss';

import './ChoosePayment.scss';

export class ChoosePayment extends Component {
  constructor(props){
    super(props);
    this.state = {
      selected: null,
      redirect: null,
    };
  }

  handleSelect = value => {
    this.setState({
      selected: value,
    });
  }

  handleSubmit = () => {
    console.log('redirecting....');
    const { selected } = this.state;
    if (selected === 'creditcard'){
      this.setState({
        redirect: commonConstant.pathChooseCreditCard,
      });
    } else if (selected === 'banktransfer'){
      this.setState({
        redirect: commonConstant.pathChooseBankTransfer,
      });
    } else {
      this.props.createPayment({ type: selected, data: {} });
      this.setState({
        redirect: commonConstant.pathPaymentConfirm,
      });
    }
  }

  render() {
    const { selected, redirect } = this.state;
    const { handleSelect, handleSubmit } = this;
    const isDisabled = !selected;
    if (redirect){
      return <Redirect to={redirect} />;
    }
    return (
      <div className="choosepayment-container">
        <div className="header">
          <img src="images/payment/Wallet.svg" alt="" />
          <div>เลือกช่องทางชำระเงิน</div>
        </div>
        <div className="body">
          <div className="payment-choice">
            <Checkbox checkmarkColor={variables.success} checked={selected === 'creditcard'} onClick={() => handleSelect('creditcard')}>
              <div className="detail">
                <div className="name">
                บัตรเครดิต/เดบิต
                  <img src="images/payment/visa.svg" alt="" />
                  <img src="images/payment/mastercard.svg" alt="" />
                </div>
                <div className="description">
                กรุณาชำระเงินก่อน 23.30 น.
                </div>
              </div>
            </Checkbox>
          </div>
          <div className="payment-choice">
            <Checkbox checkmarkColor={variables.success} checked={selected === 'amwayshop'} onClick={() => handleSelect('amwayshop')}>
              <div className="detail">
                <div className="name">
              แอมเวย์ ช็อป
                  <img src="images/payment/amway-logo-small.svg" alt="" />
                </div>
                <div className="description">
              กรุณาชำระเงินตามเวลาทำการ
                </div>
              </div>
            </Checkbox>
          </div>
          <div className="payment-choice">
            <Checkbox checkmarkColor={variables.success} checked={selected === 'counterservice'} onClick={() => handleSelect('counterservice')}>
              <div className="detail">
                <div className="name">
              เคาน์เตอร์เซอร์วิส
                  <img src="images/payment/counterservice.svg" alt=""/>
                </div>
                <div className="description">
              กรุณาชำระเงินก่อนเวลา 23.30 น.
                </div>
              </div>
            </Checkbox>
          </div>
          <div className="payment-choice">
            <Checkbox checkmarkColor={variables.success} checked={selected === 'banktransfer'} onClick={() => handleSelect('banktransfer')}>
              <div className="detail">
                <div className="name">
              โอนผ่านบัญชีธนาคาร
                </div>
                <div className="description">
              แอปพลิเคชั่น / ATM / เคาน์เตอร์ หรือเว็ปไซต์ธนาคาร
                </div>
              </div>
            </Checkbox>
          </div>
          <div className="payment-choice">
            <Checkbox checkmarkColor={variables.success} checked={selected === 'amplus'} onClick={() => handleSelect('amplus')}>
              <div className="detail">
                <div className="name">
              คะแนนแอมพลัส
                </div>
                <div className="description">
              ใช้คะแนนแอมพลัส 1,250 คะแนน
                </div>
              </div>
            </Checkbox>
          </div>
        </div>
        <div className="center">
          secured by 
          <img src="images/payment/2c2p.svg" alt="" />
        </div>
        <div className="footer">
          <h4 className="header-text">
            ชำระค่าต่อสถานะภาพนักธุรกิจ
          </h4>
          <div className="content">
            <span className="total-text">
            รวมทั้งสิ้น
            </span>
            <span className="amount-text">
            ฿ 450
            </span>
          </div>
          <button className={`away-button ${isDisabled ? '' : 'active'}`} disabled={isDisabled} onClick={!isDisabled ? handleSubmit : null}>
            ถัดไป
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createPayment: data => dispatch(createPayment(data)),
});

export default connect(null,mapDispatchToProps)(ChoosePayment);
