import React from 'react'
import './HnWFoodLogCard.scss'

const HnWFoodLogCard = ({ imagePath, header, value, label, handleClick, showAddBtn = true, children = null }) => {

    return (
        <div className="food-overview-meal-card">
            <div className="food-overview-meal-card-wrapper">
                <div className="food-overview-meal-card-header-container">
                    <div className="food-overview-meal-card-left">
                        {
                            imagePath && <img src={imagePath} />
                        }
                        <div className="food-overview-meal-card-left-title-container">
                            {header &&
                                <div className="food-overview-meal-card-left-title">
                                    {header}
                                </div>
                            }
                            {value &&
                                <div className="food-overview-meal-card-left-value">
                                    {value && <span>{value}</span>}
                                    {label && <span>{label}</span>}
                                </div>
                            }
                        </div>
                    </div>
                    {
                        showAddBtn &&
                        <div className="food-overview-meal-card-right">
                            <button onClick={handleClick} className="btn btn-icon-round">
                                <img src="/images/healthAndWellness/foodLog/icons/add-green.svg" />
                            </button>
                        </div>
                    }
                </div>
                {children ? children : null}
            </div>
        </div>
    )
}

export default HnWFoodLogCard
