import config from '../config';
import axios from 'axios'

export const productService = {
    get
};

async function get(params) {
    const response = await axios.get(`${config.API_URL}/products`, {
        params: params
    })

    return response.data.data
}