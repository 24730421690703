import React from 'react'
import './assessmentEvaluation.scss'

const AssessmentEvaluation = ({ assessmentId, redirectToAssessment }) => {
    return (
        <>
            <div className="evaluation-results-wrapper">
                <div className="evaluation-results-description">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="evaluation-results-description-title mt-0">ผลลัพท์การประเมิน</h5>
                            <img src="/images/healthAndWellness/profile/evaluation-results.svg" className="w-100" />
                            <button className="w-100 btn-green" onClick={() => redirectToAssessment(assessmentId)}>เริ่มทำแบบประเมินเลย</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssessmentEvaluation