import { COUPON_FETCH } from './types';
import axios from 'axios';

export const couponFetch = (uid) => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/coupons/${uid}`).then(res => {
    dispatch({
      type: COUPON_FETCH,
      payload: res.data,
    });
  });
};
