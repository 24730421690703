import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, format } from 'date-fns';
import { produce } from 'immer';
import BasicTab from '../../../../shared/tab/BasicTab';
import {
  FOOD_BAR_NAME,
  foodAndExerciseSeries,
  HOME_CALENDAR_TABS_MAPPING,
  LABEL_EN_DAY_CALENDAR_TAB,
  LABEL_EN_MONTH_CALENDAR_TAB,
  LABEL_EN_WEEK_CALENDAR_TAB,
  GRAPH_TYPE,
  API_DATE_FORMAT,
  HNW_GOAL_SETTING,
  FOOD_PARAMETERS_MAPPING,
  LABEL_CALORIE,
  LABEL_ML,
  EXERCISE_BAR_NAME,
  CALENDAR_LOG_LIST_CARD_MAPPING,
  TIME_BAR_NAME,
  HOME_CALENDAR_XAXIS_MAPPING,
  DAY_XAXIS_MAPPING_WITH_HOURS,
  WEEK_XAXIS_MAPPING_WITH_INDEX,
  EXERCISE_BURN_NAME,
  HEALTH_LOG_HOME_TITLE_TH,
} from '../../../../../common/commonConstant';

import {
  HealthRecordingCard,
  HealthSummaryInfoCard,
  HealthDateSelector,
  HealthLogFoodCard,
  HealthLogWaterCard,
  HealthLogExcerciseCard,
  CalendarLoader,
  Slideup,
} from '../../../../../common';
// import { foodExerciseRecordingData, foodWaterSummaryData } from '../../../../../common/logListDataDummy/logListData';
import { Dropdown, BarGraphContainer, MonthGraphContainer } from '..';
import { getHnwAllHealhGoalList } from '../../../../../actions/hnwGoalSettingActions';
import variables from '../../../../../common/commonConstant.scss';
import './HnwHomeCalendar.scss';
import {
  extractFoodWaterLogData,
  formattedDropdownValue,
  handleInfinity,
} from '../utility';
import {
  fixDataForMultibar,
  getMonthRange,
  getWeekRange,
} from '../../../../shared/graph/graph-util';
import {
  calculateDifference,
  capitalizeFirstLetter,
  getDataFromSession,
  getDatetimeStamp,
} from '../../../../../utill.func';
import {
  fetchExerciseCalendarData,
  fetchFoodCalendarData,
} from '../../../../../actions';
import { CalenderHeader } from '..';

const commonDatetimeStamp = (startEndDate) => ({
  startDate: getDatetimeStamp(format(startEndDate.startDate, API_DATE_FORMAT)),
  endDate: getDatetimeStamp(format(startEndDate.endDate, API_DATE_FORMAT)),
})

export default function HnwHomeCalendar({ routeTo }) {
  const CURRENT_DATE_START = new Date();
  CURRENT_DATE_START.setHours(0, 0, 0, 0);
  const CURRENT_DATE_END = new Date(CURRENT_DATE_START);
  CURRENT_DATE_END.setHours(23, 59, 59, 999);

  const { foodDark, foodLight, foodGreen, exerciseOrange } = variables;

  const DEFAULT_SELECTED_DATE_ITEM = {
    date: CURRENT_DATE_START,
    exercise: null,
    food: null,
    highlightedLabel: '',
  };

  const DefaultTotalValues = {
    TotalCarbs: 0,
    TotalProtein: 0,
    TotalFat: 0,
    TotalWater: 0,
    TotalFood: 0,
    TotalTime: 0,
    TotalExercise: 0,
  };

  const DefaultGoalValues = {
    date: CURRENT_DATE_START,
    foodGoal: null,
    waterGoal: null,
    exerciseGoal: null,
  };

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [startEndDate, setStartEndDate] = useState({
    startDate: CURRENT_DATE_START,
    endDate: CURRENT_DATE_END,
  });
  const startEndDateRef = useRef(startEndDate);
  const [healthObj, setHealthObj] = useState(DefaultTotalValues);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(
    HOME_CALENDAR_TABS_MAPPING.Day.name,
  );
  const [selectedDateItem, setSelectedDateItem] = useState(
    DEFAULT_SELECTED_DATE_ITEM,
  );

  const profileDetails = useSelector((state) => state.profile);
  const healthGoal =
    useSelector((state) => state.goalSetting.healthGoalList) ??
    DefaultGoalValues;

  const foodWaterData = useSelector((state) => state.hnwCalendar?.foodWater);
  const exerciseData = useSelector((state) => state.hnwCalendar?.exercise);
  const foodData = extractFoodWaterLogData(foodWaterData, FOOD_BAR_NAME);
  const foodExerciseData = [...foodWaterData, ...exerciseData];

  const [daySeries, setDaySeries] = useState([]);
  const [weekSeries, setWeekSeries] = useState([]);
  const [monthSeries, setMonthSeries] = useState([]);
  // const [foodLogCounter, setFoodLogCounter] = useState(0);
  // const [exerciseLogCounter, setExerciseLogCounter] = useState(0);
  const counter = { food: foodData.length, exercise: exerciseData.length };

  const calculateGroupedHourlyData = (acc, obj) => {
    const date = new Date(obj.updatedDate);
    let hour = Object.keys(DAY_XAXIS_MAPPING_WITH_HOURS).find((key) =>
      DAY_XAXIS_MAPPING_WITH_HOURS[key].includes(date.getHours()),
    );
    const { myFoodWeight, weight } = obj;
    const weightMultiplyFactor = handleInfinity(myFoodWeight / weight);
    // Check if there is already an entry for the current day of the month
    if (!acc[hour]) {
      // If not, initialize a new entry with default values
      acc[hour] = {
        x: hour,
        exercise: 0,
        food: 0,
      };
    }
    acc[hour].exercise += obj.caloriesBurn || 0;
    acc[hour].food += obj.caloriesPerDay * weightMultiplyFactor || 0;

    acc.TotalCarbs += obj.carbohydratePerDay * weightMultiplyFactor || 0;
    acc.TotalProtein += obj.proteinPerDay * weightMultiplyFactor || 0;
    acc.TotalFat += obj.fatPerDay * weightMultiplyFactor || 0;
    acc.TotalTime += calculateDifference(obj.startTime, obj.endTime);
    acc.TotalExercise += obj.caloriesBurn || 0;
    acc.TotalWater +=
      (obj.waterIntake || 0) +
      (obj.coffeeIntake || 0) +
      (obj.softDrinkIntake || 0);
    acc.TotalFood += obj.caloriesPerDay * weightMultiplyFactor || 0;
    return acc;
  };

  const calculateGroupedWeeklyData = (acc, obj) => {
    const date = new Date(obj.updatedDate);
    let weekDay = WEEK_XAXIS_MAPPING_WITH_INDEX[date.getDay()];
    const { myFoodWeight, weight } = obj;
    const weightMultiplyFactor = handleInfinity(myFoodWeight / weight);
    // Check if there is already an entry for the current day of the month
    if (!acc[weekDay]) {
      // If not, initialize a new entry with default values
      acc[weekDay] = {
        x: weekDay,
        exercise: 0,
        time: 0,
        food: 0,
        waterInML: 0,
      };
    }
    acc[weekDay].exercise += obj.caloriesBurn || 0;
    acc[weekDay].food += obj.caloriesPerDay * weightMultiplyFactor || 0;

    acc.TotalTime += calculateDifference(obj.startTime, obj.endTime);
    acc.TotalExercise += obj.caloriesBurn || 0;
    acc.TotalFood += obj.caloriesPerDay * weightMultiplyFactor || 0;
    return acc;
  };

  const calculateGroupedMonthData = (acc, obj) => {
    const date = new Date(obj.updatedDate);
    const dayOfMonth = date.getDate();
    date.setHours(0, 0, 0, 0);
    const { myFoodWeight, weight } = obj;
    const weightMultiplyFactor = handleInfinity(myFoodWeight / weight);

    // Check if there is already an entry for the current day of the month
    if (!acc[dayOfMonth]) {
      // If not, initialize a new entry with default values
      acc[dayOfMonth] = {
        date,
        data1: 0,
        data2: 0,
      };
    }
    acc[dayOfMonth].data1 += obj.caloriesPerDay * weightMultiplyFactor || 0;
    acc[dayOfMonth].data2 += obj.caloriesBurn || 0;

    acc.TotalTime += calculateDifference(obj.startTime, obj.endTime);
    acc.TotalExercise += obj.caloriesBurn || 0;
    acc.TotalFood += obj.caloriesPerDay * weightMultiplyFactor || 0;
    return acc;
  };

  function onTabClick(tab) {
    setActiveTab(tab);
  }

  useEffect(() => {
    switch (activeTab) {
      case HOME_CALENDAR_TABS_MAPPING.Day.name:
        setStartEndDate({
          startDate: CURRENT_DATE_START,
          endDate: CURRENT_DATE_END,
        });
        break;

      case HOME_CALENDAR_TABS_MAPPING.Week.name:
        const { startOfWeek, endOfWeek } = getWeekRange(CURRENT_DATE_START);
        setStartEndDate({ startDate: startOfWeek, endDate: endOfWeek });
        break;

      case HOME_CALENDAR_TABS_MAPPING.Month.name:
        const { startOfMonth, endOfMonth } = getMonthRange(CURRENT_DATE_START);
        setStartEndDate({ startDate: startOfMonth, endDate: endOfMonth });
        break;

      default:
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);
    startEndDateRef.current = { ...startEndDate };
    const { _id: customerId } = getDataFromSession(
      HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
    );
    const fetchData = async () => {
      await Promise.all([
        dispatch(getHnwAllHealhGoalList(customerId)),
        dispatch(
          fetchFoodCalendarData(commonDatetimeStamp(startEndDate)),
        ),
        dispatch(
          fetchExerciseCalendarData(commonDatetimeStamp(startEndDate)),
        ),
      ]).finally(() => setLoading(false));
    };
    fetchData();
  }, [startEndDate]);

  useEffect(() => {
    switch (activeTab) {
      case HOME_CALENDAR_TABS_MAPPING.Day.name: {
        const {
          TotalCarbs,
          TotalProtein,
          TotalFat,
          TotalWater,
          TotalFood,
          TotalExercise,
          TotalTime,
          ...dailyData
        } = foodExerciseData.reduce(
          calculateGroupedHourlyData,
          DefaultTotalValues,
        );
        const graphData = Object.values(dailyData);
        const newSeries = foodAndExerciseSeries.map((foodOrExercise) =>
          produce(foodOrExercise, (draft) => {
            draft.data = graphData.map((dayData) => {
              const y =
                foodOrExercise.name === FOOD_BAR_NAME
                  ? dayData.food
                  : dayData.exercise;
              return { x: dayData.x, y };
            });
            fixDataForMultibar(draft.data, HOME_CALENDAR_XAXIS_MAPPING.Day);
          }),
        );
        setDaySeries(newSeries);
        setHealthObj({
          TotalCarbs,
          TotalProtein,
          TotalFat,
          TotalWater,
          TotalExercise,
          TotalFood,
          TotalTime,
        });
        const currentInstance = new Date();
        const currentHour = currentInstance.getHours();
        const index = Object.values(DAY_XAXIS_MAPPING_WITH_HOURS).findIndex(
          (item) => item.includes(currentHour),
        );
        const food = newSeries.find((series) => series.name === FOOD_BAR_NAME)
          .data[index].y;
        const exercise = newSeries.find(
          (series) => series.name === EXERCISE_BAR_NAME,
        ).data[index].y;
        setSelectedDateItem({
          date: currentInstance,
          food,
          exercise,
          highlightedLabel: HOME_CALENDAR_XAXIS_MAPPING.Day[index],
        });
        break;
      }
      case HOME_CALENDAR_TABS_MAPPING.Week.name: {
        const {
          TotalCarbs,
          TotalProtein,
          TotalFat,
          TotalWater,
          TotalFood,
          TotalExercise,
          TotalTime,
          ...weeklyData
        } = foodExerciseData.reduce(
          calculateGroupedWeeklyData,
          DefaultTotalValues,
        );
        const graphData = Object.values(weeklyData);
        const newSeries = foodAndExerciseSeries.map((foodOrExercise) =>
          produce(foodOrExercise, (draft) => {
            draft.data = graphData.map((dayData) => {
              const y =
                foodOrExercise.name === FOOD_BAR_NAME
                  ? dayData.food
                  : dayData.exercise;
              return { x: dayData.x, y };
            });
            fixDataForMultibar(draft.data, HOME_CALENDAR_XAXIS_MAPPING.Week);
          }),
        );
        setWeekSeries(newSeries);
        setHealthObj({ TotalExercise, TotalFood, TotalTime });
        const currentInstance = new Date();
        const index = currentInstance.getDay();
        const food = newSeries.find((series) => series.name === FOOD_BAR_NAME)
          .data[index].y;
        const exercise = newSeries.find(
          (series) => series.name === EXERCISE_BAR_NAME,
        ).data[index].y;
        setSelectedDateItem({
          date: addDays(startEndDate.startDate, index),
          food,
          exercise,
          highlightedLabel: HOME_CALENDAR_XAXIS_MAPPING.Week[index],
        });
        break;
      }
      case HOME_CALENDAR_TABS_MAPPING.Month.name: {
        const { TotalExercise, TotalFood, TotalTime, ...monthlyData } =
          foodExerciseData.reduce(
            calculateGroupedMonthData,
            DefaultTotalValues,
          );
        const CalendarData = Object.values(monthlyData);
        setMonthSeries(CalendarData);
        setHealthObj({ TotalExercise, TotalFood, TotalTime });
        break;
      }
      default:
        break;
    }
  }, [foodWaterData, exerciseData]);

  const handleDateSelect = (item) => {
    setStartEndDate({ startDate: item.startDate, endDate: item.endDate });
    setDropdownOpen(false);
  };

  const onMouseMove = (data, index) => {
    const date = addDays(startEndDateRef.current.startDate, index);
    setSelectedDateItem({
      date,
      food: data?.food[index]?.y,
      exercise: data?.exercise[index]?.y,
      highlightedLabel:
        activeTab === HOME_CALENDAR_TABS_MAPPING.Day.name
          ? HOME_CALENDAR_XAXIS_MAPPING.Day[index]
          : HOME_CALENDAR_XAXIS_MAPPING.Week[index],
    });
  };

  const renderDailyGoalChart = () => {
    const healthParamsValues = FOOD_PARAMETERS_MAPPING.reduce(
      (acc, item) => ({
        ...acc,
        [item.name]: {
          ...item,
          consumed: healthObj[`Total${capitalizeFirstLetter(item.name)}`],
          total:
            healthGoal.foodGoal &&
            healthGoal.foodGoal[`target${capitalizeFirstLetter(item.name)}`],
        },
      }),
      {},
    );

    return (
      <>
        <div className="home-calendar-container">
          <HealthLogFoodCard
            isEmpty={!healthGoal.foodGoal}
            onClick={() =>
              routeTo(
                healthGoal.foodGoal ? 'pathFoodLogHome' : 'pathHnwGoalsHome',
              )
            }
            healthParamsValues={healthParamsValues}
            calorie={{
              total: healthGoal?.foodGoal?.targetCalories,
              consumed: healthObj?.TotalFood,
              unit: LABEL_CALORIE,
            }}
          />
        </div>
        <div className="home-calendar-container two-cards">
          <HealthLogWaterCard
            isEmpty={!healthGoal.waterGoal}
            onClick={() =>
              routeTo(
                healthGoal.waterGoal ? 'pathFoodLogHome' : 'pathHnwGoalsHome',
                healthGoal.waterGoal ? '?scrollTo=waterLog' : '',
              )
            }
            waterLog={{
              total: healthGoal?.waterGoal?.targetWater,
              consumed: healthObj?.TotalWater,
              unit: LABEL_ML,
            }}
          />
          <HealthLogExcerciseCard
            isEmpty={!healthGoal.activityGoal}
            onClick={() =>
              routeTo(
                healthGoal.activityGoal
                  ? 'pathHnwExerciseLogHome'
                  : 'pathHnwGoalsHome',
              )
            }
            exerciseLog={{
              total: healthGoal?.activityGoal?.targetCalories,
              consumed: healthObj?.TotalExercise,
              unit: LABEL_CALORIE,
            }}
          />
        </div>
      </>
    );
  };

  const routeToLogList = (type) => {
    routeTo(
      CALENDAR_LOG_LIST_CARD_MAPPING[type],
      `?type=${type}&startDate=${format(
        startEndDate.startDate,
        API_DATE_FORMAT,
      )}&endDate=${format(startEndDate.endDate, API_DATE_FORMAT)}`,
    );
  };

  return (
    <div className="home-calendar-home-container">
      <CalendarLoader showLoading={isLoading} />
      <CalenderHeader routeTo={routeTo} />
      <div className="profile-banner-container">
        <div className="profile-img">
          <img
            src={
              profileDetails?.pictureUrl ??
              '/images/healthAndWellness/profile/profile-img.svg'
            }
            className="profile-img-dimensions"
          />
        </div>
      </div>
      <div className="home-calendar-home-container-card">
        <div className="row align-items-center">
          <h2 className="header-typography">{HEALTH_LOG_HOME_TITLE_TH}</h2>
        </div>

        <BasicTab
          tabs={HOME_CALENDAR_TABS_MAPPING}
          activeTab={activeTab}
          onTabClick={onTabClick}
        />
        <div className="graph-header">
          <h2 className="header-typography">
            {HOME_CALENDAR_TABS_MAPPING[activeTab].heading}
          </h2>
          <Dropdown
            name="date"
            displayText={formattedDropdownValue(
              activeTab,
              startEndDate.startDate,
              startEndDate.endDate,
            )}
            onClick={() => {
              setDropdownOpen(true);
            }}
          />
        </div>
        <HealthDateSelector
          view={activeTab}
          isOpen={isDropdownOpen}
          onClickOK={handleDateSelect}
          startDate={startEndDate.startDate}
          endDate={startEndDate.endDate}
        />
        {
          {
            [LABEL_EN_DAY_CALENDAR_TAB]: (
              <BarGraphContainer
                graphType={GRAPH_TYPE.BAR}
                type={LABEL_EN_DAY_CALENDAR_TAB}
                xAxisCategories={HOME_CALENDAR_XAXIS_MAPPING.Day}
                yAxisCategories={daySeries}
                min={startEndDate.startDate}
                max={startEndDate.endDate}
                onMouseMove={onMouseMove}
                highlightedLabel={selectedDateItem.highlightedLabel}
                itemTypeList={[
                  { name: FOOD_BAR_NAME, value: selectedDateItem.food },
                  {
                    name: EXERCISE_BURN_NAME,
                    value: selectedDateItem.exercise,
                  },
                ]}
              />
            ),
            [LABEL_EN_WEEK_CALENDAR_TAB]: (
              <BarGraphContainer
                graphType={GRAPH_TYPE.BAR}
                date={selectedDateItem.date}
                type={LABEL_EN_WEEK_CALENDAR_TAB}
                xAxisCategories={HOME_CALENDAR_XAXIS_MAPPING.Week}
                yAxisCategories={weekSeries}
                min={startEndDate.startDate}
                max={startEndDate.endDate}
                onMouseMove={onMouseMove}
                highlightedLabel={selectedDateItem.highlightedLabel}
                itemTypeList={[
                  { name: FOOD_BAR_NAME, value: selectedDateItem.food },
                  {
                    name: EXERCISE_BURN_NAME,
                    value: selectedDateItem.exercise,
                  },
                ]}
              />
            ),
            [LABEL_EN_MONTH_CALENDAR_TAB]: (
              <MonthGraphContainer
                typeList={[FOOD_BAR_NAME, EXERCISE_BURN_NAME]}
                data={monthSeries}
                min={startEndDate.startDate}
                max={startEndDate.endDate}
                colorCircledark={foodDark}
                colorCirclelight={foodLight}
                showCircleWithoutDataStrength
                showDataDots
                color1Dot={foodGreen}
                color2Dot={exerciseOrange}
                showTabs={false}
              />
            ),
          }[activeTab]
        }

        <div className="health-summary-info-card-header">
          {HOME_CALENDAR_TABS_MAPPING[activeTab].summary_info_heading}
        </div>
        <div className="health-summary-info-card">
          <HealthSummaryInfoCard
            dataItems={[
              { componentName: FOOD_BAR_NAME, value: healthObj.TotalFood },
              {
                componentName: EXERCISE_BAR_NAME,
                value: healthObj.TotalExercise,
              },
              { componentName: TIME_BAR_NAME, value: healthObj.TotalTime },
            ]}
          />
        </div>
        <div className="health-record-card-header">
          {HOME_CALENDAR_TABS_MAPPING[activeTab].recording_heading}
        </div>
        {activeTab === HOME_CALENDAR_TABS_MAPPING.Day.name ? (
          renderDailyGoalChart()
        ) : (
          <div className="health-recording-card">
            {[FOOD_BAR_NAME, EXERCISE_BAR_NAME]?.map((itemName) => (
              <React.Fragment key={itemName}>
                <HealthRecordingCard
                  type={itemName}
                  key={itemName}
                  quantity={counter[itemName]}
                  navigateTo={routeToLogList}
                />
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <Slideup />
    </div>
  );
}
