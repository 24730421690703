import React from 'react';
import './DashboardHeader.scss'
import { ChallengeHeader } from '../../../../common';

const DashboardHeader = (props) => {
    const {challengeDate, badgeClassname, isHeaderSticky} = props;

    return (
        <div className='team-dashboard-header-container'>
           <ChallengeHeader challengeDate={challengeDate} badgeClassname={badgeClassname} isHeaderSticky={isHeaderSticky}/>
        </div>
    )
}

export default DashboardHeader