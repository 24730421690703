import React, { useEffect, useState } from 'react';
import { getHnWAssessmentDetail, getHnWAssessmentList, getHnWAssessmentHistoryLog, fetchBmiData } from '../../../actions';
import TermsAndPolicy from './termsAndPolicy/termsAndPolicy'
import AssessmentEvaluation from './assessmentEvaluation/assessmentEvaluation'
import UserInfo from './userInfo/userInfo'
import Detail from './detail/detail'
import './hnwProfile.scss'
import { CalendarLoader, HnwFooter, commonConstant } from '../../../common'
import { Customer } from '../../../model/Customer.model';
import { deleteHWProfileData } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import AssessmentProgressScreen from './assessmentProgressScreen/AssessmentProgressScreen'
import AssessmentNotCompleted from './assessmentNotCompleted/AssessmentNotCompleted';
import { ASSESSMENT_STATUS } from '../../../common/commonConstant';
import { updatePolicyStatus } from '../../../thunk/hnwHome.thunk';
import { SET_FOOTER_STATE_PROFILE } from '../../../actions/types';
import { setChallengeType } from '../../../actions/hnwFooterAction';

const Profile = ({ routeTo }) => {
    const { assessmentDetail, assessmentList } = useSelector((state) => state.assessment);
    const profileData = useSelector((state) => state.bmi);
    const dispatch = useDispatch();
    const [retryPopup, setRetryPopup] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [assessmentId, setAssessmentId] = useState(false);

    const customer = useSelector((state) => new Customer(state.customer));
    const profileDetails = useSelector((state) => state.profile);

    const liff = window.liff;

    useEffect(() => {
        const didMount = async () => {
            if (!profileData?.bmi) {
                dispatch(fetchBmiData());
            }
            fetchAssessmentDetail();
        }
        dispatch(setChallengeType(SET_FOOTER_STATE_PROFILE));
        didMount();
    }, [])

    useEffect(() => {
        if (isDeleted) {
            dispatch(fetchBmiData());
        }
    }, [isDeleted])

    const deleteAssessmentDetail = async () => {
        try {
            setLoading(true);
            dispatch(deleteHWProfileData()).then(res => {
                dispatch(updatePolicyStatus())
                setIsDeleted(true);
                setRetryPopup(false);
                setLoading(false);
                liff.closeWindow();
            }).catch(function (error) {
                setLoading(false);
                console.error(error);
            });
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    const fetchAssessmentDetail = async () => {
        try {
            setLoading(true);
            dispatch(getHnWAssessmentList()).then(res => {
                const assessment_id = res?.assessment_id;
                setAssessmentId(assessment_id);
                dispatch(getHnWAssessmentHistoryLog());
                dispatch(getHnWAssessmentDetail(assessment_id)).finally(() => setLoading(false))
                    .catch(err => {
                        console.error(err)
                    });
            })
        } catch (error) {
            console.error(error);
        }
    }

    const redirectToAssessment = (data) => {
        const assessment_id = data ? data : assessmentId;
        routeTo('pathHnWAssessment', `?assessment_id=${assessment_id}`);
    };

    const redirectToAssessmentHome = () => {
        let params = `?assessment_id=${assessmentId}`;
        if (assessmentDetail?.assessments_status === ASSESSMENT_STATUS.pending) {
            params = `?assessment_id=${assessmentId}&from=hnwprofile`;
        }
        routeTo('pathHnWAssessmentHome', params);
    };

    return (
        <div className="container-fluid p-0 profile-banner-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center header-bottom-section">
                    <div className="col-3">
                        <a className="mr-10" onClick={() => routeTo('pathHealthnWellness')}>
                            <img
                                src="/images/healthAndWellness/goal-setting/icons/home.svg"
                                alt="home.svg"
                            />
                        </a>
                    </div>
                    <div className="col-9 text-right header-bottom-icons">
                        <a onClick={() => routeTo('pathHnwGoalsHome')}>
                            <img
                                src="/images/healthAndWellness/goal-setting/icons/goal-green.svg"
                                alt="goal-green.svg"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="profile-banner-container">
                <img src="/images/healthAndWellness/profile/banner-bg.svg" className="w-100" />
                <div className="profile-img">
                    <img src={profileDetails?.pictureUrl ?? "/images/healthAndWellness/profile/profile-img.svg"} className="profile-img-dimensions" />
                </div>
            </div>
            <UserInfo customer={customer} />
            <div className="bg-profile-wrapper">
                <Detail routeTo={routeTo} profileData={profileData} />
                {(assessmentDetail?.assessments_status === ASSESSMENT_STATUS.done) ?
                    <AssessmentProgressScreen assessmentDetail={assessmentDetail} assessmentId={assessmentId} redirectToAssessment={redirectToAssessment} redirectToAssessmentHome={redirectToAssessmentHome} /> :
                    (assessmentDetail?.assessments_status === ASSESSMENT_STATUS.pending) ?
                        <AssessmentNotCompleted assessmentDetail={assessmentDetail} assessmentList={assessmentList} assessmentId={assessmentId} redirectToAssessment={redirectToAssessmentHome} /> :
                        <AssessmentEvaluation assessmentId={assessmentId} redirectToAssessment={redirectToAssessmentHome} />}
                <TermsAndPolicy routeTo={routeTo} deleteAssessmentDetail={deleteAssessmentDetail} setRetryPopup={setRetryPopup} retryPopup={retryPopup} />
            </div>
            <CalendarLoader showLoading={loading} />
            <HnwFooter/>
        </div>
    )
}

export default Profile