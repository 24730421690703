import config from '../config';
import axios from 'axios'

export const amwayService = {
    aboVerify,
    saveLineMID,
    memberVerify,
    saveCampaign,
    forgot
};

async function aboVerify(searchKey) {
    const response = await axios.post(`${config.API_URL}/amway/abo-verify`, {
        searchKey: searchKey
    }).catch(err => {

        if (err.response) {
           
            return err.response.data
        } else {
            return err.message
        }
    })

    return response
}

async function memberVerify(searchKey) {
    const response = await axios.post(`${config.API_URL}/amway/member-verify`, {
        
        searchKey: searchKey
    })

    return response
}

async function forgot(forgetKey) {
    const response = await axios.post(`${config.API_URL}/amway/forgot`, {
        ForgetKey: forgetKey
    })

    return response
}

async function saveLineMID(params) {
    const saveLineMID = await axios.post(`${config.API_URL}/amway/lineMID`, params)
    console.log("saveLineMID functions", saveLineMID);

    return saveLineMID
}

async function saveCampaign(params) {
    const saveCampaign = await axios.post(`${config.API_URL}/amway/campaign`, params)

    return saveCampaign
}