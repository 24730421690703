import React, {useEffect, useState} from 'react';
import { commonConstant } from '../../common';
import './CartPromotion.scss';
import { createCartPublic, getCart, getCartPublic } from '../../services/promotion/utils.service';

const CartIcon = (props) => {
  const [cartCount, setCartCount] = useState(0);

  useEffect(async() => {
    const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
    const guid = sessionStorage.getItem('guid');

    const cart = !customerFOA ? await getCart() : guid
        ? await getCartPublic(guid) : await createCartPublic();
    if (Array.isArray(cart)) {
      const count = cart[0].entries.reduce((total, item) =>
          total + (item.kitEntryCode || item.isGiveAway ? 0 : item.quantity || 0),
        0,
      );
      setCartCount(count);
    }
        
  }, []);

  const goToCart = () => {
    return (window.location.href = commonConstant.pathCartPromotion);
  }

  return (
    <div className="icon-button" onClick={goToCart}>
      <img src="/images/promotion/icon_button.png" />
      <span className="circle-notification">
        <span className="notification-text">{cartCount > 99 ? `+99` : cartCount}</span>
      </span>
    </div>
  )

};

export default CartIcon;