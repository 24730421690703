import React, { forwardRef } from 'react'
import './searchBar.scss'
import { SEARCH_BAR_PLACEHOLDER } from '../commonConstant'
import { SEARCH_REGEX } from '../../constants'

const SearchBar = forwardRef(({ searchInput, setSearchInput, placeholder = SEARCH_BAR_PLACEHOLDER, handleSearchChange, handleSearchClick, regex = SEARCH_REGEX, minLength, maxLength }, ref) => {

  const handleChange = e => {
    if (regex) {
      if (regex.test(e.target.value)) {
        setSearchInput(e.target.value);
      } else {
        e.preventDefault();
      }
      handleSearchChange(e);
    } else {
      handleSearchChange(e);
    }
  }

  const clearSearchBar = () => {
    setSearchInput('');
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="search-input-container">
          <img src="/images/icon-search.svg" className="search-img" />
          <input type="text" value={searchInput} ref={ref} onClick={handleSearchClick} minLength={minLength} maxLength={maxLength} onChange={e => handleChange(e)} className="form-control search-input"
            placeholder={placeholder} />
          {
            searchInput &&
            <img src="/images/healthAndWellness/icon/close.svg" onClick={clearSearchBar} className="close-icon" />
          }
        </div>
      </div>
    </div>
  )
})

export default SearchBar