import React, { useState } from 'react';
import { MobilePicker } from '../../../../../common';
import './Dropdown.scss';

export default function Dropdown({ disabled, displayText, onClick, styleClass = 'dropdown', dropDownID = 'dropdown-select', isTransparent=true }) {
  
  return (
    <div className={`${styleClass}`} id={dropDownID}>
      <div onClick={onClick} className={`select-styled${disabled ? ' --centered' : ''} ${!isTransparent ? 'select-styled-white' : ''}`}>
        <div className="select-styled-text">{displayText}</div>
        {!disabled && <div className="select-green-arrow">
          <img src="/images/healthAndWellness/bmi/icons/arrow-down-green.svg" />
        </div>
        }
      </div>
    </div>
  )
}
