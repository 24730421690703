import React from "react";

const ProfileImageSvg = ({isLeader}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 72 72" fill="none">
            <ellipse cx="31" cy="29.8605" rx="27.5" ry="26.8605" stroke={isLeader ? `#DBB67C` : `#FFFFFF`} strokeWidth="2" strokeLinecap="round" />
            <path d="M31 3C36.6949 3 42.2493 4.7269 46.8963 7.94227C51.5434 11.1576 55.0538 15.7029 56.9429 20.9503C58.832 26.1978 59.0066 31.8887 57.4426 37.2373C55.8786 42.5859 52.6531 47.3282 48.2115 50.8096C43.7699 54.291 38.3313 56.3397 32.6467 56.6727C26.962 57.0058 21.3117 55.6069 16.4759 52.6691C11.64 49.7312 7.85728 45.3995 5.64988 40.2719C3.44247 35.1443 2.91931 29.4738 4.15263 24.0434"
                stroke={isLeader ? `#DBB67C` : `#FFFFFF`} strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}

export default ProfileImageSvg;