import React from "react";
import ProfileImageSvg from "../../ProfileImageSvg/ProfileImageSvg";
import './ChallengeProfileImage.scss';

const ChallengeProfileImage = ({ pictureUrl, isLeader }) => {
    return (
        <div className="profile-image-frame">
            <ProfileImageSvg isLeader={isLeader} />
            <img className="user-image" src={pictureUrl} />
            {isLeader ? <div className="btn-icon-crown">
                <img src="/images/challenge/icons/icon-challenge-crown.svg" className="crown-img" alt="" />
            </div>
                : <></>}
        </div>
    )
}

export default ChallengeProfileImage;