import React from 'react';
import { HnWDeleteModal } from '../../../../common';
import './termsAndPolicy.scss';
import UserDataDeleteInfo from './UserDataDeleteInfo';
import { TERMS_AND_POLICY_TEXT } from '../../../../common/commonConstant';

const TermsAndPolicy = ({
  routeTo,
  deleteAssessmentDetail,
  setRetryPopup,
  retryPopup,
}) => {
  const renderDeleteDataPopup = () => {
    return (
      <UserDataDeleteInfo
        retryPopup={retryPopup}
        setRetryPopup={setRetryPopup}
        deleteAssessmentDetail={deleteAssessmentDetail}
      />
    );
  };
  const accordianArr = [
    {
      text: 'ข้อตกลงและเงื่อนไข',
      path: 'pathTermsConditions',
      params: '?readOnly=true',
    },
    {
      text: 'นโยบายความเป็นส่วนตัว',
      path: 'pathPolicy',
    },
  ];

  const renderAccordian = (value, path, params, index) => {
    return (
      <div
        onClick={() => routeTo(path, params)}
        key={`tnc${index}`}
        className="accordian-single"
      >
        <div className="accordian-title">{value}</div>
        <a className="accordian-right-btn">
          <img src="/images/healthAndWellness/icon/arrow-right.svg" />
        </a>
      </div>
    );
  };
  return (
    <div className="accordian-container">
      {accordianArr.map((el, index) =>
        renderAccordian(el.text, el.path, el.params, index),
      )}
      <div onClick={() => setRetryPopup(true)} className="accordian-single">
        <div className="accordian-title">{`ลบข้อมูลสุขภาพของฉัน`}</div>
        <a className="accordian-right-btn">
          <img src="/images/healthAndWellness/icon/arrow-right.svg" />
        </a>
      </div>
      {retryPopup && (
        <HnWDeleteModal
          onClose={() => setRetryPopup(false)}
          isOpen={retryPopup}
          title={TERMS_AND_POLICY_TEXT.title}
          subtitle={TERMS_AND_POLICY_TEXT.subtitle}
        >
          <div className="scroll">{renderDeleteDataPopup()}</div>
        </HnWDeleteModal>
      )}
    </div>
  );
};

export default TermsAndPolicy;
