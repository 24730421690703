import React, { useEffect, useState } from 'react';
import { LABEL_WEIGHT, LABEL_CALORIE, LABEL_UNIT_GRAM, FOOD_PARAMETERS_MAPPING, FOOD_INFO_MAPPING, CALORIE } from '../commonConstant';
import './FoodListingItem.scss';

export default function FoodListingItem({ item, handleClick }) {
  const [imgClass, setImgClass] = useState('--no_image');
  
  const handleImageLoad = () => setImgClass('');
  const handleImageError = () => setImgClass('--no_image');
  const handleRecommendedItem = () => item.isRecommended === true ? <></> : <div className="vertical-line"/>

  useEffect(() => {
    !item.image && setImgClass('--no_image');
  }, [item.image]);
  
  return (
    <div onClick={() => handleClick(item)} key={item.id} className="dietary-supplement-section">
      <div className="dietary-supplement-left-section">
        <div className={`dietary-supplement-left-img --${item.type?.toLowerCase()}`}>
          <img onLoad={handleImageLoad} onError={handleImageError} src={item.imageUrl} alt={item.name} className={imgClass} />
        </div>
        <div className="dietary-supplement-left-title text-center">
          {`${item.quantity} ${item.unit}`}
        </div>
      </div>
      <div className="dietary-supplement-right-section">
        <div className="dietary-supplement-right-title">
          <span>{item.name}</span>
        </div>
        {FOOD_INFO_MAPPING.map((paramObj) => (
          <div key={paramObj.name} className="dietary-supplement-right-description-sm">
            <div className="dietary-supplement-right-description-title">
              <img src={paramObj.iconPath} />
              <span>{paramObj.label}</span>
            </div>
            <div className={`dietary-supplement-right-description-value${paramObj.name === CALORIE ? ' green' : ''}`}>
              {item[paramObj.name]}
            </div>
          </div>
        ))}
        <div className="dietary-supplement-right-details">
          <div className="dietary-supplement-right-weight-section">
            <div className="dietary-supplement-right-weight-title">
              {LABEL_WEIGHT}
            </div>
            <div className="dietary-supplement-right-weight-value">
              {item.weight}
            </div>
            <div className="dietary-supplement-right-weight-unit">
              {LABEL_UNIT_GRAM}
            </div>
          </div>
          {handleRecommendedItem()}
          <div className="dietary-supplement-right-calories-section">
            <div className="dietary-supplement-right-calories-title">
              {LABEL_CALORIE}
            </div>
            <div className="dietary-supplement-right-calories-value green">
              {item.calorie}
            </div>
          </div>
        </div>
        <div className="line" />
        <div className="dietary-supplement-right-nutrition-slots">
          {FOOD_PARAMETERS_MAPPING.map((paramObj) => (
            <div key={paramObj.name} className="dietary-supplement-right-nutrition-slot">
              <div className="dietary-supplement-right-nutrition-title-wrapper">
                <img src={paramObj.iconPath} />
                <div className="dietary-supplement-right-nutrition-title">
                  {paramObj.label}
                </div>
              </div>
              <div className="dietary-supplement-right-nutrition-value">
                {item[paramObj.name]}
              </div>
              <div className="dietary-supplement-right-nutrition-unit">
                {LABEL_UNIT_GRAM}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
