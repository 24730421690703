import { deleteGoal, fetchAllHealthGoalList } from '../services/healthWellness/hnwGoalSetting.service';
import { FETCH_HNW_HEALTH_GOAL_LIST, DELETE_GOAL, UPDATE_HEALTH_LIST } from './types';

export const getHnwAllHealhGoalList = (customerId) => (dispatch) =>
  fetchAllHealthGoalList(customerId)
    .then((result) => {
      dispatch({
        type: FETCH_HNW_HEALTH_GOAL_LIST,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const deleteHealthGoal = (goalType) => (dispatch) =>
  deleteGoal(goalType)
    .then((result) => {
      dispatch({
        type: DELETE_GOAL,
        payload: { result, goalType },
      });
      return result;
    })
    .catch(function (error) {
      alert(error.message);
    });

export const updateWaterGoalInHealthList = payload => dispatch => dispatch({ type: UPDATE_HEALTH_LIST, payload });