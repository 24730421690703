import { addDays, startOfDay, getDaysInMonth, startOfWeek } from "date-fns";
import { HOME_GRAPH_XAXIS_MAPPING, WEIGHT_GRAPH_MIN_VALUE_OFFSET, WEIGHT_GRAPH_TYPE } from "../../../common/commonConstant";

export const getWeightGraphValues = (graphType, bmiRecords) => {
  const CURRENT_DATE = new Date();
  const CURRENT_MONTH = CURRENT_DATE.getMonth();
  const CURRENT_YEAR = CURRENT_DATE.getFullYear();

  switch (graphType) {
    case WEIGHT_GRAPH_TYPE.ANNUALLY: {
      const firstNonZeroMonth = bmiRecords?.find(item => item?.length) || [];
      let firstNonZeroValue = firstNonZeroMonth.length && firstNonZeroMonth[firstNonZeroMonth.length - 1].weight;
      let minValue = firstNonZeroValue;

      const graphData = bmiRecords?.map((monthlyData, index) => {
        const record = monthlyData.length && monthlyData[monthlyData.length - 1];
        const updatedDate = new Date(record?.updatedDate);
        const month = (updatedDate.getTime() ? updatedDate.getMonth() : (CURRENT_MONTH + 6 - index) % 12);
        const graphCoord = {
          x: HOME_GRAPH_XAXIS_MAPPING.MONTHLY[month],
          y: record?.weight ?? firstNonZeroValue
        }
        firstNonZeroValue = record?.weight ?? firstNonZeroValue;
        minValue = Math.max(0, Math.min(minValue, firstNonZeroValue * WEIGHT_GRAPH_MIN_VALUE_OFFSET));
        return graphCoord;
      });

      return { graphData, minValue };
    };

    case WEIGHT_GRAPH_TYPE.MONTHLY: {
      const lastMonthsData = bmiRecords[5];
      let lastRecordedData = lastMonthsData[lastMonthsData.length - 1]?.weight;
      const currentMonthsData = bmiRecords[6] || [];
      let minValue = lastRecordedData;

      const graphData = [...Array.from({ length: getDaysInMonth(CURRENT_MONTH) },
        (_, index) => {
          const value = currentMonthsData.find(record =>
            new Date(record?.updatedDate).getDate() === (index + 1)) || { updatedDate: new Date(CURRENT_YEAR, CURRENT_MONTH, index + 1) };
          lastRecordedData = value?.weight ?? lastRecordedData;
          minValue = Math.max(0, Math.min(minValue, lastRecordedData * WEIGHT_GRAPH_MIN_VALUE_OFFSET));
          return { x: new Date(value?.updatedDate), y: lastRecordedData };
        })];
      return { graphData, minValue };
    }

    default: {
      const FIRST_DAY_OF_WEEK = startOfWeek(CURRENT_DATE);

      const monthlyRecord = bmiRecords?.flat() || [];
      const weeksFirstRecordIndex = monthlyRecord.findIndex((item) => new Date(item?.updatedDate) >= FIRST_DAY_OF_WEEK);
      const lastRecordBeforeCurrentWeek = monthlyRecord.findLastIndex((item) => new Date(item?.updatedDate) < FIRST_DAY_OF_WEEK)

      let firstNonZeroRecordWeight = weeksFirstRecordIndex > -1 ? monthlyRecord[weeksFirstRecordIndex - (weeksFirstRecordIndex > 0)]?.weight : (monthlyRecord[lastRecordBeforeCurrentWeek]?.weight ?? 0);
      let minValue = firstNonZeroRecordWeight;
      const nonZeroGraphData = monthlyRecord.filter(({ updatedDate }) => new Date(updatedDate) >= FIRST_DAY_OF_WEEK);

      const graphData = HOME_GRAPH_XAXIS_MAPPING.DAILY.map((day, index) => {
        const record = nonZeroGraphData?.find(({ updatedDate }) => new Date(updatedDate)?.getDay() === index);
        const graphCoord = {
          x: day,
          y: record?.weight ?? firstNonZeroRecordWeight
        }
        firstNonZeroRecordWeight = record?.weight ?? firstNonZeroRecordWeight;
        minValue = Math.max(0, Math.min(minValue, firstNonZeroRecordWeight * WEIGHT_GRAPH_MIN_VALUE_OFFSET));
        return graphCoord;
      });

      return { graphData, minValue };
    }
  }
}