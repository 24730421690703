import React from 'react';
import './ExerciseGoalCard.scss';
import { EXERCISE_GOAL_LIST, HNW_EXERCISE_LOG } from '../commonConstant';
import HealthHomeProgressBar from '../healthHomeProgressBar/HealthHomeProgressBar';
import { getParamsString } from '../../utill.func';

const ExerciseGoalCard = ({
  item,
  toggleTimeLog,
  setExerciseMenuId,
  exerciseTimeLogList,
  isTargetCalorieCalculated,
  targetCalorie,
  routeTo
}) => {
  return (
    <div className="goal-card-container">
      <div
        className="goal-excercise-card-single"
        onClick={() => {
          if (
            exerciseTimeLogList?.length &&
            exerciseTimeLogList.filter(
              (a) => a?.excerciseCategory?.id === item?.exerciseMenuId,
            ).length
          ) {
            toggleTimeLog(true);
            setExerciseMenuId(item?.exerciseMenuId);
          } else {
            let paramsObj = {
              exerciseMenuId: item?.exerciseMenuId,
            };
            if (isTargetCalorieCalculated && targetCalorie) {
              paramsObj.suggestedCalorie = targetCalorie;
            }
            routeTo(
              'pathHnwExerciseLogDetail',
              `?${getParamsString(paramsObj)}`,
            );
          }
        }}
      >
        <a>
          <img
            src={item?.image}
            width={64}
            height={64}
            style={{
              borderRadius: '10px',
            }}
          />
        </a>
        <div className="goal-excercise-card-details">
          <div className="goal-excercise-card-col1">
            <div className="goal-excercise-card-col1-left">
              {item?.exerciseMenuName}
            </div>
            <div className="goal-excercise-card-col1-right">
              {item?.differenceInMinutes ? item?.differenceInMinutes : '-'}
            </div>
          </div>
          <div className="goal-excercise-card-col2">
            <div className="goal-excercise-card-col2-left">
              <span className="cat-name">{item?.exerciseCatName}</span>
            </div>
            <div className="goal-excercise-card-col2-right">
              /{item?.suggestionTime} {EXERCISE_GOAL_LIST.mins}
            </div>
          </div>
          <div className="progress">
            <HealthHomeProgressBar
              value={item?.differenceInMinutes}
              maxValue={item?.suggestionTime}
              type={HNW_EXERCISE_LOG}
            />
          </div>
          <div className="goal-excercise-card-col3">
            <img src="/images/healthAndWellness/exercise-log/icons/icon-burn-orange.svg" />
            <div className="goal-excercise-burn-value">
              {item?.caloriesBurn ? item?.caloriesBurn : '-'}
            </div>
            <div className="goal-excercise-burn-unit">
              {EXERCISE_GOAL_LIST.calories}
            </div>
          </div>
        </div>
        <div className="btn-icon-round">
          <img src="/images/healthAndWellness/exercise-log/icons/green-arrow.svg" />
        </div>
      </div>
    </div>
  );
};
export default ExerciseGoalCard;
