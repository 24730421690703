import AxiosHealthWellness from '.';
import config from '../../config';
import { FOODTYPES } from '../../constants';
import { Drinks, DrinksVolume, FoodLogGoal, FoodLog } from '../../model';
import { FoodHealth } from '../../model/FoodHealth.model';
import { AddMenuModel } from '../../model/AddMenu.model';
import { FoodGoal } from '../../model/GoalSetting.model';
import { FoodLogParent, WaterLogParent } from '../../model/HealthLog.model';

export const getFoodLogDetails = async (params) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/someurl`,
      { params },
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchWaterVolume = async (customerId) => {
  try {
    const res = await AxiosHealthWellness.get(`${config.API_URL}/health-log/water-volume/${customerId}`);
    if (res?.data?.data) {
      return new DrinksVolume(res.data.data);
    }
    return {};
  } catch (error) {
    console.error(error);
  }
};

export const postWaterVolume = async (payload) => {
  try {
    const res = await AxiosHealthWellness.post(`${config.API_URL}/health-log/water-volume`, payload);
    if (res?.data?.data) {
      return new DrinksVolume(res?.data?.data);
    }
    return {};
  } catch (error) {
    console.error(error);
  }
};

export const changeWaterVolume = async (id, customerId, type, payload) => {
  try {
    const res = await AxiosHealthWellness.put(`${config.API_URL}/health-log/water-volume/${id}/${customerId}/${type}`, payload);
    if (res?.data?.data) {
      return new DrinksVolume(res?.data?.data);
    }
    return {};
  } catch (error) {
    console.error(error);
  }
};

export const fetchHealthLogs = async (params) => {
  try {
    const res = await AxiosHealthWellness.get(`${config.API_URL}/health-log/health-data`, { params });
    if (res?.data?.data) {
      return res.data.data.map(el => {
        return el.hasOwnProperty('myFoodLog')
          ? new FoodHealth(el)
          : new Drinks(el);
      })
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const postWaterLog = async (payload) => {
  try {
    const res = await AxiosHealthWellness.post(`${config.API_URL}/health-log/water-log`, payload);
    if (res?.data) {
      return new Drinks(res.data);
    }
    return {};
  } catch (error) {
    console.error(error);
  }
};

export const updateWaterLog = async (id, payload) => {
  try {
    const res = await AxiosHealthWellness.put(
      `${config.API_URL}/health-log/water-log/${id}`,
      payload,
    );
    if (res?.data?.data) {
      return new Drinks(res.data.data);
    }
    return {};
  } catch (error) {
    console.error(error);
  }
};

export const fetchFoodLogData = async (customerId, payload) => {
  try {
    const res = await AxiosHealthWellness.get(`${config.API_URL}/health-log/food-log-data/${customerId}`, { params: payload });
    if (res?.data?.data) {
      return new FoodLog(res.data.data);
    }
    return {};
  } catch (error) {
    console.error(error);
  }
};

export const deleteWaterLog = async (id) => {
  try {
    const res = await AxiosHealthWellness.delete(`${config.API_URL}/health-log/water-log/${id}`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const fetchGoals = async () => {
  try {
    const res = await AxiosHealthWellness.get(`${config.API_URL}/health-goal/food-user-goal`);
    if (res?.data?.data) {
      return new FoodLogGoal(res.data.data);
    }
    return null;
  } catch (error) {
    console.error(error);
  }
};
export const addFoodMenuData = async (foodMenuData) => {
  try {
    const response = await AxiosHealthWellness.post(
      `${config.API_URL}/health-log/my-food-menu`,
      foodMenuData,
    );
    return new AddMenuModel(response?.data);
  } catch (e) {
    console.error(e);
  }
};

export const addFoodImage = async (image) => {
  try {
    const response = await AxiosHealthWellness.post(
      `${config.API_URL}/health-log/upload`,
      image
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const addFoodLogData = async (
  myFoodMenu,
  type,
  myFoodWeight,
  suggestGoalPayload,
) => {
  try {
    const response = await AxiosHealthWellness.post(
      `${config.API_URL}/health-log/food-log`,
      {
        myFoodMenu,
        type,
        myFoodWeight,
        ...(suggestGoalPayload && { ...suggestGoalPayload }),
      },
    );
    return new AddMenuModel(response?.data);
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};

export const getTargetCaloriesData = async () => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/health-goal/food-user-goal`);
    return new FoodGoal(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
};
export const getLogListData = async (params) => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/health-log/health-data`, { params });
    const sortedData = response?.data?.data.sort((a, b) => {
      return new Date(b.updatedDate) - new Date(a.updatedDate);
    });
    return sortedData.map(logItem => { return logItem.hasOwnProperty('waterVolume') ? new WaterLogParent(logItem) : logItem.hasOwnProperty('myFoodLog') ? new FoodLogParent(logItem) : "" });
  } catch (error) {
    console.log(error);
  }
};

export const deleteFoodAndSupplement = async (myFoodWeightId) => {
  try {
    const response = await AxiosHealthWellness.delete(
      `${config.API_URL}/health-log/${myFoodWeightId}`,
      myFoodWeightId
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
