import React from 'react';
import Chart from 'react-apexcharts';
import { fixDataForArea, getMonthFirstDayAndLastDay } from './graph-util';
import variables from '../../../common/commonConstant.scss';

const AreaGraph = ({ data = [], onMouseMove = () => { }, month, year, latestData, isPreviousMonth }) => {
  const { firstDayOfMonth, lastDayOfMonth } = getMonthFirstDayAndLastDay(month, year);

  const isMonthsFirstRecordAbsent = data[0]?.x?.getDate() !== 1;

  const series = [
    {
      name: 'Weight',
      type: 'area',
      data: fixDataForArea(data, month, year, latestData, isPreviousMonth),
    },
  ];

  const handleChartEvent = (event, chartContext, config) => {
    if (config?.dataPointIndex > -1) {
      const series = chartContext.w.config.series[config.seriesIndex]?.data;
      onMouseMove(series[config.dataPointIndex]);
    }
  };

  const options = {
    chart: {
      id: 'amway-line-graph',
      type: 'area',
      foreColor: variables.homegrey3,
      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
      events: {
        mouseMove: handleChartEvent,
        markerClick: handleChartEvent,
      },
    },
    legend: { show: false },
    colors: [variables.themePrimary],
    fill: {
      gradient: {
        colors: [variables.themePrimary], // specify the fill color here
        enabled: true,
        opacityFrom: 0.7,
        opacityTo: 0,
      },
    },
    opacity: 0.3, // specify the opacity of the fill color
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    grid: {
      borderColor: variables.themePrimary, // Hide the outside border
      strokeDashArray: 4, // Length of dashes in the vertical grid lines
      position: 'back', // Position of the grid lines
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines on the X-axis
        },
      },
      yaxis: {
        lines: {
          show: false, // Hide the horizontal grid lines on the Y-axis
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
      colors: [variables.white],
      strokeColor: variables.themePrimary,
      strokeWidth: 3,
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => '',
        },
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // Retrieve data point information
        const dataPoint = series[seriesIndex][dataPointIndex];

        // Format HTML content for the tooltip
        return `<div class="custom-tooltip">
            <span>${dataPoint || ''}</span>
          </div>`;
      },
    },
    xaxis: {
      type: 'datetime',
      min: firstDayOfMonth.getTime(),
      max: lastDayOfMonth.getTime() + 1,
      axisBorder: {
        show: false, // Hide the x-axis line
      },
      crosshairs: {
        show: true,
        width: 1,
        position: 'front',
        opacity: 0.9,
        stroke: {
          width: 2,
          dashArray: 3,
          color: variables.graphgreen,
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -155,
        formatter: () => ' ',
      },
      tickPlacement: 'on', // Place ticks on the axis,
      tickAmount: lastDayOfMonth.getDate(),
      offsetY: -10,
      labels: {
        show: true,
        formatter: (value) => new Date(value).getDate(),
        hideOverlappingLabels: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: (val) => Math.round(val),
      },
      tickAmount: 3,
      axisBorder: {
        show: true,
        color: variables.themePrimary,
      },
      opposite: true,
    },
  };

  return <Chart
    className={`${isPreviousMonth ? '--previous' : ''} ${isMonthsFirstRecordAbsent ? '--first-absent' : ''}`}
    options={options}
    type="area"
    series={series}
    width={'100%'}
    height={200}
  />;
};
export default AreaGraph;
