import React from "react";
import { useState } from "react";
import { FOOD_CATEGORIES_MAPPING } from "../commonConstant";
import './HealthCategorySelector.scss';

const HealthCategorySelector = ({ handleInputChange }) => {

    const handleValueChange = (value) => {
        handleInputChange(value);
    };

    return (
        <div className="picker">
            <div className="picker-window"></div>
            <ul className="picker-day">
                {FOOD_CATEGORIES_MAPPING.map((list) => {
                    return <li key={list.name} onChange={() => handleValueChange(list.label)}>{list.label}</li>
                })}
            </ul>
        </div>
    )
}

export default HealthCategorySelector;