import {
  CREATE_EXERCISE_LOG_TYPE,
  UDPATE_EXERCISE_LOG_TYPE,
  GET_EXERCISE_CATEGORY_LIST_TYPE,
  GET_EXERCISE_MENU_LIST_TYPE,
  GET_EXERCISE_TIMELOG_LIST_TYPE,
  GET_EXERCISE_LOG_BY_ID_TYPE,
  GET_PREV_EXERCISE_LOG_BY_ID_TYPE,
  DELETE_EXERCISE_LOG_TYPE,
  GET_EXERCISE_MENU_AND_TEMPLATES_TYPE,
  GET_SUGGESTED_EXERCISE_MENU_LIST_TYPE,
  GET_USER_ACTIVITY_GOAL,
  CLEAR_EXERCISE_LOG_TYPE,
  GET_EXERCISE_TIMELOG_LIST_TYPE_SUCCESS,
  GET_EXERCISE_TIMELOG_LIST_TYPE_FAILURE,
  GET_USER_ACTIVITY_GOAL_SUCCESS,
  GET_USER_ACTIVITY_GOAL_FAILURE,
} from '../actions/types';
import { ExerciseLog, ExerciseLogMenu } from '../model/ExerciseLog.model';

const INITIAL_STATE = {
  hnwUserGoalLoader: true,
  hnwExerciseLogLoader: true,
  exerciseLogData: new ExerciseLog(),
  exerciseTimeLogList: [],
  exerciseCategoryList: [],
  exerciseMenuList: [],
  suggestedExerciseMenuList: [],
  exerciseTemplateList: [],
  exerciseMenu: new ExerciseLogMenu(),
  userActivities: {
    exerciseCategory: [],
    exerciseTime: null,
    id: '',
    targetCalories: null,
  },
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAR_EXERCISE_LOG_TYPE:
      return { ...state, exerciseLogData: new ExerciseLog() }
    case CREATE_EXERCISE_LOG_TYPE:
      return { ...state, exerciseLogData: action.payload };
    case UDPATE_EXERCISE_LOG_TYPE:
      return { ...state, exerciseLogData: action.payload };
    case GET_EXERCISE_CATEGORY_LIST_TYPE:
      return { ...state, exerciseCategoryList: action.payload };
    case GET_EXERCISE_MENU_LIST_TYPE:
      return { ...state, exerciseMenuList: action.payload };
    case GET_SUGGESTED_EXERCISE_MENU_LIST_TYPE:
      return { ...state, suggestedExerciseMenuList: action.payload };
    case GET_EXERCISE_MENU_AND_TEMPLATES_TYPE:
      return {
        ...state,
        exerciseMenu: action?.payload || new ExerciseLogMenu(),
      };
    case GET_EXERCISE_TIMELOG_LIST_TYPE:
      return { ...state, hnwExerciseLogLoader: true, error: null };
    case GET_EXERCISE_TIMELOG_LIST_TYPE_SUCCESS:
      return { ...state, exerciseTimeLogList: action.payload, hnwExerciseLogLoader: false };
    case GET_EXERCISE_TIMELOG_LIST_TYPE_FAILURE:
      return { ...state, hnwExerciseLogLoader: false, error: action.payload };

    case GET_USER_ACTIVITY_GOAL:
      return { ...state, hnwUserGoalLoader: true, error: null};
    case GET_USER_ACTIVITY_GOAL_SUCCESS:
      return { ...state, userActivities: action.payload, hnwUserGoalLoader:false };
    case GET_USER_ACTIVITY_GOAL_FAILURE:
      return { ...state, hnwUserGoalLoader: true, error: action.payload };

    case GET_EXERCISE_LOG_BY_ID_TYPE:
      return { ...state, exerciseLogData: action.payload };
    case GET_PREV_EXERCISE_LOG_BY_ID_TYPE:
      return state;
    case DELETE_EXERCISE_LOG_TYPE:
      return state;
    default:
      return state;
  }
}
