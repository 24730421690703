import React from 'react';
import './CalendarHeader.scss';
import { DEFAULT_HOME_PAGE } from '../../../../../common/commonConstant';

export default function CalenderHeader({ routeTo }) {
  return (
    <div className="container-fluid calendar-header-wrapper">
      <div className="row align-items-center calendar-header">
        <div className="col-8 calendar-header-left" onClick={() => routeTo('pathHealthnWellness')}>
          <img src="/images/healthAndWellness/home.svg" />
          <span>{DEFAULT_HOME_PAGE.header}</span>
        </div>
        <div className="col-4 text-right calendar-header-right">
          <a className="mr-10 profile-icon" onClick={() => routeTo('pathProfile')}>
            <img src="/images/healthAndWellness/profile.svg" />
          </a>
          <a onClick={() => routeTo('pathCartPromotion')}>
            <img src="/images/healthAndWellness/shopping-bag.svg" />
          </a>
        </div>
      </div>
    </div>
  )
}