import React from 'react';
import '../promotion/CartPromotion.scss';

export const QuantityStepper = (props) => {
  return (
    <div
      className={`increment-container-box ${
        props.disableStepperBox && !props.disableQtyBox
          ? 'qty-stepper-inactive'
          : 'qty-stepper-active'
      } `}
    >
      {props.orderTotal.toLocaleString() == 0 ||
      props.disableDecreaseStepper === true ? (
        <span className="decrease-btn-inactive" onClick={props.decreaseOrder}>
          -
        </span>
      ) : (
        <span className="decrease-btn-active" onClick={props.decreaseOrder}>
          -
        </span>
      )}
      <div
        className={`stepper-qty ${
          props.disableStepperBox && !props.disableQtyBox
            ? 'stepper-qty-inactive'
            : 'stepper-qty-active'
        } `}
      >
        {props.orderTotal.toLocaleString()}
      </div>

      {props.disableIncreaseStepper === true ? (
        <span className="increase-btn-inactive" onClick={props.increaseOrder}>
          +
        </span>
      ) : (
        <span className="increase-btn-active" onClick={props.increaseOrder}>
          +
        </span>
      )}
    </div>
  );
};

export default QuantityStepper;
