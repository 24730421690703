import React from "react";
import { CHALLENGE } from "../../../../common/challengeConstants";

const CalorieBadgeDetail = ({ disabled }) => {

    const renderInactiveCalorieBadgeDetail = () => {
        return (
            <p>
                {CHALLENGE.badges.inactiveMissionBadgeHeading}<br />
                {CHALLENGE.badges.inactiveCalorieSubHeading}<br />
                {CHALLENGE.badges.inactiveFoodSubText}<br />
                {CHALLENGE.badges.activeQuizBadgeSubtext} {CHALLENGE.badges.bodyKey10Heading}
            </p>
        )
    }

    const renderActiveCalorieBadgeDetail = () => {
        return (
            <p>
                {CHALLENGE.badges.activeMissionBadgeHeading}<br />
                {CHALLENGE.badges.activeCalorieBadgeSubHeading}<br />
                {CHALLENGE.badges.activeQuizBadgeSubtext} {CHALLENGE.badges.bodyKey10Heading}
            </p>
        )
    }

    return (
        disabled ? renderInactiveCalorieBadgeDetail() : renderActiveCalorieBadgeDetail()
    )
}

export default CalorieBadgeDetail;