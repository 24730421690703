import React from 'react'
import "./spinner.css";
const Spinner = () => {
    return (
        <div className={"loader-wrapper"}>
            <div className={"loader"}>
                <div className="spinner-border text-success" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    );
}


export default Spinner
