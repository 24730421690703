
import React from 'react';
import { getPriceFormat, isCartABOType } from '../../services/promotion/utils.service';
import "./OrderSummary.scss"

export const OrderSummary = (props) => {
	return (
		<div className="order-summary-container">
			<div className="body">
				{props.entries.map((value) => {
					return (
						<div className="content-alignment">
							<div className="product-description">
								<div className="product-id">#{value.product.sku}</div>
								<div className="product-name">{value.product.identifier}</div>
								<div className="product-qty">x{value.quantity}</div>
							</div>
							{!value.kitEntryCode &&
							<div className="price-container">
								<div className="product-price">฿ {getPriceFormat(value.totalPrice.price)}</div>
								{isCartABOType(props.cartData.account.accountType) &&
								<div className="product-pvbv">PV {value.amwayValue.pointValue.toLocaleString()} / BV {value.amwayValue.businessVolume.toLocaleString()}</div>}
							</div>}
						</div>
					)
				})}
				<div className="summary-divider"></div>
				<div className="total-alignment">
					<div className="total-items-alignment">
						<div className="heading">ยอดรวม</div>
						<div className="total-price">{props.totalPrice}</div>
					</div>
					<div className="total-items-alignment">
						<div className="heading">ค่าจัดส่ง</div>
						<div className="total-pvbv">{props.deliveryCost}</div>
					</div>
					<div className="total-items-alignment">
						<div className="heading">ส่วนลด</div>
						<div className="total-pvbv">- {props.discount}</div>
					</div>
					{isCartABOType(props.cartData.account.accountType) &&
					<div className="total-items-alignment">
						<div className="heading">PV / BV</div>
						<div className="total-pvbv">+ {props.totalPvBv.pointValue.toLocaleString()} / {props.totalPvBv.businessVolume.toLocaleString()}</div>
					</div>}
				</div>
				<div className="summary-divider"></div>
			</div>
		</div>
	);
}

export default OrderSummary;
