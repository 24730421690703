import React from 'react';
import './MyDashboardStepsLoader.scss';

const MyDashboardStepsLoader = ({ className = null }) => {
  return (
    <div className="my-dashboard-step-container">
      <div className="steps-skeleton-container">
        <div className="steps-skeleton-card-container">
          <div
            className={`skeleton-card-image skeleton ${className || ''}`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MyDashboardStepsLoader;
