import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { fetchSmartReport } from '../../services/promotion/utils.service';
const liff = window.liff;

export const history = createBrowserHistory();

export default function ViewSmartReport() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const didMount = async () => {
      const { keyword } = extractUrlParams();
      await handleInitSmartReport(keyword);
    };
    didMount();
  }, []);

  const extractUrlParams = () => {
    let keyword = '';
    const urlParams = queryString.parse(history.location.search);
    if (urlParams) {
      keyword = urlParams?.keyword ?? '';
    }
    return { keyword };
  };

  const handleInitSmartReport = async (keyword) => {
    try {
      setLoading(true);
      if (keyword) {
        const reportResponse = await fetchSmartReport(keyword.toLowerCase());

        if (reportResponse?.token && reportResponse?.pdfUrl) {
          let request = new XMLHttpRequest();
          request.open('GET', reportResponse?.pdfUrl, true);
          request.responseType = 'blob';
          request.setRequestHeader('Accept', 'application/pdf');
          request.setRequestHeader(
            'Authorization',
            'Bearer ' + reportResponse?.token,
          );
          request.setRequestHeader(
            'Cache-Control',
            'no-cache, no-store, must-revalidate',
          );
          request.onload = (data) => {
            if (request.status === 200) {
              const url = window.URL.createObjectURL(
                data.currentTarget.response,
              );
              const link = document.createElement('a');
              link.setAttribute('href', url);
              document.body.appendChild(link);
              link.click();
              link.remove();
              setTimeout(() => pushDownloadSuccessMessageToUser(), 2000);
            } else {
              pushErrorMessageToUser();
            }
          };
          request.onerror = () => {
            pushErrorMessageToUser();
          };
          request.send();
        }
      } else {
        pushErrorMessageToUser();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      pushErrorMessageToUser();
      setLoading(false);
    }
  };

  const pushDownloadSuccessMessageToUser = () => {
    let successDownloadMsgText = 'Your file has been downloaded';
    liff
      .sendMessages([
        {
          type: 'text',
          text: successDownloadMsgText,
        },
      ])
      .then(() => {
        console.log('message sent', successDownloadMsgText);
        liff.closeWindow();
      })
      .catch((err) => {
        console.log('error', err);
        liff.closeWindow();
      });
  };

  const pushErrorMessageToUser = () => {
    let errMsgText = 'ไม่พบข้อมูลรายงานการจ่ายส่วนลดในเดือนที่เลือก';
    liff
      .sendMessages([
        {
          type: 'text',
          text: errMsgText,
        },
      ])
      .then(() => {
        console.log('message sent', errMsgText);
        liff.closeWindow();
      })
      .catch((err) => {
        console.log('error', err);
        liff.closeWindow();
      });
  };

  return (
    <section>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
    </section>
  );
}
