import React from 'react';
import { HealthHomeProgressBar } from '../../../../../common';
import { HEALTH_SUMMARY_RESULT_CARD_DETAILS } from '../../../../../common/commonConstant';
import './LogProgressCard.scss';

export default function LogProgressCard({
  type,
  value,
  maxValue,
  onClick = () => { },
  showArrow = true,
}) {
  return (
    <div onClick={onClick}>
      <div className="log-progress-container">
        <div className="log-progress-left">
          <img src={HEALTH_SUMMARY_RESULT_CARD_DETAILS[type].icon} />
          <div>{HEALTH_SUMMARY_RESULT_CARD_DETAILS[type].heading}</div>
        </div>
        <div className="log-progress-right">
          <div className="log-progress-right__value_wrapper">
            <div className="log-progress-right__value_wrapper-consume">
              {value ? Math.round(value).toLocaleString() : value || '-'}
            </div>
            <div className="log-progress-right__value_wrapper-target">
              <span className="log-progress-overall-value">
                /{maxValue ? Math.round(maxValue).toLocaleString() : maxValue || '-'}
              </span>
              <span className="log-progress-unit">{` ${HEALTH_SUMMARY_RESULT_CARD_DETAILS[type].unit}`}</span>
            </div>
          </div>
          {showArrow && (
            <div className="btn-icon-round">
              <img src="/images/healthAndWellness/exercise-log/icons/green-arrow.svg" />
            </div>
          )}
        </div>
      </div>
      <HealthHomeProgressBar type={type} value={value} maxValue={maxValue} />
    </div>
  );
}
