export const EContentType = {
  AMWAY_LIBRARY: 'AmwayLibrary',
  MY_LIBRARY: 'MyLibrary',
};

export const EStatus = {
  PUBLISH: 'PUBLISH',
  DELETE: 'DELETE',
};

export const EFormAction = {
  CREATE: 'Create New',
  UPDATE: 'Update',
  DELETE: 'Delete',
};

export const EModalTitle = {
  CREATE_SUCCESS: 'สร้างสำเร็จ',
  UPDATE_SUCCESS: 'บันทึกสำเร็จ',
};

export const EImageType = {
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  GIF: 'image/gif',
  PNG: 'image/png',
};
