import React from 'react';
import { HnWFoodLogCard } from '../../../../../common';
import MealItem from './mealItem/MealItem';
import {
  calculateCaloriesByWeight,
  getParamsString,
} from '../../../../../utill.func';
import { MEAL_CARDS, QUERY_PARAMS_MAPPER } from '../../../../../constants';
import { routePathKey } from '../../../../../common/commonConstant';

const FoodLogMeal = ({ foodLogData, foodGoalData, isFoodGoalCalculated, routeTo }) => {

  const handleAddClick = (card) => {
    let pathParamsObj = {
      referrer: QUERY_PARAMS_MAPPER[card.type].type,
      showSearch: QUERY_PARAMS_MAPPER[card.type].search,
    };
    if (isFoodGoalCalculated && foodGoalData) {
      pathParamsObj = {
        ...pathParamsObj,
        suggestedFoodGoal: encodeURIComponent(JSON.stringify(foodGoalData)),
      };
    }

    routeTo(routePathKey.foodLog, `?${getParamsString(pathParamsObj)}`);
  };

  const handleItemClick = (el) => {
    if (el?.myFoodWeight) {
      routeTo(
        routePathKey.foodDetail,
        `?id=${el.id}&myFoodWeight=${el?.myFoodWeight}&myFoodWeightId=${el?.myFoodWeightId}&isDelete=true`,
      );
    } else {
      routeTo(
        routePathKey.foodDetail,
        `?id=${el.id}&myFoodWeightId=${el?.myFoodWeightId}&isDelete=true`,
      );
    }
  };
  for (const card of MEAL_CARDS) {
    if (foodLogData) {
      card.data = foodLogData[card.type];
      card.totalCalories = card.data?.reduce(
        (acc, curr) => acc + calculateCaloriesByWeight(curr),
        0,
      );
    }
  }
  return (
    <div className="food-overview-meal-container">
      <div className="container-fluid">
        <div className="food-overview-meal-title">มื้ออาหาร</div>
        <div className="food-overview-meal-card-container">
          {MEAL_CARDS.map((card, index) => (
            <HnWFoodLogCard
              key={`mealCard${index}`}
              handleClick={() => handleAddClick(card)}
              imagePath={card.imagePath}
              header={card.header}
            >
              {card?.data?.length && (
                <MealItem
                  items={card?.data}
                  totalCalories={card.totalCalories}
                  handleItemClick={handleItemClick}
                />
              )}
            </HnWFoodLogCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FoodLogMeal;
