export const getEntriesFromCart = (cart) => {
  if (!cart.entries) {
    return [];
  }
  const productEntries = cart.entries.filter((entry) =>
    isProductEntry(entry, cart),
  );
  return productEntries;
};

const isProductEntry = (entry, cart) => {
  const hasPremiumItemSet = cart.premiumItemSet.length > 0;
  if (!hasPremiumItemSet) {
    return (
      !entry.isGiveAway &&
      parseInt(entry.totalPrice.price) > 0 &&
      !entry.kitEntryCode
    );
  }
  for (const premiumItem of cart.premiumItemSet) {
    const premiumItemSet = premiumItem;
    for (const item of premiumItemSet.premiumItems) {
      const isCheckPremium = entry.product.id !== item.id;
      if (isCheckPremium == false) {
        return (
          !entry.isGiveAway &&
          parseInt(entry.totalPrice.price) > 0 &&
          false &&
          !entry.kitEntryCode
        );
      }
    }
  }
  return (
    !entry.isGiveAway &&
    parseInt(entry.totalPrice.price) > 0 &&
    true &&
    !entry.kitEntryCode
  );
};
