import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { Card, InputNumber, ProgressBar } from '../../../helpers';

import './Cart.scss';

export class Cart extends Component {
  constructor(props){
    super(props);
    this.state = {
      SOPCart: [{
        isSop: true,
        id: '0291542',
        img: 'https://via.placeholder.com/150',
        title: 'ชุดนิวทริไลท์ ออล แพลนท์ โปรตีน + นิวทริไลท์ ไฟโตพาวเดอร์',
        count: {
          round: 1,
          current: 4,
          required: 6,
        },
        status: 'bought',
        cardInfo: {
          type: 'visa',
          cardNo: '1811',
        },
        renewalDay: '3',
        price: '2350',
        bonus: {
          pv: '787',
          bv: '2360',
        },
        choices: {
          name: 'ไฟโตพาวเดอร์',
          max: 3,
          value: [
            { name: 'ออเรนจ์-เอ็ม' },
            { name: 'เลมอน-บี' },
          ],
        },
      },
      {
        isSop: true,
        id: '0291543',
        img: 'https://via.placeholder.com/150',
        title: 'ชุดนิวทริไลท์ ออล แพลนท์ โปรตีน + นิวทริไลท์ ไฟโตพาวเดอร์',
        count: {
          current: 4,
          required: 6,
        },
        status: 'notbought',
        cardInfo: {
          type: 'mastercard',
          cardNo: '1811',
        },
        renewalDay: '3',
        price: '2350',
        bonus: {
          pv: '787',
          bv: '2360',
        },
        choices: {
          name: 'ไฟโตพาวเดอร์',
          max: 3,
          value: [
            { name: 'ออเรนจ์-เอ็ม' },
            { name: 'เลมอน-บี' },
            { name: 'อะเซโรลา-ซี' },
          ],
        },
      }],
    };
  }

  renderItems = () => {
    const { SOPCart } = this.state;
    return SOPCart.map((obj,idx) => (
      <div key={idx} className="item-container">
        <img src={obj.img} />
        <div className="item-detail">
          <div className="item-title">
            {obj.title}
          </div>
          <div className="flex">
            <div className="item-price-container">
              <div className="price">
            ฿ {obj.price}
              </div>
              <div className="bonus">
            + {obj.bonus.pv} PV / {obj.bonus.bv} BV
              </div>
            </div>
            <InputNumber value={1}/> 
          </div>
        </div>
        
      </div>
    ));
  }

  render() {
    const { renderItems } = this;
    const { SOPCart } = this.state;
 
    return (
      <div className="cart-container">
        <div className="header">
          <img src="/images/Active.png" />
          <div className="title">ตะกร้าของฉัน</div>
        </div>
        {
          !isEmpty(SOPCart) ? <>
          <div className="body">
            <Card className="items">
              <div className="card-header">
            รายการสินค้า
              </div>
              <div className="card-body">
                {renderItems()}
              </div>
            </Card>
            <Card className="point-discount">
            เพิ่มอีกเพียง 2,300 PV เพื่อรับ % ส่วนลดถัดไป
              <ProgressBar percent={80} />
            </Card>
            <Card className="address">
            address
            </Card>
            <Card className="phoneno">
            phoneno
            </Card>
          </div>
        <div className="footer">
          <div className="discount">
            <input className="discount-input" />
            <button className="register-next-button active">ใช้</button>
          </div>
          <div className="checkout">
          checkout
            <button className="register-next-button active">สั่งซื้อ</button>
          </div>
        </div>
      </> 
            : <div className="empty">ไม่มีสินค้าในตะกร้า</div>
        }

      </div>
    );
  }
}

const mapStateToProps = ({ SOPCart }) => ({
  SOPCart,
});

export default connect(mapStateToProps)(Cart);
