export default {
  // LIFF_URL: process.env.REACT_APP_LIFF_URL,
  API_URL: process.env.REACT_APP_API_URL,
  API_URL_UAT: process.env.REACT_APP_API_URL_UAT,
  S3_URL: process.env.REACT_APP_S3_URL,
  PORT: process.env.PORT,
  AVATAR_DEFAULT:
    'https://obs.line-scdn.net/0hytw49WslJltKTQmaOhZZDHQQLTl5LzhQaCg9PjoYeD5uKmZaJis8ODwjeGM1KmkMcC0sPGgZeztlKjM',
  AVATAR_DEFAULT2:
    'https://images.unsplash.com/photo-1616740794225-63e524d43ec7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2795&q=80',
  SHARE_URL: process.env.REACT_APP_SHARE_URL,
  AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
  AWS_SECRET_KEY: process.env.REACT_APP_AWS_SECRET_KEY,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_BUCKET: process.env.REACT_APP_AWS_S3_BUCKET,
  ENVIRONMENT: process.env.ENV_VAR,
  CHALLENGE_API_URL: 'https://t92qrdxvx4.execute-api.ap-southeast-1.amazonaws.com/v2/external',
  CHALLENGE_X_API_KEY: '29e44a53-68ae-4fcd-8aa6-527c771dcf08',
  CHALLENGE_QUIZ_API_URL: 'https://t92qrdxvx4.execute-api.ap-southeast-1.amazonaws.com/v2/job-management',
  CHALLENGE_QUIZ_EVENT_ID: 'f37a5724-cc7f-4360-9ce0-e07ec6878fad',
  CHALLENGE_QUIZ_REF_USER_ID: 'b8ee446f-12e3-4c1e-948a-2ea35f229968',
  CHALLENGE_QUIZ_X_API_KEY: '4f09f33d2bc54d209e903bd411ee5d3e0b24028e86b943e78f7dfe5be50121af',
  CMS_URL: process.env.REACT_APP_S3_URL_ASSESMENT
};
