import React from "react";
import './ChallengeMyRewardModal.scss';
import { CHALLENGE } from "../../../../common/challengeConstants";
import { thaiDateWOTime } from "../../../../utill.func";


const ChallengeMyRewardModal = ({rewardModalData}) => {
    return (
        <div class="modal fade" id="rewardDetailsModal" tabindex="-1" aria-hidden="true" className="challenge-reward-details-modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body pt-0">
                        <img src = '/images/challenge/challenge-img-container.svg' className="product-img-container"/>
                        <img src={rewardModalData?.rewardImage} className="product-img" alt="" />
                        <div className="product-card-container">
                            <div className="product-title">{rewardModalData?.rewardActualName}.</div>
                            <div className="product-price">
                                <div className="product-price-left">
                                    <span>
                                        {`${CHALLENGE.reward.rewardValue} ${rewardModalData?.rewardPrice} ${CHALLENGE.reward.rewardCurrency}`}
                                    </span>
                                </div>
                                <div className="product-price-right">
                                    <img src={rewardModalData?.rewardStatusIconAndTitle?.rewardIcon} alt="" />
                                    <span className={`reward-tag-title ${rewardModalData?.rewardStatusIconAndTitle?.rewardClass}`}>
                                        {rewardModalData?.rewardStatusIconAndTitle?.rewardTitle}
                                    </span>
                                    {rewardModalData?.rewardClaimDate &&<span>
                                        {`(${thaiDateWOTime(rewardModalData?.rewardClaimDate, 'dd MMM yy')})`}
                                    </span>}
                                </div>
                            </div>
                            <div className="product-price-description">
                                {rewardModalData?.rewardDescription}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChallengeMyRewardModal