// Login Page
export const APP_INIT = "APP_INIT";

// Login Page
export const HTTP_LOGIN_FETCHING = "HTTP_LOGIN_FETCHING";
export const HTTP_LOGIN_SUCCESS = "HTTP_LOGIN_SUCCESS";
export const HTTP_LOGIN_FAILED = "HTTP_LOGIN_FAILED";

// Error Code
export const E_PICKER_CANCELLED = "E_PICKER_CANCELLED";
export const E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR =
  "E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR";
export const E_PERMISSION_MISSING = "E_PERMISSION_MISSING";
export const E_PICKER_NO_CAMERA_PERMISSION = "E_PICKER_NO_CAMERA_PERMISSION";
export const E_USER_CANCELLED = "E_USER_CANCELLED";
export const E_UNKNOWN = "E_UNKNOWN";
export const E_DEVELOPER_ERROR = "E_DEVELOPER_ERROR";
export const TIMEOUT_NETWORK = "ECONNABORTED"; // request service timeout
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE =
  "An error has occurred. The photo was unable to upload.";


// export const apiUrl = process.env.REACT_APP_BASEURL + '/api/v2';

export const apiUrl = "https://db5d-49-237-45-155.ngrok-free.app";

export const socketurl = process.env.REACT_APP_BASEURL;
export const BASEURL2 = process.env.REACT_APP_URL;
export const manual = process.env.REACT_APP_WEBURL;
export const LINECLIENT_ID = process.env.REACT_APP_ENV_CLIENT_ID;
export const apiUrl1 = process.env.REACT_APP_BASEURL + '/api/v1';
export const YES = "YES";
export const NO = "NO";
export const OK = "ok";
export const NOK = "nok";

export const COLOR_PRIMARY = '#FFCC00';
export const COLOR_SECONDARY = '#D40511';

export const server = {
  LOGIN_URL: `/admin/login`,
  REGISTER_URL: `authen/register`,
  LOGIN_PASSED: `yes`,
};
