import { round } from "../../services";

export const logListData = {
    headerText: "รายการอาหาร",
    headerIcon: "/images/healthAndWellness/foodLog/icons/food_log_header_icon.svg"
}

export const logCardData = [{
    image: "/images/healthAndWellness/foodLog/food_log_image.svg",
    title: "สลัดไก่ย่าง",
    description: { icon: "/images/healthAndWellness/foodLog/icons/food_log_menu_icon.svg", value: "250", unit: 'แคลอรี' },
    date: "15 มีนาคม 67  10:30:56 น."
},
{
    image: "/images/healthAndWellness/foodLog/food_log_image.svg",
    title: "สลัดไก่ย่าง",
    description: { icon: "/images/healthAndWellness/foodLog/icons/food_log_menu_icon.svg", value: "250", unit: 'แคลอรี' },
    date: "15 มีนาคม 67  10:30:56 น."
}, {
    image: "/images/healthAndWellness/foodLog/food_log_image.svg",
    title: "สลัดไก่ย่าง",
    description: { icon: "/images/healthAndWellness/foodLog/icons/food_log_menu_icon.svg", value: "250", unit: 'แคลอรี' },
    date: "15 มีนาคม 67  10:30:56 น."
}, {
    image: "/images/healthAndWellness/foodLog/food_log_image.svg",
    title: "สลัดไก่ย่าง",
    description: { icon: "/images/healthAndWellness/foodLog/icons/food_log_menu_icon.svg", value: "250", unit: 'แคลอรี' },
    date: "15 มีนาคม 67  10:30:56 น."
}, {
    image: "/images/healthAndWellness/foodLog/food_log_image.svg",
    title: "สลัดไก่ย่าง",
    description: { icon: "/images/healthAndWellness/foodLog/icons/food_log_menu_icon.svg", value: "250", unit: 'แคลอรี' },
    date: "15 มีนาคม 67  10:30:56 น."
}

]

export const waterLogListData = {
    headerText: "รายการน้ำดื่ม",
    headerIcon: "/images/healthAndWellness/foodLog/icons/water_log_header_icon.svg"
}

export const waterLogCardData = [{
    image: "/images/healthAndWellness/foodLog/water_log_image.svg",
    title: "น้ำดื่ม",
    description: { icon: "/images/healthAndWellness/foodLog/icons/water_log_menu_icon.svg", value: "250", unit: 'มิลลิลิตร' },
    date: "15 มีนาคม 67  10:30:56 น."
},
{
    image: "/images/healthAndWellness/foodLog/water_log_image.svg",
    title: "น้ำดื่ม",
    description: { icon: "/images/healthAndWellness/foodLog/icons/water_log_menu_icon.svg", value: "250", unit: 'มิลลิลิตร' },
    date: "15 มีนาคม 67  10:30:56 น."
}, {
    image: "/images/healthAndWellness/foodLog/water_log_image.svg",
    title: "น้ำดื่ม",
    description: { icon: "/images/healthAndWellness/foodLog/icons/water_log_menu_icon.svg", value: "250", unit: 'มิลลิลิตร' },
    date: "15 มีนาคม 67  10:30:56 น."
}, {
    image: "/images/healthAndWellness/foodLog/water_log_image.svg",
    title: "น้ำดื่ม",
    description: { icon: "/images/healthAndWellness/foodLog/icons/water_log_menu_icon.svg", value: "250", unit: 'มิลลิลิตร' },
    date: "15 มีนาคม 67  10:30:56 น."
}, {
    image: "/images/healthAndWellness/foodLog/water_log_image.svg",
    title: "น้ำดื่ม",
    description: { icon: "/images/healthAndWellness/foodLog/icons/water_log_menu_icon.svg", value: "250", unit: 'มิลลิลิตร' },
    date: "15 มีนาคม 67  10:30:56 น."
}];

export const foodExerciseRecordingData = [
    {
        type: "FOOD",
        recordQuantity: '', // food log counter
        recordUnit: 'ครั้ง',
        recordName: 'การกิน',
        recordImagePath: '/images/healthAndWellness/foodLog/record-food-top.svg',
        noRecordImagePath: '/images/healthAndWellness/foodLog/icons/no-record-food.svg',
        isRecord: false
    },
    {
        type: "EXERCISE",
        recordQuantity: '170', // exercise log counter
        recordUnit: 'ครั้ง',
        recordName: 'การออกกำลังกาย',
        recordImagePath: '/images/healthAndWellness/foodLog/record-exercise-top.svg',
        noRecordImagePath: '/images/healthAndWellness/foodLog/icons/no-record-exercise.svg',
        isRecord: true
    }
];

export const recommendedExerciseList = [
    {
        image: '/images/healthAndWellness/exercise-log/exercise-1.svg',
        exerciseName: 'ฮูลาฮูป',
        exerciseCategory: 'คาร์ดิโอ',
        time: '30',
        calories: '550',
        times: '100'
    },
    {
        image: '/images/healthAndWellness/exercise-log/exercise-1.svg',
        exerciseName: 'การปั่นจักรยาน',
        exerciseCategory: 'ปั่นจักรยาน',
        time: '30',
        calories: '550',
        times: '100'
    },
    {
        image: '/images/healthAndWellness/exercise-log/exercise-1.svg',
        exerciseName: 'กระโดดเชือก',
        exerciseCategory: 'คาร์ดิโอ',
        time: '30',
        calories: '550',
        times: '100'
    },
    {
        image: '/images/healthAndWellness/exercise-log/exercise-1.svg',
        exerciseName: 'การปั่นจักรยานบนถนน',
        exerciseCategory: 'ปั่นจักรยาน',
        time: '30',
        calories: '550',
        times: '100'
    },
    {
        image: '/images/healthAndWellness/exercise-log/exercise-1.svg',
        exerciseName: 'เดินกลางแจ้ง',
        exerciseCategory: 'เดินและวิ่ง',
        time: '30',
        calories: '550',
        times: '100'
    },

];

export const exerciseGoalList = [
    {
        image: '/images/healthAndWellness/exercise-log/exercise-1.svg',
        exerciseName: 'เดินในร่ม',
        exerciseCategory: 'เดินและวิ่ง',
        time: '30',
        exerciseTime: '20',
        calories: '550',
        times: '100'
    },
    {
        image: '/images/healthAndWellness/exercise-log/exercise-1.svg',
        exerciseName: 'การปั่นจักรยาน',
        exerciseCategory: 'ปั่นจักรยาน',
        time: '30',
        exerciseTime: '20',
        calories: '400',
        times: '400'
    }

];

export const HOME_BADGE_CARD = [
    {
        image: '/images/healthAndWellness/home-badge-icon.svg',
        title: 'ได้รับเหรียญ',
        subtitle: 'First 5 Km'
    },
    {
        image: '/images/healthAndWellness/home-badge-icon.svg',
        title: 'ได้รับเหรียญ',
        subtitle: 'First 5 Km'
    },
    {
        image: '/images/healthAndWellness/home-badge-icon.svg',
        title: 'ได้รับเหรียญ',
        subtitle: 'First 5 Km'
    }
]

export const HOME_STEPS_CARD = (accumulatedSteps) => [
    {
        value: accumulatedSteps?.stepCount || 0,
        distance: accumulatedSteps?.stepDistance || 0,
        calories: accumulatedSteps?.stepKcal || 0,
        percent: accumulatedSteps?.perCount || 0
    }
]

export const HOME_GOAL_CARD = (exerciseLogs) => exerciseLogs.map(
    exercise => ({
        title: exercise.exerciseMenuName,
        value: round(exercise.differenceInMinutes, 2),
        target: exercise.suggestionTime,
        calories: exercise.caloriesBurn,
        exercisePercent: round(exercise.differenceInMinutes * 100/exercise.suggestionTime, 2)
    })
)

export const HOME_FOOD_LOG_DATA = {
    totalCalories: 1400,
    caloriesIn: 820,
    remainingCalories: 580
}

export const HOME_EXERCISE_LOG_CARD = {
    used_value:568,
    total_value:800,
    percent:60
}
