import React from 'react';
import { HnwSetterCard, HnwCheckbox } from '..';
import './HnwSetterCardList.scss';
import { HNW_GOAL_SETTING } from '../commonConstant';

export default function HnwSetterCardList({
  collectionId = HNW_GOAL_SETTING.ACTIVITY.DEFAULT_COLLECTION_ID,
  collectionValue = HNW_GOAL_SETTING.ACTIVITY.DEFAULT_COLLECTION_VALUE,
  collectionImage = HNW_GOAL_SETTING.ACTIVITY.DEFAULT_COLLECTION_IMAGE,
  headerText,
  headerImage,
  defaultCardImg,
  showEditBtn = true,
  isEditMode = false,
  cardDetailsList,
  showAddBtn = true,
  btnText,
  minCardValue,
  maxCardValue,
  stepperCardValue,
  onAddBtnClick,
  onEditBtnClick,
  onCancelBtnClick,
  onValueChange,
  onCheckValueChange,
  hnwSetterCardOptions = null,
}) {
  const handleCheckboxClick = (cardObj, id) => {
    const { isChecked, name } = cardObj;
    onCheckValueChange(isChecked, name, id);
  };
  return (
    <div className="hnw-setter-list-container">
      <div className="hnw-setter-list-container__header">
        <div className="hnw-setter-list-container__header__left">
          {headerImage && (
            <img
              src={headerImage}
              alt="Card-List-Image"
              className="hnw-setter-list-container__header__left-img"
            />
          )}
          <span className="hnw-setter-list-container__header__left-text">
            {headerText}
          </span>
        </div>
        {showEditBtn && isEditMode ? (
          <button
            className="hnw-setter-list-container__header-cancelBtn"
            onClick={onCancelBtnClick}
          >
            {HNW_GOAL_SETTING.ACTIVITY.EXERCISE_MENU_CANCEL_TH}
          </button>
        ) : (
          <img
            src="/images/healthAndWellness/goals/common/edit.svg"
            alt="edit-icon"
            onClick={onEditBtnClick}
          />
        )}
      </div>
      <div className="hnw-setter-list-container__list">
        {cardDetailsList.map((card, idx) => {
          const checkedState = card.isChecked ? 'checked' : 'unchecked';
          const menuHeaderImage = card[collectionImage]
            ? card[collectionImage]
            : defaultCardImg;
          let menuUnit = card.unit;
          if (hnwSetterCardOptions?.dynamicCalculateUnit) {
            menuUnit = hnwSetterCardOptions?.dynamicCalculateUnit(
              card,
              hnwSetterCardOptions?.dynamicCalculationType,
            );
          } else if (hnwSetterCardOptions?.unit) {
            menuUnit = hnwSetterCardOptions?.unit;
          }
          return (
            <div
              key={card[collectionId]}
              className={
                isEditMode
                  ? 'hnw-setter-list-container__list__card-container'
                  : ''
              }
            >
              {isEditMode && (
                <HnwCheckbox
                  state={checkedState}
                  onCheckValueChange={() =>
                    handleCheckboxClick(card, card[collectionId])
                  }
                />
              )}
              <HnwSetterCard
                id={card[collectionId] ? card[collectionId] : idx}
                name={card.name}
                headerText={card.name}
                headerImage={menuHeaderImage}
                unit={menuUnit}
                isListCard
                defaultValue={hnwSetterCardOptions?.defaultValue || 0}
                value={`${card[collectionValue]}`}
                stepperVal={
                  hnwSetterCardOptions?.stepperVal || stepperCardValue
                }
                maxValue={hnwSetterCardOptions?.maxValue || maxCardValue}
                minValue={hnwSetterCardOptions?.minValue || minCardValue}
                showResetBtn={false}
                onValueChange={onValueChange}
                borderColor={hnwSetterCardOptions?.borderColor || null}
              />
            </div>
          );
        })}
      </div>
      {showAddBtn && !isEditMode && (
        <button
          className="hnw-setter-list-container__btn"
          onClick={onAddBtnClick}
        >
          <img src="/images/healthAndWellness/goals/common/button_add.svg" />
          {btnText}
        </button>
      )}
    </div>
  );
}
