import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import ScrollLock from 'react-scrolllock';
import { osName } from 'react-device-detect';
import { connect } from 'react-redux';

import { alertActions } from '../../actions';
import { commonConstant } from '../../common';
import Condition from '../condition/condition.jsx';
import { customerService, activityService, productService } from '../../services';
import config from '../../config';

class Interest extends Component {
  constructor(props) {
    super(props);

    if (sessionStorage.getItem('customer') === null || sessionStorage.getItem('customer') === 'undefined') {
      return window.location.replace(commonConstant.pathHome);
    }
    const customer = JSON.parse(sessionStorage.getItem('customer'));
    this.state = {
      products: [],
      interests: [],
      groupSegmentId: [],
      customer: customer,
      loading: false,
      accept: false,
      error: false,
      lockScroll: false,
      pointerEvents: 'none',
      imageURLADG: null,
      condition: false,
    };
  }

  async getImageURL(image) {
    this.setState({
      imageURLADG: await this.getImageFromS3(image),
    });
    return await this.getImageFromS3(image);
  }

  async componentDidMount() {
    const interests = this.state.interests;
    const groupSegmentId = this.state.groupSegmentId;
    let registerStatus = null;
    if (typeof this.props.location.state === 'object') {
      this.props.location.state.interests.forEach((product) => {
        interests.push(product);
        groupSegmentId.push(product.groupSegmentId);
      });
      this.setState({
        accept: this.props.location.state.accept,
      });
    } else {
      let { customer } = this.state;
      if (!customer.userInterests) {
        customer = this.state.customer;
      }
      if (Array.isArray(customer.userInterests) && customer.userInterests.length > 0) {
        this.setState({
          accept: true,
        });
      }
      customer.userInterests.forEach((product) => {
        if (product.type === customer.type) {
          interests.push(product);
          groupSegmentId.push(product.groupSegmentId);
        }
      });
    }

    if (!this.state.customer.name) {
      return window.location.replace(commonConstant.pathHome);
    }

    const rawProducts = await productService.get({
      status: 'active',
      type: this.state.customer.type,
    });

    const products = [];
    const overRideImageURL = await rawProducts.map(async (value, index) => {
      value.imagePath = `${config.S3_URL}/${value.imagePath.replace(/\//, '')}`;
      value.isActive = !!interests.find(element => element._id === value._id);
      products.push(value);
    });
    await Promise.all(overRideImageURL);
    this.setState({ interests: interests, products: products, groupSegmentId: groupSegmentId });
    const { customer } = this.props;
    registerStatus = customer.registerStatus;
    if (!registerStatus) {
      registerStatus = 'incomplete';
    }

    const params = {
      registerStatus: registerStatus,
      action: 'SELECT_PRODUCT',
    };
    await customerService.update(this.state.customer._id, params);
  }

  handleSubmit = async () => {
    const { products, accept } = this.state;
    this.setState({
      loading: true,
      lockScroll: true,
      pointerEvents: 'auto',
    });
    window.scrollTo(0, 0);
    const isProd = products.filter(_ => _.isActive);
    if (isProd.length === 0) {
      this.props.showError('กรุณาเลือกสินค้าที่สนใจอย่างน้อยหนึ่งกลุ่ม');
      this.setState({ loading: false, lockScroll: false, pointerEvents: 'none' });

      return false;
    }

    if (!accept) {
      this.props.showError('กรุณากดยอมรับเงื่อนไข');
      this.setState({ loading: false, lockScroll: false, pointerEvents: 'none' });

      return false;
    }

    const newCustomer = await customerService.update(this.state.customer._id, {
      uid: this.state.customer.uid,
      name: this.state.customer.name,
      userInterests: isProd.map((value) => value._id),
      info: osName === 'IOS' ? 'iPhone' : osName,
      socialType: 'LINE',
    });

    activityService.create({
      uid: this.state.customer.uid,
      userId: this.state.customer._id,
      status: 'incomplete',
      action: 'SELECT_PRODUCT',
    });
    sessionStorage.setItem('customer', JSON.stringify(newCustomer));
    this.setState({ lockScroll: false, pointerEvents: 'none' });
    return this.props.history.push('/success');
  }

  handleSelect = _key => {
    const products = this.state.products.map((item, key) => {
      if (_key === key) {
        if (this.state.products.filter(_ => _.isActive).length < 3) {
          item.isActive = !item.isActive;
        } else {
          item.isActive = false;
        }
      }
      return item;
    });
    this.setState({ products });
  }
  handleCheckBox = () => this.setState({ accept: !this.state.accept })
  handleCondition = () => this.setState({ condition: !this.state.condition })

  render() {
    const { products, accept, lockScroll, loading, condition } = this.state;
    const isProd = products.filter(_ => _.isActive);
    const isTrue = accept && isProd.length ? true : false;
    if (condition) { return <Condition fn={this.handleCondition} />; }
    return (
      <div className="register interest">
        <ScrollLock isActive={lockScroll} />
        <LoadingOverlay active={loading} spinner text="Loading" />

        <div className="detail-box">

          <h1>กรุณาเลือกหัวข้อที่คุณสนใจ</h1>
          <p>(เลือกได้มากสุด 3 ข้อ)</p>
          <div className="list-interest">
            {products.map((value, index) =>
              <div key={index} onClick={() => this.handleSelect(index)} className={`item ${value.isActive ? '_active' : ''}`}>
                <div className="picture" htmlFor={index}>
                  <img src={value.imagePath} alt="" />
                </div>
                <div className="card-body">{value.name}</div>
              </div>,
            )}
          </div>
        </div>
        <div className="footer">
          <div className="input-checkbox">
            <span onClick={this.handleCheckBox} className={`_box-check ${accept ? '_active' : ''}`}>
              {
                accept ?
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.87987 11.8301L13.8799 3.83012L13.1199 3.17012L6.11987 11.1701L6.87987 11.8301ZM2.16987 8.38012L6.16987 11.8801L6.82987 11.1201L2.82987 7.62012L2.16987 8.38012ZM13.8799 3.83012C13.9232 3.78021 13.9563 3.72226 13.9772 3.65958C13.9982 3.59689 14.0066 3.53069 14.0019 3.46476C13.9973 3.39883 13.9797 3.33446 13.9502 3.27533C13.9207 3.21619 13.8798 3.16345 13.8299 3.12012C13.78 3.07678 13.722 3.0437 13.6593 3.02276C13.5966 3.00181 13.5304 2.99343 13.4645 2.99807C13.3986 3.00271 13.3342 3.0203 13.2751 3.04982C13.2159 3.07934 13.1632 3.12021 13.1199 3.17012L13.8799 3.83012ZM6.49987 11.5001L6.16987 11.8801C6.27139 11.966 6.40241 12.0088 6.53504 11.9994C6.66766 11.9901 6.79139 11.9293 6.87987 11.8301L6.49987 11.5001ZM2.82987 7.62012C2.72909 7.53259 2.59767 7.48869 2.46452 7.49807C2.33137 7.50745 2.20739 7.56933 2.11987 7.67012C2.03235 7.7709 1.98845 7.90232 1.99783 8.03547C2.0072 8.16862 2.06909 8.29259 2.16987 8.38012L2.82987 7.62012Z" fill="#2C2C2C" />
                  </svg> : null
              }
            </span>
            <Link onClick={this.handleCondition}>ยอมรับเงื่อนไข</Link>
          </div>

          <button className={`register-next-button ${isTrue ? 'active' : ''}`} onClick={this.handleSubmit}> ยืนยัน </button>
        </div>

      </div>
    );
  }
}

const mapStateToProps = ({ customer }) => ({ customer });

function mapDispatchToProps(dispatch) {
  return {
    showError: (message) => dispatch(alertActions.showError(message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Interest);
