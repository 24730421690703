export class ChallengeImageData {
  constructor(data) {
    this.id = data?.id || '';
    this.eventId = data?.event_id || '';
    this.name = data?.name || '';
    this.type = data?.type || '';
    this.startDate = data?.start_date || '';
    this.endDate = data?.end_date || '';
    this.isAutoApproved = data?.is_auto_approved || false
    this.userUploads = data?.user_uploads ||  []
  }
}

export class ImagePreSignedUrlAndPath {
  constructor(data){
    this.url = data.url;
    this.path = data.path
  }
}