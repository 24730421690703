import React from "react";
import './HealthLogItemEmptyCard.scss';
import { EMPTY_CARD_HEADING } from "../commonConstant";

const HealthLogItemEmptyCard = ({ itemType, onClick = () => { } }) => (
  <div className={`health-log-empty-item-card add-empty-item-content-section`} onClick={onClick}>
    <img src={`/images/healthAndWellness/foodLog/bg-card-${itemType}.svg`} className="bg-empty-item" />
    <div className={`health-log-empty-item-card-content`}>
      <div className={`inner-empty-item-description`}>
        <img src={`/images/healthAndWellness/foodLog/icons/add-${itemType}.svg`} className="add-empty-item" />
        <h6>{EMPTY_CARD_HEADING[itemType]}</h6>
      </div>
    </div>
  </div>
)

export default HealthLogItemEmptyCard;