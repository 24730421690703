import React, { Component } from 'react';

import { Collapse, Checkbox } from '../../../helpers';

import './ChooseBank.scss';

export class ChooseBank extends Component {
  constructor(props){
    super(props);
    this.state = {
      bankInfo: [
        { name: 'ธนาคารกสิกรไทย',methods: ['แอปพลิเคชั่น K plus','ATM / เคาน์เตอร์ธนาคาร','เว็ปไซต์ธนาคาร'] },
        { name: 'ธนาคารไทยพาณิชย์',methods: ['ATM / เคาน์เตอร์ธนาคาร','เว็ปไซต์ธนาคาร'] },
        { name: 'ธนาคารกรุงเทพ',methods: ['ATM / เคาน์เตอร์ธนาคาร','เว็ปไซต์ธนาคาร'] },
        { name: 'ธนาคารกรุงศรี',methods: ['ATM / เคาน์เตอร์ธนาคาร','เว็ปไซต์ธนาคาร'] },
        { name: 'ธนาคารกรุงไทย',methods: ['ATM / เคาน์เตอร์ธนาคาร','เว็ปไซต์ธนาคาร'] },
        { name: 'ธนาคารทหารไทย',methods: ['ATM / เคาน์เตอร์ธนาคาร','เว็ปไซต์ธนาคาร'] },
        { name: 'ธนาคารยูโอบี',methods: ['ATM / เคาน์เตอร์ธนาคาร','เว็ปไซต์ธนาคาร'] },
      ],
      methodSelected: null,
    };
  }

  handleMethodSelect = methodSelected => {
    this.setState({
      methodSelected,
    });
  }

  handleSubmit = () => {
    const { methodSelected } = this.state;
    console.log('methodSelected',methodSelected);
  }

  renderBanks = () => {
    const { handleMethodSelect } = this;
    const { bankInfo,methodSelected } = this.state;
    return bankInfo.map((bank,idx) => (
      <Collapse key={idx} 
        title={
          <>
            <img src="/images/payment/kbank.png" />
            <span>
              {bank.name}
            </span>
          </>
        }
      >
        {bank.methods.map((method,idx) => (
          <React.Fragment key={idx}>
            <Checkbox 
              checked={methodSelected ? methodSelected.name === bank.name && methodSelected.method === method : false} 
              checkmarkColor="#1EC100" 
              onClick={() => handleMethodSelect({ name: bank.name, method })}>
              {method}
            </Checkbox>
          </React.Fragment>
        ))}
      </Collapse>
    ));
  }
  
  render() {
    const { renderBanks, handleSubmit } = this;
    const { methodSelected } = this.state;
    return (
      <div className="choosebank-container">
        <div className="header">
          <div>เลือกธนาคาร</div>
        </div>
        <div className="body">
          {renderBanks()}
        </div>
        <div className="footer">
          <button 
            disabled={!methodSelected} 
            className={`away-button ${methodSelected && 'active'}`}
            onClick={methodSelected ? handleSubmit : null}
          >ยืนยัน</button>
        </div>
      </div>
    );
  }
}

export default ChooseBank;
