import React from "react";
import "./SquareFloatButton.scss";

const SquareFloatButton = ({ className, children, ...props }) => {
  return (
    <>
      <button className={`square-float-button ${className}`} {...props}>
        <div className="button-content">{children}</div>
      </button>
    </>
  );
}

export default SquareFloatButton;