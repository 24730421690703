import React, { useRef } from 'react';
import './HnwExerciseMenuPage.scss';
import HnwExerciseMenuItem from './HnwExerciseMenuItem';
import { HNW_GOAL_SETTING } from '../../../../../common/commonConstant';
import { Slideup } from '../../../../../common';

export default function HnwExerciseMenuPage({
  openMenuPopup,
  activityState,
  selectedExerciseMenu,
  exerciseMenuList,
  onClickCancel,
  onClickSave,
  onClickExerciseMenu,
}) {
  const ref = useRef(null);
  return (
    <div className="container-fluid">
      <div className="activity-goal-card-container">
        <div className="activity-goal-card-header">
          <div
            className="activity-goal-card-header-left"
            onClick={onClickCancel}
          >
            {HNW_GOAL_SETTING.ACTIVITY.EXERCISE_MENU_CANCEL_TH}
          </div>
          <div className="activity-goal-card-header-right">
            <button
              className={`btn ${
                !!selectedExerciseMenu.length ? 'btn-primary' : 'btn-disabled'
              }`}
              onClick={onClickSave}
            >
              {HNW_GOAL_SETTING.ACTIVITY.EXERCISE_MENU_ADD_TH}
            </button>
          </div>
        </div>
        <div className="activity-goal-card-body" ref={ref}>
          {exerciseMenuList &&
            exerciseMenuList.map((em) => (
              <HnwExerciseMenuItem
                item={em}
                key={em.id}
                activityState={activityState}
                onClickExerciseMenu={onClickExerciseMenu}
                selectedExerciseMenu={selectedExerciseMenu}
              />
            ))}
        </div>
      </div>
      {openMenuPopup && <Slideup ref={ref} />}
    </div>
  );
}
