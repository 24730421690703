// SearchMenu.js
import React, { useState } from 'react';
import './HnwSearchMenu.scss';

const HnWSearchMenu = ({ searchedProducts, searchInput, handleListClick, showAllList }) => {
    const highlightText = (text, query) => {
        if (!query) return text;
        const parts = text.split(new RegExp(`(${query})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase()
                ? <span key={`part${index}`}>{part}</span>
                : part
        );
    };

    return (
        <div className="search-container">
            {
                showAllList &&
                <ul className="search-list">
                    {
                        searchedProducts?.length ?
                            searchedProducts.map((el, index) => {
                                return (
                                    <div key={`searchListContent${index}`} className='list-content'>
                                        <img key={`searchListImg${index}`} src="/images/healthAndWellness/foodCalories/search-history/search.svg" />
                                        <li key={`searchList${index}`} onClick={() => handleListClick(el, true)}>
                                            {highlightText(el.name, searchInput)}
                                        </li>
                                    </div>
                                )
                            }) : null
                    }
                    <li key='showAll' className='show-all' onClick={() => handleListClick(searchInput)}>
                        ดูผลลัพท์ทั้งหมดของ <span>"{searchInput}"</span>
                    </li>
                </ul>
            }
        </div>
    );
};

export default HnWSearchMenu;
