import React from 'react';
import { useLottie } from 'lottie-react';
import LoadingOverlay from 'react-loading-overlay';
import dateChangeAnimation from '../../json/date-change-lady.json';
import './CalendarLoader.scss';

export default function CalendarLoader({ showLoading = false }) {

  const options = {
    loop: true,
    animationData: dateChangeAnimation,
  };

  const { View } = useLottie(options);
  return (
    <div className={`calendar-loader ${showLoading ? '' : ' --hide-loading'}`}>
      <LoadingOverlay
        text={<div className='calendar-loader-content'>{View}</div>}
        active={true}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
    </div>
  )
}
