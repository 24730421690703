import React from 'react';
import "./QuizDetail.scss"

const QuizDetail = ({ quizScore, scoreText, dateText, quizDate, timeImage, timeClass }) => {
    return (<div className="question-card-details-wrapper">
        <div className="quiz-amount-wrapper">
            <img src="/images/challenge/quiz-amount.svg" />
            <div className="quiz-amount-value">
                <span>{quizScore}</span>
                <span> {scoreText}</span>
            </div>
        </div>
        <div className="time-wrapper">
            <img src={timeImage} />
            <div className={`time-value ${timeClass}`}>
                {`${dateText} ${quizDate}`}
            </div>
        </div>
    </div>)
}

export default QuizDetail;