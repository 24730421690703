import React, { useState } from 'react';
import './HnwAddItemCard.scss';

export default function HnwAddItemCard({imgUrl,title, handleAddButtonClick}) {
    return (
        <div className='hnw-add-item-card-container'>
            <div className='hnw-add-item-card-container__details'>
                {imgUrl && (
                    <img src={imgUrl} alt='Add-Item-Card-Image' className='hnw-add-item-card-container__details-img'/>
                )}
                <span className='hnw-add-item-card-container__details-text'>{title}</span>
            </div>
            <button  className='hnw-setter-container__button' onClick={handleAddButtonClick}>
                <img src='/images/healthAndWellness/goals/common/setter-increase.svg' />
            </button>
        </div>
    )
}