import React from 'react';
import './AndroidUploadPicker.scss';
import { ANDROID_UPLOAD_PICKER } from '../../commonConstant';

export default function AndroidUploadPicker({
  onCloseHandler,
  handleAndroidPicker,
}) {
  return (
    <div className="modal-dialog">
      <div className="modal-challenge-content">
        <div className="modal-challenge-header">
          <span className="title">{ANDROID_UPLOAD_PICKER.CHOOSE_ACTION}</span>
          <button onClick={onCloseHandler} className="modal-close-action">
            <img
              src="/images/challenge/myupload/icon_close.svg"
              alt="close-icon"
            />
          </button>
        </div>
        <div className="modal-card-container">
          {ANDROID_UPLOAD_PICKER.ANDROID_CAMERA_ACTIONS.map((action, i) => (
            <div
              className="modal-card-single"
              onClick={() => handleAndroidPicker(action.type)}
              key={`${i}_${action.type}`}
            >
              <img src={action.imageIcon} alt="camera" />
              <div className="modal-card-title">{action.actionThai}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
