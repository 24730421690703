import { SearchOutlined } from '@ant-design/icons';
import { Form, Input, Spin } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { commonConstant } from '../../../common';
import {
  getAboContents,
  getAmwayContent,
} from '../../../services/library/contents/contents.service';
import { libraryImageUrl, thaiDate } from '../../../utill.func';
import { EContentType } from '../common.library';
import './libraryProfile.scss';

const DiscoverNew = ({ type }) => {
  const [contents, setContents] = useState([]);
  const [contentLoding, setContentLoading] = useState();
  const history = useHistory();

  const routeChange = () => {
    history.push({
      pathname: commonConstant.pathLibraryContentList,
      state: {
        type,
      },
    });
  };
  const viewContentDetail = (id) => {
    history.push({
      pathname: commonConstant.pathLibraryViewContent,
      search: type === EContentType.AMWAY_LIBRARY ? `Id=${id}` : `aboId=${id}`,
    });
  };
  const onSearch = async (values) => {
    setContentLoading(true);
    await fetchData(values.search);
    setContentLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (query) => {
    setContentLoading(true);
    let contentData;
    if (type === EContentType.AMWAY_LIBRARY) {
      contentData = await getAmwayContent(query);
    }
    if (type === EContentType.MY_LIBRARY) {
      contentData = await getAboContents(query);
    }
    setContents(contentData);
    setContentLoading(false);
  };

  return (
    <Spin tip="Loading content..." spinning={contentLoding}>
      <div className="library-categories-slide card-library card-discovery-new">
        <div className="card-header">
          {type === EContentType.AMWAY_LIBRARY ? (
            <div className="title">
              <img className="icon" src="../../images/library/icon_new.svg" />
              Discover New
            </div>
          ) : (
            <div className="title">
              <img
                className="icon"
                src="../../images/library/icon_contents.svg"
              />
              My Contents
            </div>
          )}

          <div className="see-all">
            <a
              onClick={() => {
                routeChange();
              }}
            >
              See all &gt;
            </a>
          </div>
        </div>
        <div className="search-input">
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ status: false }}
            onFinish={onSearch}
            autoComplete="off"
          >
            <Form.Item name="search">
              <Input placeholder="ค้นหา" prefix={<SearchOutlined />} />
            </Form.Item>
          </Form>
        </div>
        <div className="card-body">
          {contents &&
            contents.map((item, index) => (
              <div
                className="card-item"
                onClick={() => viewContentDetail(item._id)}
                key={`${item}${index}`}
              >
                <div className="card-item-img">
                  <img src={libraryImageUrl(item.imageContent)} />
                </div>
                <div className="card-desc">
                  <div className="title">{item.name}</div>
                  <div className="desc">
                    {type === EContentType.AMWAY_LIBRARY
                      ? thaiDate(item.createdDate, 'dd-MM-yyyy')
                      : item.aboCategoryId.name}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Spin>
  );
};
export default DiscoverNew;
