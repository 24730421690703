import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import _ from 'lodash'

import { alertActions } from "../../actions";
import { commonConstant } from '../../common';
import { customerService, activityService, questionService } from "../../services";


class Question extends Component {
  constructor(props) {
    super(props);

    if (sessionStorage.getItem("customer") === null || sessionStorage.getItem("customer") === "undefined") {
      return window.location.replace(commonConstant.pathHome);
    }

    const customer = JSON.parse(sessionStorage.getItem("customer"));
    this.state = {
      question: "",
      items: [],
      customer: customer,
      loading: false,
      accept: false,
      error: false,
      radio: null,
      answerGroupSegmentId: null,
    };
  }

  async componentDidMount() {
    let registerStatus = null;
    let listGroupSegmentId = [];

    if (!this.state.customer.name) {
      return window.location.replace(commonConstant.pathHome);
    }
    if (this.state.customer.type !== "public") {
      return this.props.history.push("/success");
    }
    const question = await questionService.get();
    // const question = productMockup;

    if (question.length > 0) {
      this.setState({ question: question[0].question, items: _.orderBy(question[0].items, ["choices"], "asc") });
      question[0].items.map((item) => {
        listGroupSegmentId.push(parseInt(item.groupSegmentId));
      });
    }
    const { customer } = this.props;
    if (customer.answerGroupSegmentId && listGroupSegmentId.indexOf(customer.answerGroupSegmentId) !== -1) {
      this.setState({ answerGroupSegmentId: customer.answerGroupSegmentId });
    }

    registerStatus = await customerService.get(this.state.customer.uid).then((res) => res.registerStatus);
    if (!registerStatus) {
      registerStatus = "incomplete";
    }

    const params = {
      registerStatus: registerStatus,
      action: "SELECT_QUESTION",
    };
    await customerService.update(this.state.customer._id, params);
  }

  handleChoiceClick = async (index) => {
    const answerGroupSegmentId = this.state.items[index].groupSegmentId;
    this.setState({
      loading: true,
    });
    window.scrollTo(0, 0);
    await customerService.update(this.state.customer._id, {
      answerGroupSegmentId,
    });
    activityService.create({
      uid: this.state.customer.uid,
      userId: this.state.customer._id,
      status: "incomplete",
      action: "Q&A",
    });

    return this.props.history.push("/interest");
  }

  handleSubmit = async () => {
    this.setState({
      loading: true,
    });
    window.scrollTo(0, 0);

    if (this.state.answerGroupSegmentId === null && this.state.items.length > 0) {
      this.props.showError("กรุณาเลือกคำตอบ");
      this.setState({ loading: false });

      return false;
    }

    await customerService.update(this.state.customer._id, {
      answerGroupSegmentId: this.state.answerGroupSegmentId,
    });

    activityService.create({
      uid: this.state.customer.uid,
      userId: this.state.customer._id,
      status: "incomplete",
      action: "Q&A",
    });

    return this.props.history.push("/interest");
  }

  /*
  handleCheckBox = _key => {
    // const { items } = this.state;
    const items = this.state.items.map((i, key) => {
      if (key === _key) {
        i.status = true;
      } else {
        i.status = false;
      }
      return i;
    })
    this.setState({ items })
  }
  */

  // handleCheckBox = (value, index) => {
  //   const items = this.state.items.map((i, key) => {
  //     if (key === (index - 1)) {
  //       i.status = true;
  //     } else {
  //       i.status = false;
  //     }
  //     return i;
  //   })
  //   this.setState({
  //     items,
  //     radio: index,
  //     answerGroupSegmentId: this.state.items[index - 1].groupSegmentId,
  //   });

  //   if (this.state.answerGroupSegmentId === parseInt(value) && this.state.radio === null) {
  //     this.state.setState({
  //       radio: value,
  //       answerGroupSegmentId: this.state.items[index - 1].groupSegmentId
  //     })



  //     return true;
  //   }

  //   if (this.state.radio === index) {
  //     return true;
  //   }
  //   return false;
  // }

  // renderAnswer() {
  //   if (this.state.items.length > 0) {
  //     return (
  //       <div className="question-bg">
  //         {this.state.items.map((item, index) => {
  //           if (item.name !== "") {
  //             return (
  //               <div key={index} className="answer">
  //                 <label className="container question-content" style={{ wordWrap: "break-word", width: "85%" }}>
  //                   {/* <input key={index} onChange={this.onClick(index + 1)} name="radio" checked={this.state.radio === index + 1 ? true : false} type="radio" id={`radio${index + 1}`} /> */}
  //                   <input key={index} onChange={this.onClick(index + 1)} name="radio" checked={this.handleCheckBox(item.groupSegmentId, index + 1)} type="radio" id={`radio${index + 1}`} />
  //                   <span className="checkmark check-ans">
  //                     <img src="images/choose.png" alt="" />
  //                   </span>
  //                   {item.name}
  //                 </label>
  //               </div>
  //             );
  //           }
  //           return item;
  //         })}
  //       </div>
  //     );
  //   }
  //   return <h1 style={{ marginBottom: 0 }}>{`ไม่มีคำถาม กรุณากด "ขั้นตอนต่อไป"`}</h1>;
  // }

  render() {
    const { question, items } = this.state;
    const isTrue = items.filter(_ => _.status).length ? true : false;
    return (
      <div className="regisPage no-bg _pt-35">
        <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: "100%", minHeight: "100%" } }} />
        {/* <img src="/images/logoAmway-black.svg" alt="" /> */}
        {/* <h2 className="no-color">การสั่งซื้อสินค้าแอมเวย์<br /> ที่ผ่านมาของคุณ</h2> */}
        <p className="question">{question}</p>

        <div className="questions">
          {
            items ? items.map((item, key) => (
              <div key={key} onClick={() => this.handleChoiceClick(key)} className="item">
                <div>{item.name}</div>
              </div>
            )) : 'ไม่มีคำถาม กรุณากด "ขั้นตอนต่อไป"'
          }
        </div>

        {/* <button className="redBtn" onClick={this.handleSubmit}>
          ขั้นตอนต่อไป
        </button> */}
        {
          items ? null
            : <div className="mt-32"><button className={`register-next-button ${isTrue ? 'active' : ''}`} type="submit" onClick={this.handleSubmit}>ถัดไป</button></div>
        }

      </div>
    );
  }
}

const mapStateToProps = ({ customer }) => ({ customer });

function mapDispatchToProps(dispatch) {
  return {
    showError: (message) => dispatch(alertActions.showError(message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Question);
