import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { uploadFoodImage } from '../../../../../actions';
import { commonConstant } from '../../../../../common';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import './AccessCamera.scss';
import { Camera } from '../../../../../common/challenge/commonAndroidWrapper/camera/Camera';
import { ANDROID_CAMERA_PAGE_CANCEL_BTN } from '../../../../../common/commonConstant';

export default function AccessCamera() {
  const cameraRef = useRef(null);
  const uploadRef = useRef(null);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [flash, setFlash] = useState(false);

  async function onModalOk(file) {
    setLoading(true);
    if (file) {
      const formDataImage = new FormData();
      formDataImage.append('imageFile', file, "file.jpeg");
      const imageResponse = await dispatch(uploadFoodImage(formDataImage));
      const imagePath = imageResponse?.data?.data || '';
      setLoading(false);
      window.location.href = `https://${window.location.hostname}${commonConstant.pathAddMenu}?imagePath=${imagePath}`;
    }
  }

  const handleCancelBtn = async () => {
    window.location.href = `https://${window.location.hostname}${commonConstant.pathAddMenu}?imagePath=cancelled`;
  };

  return (
    <div className="custom-android-camera">
      {loading ? (
        <LoadingOverlay
          text="Loading"
          active={loading}
          spinner={true}
        />
      ) : null}
      <Camera
        ref={cameraRef}
        setImage={(blob) => uploadRef.current.upload.uploader.props.beforeUpload(blob)}
        aspectRatio={3 / 5.5}
        facingMode="environment"
      />
      <div className="custom-android-camera-container-flash">
        <img
          src={`/images/challenge/myupload/${flash ? 'flashon' : 'flashoff'
            }.svg`}
          alt="flash.svg"
          onClick={() => {
            const flashState = cameraRef.current.toggleTorch();
            setFlash(flashState);
          }}
        />
      </div>
      <div className="custom-android-camera-container-actions">
        <span
          className="custom-android-camera-container-actions-cancel"
          onClick={handleCancelBtn}
        >
          {ANDROID_CAMERA_PAGE_CANCEL_BTN}
        </span>
        <img
          src="/images/challenge/myupload/camera-click.svg"
          alt="camera-click.svg"
          onClick={() => cameraRef.current.takePhoto('toBlob')}
        />
        <img
          src="/images/challenge/myupload/change-camera.svg"
          alt="change-camera.svg"
          onClick={() => cameraRef.current.switchCamera()}
        />
      </div>
      <ImgCrop onModalOk={onModalOk} quality={1}>
        <Upload listType="picture-card" ref={uploadRef} />
      </ImgCrop>
    </ div>
  );
}
