import AxiosHealthWellness from '.';
import config from '../../config';
import { ExerciseLog, ExerciseLogMenu } from '../../model/ExerciseLog.model';
import {
  ExerciseMenu,
  ExerciseCategory,
  ActivityExerciseCategoryGoal,
} from '../../model/GoalSetting.model';

export const getExerciseLogDetails = async (params) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/someurl`,
      { params },
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const postExerciseLog = async (requestPayload) => {
  try {
    const response = await AxiosHealthWellness.post(
      `${config.API_URL}/health-log/excercise-log`,
      requestPayload,
    );
    return new ExerciseLog(response?.data);
  } catch (error) {
    throw error;
  }
};

export const putExerciseLog = async (exerciseLogId, requestPayload) => {
  try {
    const response = await AxiosHealthWellness.put(
      `${config.API_URL}/health-log/excercise-log/${exerciseLogId}`,
      requestPayload,
    );
    return new ExerciseLog(response?.data?.data);
  } catch (error) {
    throw error;
  }
};

export const getUserActivityGoal = async (customerId) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-goal/user-activity-goal/${customerId}`,
    );
    return new ActivityExerciseCategoryGoal(response?.data?.data);
  } catch (error) {
    throw error;
  }
};

export const getExerciseLogById = async (exerciseLogId) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-log/excercise-data/${exerciseLogId}`,
    );
    return new ExerciseLog(response?.data?.data);
  } catch (error) {
    throw error;
  }
};

export const getExerciseMenuAndTemplatesById = async (exerciseMenuId) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-goal/exercise-menu/${exerciseMenuId}`,
    );
    return new ExerciseLogMenu({
      ...response?.data?.exerciseMenuData,
      exerciseTemplates:
        response?.data?.exerciseTemplateData?.exerciseTemplates,
      exerciseTemplateBannerImage: response?.data?.exerciseTemplateData?.bannerImage
    });
  } catch (error) {
    throw error;
  }
};

export const getExerciseMenuListById = async (exerciseCatId, params) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-goal/exercise-menu-list/${exerciseCatId}`,{params}
    );
    return response?.data?.data?.map((item) => new ExerciseMenu(item));
  } catch (error) {
    throw error;
  }
};

export const getExerciseTimeLogs = async (params) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-log/excercise-data`,
      { params },
    );
    const sortedData = response?.data?.data.sort((a, b) => {
      return new Date(b.updatedDate) - new Date(a.updatedDate);
    });
    return sortedData.map((logItem) => new ExerciseLog(logItem));
  } catch (error) {
    throw error;
  }
};

export const getPrevExerciseLogById = async (excerciseCatId, type) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/prev-excercise-data?excerciseCatId=${excerciseCatId}&type=${type}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteExerciseLogById = async (exerciseLogId) => {
  try {
    const response = await AxiosHealthWellness.delete(
      `${config.API_URL}/health-log/excercise-log/${exerciseLogId}`,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getExerciseCategories = async () => {
  const response = await AxiosHealthWellness.get(
    `${config.API_URL}/health-goal/excercise-category`,
  );
  if (response && response?.data?.data) {
    return response?.data?.data
      ?.sort((a, b) => a.order - b.order)
      ?.map((el) => new ExerciseCategory(el));
  }
  return [];
};

export const getExerciseSuggestedMenuList = async (params) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-goal/suggested-menu-list`,{params}
    );
    return response?.data?.data?.map((item) => new ExerciseMenu(item));
  } catch (error) {
    throw error;
  }
};

export const getActivityGoal = async (customerId) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/health-goal/user-activity-goal/${customerId}`,
    );
    return new ActivityExerciseCategoryGoal(response?.data?.data);
  } catch (error) {
    console.error(error);
  }
};
