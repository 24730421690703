import { FOOD_TABS_MAPPING } from "../common/commonConstant";
import config from "../config";

export class FoodDetail {
  constructor(data) {
    if (data) {
      this.name = data.name || '';
      this.foodImage = data.image || '';
      this.foodImageUrl = this.foodImage ? `${config.S3_URL}${this.foodImage}` : '';
      this.tagImage = data?.category?.tagImage ? `${config.S3_URL}${data?.category?.tagImage}` : '';
      this.quantity = data.quantity || '';
      this.unit = data.unit || '';
      this.weight = data.weight || '';
      this.calorie = data.calorie || '';
      this.carbs = data.carbs || '';
      this.protein = data.protein || '';
      this.fat = data.fat || '';
      this.type = data.type || '';
      this.productCategory = data.productCategory || '';
      this.isFood = this.type ? this.type.toLowerCase() === FOOD_TABS_MAPPING.Food.name.toLowerCase() : this.productCategory ? true : false;
    }
  }
}