import React, { useEffect, useState } from 'react';
import './ProfileCard.scss';
import {
  HOME_PAGE,
} from '../../../../common/homePageConstants';
import { getDataFromSession } from '../../../../utill.func';
import { commonConstant } from '../../../../common';
import { useHistory } from 'react-router-dom';

const ProfileCard = ({ customer }) => {
  const history = useHistory();
  const {pictureUrl, displayName} = getDataFromSession('lineProfile');
  const redirectToProfileScreen = () => {
    history.push(commonConstant.pathProfile);
  };
  return (
    <>
        <div className="home-profile-container">
          <div class="food-profile-left">
            <div class="food-profile-img-container"  onClick={redirectToProfileScreen} >
              <img src={pictureUrl} alt="line-profile-img" />
            </div>
          </div>
          <div className="home-profile-details">
            <div className="food-profile-title">
              {HOME_PAGE.HOME_GREETING_MSG}
            </div>
            <div className="food-profile-subtitle">{displayName}</div>
          </div>
        </div>
    </>
  );
};

export default ProfileCard;
