import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import '../../sop/Subscription/SOPSubscription.scss';
import PLPItem from '../SOPTemplate/PLPItem';
import CartIcon from '../../promotion/CartIcon';
import { getSOPList } from '../../../services/sop-sub.service';
import { commonConstant } from '../../../common';
import { getCart } from '../../../services/promotion/utils.service';

export class PLP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true
    };
  }

  async componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const sopList = await getSOPList();
    this.setState({ data: sopList, loading: false });
  }

  redirectToDetail(id){
    return (window.location.href =`${commonConstant.pathPDP}?id=${id}`)
  }

  renderPLP() {
    const sopList = this.state.data
    if(sopList.data?.lenght!==0){
    return sopList.data?.map((product) => {
      const {
        id,
        sku,
        identifier,
        galleryImages,
        prices,
        amwayValue,
        sopPlans,
      } = product;
      const img = galleryImages.length > 0 ? galleryImages[0].image_url : '';
      let aboPrice = prices.find((value) => value.type === 'ABO');
      aboPrice = parseInt(aboPrice.price);
      return (
        <div className='col-6 no-padding-col' onClick={()=>this.redirectToDetail(id)}>
          <PLPItem
            img={img}
            sku={sku}
            identifier={identifier}
            price={aboPrice}
            amwayValue={amwayValue}
            sopPlans={sopPlans}
          />
        </div>
      );
    });
  }
  }

  render() {
    return (
      <section>
        <LoadingOverlay
          text="Loading"
          spinner
          active={this.state.loading}
          styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
        />
        <div className="sop-header">
          <h2 className='new-h2'>SOP Flex</h2>
          <div className='subheading'>คุ้มค่ามากกว่า ด้วยการสั่งซื้อสินค้าแบบต่อเนื่อง</div>
        </div>
        <div className='cart-icon'>
            <CartIcon/>
        </div>
        <div className='sop-header-image'>
          <img
            src="/images/sop/subscription/header_plp_new.png"
            className="w-100"
          />
        </div>
        <div className='row no-margin-row sop-plp-container'>{this.renderPLP()}</div>
      </section>
    );
  }
}

export default PLP;
