import React from 'react';
import "./NutritionBarSingle.scss"
import { ChallengeProgressBar } from '../../../../../common';
import { round } from '../../../../../services';
import { getOthersBadgesRange } from '../../../../../utill.func';

const NutritionBarSingle = ({ value, label, max, nutritionUnit = 'ก.' }) => {
    return (
        <div class="home-nutrition-bar-single">
            <div class="home-nutrition-bar-header">
                <div class="home-nutrition-bar-name">{label}</div>
                <ChallengeProgressBar percentage={getOthersBadgesRange(value, max)} />
            </div>
            <div class="home-nutrition-bar-value-group">
                <div class="home-nutrition-bar-value-used">{value}</div>
                <div class="home-nutrition-bar-value-total">/{max}</div>
                <div class="home-nutrition-bar-value-unit">{nutritionUnit}</div>
            </div>
        </div>
    )
}

export default NutritionBarSingle;