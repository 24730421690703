import { getFoodCalendarData, getExerciseCalendarData } from '../services/healthWellness/hnwCalendar.service';
import { GET_FOOD_CALENDAR_DATA, GET_EXERCISE_CALENDAR_DATA } from './types';

export const fetchFoodCalendarData = (params) => (dispatch) => getFoodCalendarData(params)
  .then((rest) => {
    dispatch({
      type: GET_FOOD_CALENDAR_DATA,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const fetchExerciseCalendarData = (params) => (dispatch) => getExerciseCalendarData(params)
  .then((rest) => {
    dispatch({
      type: GET_EXERCISE_CALENDAR_DATA,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });
