import React, { useState, useEffect } from 'react';
import { CalendarLoader, HealthLogCard, NoResultFound } from '../../../../../common';
import './HealthLogList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FOOD_BAR_NAME, HNW_EXERCISE_LOG, NO_RESULT_FOUND, WATER_BAR_NAME, logListMapping } from '../../../../../common/commonConstant';
import { useLocation } from 'react-router-dom';
import { getLogList } from '../../../../../actions/foodLogActions';
import { extractFoodWaterLogData } from '../utility';
import { getDatetimeStamp } from '../../../../../utill.func';

export default function HealthLogList() {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const logList = useSelector(state => state.logList.logList);
    const type = params.get('type');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { headerIcon, headerText } = logListMapping[type];
    const emptyImg = NO_RESULT_FOUND.food_no_result;

    const foodData = extractFoodWaterLogData(logList, FOOD_BAR_NAME);
    const waterData = extractFoodWaterLogData(logList, WATER_BAR_NAME);

    const logData = type === FOOD_BAR_NAME ? foodData : waterData;
    useEffect(() => {
        const didMount = async () => {
            await getLogListData();
        };
        didMount();
    }, []);

    const getLogListData = async () => {
        try {
            setLoading(true);
            dispatch(getLogList({
                type,
                startDate: getDatetimeStamp(startDate),
                endDate: getDatetimeStamp(endDate),
            })).then(res => {
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                console.log(error);
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <>
            <CalendarLoader showLoading={loading} />
            <div className="log-list-container">
                <div className='log-list-header'>
                    <img className='log-list-header-img' src={headerIcon} />
                    <div className='log-list-header-text'>{headerText}</div>
                </div>
                <div className='log-list-card-container'>
                    {logData?.length ? <div>
                        {logData?.map((item) =>
                            <div className='log-list-card'>
                                <HealthLogCard item={item} />
                            </div>
                        )}</div>
                        : <NoResultFound
                            emptyImg={emptyImg} header={NO_RESULT_FOUND.header}
                            description={NO_RESULT_FOUND.description} />}
                </div>
            </div>
        </>
    );
}