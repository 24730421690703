import React, { useCallback, useEffect, useState } from 'react';
import { HnwLogStepper, HnWNumpadModal, Numpad } from '../index';
import {
  GOAL_SETTER_CARD_DEFAULT,
  H_W_VALIDATION,
} from '../../common/commonConstant';
import { formatNumberWithCommas, getNumberFromString } from '../../utill.func';
import './HnWSetterCard.scss';

export default function HnWSetterCard({
  id = '',
  name = '',
  headerText,
  headerImage,
  footerTextArr = [],
  borderColor,
  showResetBtn = true,
  resetEnabled,
  footerEnabled,
  defaultValue = 0,
  value,
  isDecimalAllowed = false,
  isListCard,
  maxValue = GOAL_SETTER_CARD_DEFAULT.CARD_MAXIMUM_VALUE,
  minValue = GOAL_SETTER_CARD_DEFAULT.CARD_MINIMUM_VALUE,
  allowOutOfRange = true,
  unit = GOAL_SETTER_CARD_DEFAULT.CARD_UNIT,
  onValueChange,
  stepperVal = GOAL_SETTER_CARD_DEFAULT.CARD_STEPPER_VALUE,
  quickAccessActionMultiplier = 0,
  enableQuickAccessAction = false,
  quickAccessActionArr = [],
  logStepperValueSuffix = '',
}) {
  const resetBtnImg = `/images/healthAndWellness/goals/common/setter-reset-button-${
    resetEnabled ? 'enabled' : 'disabled'
    }.svg`;
  const [isDecrementBtnDisabled, setIsDecrementBtnDisabled] = useState(false);
  const [isIncrementBtnDisabled, setIsIncrementBtnDisabled] = useState(false);
  const [isValueOutOfRange, setIsValueOutOfRange] = useState(false);
  const [openNumpad, setOpenNumpad] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const containerClass = isListCard
    ? 'hnw-setter-container hnw-setter-container__listCard'
    : 'hnw-setter-container';
  const inputValueBorderClass = borderColor
    ? `hnw-setter-container__wrapper__values-border-${borderColor}`
    : `hnw-setter-container__wrapper__values-border-green`;
  const inValidInputClass = isValueOutOfRange
    ? 'hnw-setter-container__wrapper__values-inValid'
    : '';
  const valueNumber = getNumberFromString(value);
  const defaultValueNumber = getNumberFromString(defaultValue);
  const minValueNumber = parseInt(minValue);
  const maxValueNumber = parseInt(maxValue);

  const toggleNumpad = (type) => {
    setOpenNumpad(type ? true : false);
    !type && setIsDirty(false);
  };

  const handleDelete = () => {
    if (value) {
      const newValue = value.toString().slice(0, -1);
      setIsValueOutOfRange(newValue === '');
      onValueChange(newValue, name, id);
    }
  };

  const handleOKClick = () => {
    toggleNumpad(false);
    if (!allowOutOfRange) {
      valueNumber =
        valueNumber <= minValueNumber
          ? minValueNumber
          : valueNumber >= maxValueNumber
            ? maxValueNumber
            : valueNumber;
    }
    setIsValueOutOfRange(
      valueNumber < minValueNumber ||
      valueNumber > maxValueNumber ||
      valueNumber === 0,
    );
    setIsDecrementBtnDisabled(valueNumber <= minValueNumber);
    setIsIncrementBtnDisabled(valueNumber >= maxValueNumber);
    onValueChange(valueNumber.toString(), name, id);
  };

  const handleInputChange = (enteredVal) => {
    if (enteredVal && value.toString().length < 10) {
      let newValue = value + enteredVal;
      const validationPattern = isDecimalAllowed
        ? H_W_VALIDATION.setterWithDecimal.pattern
        : H_W_VALIDATION.setterDefault.pattern;
      const isValid = validationPattern.test(newValue);
      if (isValid) {
        setIsValueOutOfRange(false);
        setIsDirty(true);
        onValueChange(isDirty ? newValue : enteredVal, name, id);
      }
    }
  };

  const renderNumpad = () => (
    <Numpad
      handleInputChange={handleInputChange}
      handleDelete={handleDelete}
      handleOKClick={handleOKClick}
    />
  );

  const handleReset = () => {
    setIsIncrementBtnDisabled(defaultValueNumber >= maxValueNumber);
    setIsDecrementBtnDisabled(defaultValueNumber <= minValueNumber);
    setIsValueOutOfRange(false);
    onValueChange(defaultValue, name, id);
  };

  const handleStepperBtnClick = (type) => {
    const step = parseInt(stepperVal);
    let updatedValue;
    if (type === GOAL_SETTER_CARD_DEFAULT.CARD_STEPPER_TYPE_INCREASE) {
      updatedValue = Math.min(valueNumber + step, maxValueNumber);
    } else {
      updatedValue = Math.max(valueNumber - step, minValueNumber);
    }
    setIsIncrementBtnDisabled(updatedValue >= maxValueNumber);
    setIsDecrementBtnDisabled(updatedValue <= minValueNumber);
    setIsValueOutOfRange(
      updatedValue < minValueNumber || updatedValue > maxValueNumber,
    );
    onValueChange(updatedValue.toString(), name, id);
  };

  const handleQuickAccessClick = (value) => {
    setIsIncrementBtnDisabled(valueNumber >= maxValueNumber);
    setIsDecrementBtnDisabled(valueNumber <= minValueNumber);
    onValueChange(parseInt(value) * quickAccessActionMultiplier, name, id);
  };

  const displayValue =
    value == null || value === ''
      ? formatNumberWithCommas(defaultValue)
      : formatNumberWithCommas(value);

  useEffect(() => {
    const compareValue =
      (isNaN(valueNumber) ? undefined : valueNumber) ?? defaultValueNumber;
    setIsIncrementBtnDisabled(compareValue >= maxValueNumber);
    setIsDecrementBtnDisabled(compareValue <= minValueNumber);
    setIsValueOutOfRange(
      compareValue < minValueNumber || compareValue > maxValueNumber,
    );
  }, [valueNumber]);

  return (
    <div className={containerClass}>
      <div className="hnw-setter-container__wrapper">
        <div className="hnw-setter-container__wrapper__header">
          {headerImage && (
            <img
              className="hnw-setter-container__image"
              src={headerImage}
              alt="header-image"
            />
          )}
          <div
            className={`hnw-setter-container__text ${
              headerImage
                ? 'hnw-setter-container__text-small'
                : 'hnw-setter-container__text-large'
              }`}
          >
            {headerText}
          </div>
        </div>
        {showResetBtn && (
          <button
            disabled={!resetEnabled}
            className="hnw-setter-container__button"
            onClick={handleReset}
          >
            <img src={resetBtnImg} />
          </button>
        )}
      </div>
      <div className="hnw-setter-container__wrapper hnw-setter-container__wrapper__values">
        <button
          disabled={isDecrementBtnDisabled}
          onClick={() =>
            handleStepperBtnClick(
              GOAL_SETTER_CARD_DEFAULT.CARD_STEPPER_TYPE_DECREASE,
            )
          }
          className="hnw-setter-container__button"
        >
          <img src="/images/healthAndWellness/goals/common/setter-decrease.svg" />
        </button>
        <div
          onClick={toggleNumpad}
          className={`hnw-setter-container__wrapper__values-input ${inputValueBorderClass} ${inValidInputClass}`}
        >
          {openNumpad && !isDirty ? '' : displayValue}
        </div>
        <button
          disabled={isIncrementBtnDisabled}
          onClick={() =>
            handleStepperBtnClick(
              GOAL_SETTER_CARD_DEFAULT.CARD_STEPPER_TYPE_INCREASE,
            )
          }
          className="hnw-setter-container__button"
        >
          <img src="/images/healthAndWellness/goals/common/setter-increase.svg" />
        </button>
      </div>
      <div className="hnw-setter-container__wrapper hnw-setter-container__wrapper__unit">
        {unit}
      </div>
      {enableQuickAccessAction && quickAccessActionArr.length ? (
        <div className="hnw-setter-container__wrapper hnw-setter-container__wrapper__quick-access-action">
          <HnwLogStepper
            dataArray={quickAccessActionArr}
            onClick={handleQuickAccessClick}
            quickAccessActionValue={`${value / quickAccessActionMultiplier}`}
            valueSuffix={logStepperValueSuffix}
            disableOnMount
          />
        </div>
      ) : null}
      {footerEnabled && (
        <div className="hnw-setter-container__wrapper hnw-setter-container__wrapper__footer">
          <img src="/images/healthAndWellness/goals/common/caution_icon.svg" />
          <div>
            {footerTextArr.map((text) => {
              return <p key={text}>{text}</p>;
            })}
          </div>
        </div>
      )}
      <div className="hnw-setter-container__numpad">
        <HnWNumpadModal
          onClose={() => toggleNumpad(false)}
          openNumpad={openNumpad}
          onBackgroundClick={handleOKClick}
        >
          {renderNumpad()}
        </HnWNumpadModal>
      </div>
    </div>
  );
}
