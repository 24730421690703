import React from "react";
import { percentCalculation } from "../../utill.func";
import './HealthHomeCircleChart.scss';

const HealthHomeCircleChart = ({ stopColorStart, stopColorEnd, bgIcon, usedQty, totalQty, unit, gradientId, gradientDegree }) => {

    const percentageCalculation = () => {
        if (usedQty === 0 || !usedQty) {
            return 0;
        }
        if (percentCalculation(usedQty, totalQty) <= 100) {
            return percentCalculation(usedQty, totalQty);
        }
        else {
            return 100;
        }
    }

    const renderCalculatedCircle = () => {
        return (
            <svg>
                <defs>
                    <linearGradient id={gradientId} gradientTransform={`rotate(${gradientDegree})`}>
                        <stop offset="0%" stopColor={stopColorStart}></stop>
                        <stop offset="100%" stopColor={stopColorEnd}></stop>
                    </linearGradient>
                </defs>
                <circle cx="69" cy="69" r="62"></circle>
                <circle cx="69" cy="69" r="62"
                    style={{ "--percent": percentageCalculation(), stroke: `url(#${gradientId})` }}></circle>
            </svg>
        )
    }

    return (
        <div className="circle-home-card">
            <div className="percent">
                {renderCalculatedCircle()}
                <div className="number">
                    <div className="progress-value">
                        <img src={`/images/healthAndWellness/foodLog/icons/${bgIcon}.svg`} />
                        <div className={`${+usedQty <= +totalQty ? "progress-value-inner color-black"
                            : "progress-value-inner color-max"}`}>
                            {usedQty > 0 ? (Math.round(+usedQty).toLocaleString()) : "-"}
                        </div>
                        <div className="progress-value-unit-container">
                            <div className="health-log-food-card-nutrition-single-info-title">
                            </div>
                            <div
                                className="health-log-food-card-nutrition-single-info-separtor">
                                /
                            </div>
                            <div className="health-log-food-card-nutrition-single-info-goal">
                                {totalQty?.toLocaleString() || "-"}
                            </div>
                            <div className="health-log-food-card-nutrition-single-info-unit">
                                {unit}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthHomeCircleChart;