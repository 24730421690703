import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { addCouponToCart } from '../../services/promotion/utils.service';

const InputCoupon = (props) => {
  const [loading, setLoading] = useState(false);

  const [couponCode, setCouponCode] = useState('');

  useEffect(() => {
    setCouponCode('');
  }, [props]);

  const onCodeChange = (e) => {
    e.preventDefault();
    const couponCode = e.target.value;
    setCouponCode(couponCode);
  };

  const onSubmitCode = async () => {
    if (!couponCode) {
      return alert('โปรดระบุรหัสโค้ดส่วนลด');
    }

    try {
      setLoading(true);
      const couponList = [couponCode];
      const response = await addCouponToCart(couponList, props.cartId);
      if (!response.success) {
        const errorMessage = response.error[0].th_message;
        alert(errorMessage);
        setLoading(false);
        setCouponCode('');
        return;
      }
      setLoading(false);
      setCouponCode('');
      props.callback();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      <div className="flex-between coupon-code">
        <input
          onChange={onCodeChange}
          value={couponCode}
          className="input-coupon-code"
          placeholder="พิมพ์รหัสโค้ดส่วนลด"
        ></input>
        <button className={`btn-coupon-code ${couponCode ? 'coupon-code-active' : 'coupon-code-disable'}`} onClick={onSubmitCode}>
          ใช้ส่วนลด
        </button>
      </div>
    </section>
  );
};

export default InputCoupon;
