import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import "./pdpaasesment.scoped.scss";
import Swal from "sweetalert2";
import Spinner from '../components/Spinner'
import axios from "axios";
import Slider from "react-slick";
import { Avatar } from 'antd';
import { BiArrowBack } from "react-icons/bi";


const Pdpaasesment = (props) => {
    let [loading2, setLoading2] = useState(false);
    const history = useHistory();
    let User = localStorage.getItem("User");
    const cover_user = User ? JSON.parse(User) : '';
    const [currentSlide, setCurrentSlide] = useState(0);
    const [listAssesment, setlistAssesment] = useState();

    useEffect(() => {
        getStore();
    }, []);


    const getStore = () => {
        setLoading2(true)
        const liffProfile = localStorage.getItem("Profile");
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL_UAT}/pdpa`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${cover_user.token}`
            },
        }).then((res) => {
            setLoading2(false)
            console.log(res.data.data);
            setlistAssesment(res.data.data)
        }).catch((err) => {
            setLoading2(false)
        });
    };
    const settings = {
        dots: true,
        nav: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: dots => (
            <div>
                <ul
                    style={{
                        position: "absolute",
                        bottom: "-10px",
                        width: "100%",
                        padding: "0",
                        margin: "0",
                        listStyle: "none",
                        textAlign: "center"
                    }}
                >
                    {dots.map((dot, index) => (
                        <li
                            key={index}
                            style={{
                                display: "inline-block",
                                margin: "0 4px",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                backgroundColor: dot.props.className === "slick-active" ? "#83c346" : "gray"
                            }}
                        />
                    ))}
                </ul>
            </div>
        ),


    };

    const home = () => {

        if (cover_user.type == "member") {
            history.push("/assesment");
        } else if (cover_user.type == "abuddy") {
            let roomidsestion = localStorage.getItem("roomid");
            const normalizedRoomid = roomidsestion.replace(/\"/g, "");
            history.push("/assesment?roomId=" + normalizedRoomid);

        }

    }
    const avatarStyle = {
        marginRight: '8px', // Adjust the spacing as desired
        fontSize: '24px', // Increase the font size for a larger Avatar
    };
    return (
        <>
            <div className="page overflow_disable" style={{
                minHeight: '126vh',
                overflowX: 'hidden'
            }}>
                <header style={{ textAlign: 'center' }}>
                    <div style={{ position: 'relative' }}>

                        <img src="/images/assesment/BGM.png" alt="รูปภาพ" style={{ maxWidth: '100%', maxHeight: '200px', width: '70vh', position: 'relative' }} />
                    </div>

                </header>
                <div>

                    <div>
                        <div>


                            <div>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div
                                        className="card"
                                        style={{
                                            width: '90%',
                                            borderRadius: '20px',
                                            padding: '30px',
                                            zIndex: '1',
                                            position: 'relative',
                                            marginTop: '-15vh',
                                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                                        }}
                                    >

                                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                                            <div style={{ marginTop: '10px', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px', textAlign: 'center' }}>

                                                <span> {listAssesment?.title}<br></br></span>

                                            </div>
                                            <span style={{ marginTop: '20px', color: '#2C2C2C', fontSize: '14px', fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                                {listAssesment?.content}
                                            </span>
                                        </div>

                                    </div>
                                </div>

                            </div>



                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '7vh' }}>

                                <button
                                    style={{
                                        borderRadius: '35px',
                                        backgroundColor: 'rgb(15, 119, 74)',
                                        width: '40vh',
                                        fontFamily: 'Sukhumvit Set tadmai bold',
                                        borderColor: 'rgb(15, 119, 74)',
                                        fontSize: '2vh',
                                        height: '7vh'
                                    }}
                                    type="button"
                                    className="btn btn-primary btn-lg btn-block button"
                                    onClick={() => {
                                        if (cover_user.type == "member") {
                                            localStorage.setItem(
                                                "pdpa", true
                                            );
                                            history.push("/assesment");
                                        } else if (cover_user.type == "abuddy") {
                                            localStorage.setItem(
                                                "pdpa", true
                                            );
                                            let roomidsestion = localStorage.getItem("roomid");
                                            const normalizedRoomid = roomidsestion.replace(/\"/g, "");
                                            history.push("/assesment?roomId=" + normalizedRoomid);

                                        }
                                    }}
                                >
                                    Accept
                                </button>

                            </div>

                        </div>
                    </div>
                </div >
                {loading2 ? <Spinner /> : null}
            </div>
        </>
    );
};

export default Pdpaasesment;