import React from "react";
import "./ChallengeVideoCardLoader.scss";

const ChallengeVideoCardLoader = () => {
  return (
    <div className="shimmer-quiz-card-single">
      <div className="shimmer-quiz-img-container">
        <div className="shimmer youtube-cover-img"></div>
      </div>
      <div className="shimmer-quiz-card-description">
        <div className="shimmer quiz-card-title"></div>
        <div className="shimmer quiz-card-time"></div>
      </div>
    </div>
  );
};

export default ChallengeVideoCardLoader;
