import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { createBrowserHistory } from 'history';
import './otpFOA.scss';
import queryString from 'query-string';
import {
  orderFOA,
  prepareFOAcart,
  registerFOA,
  verifiedOtp,
  generateOTPregis,
} from '../../../services/promotion/foa.service';
import { OTPInput } from '../../../helpers';
import { commonConstant } from '../../../common';

export const history = createBrowserHistory();
const { liff } = window;

export const FOAOtp = () => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [token, setToken] = useState('');
  const [cartId, setCartId] = useState('');

  const otpHandler = async (otp) => {
    setLoading(true);
    const otpData = await verifiedOtp(token, cartId, otp);
    if (otpData.success) {
      const tokenOtp = otpData.data.access_token;
      const cartFoaData = sessionStorage.getItem('cartFoaData');
      const cartId = JSON.parse(localStorage.getItem('pifCartId'));
      const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
      const guid = sessionStorage.getItem('guid');
      const cart = await prepareFOAcart(
        tokenOtp,
        customerFOA.uid,
        JSON.parse(cartFoaData),
        guid,
      );
      if (cart?.success) {
        const customer = await registerFOA(
          tokenOtp,
          JSON.parse(cartFoaData),
          cart,
          guid,
          customerFOA.uid,
        );
        if (customer?.success) {
          sessionStorage.setItem('customerFOAData', JSON.stringify(customer));
          sessionStorage.setItem('cartData', JSON.stringify(cart));
          liff.closeWindow();
        } else {
          alert('เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่');
        }
      } else {
        alert('เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่');
      }
    } else {
      alert(otpData.error[0]['th_message']);
      if (
        otpData.error[0]['th_message'] ===
        'รหัส OTP ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง'
      ) {
        window.location.reload();
      }
    }
    setLoading(false);
  };

  const handleFetchOtp = async () => {
    setLoading(true);
    const otpRegisData = await generateOTPregis(token, phone, cartId);
    alert('ระบบได้ส่งรหัส OTP ไปยังเบอร์โทรศัพท์มือถือของท่านแล้ว');
    setLoading(false);
  };

  useEffect(() => {
    const urlParams = queryString.parse(history.location.search);
    if (urlParams !== {}) {
      setPhone(urlParams.phoneNumber);
      setToken(urlParams.token);
      setCartId(urlParams.cartId);
    }
  }, []);

  return (
    <section>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%', value: '' } }}
      />
      <div className="regisPage">
        <div className="body">
          <h2 className="top-100 mb-90">กรอกรหัส OTP เพื่อยืนยัน</h2>
          <div className="title">
            ระบบได้ส่งรหัส OTP ไปยังเบอร์โทรศัพท์มือถือ
          </div>
          <div className="mb-10">{phone}</div>
          <div className="otp-box" style={{ textAlign: 'center !important' }}>
            <OTPInput
              id="cid"
              count={4}
              onChange={(value, obj) => {
                if (obj.isFilled) {
                  otpHandler(value);
                }
              }}
              resendBtn={true}
              resendFn={handleFetchOtp}
              delay={true}
              resendDelay={60}
            />
          </div>
        </div>
        <div className="otp-footer">
          <a className="link" onClick={() => window.history.back()}>
            ย้อนกลับ
          </a>
        </div>
      </div>
    </section>
  );
};
