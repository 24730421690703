import React, { useEffect, useState } from 'react';
import '../promotion/CartPromotion.scss';

export const LIFF_OPEN = 'open';

const LiffPromotionList = (props) => {
  const [isLiffOpen, setIsLiffOpen] = useState('');
  const [bgColor, setBgColor] = useState('');
  const [disableClose, setDisableClose] = useState('');

  useEffect(() => {
    const isLiffOpen = props.isOpen ? LIFF_OPEN : '';
    setIsLiffOpen(isLiffOpen);
  }, [props.isOpen]);

  useEffect(() => {
    setBgColor(props.bgColor);
  }, [props.bgColor]);

  useEffect(() => {
    setDisableClose(props.disableClose);
  }, [props.disableClose]);

  const onClose = () => {
    props.onClose();
  };

  return (
    <section className={`liff-prm-panel ${isLiffOpen}`}>
      <div
        className="select-prm-panel"
        style={{ backgroundColor: `${bgColor}` }}
      >
        <div className="header">
          <div>{props.title}</div>
          <div
            className={disableClose ? 'btn-close-disabled' : 'btn-close'}
            onClick={onClose}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="LINE/icon-close">
                <path
                  id="Icon-check"
                  d="M18 6L6 18"
                  stroke="#2C2C2C"
                  strokeWidth="1.5"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <path
                  id="Icon-check_2"
                  d="M18 18L6 6"
                  stroke="#2C2C2C"
                  strokeWidth="1.5"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
        </div>
        <div>{props.children}</div>
      </div>
    </section>
  );
};

export default LiffPromotionList;
