import { CHALLENGE_LIST } from '../common/challengeConstants';
import { getChallengeDetail, getChallengeList, getChallengeProfile } from '../services/challenge/challengeList.service';
import {
  FETCH_CHALLENGES_LIST_ONGOING_SUCCESS,
  FETCH_CHALLENGES_LIST_UPCOMING_SUCCESS,
  FETCH_CHALLENGES_LIST_FAILURE,
  FETCH_CHALLENGES_DETAIL_SUCCESS,
  FETCH_CHALLENGES_DETAIL_FAILURE,
  FETCH_CHALLENGES_PROFILE_FAILURE,
  FETCH_CHALLENGES_PROFILE_SUCCESS,
  FETCH_CHALLENGES_LIST_SUCCESS
} from './types';

export const fetchChallengeList = (params) => (dispatch) => {
  return getChallengeList(params)
    .then((result) => {
      if (!params) {
        dispatch({
          type: FETCH_CHALLENGES_LIST_SUCCESS,
          payload: result,
        });
      }
      else if (params.challengeStatus === CHALLENGE_LIST.TYPES.ongoing) {
        dispatch({
          type: FETCH_CHALLENGES_LIST_ONGOING_SUCCESS,
          payload: result,
        });
      } else if (params.challengeStatus === CHALLENGE_LIST.TYPES.upcoming) {
        dispatch({
          type: FETCH_CHALLENGES_LIST_UPCOMING_SUCCESS,
          payload: result,
        });
      }
    })
    .catch(function (error) {
      dispatch({
        type: FETCH_CHALLENGES_LIST_FAILURE,
        payload: error,
      });
    });
};

export const fetchChallengeDetail = (params) => (dispatch) => {
  return getChallengeDetail(params)
    .then((result) => {
      dispatch({
        type: FETCH_CHALLENGES_DETAIL_SUCCESS,
        payload: result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: FETCH_CHALLENGES_DETAIL_FAILURE,
        payload: error,
      });
    });
};

export const fetchChallengeProfile = (params) => (dispatch) => {
  return getChallengeProfile(params)
    .then((result) => {
      dispatch({
        type: FETCH_CHALLENGES_PROFILE_SUCCESS,
        payload: result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: FETCH_CHALLENGES_PROFILE_FAILURE,
        payload: error,
      });
    });
}
