import config from '../config';
import axios from 'axios'

export const questionService = {
    get
};

async function get() {
    const response = await axios.get(`${config.API_URL}/questions`)
    return response.data
}