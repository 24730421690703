import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import Select, { components } from 'react-select';
import { isEqual } from 'lodash';

import { isAlreadyRenewal, createCart, addRenewalEntry, customerFetch, getPickup, choosePickupStore, updateDelivery, validateCart, calculateCart } from '../../../actions';
import { ConditionEBook } from '../../condition/PaymentCondition';
import { commonConstant } from '../../../common';
import variables from '../../../common/commonConstant.scss';
import { Collapse, Checkbox, Modal } from '../../../helpers';
import { activityService } from '../../../services';

import './ChooseMagType.scss';

const liff = window.liff;

const code = {
  abo: {
    electronic: '291580',
    physical: { atShop: '291577', currentAddr: '291578' },
  },
  member: {
    physical: '254998',
  },
};

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}><img src="/images/Magnifying.svg" alt="" />{children}</components.ValueContainer>
);

export const ChooseMagType = () => {
  const dispatch = useDispatch();
  const didMount = useRef(false);
  const { profile, checkrenew, cart: cartData, addItem, customer, getStore, pickStore, delivery } = useSelector(state => state);
  const [type, setType] = useState('electronic');
  const [stores, setStores] = useState();
  const [deliveryType, setDeliveryType] = useState();
  const [deliveryStore, setDeliveryStore] = useState();
  const [modalContent, setModalContent] = useState();
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [menuIsOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const isBtnDisable = type === 'physical' && (!deliveryType || (deliveryType === 'atShop' && !deliveryStore)) && !showModal;
  const customerRef = useRef();
  const sendAvtivity = useCallback((status) => {
    activityService.create({
      uid: customer.uid,
      userId: customer._id,
      status: status,
      action: 'SELECT_MAGAZINE_TYPE',
    });
  });

  useEffect(() => {
    didMount.current = true;
    dispatch(getPickup());
    if (profile.token) {
      dispatch(customerFetch(profile.userId));
    }
  }, []);

  useEffect(() => {
    if (!isEqual(customer, customerRef.current)) {
      customerRef.current = customer;
      if (customer.amwayNumber) {
        dispatch(isAlreadyRenewal(customer.amwayNumber));
      } else if (customer.type) {
        handleToggleModal('NOT_FOUND');
      }
    }
  }, [customer]);

  useEffect(() => {
    if (profile.token && !customer.amwayNumber) {
      dispatch(customerFetch(profile.userId));
    }
  }, [profile]);

  useEffect(() => {
    if (checkrenew.data?.renewalFlag === 'CAN_RENEW' || checkrenew.data?.renewalFlag === 'MUST_RENEW') {
      dispatch(createCart({ amwayNumber: customer.amwayNumber, partyId: checkrenew.data.partyId, type: customer.type, lineId: customer.uid }));
    } else if (checkrenew.data) {
      handleToggleModal('CANT_RENEW');
    } else if (checkrenew.statusCode && checkrenew.statusCode !== 200) {
      handleToggleModal('ERROR');
    }
  }, [checkrenew]);

  useEffect(() => {
    if (cartData.data && getStore.data) {
      setStores(getStore.data.map(store => ({ label: store.displayName, value: store.name })));
      setLoading(false);
      if (customer.type === 'member') {
        setType('physical');
        setDeliveryType('currentAddr');
      }
    } else if ((!cartData.data || !getStore.data) && (cartData.code && getStore.code) && (cartData.code !== 200 || getStore.code !== 200)) {
      handleToggleModal('ERROR');
    }
  }, [cartData, getStore]);

  useEffect(() => {
    if (pickStore && pickStore.code) {
      if (pickStore.code === 200) {
        sendAvtivity('complete');
        setRedirect({
          pathname: commonConstant.pathConfirmPayment,
          state: {
            customerType: customer.type,
            type,
            deliveryType,
          },
        });
      } else {
        sendAvtivity('incomplete');
        handleToggleModal('ERROR');
      }
    }
  }, [pickStore]);

  useEffect(() => {
    if (delivery && delivery.code) {
      if (delivery.code === 200) {
        if (deliveryType === 'atShop' && deliveryStore && !pickStore.data && !pickStore.code) {
          const currStore = getStore.data.find(s => s.name === deliveryStore.value)
          dispatch(choosePickupStore({ cartCode: cartData.data.code, partyId: customer.partyId, storeId: deliveryStore.value, currStore }));
        } else {
          setRedirect({
            pathname: commonConstant.pathConfirmPayment,
            state: {
              customerType: customer.type,
              type,
              deliveryType,
            },
          });
        }
      } else {
        sendAvtivity('incomplete');
        handleToggleModal('ERROR');
      }
    }
  }, [delivery]);

  useEffect(() => {
    if (addItem.data && addItem.data[0].statusCode === 'success' && didMount.current) {
      if (addItem.data && addItem.code === 200) {
        let pickup = null;
        if (deliveryType === 'atShop') {
          pickup = 'pickup';
        } else if (deliveryType === 'currentAddr') {
          pickup = 'standard-gross';
        }
        if (pickup) {
          dispatch(updateDelivery({ cartCode: cartData.data.code, partyId: customer.partyId, pickup: pickup }));
        } else {
          // sendAvtivity('incomplete');
          // handleToggleModal('ERROR');
          const partyId = customer.partyId
          const cartId = cartData.data.code
          dispatch(validateCart(partyId, cartData.data.code))
          dispatch(calculateCart(partyId, cartId))
          setRedirect({
            pathname: commonConstant.pathConfirmPayment,
            state: {
              customerType: customer.type,
              type,
              deliveryType,
            },
          });
        }
      } else {
        sendAvtivity('incomplete');
        handleToggleModal('ERROR');
      }
    } else if (addItem.data || (addItem.statusCode && addItem.statusCode !== 200)) {
      sendAvtivity('incomplete');
      handleToggleModal('ERROR');
    }
  }, [addItem]);


  const handleSubmit = () => {
    setSubmitLoading(true);
    dispatch(addRenewalEntry({ cartCode: cartData.data.code, partyId: customer.partyId, code: customer.type === 'abo' && type === 'physical' ? code[customer.type][type][deliveryType] : code[customer.type][type] }));
  };

  const handleToggleModal = (content) => {
    setLoading(false);
    setModalContent(content);
    setShowModal(show => !show);
  };

  const handleModalContent = () => {
    switch (modalContent) {
      case 'ERROR':
        return {
          content: 'เกิดข้อพิดพลาด กรุณาลองใหม่ในภายหลัง',
          type: 'danger',
          cancleBtn: false,
          okBtn: true,
          okText: 'ปิด',
          fnOk: () => liff.closeWindow(),
        };
      case 'CANT_RENEW':
        return {
          content: <> กรุณาเช็คสถานะการต่ออายุได้ที่เมนูบัญชีของฉัน<br />
            <a href={`https://liff.line.me/${process.env.REACT_APP_CLIENT_ID}?seamless=/my-account`}>https://www.amway.co.th/my-account</a><br />
                  หรือติดต่อ Amway Contact Center <a href="tel:02725800">02725800</a></>,
          type: 'danger',
          cancleBtn: false,
          okBtn: true,
          okText: 'โทร',
          fnOk: () => window.location = 'tel:027258000',
        };
      case 'NOT_FOUND':
        return {
          content: 'ไม่พบข้อมูลสมาชิก กรุณาติดต่อ 02-725-8000',
          type: 'danger',
          cancleBtn: false,
          okBtn: true,
          okText: 'โทร',
          fnOk: () => window.location = 'tel:027258000',
        };
      default:
        return;
    }
  };

  const handleRenderBody = (memberType) => {
    switch (memberType) {
      case 'abo':
        return (
          <>
            <Collapse title={
              <Checkbox checked={type === 'electronic'} checkmarkColor={variables.success}>
                <div>อิเล็กทรอนิกส์</div>
                <div>( รับส่วนลด 150 บาท ในการซื้อสินค้า )</div>
              </Checkbox>
            } standalone isCollpased={type !== 'electronic'} onClick={() => {
              setType('electronic');
              setDeliveryType();
            }}>
              ข้าพเจ้ายอมรับ <span className="link" onClick={() => setShowConsent(true)}>เงื่อนไข E-Starter Kit</span> สำหรับนักธุรกิจ
            </Collapse>
            <Collapse title={
              <Checkbox checked={type === 'physical'} checkmarkColor={variables.success}>
                <div>รับเป็นรูปเล่ม</div>
                <div>ชุดคู่มือนักธุรกิจแอมเวย์จะจัดส่งให้ตามที่อยู่ปัจจุบัน</div>
              </Checkbox>
            } standalone isCollpased={type !== 'physical'} onClick={() => setType('physical')}>
              <div>กรุณาเลือกวิธีจัดส่ง</div>
              <div>
                <input className="radio-input" id="currentAddr" type="radio" checked={deliveryType === 'currentAddr'} onClick={() => setDeliveryType('currentAddr')} />
                <label htmlFor="currentAddr">จัดส่งที่อยู่ที่บ้าน ( ที่อยู่ปัจจุบัน )</label>
              </div>
              <div>
                <input className="radio-input" id="atShop" type="radio" checked={deliveryType === 'atShop'} onClick={() => setDeliveryType('atShop')} />
                <label htmlFor="atShop">รับด้วยตนเอง</label>
                <Select classNamePrefix="react-select" options={stores} menuPortalTarget={document.querySelector('.choosemag-container')} menuShouldScrollIntoView={true}
                  onChange={(e) => setDeliveryStore(e)}
                  onMenuOpen={() => setMenuOpen(true)}
                  onMenuClose={() => setMenuOpen(false)}
                  placeholder="ค้นหาร้านค้าแอมเวย์"
                  className={deliveryType === 'atShop' ? 'visible' : 'hide'}
                  components={{
                    ValueContainer,
                  }}
                  value={deliveryStore}
                  styles={{
                    indicatorsContainer: styles => ({ ...styles, display: 'none' }),
                    menu: styles => ({ ...styles, top: 0, marginTop: '5px' }),
                    control: styles => ({
                      ...styles,
                      borderRadius: '50px',
                      boxShadow: 'none',
                      marginTop: '5px',
                    }),
                    placeholder: styles => ({ ...styles, marginLeft: '20px', maxWidth: 'calc(100% - 32px)' }),
                    singleValue: styles => ({ ...styles, marginLeft: '20px', maxWidth: 'calc(100% - 32px)' }),
                    input: styles => ({ ...styles, marginLeft: '7px' }),
                  }} />
              </div>
            </Collapse>
          </>
        );
      case 'member':
        return (
          <>
            <Collapse title={
              <Checkbox checked={deliveryType === 'currentAddr'} checkmarkColor={variables.success}>
                <div>จัดส่งที่อยู่ที่บ้าน ( ที่อยู่ปัจจุบัน )</div>
              </Checkbox>
            }
              standalone
              seperator={false}
              customSuffix=" "
              noContent={true}
              isCollpased={deliveryType !== 'currentAddr'}
              onClick={() => setDeliveryType('currentAddr')} />
            <Collapse title={
              <Checkbox checked={deliveryType === 'atShop'} checkmarkColor={variables.success}>
                <div>รับด้วยตนเองที่แอมเวย์ ช็อป</div>
              </Checkbox>
            }
              standalone
              isCollpased={deliveryType !== 'atShop'}
              onClick={() => setDeliveryType('atShop')}
              seperator={false}
              containerClassName="member">
              <Select classNamePrefix="react-select" options={stores} menuPortalTarget={document.querySelector('body')}
                onChange={(e) => setDeliveryStore(e)}
                placeholder="ค้นหาร้านค้าแอมเวย์"
                className={deliveryType === 'atShop' ? 'visible' : 'hide'}
                components={{
                  ValueContainer,
                }}
                value={deliveryStore}
                styles={{
                  indicatorsContainer: styles => ({ ...styles, display: 'none' }),
                  menu: styles => ({ ...styles, top: 0, marginTop: '5px' }),
                  control: styles => ({
                    ...styles,
                    borderRadius: '50px',
                    boxShadow: 'none',
                    marginTop: '5px',
                  }),
                  placeholder: styles => ({ ...styles, marginLeft: '20px', maxWidth: 'calc(100% - 32px)' }),
                  singleValue: styles => ({ ...styles, marginLeft: '20px', maxWidth: 'calc(100% - 32px)' }),
                  input: styles => ({ ...styles, marginLeft: '7px' }),
                }} />
            </Collapse>
            <div className="medium-blue">
              สำหรับนิตยสารแอมนิวส์รูปแบบดิจิทัลจะส่ง
              ถึงคุณทุกวันที่ 1 ของเดือน ผ่านทาง LINE หรือ SMS ที่ได้ลงทะเบียนไว้กับบริษัท
            </div>
          </>
        );
      default:
        return;
    }
  };

  const content = handleModalContent();

  if (loading) {
    return <div> <LoadingOverlay
      active={true}
      spinner
      text="Loading"
      styles={{ wrapper: { width: '100%', height: '100%', position: 'absolute', pointerEvents: 'none', top: 0, left: 0 } }}
    /></div>;
  }
  if (showConsent) {
    return <PaymentConsent onClick={() => setShowConsent(false)} />;
  } else if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (

    <div className="choosemag-container">
      {
        //to fix label issue
        submitLoading && <LoadingOverlay
          active={true}
          spinner
          text="Loading"
          styles={{ wrapper: { width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 } }}
        />
      }
      {

        showModal && <Modal
          content={content.content}
          type={content.type}
          fnClose={() => setShowModal(false)}
          fnOk={content.fnOk}
          fnCancel={content.cancleBtn ? () => setShowModal(false) : null}
          closeBtn={content.closeBtn}
          okBtn={content.okBtn}
          okText={content.okText}
          cancleText="ยกเลิก" />
      }
      <div className="header">
        <div className="title">{customer.type === 'abo' ? 'เลือกรูปแบบนิตยสารอะชีฟ' : 'เลือกวิธีจัดส่งคู่มือสมาชิกแอมเวย์'}</div>
        <div className="desc">ค่าต่ออายุ{customer.type === 'abo' ? 'นักธุรกิจแอมเวย์ 450' : 'สมาชิกแอมเวย์ 100'} บาทต่อปี
สามารถยกเลิกการต่ออายุอัตโนมัติได้ภายหลัง</div>
      </div>
      <div className="body">
        {handleRenderBody(customer.type)}
      </div>
      <div className={`footer ${menuIsOpen && 'menu-open'}`}>
        <button className={`away-button ${isBtnDisable ? '' : 'active'}`} onClick={isBtnDisable ? '' : handleSubmit}>ยืนยัน</button>
      </div>
    </div>
  );
};


const PaymentConsent = ({ onClick }) => (
  <div className="payment-consent-container">
    <ConditionEBook />
    <div className="card-footer">
      <button className="away-button active" onClick={onClick}>กลับ</button>
    </div>
  </div>
);

