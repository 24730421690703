import React, { useEffect, useRef, useState } from 'react';
import './YoutubeVideoModal.scss';
import { useDispatch } from 'react-redux';
import { updateVideoStatus } from '../../actions/challenge/challengeVideoActions';
import { convertSecondsToDecimalMinutes, convertVideoDurationToSeconds } from '../../utill.func';
import { ARROW_WHITE_ICON } from '../challengeConstants';
import { YOUTUBE_WATCH_URI } from '../commonConstant';

const YoutubeVideoModal = (props) => {

    const {
        videoId,
        videoLink,
        duration,
        isOpen,
        onBack,
        eventId,
        aboId,
        partyId,
        startFromDuration,
        videoStatus } = props;

    const [isFullyWatched, setIsFullyWatched] = useState(videoStatus || false);
    const [playerEnded, setPlayerEnded] = useState(false);
    const dispatch = useDispatch();
    const playerRef = useRef(null);

    const loadYouTubeAPI = () => {
        return new Promise((resolve) => {
            if (window.YT && window.YT.Player) {
                resolve(window.YT);
            } else {
                window.onYouTubeIframeAPIReady = () => {
                    resolve(window.YT);
                };

                const tag = document.createElement('script');
                tag.src = YOUTUBE_WATCH_URI;
                document.body.appendChild(tag);
            }
        });
    };

    useEffect(() => {
        if (!isOpen) return;

        loadYouTubeAPI().then((YT) => {
            playerRef.current = new YT.Player(`youtube-player-${videoId}`, {
                videoId: videoId,
                events: {
                    onReady: (event) => {
                        if (startFromDuration) {
                            event.target.seekTo(startFromDuration);
                        }
                    },
                    onStateChange: (event) => {
                        if (event.data === YT.PlayerState.ENDED) {
                            setPlayerEnded(true);
                            setIsFullyWatched(true);
                            handleFullWatch();
                        }
                    }
                },
            });
        });

    }, [isOpen, startFromDuration]);

    useEffect(() => {

        return () => {
            let currentTime;
            if (playerRef?.current && !playerEnded) {
                currentTime = playerRef?.current.getCurrentTime();
            }
            else if(playerEnded) {
                currentTime = convertVideoDurationToSeconds(duration);
            }

                dispatch(updateVideoStatus({
                    eventId: eventId,
                    videoLink: videoLink,
                    aboId: aboId,
                    partyId: partyId,
                    status: isFullyWatched,
                    stoppedAt: convertSecondsToDecimalMinutes(currentTime),
                }));
        };
    }, [playerEnded, isFullyWatched]);

    const handleFullWatch = () => {
        if (playerRef?.current) {
            const totalDurationInSeconds = convertVideoDurationToSeconds(duration);

            dispatch(updateVideoStatus({
                eventId: eventId,
                videoLink: videoLink,
                aboId: aboId,
                partyId: partyId,
                status: true,
                stoppedAt: convertSecondsToDecimalMinutes(totalDurationInSeconds),
            }));
        }
    };

    const handleBack = () => {
        onBack();
    };

    if (!isOpen) return null;

    return (
        <div className="youtube-modal">
            <button onClick={handleBack} className="back-modal-button">
                <img src={ARROW_WHITE_ICON} alt='Arrow Back' />
            </button>
            <div id={`youtube-player-${videoId}`}></div>
        </div>
    );
};

export default YoutubeVideoModal;