import React, { useEffect, useRef } from 'react';
import SlickCarousel from '../../../../../common/slickCarousel/slickCarousel';
import { ALL } from '../../../../../common/commonConstant';
import './FoodCategoryCarousel.scss';

const FoodCategoryCarousel = ({ data = [], selectedCategory, onClick = () => { } }) => {
  const SliderRef = useRef(null);

  useEffect(() => {
    const indexToSlide = data.findIndex(category => category.id === selectedCategory.id);
    data.length > 1 && selectedCategory.id === ALL ? 
    SliderRef.current.slickGoTo(indexToSlide - 1) :
    SliderRef.current.slickGoTo(indexToSlide)
  }, [selectedCategory.id, data.length]);

  return <SlickCarousel slidesToShow={4} ref={SliderRef}>
    {data.map((item) => {
      return <div key={item?.id}
        onClick={(event) => onClick(item, event)}
        className={`category-card${selectedCategory.id === item?.id ? ' category-card-active' : ''}`}>
        <div className="category-img-container">
          <img src={item?.imageUrl} />
        </div>
        <div className="category-card-title">
          {item?.name}
        </div>
      </div>
    })}
  </SlickCarousel>
};

export default FoodCategoryCarousel;
