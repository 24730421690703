import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { osName } from 'react-device-detect';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';

import { alertActions } from '../../actions';
import { commonConstant } from '../../common';
import { AWDatepicker, Modal } from '../../helpers';
import { customerService, activityService, amwayService } from '../../services';
import {checkLineMID} from '../../utill.func';

const today = new Date();
const maxYear = today.getFullYear() + 540;
const minYear = today.getFullYear() + 443;

class Public extends Component {
  constructor(props) {
    super(props);

    if (sessionStorage.getItem('customer') === null || sessionStorage.getItem('customer') === 'undefined') {
      window.location.replace(commonConstant.pathHome);
    }

    const customer = JSON.parse(sessionStorage.getItem('customer'));
    if (!customer.type) {
      return window.location.replace(commonConstant.pathHome);
    }

    this.state = {
      customer: customer,
      name: '',
      lastname: '',
      birthday: '',
      province: '',
      phone: '',
      invalid_name: false,
      loading: false,
      info: '',
      error: false,
      isOpenModal: false,
    };
  }

  onPaste = e => {
    e.preventDefault();
    return false;
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleTextChange = e => {
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (e.target.value === '' || !regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleMobileChange = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || e.target.value.match(regex)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleCheckValidDate = () => {
    const { birthday } = this.state;
    const dateParts = birthday.split('-');
    const dateObject = new Date(birthday);
    if (dateObject.getFullYear() === +dateParts[0] && dateObject.getMonth() === dateParts[1] - 1 && dateObject.getDate() === +dateParts[2]) {
      return true;
    }
    return false;
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.name === '') {
      this.setState({
        invalid_name: true,
      });
      return false;
    }
    if (!this.handleCheckValidDate()) {
      this.setState({ loading: false, isOpenModal: true });
      return false;
    }

    const customer = {
      name: `${this.state.name} ${this.state.lastname}`,
      birthday: this.state.birthday,
      gender: this.state.gender,
      province: this.state.province,
      phone: this.state.phone,
      info: osName === 'IOS' ? 'iPhone' : osName,
      _id: this.state.customer._id,
    };
    const updated = await customerService.update(this.state.customer._id, customer);

    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(`${this.state.name} ${this.state.lastname}`)) {
      const errorMessage = updated.message instanceof Object
        ? updated.message.map(value => Object.values(value.constraints))
        : 'ไม่สามารถบันทึก ชื่อ-นามสกุล ที่มีตัวอักษรพิเศษ';

      this.props.showError(errorMessage);
      this.setState({ loading: false });

      return false;
    }

    if (!updated._id || format.test(`${this.state.name} ${this.state.lastname}`)) {
      const errorMessage = updated.message instanceof Object ?
        updated.message.map(value => Object.values(value.constraints)) :
        'ระบบไม่สามารถบันทึกข้อมูลได้กรุณาติดต่อเจ้าหน้าที่';

      this.props.showError(errorMessage);
      this.setState({ loading: false });

      return false;
    }

    sessionStorage.setItem('customer', JSON.stringify(updated));

    const params = {
      mid: this.state.customer.uid,
      cid: null,
      customerId: this.state.customer._id,
      info: osName === 'IOS' ? 'iPhone' : osName,
      status: 'unblock',
      displayname: this.state.customer.displayName,
    };
    
    const replymessage = await checkLineMID(params);

    if (replymessage === 'Success') {
      activityService.create({
        uid: this.state.customer.uid,
        userId: this.state.customer._id,
        status: 'incomplete',
        action: 'VERIFY_PUBLIC',
      });

      return this.props.history.push('/question');
    } else {
      this.props.showError('ระบบไม่สามารถบันทึกข้อมูลได้กรุณาติดต่อเจ้าหน้าที่');
      this.setState({ loading: false });
    }
  }

  handleChangeGender = gender => this.setState({ gender });

  handleModalOk = () => this.setState({ isOpenModal: false })

  render() {
    const { loading, name, lastname, gender, birthday, phone, province, isOpenModal } = this.state;
    const isTrue = name && lastname && gender && birthday && phone.length === 10 && province ? true : false;
    return (
      <div className="register">
        <LoadingOverlay
          active={loading}
          spinner
          text="Loading"
          styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
        />
        {
          isOpenModal ?
            <Modal
              title="วันเกิดไม่ถูกต้อง"
              detail="กรุณาระบุวันที่ให้ถูกต้อง"
              type="danger"
              closeBtn={true}
              fnClose={this.handleModalOk}
              fnOk={this.handleModalOk}
              okText="ตกลง"
              okBtn={true} /> : null
        }

        <form onSubmit={this.handleSubmit} className="away-form-new">

          <div className="info-form">
            {/* <div className="secForm regis-form ">
                            <div className="txtForm">ชื่อ - นามสกุล</div>
                            <div className="inputForm input_name"><input type="text" name="name" required maxLength="30" onChange={this.handleChange} /></div>
                        </div> */}
            <h1>กรุณากรอกข้อมูลลงทะเบียน</h1>
            <div className="form-group">
              <div><label htmlFor="name">ชื่อ</label></div>
              <input
                type="text"
                className="form-control input-line-bottom"
                id="name"
                name="name"
                required
                placeholder="ชื่อ"
                maxLength="25"
                onChange={this.handleTextChange}
                onPaste={this.onPaste}
                value={this.state.name}
              />
            </div>
            <div className="form-group">
              <div><label htmlFor="lastname">นามสกุล</label></div>
              <input
                type="text"
                className="form-control input-line-bottom"
                id="lastname"
                name="lastname"
                required
                placeholder="นามสกุล"
                maxLength="25"
                onChange={this.handleTextChange}
                onPaste={this.onPaste}
                value={this.state.lastname}
              />
            </div>

            <div className="form-group-radio">
              <div className="label">เพศ</div>
              <div className={`_radio ${gender === 'male' ? 'active' : null}`} onClick={() => this.handleChangeGender('male')}>
                ชาย
              </div>
              <div className={`_radio ${gender === 'female' ? 'active' : null}`} onClick={() => this.handleChangeGender('female')}>
                หญิง
              </div>
            </div>

            {/* <div className="form-group">
              <div><label for="birthday">วันเกิด</label></div>
              <label className={`form-control input-line-bottom-date custom-date-label font-size-14 ${birthday ? 'active' : ''}`} for="birthday">{birthday ? `${birthDate.getDate()} ${monthNamesShort[birthDate.getMonth()]} ${birthDate.getUTCFullYear() + 543}` : 'วัน / เดือน / ปี'}</label>
              <input type="date"
                className="form-control input-line-bottom-date hidden-date-input"
                id="birthday" name="birthday"
                placeholder="วัน / เดือน / ปี"
                onChange={this.handleChange}
                onPaste={this.onPaste}
                max={maxBirthday}
                min={minBirthday}
                value={birthday}
              /> */}
            {/* <div className="calendar-icon">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 2C15.552 2 16 2.448 16 3V15C16 15.552 15.552 16 15 16H1C0.448 16 0 15.552 0 15V3C0 2.448 0.448 2 1 2H3V0H6V2H10V0H13V2H15ZM14 14V5H2V14H14ZM4 7H6V9H4V7ZM7 7H9V9H7V7Z" fill="#38539A" />
                                </svg>
                            </div> */}
            {/* </div> */}
            <div className="form-group">
              <div><label htmlFor="birthday">วันเกิด</label></div>
              <AWDatepicker
                id="birthday" name="birthday"
                maxYear={maxYear}
                minYear={minYear}
                value={birthday}
                onChange={this.handleChange} />
            </div>

            <div className="form-group">
              <div><label htmlFor="phone">เบอร์โทรศัพท์มือถือ</label></div>
              <input
                type="tel"
                value={this.state.phone}
                pattern="[0]{1}[6-9]{1}[0-9]{1}[0-9]{7}"
                className="form-control input-line-bottom"
                id="phone"
                name="phone"
                required
                maxLength="10"
                onChange={this.handleMobileChange}
                onPaste={this.onPaste}
                placeholder="เบอร์โทรศัพท์มือถือ"
              />
            </div>

            <div className="form-group">
              <div><label htmlFor="province">จังหวัดที่อยู่</label></div>
              <div className="inputForm">
                <div className="inputDate">
                  <div className="select regis-select">
                    <select className={`form-control input-line-bottom ${province ? '' : 'placeholder'}`} name="province" onChange={this.handleChange} required>
                      <option value="" />
                      <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                      <option value="กระบี่">กระบี่ </option>
                      <option value="กาญจนบุรี">กาญจนบุรี </option>
                      <option value="กาฬสินธุ์">กาฬสินธุ์ </option>
                      <option value="กำแพงเพชร">กำแพงเพชร </option>
                      <option value="ขอนแก่น">ขอนแก่น</option>
                      <option value="จันทบุรี">จันทบุรี</option>
                      <option value="ฉะเชิงเทรา">ฉะเชิงเทรา </option>
                      <option value="ชัยนาท">ชัยนาท </option>
                      <option value="ชัยภูมิ">ชัยภูมิ </option>
                      <option value="ชุมพร">ชุมพร </option>
                      <option value="ชลบุรี">ชลบุรี </option>
                      <option value="เชียงใหม่">เชียงใหม่ </option>
                      <option value="เชียงราย">เชียงราย </option>
                      <option value="ตรัง">ตรัง </option>
                      <option value="ตราด">ตราด </option>
                      <option value="ตาก">ตาก </option>
                      <option value="นครนายก">นครนายก </option>
                      <option value="นครปฐม">นครปฐม </option>
                      <option value="นครพนม">นครพนม </option>
                      <option value="นครราชสีมา">นครราชสีมา </option>
                      <option value="นครศรีธรรมราช">นครศรีธรรมราช </option>
                      <option value="นครสวรรค์">นครสวรรค์ </option>
                      <option value="นราธิวาส">นราธิวาส </option>
                      <option value="น่าน">น่าน </option>
                      <option value="นนทบุรี">นนทบุรี </option>
                      <option value="บึงกาฬ">บึงกาฬ</option>
                      <option value="บุรีรัมย์">บุรีรัมย์</option>
                      <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์ </option>
                      <option value="ปทุมธานี">ปทุมธานี </option>
                      <option value="ปราจีนบุรี">ปราจีนบุรี </option>
                      <option value="ปัตตานี">ปัตตานี </option>
                      <option value="พะเยา">พะเยา </option>
                      <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา </option>
                      <option value="พังงา">พังงา </option>
                      <option value="พิจิตร">พิจิตร </option>
                      <option value="พิษณุโลก">พิษณุโลก </option>
                      <option value="เพชรบุรี">เพชรบุรี </option>
                      <option value="เพชรบูรณ์">เพชรบูรณ์ </option>
                      <option value="แพร่">แพร่ </option>
                      <option value="พัทลุง">พัทลุง </option>
                      <option value="ภูเก็ต">ภูเก็ต </option>
                      <option value="มหาสารคาม">มหาสารคาม </option>
                      <option value="มุกดาหาร">มุกดาหาร </option>
                      <option value="แม่ฮ่องสอน">แม่ฮ่องสอน </option>
                      <option value="ยโสธร">ยโสธร </option>
                      <option value="ยะลา">ยะลา </option>
                      <option value="ร้อยเอ็ด">ร้อยเอ็ด </option>
                      <option value="ระนอง">ระนอง </option>
                      <option value="ระยอง">ระยอง </option>
                      <option value="ราชบุรี">ราชบุรี</option>
                      <option value="ลพบุรี">ลพบุรี </option>
                      <option value="ลำปาง">ลำปาง </option>
                      <option value="ลำพูน">ลำพูน </option>
                      <option value="เลย">เลย </option>
                      <option value="ศรีสะเกษ">ศรีสะเกษ</option>
                      <option value="สกลนคร">สกลนคร</option>
                      <option value="สงขลา">สงขลา </option>
                      <option value="สมุทรสาคร">สมุทรสาคร </option>
                      <option value="สมุทรปราการ">สมุทรปราการ </option>
                      <option value="สมุทรสงคราม">สมุทรสงคราม </option>
                      <option value="สระแก้ว">สระแก้ว </option>
                      <option value="สระบุรี">สระบุรี </option>
                      <option value="สิงห์บุรี">สิงห์บุรี </option>
                      <option value="สุโขทัย">สุโขทัย </option>
                      <option value="สุพรรณบุรี">สุพรรณบุรี </option>
                      <option value="สุราษฎร์ธานี">สุราษฎร์ธานี </option>
                      <option value="สุรินทร์">สุรินทร์ </option>
                      <option value="สตูล">สตูล </option>
                      <option value="หนองคาย">หนองคาย </option>
                      <option value="หนองบัวลำภู">หนองบัวลำภู </option>
                      <option value="อำนาจเจริญ">อำนาจเจริญ </option>
                      <option value="อุดรธานี">อุดรธานี </option>
                      <option value="อุตรดิตถ์">อุตรดิตถ์ </option>
                      <option value="อุทัยธานี">อุทัยธานี </option>
                      <option value="อุบลราชธานี">อุบลราชธานี</option>
                      <option value="อ่างทอง">อ่างทอง </option>
                    </select>
                    <div className="select_arrow">
                      <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.34999 7.35005L13.35 1.35005L12.65 0.650054L6.64999 6.65005L7.34999 7.35005ZM0.649993 1.35005L6.64999 7.35005L7.34999 6.65005L1.34999 0.650054L0.649993 1.35005ZM13.35 1.35005C13.3978 1.30516 13.4359 1.25095 13.462 1.19075C13.4881 1.13055 13.5015 1.06565 13.5015 1.00005C13.5015 0.934457 13.4881 0.869557 13.462 0.809359C13.4359 0.749161 13.3978 0.694944 13.35 0.650054C13.3051 0.602223 13.2509 0.564101 13.1907 0.538041C13.1305 0.51198 13.0656 0.498535 13 0.498535C12.9344 0.498535 12.8695 0.51198 12.8093 0.538041C12.7491 0.564101 12.6949 0.602223 12.65 0.650054L13.35 1.35005ZM6.99999 7.00005L6.64999 7.35005C6.69488 7.39788 6.7491 7.43601 6.8093 7.46207C6.8695 7.48813 6.9344 7.50157 6.99999 7.50157C7.06559 7.50157 7.13049 7.48813 7.19069 7.46207C7.25089 7.43601 7.3051 7.39788 7.34999 7.35005L6.99999 7.00005ZM1.34999 0.650054C1.3051 0.602223 1.25089 0.564101 1.19069 0.538041C1.13049 0.51198 1.06559 0.498535 0.999993 0.498535C0.934396 0.498535 0.869496 0.51198 0.809298 0.538041C0.7491 0.564101 0.694883 0.602223 0.649993 0.650054C0.602162 0.694944 0.56404 0.749161 0.537979 0.809359C0.511919 0.869557 0.498474 0.934457 0.498474 1.00005C0.498474 1.06565 0.511919 1.13055 0.537979 1.19075C0.56404 1.25095 0.602162 1.30516 0.649993 1.35005L1.34999 0.650054Z" fill="#929497" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="text-notice">
                            เพื่อบันทึกเป็นหลักฐานข้อมูลสมาชิก<br />
                            และรักษาสิทธิประโยชน์ที่จะได้รับในอนาคต
                        </div> */}
            <div className="cancle-text text-center mb-20">
              <Link onClick={() => this.props.history.push(commonConstant.pathHome)}>ยกเลิก</Link>
            </div>
            <div className="text-center"><button className={`register-next-button ${isTrue ? 'active' : ''}`} type="submit">ถัดไป</button></div>
          </div>
        </form >
      </div >

    );
  }
}

function mapDispatchToProps(dispatch) {
  return ({
    showError: (message) => dispatch(alertActions.showError(message)),
  });
}

export default connect(null, mapDispatchToProps)(Public);
