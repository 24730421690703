import React from "react";

const CircleRoundDouble = () => {
    return (
        <div className="circle-round-1">
            <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="95.5" cy="95" r="88" stroke="white" strokeWidth="12" strokeLinecap="round" fill="none">
                </circle>
                <circle cx="95.5" cy="95" r="88" stroke="url(#paint0_linear_154_39309)" strokeWidth="12"
                    strokeLinecap="round"></circle>
                <path
                    d="M18.4237 50.5C26.3481 36.7745 37.7939 25.4141 51.5784 17.5927C65.3628 9.77129 80.9857 5.77264 96.8328 6.00998"
                    stroke="#6CC949" strokeWidth="11" strokeLinecap="round"></path>
                <defs>
                    <linearGradient id="paint0_linear_154_39309" x1="68.5" y1="12.3913" x2="109.5" y2="12.3913"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6CC949"></stop>
                        <stop offset="1" stopColor="#9FDC7D"></stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default CircleRoundDouble;