import React from 'react';
import './StepsCard.scss'
import { CHALLENGE } from '../challengeConstants';
import variables from '../commonConstant.scss';
import { HOME_PAGE, STEPS_CARD_TITLE } from '../homePageConstants';
import { encapsulatePercent, getMinToHourString } from '../../utill.func';
import { round } from '../../services';

const StepsCard = ({ data, collapsedView, exerciseGoal, id }) => {
    const totalPercent = exerciseGoal ? data?.exercisePercent : data?.percent;
    const innerGraphColor = totalPercent == 0 ? 'transparent' : variables.flgreen3;

    return (
        <div className={`steps-card-slider-container ${collapsedView}`}>
            <div className="card-slider-left">
                <div className='card-slider-title-wrapper'><div className="card-slider-title">
                    {exerciseGoal ? data.title : STEPS_CARD_TITLE}
                </div></div>
                {exerciseGoal ? <div className="card-slider-pace">
                    <span className='card-slider-pace-value'>{data?.value ? getMinToHourString(data?.value) :'-:-'}</span>
                    <span className='card-slider-pace-unit'>/{data?.target}{' '}{CHALLENGE.teamDashboard.minuteUnit}</span>
                </div> : <div className="card-slider-pace">
                    <span className='card-slider-pace-value'>{data?.value || '-'}</span>
                    <span className='card-slider-pace-unit'>{CHALLENGE.teamDashboard.stepsUnit}</span>
                </div>}
                <div className="card-slider-bottom">
                    {!exerciseGoal ? <div className="card-slider-distance">
                        <span className='card-slider-distance-value'>{data?.distance || '-'}</span>
                        <span className='card-slider-distance-unit'>{CHALLENGE.teamDashboard.kilometerUnit}</span>
                    </div> : ''}
                    <div className="card-slider-calories">
                        <span className="card-slider-calories-value">{data?.calories || '-'}</span>
                        <span className="card-slider-calories-unit">{HOME_PAGE.STEPS_CALORIE_UNIT}</span>
                    </div>
                </div>
            </div>
            <div className="card-slider-right">
                <div className="card progress-card progress-card-green-1">
                    <div className="outer-circle">
                        <div className="percent">
                            <svg>
                                <defs>
                                    <linearGradient id={id}>
                                        <stop offset="0%" stopColor={innerGraphColor}></stop>
                                        <stop offset="100%" stopColor={variables.flgreen1}></stop>
                                    </linearGradient>
                                </defs>
                                <circle cx="36.5" cy="36.5" r="31.5"></circle>
                                <circle cx="36.5" cy="36.5" r="31.5"
                                    style={{ "--percent": round(encapsulatePercent(totalPercent), 0) || 0, stroke: `url(#${id})` }}></circle>
                            </svg>


                        </div>
                        <div className="number">
                                <div className="progress-value">
                                {round(encapsulatePercent(totalPercent), 0) || 0}%
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepsCard