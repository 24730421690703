import React from 'react'
import { EXERCISE_LOG_DETAIL } from '../../../../../common/commonConstant'

export default function ExerciseSubDetails() {
  return (
    <div className="excercise-sub-details-container">
    <div className="excercise-sub-details-header">
      <img src="/images/healthAndWellness/goals/activity/exercise.svg" />
      <span>{EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_HEADER}</span>
    </div>
    <ul>
      <li>{EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_NOTE_1}</li>
    </ul>
  </div>
  )
}
