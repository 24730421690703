import React, { useState, useEffect } from 'react';
import './VerticalSliderAssessment.scss';
import config from '../../config';

const VerticalSliderAssessment = ({ sliderData, onSlide }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        handleChange(0);
    }, [sliderData]);

    const handleChange = (val) => {
        setValue(parseInt(val));
        onSlide(parseInt(val));
    };

    const _renderSliderLabelLeft = () => {
        return (
            sliderData.map((item, index) => (
                <span key = {index} className="range-label-left">
                    { parseInt(index) === parseInt(value) ?
                        <><img src={`${config.S3_URL}${item?.image_active_url}`}></img></>:
                        <><img src={`${config.S3_URL}${item?.image_inactive_url}`}></img></>
                    }
                </span>
            ))
        )
    }

    const _renderSliderLabelRight = () => {
        return (
            sliderData.map((item, index) => (
                <span key = {index} className="range-label">
                    {item.value}
                </span>
                
            ))
        )
    }

     const _renderSliderDots = () => {
        return (
            sliderData.map((item, index) => (
                <span key = {index} className={`range-dots ${parseInt(index) === parseInt(value) ? 'range-hide-dot' : ''}`}></span>
            ))
        )
    }

    return (
        <div className="vertical-slider-container">
            <span className="custom-range-label-left">{_renderSliderLabelLeft()}</span>

            <div className="custom-range-vertical">
                <input className={`custom-range-slider`} onChange={(e) => handleChange(e.target.value)}
                    type="range" min="0" max={sliderData.length - 1} step="1" value={value}/>
                <span className="custom-range-dots">{_renderSliderDots()}</span>
            </div>

            <span className="custom-range-label">{_renderSliderLabelRight()}</span>
        </div>
    );
};

export default VerticalSliderAssessment;
