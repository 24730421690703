import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import './SOPOptions.scss'
import { commonConstant } from '../../../common';
import { getSubscriptionOptions } from '../../../services/sop-sub.service';

export const SOPOptions = () => {
  const [loading, setLoading] = useState(true);
  const [optionData, setOptionsData] = useState({});

  useEffect(() => {
    const didMount = async () => {
      try {
        const options = await getSubscriptionOptions();
        setOptionsData(options.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    didMount();
  }, []);

  const renderHeader = () => {
    return (
      <div className='solution-header'>
        <div className="sop-header">
          <h2 className='new-h2'>SOP โปรแกรมสั่งซื้อต่อเนื่อง</h2>
          <div className='subheading'>สั่งซื้ออย่างต่อเนื่อง เพื่อสิทธิ์ประโยชน์</div>
        </div>
        <div className='sop-options-header'>
          <img
            src="/images/sop/sop-option-header.svg"
          />
        </div>
        <div className='sop-header-image'>
          <img
            src="/images/sop/sop-option-header.png"
            className="w-100"
          />
        </div>
      </div>
    )
  }

  const renderBasic = () => {
    return (
      <section className='row no-margin-row'>
        <span className='header_one col-12'>{optionData?.basic?.heading}</span>
        <span className='col-12 benefit_details display-flex'>
          <img
            src="/images/sop/icon-discount.svg"
          /><div>{optionData?.basic?.benefits}</div>
        </span>
        <span className='col-12 description_details display-flex'>
          <img
            src="/images/sop/icon-calendar-sop.svg"
          />
          <div>{optionData?.basic?.description}</div>
        </span>
        <div className='col-12'>
          <button onClick={() => { window.location.href = `${commonConstant.pathSolutionPLP}?plantype=BASIC` }}
            className="profile-btn-purchase profile-footer-btn ">
            เลือกซื้อ
          </button>
        </div>
      </section>
    )
  }

  const renderBeyond = () => {
    return (
      <section className='row no-margin-row'>
        <span className='header_one col-12 beyond-header'>{optionData?.beyond?.heading}</span>
        <span className='col-12 benefit_details display-flex'>
          <img
            src="/images/sop/icon-discount-beyond.svg"
          /><div>{optionData?.beyond?.benefits}</div>
        </span>
        <span className='col-12 description_details display-flex'>
          <img
            src="/images/sop/icon-calendar-sop-beyond.svg"
          /><div>
            {optionData?.beyond?.description}
          </div>
        </span>
        <div className='col-12'>
          <button onClick={() => { window.location.href = `${commonConstant.pathSolutionPLP}?plantype=BEYOND` }}
            className="profile-btn-purchase profile-footer-btn ">
            เลือกซื้อ
          </button>
        </div>
      </section>
    )
  }

  const renderFlex = () => {
    return (
      <section className='row no-margin-row'>
        <span className='header_one col-12 flex_header'>{optionData?.flex?.heading}</span>
        <span className='col-12 benefit_details display-flex'>
          <img
            src="/images/sop/icon-discount-flex.svg"
          />
          <div>{optionData?.flex?.benefits}</div>
        </span>
        <span className='col-12 description_details display-flex'>
          <img
            src="/images/sop/icon-calendar-sop-flex.svg"
          />
          <div>{optionData?.flex?.description}</div>
        </span>
        <div className='col-12 no-padding-col'>
          <button onClick={() => { window.location.href = commonConstant.pathPLP }}
            className="profile-btn-purchase profile-footer-btn ">
            เลือกซื้อ
          </button>
        </div>
      </section>
    )
  }

  return (
    <section className='sop_options'>
      <LoadingOverlay
        text="Loading"
        spinner
        active={loading}
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />
      {renderHeader()}
      {optionData && optionData.flex && <div className='row sop-plp-container no-margin-row'>
        {/* <div className='col-12 no-padding-col basic_container'>{renderBasic()}
          <div className='bg_one'>
            <div className='basic_bg_one'></div>
          </div>
        </div>
        <div className='col-12 no-padding-col basic_container'>{renderBeyond()}
          <div className='bg_one'>
            <div className='beyond_bg_one'></div>
          </div>
        </div> */}
        <div className='col-12 no-padding-col basic_container'>{renderFlex()}
          <div className='bg_one'>
            <div className='flex_bg_one'></div>
          </div>
        </div>
      </div>}
    </section>
  )
}