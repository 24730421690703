import React from 'react';
import Picker from 'react-mobile-picker';
import './style.scss';
import { SAVE_BUTTON_COMMON } from '../commonConstant';

export default function HnWTimePicker({
  pickerHeader,
  pickerValue,
  setPickerChange,
  pickerOptionsObj,
  pickerHeight = 187,
  pickerClassName = '',
  handleOKClick,
}) {
  return (
    <div className="hnw-picker-container">
      <div className="container-fluid">
        <div className="header-btn">
          <button className="btnClass" onClick={handleOKClick}>
            {SAVE_BUTTON_COMMON}
          </button>
        </div>
      </div>
      <div className="header-section">
        <div className="header">{pickerHeader}</div>
      </div>
      <Picker
        value={pickerValue}
        onChange={setPickerChange}
        itemHeight={42.58}
        height={pickerHeight}
        className={`modal-picker-wrapper ${pickerClassName}`}
      >
        {!!pickerOptionsObj &&
          Object.keys(pickerOptionsObj).map((name) => (
            <Picker.Column key={name} name={name} className='modal-picker-column'>
              {pickerOptionsObj[name].map((option) => (
                <Picker.Item key={option} value={option}>
                  {({ selected }) => (
                    <div
                      className={`modal-picker-item${selected ? ' --selected' : ''
                        }`}
                    >
                      {option}
                    </div>
                  )}
                </Picker.Item>
              ))}
            </Picker.Column>
          ))}
      </Picker>
    </div>
  );
}
