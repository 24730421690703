import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';

import { commonConstant } from '../../common';
import { customerService } from '../../services';
import { setDynamicRichMenu } from '../../actions';
import config from '../../config';
// const liff = window.liff;
const { liff } = window;
export class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      loading: true,
      redirect: null,
    };
  }


  async componentDidMount() {
    if (sessionStorage.getItem('customer') === null || sessionStorage.getItem('customer') === 'undefined') {
      return window.location.replace(commonConstant.pathHome);
    }

    const customer = JSON.parse(sessionStorage.getItem('customer'));

    if (customer.registerStatus !== 'complete') {
      const params = {
        registerStatus: 'complete',
        action: 'REGISTER_COMPLETE',
        registerDate: new Date(),
        socialType: 'LINE',
      };
      await customerService.update(customer._id, params);
      
    } else {
      const params = {
        action: 'REGISTER_COMPLETE',
        socialType: 'LINE',
      };
      await customerService.update(customer._id, params);
    }
    await this.props.setDynamicRichMenu({ ...customer, registerStatus: 'complete' });
    customerService.get(customer.uid).then((res) => {
      this.setState({
        userInfo: res,
        loading: false,
      });
    });
  }

  handleButton = () => {
    liff.closeWindow();
  }

  handleButtonInterest = async () => {
    if (sessionStorage.getItem('customer') === null || sessionStorage.getItem('customer') === 'undefined') {
      return window.location.replace(commonConstant.pathHome);
    }

    const customer = JSON.parse(sessionStorage.getItem('customer'));
    const params = {
      action: 'SELECT_PRODUCT',
    };
    await customerService.update(customer._id, params);
    this.setState({
      redirect: '/interest',
    });
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    if (this.state.loading) {
      return <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />;
    }
    const { pictureUrl, displayName, amwayNumber, type } = this.state.userInfo;
    return (
      <div className="Success">
        <div className="logoAmway">
          <img src="/images/logoAmway-black.svg" alt="" />
        </div>
        <h2>ลงทะเบียนเรียบร้อยแล้ว !</h2>
        <div className="picture">
          <img src={pictureUrl || config.AVATAR_DEFAULT} alt="" />
        </div>
        <h2 className="name">คุณ {displayName}</h2>
        {
          amwayNumber ?
            <>
              <small>{type === 'abo' ? 'หมายเลขนักธุรกิจ' : 'หมายเลขสมาชิก'}</small>
              <h2 className="away-num">{amwayNumber ? amwayNumber : '-'}</h2>
            </> : null
        }
        <div className="detail">
          ขอบคุณค่ะ คุณได้ร่วมโครงการ<br />
          Amway Social Connect เรียบร้อยแล้ว<br />
          รอรับข่าวสารดีๆ ที่คุณสนใจเร็วๆนี้
        </div>
        <div className="footer">
          {type === 'no' ? <button onClick={this.handleButtonInterest} className="away-button">เลือกหัวข้อที่สนใจใหม่</button> : null}
          <button onClick={this.handleButton} className="away-button active">ปิด</button>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ profile }) {
  return { profile };
}

const mapDispatchToProps = (dispatch) => ({
  setDynamicRichMenu: customer => setDynamicRichMenu(dispatch,customer),
});

export default connect(mapStateToProps,mapDispatchToProps)(Success);
