import React from "react";
import './ChallengeProgressBar.scss';

const ChallengeProgressBar = ({ percentage }) => {
    return (
        <div className="progress-outer">
            <div className="progress">
                <div className="progress-bar bg-fill" role="progressbar" aria-valuenow={percentage}
                    aria-valuemin="0" aria-valuemax="100" style={{width: `${percentage}%`}}></div>
            </div>
        </div>
    )
}

export default ChallengeProgressBar;