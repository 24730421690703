import React,{ useState, useEffect } from 'react';
import { commonConstant } from '../../common';

import './AWDatepicker.scss';

export const AWDatepicker = props => {
  const { onChange, id , name , maxYear, minYear, value } = props;
  const date = new Date(value);
  const [day,setDay] = useState(date.getDate());
  const [month,setMonth] = useState(date.getMonth() + 1);
  const [year,setYear] = useState(date.getFullYear() + 543);

  useEffect(() => {
    const monthWithZero = month <= 9 ? `0${month}` : month;
    const dayWithZero = day <= 9 ? `0${day}` : day;
    const dayFomatted = year && monthWithZero && dayWithZero ? `${year}-${monthWithZero}-${dayWithZero}` : '';
    onChange({ target:{ name, id, value: dayFomatted } });
  },[day, id, month, name, onChange, year]);



  const renderDayOptions = () => {
    const returnArr = [];
    for (let i = 1; i <= 31; i++){
      returnArr.push(
        <option value={i} key={`day-${i}`}>{i}</option>,
      );
    }
    return returnArr;
  };

  const renderMonthOptions = () => {
    const returnArr = [];
    for (let i = 0; i <= 11; i++){
      returnArr.push(
        <option value={i + 1} key={`month-${i}`}>{commonConstant.MONTHS.th[i]}</option>,
      );
    }
    return returnArr;
  };

  // const daysInMonth = (month, year) => new Date(year, month, 0).getDate(); 

  const renderYearOptions = () => {
    const returnArr = [];
    for (let i = maxYear; i >= minYear; i--){
      returnArr.push(
        <option value={i - 543} key={`year-${i}`}>{i}</option>,
      );
    }
    return returnArr;
  };
  return (
    <div className="awdatepicker-container">
      <div className="inline-picker">
        <select id="day" className={day ? '' : 'placeholder'} value={day ? day : ''} onChange={e => setDay(e.target.value)}>
          <option value="" disabled>วันที่</option>
          {
            renderDayOptions()
          }
        </select>
        <div className="select_arrow">
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.34999 7.35005L13.35 1.35005L12.65 0.650054L6.64999 6.65005L7.34999 7.35005ZM0.649993 1.35005L6.64999 7.35005L7.34999 6.65005L1.34999 0.650054L0.649993 1.35005ZM13.35 1.35005C13.3978 1.30516 13.4359 1.25095 13.462 1.19075C13.4881 1.13055 13.5015 1.06565 13.5015 1.00005C13.5015 0.934457 13.4881 0.869557 13.462 0.809359C13.4359 0.749161 13.3978 0.694944 13.35 0.650054C13.3051 0.602223 13.2509 0.564101 13.1907 0.538041C13.1305 0.51198 13.0656 0.498535 13 0.498535C12.9344 0.498535 12.8695 0.51198 12.8093 0.538041C12.7491 0.564101 12.6949 0.602223 12.65 0.650054L13.35 1.35005ZM6.99999 7.00005L6.64999 7.35005C6.69488 7.39788 6.7491 7.43601 6.8093 7.46207C6.8695 7.48813 6.9344 7.50157 6.99999 7.50157C7.06559 7.50157 7.13049 7.48813 7.19069 7.46207C7.25089 7.43601 7.3051 7.39788 7.34999 7.35005L6.99999 7.00005ZM1.34999 0.650054C1.3051 0.602223 1.25089 0.564101 1.19069 0.538041C1.13049 0.51198 1.06559 0.498535 0.999993 0.498535C0.934396 0.498535 0.869496 0.51198 0.809298 0.538041C0.7491 0.564101 0.694883 0.602223 0.649993 0.650054C0.602162 0.694944 0.56404 0.749161 0.537979 0.809359C0.511919 0.869557 0.498474 0.934457 0.498474 1.00005C0.498474 1.06565 0.511919 1.13055 0.537979 1.19075C0.56404 1.25095 0.602162 1.30516 0.649993 1.35005L1.34999 0.650054Z" fill={day ? '#2C2C2C' : '#929497'} />
          </svg>
        </div>
      </div>
      <div className="inline-picker">
        <select className={month ? '' : 'placeholder'} value={month ? month : ''} onChange={e => setMonth(e.target.value)}>
          <option value="" disabled>เดือน</option>
          {
            renderMonthOptions()
          }
        </select>
        <div className="select_arrow">
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.34999 7.35005L13.35 1.35005L12.65 0.650054L6.64999 6.65005L7.34999 7.35005ZM0.649993 1.35005L6.64999 7.35005L7.34999 6.65005L1.34999 0.650054L0.649993 1.35005ZM13.35 1.35005C13.3978 1.30516 13.4359 1.25095 13.462 1.19075C13.4881 1.13055 13.5015 1.06565 13.5015 1.00005C13.5015 0.934457 13.4881 0.869557 13.462 0.809359C13.4359 0.749161 13.3978 0.694944 13.35 0.650054C13.3051 0.602223 13.2509 0.564101 13.1907 0.538041C13.1305 0.51198 13.0656 0.498535 13 0.498535C12.9344 0.498535 12.8695 0.51198 12.8093 0.538041C12.7491 0.564101 12.6949 0.602223 12.65 0.650054L13.35 1.35005ZM6.99999 7.00005L6.64999 7.35005C6.69488 7.39788 6.7491 7.43601 6.8093 7.46207C6.8695 7.48813 6.9344 7.50157 6.99999 7.50157C7.06559 7.50157 7.13049 7.48813 7.19069 7.46207C7.25089 7.43601 7.3051 7.39788 7.34999 7.35005L6.99999 7.00005ZM1.34999 0.650054C1.3051 0.602223 1.25089 0.564101 1.19069 0.538041C1.13049 0.51198 1.06559 0.498535 0.999993 0.498535C0.934396 0.498535 0.869496 0.51198 0.809298 0.538041C0.7491 0.564101 0.694883 0.602223 0.649993 0.650054C0.602162 0.694944 0.56404 0.749161 0.537979 0.809359C0.511919 0.869557 0.498474 0.934457 0.498474 1.00005C0.498474 1.06565 0.511919 1.13055 0.537979 1.19075C0.56404 1.25095 0.602162 1.30516 0.649993 1.35005L1.34999 0.650054Z" fill={month ? '#2C2C2C' : '#929497'} />
          </svg>
        </div>
      </div>    
      <div className="inline-picker">
        <select className={year ? '' : 'placeholder'} value={year ? year : ''} onChange={e => setYear(e.target.value)}>
          <option value="" disabled>ปี พ.ศ.</option>
          {
            renderYearOptions()
          }
        </select>
        <div className="select_arrow">
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.34999 7.35005L13.35 1.35005L12.65 0.650054L6.64999 6.65005L7.34999 7.35005ZM0.649993 1.35005L6.64999 7.35005L7.34999 6.65005L1.34999 0.650054L0.649993 1.35005ZM13.35 1.35005C13.3978 1.30516 13.4359 1.25095 13.462 1.19075C13.4881 1.13055 13.5015 1.06565 13.5015 1.00005C13.5015 0.934457 13.4881 0.869557 13.462 0.809359C13.4359 0.749161 13.3978 0.694944 13.35 0.650054C13.3051 0.602223 13.2509 0.564101 13.1907 0.538041C13.1305 0.51198 13.0656 0.498535 13 0.498535C12.9344 0.498535 12.8695 0.51198 12.8093 0.538041C12.7491 0.564101 12.6949 0.602223 12.65 0.650054L13.35 1.35005ZM6.99999 7.00005L6.64999 7.35005C6.69488 7.39788 6.7491 7.43601 6.8093 7.46207C6.8695 7.48813 6.9344 7.50157 6.99999 7.50157C7.06559 7.50157 7.13049 7.48813 7.19069 7.46207C7.25089 7.43601 7.3051 7.39788 7.34999 7.35005L6.99999 7.00005ZM1.34999 0.650054C1.3051 0.602223 1.25089 0.564101 1.19069 0.538041C1.13049 0.51198 1.06559 0.498535 0.999993 0.498535C0.934396 0.498535 0.869496 0.51198 0.809298 0.538041C0.7491 0.564101 0.694883 0.602223 0.649993 0.650054C0.602162 0.694944 0.56404 0.749161 0.537979 0.809359C0.511919 0.869557 0.498474 0.934457 0.498474 1.00005C0.498474 1.06565 0.511919 1.13055 0.537979 1.19075C0.56404 1.25095 0.602162 1.30516 0.649993 1.35005L1.34999 0.650054Z" fill={year ? '#2C2C2C' : '#929497'} />
          </svg>
        </div>
      </div>
    </div>
  );
};
