import React, { useEffect, useState } from 'react';
import './ChallengeTag.scss';
import {
  ChallengeTeamdashboardStatus,
  rectBadgeClassname,
} from '../../../common/challengeConstants';

const ChallengeTag = ({ status, teamMissing, challengeDay }) => {
  let tagText =
    status && status === ChallengeTeamdashboardStatus.not_yet_started 
      ? `${rectBadgeClassname[status]?.text} ${teamMissing} ${rectBadgeClassname[status]?.textAfter}`
      : rectBadgeClassname[status]?.text;
    if (challengeDay && status === ChallengeTeamdashboardStatus.ongoing) {
      tagText += ` ${challengeDay}`;
    }
  return status ? (
    <div className="challenge-tag">
      <button className={`tag-button ${(rectBadgeClassname[status]?.tagClassName || rectBadgeClassname[status].classname)}`}>
        {rectBadgeClassname[status]?.iconUrl && (
          <img src={rectBadgeClassname[status]?.iconUrl} alt="Tag icon" />
        )}
        {tagText}
      </button>
    </div>
  ) : null;
};

export default ChallengeTag;
