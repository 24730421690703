import React from 'react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
import './TeamDashboardSwiperMember.scss'
import { CHALLENGE } from '../challengeConstants';
SwiperCore.use([Navigation, EffectCoverflow]);

const TeamDashboardSwiperMember = ({
    challengeUserData,
    teamSize
}) => {

    const _teamProfileHeader = () => {
        return (
            <div class="dashboard-slider-sidebar-title text-center">
                {CHALLENGE.teamDashboard.teamProfileHeader}
            </div>
        )
    }

    const _teamProfileContainer = (index) => {
        return (
            <div class="dashboard-slider-sidebar-profile">
                <div className="dashboard-slider-image-container">
                    <img className='profile-circle-img' src={challengeUserData[index]?.pictureUrl || '/images/challenge/default_challenge_profile.svg'} />
                    <div className="dashboard-slider-leader-img">
                        {index == 0 &&
                        <>
                            <img className='profile-img' src={'/images/challenge/icons/team-dashboard-circle.svg'} />
                            <div className="btn-icon-crown">
                                <img src="/images/challenge/icons/icon-challenge-crown.svg" />
                            </div>
                        </>}
                    </div>

                </div>
                <div class="dashboard-slider-sidebar-profile-name">
                    {challengeUserData[index]?.firstName || challengeUserData[index]?.lastName ? (<><div className='first-name'>{challengeUserData[index]?.firstName}</div>
                    <div className='last-name'>{challengeUserData[index]?.lastName}</div></>) : CHALLENGE.teamDashboard.noUser}
                </div>
            </div>
        )
    }

    return (
        <>
            <div class="dashboard-slider-container">
                <div class="dashboard-slider-sidebar">
                    {_teamProfileHeader()}
                    <div class="dashboard-slider-sidebar-profile-wrapper">
                        {Array.from({ length: teamSize }).map((_, index) => (
                            _teamProfileContainer(index)
                        ))}

                    </div>
                </div>
            </div>
        </>

    );
};

export default TeamDashboardSwiperMember