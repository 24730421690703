import React, { useState, useEffect } from "react";
import { VIDEO_DETAIL_HEADER, VIDEO_CARD_TEXT } from "../../../common/challengeConstants";
import QuizHeader from "../challengeQuiz/challengeHeader/QuizHeader";
import ChallengeVideoCard from "../challengeQuiz/challengeVideo/ChallengeVideoCard";
import "./challengeVideoDetail.scss";
import { useHistory, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { getDataFromSession, getParamsString } from "../../../utill.func";
import { useSelector } from "react-redux";
import ChallengeVideoCardLoader from "../challengeQuiz/challengeQuizLoader/ChallengeVideoCardLoader";
import { commonConstant } from "../../../common";

const ChallengeVideoDetail = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [filteredVideos, setFilteredVideos] = useState([]);
    const [videoHeading, setVideoHeading] = useState("");
    const history = useHistory();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const challengeId = params['challengeId'];
    const eventId = params['eventId'];
    const userId = params['userId'];
    const videoIndex = params['videoId'];
    const { challengeVideoList, loading: videoLoading } = useSelector(state => state.challengeVideo);
    const { challengeQuizList, loading: quizLoading } = useSelector(state => state.challengeQuiz);

    useEffect(() => {
        if (challengeVideoList?.length > 0) {
            const startIndex = videoIndex * 5;
            const endIndex = startIndex + 5;

            const filteredVideoData = challengeVideoList.slice(startIndex, endIndex);
            setFilteredVideos(filteredVideoData);
        }

        if(challengeQuizList?.length > 0){
            const videoDetailHeading = challengeQuizList[videoIndex]?.quiz_part?.quiz_title;
            setVideoHeading(videoDetailHeading);
        }
    }, [videoIndex, challengeVideoList, challengeQuizList]);

    const handleLeftIconRedirection = () => {
        const params = {
            challengeId: challengeId,
            eventId: eventId,
            userId: userId
        }
        history.push(`${commonConstant.pathChallengeQuiz}?${getParamsString(params)}`);
      }
    
      const handleRightIconRedirection = () => {
          const params = {
              challengeId: challengeId,
              eventId: eventId
          }
          history.push(`${commonConstant.pathChallengeTeamDashboard}?${getParamsString(params)}`);
      }

    const quizHeaderProps = {
        leftIcon: VIDEO_DETAIL_HEADER.LEFT_ICON,
        rightIcon: VIDEO_DETAIL_HEADER.RIGHT_ICON,
        isSticky: isSticky,
        handleLeftIconRedirection: handleLeftIconRedirection,
        handleRightIconRedirection: handleRightIconRedirection,
    };

    const video_detail_heading = VIDEO_CARD_TEXT.video_detail_heading;

    useEffect(() => {
        const handleScroll = () => {
            const windowTop = window.scrollY;
            setIsSticky(windowTop > 30);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const handleYoutubeModal = (
        videoId = null
      ) => {
        const params = {
          eventId: challengeId,
          videoId: videoId,
          videoIndex: videoIndex,
          screenType: 'video'
        }
        history.push(`${commonConstant.pathChallengeVideoPlayer}?${getParamsString(params)}`);
      }

    return <>
        <div className="quiz-video-details-page">
            <div className={`header-wrapper ${isSticky ? 'sticky' : ''}`}>
                <QuizHeader {...quizHeaderProps} />
            </div>
            <div className="container-fluid">
                <div className="quiz-video-container">
                    <div className="quiz-video-title-wrapper">
                        <div className="quiz-video-title">
                            {`${video_detail_heading} ${videoHeading}`}
                        </div>
                    </div>
                    <div className="video-listing-container">
                        <div>
                            <div className="video-image-container">
                                {videoLoading ?
                                    (Array.from({ length: 5 }).map((_, idx) => <ChallengeVideoCardLoader key={idx} />)) :
                                    filteredVideos?.map((video) => <ChallengeVideoCard
                                        key={video.videoId}
                                        videoData={video}
                                        openModal={handleYoutubeModal} />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ChallengeVideoDetail;