import React from 'react';
import './BoxContent.scss';
import { formatNumberWithCommas } from '../../../../../utill.func';

export default function BoxContent({
  keyProperty,
  keyPropertyValue,
  keyPropertyUnit,
}) {
  return (
    <div className="goal-setup-box-component d-flex flex-column justify-content-center align-items-center">
      <div className="goal-setup-box-component-title">{formatNumberWithCommas(keyProperty)}</div>
      <div className="goal-setup-box-component-value">{formatNumberWithCommas(keyPropertyValue)}</div>
      <div className="goal-setup-box-component-unit">{formatNumberWithCommas(keyPropertyUnit)}</div>
    </div>
  );
}
