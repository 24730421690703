import React from "react";

const BmiCirclesSvg = ({ bmiColor }) => {

    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6.5" r="6" fill={bmiColor} />
        </svg>
    )
}

export default BmiCirclesSvg;