import AxiosHealthWellness from '.';
import config from '../../config';
import { FoodDetail } from '../../model/FoodDetail.model';
import { FoodCategory } from '../../model/FoodCategory.model';
import { Product } from '../../model/Product.model';
import { HistorySearchKeywords } from '../../model/HistorySearchKeywords.model';

export const getProductCategories = async () => {
  const response = await AxiosHealthWellness.get(
    `${config.API_URL}/food-supplement/get-product-categories`,
  );
  if (response && response.data) {
    return response.data.map((el) => new FoodCategory(el));
  }
  return [];
};

/**
 * function to get products
 * @param {limit=10&start=0&categoryId=<categoryID>&type=Food/Supplement&isRecommended=boolean&isKeyword=boolean&search&status=Published} params
 * @returns promise
 */
export const getProducts = async (params) => {
  const response = await AxiosHealthWellness.get(
    `${config.API_URL}/food-supplement/get-products`, { params }
  );
  if (response?.data) {
    return {
      data: response.data?.data?.map((el) => new Product(el)),
      recordsTotal: response.data?.recordsTotal,
    };
  }
  return [];
};

export const getFoodDetail = async (params) => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/food-supplement/get-product`, { params });
    return new FoodDetail(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
}

export const getHistoryKeywords = async () => {
  try {
    const response = await AxiosHealthWellness.get(`${config.API_URL}/food-supplement/get-keywords`);
    if (response?.data) {
      return response.data?.map((el) => new HistorySearchKeywords(el));
    }
    return [];
  } catch (error) {
    console.log(error);
  }
}