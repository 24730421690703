import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { commonConstant } from '../../../common';
import { CreateOrders } from '../../../services/promotion/payment.service';
import {
    getPriceFormat, isCartABOMemberType, message
} from '../../../services/promotion/utils.service';
import '../../promotion/CartPromotion.scss';
import { liffGetProfile } from '../../../services/promotion/auth.service';

const { liff } = window;

export const AddressFooter = ({ cart, isInstallment, customerData, haveAddress, openSummary }) => {
    const [loading, setLoading] = useState(false);
    const [amwayValue, setAmwayValue] = useState({
        pointValue: 0,
        businessVolume: 0,
    });
    const [cartData, setCartData] = useState(null);
    const [isAboCart, setIsAboCart] = useState(false);

    useEffect(() => {
        const myCart = cart ? cart[0] : null;
        if (myCart) {
            setAmwayValue(myCart.amwayValue);
        }
        if (cart) {
            const { accountType = '' } = cart[0]?.account;
            const isABOMemberType = isCartABOMemberType(accountType);
            if (isABOMemberType) {
                setIsAboCart(true);
            }
        }
    }, [cart]);

    useEffect(() => {
        let cartDataLocal = JSON.parse(sessionStorage.getItem('cartData'));
        setCartData(cartDataLocal);
    }, []);

    const onPurchase = async () => {
        try {
            setLoading(true);
            if (!customerData) {
                const myCart = cart ? cart[0] : null;
                const { deliveryMethod, premiumItemSet } = myCart;

                if (!deliveryMethod.deliveryContactNumber) {
                    setLoading(false);
                    alert(message.error.phoneNotFound);
                    return;
                }

                const hasElligibleTargetQuantity = premiumItemSet.filter(
                    (v) => v.elligibleTargetQty !== 0,
                );
                if (hasElligibleTargetQuantity) {
                    for (const item of hasElligibleTargetQuantity) {
                        const premiumItem = item.premiumItems;
                        const hasFreePremiumItem = premiumItem.filter(
                            (v) => v.targetPrice === 0,
                        );
                        if (hasFreePremiumItem.length !== 0) {
                            setLoading(false);
                            return alert('กรุณาเลือกรับสินค้าแถมฟรี');
                        }
                    }
                }

                const { userId } = await liffGetProfile();
                const cartIds = [myCart.id];
                const order = await CreateOrders(cartIds, userId, isInstallment);
                if (order && !order.isNotValid) {
                    setLoading(false);
                    localStorage.setItem('deleteIds', '[]');
                    liff.closeWindow();
                } else {
                    alert(message.error.order);
                    setLoading(false);
                }
            }
            // else {
            //   await orderFOA(cartData, customerData.uid);
            //   liff.closeWindow();
            // }
        } catch (error) {
            alert(message.error.somethingWrong);
            console.log(error);
            setLoading(false);
        }
    };

    const onClose = () => {
        liff
            .sendMessages([
                {
                    type: 'text',
                    text: 'โปรโมชั่น',
                },
            ])
            .then(() => {
                console.log('message sent');
            })
            .catch((err) => {
                console.log('error', err);
            });
        liff.closeWindow();
    };

    const onCloseSOP = () => {
        liff
            .sendMessages([
                {
                    type: 'text',
                    text: 'SOP ของฉัน',
                },
            ])
            .then(() => {
                console.log('message sent');
            })
            .catch((err) => {
                console.log('error', err);
            });
        liff.closeWindow();
    };

    const recommendProduct = () => {
        liff
            .sendMessages([
                {
                    type: 'text',
                    text: 'สินค้าแนะนำ',
                },
            ])
            .then(() => {
                console.log('message sent');
            })
            .catch((err) => {
                console.log('error', err);
            });
        liff.closeWindow();
    };

    const getTotalPrice = () => {
        const totalPrice = cart[0].grandTotalPrice.price;
        const price = getPriceFormat(totalPrice);
        return `฿ ${price}`;
    };

    return (
        <section>
            <LoadingOverlay
                text="Loading"
                spinner
                active={loading}
                styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
            />
            <div className="list-address-panel-footer">
                <div className="footer-alignment">
                    <div className="price-summary-alignment">
                        <div>
                            <div className="price-text">{cart && getTotalPrice()}</div>
                        </div>
                        <div>
                            <span className="link-order-summary-text" onClick={() => openSummary(true)}>รายละเอียดคำสั่งซื้อ</span>{` >`}
                        </div>
                    </div>
                    <button
                        className="list-select-address-footer font-larger"
                        onClick={(e) => {
                            return !haveAddress
                                ? alert('กรุณาเพิ่มที่อยู่จัดส่ง')
                                : customerData && cartData
                                    ? onPurchase()
                                    : !customerData
                                        ? onPurchase()
                                        : (window.location.href = commonConstant.pathFOARegister);
                        }}
                    >
                        <span className="select-address-text">สั่งซื้อ</span>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default AddressFooter;
