import {
  GET_USER_UPLOADED_IMAGES,
  GET_USER_UPLOADED_IMAGES_SUCCESS,
  GET_USER_UPLOADED_IMAGES_ERROR,
  GET_PRE_SIGNED_URL_SUCCESS,
  GET_PRE_SIGNED_URL_ERROR,
  PUT_PRE_SIGNED_URL_SUCCESS,
  PUT_PRE_SIGNED_URL_ERROR,
  PUT_CONFIRM_UPLOAD_SUCCESS,
  PUT_CONFIRM_UPLOAD_ERROR,
} from '../../actions/types';

const initialState = {
  loading: false,
  images: [],
  preSignedUrl: '',
  path: '',
  uploadedRes: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_UPLOADED_IMAGES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_USER_UPLOADED_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        images: action.payload,
      };
    case GET_USER_UPLOADED_IMAGES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        images: [],
      };
    case GET_PRE_SIGNED_URL_SUCCESS:
      return {
        ...state,
        preSignedUrl: action.payload.url,
        path: action.payload.path,
        error: null,
      };
    case GET_PRE_SIGNED_URL_ERROR:
      return {
        ...state,
        preSignedUrl: '',
        path: '',
        contentType: '',
        error: action.payload,
      };
    case PUT_PRE_SIGNED_URL_SUCCESS:
      return {
        ...state,
        error: action.payload,
      };
    case PUT_PRE_SIGNED_URL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case PUT_CONFIRM_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadedRes: action.payload,
        error: null
      };
    case PUT_CONFIRM_UPLOAD_ERROR:
      return {
        ...state,
        uploadedRes: null,
        error: action.payload,
      };
    default:
      return state;
  }
}
